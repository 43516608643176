import React from 'react';

import { RetirementBenefits, RetirementPlanType } from 'Containers/ViewBenefitPage/types';
import { transformBenefitItems } from 'Containers/ViewBenefitPage/utils';
import { Paragraph } from 'DesignLibrary/atoms';
import Text from 'Shared/Text';
import { SupplementalPlan } from 'Types/entities';
import { toDollars } from 'Utils/helpers';

import { HiddenItem } from './PlanContent/styled';

export const retirementDetails = (plan: SupplementalPlan) => {
  const benefits = transformBenefitItems(plan) as RetirementBenefits;

  const getEmployerMatchBenefitItem = () => {
    const numMatchedTiers = parseInt(benefits.num_match_tiers || '0', 10);

    const matchedTier1 = (
      <Text
        field="benefits_section_retirement.matching_tier_1"
        vars={{
          tier1PercentMatched: benefits.matching_tiers_1_percent_matched,
          tier1RatioMatched: toDollars(benefits.matching_tiers_1_ratio_matched, 2),
        }}
      />
    );
    const matchedTier2 = (
      <Text
        field="benefits_section_retirement.matching_tier_2"
        vars={{
          tier2PercentMatched: benefits.matching_tiers_2_percent_matched,
          tier2RatioMatched: toDollars(benefits.matching_tiers_2_ratio_matched, 2),
        }}
      />
    );
    const matchedTier3 = (
      <Text
        field="benefits_section_retirement.matching_tier_3"
        vars={{
          tier3PercentMatched: benefits.matching_tiers_3_percent_matched,
          tier3RatioMatched: toDollars(benefits.matching_tiers_3_ratio_matched, 2),
        }}
      />
    );
    if (numMatchedTiers === 3) {
      return (
        <>
          {matchedTier1}
          {matchedTier2}
          {matchedTier3}
        </>
      );
    }
    if (numMatchedTiers === 2) {
      return (
        <>
          {matchedTier1}
          {matchedTier2}
        </>
      );
    }
    if (numMatchedTiers === 1) {
      return matchedTier1;
    }
    return null;
  };

  const getEmployeeVestingSchedule = () => {
    const vestingBenefitsKeys = Object.keys(benefits).filter((key) => key.startsWith('vesting_schedule_'));
    const finishedVestingIndex = vestingBenefitsKeys.findIndex((key) => benefits[key] === '100');

    if (!benefits.has_vesting) {
      return null;
    }

    if (finishedVestingIndex === 0) {
      return <Text field="benefits_section_retirement.vesting_schedule_less_than_one_year" />;
    }

    if (finishedVestingIndex > 0 && finishedVestingIndex < 6) {
      return (
        <Text
          field="benefits_section_retirement.vesting_schedule_multi_year"
          vars={{ finishedVestingIndex }}
        />
      );
    }
    return <Text field="benefits_section_retirement.vesting_schedule_six_years" />;
  };

  const getEmployeeContributionBenefitItem = () => {
    if (benefits.contribution_limit_percent_max) {
      return (
        <Text
          field="benefits_section_retirement.employee_contribution_description"
          vars={{ contributionPercent: benefits.contribution_limit_percent_max }}
        />
      );
    }
    return null;
  };

  const getAutoEnrollmentBenefitItem = () => {
    if (benefits.has_auto_enrollment) {
      return (
        <Text
          field="benefits_section_retirement.automatic_enrollment_description"
          vars={{ autoEnrollPercent: benefits.auto_enrollment_percent }}
        />
      );
    }
    return null;
  };

  return (
    <section className="benefits hidden-benefits retirement-plan">
      <Paragraph as="h6">
        <Text field="benefits_section.plan_card_text.payouts" />
      </Paragraph>
      {benefits.offer_match && (
        <HiddenItem className="employer-match">
          <Paragraph size="mini" className="key">
            <Text field="benefits_section_retirement.employer_match" />
          </Paragraph>
          <Paragraph size="mini" className="value">
            {getEmployerMatchBenefitItem()}
          </Paragraph>
        </HiddenItem>
      )}
      {benefits.has_vesting && (
        <HiddenItem className="vesting-schedule">
          <Paragraph size="mini" className="key">
            <Text field="benefits_section_retirement.vesting_schedule" />
          </Paragraph>
          <Paragraph size="mini" className="value">
            {getEmployeeVestingSchedule()}
          </Paragraph>
        </HiddenItem>
      )}
      {benefits.has_auto_enrollment && (
        <HiddenItem className="automatic-enrollment">
          <Paragraph size="mini" className="key">
            <Text field="benefits_section_retirement.automatic_enrollment" />
          </Paragraph>
          <Paragraph size="mini" className="value">
            {getAutoEnrollmentBenefitItem()}
          </Paragraph>
        </HiddenItem>
      )}
      {benefits.contribution_limit_percent_max != null && (
        <HiddenItem className="employee-contribution">
          <Paragraph size="mini" className="key">
            <Text field="benefits_section_retirement.employee_contribution" />
          </Paragraph>
          <Paragraph size="mini" className="value">
            {getEmployeeContributionBenefitItem()}
          </Paragraph>
        </HiddenItem>
      )}
    </section>
  );
};

export const retirementAdditionalNotes = (coverageType: RetirementPlanType, employerName: string) => {
  switch (coverageType) {
    case '401k':
    case '403b':
    case '403combo':
    case '457b':
      return (
        <Paragraph as="div" lineHeight={1.75} size="small" className="plan-description">
          <Text field={`benefits_section_retirement.retirement_${coverageType}`} vars={{ employerName }} />
        </Paragraph>
      );
    default:
      return (
        <Paragraph as="div" lineHeight={1.75} size="small" className="plan-description">
          <Text field="benefits_section_retirement.retirement" vars={{ employerName }} />
        </Paragraph>
      );
  }
};

export const getRetirementTagList = (retirementBenefits: RetirementBenefits): JSX.Element[] => {
  const tags: JSX.Element[] = [];
  const contributionLimit = parseFloat(retirementBenefits.contribution_limit_percent_max || '0');

  if (retirementBenefits.offer_match) {
    tags.push(<Text field="benefits_section_retirement.employer_match" />);
  }
  if (retirementBenefits.has_vesting) {
    tags.push(<Text field="benefits_section_retirement.vesting_schedule" />);
  }
  if (retirementBenefits.has_auto_enrollment) {
    tags.push(<Text field="benefits_section_retirement.automatic_enrollment" />);
  }
  if (contributionLimit > 0) {
    tags.push(<Text field="benefits_section_retirement.employee_contribution" />);
  }
  return tags;
};
