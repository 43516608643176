import styled, { css } from 'styled-components';

import { breakpoint } from 'DesignLibrary/global';
import {
  FadeInDownKeyframes,
  FadeInLeftKeyframes,
  FadeOutUpKeyframes,
  FadeInRightKeyframes,
  FadeOutKeyframes,
} from 'DesignLibrary/keyframes';

interface HeroContentProps {
  isNextClicked: boolean;
}

export const HeroContainer = styled.section<HeroContentProps>`
  ${({ isNextClicked }) =>
    isNextClicked &&
    css`
      .hero-text-note {
        opacity: 1;
        animation-name: ${FadeOutKeyframes};
        animation-duration: 1s;
        animation-delay: 0s;
        -webkit-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
      }
    `}
`;

export const HeroContentWrapper = styled.section<HeroContentProps>`
  position: relative;
  padding: var(--sizing-sm) var(--sizing-xs) var(--sizing-2xl) var(--sizing-xs);
  display: flex;
  flex-direction: column;
  gap: var(--sizing-lg);

  ${breakpoint.TABLET} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 96px 0 var(--sizing-3xl);
  }

  ${breakpoint.DESKTOP} {
    grid-template-columns: 1.2fr 1fr;
  }

  ${breakpoint.WIDEDESKTOP} {
    grid-template-columns: 1fr 1fr;
  }

  .content {
    order: 2;
    ${breakpoint.TABLET} {
      order: 1;
    }
  }

  .animation {
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    animation: ${FadeInRightKeyframes} 1s 2s forwards;
    order: 1;

    > div {
      max-width: 320px;

      ${breakpoint.TABLET} {
        max-width: 480px;
      }
    }

    ${breakpoint.TABLET} {
      position: relative;
      bottom: var(--sizing-sm);
      order: 2;
    }
  }

  ${({ isNextClicked }) =>
    isNextClicked &&
    css`
      .animation {
        opacity: 1;
        animation-name: ${FadeOutUpKeyframes};
        animation-duration: 1s;
        animation-delay: 0s;
      }
    `}
`;

export const HeroContent = styled.section<HeroContentProps>`
  z-index: 10;
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${breakpoint.TABLET} {
    display: block;
    text-align: left;
    margin-bottom: 0;
  }

  h1 {
    font-weight: 300 !important;
    opacity: 0;
    animation: ${FadeInDownKeyframes} 1s 1.25s forwards;
    margin: 0 auto;

    ${breakpoint.TABLET} {
      margin: 0;
    }

    > span {
      display: inline-flex;
      position: relative;
      top: 2px;
      left: 2px;

      img {
        height: 36px;

        ${breakpoint.DESKTOP} {
          height: 44px;
        }
      }
    }
  }

  h3 {
    font-weight: 300 !important;
    margin-bottom: 1rem;
    opacity: 0;
    animation: ${FadeInDownKeyframes} 1s 1.25s forwards;
  }

  .cta-buttons {
    opacity: 0;
    animation: ${FadeInLeftKeyframes} 1s 1.75s forwards;
    margin-top: var(--sizing-lg);
    max-width: 360px;
    width: 100%;

    ${breakpoint.TABLET} {
      max-width: 320px;
      margin-top: var(--sizing-xl);
    }
  }

  .alex-id-warning {
    opacity: 0;
    animation: ${FadeInLeftKeyframes} 1s 1.75s forwards;
    margin-top: var(--sizing-lg);
    width: 100%;
    background: rgba(255, 0, 0, 0.4);
    padding: 20px 20px;

    ${breakpoint.TABLET} {
      margin-top: var(--sizing-xl);
    }
  }

  .cta-message {
    padding-bottom: 20px;
  }

  ${({ isNextClicked }) =>
    isNextClicked &&
    css`
      h1,
      h3,
      .cta-buttons,
      .alex-id-warning {
        opacity: 1;
        animation-name: ${FadeOutUpKeyframes};
        animation-duration: 1s;
        animation-delay: 0s;
      }
    `}
`;
