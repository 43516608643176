import React, { FC, useEffect } from 'react';

import { EnrollmentReasonExplainer } from 'Containers/ProfilePage/EnrollmentSection/EnrollmentReasonExplainer';
import { AudioConfigTooltipContentfulPaths } from 'Containers/ProfilePage/ProfileAudio';
import { EnrollmentCard } from 'ContentfulDefaults/types/profile';
import { useProfileContext } from 'Contexts/profileContext';
import { useTextContext } from 'Contexts/textContext';
import ToggleList, { ToggleListOption } from 'DesignLibrary/atoms/ToggleList';
import { Paragraph } from 'DesignLibrary/atoms/typography';
import { sendInsightsEvent } from 'Utils/insights';
import { getEnvironmentFromUrl } from 'Utils/urls';

import { EnrollmentContainer } from './styled';
import Question from '../_shared/Question';
import { QuestionWrapper } from '../_shared/Question/styled';
import { Survey } from '../types';

export interface EnrollmentSectionProps {
  survey: Survey;
  isDirty: boolean;
  isDuringOE: boolean;
  isProfileAudioEnabled: boolean;
  hasActivePublication: boolean;
  hasUpcomingPublication: boolean;
  handleSaveEnrollmentAnswer: (value: string | '') => void;
  handleSectionChange: (isValid: boolean, isComplete: boolean) => void;
  setHiddenAlert: (alert: string) => void;
  handleSetEnrollmentEventType: (value: string | null) => void;
}

export const EnrollmentSection: FC<EnrollmentSectionProps> = ({
  survey,
  isDirty,
  isDuringOE,
  isProfileAudioEnabled,
  hasActivePublication,
  hasUpcomingPublication,
  handleSaveEnrollmentAnswer,
  handleSectionChange,
  handleSetEnrollmentEventType,
  setHiddenAlert,
}) => {
  const { retrieveContentfulData } = useTextContext();
  const { getSectionFromMap } = useProfileContext();

  const enrollmentCard = retrieveContentfulData<EnrollmentCard>('profile_section.enrollment');

  const getOptions = () => {
    const options: ToggleListOption[] = [];

    if (hasActivePublication) {
      options.push(
        ...[
          {
            option: enrollmentCard.enrollment_reason_new_hire,
            value: 'active-new',
          },
          {
            option: enrollmentCard.enrollment_reason_qle,
            value: 'active-qle',
          },
          {
            option: enrollmentCard.enrollment_reason_info_current_year,
            value: 'active-current',
          },
        ],
      );
    }

    if (hasUpcomingPublication) {
      if (isDuringOE) {
        options.unshift({
          option: enrollmentCard.enrollment_reason_open_enrollment,
          value: 'upcoming-oe',
          styleClass: 'open-enrollment',
        });
      } else {
        options.push({
          option: enrollmentCard.enrollment_reason_info_next_year,
          value: 'upcoming-next',
        });
      }
    }

    return options;
  };

  useEffect(() => {
    const alertText = retrieveContentfulData<string>('profile_section.accessibility.unlocked_section', '');
    setHiddenAlert(alertText.replace(/{x}/, enrollmentCard.name));
  }, []);

  useEffect(() => {
    const section = getSectionFromMap('enrollment');
    const isComplete = section?.isComplete || validateForm();
    handleSectionChange(validateForm(), isComplete);
  }, [survey?.enrollment_question]);

  // event types can be different values for tracking and BC
  const getEventType = (isInsightsEvent: boolean, value: string) => {
    let eventType = '';

    if (value.includes('new')) {
      eventType = 'new_hire';
    }

    if (value.includes('qle')) {
      if (isInsightsEvent) {
        eventType = 'qualified_life_event';
      }
      // For BC
      eventType = 'qualifying_life_event';
    }
    if (value.includes('current') || value.includes('next')) {
      if (isInsightsEvent) {
        eventType = 'informational';
      }
      // For BC
      eventType = 'none';
    }

    if (value.includes('oe')) {
      eventType = 'open_enrollment';
    }

    return eventType;
  };

  const handleChange = (value) => {
    handleSaveEnrollmentAnswer(value);
    handleSetEnrollmentEventType(getEventType(false, value));

    sendInsightsEvent(null, 'enrollment_question_event_type', {
      event_type: getEventType(true, value),
    });
  };
  function validateForm() {
    return survey?.enrollment_question !== '';
  }

  return (
    <div id="enrollment-section" data-testid="enrollment-section">
      <Question
        name="enrollment"
        error={isDirty && survey?.enrollment_question === ''}
        isIncomplete={!validateForm()}
        audioTooltipsEnabled={isProfileAudioEnabled}
        audioTooltipContentfulPath={AudioConfigTooltipContentfulPaths.EnrollmentSectionAudioTooltip}
      >
        <EnrollmentReasonExplainer
          deployEnvironment={getEnvironmentFromUrl()}
          hasActivePublication={hasActivePublication}
          hasUpcomingPublication={hasUpcomingPublication}
          isDuringOE={isDuringOE}
        />
        <QuestionWrapper className="question-wrapper">
          <Paragraph>{enrollmentCard && enrollmentCard.enrollment_reason_question}</Paragraph>
          <EnrollmentContainer>
            <ToggleList
              label={enrollmentCard.enrollment_reason_question}
              groupName="enrollmentQuestion"
              options={getOptions()}
              handleChange={(e) => handleChange(e.target.value)}
              color="--colors-gray-black"
              selected={survey?.enrollment_question}
              layout="column"
              stretch
            />
          </EnrollmentContainer>
        </QuestionWrapper>
      </Question>
    </div>
  );
};

export default EnrollmentSection;
