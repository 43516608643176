import styled from 'styled-components';

import { ParagraphMiniStyle } from 'DesignLibrary/atoms';
import { media } from 'DesignLibrary/global';
import { cardStyling } from 'DesignLibrary/utils';

export const CoveredMembersContainer = styled.div`
  ${cardStyling}
  position: relative;
  overflow: hidden;

  .coverage-group {
    padding: var(--sizing-sm);
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: var(--sizing-xs);

    ${media.TABLET`
      padding: var(--sizing-md);
      grid-template-columns: repeat(2, 1fr);
    `}

    input[type="checkbox"]:disabled + .pseudo-checkbox {
      opacity: 1;
      cursor: not-allowed;
    }

    .pseudo-checkbox {
      padding: var(--sizing-sm);
      border-radius: var(--border-radius-xs);

      &:hover {
        background: var(--background-gray-light);
      }

      &.checked {
        background: var(--background-gray);
      }

      .age-gender {
        ${ParagraphMiniStyle('--text-gray')}
      }
    }
  }
`;

export const LoadingIndicator = styled.div`
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--primary-white);
  display: flex;
  align-items: center;
  justify-content: center;
`;
