import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { makeSelectCommercialField, makeSelectConfigField } from 'Containers/App/selectors';
import { putXhrElections } from 'Containers/CommercialRoutes/saga';
import {
  getSupplementalPlansFailure,
  getSupplementalPlansSuccessSetCache,
  resetAutoEnrolled,
  trackSuppEnrollmentFailure,
  trackSuppEnrollmentSuccess,
} from 'Containers/OverviewPage/actions';
import {
  GET_SUPPLEMENTAL_PLANS_FOR_VIEW_BENEFIT_REQUEST,
  REMOVE_PLAN,
  SELECT_PLAN,
} from 'Containers/OverviewPage/constants';
import {
  makeSelectOverviewField,
  makeSelectSupplementalPlansForMemberIdsFromCache,
} from 'Containers/OverviewPage/selectors';
import { makeGetSelectedEnrollmentEventId, makeGetHouseholdId } from 'Containers/ProfilePage/selectors';
import { enrollSupplemental, getSupplementalPlansRequest } from 'Utils/api';
import * as API_TYPES from 'Utils/apiTypes';

export function* postXhrPlansEnrollment(action) {
  const builderCustomerKey = yield select(makeSelectConfigField('builder_customer_key'));
  const selectedSupplementalPlans = yield select(makeSelectOverviewField('selected_plans'));
  const coveredMembers = action.coveredMembers;
  const enrollments = {};

  Object.keys(selectedSupplementalPlans).forEach((insuranceType) => {
    const plan = selectedSupplementalPlans[insuranceType];
    if (plan) {
      const memberIds = Object.keys(coveredMembers[insuranceType]).filter(
        (memberId) => coveredMembers[insuranceType][memberId].covered,
      );

      enrollments[plan.external_id] = memberIds;
    }
  });

  try {
    // Get previously stored household id and send it with request if it exists
    const enrollmentEventId = yield select(makeGetSelectedEnrollmentEventId());
    const householdId = yield select(makeGetHouseholdId());

    const resp: API_TYPES.UpdateHouseholdResponse = yield call(
      enrollSupplemental,
      enrollments,
      householdId,
      enrollmentEventId,
    );
    yield put(trackSuppEnrollmentSuccess(resp));
    // Send updated elections if dental or vision elections change
    if (builderCustomerKey && (action.insuranceType === 'dental' || action.insuranceType === 'vision')) {
      yield call(putXhrElections, false);
    }
  } catch (err) {
    yield put(trackSuppEnrollmentFailure(err as Error));
  }
}

export function* removeAutoEnrollPlanSaga(action) {
  const supplementalPlans = yield select(makeSelectOverviewField('supplemental_plans_full_household'));

  // Set auto enrolled plans as default selected plans
  yield put(resetAutoEnrolled(action.insuranceType, supplementalPlans));
  // Send updated elections if dental or vision elections change
  if (action.insuranceType === 'dental' || action.insuranceType === 'vision') {
    yield call(putXhrElections, false);
  }
}

// gets supplemental plans for explicit member id's
export function* getXhrSupplementalPlans(action) {
  const enrollmentEventId = yield select(makeGetSelectedEnrollmentEventId());
  const householdId = yield select(makeGetHouseholdId());
  const selectedHealthPlan = yield select(makeSelectCommercialField('selectedHealthPlan'));
  const selectedHealthPlanId = selectedHealthPlan?.plan?.external_id || null;

  const isPlansInCache = yield select(makeSelectSupplementalPlansForMemberIdsFromCache(action.memberIds));

  // Make request if we haven't yet cached the response for the selected member ids
  if (!isPlansInCache) {
    try {
      const resp: API_TYPES.GetSupplementalPlansResponse = yield call(
        getSupplementalPlansRequest,
        householdId,
        action.memberIds,
        selectedHealthPlanId,
        enrollmentEventId,
      );

      yield put(getSupplementalPlansSuccessSetCache(resp, action.memberIds));
    } catch (err) {
      yield put(getSupplementalPlansFailure(err as Error));
    }
  }
}

export default function* getViewBenefitPageSaga() {
  yield all([
    takeLatest(SELECT_PLAN, postXhrPlansEnrollment),
    takeLatest(REMOVE_PLAN, removeAutoEnrollPlanSaga),
    takeLatest(GET_SUPPLEMENTAL_PLANS_FOR_VIEW_BENEFIT_REQUEST, getXhrSupplementalPlans),
  ]);
}
