import { get } from 'lodash';
import React from 'react';

import { ContentfulPlanDisclaimer, PlanCustomization } from 'ContentfulDefaults/types/customizations';
import { ContentfulTextNote } from 'ContentfulWrappers/ContentfulTextNote';
import { useTextContext } from 'Contexts/textContext';
import InfoBar from 'DesignLibrary/molecules/InfoBar';
import Plan from 'Models/plan';
import { createHraTooltip, createHsaTooltip, createSihraTooltip } from 'Utils/createTooltips';
import { toDollars } from 'Utils/helpers';

import { AccountInfobar } from './AccountInfobar';

interface DisclaimersProps {
  plan: Plan;
  employerHsaContribution?: number | null;
  maximumAvailableAnnualSihraContribution: number;
  isEntered: boolean;
  hsaContributionLimit: number;
  // fsaEligible: boolean;
}

export const Disclaimers = ({
  plan,
  employerHsaContribution,
  maximumAvailableAnnualSihraContribution,
  isEntered,
  hsaContributionLimit,
}: DisclaimersProps) => {
  const { planId, hsaEligible, hraEligible, employerHraContribution, annualSihraDollarsUsed } = plan || {};

  // Get disclaimers from Contentful
  const { retrieveContentfulData } = useTextContext();
  const customizations = retrieveContentfulData<PlanCustomization[]>('plan_customizations', []);
  const planCustomizationsIndex = customizations.findIndex((item) => item.plan_id === planId);
  const planCustomizations = planCustomizationsIndex >= 0 ? customizations[planCustomizationsIndex] : null;

  const disclaimers: ContentfulPlanDisclaimer[] = get(planCustomizations, 'result_page_disclaimers', []);

  const renderHsaBar = () => {
    if (hsaEligible) {
      const hsaTooltip = {
        ...createHsaTooltip(
          plan.isEmployerMatching,
          plan.calcEmployerHsaContribution(hsaContributionLimit),
          plan.employerHsaContributionLimit,
          plan.employerHsaPercentageMatch,
        ),
        underline: '',
      };
      if (plan.isEmployerMatching) {
        return (
          <div data-testid="hsa-info-bar-with-employer-match">
            <AccountInfobar
              type="hsa"
              field="health_section.recommendation.hsa_info_with_employer_match"
              vars={[
                `${plan.employerHsaPercentageMatch}%` || 0,
                `$${plan.employerHsaContributionLimit}` || 0,
              ]}
              tooltips={[hsaTooltip, hsaTooltip]}
            />
          </div>
        );
      }
      if (employerHsaContribution && employerHsaContribution > 0) {
        return (
          <div data-testid="hsa-info-bar-with-contribution">
            <AccountInfobar
              type="hsa"
              field="health_section.recommendation.hsa_info_with_contribution"
              vars={[toDollars(employerHsaContribution)]}
              tooltips={[hsaTooltip]}
            />
          </div>
        );
      }

      return (
        <div data-testid="hsa-info-bar-without-contribution">
          <AccountInfobar type="hsa" field="health_section.recommendation.hsa_info" />
        </div>
      );
    }

    return null;
  };

  const renderHraBar = () => {
    if (hraEligible) {
      const noContribution = !employerHraContribution
        ? 'health_section.eligibility_text.hra.standardPlan'
        : null;

      const hraTooltip = {
        ...createHraTooltip(plan.employerHraContribution),
        underline: '',
      };

      return (
        <div data-testid="hra-info-bar-with-contribution">
          <AccountInfobar
            type="hra"
            field="health_section.recommendation.hra_info_with_contribution"
            vars={[toDollars(employerHraContribution)]}
            tooltips={[hraTooltip]}
            textOverride={noContribution}
          />
        </div>
      );
    }

    return null;
  };

  const shouldRenderSihraBar =
    isEntered &&
    annualSihraDollarsUsed &&
    annualSihraDollarsUsed > 0 &&
    maximumAvailableAnnualSihraContribution > 0;

  const renderSihraBar = () => {
    if (shouldRenderSihraBar) {
      const sihraTooltip = {
        ...createSihraTooltip(maximumAvailableAnnualSihraContribution),
        underline: '',
      };
      return (
        <div data-testid="sihra-info-bar-with-contribution">
          <AccountInfobar
            type="hra"
            field="health_section.recommendation.sihra_info_with_contribution"
            vars={[toDollars(maximumAvailableAnnualSihraContribution)]}
            tooltips={[sihraTooltip]}
          />
        </div>
      );
    }

    return null;
  };

  const renderTextNote = () => {
    if (planCustomizations) {
      return <ContentfulTextNote sectionKey="plan_customizations" sectionIndex={planCustomizationsIndex} />;
    }
    return null;
  };

  const disclaimerBars = disclaimers.map((disclaimer, disclaimerIdx) => (
    <InfoBar
      color={disclaimer.barColor || 'yellow'}
      header={disclaimer.header}
      text={disclaimer.description}
      key={`disclaimer-${disclaimerIdx}`}
      showAccent={false}
    />
  ));

  return (
    <>
      {renderHsaBar()}
      {renderHraBar()}
      {renderSihraBar()}
      {disclaimerBars}
      {renderTextNote()}
    </>
  );
};
