import { debounce } from 'lodash';
import React, { useState, useEffect, useRef, useCallback } from 'react';

import { useTextContext } from 'Contexts/textContext';
import TextInput from 'DesignLibrary/atoms/inputs/TextInput';
import { AriaHiddenAlert } from 'Utils/accessibility';

import { Wrapper } from './styled';
import { Suggestion, SuggestionsList } from './SuggestionsList';

interface DrugSearchProps {
  isLoading: boolean;
  suggestions: Suggestion[];
  placeholder: string;
  isDisabled: boolean;
  isMemberComplete: boolean;
  labelId: string;
  queryDrugs: (value: string) => void;
  handleAddDrug: (key: string, displayName: string, drugForm: string) => void;
}

const DrugSearch = ({
  isLoading,
  suggestions,
  placeholder,
  isDisabled,
  isMemberComplete,
  labelId,
  handleAddDrug,
  queryDrugs,
}: DrugSearchProps) => {
  const searchRef = useRef<HTMLInputElement>(null);
  const [state, setState] = useState({
    userInput: '',
    showSuggestions: false,
    activeSuggestion: 0,
  });
  const [hiddenAlert, setHiddenAlert] = useState<string | null>(null);
  const [showLoading, setShowLoading] = useState<boolean>(false);

  const { retrieveContentfulData } = useTextContext();

  useEffect(() => {
    setShowLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    // focus on search bar on first DidMount
    if (!isMemberComplete && searchRef.current) {
      searchRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (suggestions.length > 0) {
      const numberOfResultsAlert = retrieveContentfulData<string>(
        'profile_section.member.accessibility.drug_search_results_alert',
      );
      setHiddenAlert(numberOfResultsAlert.replace(/{x}/, suggestions.length.toString()));
    }
  }, [suggestions]);

  const onFocus = () => {
    setState({ ...state, showSuggestions: true });
  };

  const onBlur = () => {
    setState({ ...state, showSuggestions: false });
  };

  const onChange = (e) => {
    setState({
      activeSuggestion: 0,
      showSuggestions: true,
      userInput: e.target.value,
    });

    if (e.target.value.length > 2) {
      setShowLoading(true);
      drugSearchThrottled(e.target.value);
    }
  };

  const onDrugClick = (e, idx) => {
    const ndc = suggestions[idx].key;
    const displayName = suggestions[idx].title;
    const drugForm = suggestions[idx].description;

    setState({
      activeSuggestion: 0,
      showSuggestions: false,
      userInput: '',
    });

    // set focus back to drug search
    if (searchRef.current) {
      searchRef.current.focus();
    }

    // Add drug row
    handleAddDrug(ndc, displayName, drugForm);
  };

  const onKeyDown = (e) => {
    const { activeSuggestion, showSuggestions } = state;
    // Only allow suggestion selection when showSuggestions is true and we have actual drug search results to show
    if (showSuggestions && suggestions.length > 0) {
      if (e.key === 'Enter') {
        // Set selected drug on reducer
        const ndc = suggestions[activeSuggestion].key;
        const displayName = suggestions[activeSuggestion].title;
        const drugForm = suggestions[activeSuggestion].description;
        handleAddDrug(ndc, displayName, drugForm);

        // When user selects a drug, reset active suggestions and update selection
        setState({
          activeSuggestion: 0,
          showSuggestions: false,
          userInput: '',
        });
      } else if (e.key === 'ArrowUp') {
        // User presses up arrow and no activeSuggestion or first index is the activeSuggestion do not drecrement active index
        if (activeSuggestion <= 0) {
          return;
        }
        // User presses up arrow, decrement active index
        setState({ ...state, activeSuggestion: activeSuggestion - 1 });
      } else if (e.key === 'ArrowDown') {
        // User presses down arrow and no activeSuggestion or last index is the activeSuggestion do not increment active index
        if (activeSuggestion + 1 === suggestions.length) {
          return;
        }
        // User presses down arrow, increment active index
        setState({ ...state, activeSuggestion: activeSuggestion + 1 });
      }
    }
  };

  const drugSearchThrottled = useCallback(
    debounce((value) => queryDrugs(value), 500),
    [],
  );

  const { showSuggestions, userInput, activeSuggestion } = state;

  const suggestionsListVisible = showSuggestions && userInput;

  return (
    <Wrapper>
      <TextInput
        role="combobox"
        aria-labelledby={labelId}
        aria-autocomplete="none"
        aria-expanded={Boolean(suggestionsListVisible)}
        aria-controls="drug-list-suggestions"
        aria-activedescendant={
          suggestionsListVisible
            ? `drug-suggestion-${activeSuggestion}`
            : '' /* activedescendant being set only makes sense when the combobox has focus and the suggestion list is visible */
        }
        ref={searchRef}
        className="med-search-input"
        data-testid="med-search-input"
        inputMode="text"
        handleChange={onChange}
        onKeyDown={onKeyDown}
        onFocus={onFocus}
        onBlur={onBlur}
        value={state.userInput}
        placeholder={placeholder}
        disabled={isDisabled}
        inputType="icon"
        iconType="Search"
        stretch
      />
      <AriaHiddenAlert>{hiddenAlert}</AriaHiddenAlert>
      {suggestionsListVisible && (
        <SuggestionsList
          showLoading={showLoading}
          userInput={userInput}
          suggestions={suggestions}
          activeSuggestion={activeSuggestion}
          onDrugClick={onDrugClick}
        />
      )}
    </Wrapper>
  );
};

export default DrugSearch;
