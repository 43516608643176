import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer, { Config } from 'react-player';
import UAParser from 'ua-parser-js';

import { useViewport } from 'DesignLibrary/context';
import { sendInsightsEvent } from 'Utils/insights';

export interface AudioProps {
  title: string;
  fullWidth?: boolean;
  url: string;
  playing?: boolean;
  loop?: boolean;
  /*
  Volume is a value between 0 and 1, where 1 is 100%.
   */
  volume?: number;
  muted?: boolean;
  testId?: string;
  closedCaptions?: boolean;
  closedCaptionVtt?: string;
  allowCrossOrigin?: boolean;
  className?: string;
  onStart?: () => void;
  onEnded?: () => void;
}

const Audio = ({
  title,
  fullWidth = false,
  url,
  playing = false,
  loop = false,
  volume = 1,
  muted,
  closedCaptions,
  closedCaptionVtt,
  allowCrossOrigin = false,
  testId = 'react-player-audio',
  className,
  onStart,
  onEnded,
  ...rest
}: AudioProps): JSX.Element => {
  const [reactPlayerReady, setReactPlayerReady] = useState(false);
  const { device } = useViewport();
  const { browser } = UAParser();
  const isMobile = device === 'mobile';
  const isFirefox = browser.name === 'Firefox';

  const handleStart = (): void => {
    sendInsightsEvent(null, 'audio_started', {
      title,
      url,
    });
    if (onStart) onStart();
  };

  const handleEnd = (): void => {
    sendInsightsEvent(null, 'audio_ended', {
      title,
      url,
    });
    if (onEnded) onEnded();
  };

  const reactPlayerRef = useRef(null);

  const changeClosedCaptionsDisplay = () => {
    const current = reactPlayerRef.current as any;
    if (current) {
      const video = current.getInternalPlayer();
      if (!video || !video.textTracks) {
        return;
      }
      if (video.textTracks.length > 0) {
        const videoTextTracks = video.textTracks[0];
        if (closedCaptions) {
          videoTextTracks.mode = 'showing';
        } else {
          videoTextTracks.mode = 'hidden';
        }
      }
    }
  };

  useEffect(() => {
    if (reactPlayerReady) {
      changeClosedCaptionsDisplay();
      // On unmount, remove existing subs in case they stuck
      return () => {
        const current = reactPlayerRef.current as any;
        if (current && !isFirefox) {
          const video = current.getInternalPlayer();
          if (!video || !video.textTracks || video.textTracks.length === 0) {
            return;
          }
          const videoTextTracks = video.textTracks[0];
          if (videoTextTracks) {
            videoTextTracks.mode = 'disabled';
          }
        }
      };
    }
    return () => {}; // For consistency/lint
  }, [closedCaptions, closedCaptionVtt, reactPlayerReady]);

  const buildReactPlayerConfig = (allowCrossOrigin?: boolean, closedCaptionVtt?: string): Config => {
    const haveClosedCaptions = !!closedCaptionVtt;

    // force video in the event we know we have CC to render.
    // if we don't, we'll let the source file dictate.
    const forceVideo = haveClosedCaptions;

    const ccTracks = haveClosedCaptions
      ? [
          {
            kind: 'captions',
            src: closedCaptionVtt || '',
            srcLang: 'en',
            default: true,
            label: 'Profile audio (en)',
          },
        ]
      : [];

    const reactPlayerConfig: Config = {
      file: {
        forceVideo,
        tracks: ccTracks,
      },
    };

    if (allowCrossOrigin) {
      reactPlayerConfig.file = {
        ...reactPlayerConfig.file,
        attributes: {
          crossOrigin: 'anonymous',
        },
      };
    }

    return reactPlayerConfig;
  };

  return (
    <ReactPlayer
      id="react-player"
      ref={reactPlayerRef}
      data-testid={testId}
      url={url}
      width={fullWidth || isMobile ? '100%' : undefined}
      playing={playing}
      loop={loop}
      volume={volume} // Must be set to enable muted.
      muted={muted}
      onStart={handleStart}
      onEnded={handleEnd}
      onReady={() => setReactPlayerReady(true)}
      config={buildReactPlayerConfig(allowCrossOrigin, closedCaptionVtt)}
      className={className}
      {...rest}
    />
  );
};

export default Audio;
