import React from 'react';

import { ContentfulReviewSection } from 'ContentfulDefaults/types/review';
import { useTextContext } from 'Contexts/textContext';
import { Button, H4, Paragraph } from 'DesignLibrary/atoms';
import { IconBlock } from 'DesignLibrary/molecules/IconBlock';
import RichText from 'Shared/RichText';
import Text from 'Shared/Text';

import { Container, CtaCard, NextStepsHeader } from './styled';

export interface NextStepsProps {
  enrollmentUrl: string;
  trackEnroll: () => void;
}

const NextSteps = ({ enrollmentUrl, trackEnroll }: NextStepsProps) => {
  const { retrieveContentfulData } = useTextContext();

  const usernameHint = retrieveContentfulData<string>('review_section.username_hint');
  const passwordHint = retrieveContentfulData<string>('review_section.password_hint');

  const hrPhoneNumber = retrieveContentfulData<string>('review_section.phone_number');
  const hrEmailAddress = retrieveContentfulData<string>('review_section.email');

  const nextStepFields = retrieveContentfulData<ContentfulReviewSection['next_step_fields']>(
    'review_section.next_step_fields',
    {} as ContentfulReviewSection['next_step_fields'],
  );

  const pdfLink = retrieveContentfulData<string>('review_section.pdf_link');

  const openPdf = () => {
    window.open(pdfLink, '_blank')?.focus();
  };

  return (
    <Container id="enroll-section">
      <NextStepsHeader>
        <IconBlock icon="ListBullets" color="blue" />
        <H4 as="h3">
          <Text field="review_section.next_steps_header" />
        </H4>
      </NextStepsHeader>

      <section className="next-step-instructions">
        <Paragraph>
          <RichText field="review_section.next_step_instructions" />
        </Paragraph>
      </section>

      <section className="contact-info">
        {usernameHint && (
          <div id="hr-username">
            <Paragraph>
              <b>{nextStepFields.username}</b> {usernameHint}
            </Paragraph>
          </div>
        )}
        {passwordHint && (
          <div id="hr-password">
            <Paragraph>
              <b>{nextStepFields.password}</b> {passwordHint}
            </Paragraph>
          </div>
        )}
        {(hrPhoneNumber || hrEmailAddress) && (
          <>
            <Paragraph>
              <Text field="review_section.contact_hr" />
            </Paragraph>
            {hrPhoneNumber && (
              <div id="hr-phone-number">
                <Paragraph>
                  <b>{nextStepFields.phone}</b> {hrPhoneNumber}
                </Paragraph>
              </div>
            )}
            {hrEmailAddress && (
              <div id="hr-email-address">
                <Paragraph>
                  <b>{nextStepFields.email}</b> {hrEmailAddress}
                </Paragraph>
              </div>
            )}
          </>
        )}
      </section>
      <section className="cards no-print">
        {pdfLink && (
          <CtaCard type="pdf">
            <Paragraph>
              <Text field="review_section.more_info_heading" />
            </Paragraph>

            <Button id="more-info-btn" onClick={openPdf} iconRight="ArrowUpRight">
              <Text field="review_section.button_text.PDFLink" />
            </Button>
          </CtaCard>
        )}
        {enrollmentUrl && (
          <CtaCard type="enroll">
            <div>
              <H4>
                <Text field="review_section.enrollment_title" />
              </H4>
              <Paragraph>
                <Text field="review_section.enroll_instructions" />
              </Paragraph>
            </div>

            <Button
              buttonType="primary"
              id="enroll-btn"
              url={enrollmentUrl}
              onClick={trackEnroll}
              iconRight="ArrowUpRight"
            >
              <Text field="review_section.button_text.enroll" />
            </Button>
          </CtaCard>
        )}
      </section>
    </Container>
  );
};

export default NextSteps;
