import styled, { css } from 'styled-components';

import { ButtonStyle } from 'DesignLibrary/atoms/Button/styled';
import { media } from 'DesignLibrary/global';

interface SwitchButtonProps {
  isSelected: boolean;
}

export const Container = styled.div`
  display: flex;
  gap: var(--sizing-xs);
  padding: var(--sizing-sm);

  ${media.TABLET`
    flex-direction: row;
    padding: var(--sizing-md);
  `}
`;

export const SwitchButton = styled(ButtonStyle)<SwitchButtonProps>`
  width: 100%;
  padding: var(--sizing-md) var(--sizing-sm);
  border: 2px solid var(--primary-blue);
  color: var(--text-black);
  background: transparent;
  border-radius: var(--border-radius-xs);

  ${media.TABLET`
    width: auto;
    min-width: calc(var(--sizing-4xl) - var(--sizing-sm));
    padding: var(--sizing-md);
  `}

  ${({ isSelected }) =>
    !isSelected &&
    css`
      border-color: transparent;
      background: var(--background-gray);
      color: var(--text-gray);
      transition: 0s border-color;

      &:hover {
        border-color: var(--primary-blue);
        background: var(--colors-blue-50);
      }
    `}

  span {
    margin-right: var(--sizing-xs);
  }
`;
