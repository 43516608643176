import React, { FC } from 'react';

import { BannerContainer } from './styled';

export interface BannerCardProps {
  imgUrl: string;
  background?: string;
  children: React.ReactNode;
}

export const BannerCard: FC<BannerCardProps> = ({ imgUrl, background, children }): JSX.Element => (
  <BannerContainer background={background}>
    <div className="img-container">
      <img src={imgUrl} alt="" />
    </div>
    <div className="content">{children}</div>
  </BannerContainer>
);
