import styled from 'styled-components';

export const DescriptionWrapper = styled.div`
  .description-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; /* Align items at the start of the container */
  }

  .left-section {
    flex: 1;
    margin-right: 10px;
  }

  .right-section {
    flex: 1;
    margin-left: 10px;
  }
`;

export const CustomWrapper = styled.div`
 .container {display: 'flex', alignItems: 'center' }

 .provider-type{fontSize: '12px', color: '#888' }
`;
