import { ContentfulAlexIntroPage } from 'ContentfulDefaults/types/alexIntro';
import { ALEX_STEPS } from 'Contexts/introContext';

export const alexIntro: {
  alex_intro_page: ContentfulAlexIntroPage;
} = {
  alex_intro_page: {
    contentful_name: '[default] Alex Intro Page',
    header: 'Intro',
    scenes: [
      {
        contentful_name: '[default] Alex Video Scene',
        key: ALEX_STEPS.ALEX_VIDEO,
        asset_type: 'video',
        asset_url: 'https://picwellapppublicdata.s3.amazonaws.com/V11_Intro_Logo.mp4',
        audio_asset_urls: [
          'https://picwellapppublicdata.s3.amazonaws.com/bc_intro_page_alex.mp3',
          'https://picwellapppublicdata.s3.amazonaws.com/bc_intro_page_courtesy.mp3',
        ],
        return_user_audio_asset_urls: [
          'https://assets.ctfassets.net/z0ugtcgrmslx/6msPI1rqBdeyOZ3K2gC16G/6b69affd0a52df314bcc173cb4a5ce8b/INTRO_0166_04202022__1_.wav',
        ],
        video_asset_url: 'https://picwellapppublicdata.s3.amazonaws.com/bc_intro_page_logo.mp4',
        transcript: "Hello, I'm ALEX. Here courtesy of your employer.",
        return_user_transcript: "Welcome back! As you might remember, I'm ALEX.",
      },
      {
        contentful_name: '[default] Sound Check Scene',
        key: ALEX_STEPS.HEAR_ME_AUDIO,
        asset_type: 'audio',
        asset_url: 'https://picwellapppublicdata.s3.amazonaws.com/Cat-meow-sound-effect.wav',
        audio_asset_urls: ['https://picwellapppublicdata.s3.amazonaws.com/bc_intro_page_soundcheck.mp3'],
        transcript:
          "Can you hear me? \u2026How about now? I'll play some tunes if you need a moment to adjust your sound. [MUSIC PLAYING]",
      },
      {
        contentful_name: '[default] Sound Off Scene',
        key: ALEX_STEPS.SOUND_OFF_AUDIO,
        asset_type: 'audio',
        asset_url: 'https://picwellapppublicdata.s3.amazonaws.com/cat-purring-40415.mp3',
        transcript: '',
      },
      {
        contentful_name: '[default] Alex Outro Scene',
        key: ALEX_STEPS.ASK_QUESTIONS_VIDEO, // TODO: Remove whole object once ff removed
        asset_type: 'video',
        asset_url: 'https://picwellapppublicdata.s3.amazonaws.com/V11_Intro_Intro.mp4',
        audio_asset_urls: [
          'https://picwellapppublicdata.s3.amazonaws.com/bc_intro_page_intro_1.mp3',
          'https://picwellapppublicdata.s3.amazonaws.com/bc_intro_page_intro_2.mp3',
          'https://picwellapppublicdata.s3.amazonaws.com/bc_intro_page_intro_3.mp3',
        ],
        video_asset_url: 'https://picwellapppublicdata.s3.amazonaws.com/bc_intro_page_intro.mp4',
        transcript:
          "Great! Because I'm going to ask you some questions\u2026help you find your best benefits\u2026and then we'll all clap in unison. [bad clapping] Okay, obviously we're not warmed up yet.",
      },
      {
        contentful_name: '[default] Alex Outro Scene',
        key: ALEX_STEPS.ASK_QUESTIONS_VIDEO1,
        asset_type: 'video',
        asset_url: 'https://picwellapppublicdata.s3.amazonaws.com/V11_Intro_Intro.mp4',
        audio_asset_urls: [
          'https://picwellapppublicdata.s3.amazonaws.com/bc_intro_page_intro_1.mp3',
          'https://picwellapppublicdata.s3.amazonaws.com/bc_intro_page_intro_2.mp3',
          'https://picwellapppublicdata.s3.amazonaws.com/bc_intro_page_intro_3.mp3',
        ],
        video_asset_url: 'https://picwellapppublicdata.s3.amazonaws.com/bc_intro_page_intro.mp4',
        transcript:
          "Great1! Because I'm going to ask you some questions\u2026help you find your best benefits\u2026and then we'll all clap in unison. [bad clapping] Okay, obviously we're not warmed up yet.",
      },
      {
        contentful_name: '[default] Alex Outro Scene',
        key: ALEX_STEPS.ASK_QUESTIONS_VIDEO2,
        asset_type: 'video',
        asset_url: 'https://picwellapppublicdata.s3.amazonaws.com/V11_Intro_Intro.mp4',
        audio_asset_urls: [
          'https://picwellapppublicdata.s3.amazonaws.com/bc_intro_page_intro_1.mp3',
          'https://picwellapppublicdata.s3.amazonaws.com/bc_intro_page_intro_2.mp3',
          'https://picwellapppublicdata.s3.amazonaws.com/bc_intro_page_intro_3.mp3',
        ],
        video_asset_url: 'https://picwellapppublicdata.s3.amazonaws.com/bc_intro_page_intro.mp4',
        transcript:
          "Great2! Because I'm going to ask you some questions\u2026help you find your best benefits\u2026and then we'll all clap in unison. [bad clapping] Okay, obviously we're not warmed up yet.",
      },
      {
        contentful_name: '[default] Alex Outro Scene',
        key: ALEX_STEPS.ASK_QUESTIONS_VIDEO3,
        asset_type: 'video',
        asset_url: 'https://picwellapppublicdata.s3.amazonaws.com/V11_Intro_Intro.mp4',
        audio_asset_urls: [
          'https://picwellapppublicdata.s3.amazonaws.com/bc_intro_page_intro_1.mp3',
          'https://picwellapppublicdata.s3.amazonaws.com/bc_intro_page_intro_2.mp3',
          'https://picwellapppublicdata.s3.amazonaws.com/bc_intro_page_intro_3.mp3',
        ],
        video_asset_url: 'https://picwellapppublicdata.s3.amazonaws.com/bc_intro_page_intro.mp4',
        transcript:
          "Great! Because I'm going to ask you some questions\u2026help you find your best benefits\u2026and then we'll all clap in unison. [bad clapping] Okay, obviously we're not warmed up yet.",
      },
      {
        contentful_name: '[default] Alex Outro Scene',
        key: ALEX_STEPS.ASK_QUESTIONS_VIDEO4,
        asset_type: 'video',
        asset_url: 'https://picwellapppublicdata.s3.amazonaws.com/V11_Intro_Intro.mp4',
        audio_asset_urls: [
          'https://picwellapppublicdata.s3.amazonaws.com/bc_intro_page_intro_1.mp3',
          'https://picwellapppublicdata.s3.amazonaws.com/bc_intro_page_intro_2.mp3',
          'https://picwellapppublicdata.s3.amazonaws.com/bc_intro_page_intro_3.mp3',
        ],
        video_asset_url: 'https://picwellapppublicdata.s3.amazonaws.com/bc_intro_page_intro.mp4',
        transcript:
          "Great! Because I'm going to ask you some questions\u2026help you find your best benefits\u2026and then we'll all clap in unison. [bad clapping] Okay, obviously we're not warmed up yet.",
      },
      {
        contentful_name: '[default] Alex Outro Scene',
        key: ALEX_STEPS.ASK_QUESTIONS_VIDEO5,
        asset_type: 'video',
        asset_url: 'https://picwellapppublicdata.s3.amazonaws.com/V11_Intro_Intro.mp4',
        audio_asset_urls: [
          'https://picwellapppublicdata.s3.amazonaws.com/bc_intro_page_intro_1.mp3',
          'https://picwellapppublicdata.s3.amazonaws.com/bc_intro_page_intro_2.mp3',
          'https://picwellapppublicdata.s3.amazonaws.com/bc_intro_page_intro_3.mp3',
        ],
        video_asset_url: 'https://picwellapppublicdata.s3.amazonaws.com/bc_intro_page_intro.mp4',
        transcript:
          "Great! Because I'm going to ask you some questions\u2026help you find your best benefits\u2026and then we'll all clap in unison. [bad clapping] Okay, obviously we're not warmed up yet.",
      },
    ],
    scenes2: [
      {
        contentful_name: '[default] Alex Video Scene',
        key: ALEX_STEPS.ALEX_VIDEO,
        asset_type: 'video',
        asset_url: 'https://picwellapppublicdata.s3.amazonaws.com/V11_Intro_Logo.mp4',
        audio_asset_urls: [
          'https://picwellapppublicdata.s3.amazonaws.com/bc_intro_page_alex.mp3',
          'https://picwellapppublicdata.s3.amazonaws.com/bc_intro_page_courtesy.mp3',
        ],
        video_asset_url: 'https://picwellapppublicdata.s3.amazonaws.com/bc_intro_page_logo.mp4',
        transcript: "Hello, I'm ALEX. Here courtesy of your employer.",
        return_user_audio_asset_urls: [
          'https://assets.ctfassets.net/z0ugtcgrmslx/6msPI1rqBdeyOZ3K2gC16G/6b69affd0a52df314bcc173cb4a5ce8b/INTRO_0166_04202022__1_.wav',
        ],
        return_user_transcript: "Welcome back! As you might remember, I'm ALEX.",
      },
      {
        contentful_name: '[default] Sound Check Scene',
        key: ALEX_STEPS.HEAR_ME_AUDIO,
        asset_type: 'audio',
        asset_url: 'https://picwellapppublicdata.s3.amazonaws.com/Cat-meow-sound-effect.wav',
        audio_asset_urls: ['https://picwellapppublicdata.s3.amazonaws.com/bc_intro_page_soundcheck.mp3'],
        transcript:
          "Can you hear me? \u2026How about now? I'll play some tunes if you need a moment to adjust your sound. [MUSIC PLAYING]",
      },
      {
        contentful_name: '[default] Sound Off Scene',
        key: ALEX_STEPS.SOUND_OFF_AUDIO,
        asset_type: 'audio',
        asset_url: 'https://picwellapppublicdata.s3.amazonaws.com/cat-purring-40415.mp3',
        transcript: '',
      },
      {
        contentful_name: '[default] Alex Outro Scene',
        key: ALEX_STEPS.ASK_QUESTIONS_VIDEO, // TODO: Remove whole object once ff removed
        asset_type: 'video',
        asset_url: 'https://picwellapppublicdata.s3.amazonaws.com/V11_Intro_Intro.mp4',
        audio_asset_urls: [
          'https://picwellapppublicdata.s3.amazonaws.com/bc_intro_page_intro_1.mp3',
          'https://picwellapppublicdata.s3.amazonaws.com/bc_intro_page_intro_2.mp3',
          'https://picwellapppublicdata.s3.amazonaws.com/bc_intro_page_intro_3.mp3',
        ],
        video_asset_url: 'https://picwellapppublicdata.s3.amazonaws.com/bc_intro_page_intro.mp4',
        transcript:
          "Great! Because I'm going to ask you some questions\u2026help you find your best benefits\u2026and then we'll all clap in unison. [bad clapping] Okay, obviously we're not warmed up yet.",
      },
      {
        contentful_name: '[default] Alex Outro Scene',
        key: ALEX_STEPS.ASK_QUESTIONS_VIDEO1,
        asset_type: 'video',
        asset_url: 'https://picwellapppublicdata.s3.amazonaws.com/V11_Intro_Intro.mp4',
        audio_asset_urls: [
          'https://picwellapppublicdata.s3.amazonaws.com/bc_intro_page_intro_1.mp3',
          'https://picwellapppublicdata.s3.amazonaws.com/bc_intro_page_intro_2.mp3',
          'https://picwellapppublicdata.s3.amazonaws.com/bc_intro_page_intro_3.mp3',
        ],
        video_asset_url: 'https://picwellapppublicdata.s3.amazonaws.com/bc_intro_page_intro.mp4',
        transcript:
          "Great1! Because I'm going to ask you some questions\u2026help you find your best benefits\u2026and then we'll all clap in unison. [bad clapping] Okay, obviously we're not warmed up yet.",
      },
      {
        contentful_name: '[default] Alex Outro Scene',
        key: ALEX_STEPS.ASK_QUESTIONS_VIDEO2,
        asset_type: 'video',
        asset_url: 'https://picwellapppublicdata.s3.amazonaws.com/V11_Intro_Intro.mp4',
        audio_asset_urls: [
          'https://picwellapppublicdata.s3.amazonaws.com/bc_intro_page_intro_1.mp3',
          'https://picwellapppublicdata.s3.amazonaws.com/bc_intro_page_intro_2.mp3',
          'https://picwellapppublicdata.s3.amazonaws.com/bc_intro_page_intro_3.mp3',
        ],
        video_asset_url: 'https://picwellapppublicdata.s3.amazonaws.com/bc_intro_page_intro.mp4',
        transcript:
          "Great2! Because I'm going to ask you some questions\u2026help you find your best benefits\u2026and then we'll all clap in unison. [bad clapping] Okay, obviously we're not warmed up yet.",
      },
      {
        contentful_name: '[default] Alex Outro Scene',
        key: ALEX_STEPS.ASK_QUESTIONS_VIDEO3,
        asset_type: 'video',
        asset_url: 'https://picwellapppublicdata.s3.amazonaws.com/V11_Intro_Intro.mp4',
        audio_asset_urls: [
          'https://picwellapppublicdata.s3.amazonaws.com/bc_intro_page_intro_1.mp3',
          'https://picwellapppublicdata.s3.amazonaws.com/bc_intro_page_intro_2.mp3',
          'https://picwellapppublicdata.s3.amazonaws.com/bc_intro_page_intro_3.mp3',
        ],
        video_asset_url: 'https://picwellapppublicdata.s3.amazonaws.com/bc_intro_page_intro.mp4',
        transcript:
          "Great! Because I'm going to ask you some questions\u2026help you find your best benefits\u2026and then we'll all clap in unison. [bad clapping] Okay, obviously we're not warmed up yet.",
      },
      {
        contentful_name: '[default] Alex Outro Scene',
        key: ALEX_STEPS.ASK_QUESTIONS_VIDEO4,
        asset_type: 'video',
        asset_url: 'https://picwellapppublicdata.s3.amazonaws.com/V11_Intro_Intro.mp4',
        audio_asset_urls: [
          'https://picwellapppublicdata.s3.amazonaws.com/bc_intro_page_intro_1.mp3',
          'https://picwellapppublicdata.s3.amazonaws.com/bc_intro_page_intro_2.mp3',
          'https://picwellapppublicdata.s3.amazonaws.com/bc_intro_page_intro_3.mp3',
        ],
        video_asset_url: 'https://picwellapppublicdata.s3.amazonaws.com/bc_intro_page_intro.mp4',
        transcript:
          "Great! Because I'm going to ask you some questions\u2026help you find your best benefits\u2026and then we'll all clap in unison. [bad clapping] Okay, obviously we're not warmed up yet.",
      },
      {
        contentful_name: '[default] Alex Outro Scene',
        key: ALEX_STEPS.ASK_QUESTIONS_VIDEO5,
        asset_type: 'video',
        asset_url: 'https://picwellapppublicdata.s3.amazonaws.com/V11_Intro_Intro.mp4',
        audio_asset_urls: [
          'https://picwellapppublicdata.s3.amazonaws.com/bc_intro_page_intro_1.mp3',
          'https://picwellapppublicdata.s3.amazonaws.com/bc_intro_page_intro_2.mp3',
          'https://picwellapppublicdata.s3.amazonaws.com/bc_intro_page_intro_3.mp3',
        ],
        video_asset_url: 'https://picwellapppublicdata.s3.amazonaws.com/bc_intro_page_intro.mp4',
        transcript:
          "Great! Because I'm going to ask you some questions\u2026help you find your best benefits\u2026and then we'll all clap in unison. [bad clapping] Okay, obviously we're not warmed up yet.",
      },
    ],
    sound_check_title: 'Can you hear me?',
    sound_check_buttons: ['Yes, I hear you.', "No, I can't hear you.", "I'd rather not use sound right now."],
    sound_off_text: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                "This experience uses sound to help you choose all of your benefits.\n\nIf you'd prefer an audio-free overview of your benefits, you can get help in a text-based experience instead.\n\nOtherwise, please turn up your sound or turn on closed captions using the CC button above before we continue.",
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    sound_off_text_without_go: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'This experience uses sound to help you choose all of your benefits.\n\nPlease turn up your sound or turn on closed captions using the CC button above before we continue.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    sound_off_buttons: [
      'get help in a text-based experience instead.',
      'Continue (with sound or closed captions)',
    ],
  },
};
