import styled from 'styled-components';

import { cardStyling } from 'DesignLibrary/utils';

export const MemberWrapper = styled.div`
  ${cardStyling};
`;

export const MobileHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--sizing-sm);

  .title {
    display: flex;
    align-items: center;
    gap: var(--sizing-xs);
  }
`;

export const MemberDivider = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 1px solid var(--border-gray);
`;
