import React, { forwardRef, Ref } from 'react';

import { H6 } from 'DesignLibrary/atoms';

import { Container } from './styled';

interface CostIconProps extends React.HTMLAttributes<HTMLElement> {
  isActive: boolean;
  displayText: string;
  svg: JSX.Element;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const CostIcon = forwardRef(
  ({ isActive, displayText, svg, onClick, ...rest }: CostIconProps, ref: Ref<HTMLElement>) => (
    <Container ref={ref} onClick={onClick} isActive={isActive} {...rest}>
      <span aria-hidden>{svg}</span>
      <H6 color={isActive ? '--text-black' : '--text-gray'} as="span">
        {displayText}
      </H6>
    </Container>
  ),
);

CostIcon.displayName = 'CostIcon';
