import { Box, Flex, ModalFooter } from '@chakra-ui/react';
import React from 'react';

import { useTextContext } from 'Contexts/textContext';
import { Button, Tooltip, Icon, TooltipContentProps, Paragraph } from 'DesignLibrary/atoms';
import Text from 'Shared/Text';

const ProviderFooter = ({ onCloseModal }) => {
  const { retrieveContentfulData } = useTextContext();
  const continueButtonTooltip = retrieveContentfulData<TooltipContentProps>(
    'health_section.secondary_content.tooltips.continue_button_tooltip',
  );
  return (
    <ModalFooter
      as={Flex}
      p={[4, 6]}
      pt={[2, 3]}
      justifyContent="space-between"
      gap={4}
      flexDir={['column', 'row']}
    >
      <Box as={Flex} data-testid="footer-text" cursor="help">
        <Tooltip id="continue-button-tooltip" label={continueButtonTooltip} data-testid="tooltip-text">
          <Flex gap={1.5} align="center">
            <Icon type="Info" size="normal" color="--text-gray" />
            <Paragraph weight="bold" size="small">
              <Text field="health_section.provider_selection_modal.footer_text" />
            </Paragraph>
          </Flex>
        </Tooltip>
      </Box>
      <Box w={['100%', 256]}>
        <Button buttonType="primary" data-testid="save-provider" stretch onClick={(e) => onCloseModal(e)}>
          <Text field="health_section.provider_selection_modal.save_button" />
        </Button>
      </Box>
    </ModalFooter>
  );
};

export default ProviderFooter;
