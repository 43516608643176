import React, { forwardRef, Ref } from 'react';

import { Persona } from 'Containers/HsaPage/types';
import { useTextContext } from 'Contexts/textContext';
import { Button, FormattedTooltipProps, Numbers, Paragraph } from 'DesignLibrary/atoms';
import { TooltipPlanDetails } from 'DesignLibrary/molecules';
import RichText from 'Shared/RichText';
import Text from 'Shared/Text';
import { toDollars } from 'Utils/helpers';

import { Rating } from '../_shared/Rating';
import { InfoContainer } from '../styled';

interface LongTermDetailsProps {
  currentHsaBalance: number | null;
  isEditing: boolean;
  persona: Persona;
  forecastedHsaBalance: number;
  retirementCost: number;
  setIsEditing: (val: boolean) => void;
}

export const LongTermDetails = forwardRef(
  (
    {
      currentHsaBalance,
      isEditing,
      persona,
      forecastedHsaBalance,
      retirementCost,
      setIsEditing,
    }: LongTermDetailsProps,
    ref: Ref<HTMLButtonElement>,
  ) => {
    const { retrieveContentfulData } = useTextContext();

    // Risk Values
    const riskPercentage = Math.min(Math.floor((forecastedHsaBalance / retirementCost) * 100), 100);

    let riskLevel: 'risky' | 'moderate' | 'safe' = 'risky';
    if (riskPercentage > 40) {
      riskLevel = 'moderate';
    }
    if (riskPercentage > 70) {
      riskLevel = 'safe';
    }

    return (
      <div className="details">
        <InfoContainer>
          <div>
            <TooltipPlanDetails
              tooltip={retrieveContentfulData<FormattedTooltipProps>(
                'hsa.interactive_section.tooltips.current_hsa_balance',
              )}
              keyHint="current-hsa-balance"
            >
              <Text field="hsa.interactive_section.current_hsa_balance" />
            </TooltipPlanDetails>
            <div className="flex">
              <Numbers size="small">{toDollars(currentHsaBalance)}</Numbers>
              <Button
                aria-label={retrieveContentfulData<string>(
                  'hsa.interactive_section.accessibility.edit_hsa_balance_button',
                )}
                iconLeft="Pencil"
                iconProps={{
                  color: '--colors-gray-500',
                  weight: 'regular',
                }}
                buttonType="standalone"
                isDisabled={isEditing}
                ref={ref}
                onClick={() => {
                  setIsEditing(true);
                }}
              />
            </div>
          </div>
          <div>
            <TooltipPlanDetails
              tooltip={retrieveContentfulData<FormattedTooltipProps>(
                'hsa.interactive_section.tooltips.usage_type',
              )}
              keyHint="persona"
            >
              <Text field="hsa.interactive_section.usage_type" />
            </TooltipPlanDetails>
            <div className="flex">
              <Numbers size="small">
                <Text
                  field={
                    persona === 'spender'
                      ? 'hsa.interactive_section.spender_persona'
                      : 'hsa.interactive_section.super_saver_persona'
                  }
                />
              </Numbers>
              <Button
                aria-label={retrieveContentfulData<string>(
                  'hsa.interactive_section.accessibility.edit_usage_type_button',
                )}
                iconLeft="Pencil"
                iconProps={{
                  color: '--colors-gray-500',
                  weight: 'regular',
                }}
                buttonType="standalone"
                isDisabled={isEditing}
                onClick={() => {
                  setIsEditing(true);
                }}
              />
            </div>
          </div>
        </InfoContainer>
        <Rating type="long" risk={riskLevel}>
          <Paragraph weight="bold" size="small">
            <RichText field="hsa.interactive_section.coverage_statistic" vars={[riskPercentage]} noWrapper />
          </Paragraph>
          <Paragraph size="small">
            <Text field={`hsa.interactive_section.retirement_coverage_detail_${riskLevel}`} />
          </Paragraph>
        </Rating>
      </div>
    );
  },
);

LongTermDetails.displayName = 'LongTermDetails';
