import styled from 'styled-components';

import { ParagraphMiniStyle, ParagraphNormalStyle, ParagraphSmallStyle } from 'DesignLibrary/atoms';
import { media } from 'DesignLibrary/global';

export const DetailsWrapper = styled.div`
  padding-left: var(--sizing-md);

  & > * + * {
    margin-top: var(--sizing-sm);
  }
`;

export const DetailsContainer = styled.div`
  border: 1px solid var(--border-gray);
  border-radius: var(--border-radius-xs);
  overflow: hidden;

  .exclusions-details {
    margin-top: var(--sizing-sm);
  }
`;

export const ListItem = styled.div`
  padding: var(--sizing-sm);
  border-bottom: 1px solid var(--border-gray);

  &:last-child {
    border: none;
  }

  &:nth-child(odd) {
    background: var(--background-gray-light);
  }

  .toggle-details-btn {
    margin-top: var(--sizing-xs);
  }

  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    span,
    .paragraph,
    .numbers {
      flex-shrink: 0;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: var(--sizing-xs);
      margin-top: var(--sizing-sm);
      padding-left: 0;
    }
  }

  &.covered-benefits {
    .item {
      display: block;

      ${media.TABLET`
        display: block;
      `}
    }
    .flat-amount {
      font-size: 14px;
      padding-top: 20px;
      font-style: normal;
    }
  }

  .hidden-benefits {
    margin-top: var(--sizing-sm);
  }

  .hidden-button {
    margin-top: var(--sizing-sm);
  }
`;

export const BenefitItem = styled.li`
  ${ParagraphMiniStyle()}
  font-weight: 600 !important;
  display: inline-block;
  padding: 4px var(--sizing-xs);
  background: var(--colors-opaque-5);
  border-radius: var(--border-radius-xs);
  list-style: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const HiddenItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--sizing-lg);
  padding: var(--sizing-xs) 0;
  border-top: 1px dotted var(--border-gray);

  span:first-child {
    flex: 1 0 16%;
  }

  .key {
    text-align: left;
  }

  .value {
    text-align: right;
  }

  &:last-child {
    border-bottom: 1px dotted var(--border-gray);
  }
`;

export const PlanDescription = styled.div`
  .paragraph,
  p {
    ${ParagraphSmallStyle()}
    margin-bottom: var(--sizing-xs);
  }

  h4 {
    ${ParagraphNormalStyle()}
    font-weight: 700 !important;
    color: var(--text-black);
    margin-top: var(--sizing-md);
    margin-bottom: var(--sizing-xs);

    &:first-child {
      margin-top: 0;
    }
  }
`;
