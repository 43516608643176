import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import {
  makeSelectProfileField,
  makeGetPayCycle,
  makeGetBuilderGoSetting,
} from 'Containers/ProfilePage/selectors';
import { PAY_PERIOD_KEYS, PAY_PERIOD_TEXT, PAY_PERIOD_VALUES } from 'Types/entities';

import { GlobalReducerState } from '../reducers';

export const usePremiumContext = () => useContext(PremiumContext);

export const PremiumContext = React.createContext<{
  payCycle: number;
  payPeriod: PAY_PERIOD_KEYS;
  payPeriodText: PAY_PERIOD_TEXT;
  useContributionTerm: boolean;
  setPayPeriod: (payPeriod: PAY_PERIOD_KEYS) => void;
}>({
  payCycle: 12,
  payPeriod: PAY_PERIOD_KEYS.MONTHLY,
  payPeriodText: PAY_PERIOD_TEXT.MONTHLY,
  useContributionTerm: false,
  setPayPeriod: () => ({}),
});

export interface PremiumProviderProps {
  defaultPayPeriod: PAY_PERIOD_KEYS;
  payCycle: number;
  benAdminPayPeriod?: PAY_PERIOD_VALUES;
  useContributionTerm: boolean;
  children: ReactNode;
}

const getBenAdminPayPeriodString = (benAdminPayPeriod: PAY_PERIOD_VALUES) => {
  switch (benAdminPayPeriod) {
    case PAY_PERIOD_VALUES.monthly: {
      return PAY_PERIOD_KEYS.MONTHLY;
    }
    case PAY_PERIOD_VALUES.semi_monthly: {
      return PAY_PERIOD_KEYS.SEMI_MONTHLY;
    }
    case PAY_PERIOD_VALUES.bi_weekly: {
      return PAY_PERIOD_KEYS.BI_WEEKLY;
    }
    case PAY_PERIOD_VALUES.weekly: {
      return PAY_PERIOD_KEYS.WEEKLY;
    }
    default: {
      return PAY_PERIOD_KEYS.MONTHLY;
    }
  }
};

export const PremiumProvider = ({
  defaultPayPeriod,
  payCycle,
  benAdminPayPeriod,
  useContributionTerm,
  children,
}: PremiumProviderProps) => {
  const [payPeriod, setPayPeriod] = useState<PAY_PERIOD_KEYS>(defaultPayPeriod);
  const [payPeriodText, setPayPeriodText] = useState<PAY_PERIOD_TEXT>(PAY_PERIOD_TEXT.MONTHLY);

  useEffect(() => {
    const newPayPeriodText = getPayPeriodText(payPeriod);
    setPayPeriodText(newPayPeriodText);
  }, [payPeriod]);

  useEffect(() => {
    // This value is only populated in Redux for ben-admin clients
    if (benAdminPayPeriod) {
      setPayPeriod(getBenAdminPayPeriodString(benAdminPayPeriod));
    }
  }, [benAdminPayPeriod]);

  const getPayPeriodText = (payPeriod: PAY_PERIOD_KEYS) => {
    /* Returns text of how often user pays for a given pay period */
    switch (payPeriod) {
      case PAY_PERIOD_KEYS.WEEKLY:
        return PAY_PERIOD_TEXT.WEEKLY;
      case PAY_PERIOD_KEYS.BI_WEEKLY:
        return PAY_PERIOD_TEXT.BI_WEEKLY;
      case PAY_PERIOD_KEYS.SEMI_MONTHLY:
        return PAY_PERIOD_TEXT.SEMI_MONTHLY;
      default:
        return PAY_PERIOD_TEXT.MONTHLY;
    }
  };

  return (
    <PremiumContext.Provider
      value={{
        payCycle,
        payPeriod,
        payPeriodText,
        useContributionTerm,
        setPayPeriod,
      }}
    >
      {children}
    </PremiumContext.Provider>
  );
};

const mapStateToProps = createStructuredSelector<
  GlobalReducerState,
  {
    payCycle: number;
    benAdminPayPeriod?: PAY_PERIOD_VALUES;
    useContributionTerm: boolean;
  }
>({
  payCycle: makeGetPayCycle(),
  benAdminPayPeriod: makeSelectProfileField('payPeriodsPerYear'),
  useContributionTerm: makeGetBuilderGoSetting('use_contribution_term'),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(PremiumProvider);
