import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import { GlobalReducerState } from 'app/reducers';
import {
  makeGetJvpHasPublicationWithFlag,
  makeGetSSOConnectionName,
  makeSelectJvpField,
} from 'Containers/App/selectors';
import { makeGetProfileIsEmpty } from 'Containers/ProfilePage/selectors';

import AuthButton, { AuthButtonReduxProps, BaseButtonProps } from './AuthButton';

// We should consolidate this and LoginButton as the only thing that differs is the action prop.
const SignupButton = ({
  children,
  userId,
  ssoEnabled,
  ssoConnectionName,
  ...rest
}: BaseButtonProps & AuthButtonReduxProps) => (
  <AuthButton
    action="signUp"
    userId={userId}
    ssoEnabled={ssoEnabled}
    ssoConnectionName={ssoConnectionName}
    {...rest}
  >
    {children}
  </AuthButton>
);

const mapStateToProps = createStructuredSelector<GlobalReducerState, AuthButtonReduxProps>({
  userId: makeSelectJvpField('userId'),
  ssoEnabled: makeGetJvpHasPublicationWithFlag('sso_enabled'),
  ssoConnectionName: makeGetSSOConnectionName(),
  isProfileEmpty: makeGetProfileIsEmpty(),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(SignupButton);
