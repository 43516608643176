import {
  Accordion as ChakraAccordion,
  AccordionProps as ChakraAccordionProps,
  AccordionItem,
  AccordionItemProps as ChakraAccordionItemProps,
  AccordionButton,
  AccordionPanel,
  Box,
} from '@chakra-ui/react';
import React from 'react';

import { Icon } from 'DesignLibrary/atoms/Icon';

import { Paragraph } from '../typography';

export interface AccordionItemProps {
  title: string;
  content: any;
  chakraAccordionItemProps?: ChakraAccordionItemProps; // Putting here instead of extending interface so they can be easily spread on the chakra componenet
}

export interface AccordionProps {
  items: AccordionItemProps[];
  chakraAccordionProps?: ChakraAccordionProps; // Putting here instead of extending interface so they can be easily spread on the chakra componenet
}

const chakraFocusOverrides = {
  _focus: {
    boxShadow: `0 0 0 2px var(--primary-blue)`,
  },
};

export const Accordion = ({ items, chakraAccordionProps }: AccordionProps): JSX.Element => (
  <ChakraAccordion {...chakraAccordionProps}>
    {items.map((item) => (
      <AccordionItem
        borderTop="1px solid var(--border-gray)"
        key={item.title}
        {...item.chakraAccordionItemProps}
      >
        {({ isExpanded }) => (
          <>
            <AccordionButton
              py="var(--sizing-sm)"
              px="var(--sizing-xs)"
              _hover={{
                background: 'var(--colors-blue-50)',
              }}
              {...chakraFocusOverrides}
            >
              <Box flex="1" textAlign="left">
                <Paragraph color="--text-black" weight="bold">
                  {item.title}
                </Paragraph>
              </Box>
              {isExpanded ? (
                <Icon type="Minus" color="--primary-blue" />
              ) : (
                <Icon color="--primary-blue" type="Plus" />
              )}
            </AccordionButton>
            <AccordionPanel py="var(--sizing-sm)" px="var(--sizing-xs)">
              {item.content}
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    ))}
  </ChakraAccordion>
);
