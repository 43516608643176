import React from 'react';

import { AccountTypeWrapper } from './styled';

interface AccountContainerProps {
  account: 'hsa' | 'hra' | 'fsa';
  children: React.ReactNode;
}

export const AccountContainer = ({ account, children }: AccountContainerProps) => (
  <AccountTypeWrapper account={account}>{children}</AccountTypeWrapper>
);
