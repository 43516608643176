import React from 'react';

import { useTextContext } from 'Contexts/textContext';
import { Dropdown } from 'DesignLibrary/molecules/Dropdown';
import { sendInsightsEvent } from 'Utils/insights';

const LanguageToggle = () => {
  const { setLocale, locale, retrieveContentfulData } = useTextContext();

  const handleLocaleSwitch = (e) => {
    const value = e.target.value;
    if (value !== 'default') {
      sendInsightsEvent(e, 'update_language', {
        locale: value,
      });
      setLocale(value);
    }
  };

  return (
    <Dropdown
      label={retrieveContentfulData<string>('top_level_navigation.language_toggle_label')}
      keyHint="language"
      options={[
        {
          display: 'EN',
          description: 'English',
          value: 'en-US',
        },
        {
          display: 'ES',
          description: 'Español',
          value: 'es-US',
        },
      ]}
      selectedValue={locale}
      groupName="language"
      isOpen={false}
      handleChange={handleLocaleSwitch}
      buttonProps={{
        size: 'small',
        iconLeft: 'Globe',
        iconProps: {
          color: '--primary-blue',
          size: 20,
        },
        isDisabled: false,
      }}
    />
  );
};

export default LanguageToggle;
