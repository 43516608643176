import { ProfileSection } from '../types';

/**
 * Enum for Profile Audio Clips (strings used for retrieveContentfulData calls)
 * @enum {string}
 */
export enum AudioConfigContentfulPaths {
  StartedProfile = 'profile_section.audio_configuration.profile_start_audio',
  CompletedProfile = 'profile_section.audio_configuration.profile_complete_audio',
  CompletedSectionResidence = 'profile_section.audio_configuration.residence_section_audio',
  CompletedSectionHousehold = 'profile_section.audio_configuration.household_section_audio',
  CompletedSectionIncome = 'profile_section.audio_configuration.income_section_audio',
  CompletedSectionRiskAversion = 'profile_section.audio_configuration.risk_aversion_section_audio',
}

export enum AudioConfigTooltipContentfulPaths {
  EnrollmentSectionAudioTooltip = 'profile_section.audio_configuration.enrollment_section_audio_tooltip',
  ResidenceSectionAudioTooltip = 'profile_section.audio_configuration.residence_section_audio_tooltip',
  HouseholdSectionAudioTooltip = 'profile_section.audio_configuration.household_section_audio_tooltip',
  SexAtBirthAudioTooltip = 'profile_section.audio_configuration.sex_at_birth_audio_tooltip',
  PrescriptionDrugsAudioTooltip = 'profile_section.audio_configuration.prescription_drugs_audio_tooltip',
  UtilSectionAudioTooltip = 'profile_section.audio_configuration.utilization_section_audio_tooltip',
  PcpAudioTooltip = 'profile_section.audio_configuration.pcp_audio_tooltip',
  SpecialistAudioTooltip = 'profile_section.audio_configuration.specialist_audio_tooltip',
  HospitalStaysAudioTooltip = 'profile_section.audio_configuration.hospital_stays_audio_tooltip',
  PregnancySectionAudioTooltip = 'profile_section.audio_configuration.pregnancy_section_audio_tooltip',
  IncomeSectionAudioTooltip = 'profile_section.audio_configuration.income_section_audio_tooltip',
  RiskSectionAudioTooltip = 'profile_section.audio_configuration.risk_section_audio_tooltip',
  AffordabilitySectionAudioTooltip = 'profile_section.audio_configuration.affordability_section_audio_tooltip',
  PlannedProceduresQuestionAudio = 'profile_section.audio_configuration.planned_procedures_question_audio_tooltip',
  PlannedProceduresInpatientAudioTooltip = 'profile_section.audio_configuration.planned_procedures_inpatient_audio_tooltip',
  PlannedProceduresOutpatientAudioTooltip = 'profile_section.audio_configuration.planned_procedures_outpatient_audio_tooltip',
  PlannedProceduresRoutineTherapyAudioTooltip = 'profile_section.audio_configuration.planned_procedures_routine_therapy_audio_tooltip',
  PlannedProceduresMentalHealthAudioTooltip = 'profile_section.audio_configuration.planned_procedures_mental_health_audio_tooltip',
  // TODO: add constants here for your contentful paths
}

/**
 * Map of profile sections to their associated completion audio contentful path.
 */
const sectionCompletionAudioMap = new Map<ProfileSection, AudioConfigContentfulPaths>([
  ['location', AudioConfigContentfulPaths.CompletedSectionResidence],
  ['member', AudioConfigContentfulPaths.CompletedSectionHousehold],
  ['income', AudioConfigContentfulPaths.CompletedSectionIncome],
  ['riskAssessment', AudioConfigContentfulPaths.CompletedSectionRiskAversion],
]);

export const getCompletionAudioForSection = (section: ProfileSection): string => {
  const audioContentPath = sectionCompletionAudioMap.get(section);
  if (audioContentPath) {
    return audioContentPath.valueOf();
  }
  return '';
};
