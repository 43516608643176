import { get } from 'lodash';
import React, { useEffect, useState } from 'react';

import { useTextContext } from 'Contexts/textContext';
import Video from 'Shared/Video';

interface MediaProps {
  field: string;
  form: 'image' | 'video';
  title?: string;
  className?: string;
  // All other props
  [x: string]: any;
}

const Media = ({ field, form, title, className, ...rest }: MediaProps): JSX.Element => {
  const [text, setText] = useState('');
  const { textMap, isLoading } = useTextContext();

  useEffect(() => {
    let textField = get(textMap, field);

    if (!isLoading && typeof textField === 'undefined') {
      textField = (
        <div id={field} className="contentful-error">
          {' '}
        </div>
      );
    }

    setText(textField);
  }, [field, textMap, isLoading]);

  if (typeof text === 'string') {
    return form === 'image' ? (
      <img className={className} data-testid="img" alt={title} src={text} {...rest} />
    ) : (
      <Video url={text} title={title || ''} width="100%" height="100%" {...rest} />
    );
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{text}</>;
};

export default Media;
