import {
  ERROR_SLUG,
  HSA_PLUS_SLUG,
  INTRO_SLUG,
  OVERVIEW_SLUG,
  PROFILE_SLUG,
  RESULT_SLUG,
  REVIEW_SLUG,
  TAX_SAVINGS_SLUG,
  VIEW_BENEFIT_SLUG,
  VIEW_PLAN_SLUG,
} from 'Containers/App/constants';
import { getEmployerSlugFromLocation, getSegmentSlugFromLocation } from 'Containers/App/utils';

import { DEPLOY_ENVIRONMENTS } from '../constants';

export const getEnvironmentFromUrl = (): DEPLOY_ENVIRONMENTS => {
  const domain = window.location.origin;

  // jv-dev-kit uses start-local
  if (domain.includes('localhost') || domain.includes('start-local.myalex')) {
    return DEPLOY_ENVIRONMENTS.LOCALHOST;
  }

  if (domain.includes('.jellydevs.com')) {
    return DEPLOY_ENVIRONMENTS.REVIEW;
  }

  if (domain.includes('staging.picwell') || domain.includes('testing.myalex')) {
    return DEPLOY_ENVIRONMENTS.STAGING;
  }

  if (domain.includes('uat.picwell') || domain.includes('review.myalex')) {
    return DEPLOY_ENVIRONMENTS.UAT;
  }

  // Default to production
  return DEPLOY_ENVIRONMENTS.PROD;
};

const getBcEnv = (deployEnv: DEPLOY_ENVIRONMENTS) => {
  switch (deployEnv) {
    case DEPLOY_ENVIRONMENTS.STAGING: {
      return 'testing';
    }
    case DEPLOY_ENVIRONMENTS.UAT: {
      return 'review';
    }
    case DEPLOY_ENVIRONMENTS.PROD: {
      return '';
    }
    default: {
      return 'testing';
    }
  }
};

export const getBackendHost = ({
  nodeEnv,
  nonLocalBackendToUseInDev,
}: {
  nodeEnv?: string;
  nonLocalBackendToUseInDev?: DEPLOY_ENVIRONMENTS;
}) => {
  if (nonLocalBackendToUseInDev) {
    const environment = getBcEnv(nonLocalBackendToUseInDev);
    if (environment) {
      return `https://start-${environment}.myalex.com`;
    }
    return `https://start.myalex.com`;
  }

  return nodeEnv === 'development' ? 'http://localhost:8000' : '';
};

export const BACKEND_HOST = getBackendHost({
  nodeEnv: process.env.NODE_ENV,
  nonLocalBackendToUseInDev: process.env.NONLOCAL_BACKEND_ENV_TO_USE_IN_DEV as
    | DEPLOY_ENVIRONMENTS
    | undefined,
});

export const getBasePath = (location: Location) => {
  const unvalidatedSegmentSlug = getSegmentSlugFromLocation(location);
  let path = getEmployerSlugFromLocation(location);
  if (unvalidatedSegmentSlug) {
    path += `/${unvalidatedSegmentSlug}`;
  }
  return path ? `/${path}` : '';
};

export const BASE_PATH = getBasePath(window.location);

const getCustomerPath = () => {
  const { hostname, pathname } = window.location;

  if (hostname.includes('staging')) {
    return hostname.split('-staging.').slice(0, 1).join();
  }

  if (hostname.includes('uat')) {
    return hostname.split('-uat.').slice(0, 1).join();
  }

  return pathname.split('/')[1];
};

export const CUSTOMER_PATH = getCustomerPath();

export const getAAPIUrl = (): string => {
  const deployedEnv = getEnvironmentFromUrl();

  // localhost (redirect to local BC)
  if (deployedEnv === DEPLOY_ENVIRONMENTS.LOCALHOST) {
    return window.location.origin.includes('start-local.myalex')
      ? 'https://local.myalex.com'
      : 'http://localhost:3001';
  }

  // Dev-Review
  if (deployedEnv === DEPLOY_ENVIRONMENTS.REVIEW) {
    return 'https://aapi-dev.jellydevs.com';
  }

  // Staging/Testing
  if (deployedEnv === DEPLOY_ENVIRONMENTS.STAGING) {
    return 'https://qa.myalex.com';
  }

  // UAT/Review
  if (deployedEnv === DEPLOY_ENVIRONMENTS.UAT) {
    return 'https://review.myalex.com';
  }

  // Default to production
  return 'https://myalex.com';
};

export const ALEX_API_URL = getAAPIUrl();

// Benefits Counselor
export const BC_PATH_ANCHOR = '#intro';

export const getBCPath = (
  isDemoMode: boolean,
  enrollmentYear: string,
  segmentSlug: string | undefined,
  hash: string,
) => {
  const demoQueryParam = isDemoMode ? '?demo=true' : '';
  let bcPath = `${ALEX_API_URL}/${CUSTOMER_PATH}/${enrollmentYear}`;
  if (segmentSlug) {
    bcPath += `/${segmentSlug}`;
  }
  return bcPath + demoQueryParam + (hash || BC_PATH_ANCHOR);
};

// Paths
export const WELCOME_PATH = `${BASE_PATH}/`;
export const INTRO_PATH = `${BASE_PATH}/${INTRO_SLUG}`;
export const PROFILE_PATH = `${BASE_PATH}/${PROFILE_SLUG}`;
export const RESULT_PATH = `${BASE_PATH}/${RESULT_SLUG}`;
export const VIEW_PLAN_PATH = `${BASE_PATH}/${VIEW_PLAN_SLUG}`;
export const VIEW_BENEFIT_PATH = `${BASE_PATH}/${VIEW_BENEFIT_SLUG}`;
export const ERROR_PATH = `${BASE_PATH}/${ERROR_SLUG}`;

// Commercial-specific paths
export const OVERVIEW_PATH = `${BASE_PATH}/${OVERVIEW_SLUG}`;
export const REVIEW_PATH = `${BASE_PATH}/${REVIEW_SLUG}`;
export const HSA_PLUS_PATH = `${BASE_PATH}/${HSA_PLUS_SLUG}`;
export const TAX_SAVINGS_PATH = `${BASE_PATH}/${TAX_SAVINGS_SLUG}`;

/**
 * Used by Storybook for Navigation
 */
export const COMMERCIAL_PATHS = new Set([
  WELCOME_PATH,
  PROFILE_PATH,
  getBCPath,
  RESULT_PATH,
  HSA_PLUS_PATH,
  TAX_SAVINGS_PATH,
  VIEW_PLAN_PATH,
  OVERVIEW_PATH,
  VIEW_BENEFIT_PATH,
  REVIEW_PATH,
]);

/**
 * Used in tests only
 */
export const CTA_PATHS = [
  PROFILE_PATH,
  RESULT_PATH,
  HSA_PLUS_PATH,
  TAX_SAVINGS_PATH,
  VIEW_PLAN_PATH,
  OVERVIEW_PATH,
  VIEW_BENEFIT_PATH,
  REVIEW_PATH,
];
