import React, { forwardRef, KeyboardEventHandler, Ref } from 'react';

import { Icon, IconProps, IconTypes } from 'DesignLibrary/atoms/Icon';
import { Spinner } from 'DesignLibrary/atoms/Spinner';
import { ButtonSizes, ButtonTypes } from 'DesignLibrary/types';
import { PrimaryColors, colorPaletteArray } from 'DesignLibrary/vars';

import { ButtonStyle } from './styled';

export interface ButtonOptions {
  buttonType?: ButtonTypes;
  color?: PrimaryColors;
  textColor?: (typeof colorPaletteArray)[number];
  iconLeft?: IconTypes | null;
  iconRight?: IconTypes | null;
  iconProps?: IconProps;
  size?: ButtonSizes;
  stretch?: boolean;
  justify?: 'center' | 'space-between' | 'flex-start';
  isDisabled?: boolean;
  isLoading?: boolean;
  children?: string | JSX.Element;
  url?: string;
  // A11y props needed for dropdown button that are not included in Button/Anchor intrinsic attributes
  className?: string;
  role?: string;
  tabindex?: number;
  id?: string;
  testId?: string;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onKeyDown?: (e: KeyboardEvent) => void;
}

interface ButtonUrlRequired extends ButtonOptions {
  url: string;
}
interface ButtonOnClickRequired extends ButtonOptions {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

export type ButtonProps = ButtonUrlRequired | ButtonOnClickRequired;

function isUrlOrOnClick(props: ButtonProps): ButtonUrlRequired | ButtonOnClickRequired {
  if ((props as ButtonUrlRequired).url !== undefined && (props as ButtonUrlRequired).url !== '') {
    return props as ButtonUrlRequired;
  }
  return props as ButtonOnClickRequired;
}

export const Button = forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonProps>((props, ref) => {
  const {
    buttonType = 'secondary',
    color,
    textColor,
    iconLeft = null,
    iconRight = null,
    iconProps,
    stretch = false,
    justify = 'center',
    size = 'normal',
    isDisabled = false,
    isLoading = false,
    children,
    url,
    className,
    role,
    tabindex,
    testId = 'button',
    onClick,
    onKeyDown,
    ...rest
  } = isUrlOrOnClick({ ...props });

  // Button Colors
  let textColorWithDefaults = '--text-black';
  if (buttonType === 'primary' && !isDisabled) textColorWithDefaults = '--text-black';
  if (textColor) textColorWithDefaults = textColor;

  const iconColor = iconProps?.color || textColorWithDefaults;

  // Button or Link Props
  const renderAsButtonOrLink = url ? 'a' : 'button';
  const hrefDefault = isLoading || isDisabled ? '' : url;
  const onClickDefault = isLoading || isDisabled ? undefined : onClick;

  const urlProps = {
    href: hrefDefault,
    target: '_blank',
    rel: 'noopener noreferrer',
    onClick: onClickDefault,
  };

  const buttonProps = {
    onClick: onClickDefault,
  };

  const urlOrButtonProps = url ? urlProps : buttonProps;

  // because of the forwardRef signature, TS thinks ref is a React.Ref<HTMLAnchorElement | HTMLButtonElement>
  //   we need to cast it to React.Ref<HTMLAnchorElement> | React.Ref<HTMLButtonElement>, which should
  //   be identical
  return (
    <ButtonStyle
      buttonType={buttonType}
      color={color}
      textColor={textColorWithDefaults}
      stretch={stretch}
      justify={justify}
      size={size}
      data-testid={testId}
      isDisabled={isDisabled || isLoading}
      disabled={isDisabled || isLoading}
      as={renderAsButtonOrLink}
      ref={ref as Ref<HTMLAnchorElement> | Ref<HTMLButtonElement>}
      className={`${className} ${!children && 'icon-only'}`}
      onKeyDown={
        onKeyDown as
          | KeyboardEventHandler<HTMLButtonElement>
          | KeyboardEventHandler<HTMLAnchorElement>
          | undefined
      }
      role={role}
      tabIndex={tabindex}
      {...urlOrButtonProps}
      {...rest}
    >
      <>
        {iconLeft && !isLoading && (
          <div className="icon-left" data-testid="icon-left">
            <Icon type={iconLeft} color={iconColor} weight={iconProps?.weight} size={iconProps?.size} />
          </div>
        )}

        <span className="text">{children}</span>

        {isLoading && (
          <div className="icon-left" data-testid="icon-left">
            <Spinner size="small" color={iconColor} />
          </div>
        )}

        {iconRight && !isLoading && (
          <div className="icon-right" data-testid="icon-right">
            <Icon type={iconRight} color={iconColor} weight={iconProps?.weight} size={iconProps?.size} />
          </div>
        )}
      </>
    </ButtonStyle>
  );
});

Button.displayName = 'Button';
