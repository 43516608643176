import { Stack } from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { TextField } from 'Containers/App/types';
import { MEMBER_TYPE } from 'Containers/ProfilePage/types';
import {
  AddBenefits,
  BuilderBenefit,
  DentalBenefits,
  HospitalIndemnityBenefits,
  InsuranceType,
  LifeBenefits,
  RetirementBenefits,
  VisionBenefits,
} from 'Containers/ViewBenefitPage/types';
import { transformBenefitItems } from 'Containers/ViewBenefitPage/utils';
import { ContentfulSupplementalPlan } from 'ContentfulDefaults/types/benefitsAdditional';
import { useTextContext } from 'Contexts/textContext';
import { Button, Numbers, Paragraph } from 'DesignLibrary/atoms';
import { TooltipPlanDetails } from 'DesignLibrary/molecules';
import RichText from 'Shared/RichText';
import Text from 'Shared/Text';
import { Benefits, FieldDataTypes, HouseholdMember, Items, SupplementalPlan } from 'Types/entities';
import { toDollars } from 'Utils/helpers';
import { sendInsightsEvent } from 'Utils/insights';

import { BenefitItem, DetailsContainer, DetailsWrapper, ListItem, PlanDescription } from './styled';
import { addNotes, getAddTagList } from '../addContent';
import { getDentalTagList } from '../dentalContent';
import FbsDetail from '../FbsDetail';
import HiddenContent from '../HiddenContent/HiddenContent';
import { getHospitalIndemnityTagList, hospitalIndemnityAdditionalNotes } from '../hospitalIndemnityContent';
import { getLifeTagList, lifeAdditionalNotes } from '../lifeContent';
import { longTermDisabilityDescription } from '../ltdContent';
import PlanDetailsTable from '../PlanDetailsTable';
import { getRetirementTagList, retirementAdditionalNotes } from '../retirementContent';
import { shortTermDisabilityDescription } from '../stdContent';
import { getVisionTagList } from '../visionContent';

export interface PlanContentProps {
  plan: SupplementalPlan;
  builderBenefit?: BuilderBenefit;
  insuranceType: InsuranceType;
  showFbsCardDetails: boolean;
  employerName: string;
  builderCustomerKey: string | null;
  selectedHospitalIndemnityPlan?: SupplementalPlan | null;
  householdMembers: HouseholdMember[];
}

const PlanContent = ({
  plan,
  builderBenefit,
  insuranceType,
  showFbsCardDetails,
  employerName,
  builderCustomerKey,
  selectedHospitalIndemnityPlan,
  householdMembers,
}: PlanContentProps) => {
  const { retrieveContentfulData } = useTextContext();
  const [isHiddenOpen, setHiddenOpen] = useState(false);
  const coveredBenefitsRef = useRef<HTMLDivElement>(null);

  const isAddPlan = insuranceType === 'add';
  const isDentalPlan = insuranceType === 'dental';
  const isVisionPlan = insuranceType === 'vision';
  const isHIPlan = insuranceType === 'hospital';
  const isLifePlan = insuranceType === 'life';
  const isStdPlan = insuranceType === 'std';
  const isLtdPlan = insuranceType === 'ltd';
  const isRetirementPlan = ['retirement', '401k', '403b'].includes(insuranceType);
  const shouldShowCoveredBenefitsList =
    !['std', 'ltd', 'critical'].includes(insuranceType) || !builderCustomerKey;

  // Determine if plan.eligible_members contains a non-policyholder
  const hasDependentCoverage = useMemo(() => {
    if (!plan.eligible_members || !householdMembers) return false;

    return plan.eligible_members.some((memberId) =>
      householdMembers.some(
        (member) => member.external_id === memberId && member.member_type !== MEMBER_TYPE.POLICYHOLDER,
      ),
    );
  }, [plan.eligible_members, householdMembers]);

  const normalizeBenefitData = (): Benefits => {
    if (!isEmpty(builderBenefit?.benefit_items)) {
      const items = builderBenefit?.benefit_items.map((benefit) => {
        const [key, val = ''] = benefit.split(':');
        return {
          item: { data: key, type: FieldDataTypes.STRING },
          description: { data: val, type: FieldDataTypes.STRING },
        } as Items;
      });

      const description = builderBenefit?.path
        ? {
            data: `${builderBenefit.path}.description`,
            type: FieldDataTypes.RICHTEXT,
          }
        : null;

      return { description, items } as Benefits;
    }

    return plan.benefits;
  };

  const normalizedBenefitData = normalizeBenefitData();

  const isHiddenDetailsShown =
    showFbsCardDetails &&
    (!isEmpty(normalizedBenefitData.items) || !isEmpty(normalizedBenefitData.exclusions));

  useEffect(() => {
    if (isHiddenOpen && coveredBenefitsRef.current) {
      coveredBenefitsRef.current.focus();
    }
  }, [isHiddenOpen]);

  const toggleHiddenOpen = (e) => {
    // send insights event if opening plan details
    if (!isHiddenOpen) {
      sendInsightsEvent(e, 'show_supplemental_plan_details', {
        plan_type: insuranceType,
        plan,
      });
    }

    setHiddenOpen((prevState) => !prevState);
  };

  const getHideButtonLabel = () => {
    const pre = 'benefits_section_additional.accessibility';
    const labelKey = isHiddenOpen ? 'collapse' : 'expand';

    return retrieveContentfulData<string>(`${pre}.${labelKey}_benefit_details`).replace(/{x}/, plan.name);
  };

  const showDetailsContainer =
    (builderCustomerKey &&
      (isDentalPlan ||
        isVisionPlan ||
        isAddPlan ||
        !isEmpty(normalizedBenefitData.items) ||
        !isEmpty(normalizedBenefitData.exclusions))) ||
    !builderCustomerKey;

  const deductibleTooltip = retrieveContentfulData<Record<string, string>>('tool_tips.deductible', {});

  const maxAnnualBenefitTooltip = retrieveContentfulData<Record<string, string>>(
    'benefits_section_additional.tooltips.max_annual_benefit',
  );

  const renderDentalHeaders = () => {
    if (isDentalPlan && plan.deductible) {
      return (
        <ListItem className="dental-deductible">
          <div className="item">
            <TooltipPlanDetails
              tooltip={{
                title: deductibleTooltip.title,
                text: deductibleTooltip.text,
              }}
              keyHint={`plan-${plan.external_id}-deductible`}
            >
              <Text field="benefits_section.plan_card_text.benefits.deductible" />
            </TooltipPlanDetails>
            <Numbers>
              <FbsDetail type={plan.deductible.type} data={plan.deductible.data} />
            </Numbers>
          </div>
        </ListItem>
      );
    }
    return null;
  };

  const renderMaximumPayout = () => {
    if (plan.maximum_payout && (!builderCustomerKey || isDentalPlan)) {
      const max_is_per_person = normalizedBenefitData.items.find(
        (benefitItem) =>
          benefitItem.item.data === 'max_is_per_person' && benefitItem.description.data === 'true',
      );
      return (
        <ListItem className="max-payout">
          <div className="item">
            <TooltipPlanDetails
              tooltip={{
                title: maxAnnualBenefitTooltip.title,
                text: maxAnnualBenefitTooltip.text,
              }}
              keyHint={`plan-${plan.external_id}-max-benefit`}
            >
              <Text field="benefits_section.plan_card_text.benefits.maxBenefit" />
            </TooltipPlanDetails>
            {max_is_per_person ? (
              <FbsDetail
                type={FieldDataTypes.STRING}
                data={
                  <Text
                    field="benefits_section_dental.per_person"
                    vars={{ x: toDollars(plan.maximum_payout.data) }}
                  />
                }
              />
            ) : (
              <Numbers>
                <FbsDetail type={plan.maximum_payout.type} data={plan.maximum_payout.data} />
              </Numbers>
            )}
          </div>
        </ListItem>
      );
    }
    return null;
  };

  const getBenefitsList = (items: Items[]) => {
    if (builderBenefit?.benefit_items && builderBenefit?.benefit_items.length > 0) {
      return builderBenefit?.benefit_items.map((bi, idx) => (
        <BenefitItem key={idx}>{bi.split(':')[0]}</BenefitItem>
      ));
    }

    let tags: JSX.Element[] = items.map((i, idx) => (
      <FbsDetail key={idx} type={i.item.type} data={i.item.data} />
    ));
    const benefits = transformBenefitItems(plan);

    if (isAddPlan) {
      tags = getAddTagList(benefits as AddBenefits);
    }
    if (isDentalPlan) {
      tags = getDentalTagList(benefits as DentalBenefits);
    }
    if (isVisionPlan) {
      tags = getVisionTagList(benefits as VisionBenefits);
    }
    if (isHIPlan) {
      tags = getHospitalIndemnityTagList(benefits as HospitalIndemnityBenefits);
    }
    if (isLifePlan) {
      tags = getLifeTagList(benefits as LifeBenefits);
    }
    if (isRetirementPlan) {
      tags = getRetirementTagList(benefits as RetirementBenefits);
    }

    return (
      <>
        {tags.slice(0, 7).map((tag, idx) => (
          <BenefitItem key={idx}>{tag}</BenefitItem>
        ))}
        {tags.length >= 7 && <BenefitItem>...</BenefitItem>}
      </>
    );
  };

  const renderCoverageHeader = () => {
    if (isDentalPlan || isVisionPlan) {
      return <Text field="benefits_section_additional.coverage_details_header" />;
    }
    // Only render for real HI modules, not text-only modules
    if (isHIPlan && plan.benefits.items.length > 0) {
      return <Text field="benefits_section_hospital_indemnity.covered_services" />;
    }
    return <Text field="benefits_section.plan_card_text.benefits.title" />;
  };

  const renderCoveredBenefits = () =>
    !isEmpty(normalizedBenefitData.items) &&
    shouldShowCoveredBenefitsList && (
      <ListItem className="covered-benefits" ref={coveredBenefitsRef}>
        <div className="item">
          <Paragraph>{renderCoverageHeader()}</Paragraph>
          <ul>{getBenefitsList(normalizedBenefitData.items)}</ul>
        </div>
        {isHiddenOpen && (
          <HiddenContent
            plan={plan}
            builderBenefit={builderBenefit}
            insuranceType={insuranceType}
            builderCustomerKey={builderCustomerKey}
          />
        )}
        {isHiddenDetailsShown && (
          <Button
            data-testid={`${plan.external_id}-expand-toggle`}
            aria-label={getHideButtonLabel()}
            size="small"
            className="toggle-details-btn"
            onClick={toggleHiddenOpen}
            stretch
            buttonType="transparent"
            iconLeft={isHiddenOpen ? 'CaretUp' : 'CaretDown'}
          />
        )}
      </ListItem>
    );

  const renderCoveredConditionsTable = () => {
    if (builderBenefit?.covered_conditions_table_data) {
      return (
        <PlanDetailsTable
          header={retrieveContentfulData<string>(
            'benefits_section_additional.covered_conditions_table_header',
          )}
          subheader={retrieveContentfulData<string>(
            'benefits_section_additional.covered_conditions_table_subheader',
          )}
          columns={builderBenefit?.covered_conditions_table_data}
        />
      );
    }

    return null;
  };

  const renderCoverageLevelTable = () => {
    if (builderBenefit?.coverage_level_table_data) {
      return (
        <PlanDetailsTable
          header={retrieveContentfulData<string>('benefits_section_additional.coverage_level_table_header')}
          subheader={retrieveContentfulData<string>(
            'benefits_section_additional.coverage_level_table_subheader',
          )}
          columns={builderBenefit?.coverage_level_table_data}
        />
      );
    }

    return null;
  };

  const renderDescription = () => {
    if (!builderCustomerKey && plan?.benefits?.description) {
      return (
        <Paragraph lineHeight={1.75} size="small" className="plan-description">
          <FbsDetail type={plan.benefits.description.type} data={plan.benefits.description.data} />
        </Paragraph>
      );
    }

    if (builderBenefit?.description) {
      return (
        <PlanDescription>
          <Paragraph as="div" lineHeight={1.75} size="small" className="plan-description">
            <RichText noWrapper={false} field={`${builderBenefit.path}.description` as TextField} />
          </Paragraph>
        </PlanDescription>
      );
    }

    if (isAddPlan) {
      return addNotes(plan, hasDependentCoverage);
    }

    // Only render for real HI modules, not text-only modules
    if (isHIPlan && plan.benefits.items.length > 0) {
      const contentfulSupplementalPlans = retrieveContentfulData<ContentfulSupplementalPlan[]>(
        'benefits_section_additional.supplemental_plans',
        [],
      );
      const selectedPlanData = contentfulSupplementalPlans.find(
        ({ plan_id }) => plan_id === selectedHospitalIndemnityPlan?.external_id,
      );
      return hospitalIndemnityAdditionalNotes(plan, selectedPlanData);
    }

    if (isLifePlan) {
      return lifeAdditionalNotes(plan, hasDependentCoverage);
    }

    if (isStdPlan && plan.benefits.items.length > 0) {
      return shortTermDisabilityDescription(plan, employerName);
    }

    if (isLtdPlan && plan.benefits.items.length > 0) {
      return longTermDisabilityDescription(plan, employerName);
    }

    if (isRetirementPlan) {
      const benefits = transformBenefitItems(plan) as RetirementBenefits;
      return retirementAdditionalNotes(benefits.plan_type, employerName);
    }

    // if (!builderBenefit?.description && !plan?.benefits?.description) return null;

    if (!builderBenefit?.path && plan?.benefits?.description) {
      return (
        <PlanDescription>
          <FbsDetail type={plan.benefits.description.type} data={plan.benefits.description.data} />
        </PlanDescription>
      );
    }

    return null;
  };

  return (
    <DetailsWrapper>
      <Stack gap={6}>
        {showDetailsContainer && (
          <DetailsContainer>
            {renderDentalHeaders()}
            {renderMaximumPayout()}
            {renderCoveredBenefits()}
            {!isEmpty(normalizedBenefitData.exclusions) && (
              <ListItem className="exclusions">
                <Paragraph lineHeight={1.75} size="small">
                  <Text field="benefits_section.plan_card_text.exclusions.instructions" />
                </Paragraph>
              </ListItem>
            )}
          </DetailsContainer>
        )}
        {renderCoveredConditionsTable()}
        {renderCoverageLevelTable()}
        {renderDescription()}
      </Stack>
    </DetailsWrapper>
  );
};

export default PlanContent;
