import React from 'react';

import { ForecastData, Persona } from 'Containers/TaxSavingsPage/types';
import { Recommendation } from 'Types/entities';
import { GetHsaRecommendationResponse } from 'Utils/apiTypes';

import { ChartWrapper } from './_shared/ChartWrapper';
import { HsaSlider } from './HsaSlider';
import { LongTermChart } from './LongTermChart';
import { LongTermDetails } from './LongTermDetails';
import { ShortTermChart } from './ShortTermChart';
import { ShortTermDetails } from './ShortTermDetails';
import { Container } from './styled';

export interface InteractiveProps {
  userContributionLimit: number;
  hsaContributionLimit: number;
  hsaData: GetHsaRecommendationResponse | Record<string, never>;
  forecastData: ForecastData | Record<string, never>;
  selectedPlan: Recommendation;
  employerHsaContribution: number | null;
  isForecastLoading: boolean;
  contribution: number;
  currentHsaBalance: number | null;
  persona: Persona;
  retirementAge: number;
  lockedHsaContribution: number | null;
  recommendedHsaContribution: number;
  setContribution: (contribution: number) => void;
  handleLockedHsaContribution: (contribution: number) => void;
  handleHsaForecastInputs: (balance: number, persona: Persona, retirementAge: number) => void;
}

export const Interactive = ({
  contribution,
  userContributionLimit,
  hsaContributionLimit,
  hsaData,
  forecastData,
  selectedPlan,
  employerHsaContribution,
  isForecastLoading,
  currentHsaBalance,
  persona,
  retirementAge,
  lockedHsaContribution,
  recommendedHsaContribution,
  setContribution,
  handleLockedHsaContribution,
  handleHsaForecastInputs,
}: InteractiveProps) => (
  <Container>
    <HsaSlider
      contribution={contribution}
      userContributionLimit={userContributionLimit}
      recommendedHsaContribution={recommendedHsaContribution}
      lockedHsaContribution={lockedHsaContribution}
      setContribution={setContribution}
      setLockedContribution={handleLockedHsaContribution}
    />
    <ChartWrapper
      // TODO: CONTENTFUL Add New Fields
      title="Yearly Contribution"
      subtitle="Here’s how we predict you’ll spend your HSA this year."
      chart={
        <ShortTermChart
          selectedPlan={selectedPlan}
          employerHsaContribution={employerHsaContribution || 0}
          contribution={contribution}
          hsaContributionLimit={hsaContributionLimit}
        />
      }
      details={<ShortTermDetails hsaData={hsaData} contribution={contribution} />}
    />

    <ChartWrapper
      // TODO: CONTENTFUL Add New Fields
      title="Saving For The Future"
      subtitle="Our projection of how your invested HSA savings will grow."
      chart={
        <LongTermChart
          forecastData={forecastData}
          currentHsaBalance={currentHsaBalance || 0}
          lockedHsaContribution={contribution}
          isForecastLoading={isForecastLoading}
          persona={persona}
        />
      }
      details={
        <LongTermDetails
          currentHsaBalance={currentHsaBalance}
          persona={persona}
          handleForecastInputs={handleHsaForecastInputs}
          retirementAge={retirementAge}
        />
      }
    />
  </Container>
);
