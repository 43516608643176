import React from 'react';

import { ChartLegendTerm } from 'ContentfulDefaults/types/hsa';
import { useTextContext } from 'Contexts/textContext';
import { TooltipCaps } from 'DesignLibrary/molecules';
import Plan from 'Models/plan';
import { Recommendation } from 'Types/entities';
import { AriaHidden } from 'Utils/accessibility';
import { toDollars } from 'Utils/helpers';

import { GraphLine, Line, Legend, LinePosition } from '../styled';

interface ShortTermForecastProps {
  selectedPlan: Recommendation;
  employerHsaContribution: number;
  contribution: number;
}

export const ShortTermForecast = ({
  selectedPlan,
  employerHsaContribution,
  contribution,
}: ShortTermForecastProps) => {
  const { isEmployerMatching } = new Plan(selectedPlan);

  // HSA Values
  const oopEstimate = selectedPlan.costs.drugs + selectedPlan.costs.services.in_network;
  const remainingExposure = Math.max(oopEstimate - employerHsaContribution - contribution, 0);
  const bottomTotal = contribution + employerHsaContribution + remainingExposure;

  // In match contribution scenarios - We should only display the amount
  // the _user_ is contributing when it is less than the
  // maximum possible employer contribution amount
  const adjustedEmployerHsaContribution = isEmployerMatching
    ? Math.min(employerHsaContribution, contribution)
    : employerHsaContribution;

  // HSA Percentages - control the width of each section of the bar
  const oopEstimatePercent =
    employerHsaContribution + contribution > oopEstimate ? (oopEstimate / bottomTotal) * 100 : 100;

  const employerPercent =
    adjustedEmployerHsaContribution > 0 ? (adjustedEmployerHsaContribution / bottomTotal) * 100 : 0;

  const contributionPercent = contribution > 0 ? (contribution / bottomTotal) * 100 : 0;

  const remainingExposurePercent = remainingExposure > 0 ? (remainingExposure / bottomTotal) * 100 : 0;

  // HSA Display Values;
  const oopEstimateDisplay = toDollars(oopEstimate);
  const employerDisplay = toDollars(adjustedEmployerHsaContribution);
  const contributionDisplay = toDollars(contribution);
  const remainingExposureDisplay = toDollars(remainingExposure);

  // HSA contribution position
  let contributionPosition = 'middle';
  if (employerPercent === 0) {
    contributionPosition = 'start';
  }
  if (remainingExposurePercent === 0) {
    contributionPosition = 'end';
  }
  if (employerPercent === 0 && remainingExposurePercent === 0) {
    contributionPosition = 'whole';
  }

  // Text Context
  const { retrieveContentfulData } = useTextContext();

  const legendTerms = retrieveContentfulData<ChartLegendTerm[]>(
    'hsa.interactive_section.yearly_chart_legend',
    [],
  );

  const legendHiddenValues = [
    employerDisplay,
    contributionDisplay,
    remainingExposureDisplay,
    oopEstimateDisplay,
  ];

  const legend = legendTerms.map((currElement, index) => (
    <TooltipCaps
      key={`legend-term-${currElement.title}`}
      keyHint={`legend-term-${currElement.title}`}
      tooltip={{
        title: legendTerms[index].title,
        text: legendTerms[index].text,
      }}
    >
      <Legend type={legendTerms[index].type}>
        <AriaHidden>{legendHiddenValues[index]}</AriaHidden>
        <span>{legendTerms[index].underline}</span>
      </Legend>
    </TooltipCaps>
  ));

  let graphAltText = ``;
  legendTerms.forEach((currElement, index) => {
    graphAltText += `${legendHiddenValues[index]} ${currElement.title},`;
  });

  const oopGraphLine = (
    <GraphLine aria-hidden>
      <Line data-testid="oopEstimate" percent={oopEstimatePercent} type="out-of-pocket" position="end">
        <span>{oopEstimateDisplay}</span>
      </Line>
    </GraphLine>
  );

  const contributionGraphLine = (
    <GraphLine aria-hidden>
      <Line data-testid="employerContribution" percent={employerPercent} type="employer" position="start">
        <span>{employerDisplay}</span>
      </Line>
      <Line
        data-testid="yourContribution"
        percent={contributionPercent}
        type="recommended"
        position={contributionPosition as LinePosition}
      >
        <span>{contributionDisplay}</span>
      </Line>
      <Line
        data-testid="remainingExposure"
        percent={remainingExposurePercent}
        type="remaining"
        position="end"
      >
        <span>{remainingExposureDisplay}</span>
      </Line>
    </GraphLine>
  );

  return (
    <>
      <section className="chart-container" role="img" aria-label={graphAltText}>
        {contributionGraphLine}
        {oopGraphLine}
      </section>
      <aside className="legend">{legend}</aside>
    </>
  );
};
