import {
  Box,
  Flex,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Square,
  Text as ChakraText,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';

import { TextField } from 'Containers/App/types';
import ExplainerModal from 'Containers/ResultPage/ExplainerModal';
import { Button, Icon, IconTypes, Paragraph } from 'DesignLibrary/atoms';
import { ButtonWrapper } from 'Shared/PlanFit/styled';
import RichText from 'Shared/RichText';
import Text from 'Shared/Text';
import { PeopleLikeYou } from 'Utils/apiTypes';

export interface PlanFitProps {
  // fit: string;
  score: number;
  variant?: 'bar' | 'tag' | 'icon';
  plyData?: PeopleLikeYou | Record<string, never>;
}

const BEST_FIT_SCORE = 99;
const GREAT_FIT_SCORE = 90;
const FAIR_FIT_SCORE = 75;

const PlanFitWhy = ({ tooltip, color, plyData }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Popover>
        <PopoverTrigger>
          <ButtonWrapper>
            <Button textColor={color} size="xsmall" buttonType="transparent" onClick={() => null}>
              <Text field="health_section.recommendation.plan_fit_labels.why_button_text" />
            </Button>
          </ButtonWrapper>
        </PopoverTrigger>
        <Portal>
          <PopoverContent zIndex="popover" border="none" _focus={{ outline: 'none' }}>
            <PopoverBody boxShadow="lg" p={4}>
              <Paragraph size="small">
                <RichText
                  field={tooltip}
                  vars={[
                    <Box
                      color="var(--primary-blue)"
                      fontWeight="600"
                      textDecoration="underline"
                      cursor="pointer"
                      display="inline"
                      onClick={onOpen}
                      key="plan-fit"
                    >
                      <Text field="health_section.recommendation.plan_fit_labels.plan_fit_link_text" />
                    </Box>,
                  ]}
                />
              </Paragraph>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
      <ExplainerModal isOpen={isOpen} handleClose={onClose} plyData={plyData} />
    </>
  );
};

interface StringOptionsT {
  best: string;
  great: string;
  fair: string;
  poor: string;
}

interface IconOptionsT {
  best: IconTypes;
  great: IconTypes;
  fair: IconTypes;
  poor: IconTypes;
}

interface ColorOptionsT {
  best: { bg: string; color: string };
  great: { bg: string; color: string };
  fair: { bg: string; color: string };
  poor: { bg: string; color: string };
}

export const PlanFit = ({ score, variant = 'bar', plyData }: PlanFitProps) => {
  // Define Plan Fit Labels
  const labelLocation = 'health_section.recommendation.plan_fit_labels';
  const labelOptions: StringOptionsT = {
    best: `${labelLocation}.best_fit_label`,
    great: `${labelLocation}.great_fit_label`,
    fair: `${labelLocation}.fair_fit_label`,
    poor: `${labelLocation}.poor_fit_label`,
  };

  // Define Plan Fit Icons
  const iconOptions: IconOptionsT = {
    best: 'Star',
    great: null,
    fair: null,
    poor: null,
  };

  // Define Plan Fit Colors
  const colorOptions: ColorOptionsT = {
    best: { bg: '--primary-green', color: '--primary-white' },
    great: { bg: '--primary-green', color: '--primary-white' },
    fair: { bg: '--colors-alex-orange', color: '--text-black' },
    poor: { bg: '--colors-alex-gray', color: '--text-black' },
  };

  // Define Plan Fit Tooltips
  const tooltipLocation = 'health_section.recommendation.plan_fit_labels';
  const tooltipOptions: StringOptionsT = {
    best: `${tooltipLocation}.best_fit_tooltip`,
    great: `${tooltipLocation}.great_fit_tooltip`,
    fair: `${tooltipLocation}.fair_fit_tooltip`,
    poor: `${tooltipLocation}.poor_fit_tooltip`,
  };

  // Set Current Parameters
  let currentValues = {
    label: labelOptions.poor,
    icon: iconOptions.poor,
    color: colorOptions.poor,
    tooltip: tooltipOptions.poor,
  };

  if (score >= BEST_FIT_SCORE) {
    // BEST
    currentValues = {
      label: labelOptions.best,
      icon: iconOptions.best,
      color: colorOptions.best,
      tooltip: tooltipOptions.best,
    };
  } else if (score >= GREAT_FIT_SCORE) {
    // GREAT
    currentValues = {
      label: labelOptions.great,
      icon: iconOptions.great,
      color: colorOptions.great,
      tooltip: tooltipOptions.great,
    };
  } else if (score >= FAIR_FIT_SCORE) {
    // FAIR
    currentValues = {
      label: labelOptions.fair,
      icon: iconOptions.fair,
      color: colorOptions.fair,
      tooltip: tooltipOptions.fair,
    };
  } else {
    currentValues = {
      // POOR
      label: labelOptions.poor,
      icon: iconOptions.poor,
      color: colorOptions.poor,
      tooltip: tooltipOptions.poor,
    };
  }

  const dataTestId = 'plan-fit-tier';

  // Render Icon
  const renderIcon = currentValues.icon ? (
    <Icon type={currentValues.icon} weight="duotone" color={currentValues.color.color} />
  ) : null;

  // Render Title + Icon
  const renderTitle = (
    <Flex align="center" gap={2}>
      {renderIcon}
      <ChakraText
        as="p"
        textTransform="uppercase"
        fontWeight={800}
        fontSize="xs"
        color={`var(${currentValues.color.color})`}
      >
        <Text field={currentValues.label as TextField} />
      </ChakraText>
    </Flex>
  );

  // Render Component

  // IF SIZE = ICON
  if (variant === 'icon') {
    return (
      <Square data-testid={dataTestId} borderRadius={6} size={8} bg={`var(${currentValues.color.bg})`}>
        {renderIcon}
      </Square>
    );
  }

  // IF SIZE = TAG
  if (variant === 'tag') {
    return (
      <Flex
        display="inline-flex"
        align="center"
        borderRadius={6}
        h={8}
        pl={2}
        pr={4}
        bg={`var(${currentValues.color.bg})`}
        data-testid={dataTestId}
      >
        {renderTitle}
      </Flex>
    );
  }

  // IF SIZE = BAR
  return (
    <Flex
      h={10}
      px={3}
      borderTopLeftRadius={6}
      borderTopRightRadius={6}
      align="center"
      justify="space-between"
      bg={`var(${currentValues.color.bg})`}
      data-testid={dataTestId}
    >
      {renderTitle}
      <PlanFitWhy tooltip={currentValues.tooltip} color={currentValues.color.color} plyData={plyData} />
    </Flex>
  );
};
