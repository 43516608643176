import React, { FC, ChangeEvent } from 'react';

import { Icon } from 'DesignLibrary/atoms/Icon';
import { Paragraph, ParagraphInterface } from 'DesignLibrary/atoms/typography';

import { CheckboxLabel, PseudoCheckbox } from './styled';

export interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  sizing?: 'small' | 'normal';
  color?: string;
  disabled?: boolean;
  interactive?: boolean;
  checked?: boolean;
  testId?: string;
  handleChange: (e: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  textStylingOverride?: ParagraphInterface;
}

const Checkbox: FC<CheckboxProps> = ({
  sizing = 'normal',
  color = '--primary-blue',
  disabled = false,
  interactive = true,
  checked = false,
  testId = 'checkbox',
  handleChange,
  textStylingOverride,
  children,
  ...rest
}): JSX.Element => {
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    // This should be handled by the browser, but wasn't performing properly in tests.
    // Either way, we don't need to trigger event callbacks for disabled inputs
    if (disabled) {
      return;
    }

    const val = !checked;
    handleChange(e, val);
  };

  return (
    <CheckboxLabel color={color} data-testid={testId} interactive={interactive}>
      <input type="checkbox" disabled={disabled} checked={checked} onChange={onChange} {...rest} />
      <PseudoCheckbox
        className={`pseudo-checkbox ${checked && 'checked'}`}
        data-testid="pseudo-checkbox"
        sizing={sizing}
      >
        <div className="checkbox-button" aria-hidden="true">
          {checked && (
            <Icon data-testid="check" size={sizing} color="--background-white" type="Check" weight="bold" />
          )}
        </div>
        {children && (
          <Paragraph size="small" weight="bold" color="--text-black" {...textStylingOverride}>
            {children}
          </Paragraph>
        )}
      </PseudoCheckbox>
    </CheckboxLabel>
  );
};

export default Checkbox;
