import styled from 'styled-components';

import { ParagraphSmallStyle } from 'DesignLibrary/atoms';

export const DrugList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: var(--sizing-xs);
`;

export const Prescription = styled.div`
  ${ParagraphSmallStyle('--primary-white', 600)}
  background: var(--primary-black);
  border-radius: var(--border-radius-xs);
  display: inline-flex;
  align-items: center;
  height: 40px;
  padding: 0 var(--sizing-xs) 0 var(--sizing-sm);

  button {
    :focus-visible {
      outline-color: var(--primary-white);
    }
  }
`;

export const PrescriptionContainer = styled.div`
  & > * + * {
    margin-top: var(--sizing-md);
  }
`;
