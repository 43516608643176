import styled from 'styled-components';

import { media } from 'DesignLibrary/global';

import { Group } from '../../_shared/styled';

export const LowMedHighContainer = styled.section`
  width: 100%;
  margin: var(--sizing-md) 0;

  ${media.TABLET`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0 var(--sizing-sm);
    align-items: start;
  `}
`;

interface DistContainerProps {
  group: Group;
}

export const DistContainer = styled.section<DistContainerProps>`
  border-radius: var(--border-radius-xs);
  max-width: 360px;
  background: ${({ group }) => {
    switch (group) {
      case 'low':
        return 'var(--colors-green-50)';
      case 'moderate':
        return 'var(--colors-yellow-50)';
      case 'high':
        return 'var(--colors-red-50)';
      default:
        return 'var(--colors-green-50)';
    }
  }};
  padding: var(--sizing-xs);
  margin-bottom: var(--sizing-sm);

  ${media.TABLET`
    margin-bottom: var(--sizing-sm);
    max-width: none;
  `}

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--sizing-xs);

    .title {
      display: flex;
      align-items: center;
      gap: 12px;
    }

    .percent {
      display: inline-block;
      font-weight: 600;
    }
  }
`;
