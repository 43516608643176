import styled, { css } from 'styled-components';

import { breakpoint } from 'DesignLibrary/global';
import { COLORS, Gradient, PrimaryColors, rgba } from 'DesignLibrary/vars';

interface AccountTypeWrapperProps {
  account: 'hsa' | 'hra' | 'fsa' | 'lpfsa';
}

const gradientHelper = (color: PrimaryColors, direction: string) =>
  Gradient([COLORS[color][100], rgba(COLORS[color][100], 0)], direction);

export const AccountTypeWrapper = styled.section<AccountTypeWrapperProps>`
  position: relative;
  z-index: 0;
  margin-left: calc(var(--sizing-sm) * -1);
  margin-right: calc(var(--sizing-sm) * -1);
  margin-bottom: var(--sizing-lg);
  padding: var(--sizing-md) var(--sizing-sm);
  padding-bottom: var(--sizing-xl);

  ${breakpoint.TABLET} {
    padding: var(--sizing-lg);
    margin-left: 0;
    margin-right: 0;
    padding-bottom: var(--sizing-2xl);
  }

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    left: 0;
    z-index: -1;
  }

  &:before {
    top: 0;
    height: 320px;

    ${breakpoint.TABLET} {
      border-radius: var(--border-radius-sm) var(--border-radius-sm) 0 0;
    }
  }

  &:after {
    bottom: 0;
    height: var(--sizing-4xl);

    ${breakpoint.TABLET} {
      border-radius: 0 0 var(--border-radius-sm) var(--border-radius-sm);
    }
  }

  ${({ account }) =>
    account === 'hsa' &&
    css`
      &:before {
        background: ${gradientHelper('blue', 'to bottom')};
      }
      &:after {
        background: ${gradientHelper('blue', 'to top')};
      }
    `}

  ${({ account }) =>
    account === 'fsa' &&
    css`
      &:before {
        background: ${gradientHelper('blue', 'to bottom')};
      }
      &:after {
        background: ${gradientHelper('blue', 'to top')};
      }
    `}

  ${({ account }) =>
    account === 'hra' &&
    css`
      &:before {
        background: ${gradientHelper('blue', 'to bottom')};
      }
      &:after {
        background: ${gradientHelper('blue', 'to top')};
      }
    `}
`;
