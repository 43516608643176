import styled from 'styled-components';

import { media } from 'DesignLibrary/global';
import { FadeInLeftKeyframes } from 'DesignLibrary/keyframes';

export const BenefitContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-flow: row wrap;
  padding-right: 0.7rem;
  margin: 1rem 0;
  animation: ${FadeInLeftKeyframes} 0.5s forwards;

  .pseudo-checkbox .paragraph {
    font-weight: 500;
  }

  .pseudo-checkbox .subject-to-deductible {
    font-size: 14px;
  }

  ${media.TABLET`
    .pseudo-checkbox .paragraph {
      display: none;
    }
  `}

  ${media.DESKTOP`
    margin: .5rem 0;
  `}

  .display-text {
    margin: 0 0 1rem 0;
    width: 100%;

    ${media.DESKTOP`
      width: 20%;
      margin: 0;
    `}
  }

  .hidden-toggle {
    opacity: 0;
  }

  div[data-testid='textInputWrapper'],
  input[data-testid='textInput'] {
    width: 160px;
    max-width: 160px;
  }
`;
