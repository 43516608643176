import { push } from 'connected-react-router';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { makeSelectCommercialField, makeSelectConfigField } from 'Containers/App/selectors';
import {
  makeGetHouseholdId,
  makeGetHouseholdMembers,
  makeGetSelectedEnrollmentEventId,
  makeGetSelectedPublicationKey,
} from 'Containers/ProfilePage/selectors';
import { getSupplementalPlansRequest } from 'Utils/api';
import * as API_TYPES from 'Utils/apiTypes';
import { ERROR_PATH } from 'Utils/urls';

import {
  getSupplementalPlansFailure,
  getSupplementalPlansSuccess,
  getSupplementalPlansSuccessSetCache,
  setDefaultCoveredMembers,
  setDefaultSelectedPlans,
} from './actions';
import { GET_SUPPLEMENTAL_PLANS_REQUEST, GET_SUPPLEMENTAL_PLANS_SUCCESS } from './constants';
import { makeSelectOverviewField } from './selectors';

// get supplemental plans for all member id's
export function* getXhrSupplementalPlans() {
  const householdId = yield select(makeGetHouseholdId());
  const enrollmentEventId = yield select(makeGetSelectedEnrollmentEventId());
  const selectedHealthPlan = yield select(makeSelectCommercialField('selectedHealthPlan'));
  const selectedPublicationKey = yield select(makeGetSelectedPublicationKey());
  const selectedHealthPlanId =
    selectedHealthPlan?.publication === selectedPublicationKey ? selectedHealthPlan?.plan?.external_id : null;

  try {
    const resp: API_TYPES.GetSupplementalPlansResponse = yield call(
      getSupplementalPlansRequest,
      householdId,
      undefined,
      selectedHealthPlanId,
      enrollmentEventId,
    );
    yield put(getSupplementalPlansSuccess(resp));
  } catch (err) {
    yield put(getSupplementalPlansFailure(err as Error));
    yield put(push(ERROR_PATH));
  }
}

export function* setDefaultOverviewStateSaga() {
  const client = yield select(makeSelectConfigField('client'));
  const householdMembers = yield select(makeGetHouseholdMembers());
  const supplementalPlans = yield select(makeSelectOverviewField('supplemental_plans_full_household'));
  const selectedSupplementalPlans = yield select(makeSelectOverviewField('selected_plans'));

  // Construct form state for household member selection in supplemental plan modal
  yield put(setDefaultCoveredMembers(householdMembers, supplementalPlans, client));

  // Set auto enrolled plans as default selected plans
  yield put(setDefaultSelectedPlans(supplementalPlans, selectedSupplementalPlans));

  const memberIds = householdMembers.map((member) => member.external_id);
  yield put(getSupplementalPlansSuccessSetCache(supplementalPlans, memberIds));
}

export default function* getOverviewPageSaga() {
  yield all([
    takeLatest(GET_SUPPLEMENTAL_PLANS_REQUEST, getXhrSupplementalPlans),
    takeLatest(GET_SUPPLEMENTAL_PLANS_SUCCESS, setDefaultOverviewStateSaga),
  ]);
}
