import { ContentfulSpousalPlanComparisonSection } from 'ContentfulDefaults/types/spousal';

export const spousal: {
  spousal_plan_comparison: ContentfulSpousalPlanComparisonSection;
} = {
  spousal_plan_comparison: {
    contentful_name: 'Spousal Plan Comparison (DEFAULT DO NOT CHANGE)',
    button_text: {
      remove: '- Eliminar beneficio',
      openPDF: '¿Qué necesito para comparar un plan?',
      complete: 'Compare este plan',
      drugTier: 'Agregar un nivel',
      editModal: 'Edit your plan',
      openModal: 'Comparar un plan',
      benefitToggle: {
        copay: 'Copago',
        coinsurance: 'Coaseguro',
      },
      premiumPeriod: {
        prefix: 'por',
        options: ['Semanal', 'Quincenal', 'Semi mensual', 'Mensual', 'Anualmente'],
        periodNames: {
          weekly: 'Semanal',
          monthly: 'Mensual',
          annually: 'Anualmente',
          biweekly: 'Quincenal',
          semimonthly: 'Semi mensual',
        },
      },
    },
    page_title: 'Comparar planes conyugales',
    heading: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Comparemos un plan para cónyuge o pareja',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-1',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Sabemos que puede tener opciones de seguro médico distintas de las que le proporciona su empleador. Puede utilizar la siguiente herramienta para ingresar la información del plan de su socio para realizar comparaciones con las opciones de su empleador.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    necessary_information: {
      items: [
        {
          link: 'https://picwellapppublicdata.s3.amazonaws.com/Build-a-plan_instructions-Spanish.pdf#page=3',
          name: 'Prima',
          type: 'premium',
        },
        {
          link: 'https://picwellapppublicdata.s3.amazonaws.com/Build-a-plan_instructions-Spanish.pdf#page=6',
          name: 'Gastos máximos de bolsillo',
          type: 'oopMax',
        },
        {
          link: 'https://picwellapppublicdata.s3.amazonaws.com/Build-a-plan_instructions-Spanish.pdf#page=2',
          name: 'Resumen de prestaciones',
          type: 'benefits',
        },
        {
          link: 'https://picwellapppublicdata.s3.amazonaws.com/Build-a-plan_instructions-Spanish.pdf#page=4',
          name: 'Deducible',
          type: 'deductible',
        },
      ],
      question: '¿Qué información del plan necesito?',
    },
    details_title: 'Detalles del plan',
    threshold_warning: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Asegúrese de ingresar ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'underline',
                },
              ],
              value: 'los valores de umbral familiar',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value: ' para los campos a continuación.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    premium_question: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '¿Cuáles son las ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'underline',
                },
              ],
              value: 'primas',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value: ' del plan?',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    deductible_question: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '¿Cuál es el ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'underline',
                },
              ],
              value: 'deducible',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value: ' anual del plan?',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    deductible_subfields: {
      expand: 'Espere, mi plan tiene deducibles médicos y de medicamentos separados',
      contract: 'No importa, mi plan tiene un deducible conjunto',
      drugText: 'Deducible por medicamentos',
      healthText: 'Deducible médico',
    },
    oop_max_question: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '¿Cuál es el desembolso máximo anual del plan?',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    oop_max_fields: {
      expand: 'Espere, mi plan tiene máximos de OOP médicos y de medicamentos separados',
      contract: 'No importa, mi plan tiene un máximo de OOP conjunto',
      drugText: 'Drogas OOP Max',
      healthText: 'Gasto máximo de bolsillo de servicios médicos',
    },
    validation_messages: {
      oopMaxValidationError:
        'Los gastos máximos de bolsillo de los planes elegibles para HSA no pueden ser mayores que {x}',
      benefitCategoryRequired: 'Esta categoría debe estar sujeta al deducible en un plan elegible para HSA',
      deductibleExceedsOopMax: 'El deducible no puede ser mayor que el gasto máximo de bolsillo',
      deductibleValidationError: 'Los deducibles para los planes elegibles para HSA deben ser mínimo de {x}',
      splitOopMaxValidationError:
        'Los planes elegibles para HSA no tienen gastos máximos de bolsillo de salud y medicamentos por separado',
      deductibleExceedsSplitOopMax:
        'El deducible no puede ser mayor que la suma de los gastos máximos de bolsillo de servicios médicos y de medicamentos.',
      splitDeductibleExceedsOopMax:
        'La suma de los deducibles médicos y de medicamentos no pueden ser mayores que los gastos máximos de bolsillo.',
      splitDeductibleValidationError:
        'Los planes elegibles para HSA no tienen deducibles separados de salud y medicamentos',
      drugDeductibleExceedsDrugOopMax:
        'El deducible de medicamentos no puede ser mayor que el gasto máximo de bolsillo de medicamentos.',
      healthDeductibleExceedsHealthOopMax:
        'El deducible de servicios médicos no puede ser mayor que el gasto máximo de bolsillo de servicios médicos.',
    },
    hsa_eligible_question: {
      nodeType: 'document',
      data: {},
      content: [
        {
          nodeType: 'paragraph',
          content: [
            {
              nodeType: 'text',
              value: 'This plan will have ',
              marks: [],
              data: {},
            },
            {
              nodeType: 'text',
              value: 'HSA contributions',
              marks: [
                {
                  type: 'underline',
                },
              ],
              data: {},
            },
            {
              nodeType: 'text',
              value: ' from you or your employer',
              marks: [],
              data: {},
            },
          ],
          data: {},
        },
      ],
    },
    hsa_contribution_question: {
      yearly: 'por año',
      question: '¿Cuál es la contribución a la HSA del empleador?',
    },
    hra_eligible_question: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '¿Este plan es ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'underline',
                },
              ],
              value: 'elegible para HRA',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value: '?',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    hra_contribution_question: {
      yearly: 'por año',
      question: '¿Cuál es la contribución del empleador a la HRA?',
    },
    benefits_title: 'Beneficios del plan',
    deductible_benefit: {
      text: 'Es posible que se brinden algunos beneficios antes de alcanzar su deducible. Para estos beneficios, desmarque la casilla a continuación.',
      title: 'Sujeto a deducible',
    },
    benefit_groups: {
      drug: {
        title:
          '¿Qué pagará si necesita medicamentos para curar su enfermedad o afección (seleccione TODOS los beneficios de medicamentos para su plan) ...',
        types: {
          specialty: 'Especialidad (Nivel 4)',
          generic_tier_1: 'Genérico (nivel 1)',
          preferred_tier_2: 'Marca preferida (Nivel 2)',
          non_preferred_tier_3: 'Marca no preferida (Nivel 3)',
        },
      },
      office: {
        title: '¿Qué pagará si visita el consultorio de un proveedor de atención médica por ...',
        types: {
          specialist: 'Una visita a un especialista',
          primaryCare: 'Una visita de atención primaria',
          preventiveCare: 'Cuidado preventivo',
        },
      },
      testing: {
        title: '¿Qué pagará si tienes una prueba ...',
        types: {
          imaging: 'Imágenes',
          laboratory: 'Laboratorio',
        },
      },
      hospital: {
        title: '¿Qué pagará si tiene una estadía en el hospital',
        types: {
          hospitalFacilityFee: 'Tarifa de instalación',
        },
      },
      emergency: {
        title: '¿Qué pagará si necesita atención médica inmediata ...',
        types: {
          urgentCare: 'Urgente que',
          emergencyCare: 'Cuidados de emergencia',
        },
      },
      outpatientSurgery: {
        title: '¿Qué pagará si tiene una cirugía ambulatoria',
        types: {
          services: 'Servicios médicos y quirúrgicos',
          outpatientFacilityFee: 'Tarifa de instalación',
        },
      },
    },
    card: {
      tag: 'Plan ingresado',
      icon: 'https://picwellapppublicdata.s3.amazonaws.com/contentful/plancomparison/compare-scale.svg',
      text: 'Sabemos que puede tener otras opciones de seguro médico. Puede usar esta herramienta para ingresar datos y comparar otro plan, como el plan de un cónyuge o pareja.',
      title: '¿Quiere ver cómo se comparan otras opciones?',
      editIcon: 'https://picwellapppublicdata.s3.amazonaws.com/contentful/plancomparison/edit.svg',
      editText:
        'Solo puede comparar un plan que no sea de un empleador a la vez. Haga clic aquí para editar su plan.',
      editTitle: 'Edita tu plan ingresado',
    },
    instructions_url: 'https://picwellapppublicdata.s3.amazonaws.com/Build-a-plan_instructions-Spanish.pdf',
  },
};

export default spousal;
