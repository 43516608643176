import React from 'react';

import Cone from 'Assets/other/construction.png';
import { H2, Paragraph } from 'DesignLibrary/atoms';

import { ConstructionWrapper } from './styled';

export const ConstructionPage = () => (
  <ConstructionWrapper>
    <img src={Cone} alt="construction cone" />
    <section className="text">
      <H2>Under Construction</H2>
      <Paragraph>Check back soon.</Paragraph>
    </section>
  </ConstructionWrapper>
);
