import styled from 'styled-components';

import { hideAccessibleCheckmark } from 'DesignLibrary/utils';

export const LocationLabel = styled.label`
  cursor: pointer;

  input[type='radio'] {
    ${hideAccessibleCheckmark}
  }

  .pseudo-radio:hover {
    background: var(--colors-opaque-10);
  }
`;
