import styled from 'styled-components';

import { breakpoint } from 'DesignLibrary/global';

export const IncentiveQuestionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border: 2px solid var(--border-gray);
  border-radius: var(--border-radius-xs);
  justify-content: space-between;

  > div {
    padding: var(--sizing-sm);
  }

  .description {
    h5 {
      margin-bottom: var(--sizing-sm);
      font-weight: 900 !important;
    }
  }

  .question {
    background: var(--background-gray);
    flex-shrink: 0;

    .paragraph {
      margin-bottom: var(--sizing-sm);
    }

    input {
      width: 100%;
    }
  }

  ${breakpoint.TABLET} {
    flex-direction: row;

    > div {
      padding: var(--sizing-md);
    }

    .question {
      width: var(--sizing-4xl);
    }
  }
`;
