import { createSelector } from 'reselect';

import { GlobalReducerState } from 'app/reducers';

import { ReviewPageReducerState } from './reducer';

const selectReviewPageDomainAsJS = (state: GlobalReducerState): ReviewPageReducerState => state.reviewPage;

const makeSelectReviewPageField = <K extends keyof ReviewPageReducerState>(field: K) =>
  createSelector(
    selectReviewPageDomainAsJS,
    (substate: ReviewPageReducerState): ReviewPageReducerState[K] => substate[field],
  );

export { selectReviewPageDomainAsJS, makeSelectReviewPageField };
