import React from 'react';
import ReactPlayer from 'react-player';

import { sendInsightsEvent } from 'Utils/insights';

export interface VideoProps {
  title: string;
  url: string;
  width: number | string;
  height: number | string;
  playing?: boolean;
  muted?: boolean;
  controls?: boolean;
  testId?: string;
  onStart?: () => void;
  onEnded?: () => void;
}

const Video = ({
  title,
  url,
  width = 365,
  height = 200,
  playing = false,
  muted = false,
  controls = true,
  testId = 'react-player',
  onStart,
  onEnded,
  ...rest
}: VideoProps): JSX.Element => {
  const handleStart = (): void => {
    sendInsightsEvent(null, 'video_started', {
      title,
      url,
    });
    if (onStart) onStart();
  };

  const handleEnd = (): void => {
    sendInsightsEvent(null, 'video_ended', {
      title,
      url,
    });
    if (onEnded) onEnded();
  };

  return (
    <ReactPlayer
      data-testid={testId}
      url={url}
      controls={controls}
      width={width}
      height={height}
      playing={playing}
      onStart={handleStart}
      onEnded={handleEnd}
      volume={1} // Must be set to enable muted
      muted={muted}
      playsinline
      {...rest}
    />
  );
};

export default Video;
