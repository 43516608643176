import React from 'react';

import { AlexProducts } from 'Containers/App/types';

import { ToggleLabel, TogglePseudo } from './styled';

export interface ToggleItemProps {
  product: AlexProducts;
  selectedProduct: AlexProducts | null;
  image: string;
  children: React.ReactNode;
  setSelectedProduct: (product: AlexProducts) => void;
  ariaLabelledBy: string;
  ariaDescribedBy: string;
}

export const ToggleItem = ({
  product,
  selectedProduct,
  image,
  children,
  setSelectedProduct,
  ariaLabelledBy,
  ariaDescribedBy,
}: ToggleItemProps) => (
  <ToggleLabel>
    <input
      type="radio"
      checked={selectedProduct === product}
      value={product}
      aria-labelledby={ariaLabelledBy}
      aria-describedby={ariaDescribedBy}
      onChange={() => setSelectedProduct(product)}
    />
    <TogglePseudo
      className="pseudo-answer"
      aria-hidden="true"
      selected={selectedProduct === product}
      data-testid={`${product}-product-toggle`}
    >
      <div className="product-image">
        <div className="pseudo-radio" />
        <img src={image} alt="" />
      </div>
      <div className="bullets">{children}</div>
    </TogglePseudo>
  </ToggleLabel>
);
