import styled from 'styled-components';

import { media } from 'DesignLibrary/global';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  gap: var(--sizing-sm);
  border-bottom: var(--border);
  padding-bottom: var(--sizing-lg);
  margin-bottom: var(--sizing-lg);
  overflow: hidden;

  ${media.TABLET`
  gap: var(--sizing-md);
  padding-bottom: var(--sizing-xl);
  margin-bottom: var(--sizing-xl);
  `}

  @media print {
    border: none;
    margin-bottom: 0;
    page-break-after: always;
  }
`;

export const ShowMoreButton = styled.div`
  border-top: var(--border);
  background: var(--background-gray-light);

  button {
    border-radius: 0;
  }
`;
