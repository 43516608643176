import React from 'react';

import ContinueIllustration from 'Assets/banner/profile-complete-illustration.png';
import { useTextContext } from 'Contexts/textContext';
import { FadeUp, H5, Paragraph } from 'DesignLibrary/atoms';

import { ContinueContainer } from './styled';

export const ContinueBanner = () => {
  const { retrieveContentfulData } = useTextContext();

  return (
    <FadeUp isOpen>
      <ContinueContainer>
        <div className="banner-image">
          <img src={ContinueIllustration} alt="" />
        </div>
        <div className="content">
          <H5 as="h2">{retrieveContentfulData<string>('profile_section.profile_complete_header')}</H5>
          <Paragraph>{retrieveContentfulData<string>('profile_section.profile_complete_body')}</Paragraph>
        </div>
      </ContinueContainer>
    </FadeUp>
  );
};
