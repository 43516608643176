import React, { useState } from 'react';

import {
  RxSearchResults,
  Member,
  UpdateMemberFields,
  MemberSectionConfigFields,
} from 'Containers/ProfilePage/types';
import { useTextContext } from 'Contexts/textContext';
import { Button, Paragraph } from 'DesignLibrary/atoms';
import { AriaHiddenAlert } from 'Utils/accessibility';

import DrugSearch from './DrugSearch';
import { DrugList, Prescription, PrescriptionContainer } from './styled';

export interface PrescriptionFormProps {
  results: RxSearchResults;
  isLoading: boolean;
  isDisabled: boolean;
  isMemberComplete: boolean;
  member: Member;
  memberConfig: MemberSectionConfigFields;
  queryDrugs: (value: string) => void;
  handleEdit: (fields: UpdateMemberFields, memberConfig: MemberSectionConfigFields) => void;
}

const PrescriptionForm = ({
  results,
  isLoading,
  isDisabled,
  isMemberComplete,
  member,
  memberConfig,
  handleEdit,
  queryDrugs,
}: PrescriptionFormProps) => {
  const [hiddenAlert, setHiddenAlert] = useState<string | null>(null);
  const { retrieveContentfulData } = useTextContext();

  const handleAddDrug = (key, displayName, drugForm) => {
    const prescriptions = member.prescriptions;
    const ndc = results.dropdown[key][0].value;
    const newDrug = {
      ndc,
      displayName,
      drugForm,
    };
    const isDuplicate = prescriptions.some((i) => i.ndc === newDrug.ndc);
    if (isDuplicate) {
      return;
    }

    let addDrugAlert = retrieveContentfulData<string>('profile_section.member.accessibility.add_drug_alert');

    addDrugAlert = addDrugAlert.replace(/{x}/, newDrug.displayName);

    setHiddenAlert(addDrugAlert);

    handleEdit(
      {
        ...member,
        prescriptions: [...prescriptions, newDrug],
      },
      memberConfig,
    );
  };

  const handleRemoveDrug = (index) => {
    const prescriptions = member.prescriptions;

    let removeDrugAlert = retrieveContentfulData<string>(
      'profile_section.member.accessibility.remove_drug_alert',
    );

    removeDrugAlert = removeDrugAlert.replace(/{x}/, prescriptions[index].displayName);

    setHiddenAlert(removeDrugAlert);

    prescriptions.splice(index, 1);

    handleEdit(
      {
        ...member,
        prescriptions,
      },
      memberConfig,
    );
  };

  const removeDrugLabel = retrieveContentfulData<string>(
    'profile_section.member.accessibility.remove_drug_button',
  );

  const drugSearchLabel = retrieveContentfulData<string>('profile_section.member.drug_search_label');
  const drugSearchLabelId = `${member.external_id}-drug-search-label`;

  return (
    <PrescriptionContainer className="rx-search">
      <AriaHiddenAlert>{hiddenAlert}</AriaHiddenAlert>
      <Paragraph id={drugSearchLabelId} size="normal">
        {drugSearchLabel}
      </Paragraph>
      <DrugSearch
        handleAddDrug={handleAddDrug}
        queryDrugs={(query) => queryDrugs(query)}
        suggestions={results.search}
        isLoading={isLoading}
        isDisabled={isDisabled}
        isMemberComplete={isMemberComplete}
        placeholder={retrieveContentfulData<string>('profile_section.member.drug_search_placeholder', '')}
        labelId={drugSearchLabelId}
      />
      {member.prescriptions.length > 0 ? (
        <DrugList className="added-rx-list">
          {member.prescriptions.map((rx, index) => (
            <Prescription key={rx.displayName}>
              {rx.displayName}
              <Button
                aria-label={removeDrugLabel.replace(/{x}/, rx.displayName)}
                buttonType="transparent"
                size="xsmall"
                iconLeft="X"
                iconProps={{ size: 'small', color: '--colors-gray-400' }}
                onClick={() => handleRemoveDrug(index)}
                data-testid={`${rx.ndc}-remove`}
              />
            </Prescription>
          ))}
        </DrugList>
      ) : null}
    </PrescriptionContainer>
  );
};

export default PrescriptionForm;
