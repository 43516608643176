import { Box } from '@chakra-ui/react';
import React from 'react';

import { Paragraph } from 'DesignLibrary/atoms';
import RichText from 'Shared/RichText';
import Text from 'Shared/Text';
import { toDollars } from 'Utils/helpers';

import { ServiceContainer, PercentContainer, PercentBar, DistBar, CostContainer, Number } from './styled';

const renderP25Tip = () => ({
  text: (
    <Paragraph lineHeight={1.75}>
      {' '}
      <Text field="people_like_you.tooltips.p25" />{' '}
    </Paragraph>
  ),
});
const renderP50Tip = () => ({
  text: (
    <Paragraph lineHeight={1.75}>
      {' '}
      <Text field="people_like_you.tooltips.p50" />{' '}
    </Paragraph>
  ),
});
const renderP75Tip = () => ({
  text: (
    <Paragraph lineHeight={1.75}>
      {' '}
      <Text field="people_like_you.tooltips.p75" />{' '}
    </Paragraph>
  ),
});

interface ServiceUtilizationProps {
  usageRate: number;
  spendingP25: number;
  spendingP50: number;
  spendingP75: number;
  categoryDisplayText: string;
  activeCostCategory: string;
}

export const ServiceUtilization = ({
  usageRate,
  spendingP25,
  spendingP50,
  spendingP75,
  categoryDisplayText,
  activeCostCategory,
}: ServiceUtilizationProps) => {
  const displayUsageRate = usageRate > 0 ? usageRate : '<1';

  const displayP25 = toDollars(spendingP25);
  const displayP50 = toDollars(spendingP50);
  const displayP75 = toDollars(spendingP75);

  let barFillPercent = 100 * ((spendingP50 - spendingP25) / (spendingP75 - spendingP25));
  barFillPercent = Math.min(Math.max(20, barFillPercent), 80);

  return (
    <ServiceContainer id={`${activeCostCategory}-panel`} aria-labelledby={`${activeCostCategory}-tab`}>
      <section>
        <Paragraph lineHeight={1.75}>
          <Text field="people_like_you.service_utilization_text" vars={{ x: categoryDisplayText }} />
        </Paragraph>
        <PercentContainer>
          <PercentBar percent={usageRate}>
            <span>{usageRate >= 1 ? usageRate : '<1'}%</span>
          </PercentBar>
        </PercentContainer>
      </section>
      <CostContainer visible={usageRate >= 2} data-testid="CostDistBarContainer">
        <Paragraph lineHeight={1.75}>
          <RichText
            field="people_like_you.cost_distribution_bar_text"
            vars={[displayUsageRate, displayP25, displayP75, displayP50]}
            toolTips={[renderP25Tip(), renderP75Tip(), renderP50Tip()]}
          />
        </Paragraph>
        <DistBar percent={barFillPercent} aria-hidden>
          <Number data-testid="CostTextP25">{displayP25}</Number>
          <Number data-testid="CostTextP50" className="P50" percent={barFillPercent}>
            {displayP50}
          </Number>
          <Number data-testid="CostTextP75">{displayP75}</Number>
        </DistBar>
        <Box pt={4}>
          <Paragraph lineHeight={1.75}>
            <Text field="people_like_you.service_utilization_caveat_text" />
          </Paragraph>
        </Box>
      </CostContainer>
    </ServiceContainer>
  );
};
