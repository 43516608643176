import React from 'react';

import { TextField } from 'Containers/App/types';
import { ContentfulTextNoteType } from 'ContentfulDefaults/types/_shared';
import { useTextContext } from 'Contexts/textContext';
import { TextNote } from 'DesignLibrary/molecules/TextNote';
import RichText from 'Shared/RichText';

export interface ContentfulTextNoteProps {
  note?: ContentfulTextNoteType;
  sectionKey?:
    | 'unified_landing_page'
    | 'health_section'
    | 'review_section'
    | 'plan_customizations'
    | 'benefits_section_additional'
    | 'profile_section.member';
  sectionIndex?: number;
  className?: string;
}

export const ContentfulTextNote = ({
  note,
  sectionKey,
  sectionIndex = -1,
  className = '',
}: ContentfulTextNoteProps) => {
  if (note) {
    return (
      <TextNote
        shortDisplayText={<RichText fieldContent={note.short_text} field={undefined} noWrapper={false} />}
        buttonText={note.button_text}
        hasExpandedText={!!note.long_text}
        expandedText={<RichText fieldContent={note.long_text} field={undefined} noWrapper={false} />}
      />
    );
  }

  const { retrieveContentfulData } = useTextContext();

  let sectionPrefix = sectionKey as string;
  if (sectionIndex > -1) {
    sectionPrefix = `${sectionKey}[${sectionIndex}]`;
  }

  const hasShortText = Boolean(retrieveContentfulData<string>(`${sectionPrefix}.text_note.short_text`));

  const hasExpandedText = Boolean(retrieveContentfulData<string>(`${sectionPrefix}.text_note.long_text`));

  const buttonText = retrieveContentfulData<string>(`${sectionPrefix}.text_note.button_text`);

  if (!hasShortText) {
    return null;
  }

  return (
    <TextNote
      className={className}
      buttonText={buttonText}
      shortDisplayText={<RichText field={`${sectionPrefix}.text_note.short_text` as TextField} />}
      hasExpandedText={hasExpandedText}
      expandedText={<RichText field={`${sectionPrefix}.text_note.long_text` as TextField} />}
    />
  );
};
