import React from 'react';
import { connect, Selector } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import { GlobalReducerState } from 'app/reducers';
import {
  makeSelectCommercialField,
  makeSelectGlobalField,
  selectIsFirstPageView,
} from 'Containers/App/selectors';
import {
  makeGetCatchupEligible,
  makeGetHsaContributionLimit,
  makeGetRecommendedHsaContribution,
  makeSelectUserContributionLimit,
} from 'Containers/CommercialRoutes/selectors';
import { makeGetIsFsaEligible } from 'Containers/OverviewPage/selectors';
import { makeGetBuilderGoSettings } from 'Containers/ProfilePage/selectors';
import { ContentfulInfoBars } from 'ContentfulWrappers/ContentfulInfoBars';
import { ContentfulHeaderWrapper } from 'ContentfulWrappers/header';
import { FadeUp, Paragraph } from 'DesignLibrary/atoms';
import { PageLayout } from 'DesignLibrary/atoms/PageLayout';
import Plan from 'Models/plan';
import { Recommendation } from 'Types/entities';
import { BuilderGoSettings, GetHsaRecommendationResponse } from 'Utils/apiTypes';
import injectSaga from 'Utils/injectSaga';
import { TAX_SAVINGS_PATH } from 'Utils/urls';

import {
  getFsaTaxSavings,
  getHsaRecommendation,
  setHsaForecastInputs,
  setLockedFsaContribution,
  setLockedHsaContribution,
} from './actions';
import { EligibleCards } from './EligibleCards';
import { FsaSection, FsaSectionProps } from './FsaSection';
import { HsaSection, HsaSectionProps } from './HsaSection';
import saga from './saga';
import { makeSelectHsaData, makeSelectTaxSavingsField } from './selectors';
import { NotEligibleContainer, TaxSavingsContainer } from './styled';
import { ForecastData, Persona } from './types';

interface TaxSavingsPageStateProps {
  isFsaEligible: boolean;
  hsaData: GetHsaRecommendationResponse | Record<string, never>;
  forecastData: ForecastData | Record<string, never>;
  recommendedHsaContribution: number;
  isHsaRecommendationLoading: boolean;
  isForecastLoading: boolean;
  selectedPlan: Recommendation;
  lockedHsaContribution: number | null;
  persona: Persona;
  currentHsaBalance: number | null;
  userContributionLimit: number;
  individualHsaContributionLimit: number;
  familyHsaContributionLimit: number;
  isFirstPageView: boolean;
  catchupEligible: boolean;
  fsaData: any; // TODO: type this
  isFsaTaxSavingsLoading: boolean;
  lockedFsaContribution?: number | null;
  hsaContributionLimit: number;
  retirementAge: number;
  builderGoSettings: BuilderGoSettings | null;
}

interface TaxSavingsPageDispatchProps {
  fetchHsaRecommendation: () => void;
  fetchFsaTaxSavings: () => void;
  handleLockedHsaContribution: (contribution: number) => void;
  handleLockedFsaContribution: (contribution: number) => void;
  handleHsaForecastInputs: (balance: number, persona: Persona, retirementAge: number) => void;
}

export type TaxSavingsPageProps = TaxSavingsPageStateProps & TaxSavingsPageDispatchProps;

// I passed in a selector and dispatch action just to keep the top level structure we've got going on in the other pages w/o ts/lint yelling
export const TaxSavingsPage = ({
  isFsaEligible,
  hsaData,
  forecastData,
  recommendedHsaContribution,
  isHsaRecommendationLoading,
  isForecastLoading,
  selectedPlan,
  lockedHsaContribution,
  persona,
  currentHsaBalance,
  userContributionLimit,
  individualHsaContributionLimit,
  familyHsaContributionLimit,
  isFirstPageView,
  catchupEligible,
  fsaData,
  isFsaTaxSavingsLoading,
  lockedFsaContribution,
  hsaContributionLimit,
  retirementAge,
  fetchHsaRecommendation,
  fetchFsaTaxSavings,
  handleLockedHsaContribution,
  handleLockedFsaContribution,
  handleHsaForecastInputs,
  builderGoSettings,
}: TaxSavingsPageProps) => {
  // Determine if plan has employer contribution/match
  const plan = new Plan(selectedPlan);
  const { isEmployerMatching, hsaEligible, employerHsaPercentageMatch } = plan;
  const employerHsaContribution = isEmployerMatching
    ? (plan.employerHsaContributionLimit as number)
    : (plan.employerHsaFixedContribution as number);

  const hsaProps: HsaSectionProps = {
    hsaData,
    forecastData,
    recommendedHsaContribution,
    isHsaRecommendationLoading,
    isForecastLoading,
    selectedPlan,
    lockedHsaContribution,
    persona,
    currentHsaBalance,
    individualHsaContributionLimit,
    familyHsaContributionLimit,
    userContributionLimit,
    hsaContributionLimit,
    catchupEligible,
    isEmployerMatching,
    employerHsaPercentageMatch,
    employerHsaContribution,
    retirementAge,
    fetchHsaRecommendation,
    handleLockedHsaContribution,
    handleHsaForecastInputs,
    builderGoSettings,
  };

  const fsaProps: FsaSectionProps = {
    fsaData,
    isFsaTaxSavingsLoading,
    lockedFsaContribution: lockedFsaContribution || null,
    hsaEligible,
    selectedPlan,
    employerHsaContribution,
    isFsaEligible,
    fetchFsaTaxSavings,
    handleLockedFsaContribution,
    builderGoSettings,
  };

  const renderContent = () => {
    if (hsaEligible) {
      return (
        <>
          <HsaSection {...hsaProps} />
          {isFsaEligible && <FsaSection {...fsaProps} />}
        </>
      );
    }
    if (isFsaEligible) {
      return <FsaSection {...fsaProps} />;
    }
    // Need this in case of BNF into TaxSavings Page; something similar exists in HsaPage currently
    return (
      <NotEligibleContainer>
        <Paragraph>Selected plan is not eligible for an HSA or FSA.</Paragraph>
      </NotEligibleContainer>
    );
  };

  return (
    <>
      <ContentfulHeaderWrapper sectionKey="hsa" animate={isFirstPageView} />
      <ContentfulInfoBars sectionKey="hsa" />

      <PageLayout>
        <FadeUp delay={800} timeout={600} isOpen>
          <EligibleCards
            isHsaEligible={hsaEligible}
            isFsaEligible={isFsaEligible}
            employerHsaContribution={employerHsaContribution}
            individualHsaContributionLimit={individualHsaContributionLimit}
            familyHsaContributionLimit={familyHsaContributionLimit}
            selectedPlan={selectedPlan}
            builderGoSettings={builderGoSettings}
          />
        </FadeUp>

        <FadeUp delay={1750} timeout={600} isOpen>
          <TaxSavingsContainer>{renderContent()}</TaxSavingsContainer>
        </FadeUp>
      </PageLayout>
    </>
  );
};

const mapStateToProps = createStructuredSelector<GlobalReducerState, TaxSavingsPageStateProps>({
  isFsaEligible: makeGetIsFsaEligible(),
  userContributionLimit: makeSelectUserContributionLimit(),
  hsaContributionLimit: makeGetHsaContributionLimit(),
  individualHsaContributionLimit: makeSelectGlobalField('hsaContributionLimitIndividual'),
  familyHsaContributionLimit: makeSelectGlobalField('hsaContributionLimitFamily'),
  currentHsaBalance: makeSelectTaxSavingsField('currentHsaBalance'),
  hsaData: makeSelectHsaData(),
  forecastData: makeSelectTaxSavingsField('forecastData'),
  recommendedHsaContribution: makeGetRecommendedHsaContribution(),
  selectedPlan: makeSelectCommercialField('selectedHealthPlan') as Selector<
    GlobalReducerState,
    Recommendation
  >,
  isHsaRecommendationLoading: makeSelectTaxSavingsField('isHsaRecommendationLoading'),
  isForecastLoading: makeSelectTaxSavingsField('isForecastLoading'),
  lockedHsaContribution: makeSelectTaxSavingsField('lockedHsaContribution'),
  persona: makeSelectTaxSavingsField('persona'),
  isFirstPageView: selectIsFirstPageView(TAX_SAVINGS_PATH),
  catchupEligible: makeGetCatchupEligible(),
  fsaData: makeSelectTaxSavingsField('fsaData'),
  isFsaTaxSavingsLoading: makeSelectTaxSavingsField('isFsaTaxSavingsLoading'),
  lockedFsaContribution: makeSelectTaxSavingsField('lockedFsaContribution'),
  retirementAge: makeSelectTaxSavingsField('retirementAge'),
  builderGoSettings: makeGetBuilderGoSettings(),
});

export function mapDispatchToProps(dispatch): TaxSavingsPageDispatchProps {
  return {
    fetchHsaRecommendation: () => dispatch(getHsaRecommendation()),
    fetchFsaTaxSavings: () => dispatch(getFsaTaxSavings()),
    handleHsaForecastInputs: (balance, persona, retirementAge) =>
      dispatch(setHsaForecastInputs(balance, persona, retirementAge)),
    handleLockedHsaContribution: (contribution) => dispatch(setLockedHsaContribution(contribution)),
    handleLockedFsaContribution: (contribution) => dispatch(setLockedFsaContribution(contribution)),
  };
}

const withSaga = injectSaga({
  key: 'taxSavingsPage',
  saga,
});
const withConnect = connect(mapStateToProps, mapDispatchToProps);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default compose(withSaga, withConnect)(TaxSavingsPage);
