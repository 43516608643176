import React from 'react';

import { useAudioContext } from 'Contexts/audioContext';
import { Button, IconTypes, Paragraph } from 'DesignLibrary/atoms';
import { MediaControls } from 'Shared/RenderMediaControls/styled';
import { DirectlyUpdateableJVPUserAttributes } from 'Utils/apiTypes';

export interface RenderMediaControlsProps {
  handlePlayPauseClick?: () => void;
  playPauseButtonIcon: IconTypes;
  handleSkipClick?: () => void;
  isSkipEnabled?: boolean;
  isPlayEnabled?: boolean;
  updateJVPUserUiSettings: (uiSettings: Partial<DirectlyUpdateableJVPUserAttributes>) => void;
}

export const RenderMediaControls = ({
  handlePlayPauseClick,
  playPauseButtonIcon,
  handleSkipClick,
  isSkipEnabled = true,
  isPlayEnabled = true,
  updateJVPUserUiSettings,
}: RenderMediaControlsProps) => {
  const { audioState, toggleClosedCaptions, toggleMute } = useAudioContext();

  const handleClosedCaptionsClick = () => {
    toggleClosedCaptions();
    updateJVPUserUiSettings({
      closed_captioned: !audioState.areClosedCaptionsEnabled,
    });
  };

  const handleMuteClick = () => {
    toggleMute();
    const newMuteState = !audioState.isMuted;
    const newClosedCaptionState = newMuteState ? true : audioState.areClosedCaptionsEnabled;
    updateJVPUserUiSettings({
      audio_muted: newMuteState,
      closed_captioned: newClosedCaptionState,
    });
  };

  return (
    <MediaControls data-testid="media-controls">
      <Button
        buttonType="transparent"
        size="xsmall"
        testId="play-pause-button"
        isDisabled={!isPlayEnabled}
        onClick={() => handlePlayPauseClick && handlePlayPauseClick()}
        iconLeft={playPauseButtonIcon}
        iconProps={{
          weight: 'fill',
          size: 'small',
          color: isSkipEnabled ? '--text-black' : '--input-gray',
        }}
      />
      <Button
        buttonType={audioState.areClosedCaptionsEnabled ? 'secondary' : 'transparent'}
        size="xsmall"
        testId="cc-button"
        onClick={() => handleClosedCaptionsClick()}
      >
        <Paragraph as="span" size="mini" weight="bold" color="--text-black">
          CC
        </Paragraph>
      </Button>
      <Button
        buttonType={audioState.isMuted ? 'secondary' : 'transparent'}
        size="xsmall"
        testId="mute-button"
        onClick={() => handleMuteClick()}
      >
        <Paragraph as="span" size="mini" weight="bold" color="--text-black">
          {/* TODO CONTENTFUL: Add Field */}
          MUTE
        </Paragraph>
      </Button>
      <Button
        buttonType="transparent"
        size="xsmall"
        testId="skip-button"
        onClick={() => handleSkipClick && handleSkipClick()}
        isDisabled={!isSkipEnabled}
      >
        <Paragraph
          as="span"
          size="mini"
          weight="bold"
          color={isSkipEnabled ? '--text-black' : '--text-gray-light'}
        >
          {/* TODO CONTENTFUL: Add Field */}
          SKIP
        </Paragraph>
      </Button>
    </MediaControls>
  );
};
