import React from 'react';

import { TextField } from 'Containers/App/types';
import { LongTermDisabilityBenefits } from 'Containers/ViewBenefitPage/types';
import { transformBenefitItems } from 'Containers/ViewBenefitPage/utils';
import { useTextContext } from 'Contexts/textContext';
import { Paragraph } from 'DesignLibrary/atoms';
import RichText from 'Shared/RichText';
import Text from 'Shared/Text';
import { SupplementalPlan } from 'Types/entities';
import { toDollars } from 'Utils/helpers';

export const longTermDisabilityDescription = (plan: SupplementalPlan, employerName: string) => {
  const { retrieveContentfulData } = useTextContext();

  const benefits = transformBenefitItems(plan) as LongTermDisabilityBenefits;

  const salaryOrEP =
    benefits.how_determined === 'EligiblePay'
      ? retrieveContentfulData<string>('benefits_section_disability.std_eligible_pay')
      : retrieveContentfulData<string>('benefits_section_disability.std_salary');

  let descriptionLabelKey = 'benefits_section_disability.ltd_core_label';
  let descriptionKey = 'benefits_section_disability.ltd_core_benefit_description';

  if (benefits.benefits === 'Voluntary') {
    descriptionLabelKey = 'benefits_section_disability.ltd_voluntary_label';
    if (benefits.has_monthly_max) {
      descriptionKey = 'benefits_section_disability.ltd_voluntary_monthly_max';
    } else {
      descriptionKey = 'benefits_section_disability.ltd_voluntary_description';
    }
  } else if (benefits.has_monthly_max) {
    descriptionKey = 'benefits_section_disability.ltd_core_monthly_max';
  } else {
    descriptionKey = 'benefits_section_disability.ltd_core_benefit_description';
  }

  const descriptionLabel = <RichText field={descriptionLabelKey as TextField} />;
  const description = (
    <Text
      field={descriptionKey as TextField}
      vars={{
        employerName,
        percent: benefits.basic_percent,
        salaryOrEP,
        max: toDollars(benefits.monthly_max),
      }}
    />
  );

  let buyUpContent: JSX.Element | null = null;
  if (benefits.benefits === 'CoreBuyUp') {
    const additionalCoverageHeader = <RichText field="benefits_section_disability.ltd_buy_up_label" />;

    let buyUpKey = 'benefits_section_disability.ltd_buy_up_description';
    if (benefits.has_supplemental_monthly_max) {
      buyUpKey = 'benefits_section_disability.ltd_buy_up_monthly_max';
    }
    const buyUp = (
      <Text
        field={buyUpKey as TextField}
        vars={{
          percent: benefits.supplemental_salary_max_percent,
          max: toDollars(benefits.supplemental_monthly_max),
          salaryOrEP,
        }}
      />
    );

    buyUpContent = (
      <>
        <br />
        {additionalCoverageHeader}
        {buyUp}
      </>
    );
  }

  let waitingPeriodContent: JSX.Element | null = null;
  if (benefits.mention_benefits_duration) {
    let waitingPeriodContentKey = '';
    if (benefits.duration_by_age === 'age') {
      if (benefits.duration_until_ssnra_group) {
        waitingPeriodContentKey = 'benefits_section_disability.ltd_waiting_period_by_ssnra_age';
      } else {
        waitingPeriodContentKey = 'benefits_section_disability.ltd_waiting_period_by_age';
      }
    } else {
      waitingPeriodContentKey = 'benefits_section_disability.ltd_waiting_period_by_years';
    }
    waitingPeriodContent = (
      <>
        <br />
        <Text
          field={waitingPeriodContentKey as TextField}
          vars={{
            waitingPeriod: benefits.waiting_period,
            age: benefits.benefits_to_age,
            years: benefits.benefits_for_years,
          }}
        />
      </>
    );
  }

  return (
    <Paragraph as="div" lineHeight={1.75} size="small" className="ltd plan-description">
      {descriptionLabel}
      {description}
      {buyUpContent}

      {waitingPeriodContent}
    </Paragraph>
  );
};
