import { get, isEmpty } from 'lodash';
import { createSelector } from 'reselect';

import { Recommendation } from 'app/types/entities';
import {
  makeSelectCommercialField,
  makeSelectConfigField,
  makeSelectGlobalField,
} from 'Containers/App/selectors';
import {
  makeGetCoveredHouseholdMemberCount,
  makeGetTierCodeData,
  makeSelectPolicyholder,
} from 'Containers/ProfilePage/selectors';
import Plan from 'Models/plan';
import { calculateHsaRecommendation } from 'Utils/hsa';

import { Election } from './types';

// TODO: Rewrite this as a real selector once we store plan_selection_id in redux
const makeSelectPlanSelectionId = () => localStorage.getItem('plan_selection_id');

const makeGetSortedRecommendations = () =>
  createSelector(makeSelectCommercialField('recommendations'), (recommendations) =>
    recommendations.sort((a, b) => {
      if (a.score > b.score) return -1;
      if (a.score < b.score) return 1;
      if (a.costs.real_cost < b.costs.real_cost) return -1;
      if (a.costs.real_cost > b.costs.real_cost) return 1;
      return 0;
    }),
  );

const makeGetHsaContributionLimit = () =>
  createSelector(
    [
      makeGetCoveredHouseholdMemberCount(),
      makeSelectGlobalField('hsaContributionLimitIndividual'),
      makeSelectGlobalField('hsaContributionLimitFamily'),
    ],
    (memberCount, indvLimit, familyLimit): number => {
      if (memberCount > 1) {
        return familyLimit;
      }
      return indvLimit;
    },
  );

const makeGetPlans = () =>
  createSelector(
    [makeGetSortedRecommendations(), makeSelectConfigField('show_carrier_friendly_score')],
    (recommendations) => recommendations,
  );

const makeGetRecommendationByPlanId = (id: string) =>
  createSelector(
    makeSelectCommercialField('recommendations'),
    (recommendations) => recommendations.find((rec) => rec.plan.external_id === id) as Recommendation,
  );

const makeGetDisplayPdfLink = () =>
  createSelector(makeSelectCommercialField('recommendations'), (recommendations) =>
    recommendations.some((i) => i.plan.benefits_summary_url),
  );

const makeGetCatchupEligible = () =>
  createSelector(
    [makeSelectPolicyholder()],
    (policyholder) => policyholder.isValid && parseInt(policyholder.age, 10) >= 55,
  );

const makeGetRecommendedHsaContribution = () =>
  createSelector(
    [makeSelectCommercialField('selectedHealthPlan'), makeGetHsaContributionLimit()],
    (selectedPlan, federalHsaContributionLimit) => {
      let recommendedHsaContribution = 0;

      if (!isEmpty(selectedPlan)) {
        const medicalCost = Math.floor(selectedPlan.costs.services.in_network);
        const drugCost = Math.floor(selectedPlan.costs.drugs);
        const oopEstimate = medicalCost + drugCost;

        if (
          // eslint-disable-next-line no-prototype-builtins
          selectedPlan.plan.hasOwnProperty('spending_account_contributions')
        ) {
          // calculate recommended contribution using employer hsa contribution from recommendation response
          const employerContribution =
            selectedPlan.plan.spending_account_contributions.hsa_annual.employer_contribution_amount;
          const hsaPercentageMatch =
            selectedPlan.plan.spending_account_contributions.hsa_annual.employer_contribution_percent;
          const employerHsaContributionLimit =
            selectedPlan.plan.spending_account_contributions.hsa_annual.employer_contribution_limit;

          recommendedHsaContribution = calculateHsaRecommendation(
            oopEstimate,
            hsaPercentageMatch || 0,
            employerHsaContributionLimit || 0,
            federalHsaContributionLimit,
            employerContribution || 0,
          );
        }
      }

      return Math.round(recommendedHsaContribution);
    },
  );

const makeGetShowHsaNav = () =>
  createSelector(
    [makeSelectConfigField('hsa_plus_enabled'), makeSelectCommercialField('selectedHealthPlan')],
    (hsaPlusEnabled, selectedPlan) => hsaPlusEnabled && get(selectedPlan, 'plan.hsa_eligible', false),
  );

const makeGetHealthPlanElection = () =>
  createSelector(
    [
      makeSelectCommercialField('selectedHealthPlan'),
      makeSelectCommercialField('isCoverageWaived'),
      makeGetTierCodeData(),
    ],
    (selectedHealthPlan, isCoverageWaived, tierCodeData): Election[] => {
      const [tierCode, tierCodeDescription] = tierCodeData;

      // only return waived when isCoverageWaived is true
      if (isCoverageWaived) {
        return [
          {
            planable_type: 'MedicalPlan',
            waived: true,
          },
        ];
      }

      // should be empty when a user deselects a health plan
      if (isEmpty(selectedHealthPlan)) {
        return [];
      }

      return [
        {
          planable_type: 'MedicalPlan',
          jv_plan_id: selectedHealthPlan?.plan?.external_plan_id,
          tier_code: tierCode,
          tier_code_description: tierCodeDescription,
        },
      ];
    },
  );

const makeSelectUserContributionLimit = () =>
  createSelector(
    [
      makeSelectCommercialField('selectedHealthPlan'),
      makeGetCatchupEligible(),
      makeSelectConfigField('catchup_contribution'),
      makeGetHsaContributionLimit(),
    ],
    (selectedPlan, isCatchupEligible, catchupContribution, hsaContributionLimit): number => {
      if (isEmpty(selectedPlan)) {
        return 0;
      }
      const plan = new Plan(selectedPlan as Recommendation);
      const employerHsaContribution = plan.isEmployerMatching
        ? plan.employerHsaContributionLimit
        : plan.employerHsaFixedContribution;

      const hsaContributionLimitAdjusted = isCatchupEligible
        ? hsaContributionLimit + catchupContribution
        : hsaContributionLimit;
      const userContributionLimit = hsaContributionLimitAdjusted - (employerHsaContribution || 0);
      return userContributionLimit;
    },
  );

export {
  makeGetSortedRecommendations,
  makeGetPlans,
  makeGetRecommendationByPlanId,
  makeGetDisplayPdfLink,
  makeGetCatchupEligible,
  makeGetHsaContributionLimit,
  makeGetRecommendedHsaContribution,
  makeGetShowHsaNav,
  makeSelectPlanSelectionId,
  makeGetHealthPlanElection,
  makeSelectUserContributionLimit,
};
