import React, { FC, useEffect, useState } from 'react';

import { useFeatureFlagContext } from 'Contexts/featureFlagContext';
import { useProfileContext } from 'Contexts/profileContext';
import { useTextContext } from 'Contexts/textContext';
import InfoBar from 'DesignLibrary/molecules/InfoBar';
import RichText from 'Shared/RichText';

import SurveyQuestion from './SurveyQuestion';
import Question from '../_shared/Question';
import { ClientSurveyQuestion, ClientSurveyResponsesByYear } from '../types';

export interface EligibilitySectionProps {
  questions: Record<string, ClientSurveyQuestion>;
  responses: ClientSurveyResponsesByYear;
  isDirty: boolean;
  hiddenQuestionIds: string[];
  selectedPublicationKey: 'active' | 'upcoming';
  submitClientSurveyResponses: (survey: ClientSurveyResponsesByYear) => void;
  handleSectionChange: (isValid: boolean, isComplete: boolean) => void;
}

const alwaysIgnored = ['zip5', 'fips5', 'age', 'spouse_age', 'tobacco', 'spouse_tobacco'];

const EligibilitySection: FC<EligibilitySectionProps> = ({
  questions,
  responses,
  isDirty,
  hiddenQuestionIds,
  selectedPublicationKey,
  submitClientSurveyResponses,
  handleSectionChange,
}) => {
  // Need to determine which number inputs user has finished/stepped past to determine valid/invalid state
  const [completedNumberInputs, setCompletedNumberInputs] = useState<string[]>([]);

  const { retrieveContentfulData } = useTextContext();
  const { getSectionFromMap } = useProfileContext();
  const { is_hide_eligibility_questions_enabled } = useFeatureFlagContext();

  const eqsToIgnore = [...alwaysIgnored];

  if (is_hide_eligibility_questions_enabled) {
    eqsToIgnore.push(...hiddenQuestionIds);
  }

  const isPartTimeIneligible =
    (retrieveContentfulData<string>('profile_section.eligibility.part_time_ineligible_disclaimer') &&
      Boolean(responses[selectedPublicationKey]?.is_full_time_or_part_time)) ||
    false;

  const handleAnswerChange = (value: string, property: string, isValid: boolean) => {
    submitClientSurveyResponses({
      ...responses,
      [selectedPublicationKey]: {
        ...responses[selectedPublicationKey],
        // empty string signals validateForm that the provided survey response was invalid
        [property]: isValid ? value : '',
      },
    });
  };

  useEffect(() => {
    const section = getSectionFromMap('eligibility');
    const isComplete = section?.isComplete || validateForm();
    handleSectionChange(validateForm(), isComplete);
  }, [JSON.stringify(responses), selectedPublicationKey]);

  const handleNumberInputChange = (val: string, property: string) => {
    const value = val.replace(/,/g, ''); // revert comma separated value

    // Calling parseInt on empty string returns NaN, only call parseInt if not empty string or zero
    // Zero is an invalid input so we store '' because that is the invalid value we check for in validateForm
    const parsedValue: number | '' = value === '' || value === '0' ? '' : parseInt(value, 10);

    // only change value if value can be parsed as an integer or input was deleted ('')
    if ((Number.isInteger(parsedValue) && +parsedValue > 0) || parsedValue === '') {
      submitClientSurveyResponses({
        ...responses,
        [selectedPublicationKey]: {
          ...responses[selectedPublicationKey],
          // empty string signals validateForm that the provided survey response was invalid
          [property]: parsedValue,
        },
      });
    }
  };

  const handleBlurNumberInput = (key: string, value: string | number) => {
    if (value !== 0) setCompletedNumberInputs([...completedNumberInputs, key]);
  };

  function validateForm() {
    let isFormValid = Object.entries(responses?.[selectedPublicationKey] || {})
      .filter(([key]) => !alwaysIgnored.includes(key))
      .every(([, value]) => value !== '' && value !== null);

    if (isFormValid && isPartTimeIneligible) {
      isFormValid = responses[selectedPublicationKey]?.is_full_time_or_part_time === 'Full-Time';
    }

    return isFormValid;
  }

  const isIncomplete = !getSectionFromMap('eligibility')?.isComplete;
  const isOnlyIgnoredEqs = Object.entries(questions).every((i) => eqsToIgnore.includes(i[0]));

  if (isOnlyIgnoredEqs) {
    return null;
  }

  return (
    <div id="eligibility-section" data-testid="eligibility-section">
      <Question error={!validateForm() && isDirty} name="eligibility" isIncomplete={isIncomplete}>
        <>
          {Object.entries(questions).map(([key, val]) => {
            if (eqsToIgnore.includes(key)) {
              return null;
            }

            if (val.type === 'number') {
              const isBlurred = completedNumberInputs.includes(key);
              return (
                <SurveyQuestion
                  questionId={key}
                  question={val}
                  response={responses[selectedPublicationKey]?.[key]}
                  handleAnswerChange={handleNumberInputChange}
                  onBlur={handleBlurNumberInput}
                  isInvalid={isBlurred && responses[selectedPublicationKey]?.[key] === ''}
                  key={key}
                />
              );
            }

            return (
              <SurveyQuestion
                questionId={key}
                question={val}
                response={responses[selectedPublicationKey]?.[key]}
                handleAnswerChange={handleAnswerChange}
                key={key}
              />
            );
          })}

          {isPartTimeIneligible &&
            responses[selectedPublicationKey].is_full_time_or_part_time === 'Part-Time' && (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div id="equinox-disclaimer">
                  <InfoBar
                    header="Part-Time Employees"
                    text={<RichText field="profile_section.eligibility.part_time_ineligible_disclaimer" />}
                  />
                </div>
              </div>
            )}
        </>
      </Question>
    </div>
  );
};

export default EligibilitySection;
