import React from 'react';

import { Paragraph, Spinner } from 'DesignLibrary/atoms';
import Text from 'Shared/Text';

export type Suggestion = {
  key: string;
  title: string;
  description: string;
};

export interface SuggestionsListProps {
  showLoading: boolean;
  userInput: string;
  suggestions: Suggestion[];
  activeSuggestion: number;
  onDrugClick: (e, idx) => void;
}

export const SuggestionsList = ({
  showLoading,
  userInput,
  suggestions,
  activeSuggestion,
  onDrugClick,
}: SuggestionsListProps): JSX.Element => (
  <ul className="suggestions" role="listbox" id="drug-list-suggestions">
    {!showLoading && userInput.length > 2 ? (
      suggestions.length === 0 ? (
        <div className="no-results">
          <Paragraph size="small">
            <Text field="profile_section.drug_search_text.noResultsFound" />
          </Paragraph>
        </div>
      ) : (
        suggestions.map((suggestion, idx) => {
          let className;

          if (idx === activeSuggestion) {
            className = 'suggestion-active';
          }

          return (
            <li
              className={className}
              key={suggestion.key}
              role="option"
              aria-selected={idx === activeSuggestion}
            >
              <button
                id={`drug-suggestion-${idx}`}
                type="button"
                className="suggestion-item"
                onMouseDown={(e) => onDrugClick(e, idx)}
              >
                <Paragraph weight="bold" color="--text-black" as="h4">
                  {suggestion.title}
                </Paragraph>
                <Paragraph size="small">{suggestion.description}</Paragraph>
              </button>
            </li>
          );
        })
      )
    ) : (
      <div className="loading">
        {showLoading ? (
          <Spinner color="--border-gray" />
        ) : (
          <Paragraph size="small">Please add at least 3 letters.</Paragraph>
        )}
      </div>
    )}
  </ul>
);
