import styled from 'styled-components';

export const DrawerTitleContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 999;
  background: var(--primary-white);
  width: 100%;
  height: var(--sizing-2xl);
  border-bottom: 1px solid var(--border-gray);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 var(--sizing-md);
  flex-shrink: 0;

  .title {
    display: flex;
    align-items: center;
    gap: var(--sizing-xs);
  }
`;
