import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
} from 'chart.js';
import React from 'react';
import { Line } from 'react-chartjs-2';

import { ForecastData, Persona, YearlyForecast } from 'Containers/TaxSavingsPage/types';
import { Paragraph, Spinner } from 'DesignLibrary/atoms';
import { useViewport } from 'DesignLibrary/context';
import { COLORS } from 'DesignLibrary/vars';
import { toDollars } from 'Utils/helpers';

import {
  ChartContainer,
  ChartTitle,
  ChartWrapper,
  Disclaimer,
  LegendContainer,
  LegendItem,
  LoadingWrapper,
  SpinnerContainer,
} from './styled';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler);

export interface LongTermChartProps {
  forecastData: ForecastData | Record<string, never>;
  currentHsaBalance: number;
  lockedHsaContribution: number;
  isForecastLoading: boolean;
  persona: Persona;
}

export const LongTermChart = ({
  forecastData,
  currentHsaBalance,
  lockedHsaContribution,
  isForecastLoading,
  persona,
}: LongTermChartProps) => {
  const { device } = useViewport();

  const yearlyForecasts = forecastData?.yearly_forecasts || [];

  // Get forecasts to display:
  // filter out any datapoints we don't want to render (i.e. keep the x axis spacing/interval clean)
  const getForecastDataSubset = (forecasts: YearlyForecast[]) => {
    // include first year, last year and every 10 yrs
    if (device === 'mobile' || device === 'tablet') {
      return forecasts.filter((i, idx) => idx === 0 || idx === forecasts.length - 1 || i.year % 10 === 0);
    }

    // include first year, last year and every 5 yrs
    return forecasts.filter((i, idx) => idx === 0 || idx === forecasts.length - 1 || i.year % 5 === 0);
  };

  const forecastDataSubset = getForecastDataSubset(yearlyForecasts);

  if (forecastDataSubset.length === 0) {
    return (
      <LoadingWrapper>
        <Spinner color="--input-gray" />
      </LoadingWrapper>
    );
  }

  const currentYear = forecastDataSubset[0]?.year as number;
  const finalYear = forecastDataSubset[forecastDataSubset.length - 1]?.year as number;

  const getDatasets = () => {
    if (persona === 'spender') {
      return [
        {
          label: 'Without Investing',
          data: forecastDataSubset.map(
            (i) => currentHsaBalance + ((i?.year as number) - currentYear) * lockedHsaContribution,
          ),
          borderColor: '#E5B045',
          fill: false,
          pointRadius: 0,
          pointBackgroundColor: '#E5B045',
          pointBorderColor: '#E5B045',
          pointBorderWidth: 2,
        },
      ];
    }

    if (persona === 'super_saver') {
      return [
        {
          label: 'Investing',
          data: forecastDataSubset.map((i) => i.hsa.balance.median),
          borderColor: '#036991',
          fill: false,
          lineTension: 0.6,
          pointRadius: 0,
          pointBackgroundColor: '#036991',
          pointBorderColor: '#036991',
          pointBorderWidth: 2,
        },
        {
          label: 'Without Investing',
          data: forecastDataSubset.map(
            (i) => currentHsaBalance + ((i?.year as number) - currentYear) * lockedHsaContribution,
          ),
          borderColor: '#E5B045',
          fill: false,
          pointRadius: 0,
          pointBackgroundColor: '#E5B045',
          pointBorderColor: '#E5B045',
          pointBorderWidth: 2,
        },
      ];
    }

    return [
      {
        label: 'Upper',
        data: forecastDataSubset.map((i) => i.hsa.balance.pct_80),
        backgroundColor: 'rgba(62, 123, 208, 0.1)',
        borderColor: 'rgba(62, 123, 208, 0.1)',
        fill: '+2',
        lineTension: 0.6,
        pointRadius: 0,
        pointBackgroundColor: 'rgba(62, 123, 208, 0.1)',
        pointBorderColor: 'rgba(62, 123, 208, 0.1)',
        pointBorderWidth: 2,
      },
      {
        label: 'Investing',
        data: forecastDataSubset.map((i) => i.hsa.balance.median),
        borderColor: '#036991',
        fill: false,
        lineTension: 0.6,
        pointRadius: 0,
        pointBackgroundColor: '#036991',
        pointBorderColor: '#036991',
        pointBorderWidth: 2,
      },
      {
        label: 'Lower',
        data: forecastDataSubset.map((i) => i.hsa.balance.pct_20),
        borderColor: 'rgba(62, 123, 208, 0.1)',
        fill: false,
        lineTension: 0.6,
        pointRadius: 0,
        pointBackgroundColor: 'rgba(62, 123, 208, 0.1)',
        pointBorderColor: 'rgba(62, 123, 208, 0.1)',
        pointBorderWidth: 2,
      },
      {
        label: 'Without Investing',
        data: forecastDataSubset.map(
          (i) => currentHsaBalance + ((i?.year as number) - currentYear) * lockedHsaContribution,
        ),
        borderColor: '#E5B045',
        fill: false,
        pointRadius: 0,
        pointBackgroundColor: '#E5B045',
        pointBorderColor: '#E5B045',
        pointBorderWidth: 2,
      },
    ];
  };

  const datasets = getDatasets();

  const data = {
    labels: forecastDataSubset.map((i) => i.year),
    datasets,
  };

  const getEndBalance = () => {
    if (persona === 'spender') {
      // no investment
      const totalContribution =
        (forecastDataSubset[forecastDataSubset.length - 1].year - currentYear) * lockedHsaContribution;
      return totalContribution + currentHsaBalance;
    }
    // investment
    return forecastDataSubset[forecastDataSubset.length - 1].hsa.balance.median;
  };

  const end_balance = getEndBalance();

  const yMax = Math.ceil(end_balance / 100000) * 100000;

  // There's some bs with ChartJs ChartOptions<'line'> type, this'll at least make it stop yelling
  const options: any = {
    responsive: true,
    layout: {
      padding: -100,
    },
    interaction: {
      intersect: false,
      mode: 'index' as const,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        backgroundColor: COLORS.gray.black,
        callbacks: {
          label(context) {
            let label = context.dataset.label || '';

            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                maximumFractionDigits: 0,
              }).format(context.parsed.y);
            }
            return label;
          },
        },
      },
    },
    scales: {
      x: {
        // display: false,
        ticks: {
          align: 'inner',
          textStrokeWidth: 2,
          padding: 4,
          font: {
            size: 15,
          },
        },
        grid: {
          drawTicks: false,
          drawBorder: false,
          display: false,
        },
      },
      y: {
        gridLines: {
          drawBorder: false,
        },
        grid: {
          drawBorder: false,
          drawTicks: false,
        },
        suggestedMin: 0,
        suggestedMax: yMax,
        ticks: {
          padding: 12,
          mirror: true,
          stepSize: yMax / 16,
          textStrokeColor: COLORS.gray[50],
          textStrokeWidth: 6,
          callback(val, index) {
            // Hide $0 and every 2nd tick label
            const v = Intl.NumberFormat('en-US', {
              notation: 'compact',
              maximumFractionDigits: 1,
            }).format(val);
            if (index === 0) {
              return '';
            }
            return index % 4 === 0 ? `$${v}` : '';
          },
        },
      },
    },
  };

  return (
    <ChartContainer>
      <ChartWrapper>
        {isForecastLoading && (
          <SpinnerContainer>
            <Spinner color="--text-black" />
          </SpinnerContainer>
        )}
        <Line options={options} data={data} />
      </ChartWrapper>
      <ChartTitle>
        {/* TODO: CONTENTFUL Add Field */}
        Projected {finalYear} Balance: <b>{toDollars(end_balance)}</b>
      </ChartTitle>
      <LegendContainer>
        <LegendItem legendType="line" color="--primary-blue">
          {/* TODO: CONTENTFUL Add Field */}
          With Investing
        </LegendItem>
        <LegendItem legendType="block" color="--colors-blue-100">
          {/* TODO: CONTENTFUL Add Field */}
          Range
        </LegendItem>
        <LegendItem legendType="line" color="--primary-yellow">
          {/* TODO: CONTENTFUL Add Field */}
          Without Investing
        </LegendItem>
      </LegendContainer>
      {(persona === 'super_saver' || persona === 'middle') && (
        <Disclaimer>
          {persona === 'super_saver' && (
            <Paragraph size="mini">
              {/* TODO: CONTENTFUL Add Field */}
              The investment figures above assume a 6% rate of return.
            </Paragraph>
          )}
          {persona === 'middle' && (
            <Paragraph size="mini">
              {/* TODO: CONTENTFUL Add Field */}
              The investment figures above assume a 6% rate of return, the shaded area represents variation in
              cost over time.
            </Paragraph>
          )}
        </Disclaimer>
      )}
    </ChartContainer>
  );
};
