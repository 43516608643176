import styled from 'styled-components';

import { media } from 'DesignLibrary/global';

export const Container = styled.section`
  max-width: 768px;

  .title {
    margin-bottom: var(--sizing-lg);

    .paragraph,
    p {
      margin-top: var(--sizing-xs);

      b {
        color: var(--primary-blue) !important;

        > div {
          display: inline-block !important;
        }

        * {
          color: var(--primary-blue) !important;
          font-weight: 900 !important;
          font-size: inherit !important;
        }
      }
    }
  }

  .bullets > div + div {
    margin-top: var(--sizing-sm);
  }
`;

export const BulletStyle = styled.div`
  display: flex;
  align-items: flex-start;

  ${media.TABLET`
    align-items: center;
  `}

  .paragraph,
  p {
    margin-left: var(--sizing-sm);
  }
`;
