import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  > button {
    background: var(--colors-opaque-5);
    height: var(--sizing-md);
    font-size: 14px;

    &:hover {
      background: var(--colors-opaque-10);
    }
  }
`;
