import styled from 'styled-components';

import { Group, tierColor } from '../../../_shared/styled';

export const PersonContainer = styled.section`
  width: 100%;
  display: grid;
  align-self: center;
  justify-content: center;
  grid-template-columns: repeat(10, 1fr);
`;

interface PersonIconProps {
  group: Group;
}

export const PersonIcon = styled.section<PersonIconProps>`
  justify-self: center;
  margin-bottom: var(--sizing-xs);

  svg {
    fill: ${({ group }) => tierColor(group)};
    width: var(--sizing-sm);
  }
`;
