import styled from 'styled-components';

type SupplementalContainerProps = {
  size: 'large' | 'normal' | 'small' | 'mini';
};

export const SupplementalIconContainer = styled.div<SupplementalContainerProps>`
  flex-shrink: 0;
  width: ${({ size }) => {
    switch (size) {
      case 'large':
        return 'var(--sizing-2xl)';
      case 'normal':
        return '52px';
      case 'small':
        return '40px';
      case 'mini':
        return 'var(--sizing-lg)';
      default:
        return '';
    }
  }};
  height: ${({ size }) => {
    switch (size) {
      case 'large':
        return 'var(--sizing-2xl)';
      case 'normal':
        return '52px';
      case 'small':
        return '40px';
      case 'mini':
        return 'var(--sizing-lg)';
      default:
        return '';
    }
  }};

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* transform: scale(1.1) translateY(1px);
    transform-origin: center center; */
  }
`;
