const codeAsString = `
  var pollInterval = 1000 * 60 * 5; // poll Conduent every 5 minutes
  var timeout = 1000 * 60 * 10; // stop polling Conduent (timeout) after 10 minutes
  var lastActivity = new Date();

  var conduentKeepAlive = function() {
    window.parent.postMessage('FE-KeepAlive', '*')

    var msgPost = 'LAW_PTNR_KA';
    if (window.top.opener !== null) {
      window.top.opener.postMessage(msgPost, '*');
    } else {
      window.top.postMessage(msgPost, '*');
    }

    console.info('Conduent: Polled.')
  };

  var pollIntervalId = setInterval(() => {
    if (new Date().valueOf() - lastActivity.valueOf() > timeout) {
      console.info('Conduent: User activity timed out.');
      clearInterval(pollIntervalId);
      return;
    }

    conduentKeepAlive();
  }, pollInterval);

  document.addEventListener('click', () => {
    lastActivity = new Date();
  });

  document.addEventListener('keyup', () => {
    lastActivity = new Date();
  });
`;

export default codeAsString;
