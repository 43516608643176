import { ContentfulType } from 'ContentfulDefaults/ContentfulType';

export const healthDetails: {
  health_details_section: ContentfulType['health_details_section'];
} = {
  health_details_section: {
    previous_page_button_text: 'All Health Plans',
    eligibility_notice: {
      name: '[DEFAULT] Eligibility Notice',
      button_text: 'Learn More',
      hsa: {
        data: {},
        content: [
          {
            data: {},
            content: [
              { data: {}, marks: [], value: 'This plan is ', nodeType: 'text' },
              {
                data: {},
                marks: [{ type: 'bold' }],
                value: 'HSA-eligible',
                nodeType: 'text',
              },
              { data: {}, marks: [], value: '.', nodeType: 'text' },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      hsa_contribution: {
        nodeType: 'document',
        data: {},
        content: [
          {
            nodeType: 'paragraph',
            data: {},
            content: [
              {
                nodeType: 'text',
                value: 'If you pick this HSA-eligible health plan, your employer will contribute ',
                marks: [],
                data: {},
              },
              {
                nodeType: 'text',
                value: '{x}',
                marks: [{ type: 'code' }, { type: 'bold' }],
                data: {},
              },
              {
                nodeType: 'text',
                value: ' to your HSA',
                marks: [{ type: 'bold' }],
                data: {},
              },
              { nodeType: 'text', value: '.', marks: [], data: {} },
            ],
          },
        ],
      },
      hsa_match: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value: 'If you pick this HSA Eligible health plan, your employer will\u00a0',
                nodeType: 'text',
              },
              {
                data: {},
                marks: [{ type: 'bold' }],
                value: 'match ',
                nodeType: 'text',
              },
              {
                data: {},
                marks: [{ type: 'code' }, { type: 'bold' }],
                value: '{x}',
                nodeType: 'text',
              },
              {
                data: {},
                marks: [{ type: 'bold' }],
                value: '% (up to ',
                nodeType: 'text',
              },
              {
                data: {},
                marks: [{ type: 'code' }, { type: 'bold' }],
                value: '{x}',
                nodeType: 'text',
              },
              {
                data: {},
                marks: [{ type: 'bold' }],
                value: ') of your contributions',
                nodeType: 'text',
              },
              { data: {}, marks: [], value: '.', nodeType: 'text' },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      hra: {
        data: {},
        content: [
          {
            data: {},
            content: [
              { data: {}, marks: [], value: 'This plan is ', nodeType: 'text' },
              {
                data: {},
                marks: [{ type: 'bold' }],
                value: 'HRA-eligible',
                nodeType: 'text',
              },
              { data: {}, marks: [], value: '.', nodeType: 'text' },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      hra_contribution: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value: 'If you pick this HRA-eligible health plan, your employer will contribute ',
                nodeType: 'text',
              },
              {
                data: {},
                marks: [{ type: 'code' }, { type: 'bold' }],
                value: '{x}',
                nodeType: 'text',
              },
              {
                data: {},
                marks: [{ type: 'bold' }],
                value: ' to your HRA',
                nodeType: 'text',
              },
              {
                data: {},
                marks: [],
                value: '.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      fsa: {
        data: {},
        content: [
          {
            data: {},
            content: [
              { data: {}, marks: [], value: 'This plan is ', nodeType: 'text' },
              {
                data: {},
                marks: [{ type: 'bold' }],
                value: 'FSA-eligible',
                nodeType: 'text',
              },
              { data: {}, marks: [], value: '.', nodeType: 'text' },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      fsa_contribution: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value: 'If you pick this FSA-eligible health plan, your employer will contribute ',
                nodeType: 'text',
              },
              {
                data: {},
                marks: [{ type: 'code' }, { type: 'bold' }],
                value: '{x}',
                nodeType: 'text',
              },
              {
                data: {},
                marks: [{ type: 'bold' }],
                value: ' to your FSA',
                nodeType: 'text',
              },
              {
                data: {},
                marks: [],
                value: '.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      sihra: {
        data: {},
        content: [
          {
            data: {},
            content: [
              { data: {}, marks: [], value: 'This plan is ', nodeType: 'text' },
              {
                data: {},
                marks: [{ type: 'bold' }],
                value: 'SIHRA-eligible',
                nodeType: 'text',
              },
              { data: {}, marks: [], value: '.', nodeType: 'text' },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      sihra_contribution: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value: 'If you pick this plan your employer will contribute ',
                nodeType: 'text',
              },
              {
                data: {},
                marks: [{ type: 'bold' }],
                value: 'up to ',
                nodeType: 'text',
              },
              {
                data: {},
                marks: [{ type: 'code' }, { type: 'bold' }],
                value: '{x}',
                nodeType: 'text',
              },
              {
                data: {},
                marks: [{ type: 'bold' }],
                value: ' to your SIHRA',
                nodeType: 'text',
              },
              {
                data: {},
                marks: [],
                value: '.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
    },
    plan_eligibility: {
      title: 'Eligible',
      subtitle: 'This plan is',
      buttonText: 'Learn More',
    },
    plan_details: {
      name: '[DEFAULT] Plan Details',
      plan_essentials_card_title: 'Plan Essentials',
      prescriptions_card_title: 'Prescriptions',
      doctor_visits_card_title: 'Doctor Visits',
      doctors_providers_card_title: 'Médicos y proveedores',
      breakdown_tag_tier1_deductible: 'Tier 1',
      breakdown_tag_tier2_deductible: 'Tier 2',
      prescription_cost_not_covered_text: 'Not Covered',
    },
    cta_pdf_header_text: 'Looking for all of the plan details?',
    cta_provider_header_text: 'Is my doctor or network provider available?',
    after_deductible: 'after deductible is met',
  },
};

export default healthDetails;
