import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';

import { Button } from 'DesignLibrary/atoms';
import { sendInsightsEvent } from 'Utils/insights';

import { BaseButtonProps } from './AuthButton';

const LogoutButton = ({ children, returnToUrl = window.location.pathname, ...rest }: BaseButtonProps) => {
  const { logout } = useAuth0();

  const handleLogout = () => {
    window.onbeforeunload = null;
    localStorage.setItem('logoutRedirect', returnToUrl);
    sendInsightsEvent(null, 'auth_logout');
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  return (
    <Button onClick={handleLogout} {...rest}>
      {children}
    </Button>
  );
};

export default LogoutButton;
