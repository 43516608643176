import React, { useContext, useEffect, useState } from 'react';

import { useViewport } from 'DesignLibrary/context';
import Plan from 'Models/plan';

export const useCompareContext = () => useContext(CompareContext);

export interface CompareContextType {
  comparablePlans: Plan[];
  canAdd: boolean;
  togglePlan: (plan: Plan) => void;
  resetComparedPlans: () => void;
}

export interface CompareProviderProps {
  defaultComparablePlans?: Plan[];
  children: JSX.Element;
}

export const defaultContext: CompareContextType = {
  comparablePlans: [],
  canAdd: true,
  togglePlan: () => null,
  resetComparedPlans: () => null,
};

export const CompareContext: React.Context<CompareContextType> = React.createContext(defaultContext);

export const CompareProvider = ({ defaultComparablePlans = [], children }: CompareProviderProps) => {
  const [comparablePlans, setComparablePlans] = useState<Plan[]>(defaultComparablePlans);
  const [maxComparablePlans, setMaxComparablePlans] = useState<number>(2);
  const [canAdd, setCanAdd] = useState<boolean>(true);
  const { device } = useViewport();

  useEffect(() => {
    if (comparablePlans.length === maxComparablePlans) {
      setCanAdd(false);
    } else {
      setCanAdd(true);
    }
  }, [comparablePlans, maxComparablePlans]);

  useEffect(() => {
    setMaxComparablePlans(device === 'mobile' ? 2 : 3);
  }, [device]);

  function handleTogglePlan(plan: Plan) {
    if (!comparablePlans.some((e) => e.planId === plan.planId) && canAdd) {
      setComparablePlans((prev) => [...prev, plan].sort((a, b) => b.picwellScore - a.picwellScore));
    }
    if (comparablePlans.length > 0 && comparablePlans.some((e) => e.planId === plan.planId)) {
      setComparablePlans((prev) => prev.filter((p) => p.planId !== plan.planId));
    }
  }

  function resetComparedPlans() {
    setComparablePlans([]);
  }

  return (
    <CompareContext.Provider
      value={{
        comparablePlans,
        canAdd,
        togglePlan: handleTogglePlan,
        resetComparedPlans,
      }}
    >
      {children}
    </CompareContext.Provider>
  );
};
