import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  margin-bottom: 12px;

  .suggestions {
    position: absolute;
    top: calc(100% + var(--sizing-xs));
    background: var(--primary-white);
    width: 100%;
    border-radius: var(--border-radius-xs);
    box-shadow: var(--shadow-large);
    overflow-y: auto;
    max-height: 20rem;
    z-index: 99;
    padding: 0;
    margin: 0;

    li {
      width: 100%;
      list-style: none;
      padding: 0;

      &:nth-child(even) {
        background: var(--background-gray-light);
      }

      button {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: var(--sizing-sm);

        &:hover {
          background-color: var(--background-gray);
        }
      }
    }

    .suggestion-active {
      button {
        background-color: var(--background-gray);
      }
    }

    .loading,
    .no-results {
      width: 100%;
      height: var(--sizing-2xl);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;
