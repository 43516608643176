import styled, { css } from 'styled-components';

import { media } from '../../global';

/*
  STYLES:
    - Headers [H1, H2, H3, H4, H5, H6]
    - Paragraph [Normal, Small, Mini]
    - Label
    - Input
    - Button
    - Numbers [Large, Medium, Small]
*/

/* BASE STYLES */
export const TooltipUnderlineStyle = css`
  text-decoration: underline;
  text-decoration-style: dashed;
  text-decoration-color: var(--colors-opaque-20);
  text-decoration-thickness: 1px;
  text-underline-offset: 2px;
  display: inline-block;
  margin: 0;

  &:hover {
    text-decoration-color: var(--colors-opaque-90);
    cursor: help;
  }

  @media print {
    text-decoration: none;
  }
`;

export const BaseTypography = (color = '--text-black', weight = 300, lineHeight) => css`
  color: var(${color});
  font-weight: ${weight} !important;
  line-height: ${lineHeight};
`;

export const HeaderBase = (color = '--text-black', weight = 700, lineHeight) => css`
  ${BaseTypography(color, weight, lineHeight)};
  font-family: var(--font-header);
  letter-spacing: -0.5px;
`;

export const ParagraphBase = (color = '--text-black', weight = 300, lineHeight) => css`
  ${BaseTypography(color, weight, lineHeight)};
  font-family: var(--font-primary);
`;

// ----------------------------------------
// PRIMATIVE TYPE STYLES
// (Initial styles, for expandable use outside TYPE SYSTEM below)
// ----------------------------------------

/* H1 */
/* Page Headers */
export const H1Style = (color = '--text-black', weight = 900, lineHeight = 1.25) => css`
  ${HeaderBase(color, weight, lineHeight)}
  font-size: 36px;
  ${media.TABLET` font-size: 48px; `}
  ${media.DESKTOP` font-size: 48px; `}
`;

/* H2 */
/* Large Plan Names */
export const H2Style = (color = '--text-black', weight = 700, lineHeight = 1.25) => css`
  ${HeaderBase(color, weight, lineHeight)}
  font-size: 32px;
  ${media.TABLET` font-size: 36px; `}
  ${media.DESKTOP` font-size: 48px; `}
`;

/* H3 */
/* Secondary Headers */
export const H3Style = (color = '--text-black', weight = 700, lineHeight = 1.25) => css`
  ${HeaderBase(color, weight, lineHeight)}
  font-size: 26px;
  ${media.TABLET` font-size: 28px; `}
  ${media.DESKTOP` font-size: 36px; `}
`;

/* H4 */
/* Small Plan Names */
export const H4Style = (color = '--text-black', weight = 500, lineHeight = 1.25) => css`
  ${HeaderBase(color, weight, lineHeight)}
  font-size: 24px;
  ${media.TABLET` font-size: 24px; `}
  ${media.DESKTOP` font-size: 28px; `}
`;

/* H5 */
/* Small Headers */
export const H5Style = (color = '--text-black', weight = 500, lineHeight = 1.25) => css`
  ${HeaderBase(color, weight, lineHeight)}
  font-size: 20px;
  ${media.TABLET` font-size: 20px; `}
  ${media.DESKTOP` font-size: 22px; `}
`;

/* H6 */
/* Caps Header */
export const H6Style = (color = '--text-black') => css`
  color: var(${color});
  text-transform: uppercase;
  font-family: var(--font-primary);
  font-weight: 600;
  font-size: 13px;
  letter-spacing: 0.5px;
`;

/* Paragraph [Regular] */
export const ParagraphNormalStyle = (color = '--text-black', weight = 300, lineHeight = 1.5) => css`
  ${ParagraphBase(color, weight, lineHeight)}
  font-size: 16px;
  ${media.TABLET` font-size: 20px; `}
`;

/* Paragraph [Small] */
export const ParagraphSmallStyle = (color = '--text-black', weight = 300, lineHeight = 1.5) => css`
  ${ParagraphBase(color, weight, lineHeight)}
  font-size: 15px;
  ${media.TABLET` font-size: 16px; `}
`;

/* Paragraph [Mini] */
export const ParagraphMiniStyle = (color = '--text-black', weight = 300, lineHeight = 1.5) => css`
  ${ParagraphBase(color, weight, lineHeight)}
  font-size: 14px;
  ${media.TABLET` font-size: 14px; `}
`;

/* Label Text */
export const LabelStyle = css`
  ${ParagraphBase('--text-black', 700, 1)}
  font-size: 14px;
  text-transform: uppercase;
`;

/* Input Text */
export const InputTextStyle = css`
  ${ParagraphBase('--text-black', 500, 1)}
  font-size: 16px;
  ${media.TABLET` font-size: 18px; `}

  &::placeholder {
    color: var(--text-gray-placeholder);
  }

  &:disabled {
    color: var(--text-gray-light);
  }
`;

/* Button */
export const ButtonTextStyle = css`
  ${ParagraphBase('--text-black', 700, 1)}
  font-size: 15px;

  &::first-letter {
    text-transform: capitalize;
  }

  ${media.TABLET` font-size: 16px; `}
`;

/* Numbers [Small] */
export const NumbersSmallStyle = (color = '--text-black', weight = 500) => css`
  ${ParagraphBase(color, weight, 1)}
  font-size: 16px;
  ${media.TABLET` font-size: 18px; `}
  ${media.DESKTOP` font-size: 20px; `}
`;

/* Numbers [Medium] */
export const NumbersMediumStyle = (color = '--text-black', weight = 500) => css`
  ${ParagraphBase(color, weight, 1)}
  font-size: 20px;
  ${media.TABLET` font-size: 22px; `}
  ${media.DESKTOP` font-size: 24px; `}
`;

/* Numbers [Large] */
export const NumbersLargeStyle = (color = '--text-black', weight = 700) => css`
  ${ParagraphBase(color, weight, 1)}
  font-size: 24px;
  ${media.TABLET` font-size: 28px; `}
  ${media.DESKTOP` font-size: 36px; `}
`;

// ----------------------------------------
// TYPE SYSTEM
// (adds html tag to above styling)
// ----------------------------------------

/*
  COMPONENTS:
    - H1
    - H2
    - H3
    - H4
    - H5
    - H6
    - Paragraph
    - Label
    - Numbers
*/

export interface BaseTypeInterface {
  color?: string;
  weight?: 'normal' | 'bold';
  lineHeight?: number;
  underline?: boolean;
}

/* H1 */
export const H1 = styled.h1<BaseTypeInterface>`
  ${({ weight = 'bold', color = '--text-black', lineHeight }) => {
    const w = weight === 'normal' ? 900 : 900;
    return H1Style(color, w, lineHeight);
  }}

  ${({ underline }) => underline && TooltipUnderlineStyle}
`;

/* H2 */
export const H2 = styled.h2<BaseTypeInterface>`
  ${({ weight = 'bold', color = '--text-black', lineHeight }) => {
    const w = weight === 'normal' ? 700 : 900;
    return H2Style(color, w, lineHeight);
  }}

  ${({ underline }) => underline && TooltipUnderlineStyle}
`;

/* H3 */
export const H3 = styled.h3<BaseTypeInterface>`
  ${({ weight = 'bold', color = '--text-black', lineHeight }) => {
    const w = weight === 'normal' ? 700 : 900;
    return H3Style(color, w, lineHeight);
  }}

  ${({ underline }) => underline && TooltipUnderlineStyle}
`;

/* H4 */
export const H4 = styled.h4<BaseTypeInterface>`
  ${({ weight = 'bold', color = '--text-black', lineHeight }) => {
    const w = weight === 'normal' ? 700 : 900;
    return H4Style(color, w, lineHeight);
  }}

  ${({ underline }) => underline && TooltipUnderlineStyle}
`;

/* H5 */
export const H5 = styled.h5<BaseTypeInterface>`
  ${({ weight = 'bold', color = '--text-black', lineHeight }) => {
    const w = weight === 'normal' ? 500 : 700;
    return H5Style(color, w, lineHeight);
  }}

  ${({ underline }) => underline && TooltipUnderlineStyle}
`;

/* H6 */
export const H6 = styled.h6<BaseTypeInterface>`
  ${({ color = '--text-black' }) => H6Style(color)}
  ${({ underline }) => underline && TooltipUnderlineStyle}
`;

/* PARAGRAPH */
export interface ParagraphInterface extends BaseTypeInterface {
  size?: 'normal' | 'small' | 'mini';
}

export const Paragraph = styled.div.attrs<{ className: string }>({
  className: 'paragraph',
})<ParagraphInterface>`
  ${({ size = 'normal', weight = 'normal', color = '--text-gray', lineHeight }) => {
    const w = weight === 'normal' ? 300 : 700;

    switch (size) {
      case 'mini':
        return ParagraphMiniStyle(color, w, lineHeight);
      case 'small':
        return ParagraphSmallStyle(color, w, lineHeight);
      case 'normal':
      default:
        return ParagraphNormalStyle(color, w, lineHeight);
    }
  }}

  ${({ underline }) => underline && TooltipUnderlineStyle}

  b {
    font-weight: 700;
    color: ${({ color }) => (color === '--text-gray' ? '--text-black' : 'inherit')};
  }
`;

/* LABEL */
export const Label = styled.label<BaseTypeInterface>`
  ${LabelStyle}
  color: var(--text-black);
  ${({ underline }) => underline && TooltipUnderlineStyle}
`;

/* NUMBERS */
export interface NumbersInterface extends BaseTypeInterface {
  size?: 'large' | 'medium' | 'small';
}

export const Numbers = styled.div.attrs<{ className: string }>({
  className: 'numbers',
})<NumbersInterface>`
  ${({ size = 'small', weight = 'normal', color = '--text-black' }) => {
    const w = weight === 'normal' ? 500 : 900;

    switch (size) {
      case 'large':
        return NumbersLargeStyle(color, w);
      case 'medium':
        return NumbersMediumStyle(color, w);
      case 'small':
      default:
        return NumbersSmallStyle(color, w);
    }
  }}
`;

/* MISC */
export const TooltipUnderlineWrapper = styled.span`
  ${TooltipUnderlineStyle}
`;
