import { hsaContributionExplainer } from 'ContentfulDefaults/defaults/english/hsaContributionExplainer';
import { hsaInteractiveSection } from 'ContentfulDefaults/defaults/english/hsaInteractiveSection';
import { ContentfulHsaSection } from 'ContentfulDefaults/types/hsa';

export const hsa: {
  hsa: ContentfulHsaSection;
} = {
  hsa: {
    contentful_name: 'HSA+ (DEFAULT DO NOT CHANGE)',
    button_text: {
      back: 'Ver plans de salud',
      save: 'Guardar contribución y continuar',
      review: 'Continuar revisando',
      expensesPDF: 'Todos los gastos elegibles (PDF)',
      supplemental: 'Continuar a Supplemental',
      balanceSubmit: 'Enviar',
    },
    info_bar: '¡Genial, ha seleccionado un plan de salud elegible para HSA!',
    page_header: {
      text: 'Contribución de la HSA',
      subtext: 'Averigüemos cuánto desea contribuir.',
    },
    // TODO: Do we want to make a spanish version of this?
    contribution_explainer: hsaContributionExplainer,
    header: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Lo que hay que saber sobre tu',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Cuenta de ahorro de salud',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-1',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    section_cards: {
      faq: {
        tags: ['Cómo puedo...', 'Y si...', 'HSA versus'],
        heading: 'Preguntas frecuentes',
        subheading: 'Algunos datos sobre su HSA.',
      },
      about: {
        tags: ['Los basicos', 'Gastos cubiertos'],
        heading: 'Comprenda sus beneficios',
        subheading: 'Cómo una HSA puede ayudarlo a usted y su familia.',
      },
      longterm: {
        tags: ['HSA Investing', 'Ventaja de triple impuesto'],
        heading: 'Prepárate para el futuro',
        subheading: 'Conozca su HSA a largo plazo.',
      },
      retirement: {
        tags: ['HSA Investing', 'Metas de jubilación'],
        heading: '¿Cómo puedo ahorrar para la jubilación?',
        subheading: 'Aprenda cómo ahorrar más puede ayudarlo a prepararse para la jubilación.',
      },
      contributions: {
        tags: ['Ahorro fiscal', 'Contribución del empleador'],
        heading: 'Sus contribuciones a la HSA',
        subheading: {
          self: 'Protéjase de los gastos de bolsillo.',
          family: 'Proteja a su familia de los gastos de bolsillo.',
        },
      },
    },
    open_hsa_question: {
      answerNo: 'No, no tengo una HSA.',
      question: '¿Tiene actualmente una cuenta de ahorro de salud',
      answerYes: 'Sí, tengo una HSA',
      noDescription:
        'No se preocupe, las HSA no dan tanto miedo como pueden parecer. En esta página veremos por qué las HSA son útiles, cómo configurar su cuenta y cuánto debe contribuir.',
      yesDescription:
        '¡Qué bueno escucharlo! Pero asegurémonos de que está contribuyendo lo suficiente a su cuenta. Le mostramos esta información a continuación en la sección Sus contribuciones.',
    },
    about_bubbles: {
      text: [
        'Ahorre dólares antes de impuestos en su cuenta',
        'Invierta y haga crecer estos fondos',
        'Pagar los gastos de atención médica calificados',
      ],
      icons: [
        'https://picwellapppublicdata.s3.amazonaws.com/contentful/hsaimages/piggy-bank.svg',
        'https://picwellapppublicdata.s3.amazonaws.com/contentful/hsaimages/chart.svg',
        'https://picwellapppublicdata.s3.amazonaws.com/contentful/hsaimages/about-heart.svg',
      ],
      title: 'Su cuenta de ahorro de salud le permite...',
    },
    about_video: {
      link: 'https://vimeo.com/499305539',
      intro: 'Aquí hay un video rápido para explicar:',
    },
    unused_funds: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '¿Pierdo fondos no utilizados?',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-4',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '¡No! El dinero en su HSA ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'bold',
                },
              ],
              value: 'siempre es suyo',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value: ', incluso si cambia de empleador.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    triple_tax: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '¿Qué hace que una HSA sea tan poderosa?',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-4',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'HSA es una cuenta con ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'bold',
                },
              ],
              value: '"ventajas impositivas triples"',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value:
                ', lo que significa que nunca paga impuestos sobre sus contribuciones, ganancias o retiros de HSA sobre gastos calificados.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    catchup_info: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Póngase al día con las contribuciones a los 55.',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-4',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Como usted o su cónyuge / pareja son mayores de 55 años, esto se aplica a usted. ¡Puede contribuir $ 1,000 adicionales a su HSA! Esto le permite acumular rápidamente fondos adicionales para la jubilación.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    hsa_expenses_title: '¿Qué gastos se pueden pagar con fondos HSA?',
    hsa_expenses: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Médico',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-6',
        },
        {
          data: {},
          content: [
            {
              data: {},
              content: [
                {
                  data: {},
                  content: [
                    {
                      data: {},
                      marks: [],
                      value: 'Deducibles',
                      nodeType: 'text',
                    },
                  ],
                  nodeType: 'paragraph',
                },
              ],
              nodeType: 'list-item',
            },
            {
              data: {},
              content: [
                {
                  data: {},
                  content: [
                    {
                      data: {},
                      marks: [],
                      value: 'Medicamentos con receta',
                      nodeType: 'text',
                    },
                  ],
                  nodeType: 'paragraph',
                },
              ],
              nodeType: 'list-item',
            },
            {
              data: {},
              content: [
                {
                  data: {},
                  content: [
                    {
                      data: {},
                      marks: [],
                      value: 'Productos sin receta',
                      nodeType: 'text',
                    },
                  ],
                  nodeType: 'paragraph',
                },
              ],
              nodeType: 'list-item',
            },
          ],
          nodeType: 'unordered-list',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Dental',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-6',
        },
        {
          data: {},
          content: [
            {
              data: {},
              content: [
                {
                  data: {},
                  content: [
                    {
                      data: {},
                      marks: [],
                      value: 'Limpiezas',
                      nodeType: 'text',
                    },
                  ],
                  nodeType: 'paragraph',
                },
              ],
              nodeType: 'list-item',
            },
            {
              data: {},
              content: [
                {
                  data: {},
                  content: [
                    {
                      data: {},
                      marks: [],
                      value: 'Rayos X',
                      nodeType: 'text',
                    },
                  ],
                  nodeType: 'paragraph',
                },
              ],
              nodeType: 'list-item',
            },
            {
              data: {},
              content: [
                {
                  data: {},
                  content: [
                    {
                      data: {},
                      marks: [],
                      value: 'Ortodoncia',
                      nodeType: 'text',
                    },
                  ],
                  nodeType: 'paragraph',
                },
              ],
              nodeType: 'list-item',
            },
          ],
          nodeType: 'unordered-list',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Visión',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-6',
        },
        {
          data: {},
          content: [
            {
              data: {},
              content: [
                {
                  data: {},
                  content: [
                    {
                      data: {},
                      marks: [],
                      value: 'Exámenes anuales',
                      nodeType: 'text',
                    },
                  ],
                  nodeType: 'paragraph',
                },
              ],
              nodeType: 'list-item',
            },
            {
              data: {},
              content: [
                {
                  data: {},
                  content: [
                    {
                      data: {},
                      marks: [],
                      value: 'Marcos',
                      nodeType: 'text',
                    },
                  ],
                  nodeType: 'paragraph',
                },
              ],
              nodeType: 'list-item',
            },
            {
              data: {},
              content: [
                {
                  data: {},
                  content: [
                    {
                      data: {},
                      marks: [],
                      value: 'Lentes + Contactos',
                      nodeType: 'text',
                    },
                  ],
                  nodeType: 'paragraph',
                },
              ],
              nodeType: 'list-item',
            },
          ],
          nodeType: 'unordered-list',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    eligible_expenses_pdf: 'https://picwellapppublicdata.s3.amazonaws.com/hsa-eligible-expenses.pdf',
    contribution_question: {
      answerNo: 'No, nunca he contribuido',
      question: '¿Has contribuido a una HSA antes?',
      answerYes: 'Sí, he contribuido antes.',
      noDescription:
        'Estamos aquí para ayudarlo. Contribuir con la cantidad correcta a su cuenta es importante para asegurarse de que pueda pagar los gastos de bolsillo durante todo el año.',
      yesDescription:
        '¡Impresionante, estás por delante de la manada! Dicho esto, muchas personas que tienen HSA no contribuyen adecuadamente a ellos. A continuación nos aseguraremos de que cubras cualquier riesgo de que puedas estar expuesto a esto año.',
    },
    contribution_info: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Entonces, ¿cuánto debería contribuir realmente?',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-4',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Como mínimo, debe planear contribuir lo suficiente ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'bold',
                },
              ],
              value: 'para cubrir su estimación de costos de bolsillo promedio',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value:
                ', pero cuanto más ahorre, mejor preparado estará si tiene costos más altos que el promedio.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'El límite de contribución anual a la HSA es ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'code',
                },
              ],
              value: '{x}',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value: ', por lo que después de contabilizar la contribución de su empleador de ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'code',
                },
              ],
              value: '{x}',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value: ', puede contribuir hasta ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'code',
                },
              ],
              value: '{x}{x}',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value: '.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    catchup_addition: '+ $ 1,000 en contribuciones para ponerse al día',
    slider_instruction:
      'Mueva el control deslizante a continuación para ajustar el monto de su contribución.',
    legend_terms: [
      {
        text: 'Los costos de atención médica que la compañía de seguros no paga. En otras palabras, cuando recibe una factura médica, esta es la cantidad de la que usted es responsable.',
        type: 'oop',
        title: 'Estimación de gastos de bolsillo',
        underline: 'Estimación de gastos de bolsillo',
      },
      {
        text: 'El monto que su empleador contribuirá a su HSA.',
        type: 'employer',
        title: 'Contribución del empleador',
        underline: 'Empleador',
      },
      {
        text: 'La cantidad que contribuirá durante todo el año.',
        type: 'recommended',
        title: 'Tu contribución',
        underline: 'Usted',
      },
      {
        text: 'El monto que estimamos que usted deberá en gastos médicos al monto de la contribución actual.',
        type: 'remaining',
        title: 'Gastos restantes',
        underline: 'Restante',
      },
    ],
    contribution_data: {
      taxSavings: {
        text: 'Esto es cuánto puede ahorrar en impuestos federales al hacer una contribución libre de impuestos a su HSA. Eso es básicamente dinero gratis que puede usar para ayudar a cubrir sus costos de atención médica en lugar de pagar al gobierno. Dependiendo de dónde si vive, puede obtener ahorros adicionales en los impuestos estatales sobre la renta ',
        underline: 'Ahorro fiscal',
      },
      perPaycheck: 'Per Paycheck',
      yearlyContribution: 'Contribución anual',
    },
    risk_data: {
      text: 'Esto le indica el porcentaje de posibilidades de que sus contribuciones a la HSA sean suficientes para cubrir sus gastos de bolsillo este año. Aumentar este número aumenta su seguridad financiera. Estimamos este número en función del gasto en atención médica de las personas Como tú.',
      underline: '¿Estoy ahorrando lo suficiente?',
      riskLevels: {
        safe: {
          tag: 'Safe',
          status: '¡Genial, estás a salvo!',
          explanation:
            'Existe una alta probabilidad de que este monto de contribución sea suficiente para cubrir sus gastos de bolsillo este año.',
        },
        risky: {
          tag: 'Risky',
          status: '¡Esto es arriesgado!',
          explanation:
            'Hay pocas posibilidades de que este monto de contribución sea suficiente para cubrir sus costos de OOP este año. Contribuir más aumentará su seguridad financiera y ahorrará más en impuestos.',
        },
        moderate: {
          tag: 'Moderate',
          status: 'No está mal',
          explanation:
            'This should be enough to cover your OOP costs in a typical year, but there is still moderate risk if you end up needing more medical care than usual.',
        },
      },
    },
    investment_question: {
      answerNo: 'No, no he invertido fondos',
      question: '¿Ha invertido sus fondos de HSA antes?',
      answerYes: 'Sip, estoy invirtiendo fondos HSA',
      noDescription:
        '¡Nunca es demasiado tarde para comenzar! La atención médica es uno de los mayores gastos para los que tendrá que prepararse para la jubilación, y al invertir los fondos de su HSA, puede aprovechar los intereses compuestos y las exenciones fiscales especiales de una HSA para asegurarse de que está listo para estos gastos ',
      yesDescription:
        '¡Buen trabajo! La atención médica es costosa, especialmente cuando envejece. Ahorrar e invertir en una HSA es la mejor manera de prepararse para sus costos de atención médica en la jubilación. Puede usar nuestra herramienta de pronóstico para ayudar a determinar cuánto más tendrá que ahorrar para cubrir sus costos de atención médica en la jubilación ',
    },
    long_term_header: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'La pareja promedio que se jubila hoy puede esperar gastar alrededor de $ 300,000 para cubrir los costos de atención médica en la jubilación.',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-4',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'La atención médica estará entre sus mayores gastos en la jubilación, pero ahorrar e invertir con una HSA es una excelente manera de prepararse para estos costos. De hecho, ¡son la mejor manera de ahorrar para los costos de atención médica en la jubilación!',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    triple_tax_header: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '¡Haciendo que su HSA trabaje para usted!',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-3',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Las HSA son una excelente manera de ahorrar para la jubilación porque tienen ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'bold',
                },
              ],
              value: 'una ventaja fiscal triple',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value: ':',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    triple_tax_bullets: [
      'Cualquier contribución que haga a una HSA está exenta de impuestos',
      'Su dinero puede crecer libre de impuestos a través de inversiones',
      'A diferencia de un 401 (k), cuando retira fondos de HSA, no pagará impuestos si usa el dinero para cubrir gastos médicos calificados',
    ],
    picwell_disclaimer:
      'Descargo de responsabilidad: las estimaciones de Picwell suponen una tasa de rendimiento constante del 6% sobre los saldos de HSA invertidos. Los resultados pueden variar según el rendimiento real del mercado.',
    retirement_savings_info: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '¿Cuánto necesito ahorrar para la jubilación?',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-4',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'La atención médica estará entre sus mayores gastos en la jubilación, pero ahorrar e invertir con una HSA es una excelente manera de prepararse para estos costos. Estimamos que las personas de su edad (jubiladas a los 65 años) deberían gastar ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'code',
                },
              ],
              value: '{x}',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value: ' durante su jubilación en gastos relacionados con la atención médica.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Esto es lo que estimamos que serían sus primas y gastos de bolsillo en un plan típico de Medicare, suponiendo que se retire cuando cumpla 65 años y tenga una esperanza de vida promedio. Nuestra estimación representa su edad, sexo y la inflación de costos de atención médica esperada.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Use nuestra herramienta de pronóstico a continuación para ver cómo ahorrar más en su HSA puede ayudarlo a prepararse para sus costos de atención médica en la jubilación.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    current_hsa_info: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '¿Cuál es su saldo actual de HSA?',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-4',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Una aproximación está bien aquí. Utilizaremos esto para representar mejor sus ahorros en el futuro.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    current_hsa_balance_question: {
      header: '1. ¿Cuál es su saldo actual de HSA?',
      description:
        'Aquí está bien una aproximación. Usaremos esto para representar mejor sus ahorros en el futuro.',
    },
    persona_question: {
      header: '2. ¿Cuál describe mejor cómo usa su HSA?',
      spender: {
        title: 'Gastador',
        description: 'Utilizo mi HSA para cubrir las facturas médicas y guardar todo lo que sobra.',
      },
      supersaver: {
        title: 'Super Ahorrador',
        description: 'Invierto todo lo que pongo en mi HSA y pago las facturas médicas con otros fondos.',
      },
    },
    long_term_graph: {
      title: {
        text: 'Para pronosticar sus ahorros de HSA, consideramos los saldos actuales de HSA, y luego, cuando elige un nivel de contribución de HSA, calculamos cuánto ahorro le quedará después de cubrir sus costos de OOP pronosticados cada año. Pronosticamos cuántos ahorros adicionales acumulará en su HSA cada año y luego asumimos que invierte estos ahorros adicionales y obtiene una tasa de rendimiento anual del 6%.',
        underline: 'Ahorro vs. Objectivo',
      },
      labels: ['Objetivo', 'Ahorros'],
      loadingText: 'Cargando resultados ...',
    },
    retirement_data: {
      text: 'Esto le indica el porcentaje de su objetivo de ahorro que alcanzará si ahorra tanto en su HSA cada año',
      underline: '¿Estoy listo para la jubilación?',
      riskLevels: {
        safe: {
          tag: 'Seguro',
          status: 'Estás en camino',
          explanation:
            'Con estos ahorros, existe una buena posibilidad de que ahorre más de lo que necesita gastar en atención médica, y eso es una gran cosa. Estará listo para costos superiores a los esperados',
          bestExplanation:
            'Sus ahorros lo acercarán bastante a sus objetivos de jubilación. Si puede ahorrar un poco más, su HSA le brinda la forma más eficiente en materia de impuestos para llegar hasta allí.',
        },
        risky: {
          tag: 'Arriesgado',
          status: '¡Estás fuera de pista!',
          explanation:
            'Esta cantidad de ahorros no le dejará suficiente para cubrir sus costos de atención médica de jubilación.',
        },
        moderate: {
          tag: 'Moderado',
          status: 'Estás fuera de pista pero te estás acercando',
          explanation:
            'Es posible que no haya ahorrado lo suficiente para cubrir sus costos de atención médica de jubilación, pero se está acercando a su objetivo.',
        },
      },
    },
    how_to_fa_qs: [
      {
        answer:
          'Puede abrir una HSA a través de su plataforma de administración de beneficios. Puede encontrar un enlace a su plataforma de administración de HSA en la página de revisión',
        question: '¿Cómo abro una HSA?',
      },
      {
        answer:
          '(1) Deducción de nómina: su contribución anual se dividirá en cantidades iguales y se deducirá de su nómina antes de impuestos (2) Transferencia electrónica desde su cuenta corriente o de ahorros utilizando su plataforma de administración HSA (enlace en la página de revisión). ',
        question: '¿Cómo contribuyo a mi HSA?',
      },
      {
        answer:
          'Cuando abra una HSA, recibirá una tarjeta de débito que puede usar para pagar los gastos médicos elegibles. Puede usar esta tarjeta de débito para pagar directamente a los proveedores de atención médica y también puede enviar estos pagos a través del sitio web de su compañía de seguros ',
        question: '¿Cómo uso mis fondos de la HSA?',
      },
      {
        answer:
          'Cuando abra una HSA, recibirá una tarjeta de débito que puede usar para pagar los gastos médicos elegibles. Puede usar esta tarjeta de débito para pagar directamente a los proveedores de atención médica y también puede enviar estos pagos a través del sitio web de su compañía de seguros ',
        question: '¿Cómo recibo la contribución de mi empleador?',
      },
    ],
    chart_comparison_fa_qs: {
      hsaFsaHra: {
        cells: [
          ['El dinero en la cuenta te pertenece', 'X', '', ''],
          ['El dinero en la cuenta pertenece a su empleador', '', 'X', 'X'],
          ['Puedes contribuir', 'X', 'X', ''],
          ['Su empleador puede contribuir', 'X', 'X', 'X'],
          ['Debe tener un plan de deducible alto para contribuir', 'X', '', ''],
          ['Puedes invertir fondos', 'X', '', ''],
        ],
        question: 'HSA, FSA, HRA: ¿Cuál es la diferencia?',
      },
      hsa401kIra: {
        cells: [
          [
            'Las contribuciones están exentas de impuestos',
            'X',
            'Las contribuciones 401 (k) no están excluidas de los impuestos del Seguro Social y Medicare',
            '',
          ],
          ['Las ganancias de captura están exentas de impuestos', 'X', 'X', 'X'],
          [
            'Los retiros son libres de impuestos.',
            'Si se usa para gastos médicos calificados.',
            'X',
            'Solo Roth IRA.',
          ],
          ['Requisitos mínimos de distribución.', 'X', 'X', 'IRA Roth excluidos.'],
        ],
        question: 'HSA, 401 (k), IRA: ¿Cuál es la diferencia?',
      },
    },
    what_if_fa_qs: [
      {
        answer:
          'Una vez que deposita los fondos en una HSA, la cuenta se puede usar para pagar los gastos médicos calificados libres de impuestos, incluso si ya no tiene cobertura HDHP. Sin embargo, no podrá hacer nuevas contribuciones ',
        question: '¿Qué sucede si no me inscribo en un plan elegible para HSA en el futuro?',
      },
      {
        answer:
          'El dinero en su HSA sigue siendo suyo incluso después de perder o dejar su trabajo. Por lo general, puede mantener ese dinero en la HSA de su antiguo empleador, o puede transferirlo a otro administrador de la HSA',
        question: '¿Qué sucede con mi HSA / fondos si cambio de trabajo o me despiden?',
      },
      {
        answer:
          'Una vez que cumpla 65 años, independientemente de si está inscrito en Medicare, no hay penalidad por retirar fondos de la HSA. Si retira fondos para gastos médicos calificados, no pagará ningún impuesto. Los fondos de la HSA utilizados para otros fines se gravan como ingresos normales, al igual que un retiro 401 (k)',
        question: '¿Qué pasa con mi HSA después de jubilarme?',
      },
    ],
    next_text: {
      review: 'A continuación, revisaremos',
      supplemental: 'A continuación, seleccionaremos planes complementarios',
    },
    contribution_modal: {
      title: 'Contribución guardada',
      content: 'Hemos bloqueado el monto de su contribución de',
      disclaimer: 'Regrese en cualquier momento para cambiar esta cantidad.',
    },
    interactive_section: hsaInteractiveSection,
    learn_more_modal_info:
      'Conozca los conceptos básicos de su HSA, para qué se puede utilizar, las preguntas más frecuentes y más.',
    learn_more_modal_button_text: 'Aprender más (PDF)',
    ineligible_health_plan_text: 'Ha seleccionado un plan de salud que no es elegible para una HSA.',
    ineligible_health_plan_subtext: 'Por favor continúe o seleccione un plan de salud diferente.',
    unselected_health_plan_text: 'Actualmente no tiene ningún plan de salud seleccionado.',
    unselected_health_plan_subtext: 'Por favor continúe o seleccione un plan de salud diferente.',
  },
};

export default hsa;
