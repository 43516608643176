import React, { useEffect, useState } from 'react';

import { useTextContext } from 'Contexts/textContext';
import { Paragraph } from 'DesignLibrary/atoms';
import { NumberWithPayPeriod } from 'DesignLibrary/atoms/NumberWithPayPeriod';
import { PremiumPerDeduction } from 'DesignLibrary/atoms/PremiumPerDeduction';
import { TooltipPlanDetails } from 'DesignLibrary/molecules';
import RichText from 'Shared/RichText';
import Text from 'Shared/Text';
import { GetHsaRecommendationResponse } from 'Utils/apiTypes';

import { getOopChance } from '../../helpers';
import { Rating, Risk } from '../_shared/Rating';
import { InfoContainer } from '../styled';

interface ShortTermDetailsProps {
  hsaData: GetHsaRecommendationResponse | Record<string, never>;
  contribution: number;
  deductionsPerYear?: number;
}

type RiskLevel = 'risky' | 'moderate' | 'safe';

export const ShortTermDetails = ({
  hsaData,
  contribution,
  deductionsPerYear = 12,
}: ShortTermDetailsProps) => {
  const [taxBenefit, setTaxBenefit] = useState(0);
  const [oopChance, setOopChance] = useState(0);

  const { retrieveContentfulData } = useTextContext();

  useEffect(() => {
    // Update tax benefit when contribution changes
    setTaxBenefit(parseInt((hsaData.tax_rate * contribution).toString(), 10));

    // Update risk coverage % when contribution changes
    setOopChance(
      parseInt((getOopChance(hsaData.recommended_contribution_deciles, contribution) * 100).toString(), 10),
    );
  }, [contribution]);

  const perPaycheckContribution = (
    <PremiumPerDeduction
      keyHint="per-paycheck-contribution-value"
      annualPremium={contribution}
      deductions={deductionsPerYear}
      currency
    />
  );

  // Risk Level
  let riskLevel: RiskLevel = 'risky';
  if (oopChance > 60) {
    riskLevel = 'moderate';
  }
  if (oopChance > 85) {
    riskLevel = 'safe';
  }

  return (
    <div className="details">
      <InfoContainer>
        <div>
          <TooltipPlanDetails
            tooltip={retrieveContentfulData<string>('hsa.interactive_section.tooltips.yearly_contribution')}
            keyHint="yearly-contribution"
          >
            <Text field="hsa.interactive_section.yearly_contribution" />
          </TooltipPlanDetails>
          <NumberWithPayPeriod
            value={contribution}
            payPeriodType="yearly"
            keyHint="yearly-contribution-value"
          />
        </div>
        <div>
          <TooltipPlanDetails
            tooltip={retrieveContentfulData<string>(
              'hsa.interactive_section.tooltips.per_paycheck_contribution',
            )}
            keyHint="per-paycheck-contribution"
          >
            <Text field="hsa.interactive_section.per_paycheck_contribution" />
          </TooltipPlanDetails>
          {perPaycheckContribution}
        </div>
        <div>
          <TooltipPlanDetails
            tooltip={retrieveContentfulData<string>('hsa.interactive_section.tooltips.yearly_tax_savings')}
            keyHint="yearly-tax-savings"
          >
            <Text field="hsa.interactive_section.yearly_tax_savings" />
          </TooltipPlanDetails>
          <NumberWithPayPeriod
            value={taxBenefit}
            payPeriodType="yearly"
            prefix="+"
            keyHint="yearly-tax-savings-value"
          />
        </div>
      </InfoContainer>
      <Rating type="short" risk={riskLevel as Risk}>
        <Paragraph weight="bold" size="small">
          <RichText noWrapper field="hsa.interactive_section.coverage_statistic" vars={[oopChance]} />
        </Paragraph>
        <Paragraph size="small">
          <Text field={`hsa.interactive_section.yearly_coverage_detail_${riskLevel}`} />
        </Paragraph>
      </Rating>
    </div>
  );
};
