import styled from 'styled-components';

export const MobileMenu = styled.div`
  border-top: 2px solid var(--border-gray);

  button {
    width: 100%;
    margin-top: 0 !important;
    display: flex;
    justify-content: space-between;
  }
`;

export const MobileNavFooter = styled.div`
  flex-shrink: 0;

  .alex-id {
    button {
      width: 100%;
    }
  }

  img {
    object-fit: contain;
    height: var(--sizing-lg);

    &:first-child {
      border-right: 2px solid var(--border-gray);
      padding-right: var(--sizing-xs);
    }
  }
`;
