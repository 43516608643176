import styled from 'styled-components';

export const MobileTooltip = styled.div`
  padding: var(--sizing-sm);
  position: relative;

  * + * {
    margin-top: var(--sizing-xs);
  }

  .title {
    margin-bottom: var(--sizing-sm);
  }

  .paragraph {
    color: var(--primary-black);
  }

  .close {
    position: absolute;
    top: var(--sizing-xs);
    right: var(--sizing-xs);
    margin: 0;

    button {
      position: static;
    }
  }
`;

interface DesktopTooltipProps {
  isInteractive?: boolean;
}

export const DesktopTooltip = styled.div<DesktopTooltipProps>`
  padding: ${({ isInteractive }) => (isInteractive ? '2px 8px' : '12px')};

  * + * {
    margin-top: var(--sizing-xs);
  }

  .paragraph {
    color: var(--primary-white);
  }
`;
