import { fromJS } from 'immutable';

import { GET_AAPI_USER_SUCCESS } from 'Containers/App/constants';
import { AlexIdUser } from 'Containers/App/types';
import { makeNewCommExternalId } from 'Containers/App/utils';
import { SET_SELECTED_MEMBER_IDS } from 'Containers/CommercialRoutes/constants';
import { determineHiddenEqs } from 'Containers/ProfilePage/utils';
import { Household, HouseholdMember, PAY_PERIOD_VALUES } from 'Types/entities';
import {
  ClientSurveySchema,
  GetBatchDrugDetailsResponse,
  GetDrugsResponse,
  GetLocationsResponse,
  IncentiveSurveyResponse,
  ItemReference,
  JVPEligibilityQuestion,
  JVPEligibilityQuestions,
  JVPSexAtBirth,
  JVPUserWithDependents,
  PreviousSelectedPlan,
} from 'Utils/apiTypes';
import { createTypedMap, TypedMap } from 'Utils/immutable-types';
import Logger from 'Utils/logger';
import { wrapImmutableReducerInPojoTranslation } from 'Utils/reducers';
import { validDependantAge, validPolicyholderAge, validSpouseAge } from 'Utils/validators';

import {
  CHANGE_FORM_VALUE,
  CHANGE_SURVEY_VALUE,
  CHANGE_SURVEY_VALUE_FROM_RESULT_PAGE,
  CREATE_OR_UPDATE_HOUSEHOLD_REQUEST,
  CREATE_OR_UPDATE_HOUSEHOLD_SUCCESS,
  EDIT_DEPENDENT,
  EDIT_POLICYHOLDER,
  EDIT_SPOUSE,
  GET_CLIENT_SURVEY_FAILURE,
  GET_CLIENT_SURVEY_REQUEST,
  GET_CLIENT_SURVEY_SUCCESS,
  GET_HOUSEHOLD_SUCCESS,
  GET_INCENTIVE_SURVEY_FAILURE,
  GET_INCENTIVE_SURVEY_SUCCESS,
  GET_JVP_CLIENT_SURVEY_FAILURE,
  GET_JVP_CLIENT_SURVEY_REQUEST,
  GET_JVP_CLIENT_SURVEY_SUCCESS,
  GET_JVP_ELIGIBILITY_QUESTIONS_FAILURE,
  GET_JVP_ELIGIBILITY_QUESTIONS_SUCCESS,
  PREFILL_USER_PROFILE_SUCCESS,
  REMOVE_DEPENDENT,
  REMOVE_SPOUSE,
  RESET_COUNTY_RESULTS,
  RESET_FORM_DIRTY_STATE,
  SANITIZE_PROFILE_INPUTS,
  SEARCH_DRUGS_FAILURE,
  SEARCH_DRUGS_REQUEST,
  SEARCH_DRUGS_SUCCESS,
  SEARCH_LOCATIONS_FAILURE,
  SEARCH_LOCATIONS_REQUEST,
  SEARCH_LOCATIONS_SUCCESS,
  SET_ACTIVE_HOUSEHOLE_MEMBERS,
  SET_INCENTIVE_SURVEY,
  SET_RESULT_PAGE_INCENTIVE_SURVEY,
  STANDARD_EQ_TO_QUESTION_CODE,
  SUBMIT_CLIENT_SURVEY,
  SUBMIT_INCENTIVE_SURVEY,
  SUBMIT_RESULT_INCENTIVE_SURVEY,
  SurveyQuestionMap,
  UPDATE_HOUSEHOLD_FROM_RESULT_PAGE,
} from './constants';
import {
  CapacityToPayAnswer,
  ClientSurveyResponsesByYear,
  CountyResult,
  genderValues,
  IncentiveSurveyAnswer,
  IncentiveSurveyQuestion,
  Member,
  MEMBER_TYPE,
  MemberSectionConfigFields,
  PlannedProcedures,
  Prescription,
  ResultPageIncentiveSurveyData,
  RiskAssessmentAnswer,
  RxSearchResults,
  Survey,
  UpdateMemberFields,
} from './types';

function makeInitialUtilization() {
  return {
    inpatient_days: 0,
    specialist_visits: 0,
    pcp_visits: 0,
    mental_health_visits: 0,
  };
}

function makeInitialPlannedProcedures(): PlannedProcedures {
  return {
    inpatient_procedures: 0,
    outpatient_procedures: 0,
    routine_therapy_visits: 0,
    mental_health_visits: 0,
  };
}

export const newMemberState = (memberType: Member['member_type']): Member =>
  makeNewMemberState(memberType, makeNewCommExternalId());

export const newMemberStateWithExistingExternalId = (
  memberType: Member['member_type'],
  externalId: string,
): Member => makeNewMemberState(memberType, externalId);

const makeNewMemberState = (memberType: Member['member_type'], externalId: string): Member => ({
  external_id: externalId,
  member_type: memberType,
  isValid: false,
  pristine: true,
  isComplete: false,
  age: '',
  gender: '',
  uses_tobacco: null,
  uses_prescriptions: null,
  prescriptions: [],
  uses_utilization: null,
  utilization: makeInitialUtilization(),
  uses_planned_procedures: null,
  planned_procedures: makeInitialPlannedProcedures(),
  income: '',
  isSkipIncome: false,
  isCovered: true,
});

function shouldCollectTobacco(
  member_type: MEMBER_TYPE,
  collectTobaccoUsageForPolicyholder: boolean,
  collectTobaccoUsageForSpouse: boolean,
) {
  return (
    (member_type === MEMBER_TYPE.POLICYHOLDER && collectTobaccoUsageForPolicyholder) ||
    ([MEMBER_TYPE.SPOUSE, MEMBER_TYPE.SPOUSE_OR_DOMESTIC_PARTNER, MEMBER_TYPE.DOMESTIC_PARTNER].includes(
      member_type,
    ) &&
      collectTobaccoUsageForSpouse)
  );
}

function isMemberComplete(
  member: Member,
  memberConfig: MemberSectionConfigFields | Record<string, never> = {},
): boolean {
  const {
    collectTobaccoUsageForPolicyholder,
    collectTobaccoUsageForSpouse,
    collectIsSpouseOrDomesticPartner,
    isProductBc,
    enablePlannedProcedures,
    sex_at_birth_enabled,
  } = memberConfig;
  const {
    age,
    gender,
    member_type,
    planned_procedures,
    prescriptions,
    uses_planned_procedures,
    uses_prescriptions,
    uses_tobacco,
    uses_utilization,
    utilization,
  } = member;

  const genderIsComplete = gender !== '' || !sex_at_birth_enabled;
  const ageIsComplete = age !== '';
  const utilizationIsComplete = uses_utilization !== null && !!utilization;
  const prescriptionsIsComplete =
    uses_prescriptions !== null && (!uses_prescriptions || prescriptions.length > 0);

  const plannedProceduresIsComplete = enablePlannedProcedures
    ? uses_planned_procedures !== null && !!planned_procedures
    : true;

  const spouseTypeIsComplete = !collectIsSpouseOrDomesticPartner || !isProductBc || member_type !== null;

  const collectTobaccoUsage = shouldCollectTobacco(
    member_type,
    collectTobaccoUsageForPolicyholder,
    collectTobaccoUsageForSpouse,
  );

  const tobaccoIsComplete = !collectTobaccoUsage || uses_tobacco !== null;

  const isComplete =
    ageIsComplete &&
    genderIsComplete &&
    utilizationIsComplete &&
    plannedProceduresIsComplete &&
    prescriptionsIsComplete &&
    spouseTypeIsComplete &&
    tobaccoIsComplete;

  return isComplete;
}

function validateMember(
  member: Member,
  memberConfig: MemberSectionConfigFields | Record<string, never> = {},
): boolean {
  const {
    collectTobaccoUsageForPolicyholder,
    collectTobaccoUsageForSpouse,
    collectIsSpouseOrDomesticPartner,
    isProductBc,
    enablePlannedProcedures,
    sex_at_birth_enabled,
  } = memberConfig;

  const { member_type, gender, age } = member;

  let isValidGender = genderValues.includes(gender) && gender !== '';
  if (!sex_at_birth_enabled) {
    // If sex at birth question is disabled, then allow empty genders.
    isValidGender = true;
  }

  let isValidAge = age !== '';

  if (isValidAge) {
    if (member_type === MEMBER_TYPE.POLICYHOLDER) {
      isValidAge = validPolicyholderAge(age);
    }
    if (
      member_type === MEMBER_TYPE.SPOUSE ||
      member_type === MEMBER_TYPE.SPOUSE_OR_DOMESTIC_PARTNER ||
      member_type === MEMBER_TYPE.DOMESTIC_PARTNER
    ) {
      isValidAge = validSpouseAge(age);
    } else if (member_type === MEMBER_TYPE.CHILD) {
      isValidAge = validDependantAge(age);
    }
  }

  const isChild = member_type === MEMBER_TYPE.CHILD;

  const collectTobaccoUsage = shouldCollectTobacco(
    member_type,
    collectTobaccoUsageForPolicyholder,
    collectTobaccoUsageForSpouse,
  );

  const isValidTobacco =
    !collectTobaccoUsage || isChild || parseInt(age, 10) < 18 || member.uses_tobacco !== null;

  const isValidSpouseOrDomesticPartner =
    !collectIsSpouseOrDomesticPartner ||
    !isProductBc ||
    member_type !== MEMBER_TYPE.SPOUSE_OR_DOMESTIC_PARTNER;

  const isValidPrescriptions =
    (member.uses_prescriptions && member.prescriptions.length > 0) || member.uses_prescriptions === false;

  const isValidUtilization =
    (member.uses_utilization && Object.values(member.utilization).some((i) => i > 0)) ||
    member.uses_utilization === false;

  const isValidPlannedProcedures =
    !enablePlannedProcedures || // if the feature flag is disabled for the customer, then having no planned procedures is valid
    (member.uses_planned_procedures && Object.values(member.planned_procedures).some((i) => i > 0)) ||
    member.uses_planned_procedures === false;

  // Only used for displaying error style on form input
  const isValid =
    isValidGender &&
    isValidAge &&
    isValidTobacco &&
    isValidSpouseOrDomesticPartner &&
    isValidPrescriptions &&
    isValidUtilization &&
    isValidPlannedProcedures;

  return isValid;
}

export interface IProfilePageReducerState {
  prefillHasCompleted: boolean;
  householdIsLoaded: boolean;
  activeMembers: string[];

  // Eligibility section fields
  clientSurvey: Record<string, ClientSurveySchema | null>;
  clientSurveyResponses: ClientSurveyResponsesByYear;
  clientSurveyIsLoaded: boolean;
  clientSurveyIsLoading: boolean;
  clientSurveyError: Error | null;

  jvpEligibilityQuestions: {
    active: JVPEligibilityQuestion[] | null;
    upcoming: JVPEligibilityQuestion[] | null;
  };
  jvpEligibilityQuestionsLoading: boolean;
  jvpEligibilityQuestionsError: Error | null;

  // Location section fields
  zipcode: string; // empty mean unset
  filing_status: string; // empty mean unset
  state_code: string; // empty mean unset
  countyResults: CountyResult[]; // haven't checked this, but probably this type
  countySearchError: Error | null;
  countyIsLoading: boolean;

  // Member section fields

  // todo i think this is 'sso' specific but should probably be consolidated
  //  with jvp behaviors.
  external_id: string; // empty mean unset

  policyholder: Member;
  spouse: Member;
  dependents: Member[];

  searchIsLoading: boolean;
  searchError: Error | null; // TODO is this the right type?

  // Prescription section fields
  rxQuery: string; // empty mean unset
  rxResults: RxSearchResults;
  rxError: Error | null;
  rxIsLoading: boolean;
  rxIsSkipped: boolean;
  prescriptions: Prescription[]; // unsure

  // Capacity to pay, risk assessment, pregnancy fields
  survey: Survey;
  previousPlan: PreviousSelectedPlan[] | null;

  // Optional ben-admin specific field to set payPeriod from Comm-API
  payPeriodsPerYear?: PAY_PERIOD_VALUES;
  hiddenQuestions: { eligibility: string[]; location: string[] };
  incentiveSurveyIsLoading: boolean;
  incentiveSurvey: IncentiveSurveyQuestion[];
  incentiveSurveyAnswers: Record<string, IncentiveSurveyAnswer | null>;
  incentiveSurveyError: Error | null;
  household_id: string | null;
  incentiveSurveyAPICalled: false;
  resultPageIncentiveSurvey: ResultPageIncentiveSurveyData;

  // From AAPI user profile, format is {customerKey}/{publicationSlug}
  // where publication slug is the plan year
  customerProductKey: string | null;
}

export const initialProfilePageReducerState: IProfilePageReducerState = {
  prefillHasCompleted: false,
  householdIsLoaded: false,
  household_id: null,
  activeMembers: [],
  // Eligibility section fields
  clientSurvey: {},
  clientSurveyResponses: {},
  clientSurveyIsLoaded: false,
  clientSurveyIsLoading: false,
  clientSurveyError: null,

  jvpEligibilityQuestions: {
    active: null,
    upcoming: null,
  },
  jvpEligibilityQuestionsError: null,
  jvpEligibilityQuestionsLoading: false,

  // Location section fields
  zipcode: '',
  filing_status: '',
  state_code: '',
  countyResults: [],
  countySearchError: null,
  countyIsLoading: false,

  // Member section fields

  // todo i think this is 'sso' specific but should probably be consolidated
  //  with jvp behaviors.
  external_id: '',
  policyholder: {
    ...newMemberState(MEMBER_TYPE.POLICYHOLDER),
    utilization: {
      inpatient_days: 0,
      specialist_visits: 0,
      pcp_visits: 0,
      mental_health_visits: 0,
    },
    isCovered: true,
  },
  spouse: {
    ...newMemberState(MEMBER_TYPE.SPOUSE_OR_DOMESTIC_PARTNER),
  },
  dependents: [{ ...newMemberState(MEMBER_TYPE.CHILD), uses_tobacco: false }],

  // Prescription section fields
  rxQuery: '',
  rxResults: {
    dropdown: {},
    search: [],
  },
  rxError: null,
  rxIsLoading: false,
  rxIsSkipped: false,
  prescriptions: [],

  searchIsLoading: false,
  searchError: null,

  // Capacity to pay, risk assessment, pregnancy fields
  survey: {
    risk_question_1: '',
    risk_question_2: '',
    plan_child_question: '',
    capacity_to_pay: '',
    enrollment_question: '',
  },
  previousPlan: [],
  hiddenQuestions: {
    eligibility: [],
    location: [],
  },
  incentiveSurvey: [],
  incentiveSurveyAnswers: {},
  incentiveSurveyIsLoading: false,
  incentiveSurveyAPICalled: false,
  incentiveSurveyError: null,
  resultPageIncentiveSurvey: { incentiveSurvey: [], incentiveSurveyAnswers: {} },
  customerProductKey: null,
};

const initialState: TypedMap<IProfilePageReducerState> = createTypedMap(initialProfilePageReducerState);

function profilePageReducer(state = initialState, action): unknown {
  switch (action.type) {
    case CREATE_OR_UPDATE_HOUSEHOLD_SUCCESS:
      return createOrUpdateHouseholdSuccess(state, action);
    case GET_HOUSEHOLD_SUCCESS:
      return populateHousehold(state, action);
    case PREFILL_USER_PROFILE_SUCCESS:
      return prefillUserProfile(state, action);
    case GET_CLIENT_SURVEY_REQUEST:
      return getClientSurvey(state);
    case GET_CLIENT_SURVEY_SUCCESS:
      return getClientSurveySuccess(state, action);
    case GET_CLIENT_SURVEY_FAILURE:
      return getClientSurveyFailure(state, action);
    case GET_JVP_CLIENT_SURVEY_REQUEST:
      return getJvpClientSurvey(state);
    case GET_JVP_CLIENT_SURVEY_SUCCESS:
      return getJvpClientSurveySuccess(state, action);
    case GET_JVP_CLIENT_SURVEY_FAILURE:
      return getJvpClientSurveyFailure(state, action);
    case SUBMIT_CLIENT_SURVEY:
      return submitClientSurvey(state, action);
    case SUBMIT_INCENTIVE_SURVEY:
      return submitIncentiveSurvey(state, action);
    case SUBMIT_RESULT_INCENTIVE_SURVEY:
      return submitResultIncentiveSurvey(state, action);
    case EDIT_POLICYHOLDER:
      return editPolicyholder(state, action);
    case EDIT_SPOUSE:
      return editSpouse(state, action);
    case EDIT_DEPENDENT:
      return editDependent(state, action);
    case REMOVE_SPOUSE:
      return removeSpouse(state);
    case REMOVE_DEPENDENT:
      return removeDependent(state, action);
    case CHANGE_FORM_VALUE:
      return changeFormValue(state, action);
    case CHANGE_SURVEY_VALUE:
      return changeSurveyValue(state, action);
    case CHANGE_SURVEY_VALUE_FROM_RESULT_PAGE:
      return changeSurveyValue(state, action);
    case SEARCH_LOCATIONS_REQUEST:
      return searchLocations(state);
    case SEARCH_LOCATIONS_SUCCESS:
      return searchLocationsSuccess(state, action);
    case SEARCH_LOCATIONS_FAILURE:
      return searchLocationsFailure(state, action);
    case RESET_COUNTY_RESULTS:
      return resetCounties(state);
    case SEARCH_DRUGS_REQUEST:
      return searchDrugs(state, action);
    case SEARCH_DRUGS_SUCCESS:
      return searchDrugsSuccess(state, action);
    case SEARCH_DRUGS_FAILURE:
      return searchDrugsFailure(state, action);
    case SANITIZE_PROFILE_INPUTS:
      return sanitizeProfileInputs(state);
    case SET_SELECTED_MEMBER_IDS:
      return setSelectedMemberIds(state, action);
    case GET_AAPI_USER_SUCCESS:
      return handleGetAAPIUserSuccess(state, action);
    case GET_INCENTIVE_SURVEY_SUCCESS:
      return getIncentiveSurveySuccess(state, action);
    case SET_INCENTIVE_SURVEY:
      return setIncentiveSurvey(state, action);
    case GET_INCENTIVE_SURVEY_FAILURE:
      return getIncentiveSurveyFailure(state, action);
    case CREATE_OR_UPDATE_HOUSEHOLD_REQUEST:
    case UPDATE_HOUSEHOLD_FROM_RESULT_PAGE:
      return createHouseholdRequest(state);
    case SET_ACTIVE_HOUSEHOLE_MEMBERS:
      return setActiveHouseholdMembers(state, action);
    case SET_RESULT_PAGE_INCENTIVE_SURVEY:
      return setResultPageIncentiveSurvey(state, action);
    case RESET_FORM_DIRTY_STATE:
      return resetMembersCovered(state);
    case GET_JVP_ELIGIBILITY_QUESTIONS_SUCCESS:
      return getJvpEligibilityQuestionsSuccess(state, action);
    case GET_JVP_ELIGIBILITY_QUESTIONS_FAILURE:
      return getJvpEligibilityQuestionsFailure(state, action);
    default:
      return state;
  }
}

type ProfileState = TypedMap<IProfilePageReducerState>;

function createOrUpdateHouseholdSuccess(
  state: ProfileState,
  action: {
    response: ItemReference;
    builderCustomerKey?: string;
  },
) {
  localStorage.setItem('household_id', action.response.household_id);

  const builderCustomerKey = action.builderCustomerKey;
  if (builderCustomerKey) {
    return state.set('householdIsLoaded', true).set('household_id', action.response.household_id);
  }
  return state
    .set('householdIsLoaded', true)
    .set('household_id', action.response.household_id)
    .set('incentiveSurveyIsLoading', false);
}

function createHouseholdRequest(state: ProfileState) {
  return state.set('incentiveSurveyIsLoading', true);
}

/**
 *
 * @param member HouseholdMember from Comm API getHousehold
 * @param memberConfig MemberSectionConfigFields needed to validate the household member for form prefill
 * @param drugDetails Object of Drug Details present in the household from Comm API getDrugDetails, for form prefill display
 * @param prevSelectedPlans List of plans user previously selected
 * @param income
 * @param isReturnUser Used for figuring out how to prefill (or not) empty Rx cases
 * @returns
 */
function formatMember(
  member: HouseholdMember,
  memberConfig: MemberSectionConfigFields,
  drugDetails?: GetBatchDrugDetailsResponse | null,
  income?: number | null,
): Member {
  const {
    member_type,
    external_id,
    age,
    gender,
    uses_tobacco,
    utilization,
    planned_procedures,
    prescriptions,
  } = member;

  const { enablePlannedProcedures } = memberConfig;

  const memberPrescriptions: Prescription[] = [];

  /*
    any first time user won't have utilization or planned procedures (if it's enabled) until they completely fill out the usage quiz
    and the frontend successfully calls JVP to update their records, so it's a pretty safe bet to
    initialize the profile builder form usage toggles based on the presence of existing usage data
  */
  const hasSubmittedMedQuiz = enablePlannedProcedures ? utilization && planned_procedures : !!utilization;
  const uses_prescriptions = hasSubmittedMedQuiz ? prescriptions?.length > 0 : null;
  const uses_utilization = hasSubmittedMedQuiz ? Object.values(utilization).some((i) => i > 0) : null;
  const uses_planned_procedures =
    enablePlannedProcedures && planned_procedures
      ? Object.values(planned_procedures).some((i) => i > 0)
      : null;

  // Create expected prescription array from cache of present drugs
  if (drugDetails && uses_prescriptions) {
    member.prescriptions.forEach((prescription) => {
      const ndc = prescription.ndc;

      const rxDetail = ndc in drugDetails ? drugDetails[ndc] : null;
      if (rxDetail) {
        memberPrescriptions.push({
          ndc: rxDetail.ndc,
          displayName: rxDetail.name,
          drugForm: rxDetail.form,
        });
      }
    });
  }

  const formattedMember: Member = {
    external_id,
    member_type: member_type as MEMBER_TYPE,
    age: age?.toString() || '',
    gender: gender || '',
    uses_tobacco,
    uses_prescriptions,
    prescriptions: memberPrescriptions,
    uses_utilization,
    utilization: {
      inpatient_days: utilization?.inpatient_days ?? 0,
      specialist_visits: utilization?.specialist_visits ?? 0,
      pcp_visits: utilization?.pcp_visits ?? 0,
      mental_health_visits: utilization?.mental_health_visits ?? 0,
    },
    uses_planned_procedures,
    planned_procedures: {
      inpatient_procedures: planned_procedures?.inpatient_procedures ?? 0,
      outpatient_procedures: planned_procedures?.outpatient_procedures ?? 0,
      routine_therapy_visits: planned_procedures?.routine_therapy_visits ?? 0,
      mental_health_visits: planned_procedures?.mental_health_visits ?? 0,
    },
    income: income?.toString() || '',
    isSkipIncome: income?.toString() === '0',
    isValid: true,
    pristine: false,
    isCovered: true,
    isComplete: true,
  };
  // Update isValid status for household member if memberConfig is present
  // since otherwise we don't know if
  if (memberConfig) {
    formattedMember.isComplete = isMemberComplete(formattedMember, memberConfig);
    formattedMember.isValid = validateMember(formattedMember, memberConfig);
  }
  return formattedMember;
}

function populateHousehold(
  state: ProfileState,
  action: { response: Household; memberConfig: MemberSectionConfigFields },
) {
  /*
  Populates the reducer with household data and consolidates differences between
  Member objects returned from API and those of the reducer
  */

  const household = action.response;
  const memberConfig = action.memberConfig;

  const householdPolicyholder = household.members.find((member) => member.policyholder);
  const householdSpouse = household.members.find(
    (member: HouseholdMember) => !member.policyholder && !member.dependant,
  );
  const householdDependants = household.members.filter((member: HouseholdMember) => member.dependant);

  const policyholder = householdPolicyholder ? formatMember(householdPolicyholder, memberConfig) : {};

  const spouse = householdSpouse ? formatMember(householdSpouse, memberConfig) : {};

  const dependents =
    householdDependants.length > 0
      ? householdDependants.map((dependent: HouseholdMember) => formatMember(dependent, memberConfig))
      : [];

  return state
    .set('householdIsLoaded', true)
    .set('policyholder', fromJS({ ...state.get('policyholder').toJS(), ...policyholder }))
    .set('spouse', fromJS({ ...state.get('spouse').toJS(), ...spouse }))
    .set('dependents', fromJS([...dependents, newMemberState(MEMBER_TYPE.CHILD)]))
    .set('payPeriodsPerYear', fromJS(household.pay_periods_per_year));
}

function jvpGenderToCommApiGender(gender: JVPSexAtBirth): string | null {
  switch (gender) {
    case 'm':
      return 'male';
    case 'f':
      return 'female';
    default:
      return gender;
  }
}

/**
 * Used for APP-1219: Prefill user profile
 * This code is nearly identical to the prefillIntegratedUserProfile code, but this one does not lock responses
 * and it does not currently fill in Eligibility (which comes in from JVP's bootstrap) as that is not in-scope
 * for APP-1219
 *
 * @param state
 * @param action { response: Household from comm API, validClientSurveyQuestionIds: list of client survey question IDs that are on the current plan year client survey,
 * publicationKey: determines which "plan year"/OE config being accessed. For a given bootstrap request, we'll get active and sometimes upcoming. Once we answer the "enrollment reason" question, we'll firmly place you in one or the other and subsequent requests should have the right versions enrollment event id,
 * jvpUser: JV Platform user. See JVPUser in utils/apiTypes }
 * @returns
 */
function prefillUserProfile(
  state: ProfileState,
  action: {
    household: Household;
    publicationKey: 'active' | 'upcoming';
    validClientSurveyQuestionIds: string[];
    jvpUser: JVPUserWithDependents;
    drugDetails: GetBatchDrugDetailsResponse | null;
    previousPlan: PreviousSelectedPlan[];
    memberConfig: MemberSectionConfigFields;
    isReturnUser: boolean | null;
    jvpEligibilityQuestions: JVPEligibilityQuestions;
  },
) {
  const { client_survey, zip_code, members, survey, income } = action.household;
  const {
    publicationKey,
    validClientSurveyQuestionIds,
    jvpUser,
    drugDetails,
    memberConfig,
    previousPlan,
    jvpEligibilityQuestions,
  } = action;

  // *** Eligibility ***
  /**
   * This block of code resolves an issue where the Household response from Comm API contains
   * client survey responses with JV eligibility question IDs from previous years or configs.
   * We are validating the current EQ/Client Survey configuration state against the responses
   * from the household so we only put the answers for valid responses into Redux.
   * Having invalid EQ/CS Question IDs in the Redux form responses was resulting in 400s from JVP.
   */

  const invalidQuestionIds: string[] = [];

  if (client_survey && validClientSurveyQuestionIds?.length > 0) {
    // Remove invalid question IDs from the client_survey object
    Object.keys(client_survey).forEach((questionId) => {
      if (!validClientSurveyQuestionIds.includes(questionId)) {
        invalidQuestionIds.push(questionId);
        delete client_survey[questionId];
      }
    });

    if (invalidQuestionIds.length > 0)
      Logger.warn(`The following question IDs are invalid for this publication: ${invalidQuestionIds}`);
  }

  const clientSurveyResponses = {
    [publicationKey]: client_survey,
  };

  // Integrated EQs should have a 'question_code' defined
  const integratedEqs = jvpEligibilityQuestions[publicationKey]?.filter((q) => !!q.question_code) || [];

  const hiddenEligibilityQuestions: string[] = determineHiddenEqs(client_survey, integratedEqs);

  // *** Location ***
  const zipCode = zip_code || state.get('zipcode');
  const stateCode = action.household.state || '';

  const hiddenLocationQuestions: string[] = [];

  if (zipCode && integratedEqs.find((q) => q.question_code === STANDARD_EQ_TO_QUESTION_CODE.zipcode)) {
    hiddenLocationQuestions.push('zip5');
  }

  if (stateCode && integratedEqs.find((q) => q.question_code === STANDARD_EQ_TO_QUESTION_CODE.state)) {
    hiddenLocationQuestions.push('state');
  }

  // *** Member ***
  const householdPolicyholder = members.find((member) => member.policyholder);

  if (householdPolicyholder && jvpUser) {
    householdPolicyholder.gender = jvpGenderToCommApiGender(jvpUser.sex_at_birth);
  }

  const householdSpouse = members.find((member) => !member.policyholder && !member.dependant);

  if (householdSpouse && jvpUser?.dependents) {
    const jvpSpouse = jvpUser.dependents.find(
      (dependent) => dependent.relationship === 'spouse' || dependent.relationship === 'partner',
    );
    if (jvpSpouse) {
      householdSpouse.gender = jvpGenderToCommApiGender(jvpSpouse.sex_at_birth);
      if (jvpSpouse.relationship === 'spouse') {
        householdSpouse.member_type = MEMBER_TYPE.SPOUSE;
      }
      // Alex ID return users with a domestic partner will have relationship set but partners sending demographic data will only have marital_status set, so we check for both here for the prefill
      else if (jvpSpouse.relationship === 'partner' || jvpUser.marital_status === 'domestic_partner') {
        householdSpouse.member_type = MEMBER_TYPE.DOMESTIC_PARTNER;
      } else householdSpouse.member_type = MEMBER_TYPE.SPOUSE_OR_DOMESTIC_PARTNER;
    }
  }

  const householdDependants = members.filter((member) => member.dependant);

  const policyholder = householdPolicyholder
    ? formatMember(householdPolicyholder, memberConfig, drugDetails, income)
    : state.get('policyholder').toJS();

  const spouse = householdSpouse
    ? formatMember(householdSpouse, memberConfig, drugDetails, null)
    : state.get('spouse').toJS();

  let dependents =
    householdDependants.length > 0
      ? householdDependants.map((dependent) => {
          const newDependent = dependent;
          if (jvpUser?.dependents) {
            const jvpDependent = jvpUser.dependents.find((i) => i.external_id === dependent.external_id);
            if (jvpDependent) {
              newDependent.gender = jvpGenderToCommApiGender(jvpDependent.sex_at_birth);
            }
          }
          return formatMember(newDependent, memberConfig, drugDetails, null);
        })
      : state.get('dependents').toJS();
  if (!dependents?.some((dep) => dep?.isValid === false && dep?.isComplete === false)) {
    dependents = [...dependents, { ...newMemberState(MEMBER_TYPE.CHILD) }];
  }

  const surveyObj: Record<string, string> = survey.reduce(
    (acc, i) => ({
      [SurveyQuestionMap[i.question_id]]: i.answer || '',
      ...acc,
    }),
    {},
  );

  const hiddenQuestions: {
    eligibility: string[];
    location: string[];
  } = {
    eligibility: hiddenEligibilityQuestions,
    location: hiddenLocationQuestions,
  };

  /**
   * prefer mergeDeep here because we don't want to overwrite some existing data
   * that may exist. For example, if clientSurveyResponses has the _other_
   * publicationKey set, we don't intend to replace it.
   */
  let newState = state.mergeDeep({
    clientSurveyResponses: fromJS(clientSurveyResponses),
    zipcode: zipCode,
    state_code: stateCode,
    policyholder: fromJS(policyholder),
    spouse: fromJS(spouse),
    dependents: fromJS(dependents),
    survey: {
      risk_question_1: surveyObj?.risk_question_1 || '',
      risk_question_2: surveyObj?.risk_question_2 || '',
      capacity_to_pay: surveyObj?.capacity_to_pay || '',
    },
    previousPlan: previousPlan || [],
  });

  newState = newState.set('hiddenQuestions', hiddenQuestions).set('prefillHasCompleted', true);

  return newState;
}

function getClientSurvey(state: ProfileState) {
  return state.set('clientSurveyIsLoading', true);
}

function getClientSurveySuccess(state: ProfileState, action: { response: ClientSurveySchema }) {
  const { response } = action;

  const defaultPublication = 'upcoming';
  const clientSurveyResponses = { [defaultPublication]: {} };

  if (response[defaultPublication].properties) {
    // eslint-disable-next-line no-restricted-syntax
    for (const key of Object.keys(response[defaultPublication].properties)) {
      clientSurveyResponses[defaultPublication][key] = '';
    }
  }
  return state
    .set('clientSurvey', fromJS(response))
    .set('clientSurveyResponses', fromJS(clientSurveyResponses))
    .set('clientSurveyIsLoading', false)
    .set('clientSurveyIsLoaded', true);
}

function getClientSurveyFailure(
  state: ProfileState,
  action: {
    error: Error;
  },
) {
  return state.set('clientSurveyError', action.error).set('clientSurveyIsLoading', false);
}

function getJvpClientSurvey(state: ProfileState) {
  return state.set('clientSurveyIsLoading', true);
}

function getJvpClientSurveySuccess(
  state: ProfileState,
  action: {
    response: Record<'active' | 'upcoming', ClientSurveySchema>;
  },
) {
  const { response } = action;

  // Dynamically create keys in survey responses field
  // The responses get reset every time the action gets called, so the survey should only be retrieve once
  const clientSurveyResponses = {
    active: {},
    upcoming: {},
  };

  if (response.active && response.active.properties) {
    // eslint-disable-next-line no-restricted-syntax
    for (const key of Object.keys(response.active.properties)) {
      // key is jv_eligibility_question_id from bootstrap response state
      clientSurveyResponses.active[key] = '';
    }
  }

  if (response.upcoming && response.upcoming.properties) {
    // eslint-disable-next-line no-restricted-syntax
    for (const key of Object.keys(response.upcoming.properties)) {
      // key is jv_eligibility_question_id from bootstrap response state
      clientSurveyResponses.upcoming[key] = '';
    }
  }

  return state
    .set('clientSurvey', fromJS(response))
    .set('clientSurveyResponses', fromJS(clientSurveyResponses))
    .set('clientSurveyIsLoading', false)
    .set('clientSurveyIsLoaded', true);
}

function getJvpClientSurveyFailure(
  state: ProfileState,
  action: {
    error: Error;
  },
) {
  return state.set('clientSurveyError', action.error).set('clientSurveyIsLoading', false);
}

function submitClientSurvey(
  state: ProfileState,
  action: {
    responses: ClientSurveyResponsesByYear;
  },
) {
  return state.set('clientSurveyResponses', fromJS(action.responses));
}

function getJvpEligibilityQuestionsSuccess(
  state: ProfileState,
  action: {
    response: JVPEligibilityQuestions;
  },
) {
  return state
    .set('jvpEligibilityQuestions', fromJS(action.response))
    .set('jvpEligibilityQuestionsLoading', false);
}

function getJvpEligibilityQuestionsFailure(
  state: ProfileState,
  action: {
    error: Error;
  },
) {
  return state
    .set('jvpEligibilityQuestionsError', fromJS(action.error))
    .set('jvpEligibilityQuestionsLoading', false);
}

function submitIncentiveSurvey(
  state: ProfileState,
  action: {
    responses: Record<string, IncentiveSurveyAnswer | null>;
  },
) {
  return state.set('incentiveSurveyAnswers', fromJS(action.responses));
}

function submitResultIncentiveSurvey(
  state: ProfileState,
  action: {
    responses: Record<string, IncentiveSurveyAnswer | null>;
  },
) {
  const resultPageIncentiveSurvey = {
    ...state.get('resultPageIncentiveSurvey').toJS(),
    incentiveSurveyAnswers: action.responses,
  };
  return state.set('resultPageIncentiveSurvey', resultPageIncentiveSurvey);
}

function editPolicyholder(
  state: ProfileState,
  action: {
    fields: UpdateMemberFields;
    memberConfig?: MemberSectionConfigFields;
  },
) {
  const policyholder = state.get('policyholder').toJS();

  // Update each key in policyholder
  const newPolicyholder = {
    ...policyholder,
    ...action.fields,
  };

  newPolicyholder.isValid = validateMember(newPolicyholder, action.memberConfig);
  if (newPolicyholder.isValid) {
    newPolicyholder.isComplete = true;
  }
  return state.set('policyholder', fromJS(newPolicyholder));
}

function editSpouse(
  state: ProfileState,
  action: {
    fields: UpdateMemberFields;
    memberConfig: MemberSectionConfigFields;
  },
) {
  // Update each key in spouse
  const spouse = {
    ...state.get('spouse').toJS(),
    ...action.fields,
  };

  spouse.isValid = validateMember(spouse, action.memberConfig);
  if (spouse.isValid) {
    spouse.isComplete = true;
  }
  return state.set('spouse', fromJS(spouse));
}

function removeSpouse(state: ProfileState) {
  // Keep original UUID
  const externalId = state.getIn(['spouse', 'external_id']);
  const spouse = {
    ...newMemberStateWithExistingExternalId(MEMBER_TYPE.SPOUSE_OR_DOMESTIC_PARTNER, externalId),
  };

  return state.set('spouse', fromJS(spouse));
}

function editDependent(
  state: ProfileState,
  action: {
    memberId: string;
    fields: UpdateMemberFields;
    memberConfig: MemberSectionConfigFields;
  },
) {
  const dependents = state.get('dependents').toJS();
  const index = dependents.findIndex((i) => i.external_id === action.memberId);
  dependents[index] = {
    ...dependents[index],
    ...action.fields,
  };

  dependents[index].isValid = validateMember(dependents[index], action.memberConfig);
  if (dependents[index].isValid) {
    dependents[index].isComplete = true;
  }

  // Push an new dependent onto list in order to render an additional Add Dependent button
  if (index + 1 === dependents.length) {
    dependents.push(newMemberState(MEMBER_TYPE.CHILD));
  }

  return state.set('dependents', fromJS(dependents));
}

function removeDependent(
  state: ProfileState,
  action: {
    memberId: string;
  },
) {
  const dependents = state.get('dependents').toJS();

  const updatedDependents = dependents.filter((i) => i.external_id !== action.memberId);

  return state.set('dependents', fromJS(updatedDependents));
}

function changeFormValue(
  state: ProfileState,
  action: {
    name: keyof IProfilePageReducerState;
    value: string;
  },
) {
  return state.set(action.name, action.value);
}

function changeSurveyValue(
  state: ProfileState,
  action: {
    name: keyof Survey;
    value: RiskAssessmentAnswer | CapacityToPayAnswer | 'yes' | 'no' | '';
  },
) {
  return state.setIn(['survey', action.name], action.value);
}

function searchLocations(state: ProfileState) {
  return state.set('searchIsLoading', true).set('countyResults', fromJS([]));
}

function searchLocationsSuccess(
  state: ProfileState,
  action: {
    response: GetLocationsResponse;
  },
) {
  let stateCode = state.get('state_code');
  const res = action.response.locations;

  if (res.length >= 1) {
    stateCode = res[0].state as string;
  }

  if (res.length === 0) {
    stateCode = '';
  }

  return state.set('searchIsLoading', false).set('countyResults', fromJS(res)).set('state_code', stateCode);
}

function searchLocationsFailure(
  state: ProfileState,
  action: {
    error: Error;
  },
) {
  return state.set('searchIsLoading', false).set('searchError', action.error);
}

function resetCounties(state: ProfileState) {
  return state.set('countyResults', fromJS([]));
}

function searchDrugs(
  state: ProfileState,
  action: {
    query: string;
  },
) {
  return state.set('rxQuery', action.query).set('rxIsLoading', true);
}

function searchDrugsSuccess(
  state: ProfileState,
  action: {
    response: GetDrugsResponse;
  },
) {
  return state.set('rxResults', fromJS(action.response.drugs)).set('rxIsLoading', false);
}

function searchDrugsFailure(
  state: ProfileState,
  action: {
    error: Error;
  },
) {
  return state.set('rxError', fromJS(action.error)).set('rxIsLoading', false);
}

// clear prescription/utilization if answer is 'no'
function sanitizeProfileInputs(state: ProfileState) {
  let policyholder = state.get('policyholder').toJS();
  let spouse = state.get('spouse').toJS();
  let dependents = state.get('dependents').toJS();

  let planChildQuestion = state.getIn(['survey', 'plan_child_question']);

  const canBearChild =
    dependents
      .concat([policyholder, spouse])
      .filter(
        (member) =>
          (member.gender === 'female' || member.gender === 'prefer_not_to_answer') && member.age >= 12,
      ).length > 0;

  if (!canBearChild) {
    planChildQuestion = '';
  }

  const emptyUtilization = {
    pcp_visits: 0,
    specialist_visits: 0,
    inpatient_days: 0,
    mental_health_visits: 0,
  };

  // clear policyholder prescription/utilization if user answered 'no'
  if (!policyholder.uses_prescriptions) {
    policyholder = {
      ...policyholder,
      prescriptions: [],
    };
  }
  if (!policyholder.uses_utilization) {
    policyholder = {
      ...policyholder,
      utilization: emptyUtilization,
    };
  }

  // clear spouse prescription/utilization if user answered 'no'
  if (spouse.isValid) {
    if (!spouse.uses_prescriptions) {
      spouse = {
        ...spouse,
        prescriptions: [],
      };
    }
    if (!spouse.uses_utilization) {
      spouse = {
        ...spouse,
        utilization: emptyUtilization,
      };
    }
  }

  // clear dependent prescription/utilization if user answered 'no'
  dependents = dependents.map((dependent) => {
    let sanitized = { ...dependent };
    if (dependent.isValid) {
      if (!dependent.uses_prescriptions) {
        sanitized = {
          ...sanitized,
          prescriptions: [],
        };
      }
      if (!dependent.uses_utilization) {
        sanitized = {
          ...sanitized,
          utilization: emptyUtilization,
        };
      }
    }
    return sanitized;
  });

  return state
    .set('policyholder', fromJS(policyholder))
    .set('spouse', fromJS(spouse))
    .set('dependents', fromJS(dependents))
    .setIn(['survey', 'plan_child_question'], fromJS(planChildQuestion));
}

function setSelectedMemberIds(
  state,
  action: {
    memberIds: string[];
  },
) {
  let newState = state;

  const spouseExternalId = state.getIn(['spouse', 'external_id']);
  const spouseIsCovered = action.memberIds.includes(spouseExternalId);
  newState = newState.setIn(['spouse', 'isCovered'], spouseIsCovered);

  const dependents = state.get('dependents');
  dependents.forEach((dependent, idx) => {
    if (dependent.get('isValid')) {
      const externalId = dependent.get('external_id');
      const isCovered = action.memberIds.includes(externalId);
      newState = newState.setIn(['dependents', idx, 'isCovered'], isCovered);
    }
  });

  return newState;
}

function handleGetAAPIUserSuccess(state, action: { user: AlexIdUser }) {
  return state
    .setIn(['policyholder', 'external_id'], action.user.alex_id_uuid)
    .set('customerProductKey', action.user.customer_product_key);
}

function setIncentiveSurvey(state, action) {
  return state
    .set('incentiveSurvey', action.data.incentiveSurvey)
    .set('incentiveSurveyAnswers', action.data.incentiveSurveyAnswers)
    .set('resultPageIncentiveSurvey', { incentiveSurvey: [], incentiveSurveyAnswers: {} });
}

function getIncentiveSurveySuccess(
  state,
  action: {
    response: IncentiveSurveyResponse;
  },
) {
  const surveyQuestions = action.response.incentive_questions;

  const surveyAnswers = state.get('incentiveSurveyAnswers').toJS();

  // persist answers if they still appear in the new response (i.e., form changes didn't make the user ineligible for the incentive)
  const emptySurveyAnswers = surveyQuestions.reduce(
    (acc, i) => ({
      ...acc,
      [i.id]: surveyAnswers?.[i.id] || null,
    }),
    {},
  );

  return state
    .set('incentiveSurvey', surveyQuestions)
    .set('incentiveSurveyAnswers', emptySurveyAnswers)
    .set('incentiveSurveyIsLoading', false)
    .set('incentiveSurveyAPICalled', true);
}

function getIncentiveSurveyFailure(state, action) {
  return state.set('incentiveSurveyError', action.error).set('incentiveSurveyIsLoading', false);
}

function setActiveHouseholdMembers(state, action) {
  return state.set('activeMembers', action.members);
}

function setResultPageIncentiveSurvey(
  state,
  action: {
    response: IncentiveSurveyResponse;
  },
) {
  const surveyQuestions = action.response.incentive_questions;

  const surveyAnswers = state.get('incentiveSurveyAnswers').toJS();

  // persist answers if they still appear in the new response (i.e., form changes didn't make the user ineligible for the incentive)
  const emptySurveyAnswers = surveyQuestions.reduce(
    (acc, i) => ({
      ...acc,
      [i.id]: surveyAnswers?.[i.id] || null,
    }),
    {},
  );

  const data = {
    incentiveSurvey: surveyQuestions,
    incentiveSurveyAnswers: emptySurveyAnswers,
  };

  return state.set('resultPageIncentiveSurvey', data).set('incentiveSurveyIsLoading', false);
}

function resetMembersCovered(state) {
  const spouse = state.get('spouse').toJS();
  let dependents = state.get('dependents').toJS();
  dependents = dependents.map((dependent) => ({ ...dependent, isCovered: true }));
  spouse.isCovered = true;
  return state.set('spouse', spouse).set('dependents', dependents);
}

const pojoProfilePageReducer = wrapImmutableReducerInPojoTranslation(profilePageReducer);

export default pojoProfilePageReducer;
