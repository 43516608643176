import React from 'react';

import { usePremiumContext } from 'Contexts/premiumContext';
import { useTextContext } from 'Contexts/textContext';
import { Dropdown } from 'DesignLibrary/molecules/Dropdown';

interface PayPeriodSelectProps {
  dir?: 'left' | 'right';
}

interface PayPeriodOptionsWithAbbreviation {
  display: string;
  abbreviation: string;
}

const PayPeriodSelect = ({ dir = 'right' }: PayPeriodSelectProps) => {
  const { payPeriod, setPayPeriod } = usePremiumContext();
  const { retrieveContentfulData } = useTextContext();

  const handlePayPeriodChange = (e) => {
    const value = e.target.value;
    setPayPeriod(value);
  };

  const payPeriodOptions = retrieveContentfulData<PayPeriodOptionsWithAbbreviation[]>(
    'top_level_navigation.pay_period_options',
    [],
  );

  const dropdownOptions = Object.keys(payPeriodOptions).map((key) => ({
    display: payPeriodOptions[key].display,
    abbreviation: payPeriodOptions[key].abbreviation,
    value: key,
  }));

  const dropdownLabel = retrieveContentfulData<string>('top_level_navigation.pay_period_label');

  return (
    <Dropdown
      label={dropdownLabel}
      keyHint="pay-period"
      options={dropdownOptions}
      selectedValue={payPeriod}
      groupName="pay period"
      isOpen={false}
      handleChange={handlePayPeriodChange}
      horizontalPosition={dir}
      buttonProps={{
        buttonType: 'secondary',
        iconLeft: 'CurrencyCircleDollar',
        iconProps: {
          color: '--primary-blue',
          size: 20,
        },
        isDisabled: false,
      }}
    />
  );
};

export default PayPeriodSelect;
