import styled, { css } from 'styled-components';

import { breakpoint } from 'DesignLibrary/global';

type TogglePseudoProps = {
  selected: boolean;
};

export const ToggleLabel = styled.label`
  cursor: pointer;
  flex: 1;
  flex-shrink: 0;
`;

export const TogglePseudo = styled.div<TogglePseudoProps>`
  border: 2px solid var(--alex-border-gray);
  border-radius: var(--border-radius-xs);
  transition: 0.15s border-color;
  overflow: hidden;
  height: 100%;

  .product-image {
    position: relative;
    display: flex;
    justify-content: center;
    padding: var(--sizing-xs);
    background: var(--colors-blue-50);

    img {
      user-select: none;
      pointer-events: none;
      height: 80px;

      ${breakpoint.TABLET} {
        height: 100px;
      }
    }

    .pseudo-radio {
      position: absolute;
      top: var(--sizing-sm);
      right: var(--sizing-sm);
      height: var(--sizing-lg);
      width: var(--sizing-lg);
      border: 2px solid var(--alex-border-gray);
      border-radius: 4rem;
      background: var(--primary-white);
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.15s border-color;

      &:after {
        content: '';
        display: block;
        height: var(--sizing-sm);
        width: var(--sizing-sm);
        background: var(--primary-white);
        transform: scale(0.3);
        border-radius: inherit;
        transition:
          0.15s background-color,
          0.15s transform;
      }

      ${({ selected }) =>
        selected &&
        css`
          border-color: var(--primary-blue);

          &:after {
            background-color: var(--primary-blue);
            transform: scale(1);
          }
        `}
    }

    ${breakpoint.TABLET} {
      padding: var(--sizing-md);
    }
  }

  .bullets {
    padding: var(--sizing-sm);

    & > * + * {
      margin-top: var(--sizing-sm);
    }

    ${breakpoint.TABLET} {
      padding: var(--sizing-md);
    }
  }

  &:hover {
    border-color: var(--colors-alex-ink80);

    .pseudo-radio {
      border-color: var(--colors-alex-ink80);
    }
  }

  ${({ selected }) =>
    selected &&
    css`
      border-color: var(--primary-blue);
      cursor: default;

      &:hover {
        border-color: var(--primary-blue);

        .pseudo-radio {
          border-color: var(--primary-blue);
        }
      }
    `}
`;
