import { fromJS } from 'immutable';
import _, { get } from 'lodash';
import { createSelector } from 'reselect';

import { GlobalReducerState } from 'app/reducers';
import { makeSelectCommercialField, makeSelectConfigField } from 'Containers/App/selectors';
import { Election } from 'Containers/CommercialRoutes/types';
import { makeSelectSurvey, makeGetTierCodeData, makeGetCoverageTier } from 'Containers/ProfilePage/selectors';
import { memberIdsListToMemberIdsKey } from 'Containers/ViewBenefitPage/utils';
import Plan from 'Models/plan';
import {
  FieldDataTypes,
  ReasonsWhy,
  Recommendation,
  SupplementalRecommendations,
  USDRangeData,
} from 'Types/entities';

import { SUPPLEMENTAL_ELECTIONS } from './constants';
import { OverviewPageReducerState } from './reducer';

const selectOverviewPageDomain = (state: GlobalReducerState) => fromJS(state).get('overviewPage');

const selectOverviewDomainAsJS = (state: GlobalReducerState): OverviewPageReducerState => state.overviewPage;

const makeSelectOverviewField = <K extends keyof OverviewPageReducerState>(field: K) =>
  createSelector(
    selectOverviewDomainAsJS,
    (substate: OverviewPageReducerState): OverviewPageReducerState[K] => substate[field],
  );

const makeGetSelectedPlansCount = () =>
  createSelector(
    [makeGetIsHealthPlanSelected(), makeSelectOverviewField('selected_plans')],
    (isHealthPlanSelected, selectedSupplementalPlans) =>
      Object.values(selectedSupplementalPlans).filter((x) => x !== null).length +
      (isHealthPlanSelected ? 1 : 0),
  );

const makeSelectSupplementalPlansForMemberIdsFromCache = (memberIds: string[]) =>
  createSelector(makeSelectOverviewField('supplemental_plans_household_subsets_cache'), (cache) => {
    const memberIdsKey = memberIdsListToMemberIdsKey(memberIds);
    const membersExist = Object.keys(cache).indexOf(memberIdsKey) >= 0;

    if (membersExist) {
      return cache[memberIdsKey];
    }
    return null;
  });

const getRiskAversion = () =>
  createSelector([makeSelectSurvey()], ({ risk_question_1, risk_question_2 }) => {
    if (risk_question_1 === 'plan_a') {
      return 'high';
    }
    if (risk_question_1 === 'plan_b' && risk_question_2 === 'plan_b') {
      return 'low';
    }
    return 'medium';
  });

const makeGetSelectedHealthDeductible = () =>
  createSelector(
    [makeSelectCommercialField('selectedHealthPlan'), makeGetCoverageTier()],
    (healthPlan, coverageTier) => {
      if (!_.isEmpty(healthPlan)) {
        return new Plan(healthPlan as Recommendation).getInNetworkDeductible(coverageTier);
      }
      return 0;
    },
  );

const makeGetSupplementalPlanRecommendations = () =>
  createSelector(
    [
      makeSelectOverviewField('supplemental_plans_full_household'),
      makeSelectConfigField('enable_fbs_recommendations'),
      makeSelectConfigField('enable_fbs_best_match'),
      makeSelectConfigField('enable_fbs_recommendation_types'),
    ],
    (
      supplementalPlans,
      enableFbsRecommendations,
      enableFbsBestMatch,
      enableFbsRecommendationTypes,
    ): SupplementalRecommendations => {
      let bestScore = -1;
      let bestMatch = '';
      let recommendedCount = 0;
      const recommendations: SupplementalRecommendations = {};

      Object.keys(supplementalPlans).forEach((benefitType) => {
        const benefitRecEnabled =
          benefitType in enableFbsRecommendationTypes && enableFbsRecommendationTypes[benefitType];

        // FBS Recs can be disabled wholesale or per-benefit-type
        if (!enableFbsRecommendations || !benefitRecEnabled) {
          return;
        }

        const plans = supplementalPlans[benefitType].plans;
        const firstPlan = plans[0];

        if (firstPlan.score_type === 'always_recommend') {
          recommendations[benefitType] = {
            score: firstPlan.score,
            score_type: firstPlan.score_type,
            label: 'recommended',
          };
        } else {
          const highestScore = plans
            .map((plan) => plan.score || -1)
            .sort()
            .reverse()[0];

          if (highestScore > 0) {
            recommendations[benefitType] = {
              score: highestScore,
              score_type: firstPlan.score_type,
              label: 'recommended',
            };

            recommendedCount += 1;

            if (highestScore > bestScore) {
              bestScore = highestScore;
              bestMatch = benefitType;
            }
          } else if (firstPlan.score_type) {
            // Only include "scored" plan types
            recommendations[benefitType] = {
              score: 0,
              score_type: firstPlan.score_type,
              label: 'not_recommended',
            };
          }
        }
      });

      // We only want 'best match' if:
      // - multiple benefits are recommended
      // - best score is > 1
      // - best match config flag is enabled
      if (recommendedCount > 1 && bestScore > 1 && enableFbsBestMatch) {
        recommendations[bestMatch].label = 'best';
      }

      return recommendations;
    },
  );

const makeGetSupplementalPlanReasonsWhy = () =>
  createSelector(
    [makeSelectOverviewField('supplemental_plans_full_household')],
    (supplementalPlans): ReasonsWhy => {
      const reasons = {};

      Object.keys(supplementalPlans).forEach((benefitType) => {
        const plans = supplementalPlans[benefitType].plans;

        // NOTE: Only look for reasons for the first plan of this type, they should be the same if there are multiple
        reasons[benefitType] = plans[0].reasons?.map((plan) => plan.code) || [];
      });

      return reasons;
    },
  );

const makeGetSupplementalPremiums = () =>
  createSelector(makeSelectOverviewField('selected_plans'), (selectedPlans): USDRangeData => {
    const formatted: USDRangeData = {
      type: FieldDataTypes.USD,
      lower: 0,
      upper: 0,
    };

    Object.keys(selectedPlans).forEach((key) => {
      const value = selectedPlans[key];
      if (value) {
        const monthlyPremium = value.monthly_premium;
        if (monthlyPremium !== null && !_.isEmpty(value.monthly_premium)) {
          if (monthlyPremium.type === FieldDataTypes.USD) {
            formatted.lower += monthlyPremium.data;
            formatted.upper += monthlyPremium.data;
          }
        }
      }
    });

    return formatted;
  });

const makeGetIsHealthPlanSelected = () =>
  createSelector(makeSelectCommercialField('selectedHealthPlan'), (healthPlan) => {
    if (!_.isEmpty(healthPlan)) return true;

    return false;
  });

const makeGetHsaPlan = () =>
  createSelector(makeSelectOverviewField('supplemental_plans_full_household'), (supplementalPlans) => {
    // eslint-disable-next-line no-prototype-builtins
    if (supplementalPlans.hasOwnProperty('hsa')) {
      return supplementalPlans.hsa?.plans[0] || null;
    }

    return null;
  });

const makeGetHospitalPlan = () =>
  createSelector(makeSelectOverviewField('supplemental_plans_full_household'), (supplementalPlans) => {
    // eslint-disable-next-line no-prototype-builtins
    if (supplementalPlans.hasOwnProperty('hospital')) {
      return supplementalPlans.hospital?.plans[0] || null;
    }

    return null;
  });

const makeGetHraPlan = () =>
  createSelector(makeSelectOverviewField('supplemental_plans_full_household'), (supplementalPlans) => {
    // eslint-disable-next-line no-prototype-builtins
    if (supplementalPlans.hasOwnProperty('hra')) {
      return supplementalPlans.hra?.plans[0] || null;
    }

    return null;
  });

const makeGetIsFsaEligible = () =>
  createSelector(
    makeSelectOverviewField('supplemental_plans_full_household'),
    (supplementalPlans) =>
      supplementalPlans?.fsa_medical?.plans?.length > 0 ||
      supplementalPlans?.fsa_dependent?.plans?.length > 0 ||
      supplementalPlans?.fsa_adoption?.plans?.length > 0,
  );

const makeGetShowTaxSavings = () =>
  createSelector(
    [
      makeSelectConfigField('show_tax_savings_page'),
      makeSelectCommercialField('selectedHealthPlan'),
      makeGetIsFsaEligible(),
    ],
    (taxSavingsEnabled, selectedPlan, isFsaEligible) =>
      taxSavingsEnabled && (get(selectedPlan, 'plan.hsa_eligible', false) || isFsaEligible),
  );

const makeGetSupplementalPlanElections = (isReviewPageLoaded: boolean) =>
  createSelector(
    [
      makeSelectOverviewField('selected_plans'),
      makeGetTierCodeData(),
      makeSelectOverviewField('supplemental_plans_full_household'),
    ],
    (selectedPlans, tierCodeData, supplementalPlans): Election[] => {
      const elections: Election[] = [];
      const [tierCode, tierCodeDescription] = tierCodeData;
      Object.keys(SUPPLEMENTAL_ELECTIONS).forEach((type) => {
        if (selectedPlans[type]) {
          elections.push({
            planable_type: SUPPLEMENTAL_ELECTIONS[type],
            jv_plan_id: selectedPlans[type]?.external_id,
            tier_code: tierCode,
            tier_code_description: tierCodeDescription,
          });
        } else if (isReviewPageLoaded) {
          // Waive coverage on plan types for which the user is eligible
          if (type in supplementalPlans && supplementalPlans[type]?.plans?.length > 0) {
            elections.push({
              planable_type: SUPPLEMENTAL_ELECTIONS[type],
              waived: true,
            });
          }
        }
      });
      return elections;
    },
  );

export {
  selectOverviewPageDomain,
  selectOverviewDomainAsJS,
  makeSelectOverviewField,
  makeSelectSupplementalPlansForMemberIdsFromCache,
  makeGetSelectedPlansCount,
  makeGetSupplementalPremiums,
  makeGetSelectedHealthDeductible,
  makeGetSupplementalPlanRecommendations,
  makeGetSupplementalPlanReasonsWhy,
  makeGetIsHealthPlanSelected,
  makeGetHsaPlan,
  makeGetHraPlan,
  makeGetHospitalPlan,
  makeGetShowTaxSavings,
  makeGetIsFsaEligible,
  getRiskAversion,
  makeGetSupplementalPlanElections,
};
