import hoistNonReactStatics from 'hoist-non-react-statics';
import React from 'react';
import { ReactReduxContext } from 'react-redux';

import getInjectors from './sagaInjectors';

// type signature inferred from ProfilePage/index.tsx
interface InjectSagaProps {
  key: string;
  saga: () => Generator;
  mode?: string;
}

/**
 * Dynamically injects a saga, passes component's props as saga arguments
 *
 * @param {string} key A key of the saga
 * @param {function} saga A root saga that will be injected
 * @param {string} [mode] By default (constants.DAEMON) the saga will be started
 * on component mount and never canceled or started again. Another two options:
 *   - constants.RESTART_ON_REMOUNT — the saga will be started on component mount and
 *   cancelled with `task.cancel()` on component unmount for improved peßrformance,
 *   - constants.ONCE_TILL_UNMOUNT — behaves like 'RESTART_ON_REMOUNT' but never runs it again.
 */
export default <TProps extends React.Component | Record<string, never>>({
    key,
    saga,
    mode,
  }: InjectSagaProps) =>
  (WrappedComponent: React.ComponentType<TProps>): React.ComponentType<TProps> => {
    class InjectSaga extends React.Component<TProps> {
      /* eslint-disable react/static-property-placement */
      static WrappedComponent = WrappedComponent;

      static contextType = ReactReduxContext;

      static displayName = `withSaga(${
        WrappedComponent.displayName || WrappedComponent.name || 'Component'
      })`;
      /* eslint-enable react/static-property-placement */

      private injectors: {
        injectSaga: (
          key: string,
          descriptors: { saga: () => Generator; mode?: string },
          args: unknown,
        ) => Generator;
        ejectSaga: (key: string) => Generator;
      };

      constructor(props, context) {
        super(props, context);

        this.injectors = getInjectors(context.store);

        this.injectors.injectSaga(
          key,
          {
            saga,
            mode,
          },
          this.props,
        );
      }

      componentWillUnmount() {
        this.injectors.ejectSaga(key);
      }

      render() {
        return <WrappedComponent {...this.props} />;
      }
    }

    return hoistNonReactStatics(InjectSaga, WrappedComponent);
  };
