import { Box, Flex } from '@chakra-ui/react';
import React from 'react';

import { useProviderSelectionContext } from 'Contexts/providerSelectionContext';
import { Button, H6, Icon } from 'DesignLibrary/atoms';
import Text from 'Shared/Text';
import { sendInsightsEvent } from 'Utils/insights';

const ProviderTag = ({ name, testId }: { name: string; testId: string }) => (
  <Flex
    align="center"
    gap={1}
    bg="var(--colors-green-100)"
    px={2}
    py={1}
    borderRadius="var(--border-radius-xs)"
    whiteSpace="nowrap"
  >
    <Icon color="--primary-green" size="small" weight="fill" type="CheckCircle" />
    <Box fontSize={12} fontWeight={700} data-testid={testId}>
      {name}
    </Box>
  </Flex>
);

const NoProviderTag = () => (
  <Flex bg="blackAlpha.50" px={2} py={1} borderRadius="var(--border-radius-xs)">
    <Box fontSize={12} fontWeight={700} color="var(--text-gray)" data-testid="in-network-provider-none">
      <Text field="health_section.recommendation.in_network_providers_none" />
    </Box>
  </Flex>
);

export const InNetworkProviders = ({ plan }) => {
  const { filteredProviders, onOpenProviderModal, providerDetails } = useProviderSelectionContext();

  const inNetworkProviderList = filteredProviders[plan.planId]
    ? filteredProviders[plan.planId]?.inNetwork
    : [];

  const renderInNetworkProviders =
    inNetworkProviderList?.length > 0 ? (
      <Flex gap={1} flexWrap={['wrap', 'nowrap']} overflowY="auto" justify={['flex-end', 'flex-start']}>
        {inNetworkProviderList.map((provider, index) => (
          <ProviderTag
            name={`${provider?.first_name} ${provider?.middle_name ? `${provider.middle_name} ` : ''}${provider?.last_name}`}
            key={provider.npi}
            testId={`in-network-provider-${index}`}
          />
        ))}
      </Flex>
    ) : (
      <NoProviderTag />
    );

  return (
    <Flex
      justify={['space-between', 'flex-start']}
      align={['flex-start', 'center']}
      gap={3}
      border="var(--border-thick)"
      borderTop="0"
      px={4}
      pr={[4, 0]}
      py={[3, 0]}
      borderBottomRadius={6}
      h={['auto', 10]}
      overflow="hidden"
    >
      <Box flexShrink={0}>
        <H6 color="--text-gray" data-testid="in-network-provider-heading">
          <Text field="health_section.recommendation.in_network_providers_heading" />
        </H6>
      </Box>

      {providerDetails.length > 0 ? (
        renderInNetworkProviders
      ) : (
        <Box sx={{ button: { borderStyle: 'dashed', borderWidth: '1px', fontSize: '14px' } }}>
          <Button
            iconLeft="Plus"
            iconProps={{
              size: 'small',
            }}
            size="xsmall"
            data-testid="provider-selection-button"
            onClick={() => {
              onOpenProviderModal();
              sendInsightsEvent(null, 'open_provider_search_modal');
            }}
          >
            <Text field="health_section.recommendation.in_network_providers_add_button" />
          </Button>
        </Box>
      )}
    </Flex>
  );
};
