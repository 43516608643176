import { hsaContributionExplainer } from 'ContentfulDefaults/defaults/english/hsaContributionExplainer';
import { hsaInteractiveSection } from 'ContentfulDefaults/defaults/english/hsaInteractiveSection';
import { ContentfulHsaSection } from 'ContentfulDefaults/types/hsa';

export const hsa: {
  hsa: ContentfulHsaSection;
} = {
  hsa: {
    contentful_name: 'HSA+ (DEFAULT DO NOT CHANGE)',
    button_text: {
      save: 'Save Contribution and Continue',
      review: 'Continue to Review',
      expensesPDF: 'All Eligible Expenses (PDF)',
      supplemental: 'Continue to Supplemental',
      balanceSubmit: 'Submit',
      back: 'View Health Plans',
    },
    info_bar: "Great, you've selected an HSA-eligible health plan!",
    page_header: {
      text: 'HSA Contributions',
      subtext: 'Use our contribution tool to understand how much you should contribute.',
    },
    contribution_explainer: hsaContributionExplainer,
    header: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Everything you need to know about your',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Health Savings Account',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-1',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    section_cards: {
      faq: {
        tags: ['How Do I...', 'What if...', 'HSA versus'],
        heading: 'Frequently Asked Questions',
        subheading: 'Some other tidbits about your HSA.',
      },
      about: {
        tags: ['The Basics', 'Covered Expenses'],
        heading: 'Understand Your Benefits',
        subheading: 'How an HSA can help you and your family.',
      },
      longterm: {
        tags: ['HSA Investing', 'Triple-Tax Advantage'],
        heading: 'Prepare for the future',
        subheading: 'Learn about your HSA over the long term.',
      },
      retirement: {
        tags: ['HSA Investing', 'Retirement Goals'],
        heading: 'How can I use my HSA to save for retirement?',
        subheading: 'Learn how saving more in your HSA can help you prepare for retirement.',
      },
      contributions: {
        tags: ['Tax Savings', 'Employer Contribution'],
        heading: 'Your HSA Contributions',
        subheading: {
          self: 'Protect yourself from Out-of-Pocket expenses.',
          family: 'Protect your family from Out-of-Pocket expenses.',
        },
      },
    },
    open_hsa_question: {
      answerNo: "No, I don't have an HSA.",
      question: 'Do you currently have a Health Savings Account?',
      answerYes: 'Yup, I have an HSA!',
      noDescription:
        'No worries, HSAs aren’t as scary as they may seem. On this page we’ll go into why HSAs are useful, how to set up your account, and how much you should contribute.',
      yesDescription:
        'That’s great to hear! But let’s make sure you are contributing enough to your account.  We show you this info down below in the Your Contributions section.',
    },
    about_bubbles: {
      text: [
        'Save pre-tax dollars in your account',
        'Invest and grow these funds',
        'Pay for qualified health care expenses',
      ],
      icons: [
        'https://picwellapppublicdata.s3.amazonaws.com/contentful/hsaimages/piggy-bank.svg',
        'https://picwellapppublicdata.s3.amazonaws.com/contentful/hsaimages/chart.svg',
        'https://picwellapppublicdata.s3.amazonaws.com/contentful/hsaimages/about-heart.svg',
      ],
      title: 'Your Health Savings Acount allows you to...',
    },
    about_video: {
      link: 'https://vimeo.com/408084854',
      intro: "Here's a quick video to explain:",
    },
    unused_funds: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Do I lose any unused funds?',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-4',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'No! Money in your HSA is ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'bold',
                },
              ],
              value: 'always yours to keep',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value: ', even if you switch employers.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    triple_tax: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'What makes an HSA so powerful?',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-4',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'HSA is a ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'bold',
                },
              ],
              value: '"triple-tax advantaged"',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value:
                ' account, which means you never pay taxes on your HSA contributions, earnings or withdrawals on qualified expenses.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    catchup_info: {
      nodeType: 'document',
      data: {},
      content: [
        {
          nodeType: 'paragraph',
          data: {},
          content: [
            {
              nodeType: 'text',
              value: 'Catch up contributions at 55: ',
              marks: [
                {
                  type: 'bold',
                },
              ],
              data: {},
            },
            {
              nodeType: 'text',
              value:
                "Since you are at least 55, this applies to you.  You're able to contribute an additional $1,000 to your HSA!  This allows you to quickly accumulate additional funds for retirement.",
              marks: [],
              data: {},
            },
          ],
        },
      ],
    },
    hsa_expenses_title: 'What expenses can be paid with HSA funds?',
    hsa_expenses: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Medical',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-6',
        },
        {
          data: {},
          content: [
            {
              data: {},
              content: [
                {
                  data: {},
                  content: [
                    {
                      data: {},
                      marks: [],
                      value: 'Deductibles',
                      nodeType: 'text',
                    },
                  ],
                  nodeType: 'paragraph',
                },
              ],
              nodeType: 'list-item',
            },
            {
              data: {},
              content: [
                {
                  data: {},
                  content: [
                    {
                      data: {},
                      marks: [],
                      value: 'Prescription Drugs',
                      nodeType: 'text',
                    },
                  ],
                  nodeType: 'paragraph',
                },
              ],
              nodeType: 'list-item',
            },
            {
              data: {},
              content: [
                {
                  data: {},
                  content: [
                    {
                      data: {},
                      marks: [],
                      value: 'Over-the-counter Products',
                      nodeType: 'text',
                    },
                  ],
                  nodeType: 'paragraph',
                },
              ],
              nodeType: 'list-item',
            },
          ],
          nodeType: 'unordered-list',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Dental',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-6',
        },
        {
          data: {},
          content: [
            {
              data: {},
              content: [
                {
                  data: {},
                  content: [
                    {
                      data: {},
                      marks: [],
                      value: 'Cleanings',
                      nodeType: 'text',
                    },
                  ],
                  nodeType: 'paragraph',
                },
              ],
              nodeType: 'list-item',
            },
            {
              data: {},
              content: [
                {
                  data: {},
                  content: [
                    {
                      data: {},
                      marks: [],
                      value: 'X-Rays',
                      nodeType: 'text',
                    },
                  ],
                  nodeType: 'paragraph',
                },
              ],
              nodeType: 'list-item',
            },
            {
              data: {},
              content: [
                {
                  data: {},
                  content: [
                    {
                      data: {},
                      marks: [],
                      value: 'Orthodontia',
                      nodeType: 'text',
                    },
                  ],
                  nodeType: 'paragraph',
                },
              ],
              nodeType: 'list-item',
            },
          ],
          nodeType: 'unordered-list',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Vision',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-6',
        },
        {
          data: {},
          content: [
            {
              data: {},
              content: [
                {
                  data: {},
                  content: [
                    {
                      data: {},
                      marks: [],
                      value: 'Annual Exams',
                      nodeType: 'text',
                    },
                  ],
                  nodeType: 'paragraph',
                },
              ],
              nodeType: 'list-item',
            },
            {
              data: {},
              content: [
                {
                  data: {},
                  content: [
                    {
                      data: {},
                      marks: [],
                      value: 'Frames',
                      nodeType: 'text',
                    },
                  ],
                  nodeType: 'paragraph',
                },
              ],
              nodeType: 'list-item',
            },
            {
              data: {},
              content: [
                {
                  data: {},
                  content: [
                    {
                      data: {},
                      marks: [],
                      value: 'Lenses + Contacts',
                      nodeType: 'text',
                    },
                  ],
                  nodeType: 'paragraph',
                },
              ],
              nodeType: 'list-item',
            },
          ],
          nodeType: 'unordered-list',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    eligible_expenses_pdf: 'https://picwellapppublicdata.s3.amazonaws.com/hsa-eligible-expenses.pdf',
    contribution_question: {
      answerNo: "No, I've never contributed",
      question: 'Have you contributed to an HSA before?',
      answerYes: "Yup, I've contributed before.",
      noDescription:
        'We’re here to help!  Contributing the right amount to your account is important to make sure you’re able to pay for out-of-pocket expenses throughout the year.',
      yesDescription:
        'Awesome, you’re ahead of the pack!  With that said, many people who have HSAs don’t properly contribute to them.  Below we’ll make sure you’re covering any risk you may be exposed to this year.',
    },
    contribution_info: {
      nodeType: 'document',
      data: {},
      content: [
        {
          nodeType: 'heading-4',
          content: [
            {
              nodeType: 'text',
              value: 'So, how much should I really contribute?',
              marks: [],
              data: {},
            },
          ],
          data: {},
        },
        {
          nodeType: 'paragraph',
          content: [
            {
              nodeType: 'text',
              value: 'At a minimum, you should plan to contribute enough ',
              marks: [],
              data: {},
            },
            {
              nodeType: 'text',
              value: 'to cover your average out-of-pocket cost estimate',
              marks: [
                {
                  type: 'bold',
                },
              ],
              data: {},
            },
            {
              nodeType: 'text',
              value:
                ', but the more you save, the better prepared you will be if you have higher than average costs.',
              marks: [],
              data: {},
            },
          ],
          data: {},
        },
        {
          nodeType: 'paragraph',
          content: [
            {
              nodeType: 'text',
              value: 'The annual HSA contribution limit in 2022 is ',
              marks: [],
              data: {},
            },
            {
              nodeType: 'text',
              value: '{x}',
              marks: [
                {
                  type: 'code',
                },
              ],
              data: {},
            },
            {
              nodeType: 'text',
              value: ', so after accounting for your employer contribution of ',
              marks: [],
              data: {},
            },
            {
              nodeType: 'text',
              value: '{x}',
              marks: [
                {
                  type: 'code',
                },
              ],
              data: {},
            },
            {
              nodeType: 'text',
              value: ', you can contribute up to ',
              marks: [],
              data: {},
            },
            {
              nodeType: 'text',
              value: '{x}{x}',
              marks: [
                {
                  type: 'code',
                },
              ],
              data: {},
            },
            {
              nodeType: 'text',
              value: '.',
              marks: [],
              data: {},
            },
          ],
          data: {},
        },
        {
          nodeType: 'paragraph',
          content: [
            {
              nodeType: 'text',
              value: '',
              marks: [],
              data: {},
            },
          ],
          data: {},
        },
      ],
    },
    catchup_addition: ' + $1,000 in catch-up contributions',
    slider_instruction: 'Move the slider below to adjust your contribution amount.',
    legend_terms: [
      {
        text: 'The health care costs that the insurance company does not pay for. In other words, when you get a medical bill, this is the amount that you are responsible for.',
        type: 'oop',
        title: 'Out-of-Pocket Estimate',
        underline: 'Out-of-Pocket Estimate',
      },
      {
        text: 'The amount your employer will contribute to your HSA.',
        type: 'employer',
        title: 'Employer Contribution',
        underline: 'Employer',
      },
      {
        text: 'The amount you will contribute throughout the year.',
        type: 'recommended',
        title: 'Your Contribution',
        underline: 'You',
      },
      {
        text: 'The estimated amount of medical expenses that are not covered through HSA contributions.',
        type: 'remaining',
        title: 'Remaining Expenses',
        underline: 'Remaining',
      },
    ],
    contribution_data: {
      taxSavings: {
        text: 'Here’s how much you can save on Federal taxes by making a tax-free contribution to your HSA.  That’s basically free money that you get to use to help cover your health care costs instead of paying the government. Depending on where you live, you may realize additional savings on state income taxes.',
        underline: 'Tax Savings',
      },
      perPaycheck: 'Per Paycheck',
      yearlyContribution: 'Yearly Contribution',
    },
    risk_data: {
      text: 'To calculate this number we compare your HSA contributions to the out-of-pocket costs of people like you. This number is the percentage of people like you who end up with costs that are lower than your contribution amount. The higher this number is, the better prepared you will be to cover your medical bills.',
      underline: 'Am I Saving Enough?',
      riskLevels: {
        safe: {
          tag: 'Safe',
          status: "Great, you're safe!",
          explanation:
            'This contribution amount would cover costs for {x} percent of people like you. You’re well prepared to deal with unexpected medical bills, and there’s a good chance you’ll have money left over to save for the future.',
        },
        risky: {
          tag: 'Risky',
          status: 'This is risky!',
          explanation:
            'This contribution amount would only cover costs for {x} percent of people like you. Contributing more will increase the chance that you’ll have enough saved to cover your costs this year and it will save you more on taxes.',
        },
        moderate: {
          tag: 'Moderate',
          status: 'Not bad.',
          explanation:
            'This contribution amount would cover costs for {x} percent of people like you. You should have more than enough to cover your costs in a typical year, but saving more will better prepare you for unexpected costs and save you more on taxes.',
        },
      },
    },
    investment_question: {
      answerNo: "No, I haven't invested funds.",
      question: 'Have you invested your HSA funds before?',
      answerYes: 'Yup, I am investing HSA funds!',
      noDescription:
        'It’s never too late to start! Health care is one of the biggest expenses that you will need to prepare for in retirement, and by investing your HSA funds, you can take advantage of compound interest and an HSA’s special tax breaks to make sure you are ready for these expenses.',
      yesDescription:
        'Nice job! Health care is expensive, especially when you get older.  Saving and investing in an HSA is the best way to prepare for your health care costs in retirement. You can use our forecasting tool to help determine how much more you’ll need to save to cover your health care costs in retirement.',
    },
    long_term_header: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'The average couple retiring today can expect to spend about $300,000 to cover health care costs in retirement.',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-4',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Health care will be among your biggest expenses in retirement, but savings and investing with an HSA is a great way for you to prepare for these costs. In fact, they are the single best way to save for health care costs in retirement!',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    triple_tax_header: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Making your HSA work for you!',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-3',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'HSAs are such a great way to save for retirement because they have a ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'bold',
                },
              ],
              value: 'triple tax advantage',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value: ': ',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    triple_tax_bullets: [
      'Any contributions you make to an HSA are tax free.',
      'Your money can grow tax free through investments.',
      'Unlike a 401(k), when you withdraw HSA funds, you won’t pay taxes if you use the money to cover qualified medical expenses.',
    ],
    picwell_disclaimer:
      'Disclaimer: Picwell estimates assume a constant rate of return of 6% on invested HSA balances. Outcomes may vary depending on actual market performance.',
    retirement_savings_info: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'How much do I need to save for retirement?',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-4',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Health care will be among your biggest expenses in retirement, but saving and investing with an HSA is a great way for you to prepare for these costs. We estimate that people your age (retiring at age 65) should expect to spend ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'code',
                },
              ],
              value: '{x}',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value: ' throughout their retirement on health care related expenses.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'This is how much we estimate your premiums and out-of-pocket expenses would be in a typical Medicare plan, assuming that you retire when you turn 65 and have an average life expectancy. Our estimate accounts for your personal characteristics, where you live, and expected health care cost inflation.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Use our forecasting tool below to see how saving more in your HSA can help you prepare for your health care costs in retirement.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    current_hsa_info: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: "What's your current HSA balance?",
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-4',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'An approximation is okay here. We’ll use this to better depict your savings in the future.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    current_hsa_balance_question: {
      header: "1. What's your current HSA balance?",
      description:
        'An approximation is okay here. We’ll use this to better depict your savings in the future.',
    },
    persona_question: {
      header: '2. Which best describes how you use your HSA?',
      spender: {
        title: 'Spender',
        description: 'I use my HSA to cover medical bills and save anything that’s left over.',
      },
      supersaver: {
        title: 'Super Saver',
        description: 'I invest everything that I put into my HSA and pay for medical bills with other funds.',
      },
    },
    long_term_graph: {
      title: {
        text: 'We forecast HSA savings by estimating how much you’ll have left over after paying your medical bills each year and adding up how much that accumulates over time. We assume that you invest those extra savings and earn a 6% annual return.',
        underline: 'Savings vs. Goal',
      },
      labels: ['Goal', 'Savings'],
      loadingText: 'Loading results...',
    },
    retirement_data: {
      text: 'We calculate this percent by dividing your projected HSA balance by your projected health care costs in retirement. Invested HSA balances may continue to grow in retirement, so you don’t have to get to 100% to be well prepared. You should aim for a higher target if you prefer to invest in more conservative funds, or if you anticipate higher than average medical costs in retirement. Higher costs can come from many factors, including living longer than average or needing more medical care than average.',
      underline: 'Am I ready for retirement?',
      riskLevels: {
        safe: {
          tag: 'On-track',
          status: 'Great job!',
          explanation:
            'With this amount of savings, you are in pretty good shape. Remember, your HSA investments will continue to grow after you retire, and that will help you cover your costs. Saving even more can help you take advantage of valuable tax breaks and be even better prepared for retirement.',
          bestExplanation:
            'You literally can’t save too much in an HSA. If you need to spend a lot on health care in retirement, you’ll be well prepared, and if you end up with more than you need, no worries! HSA withdrawals used for non-qualified expenses will be treated just like a 401(k) withdrawal.',
        },
        risky: {
          tag: 'Off-track',
          status: "You're off track.",
          explanation:
            'With this level of savings, you are not likely to have enough in your HSA to cover your health care costs in retirement. If you can save more, you’ll be able to take advantage of valuable tax breaks to meet your retirement goals.',
        },
        moderate: {
          tag: 'Off-track',
          status: 'Getting close!',
          explanation:
            'With this level of savings, there is a chance that your HSA savings won’t be able to cover all of your health care costs in retirement. If you can save more, you’ll be able to take advantage of valuable tax breaks to meet your retirement goals.',
        },
      },
    },
    how_to_fa_qs: [
      {
        answer:
          'You can open an HSA through your benefit administration platform. You can find a link to your HSA admin platform on the review page.',
        question: 'How do I open an HSA?',
      },
      {
        answer:
          '(1) Payroll deduction - your annual contribution will be divided into equal amounts and deducted from your payroll before taxes (2) Electronic transfer from your checking or savings account using your HSA admin platform (link on review page).',
        question: 'How do I contribute to my HSA?',
      },
      {
        answer:
          'When you open up an HSA, you will receive a debit card that you can use to pay for eligible medical expenses. You can use this debit card to pay health care providers directly, and you may also be able to submit these payments through your insurance company’s website.',
        question: 'How do I use my HSA funds?',
      },
      {
        answer:
          'When you open up an HSA, you will receive a debit card that you can use to pay for eligible medical expenses. You can use this debit card to pay health care providers directly, and you may also be able to submit these payments through your insurance company’s website.',
        question: "How do I receive my employer's contribution?",
      },
    ],
    chart_comparison_fa_qs: {
      hsaFsaHra: {
        cells: [
          ['The money in the account belongs to you', 'X', '', ''],
          ['The money in the account belongs to your employer', '', 'X', 'X'],
          ['You can contribute', 'X', 'X', ''],
          ['Your employer can contribute', 'X', 'X', 'X'],
          ['Must had high deductible plan to contribute', 'X', '', ''],
          ['You can invest funds', 'X', '', ''],
        ],
        question: "HSA, FSA, HRA: What's the difference?",
      },
      hsa401kIra: {
        cells: [
          [
            'Contributions are tax free',
            '',
            '401(k) contributions are not excluded from Social Security and Medicare taxes.',
            '',
          ],
          ['Capitial gains are tax free', 'X', 'X', 'X'],
          ['Withdrawals are tax free', 'If used for qualified medical expenses.', 'X', 'Roth IRA only.'],
          ['Minimum distribution requirements', 'X', 'X', 'Roth IRAs excluded.'],
        ],
        question: "HSA, 401(k), IRA: What's the difference?",
      },
    },
    what_if_fa_qs: [
      {
        answer:
          'Once you deposit the funds into an HSA, the account can be used to pay for qualified medical expenses tax-free, even if you no longer have HDHP coverage. However, you won’t be able to make new contributions.',
        question: "What if I don't enroll in an HSA eligible plan in the future?",
      },
      {
        answer:
          "The money in your HSA remains yours even after you lose or leave your job. You can usually keep that money in your former employer's HSA, or you can roll it over to another HSA administrator.",
        question: 'What happens to my HSA/funds if I switch jobs or get laid off?',
      },
      {
        answer:
          'Once you turn 65, regardless of whether you are enrolled in Medicare, there is no penalty to withdraw funds from the HSA. If you withdraw funds for qualified medical expenses, you won’t pay any taxes. HSA funds used for other purposes are taxed as normal income, just like a 401(k) withdrawal.',
        question: 'What happens to my HSA after I retire?',
      },
    ],
    next_text: {
      review: "Next, we'll review.",
      supplemental: "Next, we'll select supplemental plans.",
    },
    contribution_modal: {
      title: 'Contribution Saved',
      content: "We've locked in your contribution amount of",
      disclaimer: 'Come back at any time to change this amount.',
    },
    interactive_section: hsaInteractiveSection,
    learn_more_modal_info: 'Learn about the basics of your HSA, what it can be used for, FAQs, and more.',
    learn_more_modal_button_text: 'Learn More',
    ineligible_health_plan_text: 'You have selected a health plan that is not HSA eligible.',
    ineligible_health_plan_subtext: 'Please continue or select a different health plan.',
    unselected_health_plan_text: 'You currently have no health plan selected.',
    unselected_health_plan_subtext: 'Please continue or select a different health plan.',
  },
};
