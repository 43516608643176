import styled from 'styled-components';

export const PlanBullets = styled.section`
  ul {
    padding-left: var(--sizing-md);

    li {
      margin-bottom: 1rem;
      font-size: 18px;
      color: var(--text-gray);
    }
  }
`;
