import React from 'react';

import { ChartLegendTerm } from 'ContentfulDefaults/types/hsa';
import { useTextContext } from 'Contexts/textContext';
import { Paragraph, Tooltip } from 'DesignLibrary/atoms';
import Plan from 'Models/plan';
import { Recommendation } from 'Types/entities';
import { toDollars } from 'Utils/helpers';

import {
  ChartWrapper,
  ChartContainer,
  OopLine,
  ChartTitle,
  ContributionContainer,
  YourContribution,
  EmployerContribution,
  YAxis,
} from './styled';
import { LegendContainer, LegendItem } from '../styled';

export interface ShortTermChartProps {
  selectedPlan: Recommendation;
  employerHsaContribution: number;
  contribution: number;
  hsaContributionLimit: number;
}

export const ShortTermChart = ({
  selectedPlan,
  employerHsaContribution,
  contribution,
  hsaContributionLimit,
}: ShortTermChartProps) => {
  const { isEmployerMatching } = new Plan(selectedPlan);

  // HSA Values
  const oopEstimate = selectedPlan.costs.drugs + selectedPlan.costs.services.in_network;
  const remainingExposure = Math.max(oopEstimate - employerHsaContribution - contribution, 0);

  // In match contribution scenarios - We should only display the amount
  // the _user_ is contributing when it is less than the
  // maximum possible employer contribution amount
  const adjustedEmployerHsaContribution = isEmployerMatching
    ? Math.min(employerHsaContribution, contribution)
    : employerHsaContribution;

  // Percent of OOP value to max contribution
  const percentOopLine = (oopEstimate / hsaContributionLimit) * 100;

  // Percent of Cont. + Employer Cont. value to max contribution
  const percentContContainer =
    ((contribution + adjustedEmployerHsaContribution) / hsaContributionLimit) * 100;

  // Percent of Cont. value to contribution total
  const percentYourContribution = (contribution / (contribution + adjustedEmployerHsaContribution)) * 100;

  // Percent of Employer Cont. value to contribution total
  const percentEmployerContribution =
    (adjustedEmployerHsaContribution / (contribution + adjustedEmployerHsaContribution)) * 100;

  // Y Axis
  const axisTopValue = Math.floor(hsaContributionLimit / 1000) * 1000;

  const axisValues = [
    0, // $0
    Math.ceil((axisTopValue * 0.3333) / 100) * 100, // 2nd lowest value on Y-Axis
    Math.ceil((axisTopValue * 0.6666) / 100) * 100, // 2nd highest value on Y-Axis
    axisTopValue, // Highest value on Y-Axis
  ];
  const percentAxisHeight = Number(
    ((axisValues[axisValues.length - 1] / hsaContributionLimit) * 100).toFixed(4),
  );

  // A11y ARIA LABEL TEXT
  // TODO: This will need to get reconfigured in the future, but for now this gives us the A11y support that we need.
  const { retrieveContentfulData } = useTextContext();

  const legendTerms = retrieveContentfulData<ChartLegendTerm[]>(
    'hsa.interactive_section.yearly_chart_legend',
  );

  const oopEstimateDisplay = toDollars(oopEstimate);
  const employerDisplay = toDollars(adjustedEmployerHsaContribution);
  const contributionDisplay = toDollars(contribution);
  const remainingExposureDisplay = toDollars(remainingExposure);

  const legendHiddenValues = [
    employerDisplay,
    contributionDisplay,
    remainingExposureDisplay,
    oopEstimateDisplay,
  ];

  let graphAltText = ``;
  legendTerms.forEach((currElement, index) => {
    graphAltText += `${legendHiddenValues[index]} ${currElement.title},`;
  });

  return (
    <ChartContainer role="img" aria-label={graphAltText}>
      <ChartWrapper className="chart-container">
        <ContributionContainer percent={percentContContainer}>
          {percentYourContribution > 0 && (
            <YourContribution
              percent={percentYourContribution}
              small={contribution < 250}
              curved={contribution > 0}
            >
              <span data-testid="yourContribution">{toDollars(contribution)}</span>
            </YourContribution>
          )}
          {percentEmployerContribution > 0 && (
            <EmployerContribution
              percent={percentEmployerContribution}
              small={adjustedEmployerHsaContribution < 250}
              curved={contribution === 0}
            >
              <span data-testid="employerContribution">{toDollars(adjustedEmployerHsaContribution)}</span>
            </EmployerContribution>
          )}
        </ContributionContainer>
        <OopLine percent={percentOopLine} />
        <YAxis percent={percentAxisHeight}>
          {axisValues.map((num, index) => (
            <Paragraph size="mini" key={index}>
              {toDollars(num)}
            </Paragraph>
          ))}
        </YAxis>
      </ChartWrapper>
      <ChartTitle>
        {/* TODO: CONTENTFUL Add Field */}
        Max Contribution: <b>{toDollars(hsaContributionLimit)}</b>
      </ChartTitle>

      <LegendContainer>
        <LegendItem legendType="block" color="--colors-blue-400">
          <Tooltip
            // TODO: CONTENTFUL Add New Fields
            label={{
              title: 'Your Contribution',
              text: 'The amount you will contribute throughout the year.',
            }}
          >
            {/* TODO: CONTENTFUL Add Field */}
            Your Contribution
          </Tooltip>
        </LegendItem>
        <LegendItem legendType="block" color="--colors-blue-800">
          <Tooltip
            // TODO: CONTENTFUL Add New Fields
            label={{
              title: 'Employer Contribution',
              text: 'The amount your employer will contribute to your HSA.',
            }}
          >
            {/* TODO: CONTENTFUL Add Field */}
            Your Employer
          </Tooltip>
        </LegendItem>
        <LegendItem legendType="dash" color="--text-black">
          <Tooltip
            // TODO: CONTENTFUL Add New Fields
            label={{
              title: 'Out-of-Pocket Estimate',
              text: 'The health care costs that the insurance company does not pay for. In other words, when you get a medical bill, this is the amount that you are responsible for.',
            }}
          >
            {/* TODO: CONTENTFUL Add Field */}
            Out-of-Pocket Est.
          </Tooltip>
        </LegendItem>
      </LegendContainer>
    </ChartContainer>
  );
};
