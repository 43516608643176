import styled from 'styled-components';

import { FadeInKeyframes } from 'DesignLibrary/keyframes';

import { media } from '../../global';
import { cardStyling } from '../../utils';

interface BannerContainerProps {
  background?: string;
}

export const BannerContainer = styled.div<BannerContainerProps>`
  ${cardStyling}
  width: 100%;
  overflow: hidden;

  .img-container {
    width: 100%;
    height: calc(var(--sizing-xxl) * 1.5);
    background: var(${({ background }) => background || '--background-gray-light'});

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
      opacity: 0;
      animation: ${FadeInKeyframes} 0.75s 0.75s forwards;
    }
  }

  .content {
    padding: var(--sizing-sm);

    & > * + * {
      margin-top: var(--sizing-sm);
    }

    & > * {
      text-align: center;
    }

    ${media.TABLET`
      padding: var(--sizing-md);

      & > * {
        text-align: left;
      }
    `}
  }
`;
