import styled, { css } from 'styled-components';

import { media } from 'DesignLibrary/global';

export const Head = styled.header`
  display: flex;
  align-items: center;
  column-gap: 12px;

  .score-container {
    height: var(--sizing-xl);
    width: var(--sizing-xl);
    position: relative;
    flex-shrink: 0;

    > * {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    ${media.TABLET`
      height: var(--sizing-2xl);
      width: var(--sizing-2xl);
    `}
  }

  .header-content {
    transition: 0.15s transform;
    min-width: 0;

    > * {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  ${media.TABLET`
    column-gap: var(--sizing-sm);
  `}

  ${media.DESKTOP`
    .score-container {
      display: none;
    }

    .header-content.scoot {
      transform: translateX(var(--sizing-md));
    }
  `}
`;

interface PlanTitleProps {
  length: number;
}
// Truncating Breakpoints
const tb = [36, 42, 50, 58, 64];

const getFontSize = (length: number, fontSizes: number[]): string | null => {
  let size;

  switch (true) {
    case length > tb[0] && length < tb[1]:
      size = fontSizes[0];
      break;
    case length > tb[1] && length < tb[2]:
      size = fontSizes[1];
      break;
    case length > tb[2] && length < tb[3]:
      size = fontSizes[2];
      break;
    case length > tb[3] && length < tb[4]:
      size = fontSizes[3];
      break;
    case length > tb[4]:
      size = fontSizes[4];
      break;
    default:
      break;
  }

  return size ? `font-size: ${size}px;` : null;
};

export const PlanTitle = styled.div<PlanTitleProps>`
  h2 {
    ${({ length }) => css`
      ${length > tb[0] ? 'margin-top: var(--sizing-xs);' : null}

      ${getFontSize(length, [18, 17, 16, 15, 15])}

        ${media.TABLET`
          ${getFontSize(length, [26, 25, 24, 21, 18])}
        `}

        ${media.DESKTOP`
          ${getFontSize(length, [26, 25, 22, 20, 17])}
        `}

        ${media.WIDEDESKTOP`
          ${getFontSize(length, [32, 30, 26, 24, 22])}
        `}
    `}
  }
`;

export const Divider = styled.div`
  width: 1px;
  height: 12px;
  border-right: 1px solid;
  border-color: var(--alex-border-gray) !important;
`;

export const SearchProviderWrapper = styled.div`
  button,
  a {
    text-decoration: none;
    font-size: 15px;
  }
`;
