import _ from 'lodash';
import React from 'react';

import { TextField } from 'Containers/App/types';
import { DRUG_TYPES, SERVICE_TYPES } from 'Containers/ResultPage/constants';
import { useTextContext } from 'Contexts/textContext';
import { FormattedTooltipProps, Numbers, Paragraph } from 'DesignLibrary/atoms';
import { TooltipPlanDetails } from 'DesignLibrary/molecules';
import Plan from 'Models/plan';
import Text from 'Shared/Text';
import { toDollars } from 'Utils/helpers';

import { PlanCardRow } from './styled';

export const renderCosts = (
  plan: Plan,
  client: string,
  costType: SERVICE_TYPES | DRUG_TYPES,
  isDrugCost: boolean,
  index: number,
) => {
  const { retrieveContentfulData } = useTextContext();

  const subDeductible = plan[costType].deductible_applies;

  // can be both isCopay and isCoinsurance, e.g. see API-377
  const isCopay = plan[costType].coinsurance == null;

  const costTypeSnakeCase = _.snakeCase(costType);
  const costTypeKebabCase = _.kebabCase(costType);
  const copayOrCoinsurance = isCopay ? 'copay' : 'coinsurance';

  const tooltipProps: FormattedTooltipProps = {
    ...retrieveContentfulData<FormattedTooltipProps>(
      `tool_tips.${costTypeSnakeCase}_${copayOrCoinsurance}` as TextField,
    ),
  };

  return (
    <PlanCardRow className="planCardRow" key={index}>
      <TooltipPlanDetails tooltip={tooltipProps} keyHint={`plan-${plan.planId}-${costTypeKebabCase}-cost`}>
        {String(tooltipProps.underline)}
      </TooltipPlanDetails>
      <Paragraph id={`${costTypeKebabCase}-copay-or-coinsurance`}>
        {isDrugCost
          ? renderDrugCost(plan, costType, isCopay, subDeductible)
          : renderServiceCost(plan, costType, isCopay, subDeductible)}
      </Paragraph>
    </PlanCardRow>
  );
};

export const renderDrugCost = (
  plan: Plan,
  costType: SERVICE_TYPES | DRUG_TYPES,
  isCopay: boolean,
  subDeductible,
) => {
  const { retrieveContentfulData } = useTextContext();

  if (plan[costType].covered) {
    return (
      <span aria-labelledby={subDeductible ? `${plan.planId}${costType} afterDeductible` : ''}>
        <Numbers>
          <span aria-hidden={!!subDeductible} id={`${plan.planId}${costType}`}>
            {isCopay ? `$${plan[costType].copay}` : `${plan[costType].coinsurance}%`}
            {plan[costType].max_cost_sharing && (
              <Text
                field="health_section.min_max_cost_sharing.maxCostSharing"
                vars={{
                  x: toDollars(plan[costType].max_cost_sharing),
                }}
              />
            )}
          </span>
          {subDeductible && <span aria-hidden>*</span>}
        </Numbers>
      </span>
    );
  }
  return (
    <Numbers>
      {retrieveContentfulData<string>(
        'health_details_section.plan_details.prescription_cost_not_covered_text',
      )}
    </Numbers>
  );
};

const renderServiceCost = (
  plan: Plan,
  costType: SERVICE_TYPES | DRUG_TYPES,
  isCopay: boolean,
  subDeductible,
) => {
  // if coverage has both copay and coinsurance, prefer to display coinsurance. This is something
  // of a hack, until we can improve configuration and explanatory text to support combined
  // copay/coins.
  if (isCopay) {
    return (
      <span aria-labelledby={subDeductible ? `${plan.planId}${costType} afterDeductible` : ''}>
        <Numbers>
          <span aria-hidden={!!subDeductible} id={`${plan.planId}${costType}`}>
            {`$${plan[costType].copay} `}
            <Text field="health_section.compare_plan_text.compareModal.perVisit" />
          </span>
          {subDeductible && <span aria-hidden>*</span>}
        </Numbers>
      </span>
    );
  }
  return (
    <span aria-labelledby={subDeductible ? `${plan.planId}${costType} afterDeductible` : ''}>
      <Numbers>
        <span aria-hidden={!!subDeductible} id={`${plan.planId}${costType}`}>
          {`${plan[costType].coinsurance}%`}
        </span>
        {subDeductible && <span aria-hidden>*</span>}
      </Numbers>
    </span>
  );
};
