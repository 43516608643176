import { Stack } from '@chakra-ui/react';
import React from 'react';

import { TextField } from 'Containers/App/types';
import { HIStayOrYear, HospitalIndemnityBenefits } from 'Containers/ViewBenefitPage/types';
import { transformBenefitItems, maxTimeUnit } from 'Containers/ViewBenefitPage/utils';
import { RichTextContent } from 'ContentfulDefaults/types/_shared';
import { ContentfulSupplementalPlan } from 'ContentfulDefaults/types/benefitsAdditional';
import { H5, Paragraph } from 'DesignLibrary/atoms';
import RichText from 'Shared/RichText';
import Text from 'Shared/Text';
import { SupplementalPlan } from 'Types/entities';
import { toDollars } from 'Utils/helpers';

import { HiddenItem } from './PlanContent/styled';

const admissionOrYear = (value: HIStayOrYear) => {
  if (value === 'year') {
    return <Text field="benefits_section_hospital_indemnity.year" />;
  }
  return <Text field="benefits_section_hospital_indemnity.admission" />;
};

export const hospitalIndemnityDetails = (plan: SupplementalPlan) => {
  const benefits = transformBenefitItems(plan) as HospitalIndemnityBenefits;

  const hospAdmissionCoverage =
    benefits.hospital_admission_max_number === '1' ? (
      <Text
        field="benefits_section_hospital_indemnity.hospital_admission_coverage"
        vars={{
          copay: toDollars(benefits.hospital_admission_amount),
          frequency: <Text field="benefits_section_hospital_indemnity.once" />,
        }}
      />
    ) : (
      <Text
        field="benefits_section_hospital_indemnity.hospital_admission_coverage"
        vars={{
          copay: toDollars(benefits.hospital_admission_amount),
          frequency: (
            <Text
              field="benefits_section_hospital_indemnity.up_to_x_times"
              vars={{ times: benefits.hospital_admission_max_number, limit: '' }}
            />
          ),
        }}
      />
    );

  return (
    <section className="benefits hidden-benefits hospital-indemnity-plan">
      <Paragraph as="h6">
        <Text field="benefits_section.plan_card_text.payouts" />
      </Paragraph>
      {benefits.has_hospital_admission && (
        <HiddenItem className="hospital-admission">
          <Paragraph size="mini" className="key">
            <Text field="benefits_section_hospital_indemnity.hospital_admission" />
          </Paragraph>
          <Paragraph size="mini" className="value">
            {hospAdmissionCoverage}
          </Paragraph>
        </HiddenItem>
      )}
      {benefits.has_hospital_confinement && (
        <HiddenItem className="hospital-stay">
          <Paragraph size="mini" className="key">
            <Text field="benefits_section_hospital_indemnity.hospital_stay" />
          </Paragraph>
          <Paragraph size="mini" className="value">
            <Text
              field="benefits_section_hospital_indemnity.hospital_stay_coverage"
              vars={{
                copay: toDollars(benefits.hospital_confinement_amount),
                timeUnit: (
                  <Text
                    field="benefits_section_hospital_indemnity.up_to_x_times"
                    vars={{
                      times: benefits.hospital_confinement_max_number,
                      limit: maxTimeUnit(
                        benefits.hospital_confinement_max_number,
                        benefits.hospital_confinement_max_unit,
                      ),
                    }}
                  />
                ),
                admissionOrYear: admissionOrYear(benefits.hospital_confinement_max_window),
              }}
            />
          </Paragraph>
        </HiddenItem>
      )}
      {benefits.has_icu && (
        <HiddenItem className="icu-stay">
          <Paragraph size="mini" className="key">
            <Text field="benefits_section_hospital_indemnity.icu_stay" />
          </Paragraph>
          <Paragraph size="mini" className="value">
            <Text
              field="benefits_section_hospital_indemnity.icu_stay_coverage"
              vars={{
                copay: toDollars(benefits.icu_amount),
                timeUnit: maxTimeUnit(benefits.icu_max_number, benefits.icu_max_unit),
                limit: benefits.icu_max_number,
                admissionOrYear: admissionOrYear(benefits.icu_max_window),
              }}
            />
          </Paragraph>
        </HiddenItem>
      )}
      <HiddenItem className="pregnancy">
        <Paragraph size="mini" className="key">
          <Text field="benefits_section_hospital_indemnity.pregnancy" />
        </Paragraph>
        <Paragraph size="mini" className="value">
          {benefits.has_pregnancy ? (
            <Text field="benefits_section_hospital_indemnity.pregnancy_coverage_included" />
          ) : (
            <Text field="benefits_section_hospital_indemnity.pregnancy_coverage_not_included" />
          )}
        </Paragraph>
      </HiddenItem>
    </section>
  );
};

export const hospitalIndemnityAdditionalNotes = (
  plan: SupplementalPlan,
  contentfulSupplementalPlan: ContentfulSupplementalPlan | undefined,
) => {
  const benefits = transformBenefitItems(plan) as HospitalIndemnityBenefits;

  const spouseHasSameBenefits = benefits.spouse_has_same_benefits;
  const childHasSameBenefits = benefits.child_has_same_benefits;
  const availableToPartner = benefits.available_to_partner;

  const outroTextNoteShort: RichTextContent | null =
    contentfulSupplementalPlan?.outro_text_note_short || null;
  const outroTextNoteLong: RichTextContent | null = contentfulSupplementalPlan?.outro_text_note_long || null;
  const outroNoteCoveredServices: RichTextContent | null =
    contentfulSupplementalPlan?.outro_note_cover || null;

  let lowerPayoutField: string = '';
  if (!spouseHasSameBenefits && !childHasSameBenefits) {
    if (availableToPartner) {
      lowerPayoutField = 'benefits_section_hospital_indemnity.lower_payout_family';
    } else {
      lowerPayoutField = 'benefits_section_hospital_indemnity.lower_payout_spouse_or_children';
    }
  } else if (!childHasSameBenefits) {
    lowerPayoutField = 'benefits_section_hospital_indemnity.lower_payout_children';
  } else if (!spouseHasSameBenefits) {
    if (availableToPartner) {
      lowerPayoutField = 'benefits_section_hospital_indemnity.lower_payout_spouse_or_partner';
    } else {
      lowerPayoutField = 'benefits_section_hospital_indemnity.lower_payout_spouse';
    }
  }

  const additionalInfo: JSX.Element[] = [];
  if (!benefits.has_guaranteed_issue) {
    additionalInfo.push(
      <RichText field="benefits_section_hospital_indemnity.non_guaranteed_issue_disclaimer" />,
    );
  }

  if (lowerPayoutField) {
    additionalInfo.push(<Text field={lowerPayoutField as TextField} />);
  }

  return (
    <Stack gap={4}>
      {benefits.has_covered_services && (
        <Stack>
          <H5>
            <Text field="benefits_section_hospital_indemnity.other_covered_services" />
          </H5>
          <Paragraph as="div" lineHeight={1.75} size="small" className="plan-description">
            {outroNoteCoveredServices && (
              <Paragraph>
                <RichText field={undefined} fieldContent={outroNoteCoveredServices} />
              </Paragraph>
            )}
          </Paragraph>
        </Stack>
      )}
      {additionalInfo.length > 0 && (
        <Stack>
          <H5>
            <Text field="benefits_section_hospital_indemnity.additional_information" />
          </H5>
          <Paragraph as="div" lineHeight={1.75} size="small" className="plan-description">
            <ul>
              {additionalInfo.map((item, index) => (
                <li key={`addtl-info-${index}`}>{item}</li>
              ))}
            </ul>
          </Paragraph>
        </Stack>
      )}
      {outroTextNoteShort && (
        <Stack>
          <Paragraph>
            <RichText field={undefined} fieldContent={outroTextNoteShort} />
          </Paragraph>
          {outroTextNoteLong && (
            <Paragraph>
              <RichText field={undefined} fieldContent={outroTextNoteLong} />
            </Paragraph>
          )}
        </Stack>
      )}
    </Stack>
  );
};

export const getHospitalIndemnityTagList = (
  hospitalIndemnityBenefits: HospitalIndemnityBenefits,
): JSX.Element[] => {
  const tags: JSX.Element[] = [];

  if (hospitalIndemnityBenefits.has_hospital_admission) {
    tags.push(<Text field="benefits_section_hospital_indemnity.hospital_admissions" />);
  }
  if (hospitalIndemnityBenefits.has_hospital_confinement) {
    tags.push(<Text field="benefits_section_hospital_indemnity.hospital_stays" />);
  }
  if (hospitalIndemnityBenefits.has_icu) {
    tags.push(<Text field="benefits_section_hospital_indemnity.icu" />);
  }
  if (hospitalIndemnityBenefits.has_pregnancy) {
    tags.push(<Text field="benefits_section_hospital_indemnity.pregnancy" />);
  }

  return tags;
};
