import { get } from 'lodash';
import React, { useContext } from 'react';

import { ClientConfig } from 'Utils/apiTypes';

interface ConfigProviderProps {
  config: ClientConfig | Record<string, never>;
  children: JSX.Element;
}

interface ConfigContextProps {
  config: ClientConfig | Record<string, never>;
  getConfigField: (field: keyof ClientConfig) => ClientConfig[keyof ClientConfig];
}

export const useConfigContext = () => useContext(ConfigContext);

export const ConfigContext = React.createContext<ConfigContextProps>({
  config: {},
  getConfigField: () => ({}),
});

export const ConfigProvider = ({ config, children }: ConfigProviderProps) => {
  const getConfigField = (
    field: keyof ClientConfig,
    defaultOverride = null,
  ): ClientConfig[keyof ClientConfig] => get(config, field, defaultOverride);

  return <ConfigContext.Provider value={{ config, getConfigField }}>{children}</ConfigContext.Provider>;
};
