import { formatMoney } from 'accounting';
import parse from 'html-react-parser';
import sanitizeHtml from 'sanitize-html';

import { RichTextContent } from 'ContentfulDefaults/types/_shared';
import { DoctorType, LocationProps, ProviderLocationProps } from 'Types/entities';

// Returns true when arrays do not contain identical elements (order not considered)
export const checkArraysForSymetricDifference = (arrOne, arrTwo) => {
  const differenceOne = arrOne.filter((i) => !arrTwo.includes(i));
  const differenceTwo = arrTwo.filter((j) => !arrOne.includes(j));
  return [...differenceOne, ...differenceTwo].length > 0;
};

// given Contentful RichText Object, returns all the string values concatenated into one string
export const getRichTextAsString = (rtObject: RichTextContent, vars: string[] = []) => {
  let parsed = ``;
  let varsIndex = 0;
  rtObject?.content?.forEach((i) => {
    i.content?.forEach((j) => {
      if (j.value) {
        if (!!vars.length && j.value.includes('{x}')) {
          parsed += `${j.value.replace(/{x}/, vars[varsIndex])}`;
          varsIndex += 1;
        } else {
          parsed += `${j.value}`;
        }
      }
    });
  });
  return parsed;
};

// given Contentful RichText Object, returns all the string values as a 1D string array
export const getRichTextAsStringArray = (rtObject: RichTextContent, vars: string[] = []) => {
  const parsed: string[] = [];
  let varsIndex = 0;
  rtObject.content?.forEach((i) => {
    i.content?.forEach((j) => {
      if (j.value) {
        if (!!vars.length && j.value.includes('{x}')) {
          parsed.push(`${j.value.replace(/{x}/, vars[varsIndex])}`);
          varsIndex += 1;
        } else {
          parsed.push(`${j.value}`);
        }
      }
    });
  });
  return parsed;
};

export const getIsRichTextEmpty = (rtObject: RichTextContent): boolean =>
  getRichTextAsString(rtObject) === '';

export const parseSanitizeHtml = (dirty: string) => parse(sanitizeHtml(dirty));

export const toDollars = (dollars: string | number | null | undefined, precision = 0) =>
  formatMoney(dollars, '$', precision);

export const formatDate = (
  date: string | Date,
  locale: string,
  options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    timeZone: 'UTC',
  } as Intl.DateTimeFormatOptions,
) => new Date(date).toLocaleDateString(locale, options);

export const isValidZipCode = (zip?: string): boolean =>
  zip !== undefined && zip !== '00000' && !!zip.toString().match(/^[0-9]{5}$/);

export const getProviderAddress = (addressDetail): string => {
  const { street, city, state, zip } = addressDetail || {};

  // Create an array for the address parts, with state and zip combined
  const addressParts: string[] = [];

  if (street) addressParts.push(street);
  if (city) addressParts.push(city);
  if (state || zip) addressParts.push([state, zip].filter(Boolean).join(' '));

  return addressParts.join(', ');
};

export const createProviderData = (result: DoctorType): ProviderLocationProps => {
  const label = [result.first_name, result.middle_name, result.last_name].filter(Boolean).join(' ');
  const providerTypes = result.provider_types;
  const locations: LocationProps[] = result.locations.map((location) => {
    const address = getProviderAddress(location!.address_details);
    const { uuid, name, distance, phone_numbers } = location;
    return {
      uuid,
      name,
      address,
      distance,
      phoneNumbers: phone_numbers,
      value: result.npi,
    };
  });

  return {
    label,
    locations,
    providerTypes,
    value: result.npi,
  };
};
