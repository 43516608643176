import styled from 'styled-components';

export const ProfileContent = styled.div`
  .profile-header {
    padding: var(--sizing-sm);
    border-bottom: 2px solid var(--border-gray);

    .user-name {
      color: var(--text-black) !important;
      font-weight: 900 !important;
    }
  }

  .profile-actions {
    button {
      text-decoration: none;
      display: block;
      width: 100%;
      text-align: left;
      margin-top: 0;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

export const SignedInWithGoogle = styled.div`
  margin-top: var(--sizing-xs);
  padding: var(--sizing-xs);
  background: var(--background-gray);
  border-radius: var(--border-radius-xs);

  img {
    height: var(--sizing-sm);
    width: var(--sizing-sm);
  }
`;
