import styled, { css } from 'styled-components';

import { media } from 'DesignLibrary/global';
import { focusUtil } from 'DesignLibrary/utils';

export type CtaItemStyleProps = {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  isDisabled?: boolean;
};

const ItemPrimitive = css`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-radius: var(--border-radius-xs);
  border: var(--border-thick);
  height: var(--sizing-xl);
  padding: 0 var(--sizing-sm);
  background: var(--background-gray-light);
`;

export const CtaItemStyle = styled.button<CtaItemStyleProps>`
  ${ItemPrimitive}
  transition: 0.1s background-color;
  padding-left: 6px;
  min-width: 0;
  width: 100%;

  ${media.TABLET`
    width: auto;
    max-width: var(--sizing-4xl);
  `}

  .paragraph {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 12px;
  }

  /* FOCUS STYLING */
  ${focusUtil}

  &:hover {
    background: var(--colors-gray-100);
    border-color: var(--input-gray);
  }

  &:active {
    background: var(--colors-gray-50);
    border-color: var(--input-gray);
  }

  .supplemental-icon {
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    margin-left: 4px;
  }
`;

export const CtaPlaceholderItem = styled.div`
  ${ItemPrimitive}
  border: 2px dashed var(--border-gray);
`;

export const SupplementalToggle = styled(CtaItemStyle)`
  padding: 0 var(--sizing-sm);
  display: flex;
  align-items: center;
  gap: var(--sizing-xs);
`;
