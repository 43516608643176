import styled from 'styled-components';

import { media } from 'DesignLibrary/global';
import { cardStyling, selectedBlueStyling, selectedGreenStyling } from 'DesignLibrary/utils';

interface PlanCardProps {
  isSelected: boolean;
  isAutoEnrolled: boolean;
}

export const PlanCard = styled.section<PlanCardProps>`
  ${cardStyling}
  position: relative;
  padding: var(--sizing-sm);

  ${media.TABLET`
    padding: var(--sizing-md);
  `}

  ${({ isSelected }) => isSelected && selectedGreenStyling}
  ${({ isSelected, isAutoEnrolled }) => isAutoEnrolled && isSelected && selectedBlueStyling}

  .badge-container {
    display: none;

    ${media.DESKTOP`
    display: block;
    position: absolute;
    top: 20px;
    right: 100%;
    
    .badge {
      position: relative;
      left: var(--sizing-lg);
    }
    `}
  }

  .messages,
  .plan-content,
  .button-container {
    margin-top: var(--sizing-sm);

    ${media.TABLET`
      margin-top: var(--sizing-md);
    `}
  }

  .messages {
    & > * + * {
      margin-top: var(--sizing-xs);
    }
  }
`;

export const CardHeader = styled.section`
  ${media.TABLET`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `}

  ${media.DESKTOP`
    .header-text {
      transition: .2s transform;
    }

    .header-text.scoot {
      transform: translateX(var(--sizing-md));
    }
  `}

  .header {
    display: flex;
    align-items: center;
    gap: var(--sizing-sm);
  }

  .premium {
    margin-top: var(--sizing-xs);

    ${media.TABLET`
      margin: 0;
    `}
  }
`;

export const ButtonContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: var(--sizing-xs);

  > button {
    flex-shrink: 0;
  }

  ${media.TABLET`
    flex-direction: row;
    justify-content: flex-end;
    column-gap: var(--sizing-xs);

    > button {
      max-width: var(--sizing-4xl);
    }
  `}
`;
