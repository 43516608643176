import { ContentfulBenefitsAdditional } from 'ContentfulDefaults/types/benefitsAdditional';

export const benefitsAdditional: {
  benefits_section_additional: ContentfulBenefitsAdditional;
} = {
  benefits_section_additional: {
    accessibility: {
      collapse_benefit_details: 'Hide {x} Details',
      expand_benefit_details: 'Show More {x} Details',
    },
    best_match_badge_label: 'Great Fit',
    coverage_details_header: 'Coverage Details',
    coverage_level_table_header: 'Coverage levels',
    coverage_level_table_subheader: 'You can select your preferred amounts when you go to enroll.',
    coverage_options_auto_enrolled_label: 'Select who you would be adding additional coverage for.',
    coverage_options_label: 'First, select who you are covering in a {x} plan.',
    covered_conditions_table_header: 'Covered conditions',
    covered_conditions_table_subheader: 'Here are the conditions that trigger a payout on this plan.',
    custom_messages: [],
    deductible: 'Deductible',
    enrollee_select_disclaimer: {
      contentful_name: 'Enrollee Select Disclaimer',
      color: 'blue',
      text: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'For certain benefits below, you may check or uncheck enrollees to see the rates for your selection.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
    },
    fbs_plan_auto_enrolled_info_bar: {
      contentful_name: '[Benefit Details] Fbs Plan Auto Enrolled Info Bar',
      text: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value: 'Your employer offers this plan to you at no cost!',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      color: 'blue',
    },
    fbs_plan_auto_enrolled_label: 'Optionally, choose additional {x} coverage below.',
    fbs_plan_buy_up_info_bar: {
      contentful_name: '[Benefit Details] Fbs Plan Buy Up Info Bar',
      text: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'You may elect to purchase additional coverage below which provides benefits beyond this auto-enrolled plan.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      color: 'blue',
    },
    fbs_plan_label: 'Select your coverage.',
    id_theft_benefit: {
      contentful_name: 'Id Theft Benefit',
      icon_link: 'https://picwellapppublicdata.s3.amazonaws.com/legal_services.svg',
      card_title: 'ID Theft',
      card_description:
        'Identity theft protection benefits protect you from ID theft and help you recover if you become a victim of ID theft.',
      modal_description: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'ID Theft Protection Benefits help block criminals by monitoring the internet for suspicious activity related to your credit and other accounts, and sending you alerts when they notice suspicious activity. ',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'These benefits can also help you mitigate the harm from ID theft if it does occur, by providing access to advocates who can help you through the time consuming and stressful process of reversing damages from ID theft. Some benefits also include insurance to help you cover costs associated with ID theft, such as lost wages, legal fees, or direct reimbursement of stolen funds. ',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'The specific benefits available to you may vary, so be sure to carefully review your plan details. ',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
          {
            data: {},
            content: [{ data: {}, marks: [], value: '\n', nodeType: 'text' }],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      video_link: 'https://vimeo.com/748110370',
    },
    in_network: 'IN-NETWORK',
    network_info_header: 'Network Info',
    network_name: 'Network Name',
    no_plans_info_bar: {
      contentful_name: '[BenefitDetails] No Plans Info Bar',
      text: {
        nodeType: 'document',
        data: {},
        content: [
          {
            nodeType: 'paragraph',
            data: {},
            content: [
              {
                nodeType: 'text',
                value:
                  'Unlike other benefits with "Choose" buttons, there are no plans to choose from here.  Learn more about how this benefit works below.',
                marks: [],
                data: {},
              },
            ],
          },
        ],
      },
    },
    non_selectable_plans_label: 'Learn more about this service your employer offers:',
    number_with_pay_period_range_label: 'starting at',
    other_benefit: {
      contentful_name: 'Other Benefit',
      card_title: 'Other Benefits',
      card_description: 'Additional supplemental benefits offered by your employer',
    },
    page_header: {
      text: 'Choose Your Benefits',
      subtext: 'Your employer offers these voluntary supplemental benefits.',
    },
    provider_link: 'Find a provider',
    provider_directory: 'Provider Directory',
    recommended_badge_label: 'Good Fit',
    supplemental_plans: [
      {
        plan_id: '12345',
        benefit_items: ['Broken Toe:$2,000 minimum benefit', 'Broken Hip:$5,000 maximum benefit'],
        carrier: 'carrier',
        contentful_name: '[test-go] Plan - Accident - 735005',
        coverage_disclaimer:
          'Accident coverage is available for spouses and children. Kids may be covered until age 22.',
      },
    ],
    text_note: {
      short_text: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value: 'text note short text',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      long_text: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value: 'long text text note',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      button_text: 'Read More',
    },
    tooltips: {
      max_annual_benefit: {
        title: 'Maximum Annual Benefit',
        text: 'This is the maximum amount that your plan will cover for dental services in a year.',
      },
      recommended_badge:
        'This type of benefit is a great option for you! Click "Learn More" or "Choose" to see why we recommend it.',
      best_match_badge:
        'Of the voluntary benefits available to you, we think that this is your best match! Click on the "Learn More" button to see why we recommend it.',
    },
    coverage_disclaimer_available_to_all:
      '{plan} insurance is available for all family members. Kids may be covered until age {maxAge}.',
    coverage_disclaimer_available_to_none: '{plan} insurance is not available for family members.',
    coverage_disclaimer_available_to_spouses:
      '{plan} insurance is available for spouses. Coverage is not available for children.',
    coverage_disclaimer_available_to_children:
      '{plan} insurance is not available for spouses or partners. Kids may be covered until age {maxAge}.',
    coverage_disclaimer_available_to_partners:
      '{plan} insurance is not available for children. You may cover a spouse or partner.',
    coverage_disclaimer_spouses_and_children:
      '{plan} insurance is available for spouses and children. Kids may be covered until age {maxAge}.',
  },
};
