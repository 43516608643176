import styled from 'styled-components';

import { breakpoint } from 'DesignLibrary/global';

export const ButtonContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: var(--sizing-xs);
  width: 100%;

  > button {
    flex-shrink: 0;
    width: auto !important;
  }

  ${breakpoint.TABLET} {
    flex-direction: row;
    justify-content: flex-end;
    column-gap: var(--sizing-xs);

    > button {
      flex: 2;
    }
  }
`;
