import { fromJS } from 'immutable';

import { GET_RECOMMENDATIONS_SUCCESS, SELECT_HEALTH_PLAN } from 'Containers/CommercialRoutes/constants';
import { GetForecastsResponse, GetHsaRecommendationResponse } from 'Utils/apiTypes';
import { wrapImmutableReducerInPojoTranslation } from 'Utils/reducers';

import {
  FORECAST_HSA_FAILURE,
  FORECAST_HSA_SUCCESS,
  GET_FSA_TAX_SAVINGS_REQUEST,
  GET_FSA_TAX_SAVINGS_SUCCESS,
  GET_HSA_RECOMMENDATION_FAILURE,
  GET_HSA_RECOMMENDATION_REQUEST,
  GET_HSA_RECOMMENDATION_SUCCESS,
  LOCK_FSA_CONTRIBUTION,
  LOCK_HSA_CONTRIBUTION,
  RETIREMENT_AGE,
  SET_HSA_BALANCE_AND_PERSONA,
} from './constants';
import { TaxSavingsPageState, Persona } from './types';

const initialState: TaxSavingsPageState = fromJS({
  hsaData: {},
  forecastData: {},
  fsaData: {},
  retirementForecastData: [],
  isHsaRecommendationLoading: true, // TODO: we can set this to false when a loading state is created for HSA page
  isFsaTaxSavingsLoading: true,
  isForecastLoading: false,
  isRetirementForecastLoading: false,
  error: null,
  forecastError: null,
  retirementError: null,
  fsaTaxSavingsError: null,
  // insights and the review pag event are falling back to the recommended
  // contribution if null to render the "final" contribution,
  lockedHsaContribution: null,
  lockedFsaContribution: null,
  longtermHsaContribution: 0,
  currentHsaBalance: null,
  persona: 'middle',
  retirementAge: RETIREMENT_AGE,
});

function taxSavingsPageReducer(state = initialState, action) {
  switch (action.type) {
    case GET_HSA_RECOMMENDATION_REQUEST:
      return getHsaData(state);
    case GET_HSA_RECOMMENDATION_SUCCESS:
      return getHsaDataSuccess(state, action);
    case GET_HSA_RECOMMENDATION_FAILURE:
      return getHsaDataFailure(state, action);
    case FORECAST_HSA_SUCCESS:
      return getForecastSuccess(state, action);
    case FORECAST_HSA_FAILURE:
      return getForecastFailure(state, action);
    case LOCK_HSA_CONTRIBUTION:
      return setSavedHsaContribution(state, action);
    case LOCK_FSA_CONTRIBUTION:
      return setSavedFsaContribution(state, action);
    case SELECT_HEALTH_PLAN:
      return resetContributionAmounts(state);
    case SET_HSA_BALANCE_AND_PERSONA: {
      return setHsaForecastInputs(state, action);
    }
    case GET_FSA_TAX_SAVINGS_REQUEST:
      return getFsaTaxSavings(state);
    case GET_FSA_TAX_SAVINGS_SUCCESS:
      return getFsaTaxSavingsSuccess(state, action);
    case GET_RECOMMENDATIONS_SUCCESS:
      return resetHsaData(state);
    default:
      return state;
  }
}

function resetHsaData(state: TaxSavingsPageState) {
  return state
    .set('isHsaRecommendationLoading', true)
    .set('lockedHsaContribution', null)
    .set('hsaData', fromJS({}))
    .set('isForecastLoading', false)
    .set('isRetirementForecastLoading', false)
    .set('forecastData', fromJS({}))
    .set('retirementForecastData', fromJS([]));
}

function getHsaData(state: TaxSavingsPageState) {
  return state.set('isHsaRecommendationLoading', true);
}

function getHsaDataSuccess(
  state: TaxSavingsPageState,
  action: {
    response: GetHsaRecommendationResponse;
  },
) {
  return state.set('isHsaRecommendationLoading', false).set('hsaData', fromJS(action.response));
}

function getHsaDataFailure(
  state: TaxSavingsPageState,
  action: {
    error: Error;
  },
) {
  return state.set('isHsaRecommendationLoading', false).set('error', action.error);
}

function getForecastSuccess(
  state: TaxSavingsPageState,
  action: {
    response: GetForecastsResponse;
  },
) {
  return state.set('isForecastLoading', false).set('forecastData', fromJS(action.response));
}

function getForecastFailure(
  state: TaxSavingsPageState,
  action: {
    error: Error;
  },
) {
  return state.set('isForecastLoading', false).set('forecastError', action.error);
}

function setSavedHsaContribution(
  state: TaxSavingsPageState,
  action: {
    contribution: number;
  },
) {
  return state.set('lockedHsaContribution', action.contribution).set('isForecastLoading', true);
}

function setSavedFsaContribution(
  state: TaxSavingsPageState,
  action: {
    contribution: number;
  },
) {
  return state.set('lockedFsaContribution', action.contribution).set('isFsaTaxSavingsLoading', true);
}

function resetContributionAmounts(state: TaxSavingsPageState) {
  return state.set('lockedFsaContribution', null).set('lockedHsaContribution', null);
}

function setHsaForecastInputs(
  state: TaxSavingsPageState,
  action: {
    balance: number;
    persona: Persona;
    retirementAge: number;
  },
) {
  return state
    .set('currentHsaBalance', action.balance)
    .set('persona', action.persona)
    .set('retirementAge', action.retirementAge)
    .set('isForecastLoading', true);
}

function getFsaTaxSavings(state: TaxSavingsPageState) {
  return state.set('isFsaTaxSavingsLoading', true);
}

function getFsaTaxSavingsSuccess(
  state: TaxSavingsPageState,
  action: {
    response: any;
  },
) {
  return state.set('isFsaTaxSavingsLoading', false).set('fsaData', fromJS(action.response));
}

const pojoTaxSavingsPageReducer = wrapImmutableReducerInPojoTranslation(taxSavingsPageReducer);

export default pojoTaxSavingsPageReducer;
