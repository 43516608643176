import { EnteredPlan } from 'Containers/CommercialRoutes/types';
import { Recommendation } from 'Types/entities';
import * as API_TYPES from 'Utils/apiTypes';
import { GetRecommendationsResponse, PeopleLikeYou } from 'Utils/apiTypes';

import {
  CHANGE_FORM_VALUE,
  GET_HOUSEHOLD_ID_BY_EXTERNAL_ID_FAILURE,
  GET_HOUSEHOLD_ID_BY_EXTERNAL_ID_SUCCESS,
  GET_PEOPLE_LIKE_YOU_FAILURE,
  GET_PEOPLE_LIKE_YOU_SUCCESS,
  GET_RECOMMENDATIONS_EPHEMERAL_REQUEST,
  GET_RECOMMENDATIONS_FAILURE,
  GET_RECOMMENDATIONS_SUCCESS,
  GET_RESULTS_PAGE_DATA,
  POST_INCENTIVE_SURVEY_FAILURE,
  POST_INCENTIVE_SURVEY_SUCCESS,
  SELECT_HEALTH_PLAN,
  SELECT_HEALTH_PLAN_FAILURE,
  SELECT_HEALTH_PLAN_SUCCESS,
  SET_HOUSEHOLD_EXTERNAL_ID,
  SET_SELECTED_MEMBER_IDS,
  WAIVE_HEALTH_COVERAGE,
  SELECT_HEALTH_PLAN_STORE_ONLY,
} from './constants';
import { CommercialRoutesReducerState } from './reducer';

export function changeFormValue(value: string | EnteredPlan, name: keyof CommercialRoutesReducerState) {
  return {
    type: CHANGE_FORM_VALUE,
    value,
    name,
  };
}

export function postIncentiveSurveySuccess(response: API_TYPES.PostIncentiveSurveyResponse) {
  return {
    type: POST_INCENTIVE_SURVEY_SUCCESS,
    response,
  };
}
export function postIncentiveSurveyFailure(error: Error) {
  return {
    type: POST_INCENTIVE_SURVEY_FAILURE,
    error,
  };
}

export function getRecommendationsSuccess(response: GetRecommendationsResponse, storeHousehold = false) {
  return {
    type: GET_RECOMMENDATIONS_SUCCESS,
    response,
    storeHousehold,
  };
}

/*
`error` here is type Error, but TS won't let you type things you catch
*/
export function getRecommendationsFailure(error: Error) {
  return {
    type: GET_RECOMMENDATIONS_FAILURE,
    error,
  };
}

/**
 * This will do the following:
 *  * Set selected health plan in redux
 *  * Set household.enrollment on Comm Household
 *  * Set elections on JVP User.
 *
 *  Passing `{}` to waive a plan is *deprecated*. Use `WAIVE_HEALTH_COVERAGE` instead.
 */
export function selectHealthPlan(plan: Recommendation | Record<string, never>) {
  return {
    type: SELECT_HEALTH_PLAN,
    plan,
  };
}

/**
 * This only updates the health plan selection on redux store.
 * Pass `{}` to deselect plan.
 */
export function selectHealthPlanStoreOnly(plan: Recommendation | Record<string, never>) {
  return {
    type: SELECT_HEALTH_PLAN_STORE_ONLY,
    plan,
  };
}

export function waiveHealthCoverage() {
  return {
    type: WAIVE_HEALTH_COVERAGE,
  };
}

export function setHouseholdExternalId(externalId: string) {
  return {
    type: SET_HOUSEHOLD_EXTERNAL_ID,
    id: externalId,
  };
}

export function peopleLikeYouSuccess(response: PeopleLikeYou) {
  return {
    type: GET_PEOPLE_LIKE_YOU_SUCCESS,
    response,
  };
}

/*
`error` here is type Error, but TS won't let you type things you catch
*/
export function peopleLikeYouFailure(error: Error) {
  return {
    type: GET_PEOPLE_LIKE_YOU_FAILURE,
    error,
  };
}

export function setSelectedMemberIds(memberIds: string[]) {
  return {
    type: SET_SELECTED_MEMBER_IDS,
    memberIds,
  };
}

export function recommendWithEphemeralPlan(plan: EnteredPlan) {
  return {
    type: GET_RECOMMENDATIONS_EPHEMERAL_REQUEST,
    plan,
  };
}

export function getResultsPageData() {
  return { type: GET_RESULTS_PAGE_DATA };
}

export function selectHealthPlanSuccess(response: API_TYPES.UpdateHouseholdResponse) {
  return {
    type: SELECT_HEALTH_PLAN_SUCCESS,
    response,
  };
}

export function selectHealthPlanFailure(error: Error) {
  return {
    type: SELECT_HEALTH_PLAN_FAILURE,
    error,
  };
}

export function getHouseholdIdByExternalIdSuccess(response: string | null) {
  return {
    type: GET_HOUSEHOLD_ID_BY_EXTERNAL_ID_SUCCESS,
    response,
  };
}

export function getHouseholdIdByExternalIdError(error: Error) {
  return {
    type: GET_HOUSEHOLD_ID_BY_EXTERNAL_ID_FAILURE,
    error,
  };
}
