import styled from 'styled-components';

import { media } from 'DesignLibrary/global';

interface HideProp {
  hide?: boolean;
}

export const Introduction = styled.div`
  padding: 0 2.5rem 1.75rem 1.25rem;
  background: var(--primary-white);

  h1,
  h2,
  h3,
  .paragraph,
  p {
    color: var(--text-black);
  }

  h2 {
    margin: var(--sizing-md) 0;
  }

  .accordion-head {
    font-size: 18px;
    padding-left: 0;
  }

  a {
    text-decoration: none;
  }

  h4 {
    margin-top: var(--sizing-lg);
    font-size: 24px;
  }
`;

export const Necessities = styled.div`
  margin: 0.5rem 0 0.5rem 0;
  max-width: 90%;
  display: flex;
  flex-flow: row wrap;
  padding-left: 0;

  ${media.WIDEDESKTOP`
    max-width: 70%;
  `}

  a {
    margin: 0 var(--sizing-xs) var(--sizing-xs) 0;
  }
`;

export const FormWrapper = styled.div`
  padding: 0.75rem 1.25rem;
  background: #f4f4f6;

  #continue-btn {
    margin: 2.5rem 0 1rem 0;
    padding: 0 1.5rem;
    height: 48px;
    width: fit-content;
    background: var(--colors-yellow-100);
    border: 1px solid var(--colors-yellow-100);
    color: var(--text-black);
    text-transform: none;
    font-size: 16px;

    &:after{
      display: none;
    }

    &:hover {
      background: var(--colors-yellow-200) !important;
      border-color: var(--colors-yellow-300) !important;
      color: var(--text-black) !important;

      &:after {
        display none;
      }
    }
  }

  .subject-to-deductible {
    max-width: 85px;
  }

  .remove-btn {
    color: var(--primary-blue);
    font-size: 14px;
  }

  .formInput {
    background: white;
    border-radius: 8px;
    max-width: 40%;

    ${media.DESKTOP`
      max-width: 22%;
    `}
  }

  .removal {
    color: var(--primary-blue);

    .paragraph {
      margin-top: 0;
    }
  }

  h1 {
    margin: 1.5rem 0 1rem 0;
    font-size: 26px;
  }

  h2 {
    font-size: 18px;
    max-width: 36rem;
    margin-top: var(--sizing-xl);
    font-weight: 700;

    &:first-of-type {
      margin-top: 0;
    }
  }

  h4 {
    font-size: 16px;
    margin: 0 0 0.8rem 0;
  }

  .pseudo-checkbox {
    height: auto !important;
    min-height: 42px;
  }
`;

export const QuestionWrapper = styled.div`
  margin: 0 0 2rem 0;
  display: block;

  p {
    font-size: 18px;
  }

  .prefix {
    margin-right: 1rem;
  }

  .tooltip-icon,
  .tooltip {
    margin: 0;
    color: var(--text-black);
  }
`;

export const InputWrapper = styled.div<HideProp>`
  display: ${({ hide }) => (hide ? 'none' : 'flex')};
  align-items: center;
  justify-content: flex-start;
  margin: 1rem 0;

  .toggle-text {
    cursor: pointer;
  }

  > * {
    margin-right: 20px;
  }
`;

export const CheckWrapper = styled.div`
  width: fit-content;
  margin: 0.5rem 0;

  .pseudo-checkbox {
    .paragraph {
      font-size: 18px;
    }
    border: 0;
  }

  .checkbox-label-spacing {
    margin-left: var(--sizing-xs);
  }
`;
