import React, { FC } from 'react';

import { TextField } from 'Containers/App/types';
import { BulletStyle } from 'Containers/HsaPage/YourContributions/styled';
import { IconTypes, Paragraph } from 'DesignLibrary/atoms';
import { IconBlock } from 'DesignLibrary/molecules/IconBlock';
import RichText from 'Shared/RichText';
import { toDollars } from 'Utils/helpers';

interface HsaBulletProps {
  icon: IconTypes;
  field: TextField;
  employerHsaContribution?: number;
  employerHsaPercentageMatch?: number;
  dataTestId?: string;
}

export const HsaBullet: FC<HsaBulletProps> = ({
  icon,
  field,
  employerHsaContribution,
  employerHsaPercentageMatch = null,
  dataTestId = null,
}) => {
  const getVars = () => {
    if (employerHsaPercentageMatch) {
      return [employerHsaPercentageMatch, toDollars(employerHsaContribution)];
    }
    if (employerHsaContribution) {
      return [toDollars(employerHsaContribution)];
    }
    return null;
  };

  return (
    <BulletStyle data-testid={dataTestId}>
      <IconBlock color="blue" shade={100} icon={icon} />
      <Paragraph color="--text-black">
        <RichText noWrapper field={field} vars={getVars()} />
      </Paragraph>
    </BulletStyle>
  );
};
