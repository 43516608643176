import styled from 'styled-components';

import { media } from 'DesignLibrary/global';
import { focusSingle, hideAccessibleCheckmark } from 'DesignLibrary/utils';

export const EditableBalance = styled.div`
  padding: var(--sizing-sm);
  ${media.TABLET`padding: var(--sizing-sm) var(--sizing-md) var(--sizing-md) var(--sizing-md);`}

  .question-wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--sizing-md);

    ${media.TABLET`
      align-items: stretch;
      flex-direction: row;
      gap: var(--sizing-lg);
    `}
  }

  .button-wrapper {
    margin-top: var(--sizing-md);

    ${media.TABLET`
      display: flex;
      justify-content: flex-end;
    `}

    button {
      width: 100%;
      ${media.TABLET`width: var(--sizing-4xl);`}
    }
  }
`;

export const Question = styled.div`
  border-left: 3px solid var(--border-gray);
  padding: 4px 0 4px var(--sizing-sm);
  flex-shrink: 0;

  ${media.TABLET`
  min-width: var(--sizing-4xl);
  padding: 0 0 0 var(--sizing-md);
  `}

  &.persona-question {
    flex: 1;
  }

  .title {
    display: flex;
    align-items: center;
    gap: var(--sizing-sm);
    margin-bottom: var(--sizing-sm);
  }

  .input-wrapper {
    max-width: 360px;
    ${media.TABLET`max-width: var(--sizing-4xl);`}
  }

  .persona-toggle {
    min-width: auto;
    border: none;

    .persona-toggle-helper {
      display: flex;
      flex-direction: column;
      gap: var(--sizing-xs);

      ${media.TABLET`
        flex-direction: row;
      `}
    }
  }

  .note {
    margin-top: var(--sizing-xs);
  }
`;

export const AgeDisclaimer = styled.div`
  padding: var(--sizing-md);
`;

export const PersonaToggle = styled.label`
  display: block;
  min-width: 0;

  input[type='radio'] {
    ${hideAccessibleCheckmark}

    &:focus + .pseudo-answer {
      ${focusSingle};
    }

    &:checked + .pseudo-answer {
      border-color: var(--primary-blue);
      color: var(--text-black) !important;
      background: var(--colors-blue-50);
    }
  }

  .pseudo-answer {
    border: 2px solid var(--border-gray);
    background: var(--background-gray-light);
    border-radius: var(--border-radius-xs);
    padding: var(--sizing-sm);

    .paragraph + .paragraph {
      margin-top: var(--sizing-xs);
    }
  }
`;

export const SpinnerContainer = styled.div`
  position: absolute;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--primary-white);
`;
