import styled, { keyframes } from 'styled-components';

interface BadgeContainerProps {
  type: 'selected' | 'auto_enrolled';
  size: 'small' | 'normal' | 'large';
}

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const BadgeContainer = styled.div<BadgeContainerProps>`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${({ size }) => {
    switch (size) {
      case 'small':
        return '50px';
      case 'normal':
        return '58px';
      default:
        return '70px';
    }
  }};
  height: ${({ size }) => {
    switch (size) {
      case 'small':
        return '50px';
      case 'normal':
        return '58px';
      default:
        return '70px';
    }
  }};
  z-index: 0;

  .check {
    z-index: 2;

    svg {
      display: block;
      fill: var(--primary-white);
    }
  }

  .background {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    z-index: 1;
    left: 0;
    top: 0;
    animation: ${rotate} 6s 0s linear infinite;

    svg {
      fill: var(--primary-blue);
      width: 100%;
      height: 100%;
    }
  }

  &:after {
    display: block;
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 86%;
    height: 86%;
    transform: translate(-50%, -50%);
    background: var(--primary-blue);
    border-radius: 24rem;
    box-shadow: var(--shadow-badge);
  }
`;
