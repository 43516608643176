import React, { FC } from 'react';

import { useTextContext } from 'Contexts/textContext';

import { LabelInputStyle } from './styled';
import { Label, Paragraph } from '../../atoms/typography/index';

export interface LabelInputProps extends React.HTMLAttributes<HTMLDivElement> {
  label: string;
  id: string;
  errorText?: string;
  error?: boolean;
  children;
}

const LabelInput: FC<LabelInputProps> = ({
  label,
  id,
  children,
  error = false,
  errorText,
  ...rest
}): JSX.Element => {
  const { retrieveContentfulData } = useTextContext();
  const defaultErrorText =
    errorText || retrieveContentfulData<string>('profile_section.error_text.invalidZipCodeInput');

  return (
    <LabelInputStyle error={error} {...rest}>
      <Label id={id}>{label}</Label>
      {children}
      {error && (
        <Paragraph size="mini" color="--primary-red" data-testid="label-error">
          {defaultErrorText}
        </Paragraph>
      )}
    </LabelInputStyle>
  );
};

export default LabelInput;
