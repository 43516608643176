import React, { useContext, useState } from 'react';

import { DoctorType } from 'Types/entities';

export const useProviderSelectionContext = () => useContext(ProviderSelectionContext);

export interface ProviderSelectionContextType {
  providerDetails: DoctorType[];
  filteredProviders: { [key: string]: { inNetwork: DoctorType[]; outOfNetwork: DoctorType[] } };
  setFilteredProviders: React.Dispatch<
    React.SetStateAction<{ [key: string]: { inNetwork: DoctorType[]; outOfNetwork: DoctorType[] } }>
  >;
  setProviderDetails: React.Dispatch<React.SetStateAction<DoctorType[]>>;
  onOpenProviderModal: () => void;
}

export interface ProviderSelectionProviderProps {
  defaultProviderDetails?: DoctorType[];
  defaultFilteredProviders?: ProviderSelectionContextType['filteredProviders'];
  children: JSX.Element;
  onOpenProviderModal: () => void;
}

export const defaultContext: ProviderSelectionContextType = {
  providerDetails: [],
  filteredProviders: {},
  setFilteredProviders: () => {},
  setProviderDetails: () => {},
  onOpenProviderModal: () => {},
};

export const ProviderSelectionContext: React.Context<ProviderSelectionContextType> =
  React.createContext(defaultContext);

export const ProviderSelectionProvider = ({
  defaultProviderDetails = [],
  defaultFilteredProviders = {},
  children,
  onOpenProviderModal,
}: ProviderSelectionProviderProps) => {
  const [providerDetails, setProviderDetails] = useState<DoctorType[]>(defaultProviderDetails);
  const [filteredProviders, setFilteredProviders] =
    useState<ProviderSelectionContextType['filteredProviders']>(defaultFilteredProviders);
  return (
    <ProviderSelectionContext.Provider
      value={{
        providerDetails,
        filteredProviders,
        setFilteredProviders,
        setProviderDetails,
        onOpenProviderModal,
      }}
    >
      {children}
    </ProviderSelectionContext.Provider>
  );
};
