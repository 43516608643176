export enum SERVICE_TYPES {
  PRIMARY_CARE = 'primaryCare',
  SPECIALIST_CARE = 'specialistCare',
}

export enum DRUG_TYPES {
  GENERIC_DRUG = 'genericDrug',
  BRAND_DRUG = 'brandDrug',
  NON_FORMULARY_DRUG = 'nonFormularyDrug',
  SPECIALTY_DRUG = 'specialtyDrug',
}
