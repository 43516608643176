import styled, { css } from 'styled-components';

import { H6Style } from 'DesignLibrary/atoms';
import { breakpoint } from 'DesignLibrary/global';
import { cardStyling } from 'DesignLibrary/utils';

interface ContainerProps {
  singleColumn: boolean;
}

export const Container = styled.div<ContainerProps>`
  ${cardStyling}
  padding: var(--sizing-sm);
  gap: var(--sizing-sm);

  ${breakpoint.TABLET} {
    display: grid;
    grid-template-columns: minmax(240px, 320px) 1fr;
    padding: var(--sizing-sm);
  }

  ${({ singleColumn }) =>
    singleColumn &&
    css`
      ${breakpoint.TABLET} {
        grid-template-columns: 1fr;
      }
    `}
`;

interface TitleContainerProps {
  color: string;
}
export const TitleContainer = styled.div<TitleContainerProps>`
  width: 100%;
  position: relative;
  background: ${({ color }) => `var(--colors-${color}-100)`};
  border: 2px solid var(--primary-blue);
  border-radius: var(--border-radius-xs);
  padding: var(--sizing-sm);

  ${breakpoint.TABLET} {
    padding: var(--sizing-md);
  }

  .cap-title {
    ${H6Style('--primary-blue')}
  }

  .title-type {
    font-weight: 900 !important;
  }
`;

export const NumbersContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: var(--border-radius-xs);
    padding: var(--sizing-sm);

    &:nth-child(2n + 1) {
      background: var(--background-gray-light);
    }
  }

  .centered {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .paragraph,
    p {
      text-align: center;

      b {
        color: var(--text-black);
      }
    }
  }
`;
