import React, { useState } from 'react';

import { ALL_SELECTABLE_PLAN_TYPES } from 'Containers/OverviewPage/constants';
import { InsuranceType } from 'Containers/ViewBenefitPage/types';
import { useTextContext } from 'Contexts/textContext';
import { Button } from 'DesignLibrary/atoms/Button';
import { useViewport } from 'DesignLibrary/context';
import Text from 'Shared/Text';
import { SupplementalPlan } from 'Types/entities';
import { AriaHiddenAlert } from 'Utils/accessibility';

export interface PlanButtonsProps {
  plan: SupplementalPlan;
  idx: number;
  insuranceType: InsuranceType;
  isSelected: boolean;
  isAutoEnrolled: boolean;
  isEligible: boolean;
  hasNoSelection: boolean;
  handleSelect: (e) => void;
  handleRemovePlan: (insuranceType: InsuranceType) => void;
  handleTrackPdfView: (e) => void;
}

const PlanButtons = ({
  plan,
  idx,
  insuranceType,
  isSelected,
  isAutoEnrolled,
  isEligible,
  hasNoSelection,
  handleSelect,
  handleRemovePlan,
  handleTrackPdfView,
}: PlanButtonsProps) => {
  const [hiddenAlert, setHiddenAlert] = useState<string | null>(null);
  const { device } = useViewport();
  const { locale } = useTextContext();
  const pdf = locale === 'en-US' ? plan.pdf_url : plan.pdf_url_spanish || plan.pdf_url;

  const isNonSelectableBenefit = !ALL_SELECTABLE_PLAN_TYPES.includes(insuranceType);

  const renderPdfButton = () => {
    if (pdf) {
      return (
        <Button
          iconRight="ArrowUpRight"
          onClick={handleTrackPdfView}
          url={pdf}
          size={device !== 'mobile' ? 'normal' : 'small'}
          stretch={device === 'mobile'}
          role="link"
          aria-describedby={`${plan.external_id}-title`}
        >
          {isNonSelectableBenefit ? (
            <Text field="benefits_section.button_text.learnMorePdf" />
          ) : (
            <Text field="benefits_section.button_text.planDetails" />
          )}
        </Button>
      );
    }
    return null;
  };

  return (
    <>
      <AriaHiddenAlert>{hiddenAlert}</AriaHiddenAlert>
      {renderPdfButton()}

      {isAutoEnrolled ? (
        <Button
          buttonType="primary"
          className="auto-enroll-tag"
          color="blue"
          stretch
          disabled
          isDisabled
          size={device !== 'mobile' ? 'normal' : 'small'}
          iconLeft="Check"
          onClick={() => null}
        >
          <Text field="benefits_section.auto_enrolled_info.tipTitle" />
        </Button>
      ) : isSelected ? (
        <Button
          stretch
          id={`remove-${insuranceType}-btn`}
          data-testid={`remove-${insuranceType}-btn`}
          onClick={() => {
            handleRemovePlan(insuranceType);
            setHiddenAlert(`removed plan ${plan.name}`);
          }}
          size={device !== 'mobile' ? 'normal' : 'small'}
          iconLeft="X"
          // defined in <ExpandablePlanCard>
          aria-describedby={`${plan.external_id}-title`}
        >
          <Text field="benefits_section.button_text.unselectPlan" />
        </Button>
      ) : (
        !hasNoSelection &&
        (isEligible ? (
          <Button
            buttonType="primary"
            stretch
            iconLeft="PlusCircle"
            id={`select-${insuranceType}-${idx}-btn`}
            data-testid={`select-${insuranceType}-${idx}-btn`}
            onClick={(planToSelect) => {
              handleSelect(planToSelect);
              setHiddenAlert(`Selected plan ${plan.name}`);
            }}
            size={device !== 'mobile' ? 'normal' : 'small'}
            // defined in <ExpandablePlanCard>
            aria-describedby={`${plan.external_id}-title`}
          >
            <Text field="benefits_section.button_text.choosePlan" />
          </Button>
        ) : (
          <Button
            stretch
            disabled
            isDisabled
            className="ineligible-btn"
            data-testid="ineligible-btn"
            size={device !== 'mobile' ? 'normal' : 'small'}
            onClick={() => null}
          >
            <Text field="benefits_section.button_text.notEligible" />
          </Button>
        ))
      )}
    </>
  );
};

export default PlanButtons;
