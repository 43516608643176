import { useAuth0 } from '@auth0/auth0-react';
import { Stack } from '@chakra-ui/react';
import React from 'react';

import SignupButton from 'Containers/Auth/SignupButton';
import { Button, Paragraph } from 'DesignLibrary/atoms';
import Text from 'Shared/Text';

import { ContinueContainer } from './styled';

export interface GetStartedButtonProps {
  isAlexIdEnabled: boolean;
  isLoading: boolean;
  handleNext?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  forceAlexID: boolean;
  getStartedButtonRef: any;
  getStartedButtonAccessibilityProps: any;
}

const GetStartedButton = ({
  isAlexIdEnabled,
  isLoading,
  handleNext,
  forceAlexID,
  getStartedButtonRef = null,
  getStartedButtonAccessibilityProps = {},
}: GetStartedButtonProps) => {
  const { isAuthenticated } = useAuth0();

  if (!isAuthenticated && isAlexIdEnabled) {
    return (
      <Stack gap="6">
        <Stack>
          <SignupButton
            buttonType="primary"
            id="login-signup-btn"
            className="pingdom-get-started"
            iconRight="CaretRight"
            isLoading={isLoading}
            stretch
            justify="space-between"
          >
            <Text field="unified_landing_page.hero.login_or_signup_button" />
          </SignupButton>
          <Paragraph size="small" color="--text-gray">
            <Text field="unified_landing_page.hero.login_signup_description" />
          </Paragraph>
        </Stack>

        {!forceAlexID && (
          <Stack>
            <Button
              ref={getStartedButtonRef}
              id="continue-as-guest-btn"
              data-testid="continue-as-guest-btn"
              className="pingdom-get-started"
              iconRight="CaretRight"
              onClick={(e) => handleNext && handleNext(e)}
              stretch
              justify="space-between"
              isLoading={isLoading}
              {...getStartedButtonAccessibilityProps}
            >
              <Text field="unified_landing_page.hero.continue_as_guest_button" />
            </Button>
            <Paragraph size="small" color="--text-gray">
              <Text field="unified_landing_page.hero.continue_as_guest_description" />
            </Paragraph>
          </Stack>
        )}
      </Stack>
    );
  }

  return (
    <ContinueContainer>
      <Button
        ref={getStartedButtonRef}
        buttonType="primary"
        id="get-started-btn"
        className="pingdom-get-started"
        iconRight="CaretRight"
        onClick={(e) => handleNext && handleNext(e)}
        stretch
        justify="space-between"
        isLoading={isLoading}
        {...getStartedButtonAccessibilityProps}
      >
        <Text field="unified_landing_page.hero.cta_button" />
      </Button>
    </ContinueContainer>
  );
};

export default GetStartedButton;
