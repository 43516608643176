import React, { useEffect, useState } from 'react';

import { Member, MemberSectionConfigFields } from 'Containers/ProfilePage/types';
import { MemberCard } from 'ContentfulDefaults/types/profile';
import { useProfileContext } from 'Contexts/profileContext';
import { useTextContext } from 'Contexts/textContext';
import { Icon } from 'DesignLibrary/atoms';

import { AddMemberButton, AddMemberContainer } from './styled';

export interface AddMemberButtonsProps {
  policyholder: Member;
  spouse: Member;
  dependents: Member[];
  isOpen: {
    policyholder: boolean;
    spouse: boolean;
    dependents: string[];
  };
  memberConfig: MemberSectionConfigFields;
  isIncentiveLoading: boolean;
  handleOpen: (relationship: string, external_id: string) => void;
}

export const AddMemberButtons = ({
  policyholder,
  spouse,
  dependents,
  isOpen,
  memberConfig,
  isIncentiveLoading,
  handleOpen,
}: AddMemberButtonsProps) => {
  const { retrieveContentfulData } = useTextContext();
  const { collectIsSpouseOrDomesticPartner } = memberConfig;
  const { shouldUpdateIncentives } = useProfileContext();
  const profileCard = retrieveContentfulData<MemberCard>('profile_section.member');
  const [isDisabled, setIsDisabled] = useState(false);

  // Disable spouse/dependent button while incentives call is being made & update incentives required
  useEffect(() => {
    if (shouldUpdateIncentives || isIncentiveLoading) {
      setIsDisabled(true);
    }

    let timer: number;

    if (!shouldUpdateIncentives && !isIncentiveLoading) {
      timer = window.setTimeout(() => setIsDisabled(false), 1000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [shouldUpdateIncentives, isIncentiveLoading]);

  // Dependent button should be disabled if Policyholder OR Spouse OR Other Dependent is invalid or incomplete
  const getIsDisabled = (relationship, member) => {
    if (member.pristine) {
      if (!policyholder.isValid) {
        return true;
      }
      if (relationship === 'spouse') {
        const incompleteDependents = dependents.filter((dependent) => !dependent.isComplete);
        const invalidDependents = dependents.filter((dependent) => !dependent.isValid);
        if (incompleteDependents.length > 1 || invalidDependents.length > 1) return true;
      }
      if (relationship === 'dependent') {
        // disable add dependent if spouse is invalid
        if (isOpen.spouse && !spouse.isValid) {
          return true;
        }
        // disable add dependent if OTHER dependent is invalid
        let isOtherDependentInvalid = false;
        dependents.forEach((i) => {
          if (isOpen.dependents.includes(i.external_id) && !i.isValid) isOtherDependentInvalid = true;
        });
        return isOtherDependentInvalid;
      }
    }
    return false;
  };

  const renderPolicyholderButton = () =>
    isOpen.policyholder ? null : (
      <AddMemberButton
        className="member-view add-policyholder"
        onClick={() => handleOpen('policyholder', '')}
        textColor="--text-black"
      >
        <div className="icon-left">
          <Icon type="Plus" />
        </div>
        {profileCard.add_policyholder_button}
      </AddMemberButton>
    );

  const renderSpouseButton = () =>
    isOpen.spouse ? null : (
      <AddMemberButton
        className="add-spouse"
        onClick={() => handleOpen('spouse', '')}
        disabled={getIsDisabled('spouse', spouse) || isDisabled}
        isDisabled={getIsDisabled('spouse', spouse) || isDisabled}
        textColor="--text-black"
      >
        <div className="icon-left">
          <Icon type="Plus" />
        </div>
        {collectIsSpouseOrDomesticPartner
          ? profileCard.add_spouse_or_domestic_partner_button
          : profileCard.add_spouse_button}
      </AddMemberButton>
    );

  const renderDependentsButtons = () =>
    dependents.map((i) =>
      isOpen.dependents.includes(i.external_id) ? null : (
        <AddMemberButton
          key={`add-dependent-btn-${i.external_id}`}
          className="add-dependent"
          onClick={() => handleOpen('dependent', i.external_id)}
          isDisabled={getIsDisabled('dependent', i) || isDisabled}
          disabled={getIsDisabled('dependent', i) || isDisabled}
          textColor="--text-black"
        >
          <div className="icon-left">
            <Icon type="Plus" />
          </div>
          {profileCard.add_dependent_button}
        </AddMemberButton>
      ),
    );
  return (
    <AddMemberContainer>
      {renderPolicyholderButton()}
      <div>
        {renderSpouseButton()}
        {renderDependentsButtons()}
      </div>
    </AddMemberContainer>
  );
};
