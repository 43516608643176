import styled, { css } from 'styled-components';

import { FadeOutKeyframes } from 'DesignLibrary/keyframes';

interface FooterProps {
  isNextClicked: boolean;
}

export const FooterContainer = styled.footer<FooterProps>`
  padding: var(--sizing-md) 0;

  ${({ isNextClicked }) =>
    isNextClicked &&
    css`
      & {
        opacity: 1;
        animation: ${FadeOutKeyframes} 1s 0.25s forwards;
      }
    `}

  & > * + * {
    margin-top: var(--sizing-md);
  }

  .paragraph,
  p {
    max-width: 800px;
  }

  a {
    color: var(--text-black);
    font-weight: 600;
    text-decoration: underline;
  }
`;
