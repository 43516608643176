import styled from 'styled-components';

export const DisclaimerContainer = styled.div`
  padding: var(--sizing-sm);
  background: var(--primary-yellow);

  button {
    margin-top: var(--sizing-sm);
  }
`;
