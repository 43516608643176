import styled from 'styled-components';

export const EligibilitySticker = styled.div`
  display: flex;
  align-items: center;
  gap: var(--sizing-xs);
  margin-bottom: var(--sizing-xs);
`;

export const EmployerContribution = styled.div`
  padding: 4px var(--sizing-xs);
  background: var(--background-gray);
  border-radius: var(--sizing-xs);
`;
