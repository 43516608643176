import { ContentfulPlySection } from 'ContentfulDefaults/types/ply';

export const ply: { people_like_you: ContentfulPlySection } = {
  people_like_you: {
    contentful_name: 'People Like You (DEFAULT DO NOT CHANGE)',
    button_text: {
      results: 'Show My Results',
      backToResults: 'Back to results',
    },
    plan_ranking: {
      texts: [
        'Analyzing all eligible plans',
        'Comparing plans for people like you',
        'Finalizing plan rankings',
      ],
      title: 'Ranking your plans',
    },
    people_like_you_title: 'Finding People Like You ',
    office_visits: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'had ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [{ type: 'bold' }],
              value: 'one or more office visits',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value: ' over the plan year.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    emergency_care: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'received',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [{ type: 'bold' }],
              value: ' urgent or emergency care',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value: ' over the plan year.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    claim_rate: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'were ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [{ type: 'bold' }],
              value: 'admitted to a hospital ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value: 'over the plan year.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    profile_summary_title: 'Compiling your profile answers',
    profile_answers: {
      spouse: 'You and Spouse/Partner',
      noSpouse: 'Covering You',
      dependent: {
        one: '1 Dependent',
        many: '{x} Dependents',
      },
      pcpVisits: {
        one: '1 PCP visit',
        many: '{x} PCP visits',
      },
      pregnancy: 'Expecting pregnancy',
      riskAversion: {
        low: 'Low risk aversion',
        high: 'High risk aversion',
        moderate: 'Moderate risk aversion',
      },
      capacityToPay: {
        lt_500: 'Could afford less than $500',
        gt_6000: 'Could afford more than $6,000',
        '500_1000': 'Could afford $500 to $1,000',
        '1000_2000': 'Could afford $1,000 to $2,000',
        '2000_3000': 'Could afford $2,000 to $3,000',
        '3000_4000': 'Could afford $3,000 to $4,000',
        '4000_6000': 'Could afford $4,000 to $6,000',
      },
      inpatientDays: {
        one: '1 Inpatient day',
        many: '{x} Inpatient days',
      },
      prescriptions: {
        one: '1 Prescription',
        many: '{x} Prescriptions',
      },
      specialistVisits: {
        one: '1 Specialist visit',
        many: '{x} Specialist visits',
      },
      mentalHealthVisits: {
        one: '1 Mental Health provider visit',
        many: '{x} Mental Health provider visits',
      },
    },
    modal_title: 'Explore Our Predictions',
    spending_icons: [
      {
        plyKey: 'office_visits',
        iconText: 'Office Visits',
        inlineText: 'office visits',
      },
      {
        plyKey: 'labs_diagnostics',
        iconText: 'Labs',
        inlineText: 'labs and diagnostics',
      },
      {
        plyKey: 'emergency_urgent_care',
        iconText: 'Emergency',
        inlineText: 'emergency care',
      },
      {
        plyKey: 'inpatient',
        iconText: 'Hospital',
        inlineText: 'hospital visits',
      },
    ],
    spending_title: 'Your Potential Spending',
    spending_explanatory_text:
      'Take a look at how likely you are to use different healthcare services. There are 5 different categories we look at.',
    service_utilization_text: 'Percentage of people like you who had {x}:',
    cost_distribution_bar_text: {
      nodeType: 'document',
      data: {},
      content: [
        {
          nodeType: 'paragraph',
          content: [
            {
              nodeType: 'text',
              value: 'For those ',
              marks: [],
              data: {},
            },
            {
              nodeType: 'text',
              value: '{x}',
              marks: [{ type: 'code' }],
              data: {},
            },
            {
              nodeType: 'text',
              value: '% that did, total medical bills for these services ranged\nfrom ',
              marks: [],
              data: {},
            },
            {
              nodeType: 'text',
              value: '$',
              marks: [{ type: 'bold' }],
              data: {},
            },
            {
              nodeType: 'text',
              value: '{x}',
              marks: [{ type: 'code' }, { type: 'bold' }],
              data: {},
            },
            {
              nodeType: 'text',
              value: ' ',
              marks: [{ type: 'bold' }],
              data: {},
            },
            {
              nodeType: 'text',
              value: 'to ',
              marks: [],
              data: {},
            },
            {
              nodeType: 'text',
              value: '$',
              marks: [{ type: 'bold' }],
              data: {},
            },
            {
              nodeType: 'text',
              value: '{x}',
              marks: [{ type: 'code' }, { type: 'bold' }],
              data: {},
            },
            {
              nodeType: 'text',
              value: '. The median cost was ',
              marks: [],
              data: {},
            },
            {
              nodeType: 'text',
              value: '$',
              marks: [{ type: 'bold' }],
              data: {},
            },
            {
              nodeType: 'text',
              value: '{x}',
              marks: [{ type: 'code' }, { type: 'bold' }],
              data: {},
            },
            {
              nodeType: 'text',
              value: '.',
              marks: [],
              data: {},
            },
          ],
          data: {},
        },
      ],
    },
    healthcare_usage_title: 'Your Healthcare Usage',
    healthcare_usage_description: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: "To better understand your risk, let's explore information about how ",
              nodeType: 'text',
            },
            {
              data: {},
              marks: [{ type: 'bold' }],
              value: 'people like you',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value: " use health care. We've broken their yearly usage patterns into 3 tiers as seen below.",
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    healthcare_usage_range_descriptions: {
      low: {
        tooltip: 'Less than $1,000 in total medical bills.',
        title: 'Low',
        description:
          'This means that your total medical bills for the year are likely to be less than $1,000. When you are more likely to have a low level of medical spending, plans with lower premiums will be a better value.',
      },
      high: {
        tooltip: 'More than $10,000 in total medical bills.',
        title: 'High',
        description:
          'This means that your total medical bills for the year are likely to be $10,000 or more.  When you are more likely to have a high level of medical spending, plans that limit your out-of-pocket cost will be a better match for you, but the price still needs to be right for it to be a good value.',
      },
      moderate: {
        tooltip: '$1,000 - $10,000 in total medical bills.',
        title: 'Moderate',
        description:
          'This means that your total medical bills for the year are likely to be between $1,000 and $10,000.  When you are more likely to have a moderate level of medical spending, the best plan for you will strike a balance between premium savings and coverage.',
      },
    },
    healthcare_usage_distribution_text: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'This means you are most likely to fall into the ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [{ type: 'code' }, { type: 'bold' }],
              value: '{x}',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [{ type: 'bold' }],
              value: ' ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value: 'grouping. In other words, you are most likely to use ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [{ type: 'code' }, { type: 'bold' }],
              value: '{x} ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value:
                'in healthcare services in the plan year.\n\nKeep in mind, the dollar values that we show represent the cost of medical care before insurance kicks in.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    healthcare_usage_spending_groups: {
      low: 'less than $1,000 ',
      high: 'more than $10,000 ',
      moderate: '$1,000 - $10,000 ',
    },
    modal_overview_text: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'We all need health insurance to help us pay our medical bills,\nespecially bills for expensive medical care that we might not have planned for.\nTo put it another way, health insurance helps you manage risk,\nand you need to understand your risk if you want to know how much insurance you really need.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    modal_button_primer_text:
      'Anticipating healthcare needs can be hard. Picwell predicts a range of possible outcomes to find the best plans for you.',
    overview_title: 'How we make our predictions',
    user_icon_text: 'First, we identify people like you who have similar healthcare usage.',
    costs_icon_text: 'Next, we understand their spending across different healthcare services.',
    ranks_icon_text: 'Finally, results are ranked based on your provided preferences.',
    overview_explanatory_text: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Picwell helps you find the best plan by analyzing data from People Like You to predict your medical costs and risk.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: "Here's how we process the information you provided in the profile section.",
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    overview_learn_more: 'To learn more about how this works, read on.',
    rankings_title: 'Your Results',
    rankings_text: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Now that we have a picture of your healthcare usage, we can estimate\n  your ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [{ type: 'bold' }],
              value: 'OOP costs in a typical year',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value: ' as\n  well as your ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [{ type: 'bold' }],
              value: 'risk of high OOP spending',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value: ' under your available plans.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Finally, we combine this information with the ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [{ type: 'bold' }],
              value: 'plan premiums',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value: ' and your ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [{ type: 'bold' }],
              value: 'financial preferences',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value: ' in order to score and rank your plan options.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    modal_button_text: 'Explore Our Predictions',
    tooltips: {
      medicalSpending:
        'The total price of medical care, including what you pay and what your insurance covers.',
      p25: '25th percentile: The least expensive quarter spend less than this.',
      p50: '50th percentile: Half spend less than this amount and half spend more.',
      p75: '75th percentile: The most expensive quarter spend more than this.',
    },
    service_utilization_caveat_text:
      'Keep in mind, this reflects the cost of service before insurance coverage.  Consult the plan details to understand how a particular plan will cover costs for this service.',
    spending_section_introduction:
      "Curious about what type of health care services people like you use? Here's how often people like you use different types of healthcare and how much they spend.",
  },
};

export default ply;
