import { Stack, Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import { GlobalReducerState } from 'app/reducers';
import { makeGetPlans } from 'Containers/CommercialRoutes/selectors';
import { makeSelectProfileField } from 'Containers/ProfilePage/selectors';
import { LocationSelect } from 'Containers/ResultPage/ProviderSelectionModal/LocationSelect';
import { NoProviderSkeleton } from 'Containers/ResultPage/ProviderSelectionModal/NoProviderSkeleton';
import { useProviderSelectionContext } from 'Contexts/providerSelectionContext';
import { useViewport } from 'DesignLibrary/context';
import {
  ProviderLocationProps,
  LocationProps,
  DoctorType,
  Recommendation,
  DoctorSummary,
} from 'Types/entities';
import { createProviderData } from 'Utils/helpers';
import { sendInsightsEvent } from 'Utils/insights';

import ProviderFooter from './ProviderFooter';
import ProviderHeader from './ProviderHeader';
import ProviderList from './ProviderList';
import ProviderSearch from './ProviderSearch';

export interface ProviderSelectionModalProps {
  isOpen: boolean;
  onClose: () => void;
}
export interface ProviderSelectionModalStateProps {
  zipcode: string;
  availablePlans: Recommendation[] | [];
}

export const ProviderSelectionModal = ({
  isOpen, // Flag to open the modal
  onClose, // Handler for closing the modal
  zipcode,
  availablePlans, // Object representing the available plans
}: ProviderSelectionModalProps & ProviderSelectionModalStateProps) => {
  const { device } = useViewport();
  const { providerDetails, setProviderDetails, setFilteredProviders } = useProviderSelectionContext();

  const [searchText, setSearchText] = useState<string>('');
  const [options, setOptions] = useState<ProviderLocationProps[]>([]);
  const [selectedProvider, setSelectedProvider] = useState<ProviderLocationProps>();
  const [showSearchPage, setShowSearchPage] = useState<boolean>(true);
  const [selectedLocation, setSelectedLocation] = useState<LocationProps>();
  const [isAdded, setIsAdded] = useState<boolean>(false);
  const [doctorList, setDoctorList] = useState<DoctorType[]>([]);

  const [isUpdated, setIsUpdated] = useState<boolean>(false);
  const [isEditLocation, setIsEditLocation] = useState<boolean>(false);
  const [zipcodeLocal, setZipcodeLocal] = useState<string>(zipcode || '');
  const [distance, setDistance] = useState<string>('5');

  // Get updated doctor list
  const getUpdatedDoctorList = (value: string) => {
    return providerDetails?.filter((doctor: DoctorType) => {
      return doctor.npi !== value;
    });
  };

  useEffect(() => {
    if (isAdded || isUpdated) {
      const list = isAdded ? doctorList : providerDetails;
      setIsAdded(false);
      setIsUpdated(false);
      // Add list of doctors with location
      const doctor = list?.find(
        (doc) =>
          doc.npi === selectedProvider!.value &&
          doc.locations?.some((loc) => loc.uuid === selectedLocation!.uuid),
      ) as DoctorType;

      if (doctor?.locations) {
        doctor.selected_location = doctor?.locations?.filter((loc) => loc.uuid === selectedLocation!.uuid)[0];
        setProviderDetails([...getUpdatedDoctorList(selectedProvider!.value), doctor as DoctorType]);
      }
    }
  }, [isUpdated, isAdded, selectedLocation, doctorList]);

  useEffect(() => {
    setSearchText('');
  }, [providerDetails]);

  const filterProviders = (providerDetails: DoctorType[]) => {
    availablePlans.forEach((recommendation: Recommendation) => {
      const inNetworkProviderList: DoctorType[] = [];
      const outOfNetworkProviderList: DoctorType[] = [];
      providerDetails.forEach((provider: DoctorType) => {
        if (
          provider.insurances?.some((insurance) =>
            recommendation.plan.docfinder_network_uuids?.includes(insurance.uuid),
          )
        ) {
          inNetworkProviderList.push(provider);
        } else {
          outOfNetworkProviderList.push(provider);
        }
      });
      setFilteredProviders((prev) => {
        return {
          ...prev,
          [recommendation.plan.external_id]: {
            inNetwork: inNetworkProviderList,
            outOfNetwork: outOfNetworkProviderList,
          },
        };
      });
    });
  };

  // Handler for close modal
  const onCloseModal = (e) => {
    const filteredProviderDetails: DoctorSummary[] =
      Array.isArray(providerDetails) && providerDetails?.length > 0
        ? providerDetails?.map((provider) => ({
            npi: provider.npi,
            first_name: provider.first_name,
            middle_name: provider.middle_name,
            last_name: provider.last_name,
            age: provider.age,
          }))
        : [];
    sendInsightsEvent(null, 'saved_and_continued_on_provider_search', {
      saved_doctors: filteredProviderDetails as DoctorSummary[],
    });
    e.preventDefault();
    setProviderDetails(providerDetails);
    filterProviders(providerDetails);
    setSearchText('');
    onClose();
  };

  // Add context values on modal open
  useEffect(() => {
    if (isOpen) {
      setProviderDetails(providerDetails);
      filterProviders(providerDetails);
    }
  }, [isOpen]);

  // Set context details
  useEffect(() => {
    if (providerDetails?.length > 0) {
      setProviderDetails(providerDetails);
      filterProviders(providerDetails);
    }
  }, [providerDetails]);

  // Handler to delete provider location
  const handleProviderDelete = (key: string) => {
    const newProviderList = providerDetails.filter((item: DoctorType) => {
      return key !== item.npi;
    });
    setProviderDetails(newProviderList);
    filterProviders(newProviderList);
    setIsEditLocation(false);
    sendInsightsEvent(null, 'provider_removed_from_list');
  };

  // Handler to edit provider location
  const handleProviderEdit = (provider: DoctorType) => {
    setSelectedProvider(createProviderData(provider));
    setShowSearchPage(false);
    setIsEditLocation(true);
  };

  useEffect(() => {
    setZipcodeLocal(zipcode); // Function to set zipcode from reducer
  }, [zipcode]);

  return (
    <div data-testid="provider-selection-modal">
      <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside" isCentered={device === 'mobile'}>
        <ModalOverlay />
        <ModalContent
          maxW="calc(100vw - 32px)"
          maxH={720}
          w="720px"
          h={['calc(100vh - 48px)', 'calc(100vh - 120px)']}
        >
          <ProviderHeader
            setOptions={setOptions}
            setSearchText={setSearchText}
            onClose={onClose}
            sendInsightsEvent={sendInsightsEvent}
          />
          <ModalBody pt={0} px={[4, 6]} display="flex" flexDir="column">
            {showSearchPage ? (
              <>
                <ProviderSearch
                  searchText={searchText}
                  setSearchText={setSearchText}
                  zipcodeLocal={zipcodeLocal}
                  setZipcodeLocal={setZipcodeLocal}
                  setDoctorList={setDoctorList}
                  options={options}
                  setOptions={setOptions}
                  setShowSearchPage={setShowSearchPage}
                  setSelectedProvider={setSelectedProvider}
                  distance={distance}
                  setDistance={setDistance}
                />
                {providerDetails.length <= 0 ? (
                  <NoProviderSkeleton />
                ) : (
                  <Stack py={4}>
                    {providerDetails?.map((provider: DoctorType) => (
                      <ProviderList
                        key={provider.npi}
                        location={provider.selected_location}
                        providerTypes={provider.provider_types}
                        label={[provider.first_name, provider.middle_name, provider.last_name]
                          .filter(Boolean)
                          .join(' ')}
                        handleProviderDelete={() => handleProviderDelete(provider.npi)}
                        handleProviderEdit={() => handleProviderEdit(provider)}
                      />
                    ))}
                  </Stack>
                )}
              </>
            ) : (
              <LocationSelect
                selectedProvider={selectedProvider}
                setShowSearchPage={setShowSearchPage}
                setSelectedProvider={setSelectedProvider}
                setIsAdded={setIsAdded}
                setIsUpdated={setIsUpdated}
                setSelectedLocation={setSelectedLocation}
                providerDetails={providerDetails}
                setSearchText={setSearchText}
                isEditLocation={isEditLocation}
                setIsEditLocation={setIsEditLocation}
              />
            )}
          </ModalBody>
          {showSearchPage && (
            // Component for Footer having tooltip and continue button
            <ProviderFooter onCloseModal={onCloseModal} />
          )}
        </ModalContent>
      </Modal>
    </div>
  );
};
const mapStateToProps = createStructuredSelector<GlobalReducerState, ProviderSelectionModalStateProps>({
  zipcode: makeSelectProfileField('zipcode'),
  availablePlans: makeGetPlans(),
});
const withConnect = connect(mapStateToProps);
export default compose(withConnect)(ProviderSelectionModal);
