import { HSAInteractiveSection } from 'ContentfulDefaults/types/hsa';

export const hsaInteractiveSection: HSAInteractiveSection = {
  contentful_name: '[DEFAULT] HSA Interactive Section',
  yearly_contribution_button: 'Yearly Contribution',
  yearly_contribution_chart_title: 'Yearly Contribution Chart',
  yearly_chart_legend: [
    {
      text: 'The health care costs that the insurance company does not pay for. In other words, when you get a medical bill, this is the amount that you are responsible for.',
      type: 'oop',
      title: 'Out-of-Pocket Estimate',
      underline: 'Out-of-Pocket Estimate',
    },
    {
      text: 'The amount your employer will contribute to your HSA.',
      type: 'employer',
      title: 'Employer Contribution',
      underline: 'Employer',
    },
    {
      text: 'The amount you will contribute throughout the year.',
      type: 'recommended',
      title: 'Your Contribution',
      underline: 'You',
    },
    {
      text: 'The estimated amount of medical expenses that are not covered through HSA contributions.',
      type: 'remaining',
      title: 'Remaining Expenses',
      underline: 'Remaining',
    },
  ],
  retirement_savings_button: 'Retirement Savings',
  retirement_savings_chart_title: 'Retirement Savings Chart',
  retirement_savings_chart_legend: [
    {
      text: '',
      type: 'projectedHsaBalance',
      title: 'Projected HSA Balance',
      underline: 'Projected HSA Balance',
    },
    {
      text: '',
      type: 'retirementHealthcareCosts',
      title: 'Retirement Healthcare Costs',
      underline: 'Retirement Healthcare Costs',
    },
  ],
  yearly_contribution: 'Yearly Contribution',
  per_paycheck_contribution: 'Per Paycheck Contribution',
  yearly_tax_savings: 'Yearly Tax Savings',
  yearly_will_this_cover_question: 'Will this cover my yearly costs?',
  coverage_statistic: {
    data: {},
    content: [
      {
        data: {},
        content: [
          {
            data: {},
            marks: [
              {
                type: 'bold',
              },
            ],
            value: 'This is enough to cover costs for ',
            nodeType: 'text',
          },
          {
            data: {},
            marks: [
              {
                type: 'code',
              },
              {
                type: 'bold',
              },
            ],
            value: '{x}',
            nodeType: 'text',
          },
          {
            data: {},
            marks: [
              {
                type: 'bold',
              },
            ],
            value: '% of people like you.',
            nodeType: 'text',
          },
        ],
        nodeType: 'paragraph',
      },
    ],
    nodeType: 'document',
  },
  yearly_coverage_detail_risky:
    'We highly recommend contributing more than this amount. This will increase your financial security and save more on taxes.',
  yearly_coverage_detail_moderate: 'Saving more will increase your financial safety and save more on taxes.',
  yearly_coverage_detail_safe: 'You can always contribute more (up to the max) to better protect yourself.',
  current_hsa_balance: 'Current HSA Balance',
  usage_type: 'Usage Type',
  retirement_coverage_detail_risky:
    'We highly recommend contributing more than this amount. This will increase your financial security and save more on taxes.',
  retirement_coverage_detail_moderate:
    'Saving more will increase your financial safety and save more on taxes.',
  retirement_coverage_detail_safe:
    'With this amount of savings, you are in pretty good shape. Remember, your HSA investments will continue to grow after you retire.',
  retirement_will_this_cover_question: 'Will this cover my costs?',
  spender_persona: 'Spender',
  super_saver_persona: 'Super Saver',
  hsa_balance_question: 'What is your current HSA balance?',
  hsa_balance_question_approximation: 'An approximation is okay here.',
  persona_question: 'What best describes how you use your HSA?',
  spender_persona_detail: "I use my HSA to cover medical bills and save anything that's left over.",
  super_saver_persona_detail:
    'I invest everything that I put into my HSA and pay for medical bills with other funds.',
  save_answers: 'Save Answers',
  tooltips: {
    yearly_contribution: {
      title: 'Yearly Contribution Tooltip',
      text: "This is how much you'll contribute this year.",
    },
    per_paycheck_contribution: {
      title: 'Per-Paycheck Contribution',
      text: "This is how much you'll contribute each paycheck.",
    },
    yearly_tax_savings: {
      title: 'Yearly Tax Savings',
      text: "This is your estimated tax savings from contributing to your HSA, which was calculated using the most common incremental rate if you didn't provide an estimated household income.",
    },
    current_hsa_balance: {
      title: 'Current HSA Balance',
      text: 'Enter your existing HSA balance, if applicable.',
    },
    usage_type: {
      title: 'Usage Type',
      text: 'Set this to represent how you plan to use your HSA.',
    },
    yearly_will_this_cover_question_short_term: {
      text: 'Picwell predicts if your expected contribution will cover your healthcare costs this year.',
    },
    yearly_will_this_cover_question_long_term: {
      text: 'Picwell predicts if your expected contribution will cover your healthcare costs at retirement.',
    },
  },
  accessibility: {
    edit_hsa_balance_button: 'Edit Current Hsa Balance',
    edit_usage_type_button: 'Edit Usage Type',
    select_retirement_savings: 'Selected retirement savings tab',
    select_yearly_contributions: 'Selected yearly contributions tab',
  },
  policyholder_age_disclaimer:
    'For anyone age {x} or older, this forecast is based on a single year of healthcare expenses.',
};
