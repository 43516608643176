import styled from 'styled-components';

import { media } from 'DesignLibrary/global';

export const GridContainer = styled.div`
  ${media.DESKTOP`
    display: grid;
    grid-template-columns: minmax(660px, auto) minmax(240px, 320px);
    grid-gap: var(--sizing-md);
  `}

  @media print {
    display: block;
    max-width: 640px;
  }

  .review-sidebar {
    border-top: var(--border);
    padding-top: var(--sizing-lg);
    margin-top: var(--sizing-lg);

    & > * + * {
      margin-top: var(--sizing-md);
    }

    ${media.TABLET`
      display: grid;
      gap: var(--sizing-md);
      grid-template-columns: 1fr 1fr;
      align-items: flex-start;

      & > * + * {
        margin-top: 0;
      }
    `}

    ${media.DESKTOP`
      display: block;
      border: 0;
      padding: 0;
      margin: 0;

      & > * + * {
        margin-top: var(--sizing-md);
      }
    `}

    @media print {
      display: none !important;
    }
  }
`;
