import { Box, Flex, Stack } from '@chakra-ui/react';
import React from 'react';

import { Paragraph } from 'DesignLibrary/atoms';

interface SectionWrapperProps {
  number: number;
  children: React.ReactNode;
}

interface NumberBlockProps {
  number: number;
  size?: 'sm' | 'lg';
}

export const NumberBlock = ({ number, size = 'sm' }: NumberBlockProps) => (
  <Flex
    w={size === 'sm' ? 12 : 14}
    h={size === 'sm' ? 12 : 14}
    align="center"
    justify="center"
    display="inline-flex"
    bg="var(--colors-blue-100)"
    borderBottomRadius={6}
  >
    <Paragraph weight="bold" size={size === 'sm' ? 'small' : 'normal'} color="--primary-blue">
      {number}
    </Paragraph>
  </Flex>
);

const SectionWrapper = ({ number, children }: SectionWrapperProps) => (
  <Stack gap={4} px={8} pb={8} borderTop="var(--border-thick)">
    <NumberBlock size="lg" number={number} />
    <Box>{children}</Box>
  </Stack>
);

export default SectionWrapper;
