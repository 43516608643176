import React, { FC, useEffect, useRef, KeyboardEvent } from 'react';

import { DropdownContainer, DropdownListItem } from './styled';
import { RadioPseudo } from '../../../atoms/inputs/RadioPseudo';
import { Paragraph } from '../../../atoms/typography';

export interface Option {
  display: string;
  description?: string;
  value: string;
}

export interface DropdownListProps {
  label?: string;
  options: Option[];
  selected: string;
  selectedIndex: number;
  focusedIndex: number;
  handleFocusChange: (index: number) => void;
  isDisabled?: boolean;
  handleChange: (e: React.MouseEvent<HTMLButtonElement>, value: string, index: number) => void;
  handleClose: (click: boolean) => void;
  horizontalPosition: 'left' | 'right';
  containerId: string;
}

export const DropdownList: FC<DropdownListProps> = ({
  label,
  options,
  selected,
  selectedIndex,
  focusedIndex,
  isDisabled,
  horizontalPosition,
  containerId,
  handleFocusChange,
  handleChange,
  handleClose,
}) => {
  const dropdownContainerRef = useRef<HTMLDivElement>(null);
  const optionsRefs = useRef<HTMLButtonElement[]>([]);

  const handleClickOutside = ({ target }) => {
    if (dropdownContainerRef.current && !dropdownContainerRef.current.contains(target)) {
      handleClose(true);
      // reset focusedIndex to selectedIndex when DropdownList is closed
      handleFocusChange(selectedIndex);
    }
  };

  useEffect(() => {
    // Manually set focus to the first dropdown option when list is rendered
    if (optionsRefs.current[selectedIndex]) optionsRefs.current[selectedIndex].focus();

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (optionsRefs.current[focusedIndex]) optionsRefs.current[focusedIndex].focus();
  }, [focusedIndex]);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>, value: string, i: number) => {
    if (!isDisabled && value !== selected) {
      handleChange(e, value, i);
      handleClose(false);
    }
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLButtonElement>) => {
    // get option index from id
    const target = e.target as HTMLButtonElement;
    const id = target.getAttribute('id');
    const index = id?.split('-')[1];
    const idx = index ? parseFloat(index) : 0;

    switch (e.key) {
      case 'Escape':
      case 'Esc':
        handleClose(false);
        // reset focusedIndex to selectedIndex when DropdownList is closed
        handleFocusChange(selectedIndex);
        break;
      case 'Home':
        handleFocusChange(0);
        break;
      case 'End':
        handleFocusChange(options.length - 1);
        break;
      case 'ArrowDown':
        if (optionsRefs.current[idx + 1] && idx + 1 < options.length) {
          handleFocusChange(idx + 1);
        }
        break;
      case 'ArrowUp':
        if (optionsRefs.current[idx - 1] && idx - 1 >= 0) {
          handleFocusChange(idx - 1);
        }
        break;
      default:
    }
  };

  const createOptionRef = (el: HTMLButtonElement, i: number) => {
    optionsRefs.current[i] = el;
  };

  return (
    <DropdownContainer
      ref={dropdownContainerRef}
      id={containerId}
      data-testid="dropdownList"
      horizontalPosition={horizontalPosition}
      role="listbox"
      tabIndex={-1}
    >
      {label && (
        <div className="label">
          <Paragraph size="mini">{label}</Paragraph>
        </div>
      )}
      <ul>
        {options.map((v, i) => {
          const { value, display, description } = v;
          const isSelected = value === selected;

          return (
            <li key={value}>
              <DropdownListItem
                id={`${value}-${i}`}
                data-testid={isSelected ? `${value}-selected` : value}
                role="option"
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleClick(e, value, i)}
                onKeyDown={handleKeyPress}
                selected={isSelected}
                disabled={value === 'default'}
                aria-selected={isSelected}
                value={value}
                ref={(el: HTMLButtonElement) => createOptionRef(el, i)}
              >
                <div className="values">
                  <Paragraph
                    weight={isSelected ? 'bold' : 'normal'}
                    color={isSelected ? '--text-black' : '--text-gray'}
                    size="small"
                  >
                    {display}
                  </Paragraph>
                  {description && (
                    <Paragraph
                      className="description"
                      size="mini"
                      color={isSelected ? '--text-black' : '--text-gray-light'}
                      data-testid={`${value}-description-text`}
                    >
                      {description}
                    </Paragraph>
                  )}
                </div>

                <RadioPseudo isChecked={isSelected} color="--text-black" sizing="small" />
              </DropdownListItem>
            </li>
          );
        })}
      </ul>
    </DropdownContainer>
  );
};
