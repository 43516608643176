import styled from 'styled-components';

interface ContainerProps {
  risk: 'risky' | 'moderate' | 'safe';
}

const handleBackground = (risk) => {
  switch (risk) {
    case 'risky':
      return 'var(--colors-red-100)';
    case 'moderate':
      return 'var(--colors-yellow-100)';
    case 'safe':
      return 'var(--colors-green-100)';
    default:
      return null;
  }
};

export const Container = styled.div<ContainerProps>`
  background: ${({ risk }) => handleBackground(risk)};
  border-radius: var(--border-radius-xs);
  padding: var(--sizing-sm);
  padding-top: 0;
  margin-top: var(--sizing-sm);
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.06);

  .status-container {
    display: flex;
    gap: 0 4px;
    margin-bottom: var(--sizing-sm);
  }
`;

interface StatusProps {
  state: 'risky' | 'moderate' | 'safe';
  active: boolean;
}

export const Status = styled.div<StatusProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: var(--sizing-xs) var(--sizing-sm);
  border-radius: 0 0 var(--border-radius-xs) var(--border-radius-xs);
  background: ${({ state, active }) => {
    if (!active) {
      return 'var(--colors-opaque-5)';
    }

    switch (state) {
      case 'risky':
        return 'var(--primary-red)';
      case 'moderate':
        return 'var(--colors-yellow-300)';
      case 'safe':
        return 'var(--primary-green)';
      default:
        return 'var(--background-gray)';
    }
  }};
`;
