import React from 'react';

import {
  Member,
  MemberSectionConfigFields,
  RxSearchResults,
  UpdateMemberFields,
} from 'Containers/ProfilePage/types';

import { Container } from './styled';
import HouseholdMember from '../HouseholdMember';

export interface HouseholdMemberListProps {
  policyholder: Member;
  spouse: Member;
  dependents: Member[];
  memberConfig: MemberSectionConfigFields;
  isOpen: {
    policyholder: boolean;
    spouse: boolean;
    dependents: string[];
  };
  drugResults: RxSearchResults;
  isRxLoading: boolean;
  isRxSkipped: boolean;
  isProfileAudioEnabled: boolean;
  handleEditPolicyholder: (update: UpdateMemberFields, memberConfig: MemberSectionConfigFields) => void;
  handleEditSpouse: (update: UpdateMemberFields, memberConfig: MemberSectionConfigFields) => void;
  handleEditDependent: (
    memberId: string,
    update: UpdateMemberFields,
    memberConfig: MemberSectionConfigFields,
  ) => void;
  handleRemoveMember: (relationship: string, memberId: string) => void;
  queryDrugs: (value: string) => void;
  setError: (isError: boolean) => void;
}

export const HouseholdMemberList = ({
  policyholder,
  spouse,
  memberConfig,
  dependents,
  isOpen,
  drugResults,
  isRxLoading,
  isRxSkipped,
  isProfileAudioEnabled,
  handleEditPolicyholder,
  handleEditSpouse,
  handleEditDependent,
  handleRemoveMember,
  queryDrugs,
  setError,
}: HouseholdMemberListProps) => {
  const renderDependents = () => {
    if (dependents.length > 0) {
      return dependents.map(
        (dependent, idx) =>
          isOpen.dependents.includes(dependent.external_id) && (
            <HouseholdMember
              key={dependent.external_id}
              member={dependent}
              relationship="dependent"
              memberConfig={memberConfig}
              dependentIndex={idx}
              drugResults={drugResults}
              isRxLoading={isRxLoading}
              isRxSkipped={isRxSkipped}
              isProfileAudioEnabled={isProfileAudioEnabled}
              handleEdit={(update) => handleEditDependent(dependent.external_id, update, memberConfig)}
              handleDelete={() => handleRemoveMember('dependent', dependent.external_id)}
              queryDrugs={queryDrugs}
              setError={setError}
            />
          ),
      );
    }
    return null;
  };

  if (!isOpen.policyholder) {
    return null;
  }

  return (
    <Container>
      {isOpen.policyholder && (
        <HouseholdMember
          key={policyholder.external_id}
          member={policyholder}
          handleDelete={() => null}
          relationship="policyholder"
          memberConfig={memberConfig}
          drugResults={drugResults}
          isRxLoading={isRxLoading}
          isRxSkipped={isRxSkipped}
          isProfileAudioEnabled={isProfileAudioEnabled}
          handleEdit={handleEditPolicyholder}
          queryDrugs={queryDrugs}
          setError={setError}
        />
      )}
      {isOpen.spouse && (
        <HouseholdMember
          member={spouse}
          relationship="spouse"
          memberConfig={memberConfig}
          drugResults={drugResults}
          isRxLoading={isRxLoading}
          isRxSkipped={isRxSkipped}
          isProfileAudioEnabled={isProfileAudioEnabled}
          handleEdit={handleEditSpouse}
          handleDelete={() => handleRemoveMember('spouse', '')}
          queryDrugs={queryDrugs}
          setError={setError}
        />
      )}
      {renderDependents()}
    </Container>
  );
};
