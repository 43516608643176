import { all, call, ForkEffect, put, select, takeLatest } from 'redux-saga/effects';

import { makeSelectCommercialField, makeSelectConfigField } from 'Containers/App/selectors';
import {
  makeGetSelectedEnrollmentEventId,
  makeGetHousehold,
  makeGetHouseholdId,
} from 'Containers/ProfilePage/selectors';
import { Household, Recommendation } from 'Types/entities';
import {
  getFsaTaxSavings,
  getHsaForecasts,
  getHsaRecommendations,
  getMarketplaceHsaRecommendations,
  postContributions,
} from 'Utils/api';
import * as API_TYPES from 'Utils/apiTypes';

import {
  forecastHsaFailure,
  forecastHsaSuccess,
  getFsaTaxSavingsSuccess,
  getHsaRecommendationFailure,
  getHsaRecommendationSuccess,
  postContributionsFailure,
  postContributionsSuccess,
} from './actions';
import {
  GET_FSA_TAX_SAVINGS_REQUEST,
  GET_HSA_RECOMMENDATION_REQUEST,
  LOCK_FSA_CONTRIBUTION,
  LOCK_HSA_CONTRIBUTION,
  SET_HSA_BALANCE_AND_PERSONA,
} from './constants';
import { makeGetForecastPayload, makeGetFsaTaxSavingsPayload, makeSelectTaxSavingsField } from './selectors';

export function* getXhrHsaRecommendation() {
  // Get selected plan
  const selectedPlan: Recommendation = yield select(makeSelectCommercialField('selectedHealthPlan'));

  try {
    // Get previously stored household id and send it with request if it exists
    const householdId: string = yield select(makeGetHouseholdId());

    const enrollmentEventId: string = yield select(makeGetSelectedEnrollmentEventId());

    const resp: API_TYPES.GetHsaRecommendationResponse = yield call(
      getHsaRecommendations,
      selectedPlan,
      householdId,
      enrollmentEventId,
    );
    yield put(getHsaRecommendationSuccess(resp));
  } catch (err: unknown) {
    yield put(getHsaRecommendationFailure(err as Error));
  }
}

export function* getXhrMarketplaceHsaRecommendation() {
  // Get selected plan
  const selectedPlan: Recommendation = yield select(makeSelectCommercialField('selectedHealthPlan'));

  // Get household
  const household: Household = yield select(makeGetHousehold());

  try {
    const resp: API_TYPES.GetHsaRecommendationResponse = yield call(
      getMarketplaceHsaRecommendations,
      selectedPlan,
      household,
    );
    yield put(getHsaRecommendationSuccess(resp));
  } catch (err: unknown) {
    yield put(getHsaRecommendationFailure(err as Error));
  }
}

/*
done on page load and slider edit
sets `forecastData`
 */
export function* postXhrHsaForecast() {
  // Get forecast payload
  const payload = yield select(makeGetForecastPayload());
  const enrollmentEventId = yield select(makeGetSelectedEnrollmentEventId());

  try {
    const resp = yield call(getHsaForecasts, payload, enrollmentEventId);
    yield put(forecastHsaSuccess(resp));
  } catch (err: unknown) {
    yield put(forecastHsaFailure(err as Error));
  }
}

export function* getXhrFsaTaxSavings() {
  const householdId: string = yield select(makeGetHouseholdId());
  const payload = yield select(makeGetFsaTaxSavingsPayload());
  const enrollmentEventId = yield select(makeGetSelectedEnrollmentEventId());

  try {
    const resp = yield call(getFsaTaxSavings, householdId, payload, enrollmentEventId);
    yield put(getFsaTaxSavingsSuccess(resp));
  } catch (err: unknown) {
    yield put(forecastHsaFailure(err as Error));
  }
}

export function* postXhrContributions() {
  const enrollmentEventId = yield select(makeGetSelectedEnrollmentEventId());
  const hsaContribution = yield select(makeSelectTaxSavingsField('lockedHsaContribution'));

  const fsaContribution = yield select(makeSelectTaxSavingsField('lockedFsaContribution'));

  const formattedJvpContributions: Record<string, API_TYPES.Contribution[]> = {
    contributions: [],
  };

  if (hsaContribution !== null) {
    formattedJvpContributions?.contributions.push({
      account_type: 'hsa',
      annual_contribution_amount: hsaContribution,
    });
  }

  if (fsaContribution !== null) {
    formattedJvpContributions?.contributions.push({
      account_type: 'fsa',
      annual_contribution_amount: fsaContribution,
    });
  }

  try {
    const resp: API_TYPES.PostContributionsResponse = yield call(
      postContributions,
      enrollmentEventId,
      formattedJvpContributions,
    );
    // Firing this action doesn't actually do anything; response just contains the payload we sent
    yield put(postContributionsSuccess(resp));
  } catch (err: unknown) {
    yield put(postContributionsFailure(err as Error));
  }
}

export default function* hsaPageSaga() {
  const isMarketplace = yield select(makeSelectConfigField('is_marketplace'));
  const sagas: ForkEffect[] = [];

  const recommendationFunc = isMarketplace ? getXhrMarketplaceHsaRecommendation : getXhrHsaRecommendation;
  sagas.push(takeLatest(GET_HSA_RECOMMENDATION_REQUEST, recommendationFunc));
  sagas.push(takeLatest(LOCK_HSA_CONTRIBUTION, postXhrContributions));
  sagas.push(takeLatest(LOCK_FSA_CONTRIBUTION, postXhrContributions));
  sagas.push(takeLatest(LOCK_HSA_CONTRIBUTION, postXhrHsaForecast));
  sagas.push(takeLatest(SET_HSA_BALANCE_AND_PERSONA, postXhrHsaForecast));
  sagas.push(takeLatest(GET_FSA_TAX_SAVINGS_REQUEST, getXhrFsaTaxSavings));

  yield all(sagas);
}
