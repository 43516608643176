import React from 'react';

import AccountsIllustration from 'Assets/banner/accounts-illustration.png';
import BuildPlanIllustration from 'Assets/banner/buildaplan-illustration.png';
import PlanFitIllustration from 'Assets/banner/plan-fit-illustration.png';
import PredictionsIllustration from 'Assets/banner/predictions-illustration.png';
import { useFeatureFlagContext } from 'Contexts/featureFlagContext';
import { useTextContext } from 'Contexts/textContext';
import { Button, Paragraph } from 'DesignLibrary/atoms';
import { useViewport } from 'DesignLibrary/context';
import { BannerCard } from 'DesignLibrary/molecules/BannerCard';
import Text from 'Shared/Text';

import { ButtonGrid, Content, WaiveContainer, WaiveButton } from './styled';

export const BuildAPlanBanner = ({ handleOpen }) => {
  const { retrieveContentfulData } = useTextContext();

  return (
    <BannerCard imgUrl={BuildPlanIllustration} background="--colors-blue-50">
      <Content>
        <Paragraph size="small">
          <Text field="health_section.secondary_content.build_a_plan_cta" />
        </Paragraph>
        <Button
          data-testid="planEntryShowModalButton"
          onClick={handleOpen}
          iconLeft="FilePlus"
          size="small"
          stretch
        >
          <Text field="health_section.secondary_content.build_a_plan_button_text" />
        </Button>
        <Button
          buttonType="transparent"
          size="small"
          stretch
          url={retrieveContentfulData<string>(
            'health_section.secondary_content.build_a_plan_instructions_button_link',
          )}
          iconRight="ArrowUpRight"
        >
          <Text field="health_section.secondary_content.build_a_plan_instructions_button_text" />
        </Button>
      </Content>
    </BannerCard>
  );
};

export const AccountsBanner = ({
  hasHsaPlan,
  hasHraPlan,
  isFsaEligible,
  handleHsa,
  handleHra,
  handleFsa,
}) => {
  const showCard = hasHsaPlan || hasHraPlan || isFsaEligible;
  if (showCard) {
    return (
      <BannerCard imgUrl={AccountsIllustration} background="--colors-blue-50">
        <Content>
          <Paragraph size="small">
            <Text field="health_section.secondary_content.hsa_hra_fsa_cta" />
          </Paragraph>

          <ButtonGrid>
            {hasHsaPlan && (
              <Button size="small" onClick={handleHsa} data-testid="showHsaModalButton">
                <Text field="health_section.secondary_content.hsa_button_text" />
              </Button>
            )}

            {hasHraPlan && (
              <Button size="small" onClick={handleHra} data-testid="showHraModalButton">
                <Text field="health_section.secondary_content.hra_button_text" />
              </Button>
            )}

            {isFsaEligible && (
              <Button size="small" onClick={handleFsa} data-testid="showFsaModalButton">
                <Text field="health_section.secondary_content.fsa_button_text" />
              </Button>
            )}
          </ButtonGrid>
        </Content>
      </BannerCard>
    );
  }
  return null;
};

export const PredictionsBanner = ({ handleOpen }) => {
  const { is_improved_recommendation_explanation_enabled } = useFeatureFlagContext();
  const imageUrl = is_improved_recommendation_explanation_enabled
    ? PlanFitIllustration
    : PredictionsIllustration;

  return (
    <BannerCard imgUrl={imageUrl} background="--colors-yellow-50">
      <Content>
        <Paragraph size="small">
          <Text field="health_section.secondary_content.explore_our_predictions_cta" />
        </Paragraph>

        <Button size="small" stretch onClick={handleOpen} data-testid="plyShowModalButton">
          <Text field="health_section.secondary_content.explore_our_predictions_button_text" />
        </Button>
      </Content>
    </BannerCard>
  );
};

export const WaiveBar = ({ handleWaiveHealthPlan, isCoverageWaived }) => {
  const { device } = useViewport();

  return (
    <WaiveContainer isCoverageWaived={isCoverageWaived}>
      <Paragraph>
        <Text field="health_section.secondary_content.waive_coverage_cta" />
      </Paragraph>
      <WaiveButton>
        <Button
          data-testid="waive-health-btn"
          id="waive-health-btn"
          onClick={handleWaiveHealthPlan}
          iconLeft="Prohibit"
          textColor="--colors-red-400"
          color="red"
          buttonType={isCoverageWaived ? 'secondary' : 'transparent'}
          stretch
          size={device === 'mobile' ? 'small' : 'normal'}
          isDisabled={isCoverageWaived}
        >
          {isCoverageWaived ? (
            <Text field="call_to_action.health_waived" />
          ) : (
            <Text field="health_section.secondary_content.waive_coverage_button_text" />
          )}
        </Button>
      </WaiveButton>
    </WaiveContainer>
  );
};
