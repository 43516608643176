import styled from 'styled-components';

import { media } from 'DesignLibrary/global';
import { cardStyling } from 'DesignLibrary/utils';

export const ContinueContainer = styled.div`
  ${cardStyling}
  overflow: hidden;
  max-width: 400px;

  .banner-image {
    width: 100%;
    height: calc(var(--sizing-2xl) * 1.25);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .content {
    padding: var(--sizing-md);

    & > * + * {
      margin-top: var(--sizing-xs);
    }
  }

  ${media.TABLET`
    display: flex;
    align-items: flex-start;
    max-width: unset;

    .banner-image {
      flex-shrink: 0;
      width: calc(var(--sizing-sm) * 10);
      height: auto;
      align-self: stretch;
      overflow: hidden;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        transform: scale(1.5);
        transform-origin: center center;
      }
    }

    .content {
      align-self: center;
    }
  `}
`;
