import { Box, BoxProps, Flex, Skeleton, Stack } from '@chakra-ui/react';
import React from 'react';

import { Icon, Paragraph } from 'DesignLibrary/atoms';
import Text from 'Shared/Text';

export interface ProviderCardProps extends BoxProps {
  title?: string;
  subtitle?: string | React.ReactNode;
  isSkeleton?: boolean;
  isNoProviders?: boolean;
  showIcon?: boolean;
  variant?: 'default' | 'search';
  testId?: string;
}

export interface ProviderCardLayoutProps {
  isDisabled?: boolean;
  showIcon: boolean;
  children: React.ReactNode;
}

export const ProviderCardLayout = ({
  isDisabled = false,
  showIcon = true,
  children,
}: ProviderCardLayoutProps) => (
  <Flex align="center" gap={4}>
    {showIcon && (
      <Box opacity={isDisabled ? '0.5' : undefined}>
        <Icon size="large" type="Stethoscope" color={!isDisabled ? '--primary-blue' : '--text-gray'} />
      </Box>
    )}
    {children}
  </Flex>
);

export const ProviderCard = ({
  title,
  subtitle,
  isSkeleton = false,
  isNoProviders = false,
  showIcon = true,
  variant = 'default',
  testId,
  ...rest
}: ProviderCardProps) => {
  const renderProvider = (
    <Box w="100%">
      {title && (
        <Paragraph
          color="--text-black"
          size={variant === 'default' ? 'small' : 'normal'}
          weight="bold"
          data-testid={testId}
        >
          {title}
        </Paragraph>
      )}
      {subtitle && (
        <Paragraph size={variant === 'default' ? 'mini' : 'small'} data-testid="provider-subtitle">
          {subtitle}
        </Paragraph>
      )}
    </Box>
  );

  const renderSkeleton = (
    <Stack w="100%">
      <Skeleton startColor="blackAlpha.300" endColor="blackAlpha.100" speed={3} w="100%" height={4}>
        Skeleton
      </Skeleton>
      <Skeleton speed={3.5} startColor="blackAlpha.300" endColor="blackAlpha.100" w="25%" height={4} />
    </Stack>
  );

  return (
    <Box
      p={variant === 'default' ? 4 : 0}
      borderRadius="var(--border-radius-xs)"
      bg={variant === 'default' ? 'var(--background-gray)' : 'none'}
      {...rest}
    >
      <ProviderCardLayout showIcon={showIcon} isDisabled={isSkeleton || isNoProviders}>
        {!isSkeleton && !isNoProviders && renderProvider}

        {isSkeleton && renderSkeleton}

        {isNoProviders && (
          <Paragraph size="small">
            <Text field="health_section.secondary_content.none_added" />
          </Paragraph>
        )}
      </ProviderCardLayout>
    </Box>
  );
};
