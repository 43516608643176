import React from 'react';

import { COVERAGE_TIERS } from 'Containers/App/constants';
import { useProfileContext } from 'Contexts/profileContext';
import { useTextContext } from 'Contexts/textContext';
import Plan from 'Models/plan';

// Helpers
import {
  renderInNetworkDeductible,
  renderInNetworkOopMax,
  renderOutNetworkDeductible,
  renderOutNetworkOopMax,
  renderProvidersByNetwork,
} from './_shared';
import { CompareTable } from '../_shared/CompareTable';
import { CompareTableRow } from '../_shared/CompareTableRow';

interface DeductiblesLimitsProps {
  plans: Plan[];
  client: string;
  coverageTier: COVERAGE_TIERS;
}

export const DeductiblesLimits = ({ plans, client, coverageTier }: DeductiblesLimitsProps) => {
  const { retrieveContentfulData } = useTextContext();
  const { selectedPublicationInfo } = useProfileContext();

  // display provider search section feature enabled from alex builder
  const docFinderEnabled = selectedPublicationInfo?.publication?.docfinder_enabled;

  const firstPlan = plans[0];
  const secondPlan = plans[1];
  const thirdPlan = plans[2] || null;

  // HEADER AND ITEM TEXT
  const inNetworkTitle = retrieveContentfulData<string>(
    'health_section.compare_plan_text.compareModal.inNetwork',
  );
  const outOfNetworkTitle = retrieveContentfulData<string>(
    'health_section.compare_plan_text.compareModal.outOfNetwork',
  );
  const deductibleTitle = retrieveContentfulData<string>(
    'health_section.compare_plan_text.compareModal.deductible',
  );
  const oopLimitTitle = retrieveContentfulData<string>(
    'health_section.compare_plan_text.compareModal.oopLimit',
  );

  const doctorsProvidersTitle = retrieveContentfulData<string>(
    'health_details_section.plan_details.doctors_providers_card_title',
  );

  // ITEM TOOLTIPS
  const inNetworkDeductibleTooltip = retrieveContentfulData<string>('tool_tips.in_network_deductible.text');
  const inNetworkOopTooltip = retrieveContentfulData<string>('tool_tips.in_network_out_of_pocket_limit.text');
  const outNetworkDeductibleTooltip = retrieveContentfulData<string>(
    'tool_tips.out_of_network_deductible.text',
  );
  const outNetworkOopTooltip = retrieveContentfulData<string>(
    'tool_tips.out_of_network_out_of_pocket_limit.text',
  );

  return (
    <>
      {/* IN-NETWORK */}
      <CompareTable tableHeader={inNetworkTitle}>
        {/* IN-NETWORK DEDUCTIBLE */}
        <CompareTableRow
          rowHeader={deductibleTitle}
          rowHeaderTooltip={inNetworkDeductibleTooltip}
          columnOne={renderInNetworkDeductible(firstPlan, client, coverageTier)}
          columnTwo={renderInNetworkDeductible(secondPlan, client, coverageTier)}
          columnThree={thirdPlan && renderInNetworkDeductible(thirdPlan, client, coverageTier)}
          keyHint="inn-deductible"
        />

        {/* IN-NETWORK OOP MAX */}
        <CompareTableRow
          rowHeader={oopLimitTitle}
          rowHeaderTooltip={inNetworkOopTooltip}
          columnOne={renderInNetworkOopMax(firstPlan)}
          columnTwo={renderInNetworkOopMax(secondPlan)}
          columnThree={thirdPlan && renderInNetworkOopMax(thirdPlan)}
          keyHint="inn-moop"
        />

        {/* Doctors & Providers */}
        {docFinderEnabled && (
          <div data-testid="compare-in-provider">
            <CompareTableRow
              rowHeader={doctorsProvidersTitle}
              columnOne={renderProvidersByNetwork(firstPlan, true)}
              columnTwo={renderProvidersByNetwork(secondPlan, true)}
              columnThree={thirdPlan && renderProvidersByNetwork(thirdPlan, true)}
              hideTooltip
              keyHint="inn-doctors-providers"
            />
          </div>
        )}
      </CompareTable>

      {/* OUT-OF-NETWORK */}
      <CompareTable tableHeader={outOfNetworkTitle}>
        {/* OUT-OF-NETWORK DEDUCTIBLE */}
        <CompareTableRow
          rowHeader={deductibleTitle}
          rowHeaderTooltip={outNetworkDeductibleTooltip}
          columnOne={renderOutNetworkDeductible(firstPlan)}
          columnTwo={renderOutNetworkDeductible(secondPlan)}
          columnThree={thirdPlan && renderOutNetworkDeductible(thirdPlan)}
          keyHint="oon-deductible"
        />

        {/* OUT-OF-NETWORK OOP MAX */}
        <CompareTableRow
          rowHeader={oopLimitTitle}
          rowHeaderTooltip={outNetworkOopTooltip}
          columnOne={renderOutNetworkOopMax(firstPlan)}
          columnTwo={renderOutNetworkOopMax(secondPlan)}
          columnThree={thirdPlan && renderOutNetworkOopMax(thirdPlan)}
          keyHint="oon-moop"
        />

        {/* Doctors & Providers */}
        {docFinderEnabled && (
          <div data-testid="compare-out-provider">
            <CompareTableRow
              rowHeader={doctorsProvidersTitle}
              columnOne={renderProvidersByNetwork(firstPlan, false)}
              columnTwo={renderProvidersByNetwork(secondPlan, false)}
              columnThree={thirdPlan && renderProvidersByNetwork(thirdPlan, false)}
              hideTooltip
              keyHint="oon-doctors-providers"
            />
          </div>
        )}
      </CompareTable>
    </>
  );
};
