import styled from 'styled-components';

import { ParagraphMiniStyle } from 'DesignLibrary/atoms';
import { media } from 'DesignLibrary/global';

export const CardContainer = styled.div`
  position: relative;
  padding: var(--sizing-sm);

  &:nth-child(odd) {
    background: var(--background-gray-light);
  }

  ${media.TABLET`
    padding: var(--sizing-md);
    display: flex;
    justify-content: space-between;
    align-items: center;
  `}

  @media print {
    display: flex;
    justify-content: space-between;
    border-bottom: var(--border);
    padding: var(--sizing-sm);

    &:last-child {
      border: none;
    }

    &:nth-child(odd) {
      background: none;
    }
  }

  .card-left {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--sizing-sm);
    margin-bottom: var(--sizing-xs);

    .title {
      display: flex;
      align-items: center;
      min-width: 0;

      .plan-title * {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    ${media.TABLET`
      margin-bottom: 0;
    `}

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      margin-right: var(--sizing-sm);
    }

    .plan-title {
      min-width: 0;

      .paragraph {
        margin: 0;
        line-height: 1.25;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  .card-right {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    ${media.TABLET`
      justify-content: flex-start;
      align-items: center;
    `}

    @media print {
      button {
        display: none;
      }
    }

    button {
      margin-bottom: 0;
    }

    .paragraph {
      ${media.TABLET`
        text-align: right;
        max-width: 145px;
      `}
    }

    .pricing {
      /* max-width: var(--sizing-3xl); */
      text-align: right;
      display: flex;
      justify-content: flex-end;

      ${media.TABLET`
        width: var(--sizing-3xl);
        margin-left: var(--sizing-md);
      `}
    }

    .secondary-content {
      flex-shrink: 0;
    }
  }
`;

export const AutoTag = styled.div`
  ${ParagraphMiniStyle('--colors-blue-600', 600)}
  display: inline-block;
  background: var(--colors-blue-50);
  border-radius: var(--border-radius-xs);
  padding: 4px var(--sizing-xs);
`;
