import * as Insights from '@picwell/insights-js';
import _ from 'lodash';
import React, { KeyboardEvent } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { defaultCookieDomain, isDevelopment, setCookie } from 'Containers/App/utils';
import {
  DoctorSummary,
  DoctorType,
  Recommendation,
  SupplementalPlan,
  SupplementalRecommendations,
} from 'Types/entities';
import Logger from 'Utils/logger';

type InsightsEvent = KeyboardEvent<HTMLElement> | React.MouseEvent<HTMLButtonElement> | null;

interface BuilderInsightsPlans {
  plan: InsightsPlanIds;
  score: number;
}

interface InsightsPlanIds {
  external_id: string;
  id: string;
}

export type InsightsEventData = Record<
  string,
  | string
  | boolean
  | number
  | SupplementalPlan
  | string[]
  | null
  | BuilderInsightsPlans[]
  | SupplementalRecommendations
  | DoctorType[]
  | DoctorSummary[]
>;

export const createSessionId = () => {
  let sessionId = localStorage.getItem('insights_session_id');

  // Store insights session id across visits
  if (!sessionId) {
    sessionId = uuidv4() as string;
    localStorage.setItem('insights_session_id', sessionId);
  }

  return sessionId;
};

export const createUnifiedSessionId = (reset?: boolean) => {
  const unified_session_id = uuidv4() as string;

  setCookie('unified_session_id', unified_session_id, defaultCookieDomain(), '/');
  if (reset) {
    updateInsightsDefaults({ unified_session_id });
  }
  return unified_session_id;
};

export const formatInsightsData = (
  domEvent: InsightsEvent,
  eventName: string,
  eventData: InsightsEventData,
) => {
  let eventSource: string | null = null;

  if (domEvent && !_.isEmpty(domEvent)) {
    eventSource = (domEvent.target as Element).id || (domEvent.target as Element).classList[0] || null;
  }

  const data = eventData;
  const queryString = localStorage.getItem('query_string');
  if (queryString && data) {
    data.query_string = JSON.parse(queryString);
  }
  if (data) {
    data.sso_user = localStorage.getItem('access_token') !== null;
  }

  return {
    event_name: eventName,
    event_source_url: window.location.href,
    event_timestamp: new Date().getTime(),
    event_source_id: eventSource,
    event_data: data,
  };
};

export const createInsightsSession = async (
  clientId: string,
  builderCustomerKey: string | null,
  validatedSegmentSlug: string | null,
) => {
  if (!isDevelopment()) {
    const sessionId = createSessionId();
    const unifiedSessionId = createUnifiedSessionId();

    const defaultEventData: Record<string, any> = {
      client_id: clientId,
      builder_customer_key: builderCustomerKey,
      session_id: sessionId,
      unified_session_id: unifiedSessionId,
    };

    if (validatedSegmentSlug) {
      defaultEventData.segment_slug = validatedSegmentSlug;
    }

    return Promise.resolve(Insights.setDefaultEventData(defaultEventData));
  }
  return Promise.resolve();
};

export const updateInsightsDefaults = async (values: any) => {
  if (!isDevelopment()) {
    return Promise.resolve(Insights.setDefaultEventData(values));
  }
  return Promise.resolve();
};

export const sendInsightsEvent = (
  domEvent: InsightsEvent,
  eventName: string,
  eventData: InsightsEventData = {},
) => {
  if (!isDevelopment()) {
    const data = {
      household_id: localStorage.getItem('household_id') || null,
      ...eventData,
    };
    const clientReferrer = document?.referrer;
    if (clientReferrer !== '') {
      updateInsightsDefaults({ client_referrer: clientReferrer });
    }

    const event = formatInsightsData(domEvent, eventName, data);
    try {
      Insights.postEvent(event);
    } catch (err) {
      Logger.error(err);
    }
  }
};

export function formatBuilderPlansForInsights(plans: Recommendation[]): BuilderInsightsPlans[] {
  const builderPlansForInsights = plans.map((plan) => ({
    plan: {
      external_id: plan.plan.external_id as string,
      id: plan.plan.id as string,
    },
    score: plan.score as number,
  }));
  return builderPlansForInsights;
}
