import React, { FC } from 'react';

import { IconTypes, Paragraph } from 'DesignLibrary/atoms';
import { IconBlock } from 'DesignLibrary/molecules/IconBlock';

import { BulletStyle } from './styled';

interface BulletProps {
  icon: IconTypes;
  dataTestId?: string;
  color: 'purple' | 'green' | 'blue';
  children: React.ReactNode;
}

export const Bullet: FC<BulletProps> = ({ icon, dataTestId = null, color, children }) => (
  <BulletStyle data-testid={dataTestId}>
    <IconBlock smallMobile color={color} shade={100} icon={icon} />
    <Paragraph color="--text-black">{children}</Paragraph>
  </BulletStyle>
);
