import styled from 'styled-components';

import { breakpoint } from 'DesignLibrary/global';

export const AlexIntroWrapper = styled.div`
  background: var(--primary-white);
  min-height: calc(100vh - var(--sizing-2xl));
`;

export const IntroHeader = styled.div`
  border-bottom: var(--border);
  padding-top: var(--sizing-lg);
  padding-bottom: var(--sizing-xs);
  margin-bottom: var(--sizing-xs);

  ${breakpoint.TABLET} {
    padding-top: var(--sizing-2xl);
  }
`;

export const VideoWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const ClosedCaptions = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  pointer-events: none;

  .cc-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--sizing-sm) 0;
    min-height: var(--sizing-3xl);

    .paragraph {
      text-align: center;
      max-width: 800px;
    }
  }

  .speaker {
    span {
      display: inline-flex !important;
      margin-right: var(--sizing-sm);
      vertical-align: middle;
    }
  }
`;

export const SoundCheckWrapper = styled.div`
  margin-top: var(--sizing-2xl);

  .answers {
    margin-top: var(--sizing-md);

    & > * + * {
      margin-top: var(--sizing-xs);
    }
  }

  button {
    width: 100%;
    max-width: 320px;
  }
`;

export const SoundOffWrapper = styled.div`
  margin-top: var(--sizing-xl);

  .text {
    max-width: 800px;

    & > * + * {
      margin-top: var(--sizing-sm);
    }
  }

  .answers {
    margin-top: var(--sizing-lg);

    & > * + * {
      margin-top: var(--sizing-xs);
    }
  }

  button {
    width: 100%;
    max-width: 320px;
    height: auto;
    padding: var(--sizing-sm);
    text-align: left;

    span {
      line-height: 1.25;
    }
  }
`;
