import styled from 'styled-components';

import { media } from 'DesignLibrary/global';

export const EligNone = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const GridContainer = styled.div`
  ${media.DESKTOP`
    display: grid;
    grid-template-columns: minmax(660px, auto) minmax(240px, 320px);
    grid-gap: var(--sizing-md);
  `}
`;

export const PlanChangeMessage = styled.div`
  &:empty {
    display: none;
  }

  .inner-wrapper {
    padding: 0;

    > span {
      padding-top: 0;
    }
  }
`;

export const PlanCards = styled.section`
  display: flex;
  flex-direction: column;
  gap: var(--sizing-sm);

  ${media.TABLET`
    gap: var(--sizing-md);
  `}
`;

export const Sidebar = styled.aside`
  display: flex;
  flex-direction: column;
  gap: var(--sizing-md);
`;

export const HorizontalScrollBar = styled.aside`
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  gap: var(--sizing-md);
  overflow-x: auto;
  overflow-y: visible;
  padding: var(--sizing-xs) var(--sizing-lg);
  margin-left: -32px;
  width: calc((2 * var(--sizing-lg)) + 100%);

  > * {
    width: 320px;
    flex-shrink: 0;
  }
`;

export const BottomCards = styled.aside`
  display: flex;
  flex-direction: column;
  gap: var(--sizing-md);
  padding-top: var(--sizing-xl);
  margin-top: var(--sizing-xl);
  margin-bottom: var(--sizing-2xl);
  border-top: 1px solid var(--border-gray);

  ${media.TABLET`
    display: grid;
    grid-template-columns: repeat(2,1fr);
    margin-bottom: var(--sizing-md);
  `}

  ${media.DESKTOP`
    grid-template-columns: 1fr 1fr 1fr;
  `}
`;

export const MemberLoadingContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--sizing-lg);
`;
