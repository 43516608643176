import { Box, Flex, Stack } from '@chakra-ui/react';
import React from 'react';

import { Icon, Paragraph, RadioPseudo } from 'DesignLibrary/atoms';

import { LocationLabel } from './styled';

export const Location = ({
  location, // Object representing the location
  setSelectedLocation, // Function to set location of provider
  isSelected, // Flag to check location is selected
  uniqueId,
  setSelectedIndex,
}) => {
  const { address, distance, phoneNumbers } = location;
  // Get primary phone number
  const number = phoneNumbers?.find(
    (phoneNumber: { details: string }) => phoneNumber.details === 'primary',
  )?.phone;

  // Handler to enable add button and add selected location
  const handleOnClick = () => {
    setSelectedIndex(uniqueId);
    setSelectedLocation(location);
  };

  return (
    <LocationLabel data-testid="location">
      <input
        type="radio"
        name="location"
        className="inputStyle"
        checked={isSelected}
        onClick={handleOnClick}
        data-testid="location-radio-input"
      />

      <Flex
        className="pseudo-radio"
        align="flex-start"
        gap={4}
        p={4}
        borderRadius="var(--border-radius-xs)"
        bg="var(--background-gray-light)"
        border={`2px solid ${isSelected ? 'var(--primary-blue)' : 'transparent'}`}
      >
        <Box pointerEvents="none">
          <RadioPseudo
            sizing="small"
            color="--primary-blue"
            isChecked={isSelected}
            uniqueId={`radioGroupId-${uniqueId}`}
          />
        </Box>
        <Stack>
          {location.name && (
            <Paragraph
              weight="bold"
              color="--text-black"
              className="provider-location-name"
              data-testid="selected-doctor-name"
              size="small"
            >
              {location.name}
            </Paragraph>
          )}
          <Paragraph size="small" data-testid="address">
            {address}
          </Paragraph>

          <Flex flexDir={['column', 'row']} gap={4}>
            <Flex align="center" gap={1}>
              <Icon type="MapPin" size="small" weight="fill" />
              <Paragraph
                size="mini"
                color="--text-black"
                weight="bold"
                className="distance"
                data-testid="distance"
              >
                {`${distance} miles`}
              </Paragraph>
            </Flex>
            {number && (
              <Paragraph size="mini" data-testid="number">
                {`(${number.slice(0, 3)}) 
          ${number.slice(3, 6)}-${number.slice(6)}`}
              </Paragraph>
            )}
          </Flex>
        </Stack>
      </Flex>
    </LocationLabel>
  );
};
