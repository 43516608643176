import React from 'react';

import { TextField } from 'Containers/App/types';
import { ContentfulCustomMessage } from 'ContentfulDefaults/types/_shared';
import { useTextContext } from 'Contexts/textContext';
import { FormattedTooltipProps } from 'DesignLibrary/atoms';
import InfoBar, { InfoBarProps } from 'DesignLibrary/molecules/InfoBar';
import RichText from 'Shared/RichText';
import Text from 'Shared/Text';

interface ContentfulInfoBarProps {
  field: string;
  vars?: (string | number)[];
  headerVars?: (string | number)[];
  tooltips?: FormattedTooltipProps[];
  overrides?: InfoBarProps;
}

export const ContentfulInfoBar = (props: ContentfulInfoBarProps) => {
  const { field, vars, headerVars, tooltips = [], overrides } = props;

  const { retrieveContentfulData } = useTextContext();

  const message = retrieveContentfulData<ContentfulCustomMessage>(field);
  const header = <Text field={`${field}.header` as TextField} vars={{ x: headerVars }} />;

  return message ? (
    <InfoBar
      text={<RichText noWrapper field={`${field}.text` as TextField} vars={vars} toolTips={tooltips} />}
      header={header}
      url={message.url}
      color={message.color || 'yellow'}
      buttonText={message.url_button_text}
      icon={message.icon}
      key={field}
      {...overrides}
    />
  ) : null;
};
