import { Box, Flex, Stack } from '@chakra-ui/react';
import React from 'react';

import { ViewPlanPageCard } from 'Containers/ViewPlanPage/ViewPlanPageCard';
import { useProviderSelectionContext } from 'Contexts/providerSelectionContext';
import { useTextContext } from 'Contexts/textContext';
import { Button, Paragraph } from 'DesignLibrary/atoms';
import { IconBlockProps } from 'DesignLibrary/molecules/IconBlock';
import Text from 'Shared/Text';
import { DoctorProvidersProps } from 'Types/entities';
import { sendInsightsEvent } from 'Utils/insights';

import { ProviderSection } from './ProviderSection';

export const DoctorsProvidersCard = ({ planId }: DoctorProvidersProps) => {
  const { retrieveContentfulData } = useTextContext();
  const { filteredProviders, onOpenProviderModal, providerDetails } = useProviderSelectionContext();

  const title = retrieveContentfulData<string>(
    'health_details_section.plan_details.doctors_providers_card_title',
  );

  const icon: IconBlockProps = {
    icon: 'Stethoscope',
    color: 'blue',
  };

  const inNetworkProvider = filteredProviders[planId] ? filteredProviders[planId]?.inNetwork : [];
  const outOfNetworkProvider = filteredProviders[planId] ? filteredProviders[planId]?.outOfNetwork : [];

  const openProviderSelectionModal = () => {
    onOpenProviderModal();
    sendInsightsEvent(null, 'open_provider_search_modal');
  };

  return (
    <ViewPlanPageCard title={title} icon={icon}>
      {providerDetails?.length > 0 ? (
        <Stack gap={6} mt={6}>
          <ProviderSection type="in-network" providers={inNetworkProvider} />
          <ProviderSection type="out-of-network" providers={outOfNetworkProvider} />
          <Flex justify="flex-end">
            <Button
              size="small"
              onClick={openProviderSelectionModal}
              testId="provider-selection-update-button"
            >
              <Text field="health_section.secondary_content.provider_selection_update_button" />
            </Button>
          </Flex>
        </Stack>
      ) : (
        <Stack p={8} gap={3} bg="var(--background-gray)" align="center" justify="center" textAlign="center">
          <Paragraph size="small" data-testid="add-provider-text">
            <Text field="health_section.recommendation.in_network_providers_add_provider_text" />
          </Paragraph>
          <Box minW={['auto', 256]}>
            <Button
              iconLeft="Plus"
              testId="provider-selection-button"
              stretch
              size="small"
              onClick={openProviderSelectionModal}
            >
              <Text field="health_section.recommendation.in_network_providers_add_button" />
            </Button>
          </Box>
        </Stack>
      )}
    </ViewPlanPageCard>
  );
};
