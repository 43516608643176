import { COVERAGE_TIERS } from 'Containers/App/constants';
import Plan from 'Models/plan';
import { Recommendation } from 'Types/entities';
import { GetHsaRecommendationResponse } from 'Utils/apiTypes';

type RecommendedContributionDeciles = GetHsaRecommendationResponse['recommended_contribution_deciles'];

const interpolateDeciles = (x1: number, x2: number, x3: number, idx: number) => {
  const y1 = 0;
  const y3 = 10;

  const y2 = ((x2 - x1) * (y3 - y1)) / (x3 - x1) + y1;
  const result = (y2 + (idx + 1) * 10) / 100;

  return Math.min(Math.max(result, 0), 0.99);
};

export const getOopChance = (deciles: RecommendedContributionDeciles, contribution: number) => {
  if (!deciles) {
    return 0;
  }

  const isActualDeciles = deciles.length === 11;
  const adjustIdx = isActualDeciles ? -1 : 0;

  if (contribution < deciles[0]) {
    // Handles case between 0 and decile value at 0 index for smooth scaling
    const x1 = 0;
    const x2 = contribution;
    const x3 = deciles[0];

    return interpolateDeciles(x1, x2, x3, -1);
  }

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < deciles.length - 1; i++) {
    if (
      (contribution >= deciles[i] && contribution < deciles[i + 1]) ||
      (contribution > deciles[i] && contribution <= deciles[i + 1])
    ) {
      const x1 = deciles[i];
      const x2 = contribution;
      const x3 = deciles[i + 1];

      return interpolateDeciles(x1, x2, x3, i + adjustIdx);
    }
  }

  return 0.99;
};

enum FORECASTING_TIERS {
  INDIVIDUAL = 'individual',
  INDIVIDUAL_CHILDREN = 'individual_children',
  COUPLE = 'couple',
  COUPLE_CHILDREN = 'couple_children',
}

export function mapCommApiTierToForecastingTier(coverageTier: COVERAGE_TIERS): FORECASTING_TIERS {
  /* Converts Commercial API's coverage tier to accepted coverage tiers for Forecasting API */
  // TODO how to get a compile error if we don't add handling for new COVERAGE_TIERS ?
  const map: { [key in COVERAGE_TIERS]: FORECASTING_TIERS } = {
    [COVERAGE_TIERS.INDIVIDUAL]: FORECASTING_TIERS.INDIVIDUAL,
    [COVERAGE_TIERS.INDIVIDUAL_CHILD]: FORECASTING_TIERS.INDIVIDUAL_CHILDREN,
    [COVERAGE_TIERS.INDIVIDUAL_CHILDREN]: FORECASTING_TIERS.INDIVIDUAL_CHILDREN,
    [COVERAGE_TIERS.INDIVIDUAL_SPOUSE]: FORECASTING_TIERS.COUPLE,
    [COVERAGE_TIERS.FAMILY]: FORECASTING_TIERS.COUPLE_CHILDREN,
  };
  return map[coverageTier];
}

export function formatEmployerContribution(selectedPlan: Recommendation) {
  /* Format employer contribution for Forecasting API payload */
  const employerMatchPercent =
    selectedPlan.plan.spending_account_contributions.hsa_annual.employer_contribution_percent || 0;

  const isEmployerMatching = employerMatchPercent > 0;
  return isEmployerMatching
    ? {
        contribution_limit:
          selectedPlan.plan.spending_account_contributions.hsa_annual.employer_contribution_limit,
        percentage_match: employerMatchPercent,
      }
    : {
        contribution_amount:
          selectedPlan.plan.spending_account_contributions.hsa_annual.employer_contribution_amount,
      };
}

export function formatPlanParameters(selectedPlan: Recommendation, coverageTier: COVERAGE_TIERS) {
  /* Format plan parameters for Forecasting API payload */
  const plan = new Plan(selectedPlan);

  const inNetworkThreshold = plan.getInNetworkThreshold(coverageTier);

  const isInNetworkDedComprehensive = plan.getIsInNetworkDeductibleComprehensive();
  const isInNetworkOopLimitComprehensive = plan.getIsInNetworkOopLimitComprehensive();

  // Determine whether to send copay or coinsurance values
  const primaryCareIsCopay = selectedPlan.plan.primary_care.copay != null;
  const specialistCareIsCopay = selectedPlan.plan.specialist_care.copay != null;

  return {
    ...(isInNetworkDedComprehensive && {
      deductible: inNetworkThreshold.medical_and_drug_deductible,
    }),
    ...(!isInNetworkDedComprehensive && {
      drug_deductible: inNetworkThreshold.drug_deductible,
      medical_deductible: inNetworkThreshold.medical_deductible,
    }),
    ...(isInNetworkOopLimitComprehensive && {
      maximum_oop: inNetworkThreshold.medical_and_drug_oop_max,
    }),
    ...(!isInNetworkOopLimitComprehensive && {
      drug_maximum_oop: inNetworkThreshold.drug_oop_max,
      medical_maximum_oop: inNetworkThreshold.medical_oop_max,
    }),
    ...(primaryCareIsCopay && {
      primary_care_physician_copay: selectedPlan.plan.primary_care.copay,
    }),
    ...(!primaryCareIsCopay && {
      primary_care_physician_coinsurance_percent: selectedPlan.plan.primary_care.coinsurance,
    }),
    ...(specialistCareIsCopay && {
      specialist_copay: selectedPlan.plan.specialist_care.copay,
    }),
    ...(!specialistCareIsCopay && {
      specialist_coinsurance_percent: selectedPlan.plan.specialist_care.coinsurance,
    }),
  };
}
