import React from 'react';

import { useFeatureFlagContext } from 'Contexts/featureFlagContext';
import { usePremiumContext } from 'Contexts/premiumContext';
import { NumberWithPayPeriod } from 'DesignLibrary/atoms';
import { PremiumPerDeduction } from 'DesignLibrary/atoms/PremiumPerDeduction';
import Plan from 'Models/plan';
import { Recommendation } from 'Types/entities';
import {
  OopEstimateTooltipWithTooltip,
  PremiumTooltipWithText,
  YearlyTotalTooltipWithText,
} from 'Utils/createTooltips';

import { PricingOverviewItem, PricingOverviewWrapper } from './styled';

interface PricingOverviewProps {
  recommendation: Recommendation;
  hsaContributionLimit: number | null;
}

export const PricingOverview: React.FC<PricingOverviewProps> = ({ recommendation, hsaContributionLimit }) => {
  const { payPeriod } = usePremiumContext();

  const {
    // Remove when retiring feature flag APP-1028-oop-estimate-uses-comm-api
    is_real_cost_before_tax_benefit_enabled,
  } = useFeatureFlagContext();

  // useLegacyCostCalc is true when is_real_cost_before_tax_benefit_enabled is false.
  // Remove arg when retiring feature flag APP-1028-oop-estimate-uses-comm-api
  const plan = new Plan(
    recommendation,
    !is_real_cost_before_tax_benefit_enabled, // Remove arg when retiring feature flag APP-1028-oop-estimate-uses-comm-api
  );

  const outOfPocket = plan.calcOutOfPocket(hsaContributionLimit);

  const estimatedYearlyTotal = is_real_cost_before_tax_benefit_enabled
    ? plan.realCostBeforeTaxBenefit
    : plan.calcEstimatedYearlyTotal(hsaContributionLimit);

  const premiumComponent = (
    <PremiumPerDeduction
      annualPremium={plan.annualPremium}
      deductions={plan.annualPremiumDeductions}
      currency
      size="large"
      precision={2}
      keyHint={`plan-${plan.planId}-premium-value`}
    />
  );

  return (
    <PricingOverviewWrapper>
      <PricingOverviewItem>
        <YearlyTotalTooltipWithText payPeriod={payPeriod} as="h3" />
        <NumberWithPayPeriod
          payPeriodType="yearly"
          size="large"
          fontWeight="bold"
          value={estimatedYearlyTotal}
          keyHint={`plan-${plan.planId}-estimated-yearly-total`}
        />
      </PricingOverviewItem>

      <PricingOverviewItem>
        <PremiumTooltipWithText payPeriod={payPeriod} as="h3" keyHint={`plan-${plan.planId}-premium`} />
        {premiumComponent}
      </PricingOverviewItem>

      <PricingOverviewItem>
        <OopEstimateTooltipWithTooltip as="h3" keyHint={`plan-${plan.planId}-oop`} />
        <NumberWithPayPeriod
          value={outOfPocket}
          payPeriodType="yearly"
          size="large"
          keyHint={`plan-${plan.planId}-oop-value`}
        />
      </PricingOverviewItem>
    </PricingOverviewWrapper>
  );
};
