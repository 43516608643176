import styled from 'styled-components';

import { cardStyling } from 'DesignLibrary/utils';

export const CtaCard = styled.div`
  ${cardStyling}
  flex: 1;

  h3 {
    text-align: center;
    font-weight: 900 !important;
  }
`;

export const IconBlock = styled.div`
  height: var(--sizing-2xl);
  width: var(--sizing-2xl);
  border-radius: var(--border-radius-xs);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  flex-grow: 0;
  background: var(--colors-blue-50);

  span {
    display: flex;
  }
`;
