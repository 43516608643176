import { push } from 'connected-react-router';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import { GlobalReducerState } from 'app/reducers';
import AlexIntro from 'Containers/AlexIntro';
import { makeSelectConfigField, makeSelectGlobalField } from 'Containers/App/selectors';
import HsaPage from 'Containers/HsaPage';
import OverviewPage from 'Containers/OverviewPage';
import ProfilePage from 'Containers/ProfilePage';
import { makeGetSelectedEnrollmentEventId, makeSelectClientSurvey } from 'Containers/ProfilePage/selectors';
import ResultPage from 'Containers/ResultPage';
import ReviewPage from 'Containers/ReviewPage';
import TaxSavingsPage from 'Containers/TaxSavingsPage';
import UnifiedLandingPage from 'Containers/UnifiedLandingPage';
import ViewBenefitPage from 'Containers/ViewBenefitPage';
import ViewPlanPage from 'Containers/ViewPlanPage';
import { useTextContext } from 'Contexts/textContext';
import Error500, { BrowserNavError } from 'Shared/Error500';
import { ErrorContainer } from 'Shared/Error500/styled';
import { ClientSurveySchema } from 'Utils/apiTypes';
import injectSaga from 'Utils/injectSaga';
import {
  WELCOME_PATH,
  INTRO_PATH,
  PROFILE_PATH,
  RESULT_PATH,
  ERROR_PATH,
  HSA_PLUS_PATH,
  OVERVIEW_PATH,
  REVIEW_PATH,
  TAX_SAVINGS_PATH,
  VIEW_BENEFIT_PATH,
  VIEW_PLAN_PATH,
} from 'Utils/urls';

import saga from './saga';

const UNIFIED_LANDING_ROUTES: {
  path: string;
  component: any;
  exact: boolean;
}[] = [
  {
    path: WELCOME_PATH,
    component: UnifiedLandingPage,
    exact: true,
  },
  {
    path: INTRO_PATH,
    component: AlexIntro,
    exact: true,
  },
];

// TODO what on earth is the type of `component` here?
const ROUTES: { path: string; component: any; exact: boolean }[] = [
  {
    path: PROFILE_PATH,
    component: ProfilePage,
    exact: true,
  },
  {
    path: RESULT_PATH,
    component: ResultPage,
    exact: true,
  },
  {
    path: HSA_PLUS_PATH,
    component: HsaPage,
    exact: true,
  },
  {
    path: TAX_SAVINGS_PATH,
    component: TaxSavingsPage,
    exact: true,
  },
  {
    path: `${VIEW_PLAN_PATH}/:planId`,
    component: ViewPlanPage,
    exact: false,
  },
  {
    path: REVIEW_PATH,
    component: ReviewPage,
    exact: true,
  },
];

const FBS_ROUTES: { path: string; component: any; exact: boolean }[] = [
  {
    path: OVERVIEW_PATH,
    component: OverviewPage,
    exact: true,
  },
  {
    path: `${VIEW_BENEFIT_PATH}/:insuranceType`,
    component: ViewBenefitPage,
    exact: true,
  },
];

export interface CommercialV2ParentProps {
  isUnderConstruction: boolean;
}

interface CommercialV2StateProps {
  isFullBenefitEnabled: boolean;
  enrollmentEventId?: string;
  clientSurvey: ClientSurveySchema | null;
  builderCustomerKey: string | null;
  hasUnsavedProfileChanges: boolean;
}

interface CommercialV2DispatchProps {
  redirectToPath: (path: string) => void;
}

export type CommercialV2Props = CommercialV2ParentProps & CommercialV2StateProps & CommercialV2DispatchProps;

export const CommercialV2 = ({
  isFullBenefitEnabled,
  hasUnsavedProfileChanges,
  redirectToPath,
}: CommercialV2Props) => {
  const { isError } = useTextContext();
  const [isBnf, setIsBnf] = useState(false);

  useEffect(() => {
    const path = window.location.pathname;
    if (path !== PROFILE_PATH && hasUnsavedProfileChanges) {
      setIsBnf(true);
    } else if (isBnf) {
      setIsBnf(false);
    }
  }, []);

  useEffect(() => {
    if (isError) {
      redirectToPath(ERROR_PATH);
    }
  }, [isError]);

  const getRoutes = () => {
    let tempRoutes = [...UNIFIED_LANDING_ROUTES, ...ROUTES];

    if (isFullBenefitEnabled) {
      tempRoutes = [...tempRoutes, ...FBS_ROUTES];
    }

    return tempRoutes;
  };

  const routes = getRoutes();

  if (isBnf) {
    return (
      <ErrorContainer>
        <BrowserNavError errorRoute={() => redirectToPath(PROFILE_PATH)} />
      </ErrorContainer>
    );
  }

  return (
    <Switch>
      {routes.map((route, index) => (
        <Route
          exact={route.exact}
          key={index}
          path={route.path}
          render={(routeProps) => <route.component {...routeProps} />}
        />
      ))}
      <Route render={() => <Error500 />} path={ERROR_PATH} exact />
    </Switch>
  );
};

const mapStateToProps = createStructuredSelector<GlobalReducerState, CommercialV2StateProps>({
  isFullBenefitEnabled: makeSelectConfigField('is_fbs_enabled'),
  enrollmentEventId: makeGetSelectedEnrollmentEventId(),
  clientSurvey: makeSelectClientSurvey(),
  builderCustomerKey: makeSelectConfigField('builder_customer_key'),
  hasUnsavedProfileChanges: makeSelectGlobalField('hasUnsavedProfileChanges'),
});

export function mapDispatchToProps(dispatch): CommercialV2DispatchProps {
  return {
    redirectToPath: (path: string) => dispatch(push(path)),
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const withSaga = injectSaga<CommercialV2ParentProps>({
  key: 'commercialApp',
  saga,
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default compose(withConnect, withSaga)(CommercialV2);
