import { Box, Flex, Stack, useDisclosure } from '@chakra-ui/react';
import React from 'react';

import EducationalDrawer from 'Containers/_drawers/EducationalDrawer';
import { fetchShowVideoStatus } from 'Containers/App/utils';
import { useFeatureFlagContext } from 'Contexts/featureFlagContext';
import { Button, H4, Icon, Numbers, NumberWithPayPeriod, Paragraph } from 'DesignLibrary/atoms';
import { TooltipCaps } from 'DesignLibrary/molecules';
import Plan from 'Models/plan';
import RichText from 'Shared/RichText';
import Text from 'Shared/Text';
import { Recommendation } from 'Types/entities';
import { BuilderGoSettings } from 'Utils/apiTypes';
import { createHraTooltip, createHsaTooltip } from 'Utils/createTooltips';
import { toDollars } from 'Utils/helpers';

import { Container, NumbersContainer, TitleContainer } from './styled';

export interface PlanEligibilityProps {
  recommendation: Recommendation;
  selectedPlan: Recommendation | Record<string, never>;
  maximumAvailableAnnualSihraContribution: number;
  individualHsaContributionLimit: number;
  familyHsaContributionLimit: number;
  hsaContributionLimit: number | null;
  builderGoSettings: BuilderGoSettings | null;
}

export const PlanEligibility: React.FC<PlanEligibilityProps> = ({
  recommendation,
  selectedPlan,
  maximumAvailableAnnualSihraContribution,
  individualHsaContributionLimit,
  familyHsaContributionLimit,
  hsaContributionLimit,
  builderGoSettings,
}) => {
  const { isOpen: hsaIsOpen, onOpen: hsaOnOpen, onClose: hsaOnClose } = useDisclosure();
  const { isOpen: hraIsOpen, onOpen: hraOnOpen, onClose: hraOnClose } = useDisclosure();
  const { is_educational_videos_enabled } = useFeatureFlagContext();

  const plan = new Plan(recommendation);
  const { hsaEligible, hraEligible, isEmployerMatching, employerHraContribution } = plan;

  const employerHsaContribution = plan.calcEmployerHsaContribution(hsaContributionLimit);

  const employerHsaMatch = plan.calcEmployerHsaMatch(hsaContributionLimit);

  const recommendedHsaContribution = plan.calcRecommendedHsaContribution(hsaContributionLimit);

  const hsaTaxSavings = plan.calcHsaTaxBenefit(hsaContributionLimit);

  let planType;
  if (hsaEligible) planType = 'hsa';
  if (hraEligible) planType = 'hra';
  if (maximumAvailableAnnualSihraContribution > 0) planType = 'sihra';

  let employerContribution = 0;
  if (hsaEligible) employerContribution = employerHsaContribution || 0;
  if (hraEligible) employerContribution = employerHraContribution || 0;

  const isOpen = planType === 'hsa' ? hsaIsOpen : hraIsOpen;
  const handleOpenInfoModal = planType === 'hsa' ? hsaOnOpen : hraOnOpen;
  const handleCloseInfoModal = planType === 'hsa' ? hsaOnClose : hraOnClose;

  const color = 'blue';

  if (!planType) {
    return null;
  }

  const renderHsa = () => {
    const contributionTooltip = {
      ...createHsaTooltip(
        plan.isEmployerMatching,
        plan.calcEmployerHsaContribution(hsaContributionLimit),
        plan.employerHsaContributionLimit,
        plan.employerHsaPercentageMatch,
      ),
    };

    return (
      <NumbersContainer>
        {!!employerHsaContribution &&
          (isEmployerMatching ? (
            <div className="row">
              <TooltipCaps
                as="h3"
                keyHint={`plan-${plan.planId}-oop-cost-hsa-match`}
                tooltip={contributionTooltip}
              >
                <Text field="health_section.oop_cost_receipt.hsaMatch" />
              </TooltipCaps>
              <Numbers>+{toDollars(employerHsaMatch)}</Numbers>
            </div>
          ) : (
            <div className="row">
              <TooltipCaps
                as="h3"
                keyHint={`plan-${plan.planId}-oop-cost-hsa-contribution`}
                tooltip={contributionTooltip}
              >
                <Text field="health_section.oop_cost_receipt.hsaContribution" />
              </TooltipCaps>
              <Numbers>+{toDollars(employerHsaContribution)}</Numbers>
            </div>
          ))}
        {/* Recommended HSA Contribution */}
        <div className="row">
          <TooltipCaps
            as="h3"
            keyHint={`plan-${plan.planId}-hsa-contribution`}
            tooltip={{
              title: <Text field="tool_tips.recommended_hsa_contribution.title" />,
              text: <Text field="tool_tips.recommended_hsa_contribution.text" />,
            }}
          >
            <Text field="tool_tips.recommended_hsa_contribution.underline" />,
          </TooltipCaps>
          <NumberWithPayPeriod
            value={recommendedHsaContribution}
            precision={0}
            size="small"
            payPeriodType="yearly"
            keyHint={`plan-${plan.planId}-recommended-hsa-contribution`}
          />
        </div>
        {/* HSA Tax Savings */}
        <div className="row">
          <TooltipCaps
            as="h3"
            keyHint={`plan-${plan.planId}-yearly-tax-savings`}
            tooltip={{
              title: <Text field="hsa.interactive_section.tooltips.yearly_tax_savings.title" />,
              text: <Text field="hsa.interactive_section.tooltips.yearly_tax_savings.text" />,
            }}
          >
            <Text field="hsa.interactive_section.tooltips.yearly_tax_savings.title" />
          </TooltipCaps>
          <NumberWithPayPeriod
            value={hsaTaxSavings}
            prefix="+"
            precision={0}
            size="small"
            payPeriodType="yearly"
            keyHint={`plan-${plan.planId}-hsa-tax-savings`}
          />
        </div>
      </NumbersContainer>
    );
  };

  const hraTooltip = createHraTooltip(employerHraContribution);

  const renderHra = () => {
    // Only render NumbersContainer for HRA eligible plans if there is an employer HRA contribution to display
    if (!!employerHraContribution && employerHraContribution > 0)
      return (
        <NumbersContainer>
          <div className="row">
            <TooltipCaps
              as="h3"
              tooltip={hraTooltip}
              keyHint={`plan-${plan.planId}-oop-cost-hra-contribution`}
            >
              <Text field="health_section.oop_cost_receipt.hraContribution" />
            </TooltipCaps>
            <Numbers>+{toDollars(employerHraContribution)}</Numbers>
          </div>
        </NumbersContainer>
      );
    return null;
  };

  const renderSihra = () => (
    <NumbersContainer>
      <Box p={4} className="centered">
        <Paragraph>
          <RichText
            field="health_details_section.eligibility_notice.sihra_contribution"
            vars={[toDollars(maximumAvailableAnnualSihraContribution)]}
          />
        </Paragraph>
      </Box>
    </NumbersContainer>
  );

  const isHraWithNoMatch = planType === 'hra' && !employerHraContribution;

  const shouldShowEducationalVideo =
    !is_educational_videos_enabled ||
    (!!builderGoSettings && builderGoSettings[fetchShowVideoStatus(planType)]);

  return (
    <Container singleColumn={isHraWithNoMatch} data-testid={`${planType}-eligible-card`}>
      <TitleContainer color={color}>
        <Stack align="center" gap={2}>
          <Stack align="center" gap="0px" role="heading" aria-level={2}>
            <span className="cap-title">
              <Text field="health_details_section.plan_eligibility.subtitle" />
            </span>
            <Flex align="center" gap={2}>
              <Icon type="Coins" weight="duotone" size="large" color="--primary-blue" />
              <H4 as="p" color="--primary-blue" weight="bold" className="title-type">
                {planType.toUpperCase()}
                <Text field="health_details_section.plan_eligibility.title" />
              </H4>
            </Flex>
          </Stack>
          <Button
            color="blue"
            buttonType="primary"
            size="small"
            onClick={handleOpenInfoModal}
            data-testid="learn-more-plan-eligibility"
          >
            <Text field="health_details_section.plan_eligibility.buttonText" />
          </Button>
        </Stack>
      </TitleContainer>
      {planType === 'hsa' && renderHsa()}
      {planType === 'hra' && renderHra()}
      {planType === 'sihra' && renderSihra()}

      <EducationalDrawer
        handleClose={handleCloseInfoModal}
        isOpen={isOpen}
        infoType={planType}
        individualHsaContributionLimit={individualHsaContributionLimit}
        familyHsaContributionLimit={familyHsaContributionLimit}
        employerContribution={employerContribution}
        selectedPlan={selectedPlan}
        shouldShowEducationalVideo={planType === 'sihra' || shouldShowEducationalVideo}
      />
    </Container>
  );
};
