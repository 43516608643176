import React, { MouseEvent, FC, useState, useRef, useEffect } from 'react';

import { DropdownList, Option } from './DropdownList';
import { DropdownContainer } from './styled';
import { FadeUp } from '../../atoms/_transitions/FadeUp';
import { Button, ButtonOptions } from '../../atoms/Button';

export interface DropdownProps {
  label?: string;
  options: Option[];
  selectedValue: string;
  groupName: string;
  buttonProps: ButtonOptions;
  isOpen: boolean;
  horizontalPosition?: 'left' | 'right';
  keyHint: string;
  handleChange: (e: React.MouseEvent<HTMLButtonElement>, value: string) => void;
}

export const Dropdown: FC<DropdownProps> = ({
  label,
  options,
  selectedValue,
  groupName,
  buttonProps,
  isOpen = false,
  horizontalPosition = 'right',
  keyHint,
  handleChange,
}): JSX.Element => {
  const [selection, setSelection] = useState(selectedValue);

  // Automatically grab the index of the selectedValue to populate selectionIndex
  let selectedIdx = 0;
  options.forEach((option, index) => {
    if (option.value === selectedValue) selectedIdx = index;
  });
  const [selectionIndex, setSelectionIndex] = useState<number>(selectedIdx);

  const [focusIndex, setFocusIndex] = useState<number>(selectedIdx);
  const [isDropdownOpen, setDropdownOpen] = useState(isOpen);
  const dropdownButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (selectedValue !== selection) {
      setSelection(selectedValue);
      const idx = options.findIndex((i) => i.value === selectedValue);
      setSelectionIndex(idx);
    }
  }, [selectedValue]);

  const handleClose = (clickedOutside: boolean) => {
    setDropdownOpen(false);

    if (dropdownButtonRef.current && !clickedOutside) dropdownButtonRef.current.focus();
  };

  const toggleOpen = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const onChange = (e: React.MouseEvent<HTMLButtonElement>, value: string, index: number) => {
    if (value !== selection) {
      setSelection(value);
      setSelectionIndex(index);
      setFocusIndex(index);
      handleChange(e, value);
    }
  };

  const onFocusChange = (index: number) => {
    setFocusIndex(index);
  };

  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'ArrowDown' && !isDropdownOpen) {
      toggleOpen();
    }
    if (e.key === 'Home') {
      toggleOpen();
      setFocusIndex(0);
    }
    if (e.key === 'End') {
      toggleOpen();
      setFocusIndex(options.length - 1);
    }
  };

  const dropdownListId = `dropdown-list-${keyHint}`;

  return (
    <DropdownContainer>
      <Button
        data-testid="dropdown-button"
        isDisabled={buttonProps.isDisabled || false} // set default to false
        className="dropdown-button"
        size="small"
        onClick={toggleOpen}
        onKeyDown={handleKeyPress}
        aria-controls={dropdownListId}
        aria-expanded={isDropdownOpen}
        aria-haspopup="listbox"
        aria-label={groupName}
        role="combobox"
        tabindex={0}
        aria-activedescendant={isDropdownOpen ? `${options[focusIndex].value}-${focusIndex}` : ''}
        ref={dropdownButtonRef}
        {...buttonProps}
      >
        {options.filter((option) => option.value === selection)[0].display}
      </Button>
      {isDropdownOpen && (
        <FadeUp isOpen={isDropdownOpen}>
          <DropdownList
            label={label}
            options={options}
            selected={selection}
            selectedIndex={selectionIndex}
            focusedIndex={focusIndex}
            isDisabled={buttonProps.isDisabled}
            horizontalPosition={horizontalPosition}
            containerId={dropdownListId}
            handleChange={onChange}
            handleFocusChange={onFocusChange}
            handleClose={handleClose}
          />
        </FadeUp>
      )}
    </DropdownContainer>
  );
};
