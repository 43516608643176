// TODO - replace all these with string union
const RiskAversionValues = ['high', 'medium', 'low'];
export type RiskAversion = (typeof RiskAversionValues)[number];

/* Action Types */

export type UpdateMemberFields = {
  [K in keyof Member]?: Member[K];
};

export interface MemberSectionConfigFields {
  collectTobaccoUsageForPolicyholder: boolean;
  collectTobaccoUsageForSpouse: boolean;
  collectIsSpouseOrDomesticPartner: boolean;
  isProductBc: boolean;
  enablePlannedProcedures: boolean;
  sex_at_birth_enabled: boolean;
  allow_sex_at_birth_no_answer_enabled: boolean;
}

/* Household Interfaces */

export interface Utilization {
  // TODO added | '' to allow the initialization.  can we initialize this to null?
  inpatient_days: number | '';
  specialist_visits: number | '';
  pcp_visits: number | '';
  mental_health_visits: number | '';
}

export interface PlannedProcedures {
  // TODO added | '' to allow the initialization.  can we initialize this to null?
  //   Abir - I just copied this comment, sorry idk what to do with it
  inpatient_procedures: number | '';
  outpatient_procedures: number | '';
  routine_therapy_visits: number | '';
  mental_health_visits: number | '';
}

export const genderValues = ['male', 'female', 'prefer_not_to_answer', ''];
export type Gender = (typeof genderValues)[number];

export enum MEMBER_TYPE {
  POLICYHOLDER = 'policyholder',
  SPOUSE = 'spouse',
  DOMESTIC_PARTNER = 'domestic_partner',
  SPOUSE_OR_DOMESTIC_PARTNER = 'spouse_domestic_partner',
  CHILD = 'child',
}

export interface Member {
  external_id: string;
  member_type: MEMBER_TYPE;
  age: string;
  gender: Gender;
  uses_tobacco: boolean | null;
  uses_prescriptions: boolean | null;
  prescriptions: Prescription[];
  uses_utilization: boolean | null;
  utilization: Utilization;
  uses_planned_procedures: boolean | null;
  planned_procedures: PlannedProcedures;
  income: string;
  isSkipIncome: boolean;
  isValid: boolean;
  pristine: boolean;
  isCovered: boolean;
  isComplete: boolean;
}

export interface Prescription {
  ndc: string;
  displayName: string;
  drugForm: string;
}

const RiskAssessmentAnswerValues = ['', 'plan_a', 'plan_b'];
export type RiskAssessmentAnswer = (typeof RiskAssessmentAnswerValues)[number];

export enum CAPACITY_TO_PAY {
  CAP_LT_500 = 'lt_500',
  CAP_500_1000 = '500_1000',
  CAP_1000_2000 = '1000_2000',
  CAP_2000_3000 = '2000_3000',
  CAP_3000_4000 = '3000_4000',
  CAP_4000_6000 = '4000_6000',
  CAP_GT_6000 = 'gt_6000',
}

const capacityToPayValues = [
  '',
  CAPACITY_TO_PAY.CAP_LT_500,
  CAPACITY_TO_PAY.CAP_500_1000,
  CAPACITY_TO_PAY.CAP_1000_2000,
  CAPACITY_TO_PAY.CAP_2000_3000,
  CAPACITY_TO_PAY.CAP_3000_4000,
  CAPACITY_TO_PAY.CAP_4000_6000,
  CAPACITY_TO_PAY.CAP_GT_6000,
];

export type CapacityToPayAnswer = (typeof capacityToPayValues)[number];

const planChildQuestionValues = ['yes', 'no', ''];

export interface Survey {
  risk_question_1: RiskAssessmentAnswer;
  risk_question_2: RiskAssessmentAnswer;
  capacity_to_pay: CapacityToPayAnswer;
  plan_child_question: (typeof planChildQuestionValues)[number];
  enrollment_question: string;
}

/* Selector Interfaces */
export type ClientSurveyQuestion = {
  description: string;
  type: 'string' | 'number';
  enum?: string[];
  hideChoices?: boolean;
  pattern?: string;
};

export type ClientSurveyResponses = { zip5?: string } & Record<string, string | number | null>;

export type ClientSurveyResponsesByYear = Record<string, ClientSurveyResponses>;

/* API Data Interfaces */

export interface CountyResult {
  county_fips: string;
  county_name: string;
  ma_region: string;
  pdp_region: string;
  state: string;
  state_fips: string;
  state_name: string;
  zip_code: string;
}

export interface RxDropdownProperty {
  [drug: string]: {
    key: string;
    text: string;
    value: string;
  }[];
}

export interface RxSearchItem {
  key: string;
  title: string;
  description: string;
}

export interface RxSearchResults {
  dropdown: RxDropdownProperty;
  search: RxSearchItem[];
}

export type ProfileSection =
  | 'enrollment'
  | 'eligibility'
  | 'location'
  | 'member'
  | 'pregnancy'
  | 'income'
  | 'riskAssessment'
  | 'capacityToPay'
  | 'incentives';

export interface IncentiveSurveyQuestion {
  id: number;
  name: string;
  incentive_type: 'premium' | 'health_fund';
  question_type: IncentiveSurveyQuestionType;
  is_surcharge: boolean;
  question: string;
}

export interface ResultPageIncentiveSurveyData {
  incentiveSurvey: IncentiveSurveyQuestion[];
  incentiveSurveyAnswers: Record<string, IncentiveSurveyAnswer | null>;
}

export type IncentiveSurveyQuestionType = 'fill_in_the_blank' | 'yes_or_no' | 'yes_or_no_include_spouse';

export type IncentiveType = 'health_fund' | 'premium';

export interface IncentiveSurveyAnswer {
  jv_incentive_id: string;
  expected_incentive_amount: string | null;
  choice: 'employee' | 'employee+spouse' | 'spouse' | 'yes' | 'no' | null;
}
