import styled, { css } from 'styled-components';

interface ContainerProps {
  isLanding: boolean;
}

export const Container = styled.div<ContainerProps>`
  position: relative;
  z-index: 1299;
  width: 100%;

  .header-wrapper {
    position: relative;
    z-index: 9999;
  }

  header {
    height: calc(var(--sizing-2xl) + var(--sizing-xs));
    background: var(--primary-white);
  }

  @media print {
    display: none;
  }

  .right-toggles {
    button {
      font-size: 14px;
      padding: 12px;
    }
  }
`;

interface LogoWrapperProps {
  second?: boolean;
}

export const LogoWrapper = styled.div<LogoWrapperProps>`
  height: var(--sizing-lg);
  flex-shrink: 0;

  ${({ second }) =>
    second &&
    css`
      border-left: 2px solid var(--border-gray);
      padding-left: var(--sizing-sm);
    `}

  img {
    height: 100%;
    object-fit: contain;
    max-width: 200px;
    transform-origin: top left;
    transition: 0.4s transform;
  }
`;

export const NavContainer = styled.div`
  position: relative;
  z-index: 0;

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: var(--sizing-sm);
    position: absolute;
    left: 0;
    top: 0;
    background: var(--primary-white);
  }
`;

interface NavButtonProps {
  active: boolean;
}

export const NavButton = styled.button<NavButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--sizing-xs);
  user-select: none;
  min-width: var(--sizing-3xl);
  padding: 0 var(--sizing-md);
  background: var(--primary-white);
  border: 2px solid var(--input-gray);
  border-radius: 4rem;
  height: var(--sizing-lg);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;

  span {
    color: var(--text-gray);
  }

  &:hover {
    border-color: var(--text-black);
    color: var(--text-black);
    background: var(--colors-blue-50);

    span {
      color: var(--text-black);
    }
  }

  &:active {
    background: var(--primary-white);
  }

  ${({ active }) =>
    active &&
    css`
      cursor: default;
      background: var(--primary-blue);
      border-color: var(--primary-blue);
      color: var(--primary-white);
      pointer-events: none;

      span {
        color: var(--primary-white);
      }
    `}

  &:disabled {
    cursor: not-allowed;
    color: var(--text-gray-light);
    border-color: var(--border-gray);

    span {
      color: var(--text-gray-placeholder);
    }

    &:hover,
    &:focus {
      color: var(--text-gray-light);
      border-color: var(--border-gray);
      background: var(--primary-white);

      span {
        color: var(--text-gray-placeholder);
      }
    }
  }
`;
