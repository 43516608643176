import styled from 'styled-components';

import { breakpoint } from 'DesignLibrary/global';
import { cardStyling } from 'DesignLibrary/utils';

export const PlanCardWrapper = styled.div`
  ${cardStyling}
  overflow: hidden;
  padding: var(--sizing-sm);
`;

interface PlanCardHeaderProps {
  border: boolean;
}

export const PlanCardHeader = styled.div<PlanCardHeaderProps>`
  display: flex;
  align-items: center;
  gap: var(--sizing-sm);
  margin-bottom: var(--sizing-sm);

  div {
    display: flex;
    flex-direction: row;
    align-items: center;

    div {
      margin-right: var(--sizing-sm);
    }
  }
`;

export const PlanCardRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: var(--sizing-sm);
  align-items: center;
  gap: var(--sizing-sm);
  border-radius: var(--border-radius-xs);

  ${breakpoint.TABLET} {
    padding: var(--sizing-md);
  }

  &:nth-child(2n + 1) {
    background: var(--background-gray-light);
  }
`;
