import React from 'react';

import { TextField } from 'Containers/App/types';
import { H5 } from 'DesignLibrary/atoms';
import RichText from 'Shared/RichText';
import Text from 'Shared/Text';
import { toDollars } from 'Utils/helpers';

import { HowContainer } from './styled';

interface HowItWorksProps {
  infoType: 'hsa' | 'hra' | 'fsa';
  individualHsaContributionLimit: number | undefined;
  familyHsaContributionLimit: number | undefined;
  employerContribution: number | null;
}

export const HowItWorks = ({
  infoType,
  individualHsaContributionLimit,
  familyHsaContributionLimit,
  employerContribution,
}: HowItWorksProps) => {
  const headerText: TextField = `health_section.secondary_content.${infoType}_modal.how_does_it_work_header_text`;
  const content: TextField = `health_section.secondary_content.${infoType}_modal.how_does_it_work_content`;

  let vars;
  if (infoType === 'hsa') {
    vars = [toDollars(individualHsaContributionLimit), toDollars(familyHsaContributionLimit)];
  } else if (infoType === 'hra') {
    vars = [toDollars(employerContribution)];
  }

  return (
    <HowContainer>
      <H5 as="h2">
        <Text field={headerText} />
      </H5>
      <RichText vars={vars} field={content} />
    </HowContainer>
  );
};
