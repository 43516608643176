import { get } from 'lodash';
import React from 'react';

import { TextField } from 'Containers/App/types';
import { ViewPlanPageCard } from 'Containers/ViewPlanPage/ViewPlanPageCard';
import { PlanCustomization } from 'ContentfulDefaults/types/customizations';
import { useFeatureFlagContext } from 'Contexts/featureFlagContext';
import { useTextContext } from 'Contexts/textContext';
import { Paragraph } from 'DesignLibrary/atoms';
import { IconBlockProps } from 'DesignLibrary/molecules/IconBlock';
import Plan from 'Models/plan';
import RichText from 'Shared/RichText';
import Text from 'Shared/Text';
import { Recommendation } from 'Types/entities';
import { toDollars } from 'Utils/helpers';

import { PlanBullets } from './styled';

interface OtherNotesCardProps {
  recommendation: Recommendation;
  hsaContributionLimit: number;
}

export const OtherNotesCard: React.FC<OtherNotesCardProps> = ({ recommendation, hsaContributionLimit }) => {
  const { retrieveContentfulData } = useTextContext();

  // Remove when retiring feature flag APP-1028-oop-estimate-uses-comm-api
  const { is_real_cost_before_tax_benefit_enabled } = useFeatureFlagContext();

  // TODO move this contentful field over from `health_section` to `health_details_section`
  //  and change from "Other Plan Notes" to "Other Notes"
  const title = retrieveContentfulData<string>('health_section.other_plan_notes.title');
  const icon: IconBlockProps = {
    icon: 'ListBullets',
    color: 'blue',
  };

  // useLegacyCostCalc is true when is_real_cost_before_tax_benefit_enabled is false.
  // Remove arg when retiring feature flag APP-1028-oop-estimate-uses-comm-api
  const plan = new Plan(
    recommendation,
    !is_real_cost_before_tax_benefit_enabled, // Remove arg when retiring feature flag APP-1028-oop-estimate-uses-comm-api
  );

  const { oopEstimate, hsaEligible, inNetworkOopMax } = plan;

  const isInNetworkOopLimitComprehensive = plan.getIsInNetworkOopLimitComprehensive();
  const employerHsaContribution = plan.calcEmployerHsaContribution(hsaContributionLimit) as number;

  const customizations = retrieveContentfulData<PlanCustomization[]>('plan_customizations', []);
  const planIndex = customizations.findIndex((item) => item.plan_id === plan.planId);
  const customNotes = get(customizations[planIndex], 'custom_plan_notes', []);
  return (
    <ViewPlanPageCard title={title} icon={icon}>
      {(hsaEligible || isInNetworkOopLimitComprehensive || customNotes.length > 0) && (
        <PlanBullets>
          <ul>
            {hsaEligible && employerHsaContribution > oopEstimate && (
              <>
                <li>
                  <Paragraph>
                    <Text
                      vars={{
                        remainder: toDollars(employerHsaContribution - oopEstimate),
                        hsaContribution: toDollars(employerHsaContribution),
                        oopEstimate: toDollars(oopEstimate),
                      }}
                      field="health_section.other_plan_notes.hsaEligible.employerContribution_>_oopEstimate.employer"
                    />
                  </Paragraph>
                </li>
                <li>
                  <Paragraph>
                    <Text field="health_section.other_plan_notes.hsaEligible.employerContribution_>_oopEstimate.personal" />
                  </Paragraph>
                </li>
              </>
            )}
            {hsaEligible && oopEstimate < hsaContributionLimit && (
              <li>
                <Paragraph>
                  <Text field="health_section.other_plan_notes.hsaEligible.oopEstimate_<_hsaLimit" />
                </Paragraph>
              </li>
            )}
            {isInNetworkOopLimitComprehensive && (
              <li>
                <Paragraph>
                  <Text
                    vars={{
                      inNetworkMax: toDollars(inNetworkOopMax),
                    }}
                    field="health_section.other_plan_notes.comprehensiveInNetworkOopLimit"
                  />
                </Paragraph>
              </li>
            )}
            {hsaEligible && employerHsaContribution > 0 && (
              <li>
                <Paragraph>
                  <Text field="health_section.other_plan_notes.hsaEligible.employerContribution" />
                </Paragraph>
              </li>
            )}
            {customNotes.map((note, index) => (
              <li key={`note-${index}`}>
                <Paragraph>{note}</Paragraph>
              </li>
            ))}
          </ul>
        </PlanBullets>
      )}
      {planIndex >= 0 && (
        <PlanBullets>
          <Paragraph>
            <RichText field={`plan_customizations[${planIndex}].general_note` as TextField} />
          </Paragraph>
        </PlanBullets>
      )}
    </ViewPlanPageCard>
  );
};
