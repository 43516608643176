export const GET_HSA_RECOMMENDATION_REQUEST = 'app/CommercialV2/HsaPage/GET_HSA_RECOMMENDATION_REQUEST';
export const GET_HSA_RECOMMENDATION_SUCCESS = 'app/CommercialV2/HsaPage/GET_HSA_RECOMMENDATION_SUCCESS';
export const GET_HSA_RECOMMENDATION_FAILURE = 'app/CommercialV2/HsaPage/GET_HSA_RECOMMENDATION_FAILURE';

export const FORECAST_HSA_SUCCESS = 'app/CommercialV2/HsaPage/FORECAST_HSA_SUCCESS';
export const FORECAST_HSA_FAILURE = 'app/CommercialV2/HsaPage/FORECAST_HSA_FAILURE';

export const GET_RETIREMENT_FORECASTS = 'app/CommercialV2/HsaPage/GET_RETIREMENT_FORECASTS';
export const GET_RETIREMENT_FORECASTS_SUCCESS = 'app/CommercialV2/HsaPage/GET_RETIREMENT_FORECASTS_SUCCESS';
export const GET_RETIREMENT_FORECASTS_FAILURE = 'app/CommercialV2/HsaPage/GET_RETIREMENT_FORECASTS_FAILURE';

export const LOCK_HSA_CONTRIBUTION = 'app/CommercialV2/HsaPage/LOCK_HSA_CONTRIBUTION';
export const SET_HSA_BALANCE_AND_PERSONA = 'app/CommercialV2/HsaPage/SET_HSA_BALANCE_AND_PERSONA';

// Forecasting constants
export const INVESTMENT_RETURN_PERCENT = 6;
export const MIN_DESIRED_BALANCE = 2000;
export const MALE_LIFE_EXPECTANCY = 86;
export const FEMALE_LIFE_EXPECTANCY = 88;
export const RETIREMENT_AGE = 65;
