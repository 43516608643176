import { ContentfulFbsTokensSection } from 'ContentfulDefaults/types/fbsTokens';

export const fbs: {
  fbs_tokens: ContentfulFbsTokensSection;
} = {
  fbs_tokens: {
    cancer: 'Cancer (Internal or Invasive)',
    crowns: 'Crowns',
    inlays: 'Inlays',
    lenses: 'Lenses',
    stroke: 'Stroke',
    fillings: 'Fillings',
    orthodontia: 'Orthodontia',
    'skin-cancer': 'Skin Cancer',
    'dental-xrays': 'Dental X-Rays',
    'heart-attack': 'Heart Attack (Myocardial Infarction)',
    'admission-icu': 'Admission (ICU)',
    'ambulance-air': 'Ambulance - Air',
    'contact-lenses': 'Contact Lenses',
    'intensive-care': 'Intensive Care',
    'confinement-icu': 'Confinement (ICU)',
    'inpatient-rehab': 'Inpatient Rehab',
    'ambulance-ground': 'Ambulance - Ground',
    'health-screening': 'Health Screening',
    'oral-examination': 'Oral Examination Copay / Coinsurance',
    '1000-lifetime-max': '$1,000 lifetime maximum for child(ren) under age 19. Adult ortho not covered',
    '500-per-admission': '$500 per admission',
    'admission-non-icu': 'Admission (non-ICU)',
    'carcinoma-in-situ': 'Carcinoma in situ',
    'frames-and-lenses': 'Frames',
    'prophylaxis-adult': 'Prophylaxis - Adult',
    'prophylaxis-child': 'Prophylaxis - Child',
    '1000-per-admission': '1,000 per admission',
    'deductible-then-50': 'Deductible then 50%',
    'deductible-then-80': 'Deductible then 80%',
    'deductible-then-90': 'Deductible then 90%',
    'xray-and-emergency': 'X-ray and Emergency Room Services',
    'confinement-non-icu': 'Confinement (non-ICU)',
    'exclusions-ci1-demo':
      'The plan contains a 30-day waiting period. This means that no benefits are payable for anyone who has been diagnosed before your coverage has been in force 30 days from the effective date. If you are first diagnosed during the waiting period, benefits for treatment of that critical illness will apply only to loss starting after 12 months from the effective date or the employee can elect to void the coverage and receive a full refund of premium. The applicable benefit amount will be paid if: the date of diagnosis is after the waiting period; the date of diagnosis occurs while the certificate is in force; and the cause of the illness is not excluded by name or specific description.',
    'exclusions-hi1-demo':
      'The policy or its provisions may vary or be unavailable in some states. There is a preexisting condition limitation for hospital sickness benefits. MetLife’s Hospital Indemnity Insurance may be subject to benefit reductions that begin at age 65. Like most group accident and health insurance policies, policies offered by MetLife may contain certain exclusions, limitations and terms for keeping them in force. For complete details of coverage and availability, please refer to the group policy form GPNP12- AX or GPNP13-HI or contact MetLife. Benefits are underwritten by Metropolitan Life Insurance Company, New York, New York.',
    '50-per-calender-year': '$50 per calendar year',
    'description-add-demo':
      'You may elect Voluntary AD&D coverage in increments of $25,000. The minimum amount you may elect is $25,000. The maximum amount is the lesser of $500,000, or 10 times your annual earnings, rounded to the next lower multiple of $25,000, if not already a multiple of $25,000.',
    'description-hra-demo':
      'Your employer puts $1,050 into your HRA, and you receive the full contribution at the beginning of the plan year. Your account is automatically debited when you receive covered medical services.',
    'description-pet-demo':
      'Each pet you enroll will have a separate policy. If you enroll 2-3 pets, you will be eligible for a 5% multi-policy premium discount. Visit the AlliantCHOICE Plus website through WorkDay to select a Pet Insurance.',
    'description-std-demo':
      'STD benefits will begin to be paid to a disabled employee 14 days after the Disability Date. During the 14-day waiting period, available sick time and paid time off (PTO) must be used (in that order). After the 14-day waiting period, a disabled employee will receive STD payments equal to 100% of base salary for up to six (6) weeks.',
    'exclusions-acc1-demo':
      'Benefits are not paid for: (a) injury incurred before the effective date; (b) injury as a result of an on-the-job accident; (c) any act of war or participation in a riot, insurrection or rebellion; (d) self-inflicted injury; (e) suicide or attempted suicide; (f) being under the influence of alcohol or narcotics unless taken on the advice of a physician; (g) bacterial infection (except pyogenic infections from an accidental cut or wound); (h) participation in aeronautics unless a fare-paying passenger on a licensed common-carrier aircraft; (i) engaging in an illegal occupation, assault or felony; (j) driving in any race or speed test or testing any vehicle on any racetrack or speedway; (k) serving as an active member of the Military, Naval, or Air Forces of any country; and (l) hernia, including complications.',
    'hospital-confinement': 'Hospital Confinement',
    '150-allowance-vision1':
      '$150 allowance for contacts and contact lens exam (fitting and evaluation). 15% savings on a contact lens exam.',
    '180-allowance-vision1':
      '$180 allowance for a wide selection of frames. 20% savings on the amount over your allowance. $100 Costco or Walmart allowance.',
    'description-401k-demo':
      "401K — Your employer will match up to 5% of your pay in contributions to the 401(k) Plan. You choose how your contributions — and employer's matching contributions — are invested. Get started here: https://nb.fidelity.com/public/nb/default/home",
    'description-dis1-demo':
      'Your employer provides you with 100% paid Long Term Disability insurance. 50% of the first $2,000 of your pre-disability earnings, up to a maximum of $1,000 a month. Minimum Benefit is 10% before reductions for Other Income Benefits of $100, whichever is greater, subject to overpayments and rehabilitation incentive within the certificate. Please see Plan Details for more information.',
    'description-hsa1-demo':
      'If you enroll in a HSA eligible plan and open a health savings account, your employer will make the following contributions into your HSA account: $2,000 for individuals and $4,000 for a family. Note: If you are a new hire, HSA contributions are prorated based upon hire date during the year.',
    'description-ltd2-demo':
      '50% of the first $2,000 of your pre-disability earnings, up to a maximum of $1,000 a month. Minimum Benefit is 10% before reductions for Other Income Benefits of $100, whichever is greater, subject to overpayments and rehabilitation incentive within the certificate.',
    'description-life1-demo':
      'Your employer provides you with 100% paid Basic Term Life and AD&D insurance. Accidental Death & Dismemberment (AD&D) coverage complements your Basic Life insurance coverage and helps protect you 24 hours a day, 365 days a year. Please see Plan Details for more information.',
    'description-life2-demo':
      'Your employer provides you with 100% paid Basic Term Life insurance. Please see Full Plan Details for more information.',
    'major-organ-transplant': 'Major Organ Transplant',
    '100-a-day-up-to-15-days': '$100 a day, up to 15 days per admission',
    'dislocation-or-fracture': 'Dislocation or Fracture',
    'end-stage-renal-failure': 'End-Stage renal Failure',
    'initial-hospitalization': 'Initial Hospitilization',
    'description-commuter-demo':
      'When an employee enrolls in a commuter benefits program, they can pay for their commuting costs with pre-tax money, up to the current IRS tax limit of $270 / month. Because of the various ways employees commute, by car, bus, subways, trains, ferries, and vanpools, there are several ways employees can receive their benefit so that everyone is covered. Once employees have enrolled and provided their commuting information, they can then receive their commuter benefit product directly at home, or at the workplace',
    'health-screening-wellness': 'Health Screening (Wellness)',
    '100-a-day-for-up-to-31-days': '$100 a day for up to 31 days',
    '200-a-day-for-up-to-31-days': '$200 a day for up to 31 days',
    'description-fsamedical-demo':
      'Use ADP to sign up for a Health Care FSA within 31 days of your date of hire or appointment—or within 31 days of the date you receive your official Welcome Letter, whichever is later',
    'accident-physician-treatment': 'Accident Physician Treatment',
    'description-fsadependant-demo':
      'You may enroll in a Dependent Care FSA within 31 days of your date of hire or appointment or within 31 days of the time you receive your official Welcome Letter, whichever is later. To open up an account visit: https://www.wageworks.com',
    'coronary-artery-bypass-surgery': 'Coronary artery bypass surgery',
    'oral-surgery': 'Oral Surgery',
    'ncoa-medicare':
      'Thinking about retiring, turning 65 soon, or looking for information for a loved one? Start learning about Medicare enrollment now and get free, personalized Medicare advice.',
    'description-legal-demo':
      'Legal plans are a supplemental benefit that employers offer to provide employees access to a network of experienced attorneys.',
    'description-eap-demo':
      'An Employee Assistance Program, or EAP, is benefit designed to help employees resolve various issues related to mental and emotional well-being that may be affecting job performance.',
    'description-wellness-demo':
      'Wellness benefits programs are designed to help improve the overall health and wellbeing of employees, and they focus on reducing illness and improving quality of life.',
    'description-financial-wellness-demo':
      'Financial wellness benefits include a range of services and are designed to help employees improve their financial standings.',
    'description-life-add-demo':
      'Life and AD&D insurance are benefits that can help protect your loved ones from financial hardship due to death or serious injury.',
    'description-403b-demo':
      '403(b) plans let you save and invest a portion of your paycheck before taxes are taken out.',
    'description-teledoc-demo':
      'Telemedicine provides you with the ability to have a doctor’s visit over the phone to diagnose common illnesses and ailments.',
    'description-sihra-hub':
      'If you enroll in an alternative group medical plan and waive HUB coverage, the Health Plan Reimbursement Program will reimburse 100% of your covered out-of-pocket medical expenses, up to the Affordable Care Act (ACA) out-of-pocket limits. This includes your deductible, coinsurance and copays, but does not include your medical premiums.',
  },
};
