import React, { FC, useState, KeyboardEvent } from 'react';

import { Icon } from 'DesignLibrary/atoms/Icon';

import { CounterContainer, Button } from './styled';

export interface CounterProps {
  startValue: number;
  minValue: number;
  maxValue: number;
  disabled?: boolean;
  id: string;
  label: string;
  describedBy?: string;
  handleChange: (value: number) => void;
}

const Counter: FC<CounterProps> = ({
  startValue,
  minValue,
  maxValue,
  disabled = false,
  id,
  label,
  describedBy,
  handleChange,
}): JSX.Element => {
  const [value, setValue] = useState<number>(startValue);

  function parseOnlyInt(value: string): number {
    if (/^\d+$/.test(value)) {
      return parseInt(value, 10);
    }
    if (value === '') {
      return 0;
    }
    return NaN;
  }

  function tryChangeValue(value: number): void {
    if (value >= minValue && value <= maxValue) {
      setValue(value);
      handleChange(value);
    }
  }

  const onType = (e) => {
    const val = parseOnlyInt(e.target.value);
    if (!Number.isNaN(val)) {
      tryChangeValue(val);
    }
  };

  const decrement = () => {
    tryChangeValue(value - 1);
  };

  const increment = () => {
    tryChangeValue(value + 1);
  };

  const keyPressHandler = (e: KeyboardEvent) => {
    if (e.key === 'ArrowDown' || e.key === '-') decrement();
    if (e.key === 'ArrowUp' || e.key === '+') increment();
  };

  return (
    <CounterContainer>
      <Button
        className="left"
        data-testid="left"
        disabled={disabled || value <= minValue}
        onClick={() => decrement()}
        tabIndex={-1}
        aria-hidden
        role="button"
      >
        <Icon
          type="Minus"
          size="small"
          color={disabled || value <= minValue ? '--text-gray-light' : '--text-black'}
        />
      </Button>
      <input
        className="counter"
        id={id}
        aria-label={label}
        aria-describedby={describedBy}
        disabled={disabled}
        value={value}
        inputMode="numeric"
        onKeyDown={keyPressHandler}
        role="spinbutton"
        aria-valuemin={minValue}
        aria-valuemax={maxValue}
        aria-valuenow={value}
        onChange={onType}
      />
      <Button
        className="right"
        data-testid="right"
        disabled={disabled || value >= maxValue}
        onClick={() => increment()}
        tabIndex={-1}
        aria-hidden
        role="button"
      >
        <Icon
          type="Plus"
          size="small"
          color={disabled || value >= maxValue ? '--text-gray-light' : '--text-black'}
        />
      </Button>
    </CounterContainer>
  );
};

export default Counter;
