import styled from 'styled-components';

import { breakpoint } from 'DesignLibrary/global';

export const PlanHeaderContainer = styled.div`
  min-width: 0;
  flex-grow: 0;
  position: relative;

  * {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const SmallPlanTitle = styled.div`
  position: absolute;
  z-index: 2;
  bottom: -12px;
  left: 0;
  width: 100%;
  padding-top: 8px;
  background: var(--primary-white);

  .paragraph {
    font-family: var(--font-header) !important;
    text-align: center;
  }
`;

export const EmptyPlan = styled.div`
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: none;
  text-align: center;

  ${breakpoint.TABLET} {
    display: flex;
  }
`;
