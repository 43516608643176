import styled, { css } from 'styled-components';

import { media } from 'DesignLibrary/global';

interface PageLayoutProps {
  size: 'normal' | 'small';
  bg: string;
  page?: string;
}

export const PageLayoutContainer = styled.div<PageLayoutProps>`
  position: relative;
  width: 100%;
  background: var(${({ bg }) => bg || 'none'});
  transition: 0.75s background-color;

  ${({ page }) =>
    page === 'landing' &&
    css`
      overflow: hidden;
    `};

  ${({ page }) =>
    page === 'landing-footer' &&
    css`
      z-index: 2;
    `};

  .inner-wrapper {
    position: relative;
    width: 100%;
    margin: 0 auto;
    padding-left: var(--sizing-sm);
    padding-right: var(--sizing-sm);

    ${({ size }) =>
      size === 'normal' &&
      css`
        ${media.TABLET`
        padding-left: var(--sizing-lg);
        padding-right: var(--sizing-lg);
      `}

        ${media.DESKTOP`
        padding-left: var(--sizing-xxl);
        padding-right: var(--sizing-xxl);
      `}

      ${media.WIDEDESKTOP`
        max-width: 1164px;
        padding-left: 0;
        padding-right: 0;
      `}
      `}

    ${({ size }) =>
      size === 'small' &&
      css`
        ${media.TABLET`
        padding-left: var(--sizing-lg);
        padding-right: var(--sizing-lg);
        max-width: calc(800px + var(--sizing-xxl));
      `}

        ${media.DESKTOP`
        padding-left: 0;
        padding-right: 0;
        max-width: 800px;
      `}
      `}

    .red {
      border: 1px solid red;
    }

    ${({ page }) =>
      page === 'cta-bar' &&
      css`
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
      `}

    ${({ page }) =>
      page === 'profile' &&
      css`
        padding-top: var(--sizing-md);
        padding-bottom: var(--sizing-3xl);

        ${media.TABLET`
          padding-top: var(--sizing-lg);
        `}

        ${media.DESKTOP`
          padding-top: var(--sizing-xl);
          display: grid;
          grid-template-columns: auto minmax(240px, 320px);
          column-gap: var(--sizing-md);
          align-items: flex-start;
        `}
      `}

    ${({ page }) =>
      page === 'results' &&
      css`
        padding-top: var(--sizing-md);
        padding-bottom: var(--sizing-3xl);

        ${media.TABLET`
          padding-top: var(--sizing-lg);
        `}

        ${media.DESKTOP`
          padding-top: var(--sizing-xl);
        `}
      `}

    ${({ page }) =>
      page === 'compare-bar' &&
      css`
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
      `}
    
    ${({ page }) =>
      page === 'overview' &&
      css`
        padding-top: var(--sizing-xl);
        padding-bottom: var(--sizing-3xl);
      `}

    ${({ page }) =>
      page === 'review' &&
      css`
        padding-top: var(--sizing-md);
        padding-bottom: var(--sizing-3xl);

        ${media.TABLET`
          padding-top: var(--sizing-lg);
        `}

        ${media.DESKTOP`
          padding-top: var(--sizing-xl);
        `}
      `}
  }
`;
