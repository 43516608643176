import styled from 'styled-components';

import { media } from 'DesignLibrary/global';
import { cardStyling } from 'DesignLibrary/utils';

interface CtaCardProps {
  type: string;
}

export const Container = styled.div`
  ${cardStyling}
  padding: var(--sizing-md);

  @media print {
    box-shadow: none;
    border: var(--border);
  }

  .paragraph {
    b {
      color: var(--text-black);
    }
  }

  & > section + section {
    margin-top: var(--sizing-md);
  }

  .contact-info {
    & > * + * {
      margin-top: var(--sizing-sm);
    }
  }

  .cards {
    & > * + * {
      margin-top: var(--sizing-sm);
    }
  }
`;

export const NextStepsHeader = styled.section`
  display: flex;
  align-items: center;
  gap: var(--sizing-sm);
`;

export const CtaCard = styled.div<CtaCardProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--sizing-sm);
  padding: var(--sizing-md);
  border-radius: var(--border-radius-xs);
  background: ${({ type }) => {
    switch (type) {
      case 'pdf':
        return 'var(--background-gray)';
      case 'enroll':
      default:
        return 'var(--colors-blue-50)';
    }
  }};

  button {
    flex-shrink: 0;
  }

  > * {
    text-align: center;
  }

  ${media.TABLET`
    flex-direction: row;
    justify-content: space-between;

    > * {
      text-align: left;
    }
  `}
`;
