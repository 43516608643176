import React from 'react';

import { Icon, Paragraph } from 'DesignLibrary/atoms';
import RichText from 'Shared/RichText';
import Text from 'Shared/Text';

import { Container, Status } from './styled';

export type Risk = 'risky' | 'moderate' | 'safe';

export interface RatingProps {
  risk: Risk;
  oopChance: number;
}

export const Rating = ({ risk, oopChance }: RatingProps) => (
  <Container risk={risk}>
    <div className="status-container" aria-hidden>
      <Status state="risky" active={risk === 'risky'}>
        <Icon type="Warning" color={risk === 'risky' ? '--primary-white' : '--colors-opaque-40'} />
      </Status>
      <Status state="moderate" active={risk === 'moderate'}>
        <Icon type="WarningCircle" color={risk === 'moderate' ? '--primary-white' : '--colors-opaque-40'} />
      </Status>
      <Status state="safe" active={risk === 'safe'}>
        <Icon type="CheckCircle" color={risk === 'safe' ? '--primary-white' : '--colors-opaque-40'} />
      </Status>
    </div>
    <Paragraph size="small" color="--text-black">
      <b>
        {/* TODO: CONTENTFUL Update Field Location */}
        <RichText noWrapper field="hsa.interactive_section.coverage_statistic" vars={[oopChance]} />
      </b>{' '}
      {/* TODO: CONTENTFUL Update Field Location */}
      <Text field={`hsa.interactive_section.yearly_coverage_detail_${risk}`} />
    </Paragraph>
  </Container>
);
