import * as Sentry from '@sentry/react';
import { get, merge } from 'lodash';
import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';

import { TextField } from 'Containers/App/types';
import { ContentfulType } from 'ContentfulDefaults/ContentfulType';
import { getEnglishDefaults } from 'ContentfulDefaults/defaults/english/englishDefaults';
import { getSpanishDefaults } from 'ContentfulDefaults/defaults/spanish/spanishDefaults';
import { useFeatureFlagContext } from 'Contexts/featureFlagContext';
import { WELCOME_PATH, PROFILE_PATH, RESULT_PATH, BACKEND_HOST, BASE_PATH } from 'Utils/urls';

export enum CLIENT_MODEL_CONTENT_TYPES {
  logo = 'logo',
  display_name = 'display_name',
  eligibility_section = 'eligibility_section',
  top_level_navigation = 'top_level_navigation',
  unified_landing_page = 'unified_landing_page',
  profile_section = 'profile_section',
  tool_tips = 'tool_tips',
  people_like_you = 'people_like_you',
  health_section = 'health_section',
  health_details_section = 'health_details_section',
  benefits_section = 'benefits_section',
  benefits_section_add = 'benefits_section_add',
  benefits_section_additional = 'benefits_section_additional',
  benefits_section_dental = 'benefits_section_dental',
  benefits_section_vision = 'benefits_section_vision',
  benefits_section_hospital_indemnity = 'benefits_section_hospital_indemnity',
  benefits_section_commuter = 'benefits_section_commuter',
  benefits_section_medical_fsa = 'benefits_section_medical_fsa',
  benefits_section_fsa = 'benefits_section_fsa',
  benefits_section_life = 'benefits_section_life',
  benefits_section_retirement = 'benefits_section_retirement',
  benefits_section_disability = 'benefits_section_disability',
  review_section = 'review_section',
  plan_customizations = 'plan_customizations',
  fbs_tokens = 'fbs_tokens',
  hsa = 'hsa',
  spousal_plan_comparison = 'spousal_plan_comparison',
  call_to_action = 'call_to_action',
  alex_intro_page = 'alex_intro_page',
}

// We may want to actually type this specifically as an object with the keys '/', '/profile', and '/results'
const ROUTE_TO_QUERY_MAP: Record<string, string[]> = {
  [WELCOME_PATH]: [
    CLIENT_MODEL_CONTENT_TYPES.logo,
    CLIENT_MODEL_CONTENT_TYPES.display_name,
    CLIENT_MODEL_CONTENT_TYPES.eligibility_section,
    CLIENT_MODEL_CONTENT_TYPES.top_level_navigation,
    CLIENT_MODEL_CONTENT_TYPES.unified_landing_page,
    CLIENT_MODEL_CONTENT_TYPES.profile_section,
    CLIENT_MODEL_CONTENT_TYPES.call_to_action,
    CLIENT_MODEL_CONTENT_TYPES.alex_intro_page,
  ],
  [PROFILE_PATH]: [
    CLIENT_MODEL_CONTENT_TYPES.tool_tips,
    CLIENT_MODEL_CONTENT_TYPES.people_like_you,
    CLIENT_MODEL_CONTENT_TYPES.health_section,
    CLIENT_MODEL_CONTENT_TYPES.health_details_section,
  ],
  [RESULT_PATH]: [
    CLIENT_MODEL_CONTENT_TYPES.benefits_section,
    CLIENT_MODEL_CONTENT_TYPES.benefits_section_add,
    CLIENT_MODEL_CONTENT_TYPES.benefits_section_additional,
    CLIENT_MODEL_CONTENT_TYPES.benefits_section_dental,
    CLIENT_MODEL_CONTENT_TYPES.benefits_section_vision,
    CLIENT_MODEL_CONTENT_TYPES.benefits_section_hospital_indemnity,
    CLIENT_MODEL_CONTENT_TYPES.benefits_section_commuter,
    CLIENT_MODEL_CONTENT_TYPES.benefits_section_medical_fsa,
    CLIENT_MODEL_CONTENT_TYPES.benefits_section_life,
    CLIENT_MODEL_CONTENT_TYPES.benefits_section_retirement,
    CLIENT_MODEL_CONTENT_TYPES.benefits_section_disability,
    CLIENT_MODEL_CONTENT_TYPES.benefits_section_fsa,
    CLIENT_MODEL_CONTENT_TYPES.review_section,
    CLIENT_MODEL_CONTENT_TYPES.plan_customizations,
    CLIENT_MODEL_CONTENT_TYPES.fbs_tokens,
    CLIENT_MODEL_CONTENT_TYPES.hsa,
    CLIENT_MODEL_CONTENT_TYPES.spousal_plan_comparison,
  ],
};

export interface ITextContext {
  textMap: ContentfulType | Record<string, unknown>;
  retrieveContentfulData: <T>(key: string | TextField, defaultValue?: T) => T;
  isLoading: boolean;
  locale: string;
  setLocale: Dispatch<SetStateAction<string>>;
  isError: boolean;
  initialMap?: ContentfulType;
}

interface Props {
  children: JSX.Element;
  location: { pathname: string };
  initialMap?: ContentfulType;
}

export const useTextContext = (): ITextContext => useContext(TextContext);

export const TextContext = React.createContext<ITextContext>({
  textMap: {},
  isLoading: false,
  locale: 'en-US',
  setLocale: () => null,
  // @ts-expect-error (we don't want a default value here, but are required to set one)
  retrieveContentfulData: () => '',
  isError: false,
});

export function retrieveContentfulDataFromMap<T>(
  map: ContentfulType | Record<string, unknown>,
  key: string,
  defaultValue?: T,
): T {
  return get(map, key) || defaultValue;
}

const TextProviderComponent: (props: Props) => unknown = ({ children, location, initialMap = {} }) => {
  const [textMap, setTextMap] = useState<ContentfulType | Record<string, unknown>>(initialMap);
  const [englishMap, setEnglishMap] = useState({});
  const [spanishMap, setSpanishMap] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [locale, setLocale] = useState('en-US');
  const [visited, setVisited] = useState<string[]>([]);
  const [isError, setIsError] = useState<boolean>(false);
  const { useContentfulV2 } = useFeatureFlagContext();

  const fallbackToContentfulDefaults = (query: keyof ContentfulType) => {
    const defaults = locale === 'en-US' ? getEnglishDefaults(query) : getSpanishDefaults(query);
    setIsLoading(true);
    setTextMap((prevState) => merge(prevState, defaults));
  };

  async function loadData(query: string): Promise<void> {
    const contentfulUrl = `${BACKEND_HOST}${BASE_PATH}/customers/contentful_v2/?query=${query}&locale=${locale}`;

    const res = await fetch(contentfulUrl, { method: 'GET' });

    if (res.ok) {
      const data = await res.json();

      setIsLoading(true);
      if (locale === 'en-US') {
        setEnglishMap((prevState) => merge(prevState, data));
      } else {
        setSpanishMap((prevState) => merge(prevState, data));
      }
    } else if (res.status === 404) {
      // Allow 404s because many clients do not have the plan customizations section,
      // Or there _may_ be old clients missing newer section. In the former scenario,
      // not failing is the right behavior. In the latter... falling back to defaults
      // _shouldn't_ matter because the client _shouldn't_ be using that text,
      // but let's be safe until we have time to look into it.
      fallbackToContentfulDefaults(query as keyof ContentfulType);
    } else {
      Sentry.captureException(res.statusText);
      setIsError(true);
    }
  }

  // Loads data from current route
  useEffect(() => {
    const contentTypes = ROUTE_TO_QUERY_MAP[location.pathname];

    if (contentTypes && !get(textMap, contentTypes[0])) {
      setVisited((prevState) => [...prevState, location.pathname]);
      const promises: Promise<void>[] = [];

      for (let i = 0; i < contentTypes.length; i += 1) {
        promises.push(loadData(contentTypes[i]));
      }

      Promise.all(promises).then(() => {
        const currMap = locale === 'en-US' ? englishMap : spanishMap;
        setTextMap(currMap);
        setIsLoading(false);
      });
    }
  }, [location, useContentfulV2]);

  // Loads previous data in new locale then switches
  useEffect(() => {
    async function updateLocale() {
      for (let i = 0; i < visited.length; i += 1) {
        const contentTypes = ROUTE_TO_QUERY_MAP[visited[i]];

        // This currently skips if it is already in the map
        if (contentTypes && !get(locale === 'en-US' ? englishMap : spanishMap, contentTypes[0])) {
          const promises: Promise<void>[] = [];

          for (let j = 0; j < contentTypes.length; j += 1) {
            promises.push(loadData(contentTypes[j]));
          }

          Promise.all(promises).then(() => {
            setIsLoading(false);
          });
        } else {
          const currMap = locale === 'en-US' ? englishMap : spanishMap;
          setTextMap(currMap);
        }
      }
    }

    updateLocale().then(() => {
      document.documentElement.lang = locale === 'es-US' ? 'es' : 'en';
      setTextMap((prevState) => merge(locale === 'en-US' ? englishMap : spanishMap, prevState));
    });
  }, [locale, useContentfulV2]);

  return (
    <TextContext.Provider
      value={{
        isLoading,
        textMap,
        locale,
        setLocale,
        retrieveContentfulData: (key, defaultValue?) =>
          retrieveContentfulDataFromMap(textMap, key as string, defaultValue),
        isError,
      }}
    >
      {children}
    </TextContext.Provider>
  );
};

export const TextProvider = withRouter(TextProviderComponent);
