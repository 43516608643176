import { fromJS } from 'immutable';

export function wrapImmutableReducerInPojoTranslation(reducer) {
  /*
  Our state stored in Redux is now just a plain javascript object, not an Immutable object

  This returns a reducer that:
  1. turns that POJO into an immutable object, pass it to our old reducer that expects an
  Immutable object
  2. Transform the Immutable objected returned from our old reducer back into a POJO
  3. Return the new POJO
  */
  return (state, action) => {
    const immutablePrevState = fromJS(state);
    const immutableNewState = reducer(immutablePrevState, action);
    const mutableNewState = immutableNewState.toJS();
    return mutableNewState;
  };
}
