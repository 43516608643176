import styled from 'styled-components';

import { breakpoint } from 'DesignLibrary/global';
import { cardStyling, selectedBlueStyling } from 'DesignLibrary/utils';

interface CardContainerProps {
  isSelected: boolean;
  idx: unknown;
  'data-testid': string;
}

export const CardContainer = styled.div<CardContainerProps>`
  ${cardStyling}
  position: relative;

  .score-container-attached,
  .badge-container {
    display: none;
  }

  ${breakpoint.DESKTOP} {
    .score-container-attached,
    .badge-container {
      display: block;
      position: absolute;
      top: var(--sizing-md);
      right: 100%;
    }

    .score-container-attached {
      width: var(--sizing-2xl);
      overflow: hidden;
    }

    .badge-container {
      .badge {
        position: relative;
        left: var(--sizing-lg);
      }
    }
  }

  ${({ isSelected }) => isSelected && selectedBlueStyling}
`;

export const CompareCheck = styled.div`
  display: flex;
  justify-content: center;

  label {
    padding: var(--sizing-2xs) 0;
    border-radius: var(--border-radius-xs);
    border: var(--border-thick);
    border-color: var(--input-gray);
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
  }

  ${breakpoint.TABLET} {
    position: absolute;
    top: var(--sizing-sm);
    right: var(--sizing-lg);

    label {
      all: unset;
    }
  }
`;

export const MainContentContainer = styled.div`
  > * + * {
    margin-top: var(--sizing-xs);
  }
`;

// DELETING THIS WILL BREAK CURRENT RICHTEXT W/ TOOLTIPS
export const TooltipUnderline = styled.span`
  margin: 0;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.2);
  display: inline;
  color: inherit;
  &:hover {
    border-color: var(--border-gray);
  }
`;
