import {
  documentToReactComponents,
  Options as ContentfulOptions,
} from '@contentful/rich-text-react-renderer';
import { MARKS, BLOCKS, INLINES } from '@contentful/rich-text-types';
import { get } from 'lodash';
import React from 'react';

import { TextField } from 'Containers/App/types';
import { TooltipUnderline } from 'Containers/ResultPage/Recommendation/styled';
import { RichTextContent } from 'ContentfulDefaults/types/_shared';
import { usePremiumContext } from 'Contexts/premiumContext';
import { useTextContext } from 'Contexts/textContext';
import { FormattedTooltipProps } from 'DesignLibrary/atoms';
import { Tooltip } from 'DesignLibrary/atoms/Tooltip';
import { applyTextReplacements, PREMIUM_TEXT_REPLACEMENT, TextReplacements } from 'Utils/textReplacement';

export interface RichTextProps {
  field: TextField | undefined; // Contentful key, eg. 'coverageLevelTableHeader'
  fieldContent?: RichTextContent; // if present, precludes fetching of `field` from Contentful.
  functions?: React.MouseEventHandler<HTMLButtonElement>[];
  toolTips?: FormattedTooltipProps[];
  vars?: (string | number | JSX.Element)[] | null;
  noWrapper?: boolean;
  textReplacements?: TextReplacements;
}

const RichText = ({
  field,
  fieldContent = undefined,
  functions,
  toolTips = [],
  vars,
  noWrapper = true,
  textReplacements = undefined,
}: RichTextProps) => {
  const { textMap, locale } = useTextContext();
  const { useContributionTerm } = usePremiumContext();

  let functionIndex = 0;
  let varIndex = 0;
  let tipIndex = 0;

  const incrementFunction = () => {
    if (functions && functions.length > 0) {
      try {
        const func = functions[functionIndex];
        functionIndex += 1;
        return func;
      } catch (error) {
        return undefined;
      }
    }
    return undefined;
  };

  const incrementVars = () => {
    if (vars && vars.length > 0) {
      try {
        const variable = vars[varIndex];
        varIndex += 1;
        return variable;
      } catch (error) {
        return null;
      }
    }
    return undefined;
  };

  const incrementTips = (content) => {
    if (toolTips.length > 0) {
      try {
        const tip = toolTips[tipIndex];
        tipIndex += 1;
        return (
          <Tooltip
            id={`tooltip-${field}-${tipIndex}`}
            label={{
              text: tip.text,
              title: tip.title,
            }}
          >
            {/* TODO: Use the useUnderline prop in DL Tooltip instead of this underline styled components */}
            <TooltipUnderline>{tip.underline ? tip.underline : content}</TooltipUnderline>
          </Tooltip>
        );
      } catch (error) {
        return null;
      }
    }
    // Fail loudly: when the tooltip is missing return text as underlined without any tooltip so it is obvious we need to fix the tooltip
    return <u>{content}</u>;
  };

  const buildLink = (node, content) => (
    <a href={node.data.uri} target="_blank" rel="noreferrer">
      {content}
    </a>
  );

  // Possible rendering options
  let options: ContentfulOptions = {
    renderNode: {
      [INLINES.HYPERLINK]: (node, content) => buildLink(node, content),
    },
  };

  const functionOptions = {
    renderMark: {
      [MARKS.BOLD]: (content) => (
        <button type="button" onClick={incrementFunction()}>
          {content}
        </button>
      ),
    },
    renderNode: {
      [INLINES.HYPERLINK]: (node, content) => buildLink(node, content),
    },
  };

  const varOptions = {
    renderMark: {
      [MARKS.CODE]: () => incrementVars(),
      [MARKS.UNDERLINE]: (content) => incrementTips(content),
    },
    renderNode: {
      [INLINES.HYPERLINK]: (node, content) => buildLink(node, content),
    },
  };

  const noParagraphOptions = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (_, content) => content,
      [INLINES.HYPERLINK]: (node, content) => buildLink(node, content),
    },
  };

  // If the customer uses contribution terms, replace "premium" with "contribution" in selected locale
  let allTextReplacements = {
    ...textReplacements,
  };

  if (useContributionTerm) {
    allTextReplacements = {
      ...allTextReplacements,
      ...PREMIUM_TEXT_REPLACEMENT[locale],
    };
  }

  // replace /n (which is inserted when contentful user types shift + enter in richText field) with <br/>
  const newlineOptions = {
    // taken from docs --> https://www.npmjs.com/package/@contentful/rich-text-react-renderer
    renderText: (text: string): JSX.Element[] => {
      return text.split('\n').map((textSegment, index) => (
        <React.Fragment key={index}>
          {index > 0 && <br />}
          {applyTextReplacements(textSegment, allTextReplacements)}
        </React.Fragment>
      ));
    },
  };

  // Toggle options according to props
  let textField = fieldContent || get(textMap, field as string);

  if (vars || toolTips) {
    options = {
      ...options,
      ...varOptions,
    };
  }
  if (functions) {
    options = {
      ...options,
      ...functionOptions,
    };
  }
  if (noWrapper) {
    options = {
      ...options,
      ...noParagraphOptions,
    };
  }

  options = {
    ...options,
    ...newlineOptions,
  };

  textField = documentToReactComponents(textField, options);

  if (typeof textField === 'undefined') {
    textField = (
      <div id={field} className="contentful-error">
        {' '}
      </div>
    );
  }

  return textField;
};

export default RichText;
