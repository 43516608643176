import { fromJS } from 'immutable';
import { isEmpty } from 'lodash';
import { createSelector } from 'reselect';

import { GlobalReducerState } from 'app/reducers';
import { COVERAGE_TIERS, DEFAULT_BUILDER_GO_SETTINGS } from 'Containers/App/constants';
import {
  makeSelectCommercialField,
  makeSelectConfigField,
  makeSelectGlobalField,
  makeSelectJvpField,
} from 'Containers/App/selectors';
import { AlexIdUser, JVPState } from 'Containers/App/types';
import { IProfilePageReducerState } from 'Containers/ProfilePage/reducer';
import { Household, HouseholdMember, Prescription } from 'Types/entities';
import {
  BuilderGoSettings,
  ClientSurveySchema,
  JVPBootstrapPublicationInfo,
  JVPEnrollmentEvent,
} from 'Utils/apiTypes';
import { TypedMap } from 'Utils/immutable-types';

import { ClientSurveyResponsesByYear, CountyResult, Gender, Member, Survey } from './types';

const selectProfileDomain = (state: GlobalReducerState): TypedMap<IProfilePageReducerState> =>
  fromJS(state).get('profilePage');

const selectProfileDomainAsJS = (state: GlobalReducerState): IProfilePageReducerState => state.profilePage;

const makeSelectProfileField = <K extends keyof IProfilePageReducerState>(field: K) =>
  createSelector(
    selectProfileDomainAsJS,
    (substate: IProfilePageReducerState): IProfilePageReducerState[K] => substate[field],
  );

const makeSelectIsHouseholdLoaded = () =>
  createSelector(selectProfileDomain, (substate) => substate.get('householdIsLoaded'));

const makeGetHouseholdId = () =>
  createSelector(
    [makeSelectGlobalField('user'), makeSelectProfileField('household_id')],
    (user, commHouseholdId): string | null => {
      // In a developer context,
      //   it may be useful to hard-code your integrated (EITS) or ALEX ID here.
      if (user?.alex_id_uuid) {
        return user.alex_id_uuid;
      }
      return commHouseholdId;
    },
  );

const makeGetSelectedPublicationKey = () =>
  createSelector(
    makeSelectSurvey(),
    makeSelectJvpField('active'),
    makeGetIntegratedUserPublicationKey(),
    (
      survey: Survey,
      active: JVPBootstrapPublicationInfo | null,
      integratedUserPublicationKey: 'active' | 'upcoming' | null,
    ): 'active' | 'upcoming' => {
      if (integratedUserPublicationKey) {
        return integratedUserPublicationKey;
      }

      if (survey?.enrollment_question) {
        return survey?.enrollment_question.split('-')[0] as 'active' | 'upcoming';
      }

      return active ? 'active' : 'upcoming';
    },
  );

const makeGetIntegratedUserPublicationKey = () =>
  createSelector(
    [
      makeSelectJvpField('active'),
      makeSelectJvpField('upcoming'),
      makeSelectProfileField('customerProductKey'),
    ],
    (
      active: JVPBootstrapPublicationInfo | null,
      upcoming: JVPBootstrapPublicationInfo | null,
      customerProductKey: string | null,
    ): 'active' | 'upcoming' | null => {
      if (customerProductKey) {
        const publicationSlug = customerProductKey.split('/')[1];
        if (publicationSlug === active?.publication.slug) {
          return 'active';
        }
        if (publicationSlug === upcoming?.publication.slug) {
          return 'upcoming';
        }
      }

      return null;
    },
  );

const makeGetSelectedPublicationInfo = () =>
  createSelector(
    [makeSelectGlobalField('jvp'), makeGetSelectedPublicationKey()],
    (jvp: JVPState, key: string): JVPBootstrapPublicationInfo | null => jvp[key],
  );

const makeGetSelectedPublicationPlanYear = () =>
  createSelector(
    [makeGetSelectedPublicationInfo()],
    (publicationInfo: JVPBootstrapPublicationInfo | null): number | undefined =>
      publicationInfo?.publication.plan_year,
  );

const makeGetSelectedPublicationSegmentSlug = () =>
  createSelector(
    [makeGetSelectedPublicationInfo()],
    (publicationInfo: JVPBootstrapPublicationInfo | null): string | undefined =>
      publicationInfo?.segment?.slug,
  );

const makeGetSelectedEnrollmentEventId = () =>
  createSelector(
    [makeGetSelectedPublicationInfo()],
    (publicationInfo: JVPBootstrapPublicationInfo | null): string | undefined =>
      publicationInfo?.enrollment_event.id,
  );

const makeGetSelectedEnrollmentEvent = () =>
  createSelector(
    [makeGetSelectedPublicationInfo()],
    (publicationInfo: JVPBootstrapPublicationInfo | null): JVPEnrollmentEvent | undefined =>
      publicationInfo?.enrollment_event,
  );

const makeGetBuilderGoSettings = () =>
  createSelector(
    [makeGetSelectedPublicationInfo()],
    (publicationInfo: JVPBootstrapPublicationInfo | null): BuilderGoSettings | null =>
      publicationInfo?.publication.go_settings || DEFAULT_BUILDER_GO_SETTINGS,
  );

const makeGetBuilderGoSetting = (setting: string) =>
  createSelector([makeGetBuilderGoSettings()], (builderGoSettings: BuilderGoSettings | null): boolean =>
    builderGoSettings && setting in builderGoSettings
      ? builderGoSettings[setting]
      : DEFAULT_BUILDER_GO_SETTINGS[setting],
  );

const makeGetIsDuringOE = () =>
  createSelector(
    [makeSelectJvpField('upcoming')],
    (upcoming: JVPBootstrapPublicationInfo | null): boolean => {
      const todayTimeStamp = new Date().setHours(0, 0, 0, 0);

      const oeStart = upcoming?.publication.oe_start_date;
      const oeEnd = upcoming?.publication.oe_end_date;

      const oeStartTimeStamp = new Date(`${oeStart?.substring(0, 10)}T00:00:00.000`).getTime();
      const oeEndTimeStamp = new Date(`${oeEnd?.substring(0, 10)}T00:00:00.000`).getTime();
      return !!oeStart && !!oeEnd && todayTimeStamp >= oeStartTimeStamp && todayTimeStamp <= oeEndTimeStamp;
    },
  );

/* Eligibility section selectors */
const makeSelectClientSurvey = () =>
  createSelector([selectProfileDomain, makeGetSelectedPublicationKey()], (substate, year: string) => {
    const clientSurvey: TypedMap<ClientSurveySchema> | TypedMap<null> = substate.getIn([
      'clientSurvey',
      year,
    ]);
    if (clientSurvey) {
      return clientSurvey.toJS();
    }
    return null;
  });

const makeSelectShowClientSurvey = () =>
  createSelector([makeSelectClientSurvey()], (survey: ClientSurveySchema | null) =>
    Boolean(survey && !isEmpty(survey.properties)),
  );

const makeGetSurveyQuestions = () =>
  createSelector(
    /* Parses JSONSchema properties into map of questions */
    makeSelectClientSurvey(),
    (survey: ClientSurveySchema | null) => {
      if (survey && survey.properties) {
        return Object.entries(survey.properties).reduce(
          (acc, [key, val]) => ({
            [key]: val,
            ...acc,
          }),
          {},
        );
      }

      return {};
    },
  );

const makeSelectClientSurveyResponses = () =>
  createSelector(selectProfileDomain, (substate) => {
    const responses = substate.get('clientSurveyResponses');
    return responses.toJS() as ClientSurveyResponsesByYear;
  });

const makeGetPayCycle = () =>
  createSelector(
    /* Used to determine the timeframe that an employee is paid through (eg. 9 months, 12 months) */
    [makeSelectClientSurveyResponses(), makeSelectConfigField('client')],
    (responses, client) => {
      // might be able to remove?
      if ('pay_cycle' in responses && client === 'cmu') {
        if ((responses.pay_cycle as unknown) === '18') {
          return 9;
        }
      }

      if (client === 'flourbluffschools') {
        if ((responses.pay_frequency as unknown) === 'Bi-weekly for 10 months') {
          return 9;
        }
      }

      return 12;
    },
  );

const makeSelectClientSurveyIsLoaded = () =>
  createSelector(selectProfileDomain, (substate) => substate.get('clientSurveyIsLoaded'));

/* Location section selectors */

const makeSelectCountyResults = () =>
  createSelector(selectProfileDomain, (substate) => {
    const countyResults: CountyResult[] = substate.get('countyResults').toJS();
    return countyResults;
  });

const makeGetStateCodeOptions = () =>
  createSelector([makeSelectCountyResults()], (response) => response.map((county) => county.state));

/* Member section selectors */

const makeSelectValidPolicyholderOrUndefined = () =>
  createSelector(selectProfileDomain, (substate) => {
    const policyholder = substate.get('policyholder').toJS();
    if (policyholder.isValid && !policyholder.pristine) {
      return policyholder;
    }
    return undefined;
  });

/*
Deprecated in favor of makeSelectValidPolicyholderOrUndefined. Now should only be used by the profile
page.
*/
const makeSelectPolicyholder = () =>
  createSelector(selectProfileDomain, (substate) => substate.get('policyholder').toJS());

const makeSelectValidSpouseOrUndefined = () =>
  createSelector(selectProfileDomain, (substate) => {
    const spouse = substate.get('spouse').toJS();
    return spouse.isValid && !spouse.pristine ? spouse : undefined;
  });

/*
Deprecated in favor of makeSelectValidSpouseOrUndefined. Now should only be used by the profile
page.
*/
const makeSelectSpouse = () =>
  createSelector(selectProfileDomain, (substate) => substate.get('spouse').toJS());

const makeSelectValidDependents = () =>
  createSelector(selectProfileDomain, (substate) =>
    substate
      .get('dependents')
      .toJS()
      .filter((dependent) => dependent.isValid && !dependent.pristine && dependent.age < 26),
  );

/*
Deprecated in favor of makeSelectValidSpouseOrUndefined. Now should only be used by the profile
page.
 */
const makeSelectDependents = () =>
  createSelector(selectProfileDomain, (substate) => substate.get('dependents').toJS());

const makeGetActiveHousholdMembers = () =>
  createSelector(selectProfileDomain, (substate) => substate.get('activeMembers').toJS());

const makeSelectDependentsCount = () =>
  createSelector(makeSelectDependents(), (dependents) => dependents.filter((dep) => dep.isValid).length);

const makeGetCoveredHouseholdMemberCount = () =>
  createSelector([makeSelectValidSpouseOrUndefined(), makeSelectValidDependents()], (spouse, dependents) => {
    let count = 1; // initialize at 1 for policyholder

    count += dependents.concat([spouse]).filter((member) => member?.isCovered).length;
    return count;
  });

const makeGetCanBearChild = () =>
  createSelector(
    [makeSelectPolicyholder(), makeSelectSpouse(), makeSelectDependents()],
    (policyholder, spouse, dependents) =>
      dependents
        .concat([policyholder, spouse])
        .filter(
          (member) =>
            (member.gender === 'female' || member.gender === 'prefer_not_to_answer') && member.age >= 12,
        ).length > 0,
  );

function makeSexOrError(value: Gender): Gender | null {
  if (value === undefined) {
    throw Error('undefined gender');
  }
  if (value === '') {
    return null;
  }
  return value;
}

function makeNumberOrError(value: number | ''): number {
  if (value === '') {
    throw Error('found an empty string in utilization');
  }
  return value;
}

function makeApiMemberOrError(
  member: Member,
  isPolicyholder: boolean,
  isDependant: boolean,
  externalId?: string,
  jvpUserId?: string,
  householdId?: string | null,
  user?: AlexIdUser,
): HouseholdMember {
  const prescriptions = member.prescriptions
    .filter((result) => result.ndc !== '')
    .map((d) => ({
      ndc: d.ndc,
      quantity: 0,
      frequency: 0,
    }));

  // todo why would we prefer householdId if alex_id_uuid is set?
  //   i would think alex_id_uuid == jvpUserId.
  const id = user?.alex_id_uuid ? householdId : jvpUserId;

  const specialistAndMentalHealthVisits =
    makeNumberOrError(member.utilization.specialist_visits) +
    makeNumberOrError(member.utilization.mental_health_visits);

  return {
    // this external_id logic makes me sad
    external_id: id || externalId || member.external_id,
    gender: makeSexOrError(member.gender),
    age: parseInt(member.age, 10),
    dependant: isDependant,
    policyholder: isPolicyholder,
    uses_tobacco: member.uses_tobacco || false,
    member_type: member.member_type,
    prescriptions: prescriptions || [],
    utilization: {
      pcp_visits: makeNumberOrError(member.utilization.pcp_visits),
      specialist_visits: specialistAndMentalHealthVisits,
      inpatient_days: makeNumberOrError(member.utilization.inpatient_days),
    },
    planned_procedures: {
      inpatient_procedures: makeNumberOrError(member.planned_procedures.inpatient_procedures),
      outpatient_procedures: makeNumberOrError(member.planned_procedures.outpatient_procedures),
      routine_therapy_visits: makeNumberOrError(member.planned_procedures.routine_therapy_visits),
      mental_health_visits: makeNumberOrError(member.planned_procedures.mental_health_visits),
    },
  };
}

const makeGetHouseholdMembers = () =>
  createSelector(
    [
      makeSelectJvpField('userId'),
      makeSelectCommercialField('external_id'),
      makeSelectPolicyholder(),
      makeSelectSpouse(),
      makeSelectDependents(),
      makeGetHouseholdId(),
      makeSelectGlobalField('user'),
    ],
    (jvpUserId, externalId, policyholder, spouse, dependents, householdId, user) => {
      const members: HouseholdMember[] = [
        // Add policyholder by default
        makeApiMemberOrError(policyholder, true, false, externalId, jvpUserId, householdId, user),
      ];

      // Add spouse if spouse form was filled out
      if (spouse.isValid && !spouse.pristine) {
        members.push(makeApiMemberOrError(spouse, false, false));
      }

      // Add filled out dependents
      dependents
        .filter((dep) => dep.isValid && !dep.pristine)
        .map((m) => members.push(makeApiMemberOrError(m, false, true)));

      return members;
    },
  );

// Selector to format states into a household expected by Commercial API
const makeGetHousehold = () =>
  createSelector(
    [
      makeGetHouseholdMembers(),
      makeGetHouseholdIncome(),
      makeSelectSurvey(),
      makeGetMonthlyHRAEmployerContribution(),
      makeSelectShowClientSurvey(),
      makeSelectConfigField('collect_tax_inputs'),
      makeSelectJvpField('userId'),
      makeSelectClientSurvey(),
      makeGetProfileFieldsForHousehold(),
      makeSelectGlobalField('user'),
    ],
    (
      members,
      income,
      survey,
      employerHraContribution,
      showClientSurvey,
      collectTaxInputs,
      userId,
      clientSurveyQuestions,
      profileFields,
      user,
    ): Household => {
      const { zipcode, stateCode, filingStatus, clientSurveyResponses } = profileFields;
      const clientSurvey = {
        client_survey: showClientSurvey && !isEmpty(clientSurveyResponses) ? clientSurveyResponses : null,
      };
      const policyholder = members.find((m) => m.policyholder)!;
      const spouse = members.find((m) => !m.policyholder && !m.dependant);

      // Handle special jvp standard eqs
      const clientSurveyQuestionsKeys = clientSurveyQuestions
        ? Object.keys(clientSurveyQuestions.properties)
        : [];

      if (clientSurveyQuestionsKeys.includes('age') && clientSurvey?.client_survey) {
        clientSurvey.client_survey.age = policyholder.age;
      }

      if (clientSurveyQuestionsKeys.includes('tobacco') && clientSurvey?.client_survey) {
        clientSurvey.client_survey.tobacco = policyholder.uses_tobacco ? 'Yes' : 'No';
      }

      if (clientSurveyQuestionsKeys.includes('spouse_age') && clientSurvey?.client_survey) {
        if (spouse?.age) {
          clientSurvey.client_survey.spouse_age = spouse.age;
        } else {
          // unset answers handled in comm api so deleting this question won't cause errors
          delete clientSurvey.client_survey.spouse_age;
        }
      }

      if (clientSurveyQuestionsKeys.includes('spouse_tobacco') && clientSurvey?.client_survey) {
        clientSurvey.client_survey.spouse_tobacco = spouse?.uses_tobacco ? 'Yes' : 'No';
      }

      const household: Household = {
        version: new Date().getFullYear(),
        zip_code: zipcode,
        survey: [
          {
            question_id: '3',
            answer: survey.plan_child_question || 'no',
          },
          {
            question_id: '5',
            answer: survey.risk_question_1,
          },
          {
            question_id: '6',
            answer: survey.risk_question_2,
          },
          {
            question_id: '9',
            answer: survey.capacity_to_pay,
          },
        ],
        members,
        income,
        spending_accounts: [
          {
            type: 'hsa',
            employer_contribution: 0,
          },
          {
            type: 'hra',
            employer_contribution: employerHraContribution,
          },
        ],
        // only send client_survey field if feature flag is enabled for forwards compatibility
        ...clientSurvey,
        // only send tax inputs if feature flag is enabled
        ...(collectTaxInputs && {
          state: stateCode,
          filing_status: filingStatus,
        }),
      };

      // todo this seems bad.
      //   ideally we should manage this state via redux but using `external_id`
      //   as it currently exists seems tangled with SSO behaviors we don't
      //   intend to tap into (?)
      if (userId) {
        // todo i think these values are always the same?
        const id = user?.alex_id_uuid ? user.alex_id_uuid : userId;

        policyholder.external_id = id;
        household.external_id = id;
      }

      return household;
    },
  );

// this is because reselect limits selector arguments at 12
const makeGetProfileFieldsForHousehold = () =>
  createSelector(
    [
      makeSelectProfileField('zipcode'),
      makeSelectProfileField('state_code'),
      makeSelectProfileField('filing_status'),
      makeSelectProfileField('clientSurveyResponses'),
      makeGetSelectedPublicationKey(),
    ],
    (zipcode, stateCode, filingStatus, clientSurveyResponses, enrollmentYear) => {
      const responses = clientSurveyResponses[enrollmentYear];
      const profileFields = {
        zipcode,
        stateCode,
        filingStatus,
        clientSurveyResponses: responses,
      };
      return profileFields;
    },
  );

const makeGetCoverageTier = () =>
  createSelector(
    [
      makeSelectValidPolicyholderOrUndefined(),
      makeSelectValidSpouseOrUndefined(),
      makeSelectValidDependents(),
    ],
    (policyholder, spouse, dependents) => {
      const dependentsCount = dependents.filter((dep) => dep.isCovered).length;
      const hasSpouse = spouse && spouse.isCovered;

      if (hasSpouse) {
        if (dependentsCount > 0) {
          return COVERAGE_TIERS.FAMILY;
        }
        return COVERAGE_TIERS.INDIVIDUAL_SPOUSE;
      }
      if (dependentsCount > 0) {
        // Don't use INDIVIDUAL_CHILDREN for now until we need it, it's new.
        //   I don't believe it needs more testing, but we're in a hurry
        // if (dependentsCount > 1) {
        //   return COVERAGE_TIERS.INDIVIDUAL_CHILDREN
        // }
        return COVERAGE_TIERS.INDIVIDUAL_CHILD;
      }
      return COVERAGE_TIERS.INDIVIDUAL;
    },
  );

const makeGetIsFamily = () => createSelector(makeGetCoveredHouseholdMemberCount(), (count) => count > 1);

const makeGetHasSpouse = () => createSelector(makeSelectSpouse(), (spouse) => spouse.isValid);

/* Income Section */

const makeGetHouseholdIncome = () =>
  createSelector([makeSelectPolicyholder(), makeSelectSpouse()], (policyholder, spouse) => {
    const policyHolderIncome = policyholder.income === '' ? 0 : parseInt(policyholder.income, 10);
    const spouseIncome = spouse.income === '' ? 0 : parseInt(spouse.income, 10);
    const totalIncome = policyHolderIncome + spouseIncome;
    return totalIncome;
  });

/* Prescription section selectors */

const makeSelectDrugResults = () =>
  createSelector(selectProfileDomain, (substate) => substate.get('rxResults').toJS());

const makeSelectRxIsLoading = () =>
  createSelector(selectProfileDomain, (substate) => substate.get('rxIsLoading'));

const makeSelectRxIsSkipped = () =>
  createSelector(selectProfileDomain, (substate) => substate.get('rxIsSkipped'));

const makeSelectPrescriptions = () =>
  createSelector(
    // This selector is used to render multiple rows of drug search inputs
    selectProfileDomain,
    (substate) => substate.get('prescriptions').toJS(),
  );

const makeGetHouseholdPrescriptions = () =>
  createSelector(
    // This selector is used to construct a valid list of drugs expected by Commercial API
    makeSelectPrescriptions(),
    (drugs): Prescription[] =>
      drugs
        .filter((result) => result.ndc !== '')
        .map((d) => ({
          ndc: d.ndc,
          quantity: 0,
          frequency: 0,
        })),
  );

/* Capacity, Risk sections */

const makeSelectSurvey = () =>
  createSelector(selectProfileDomain, (substate) => substate.get('survey').toJS());

const makePreviousSelectedPlan = () =>
  createSelector(selectProfileDomain, (substate) => substate.get('previousPlan').toJS());

/* Misc */
const makeGetYearlyHRAEmployerContribution = () =>
  createSelector(
    [
      makeGetCoveredHouseholdMemberCount(),
      makeSelectConfigField('hra_employer_contribution_individual'),
      makeSelectConfigField('hra_employer_contribution_family'),
    ],
    (memberCount, indvContribution, familyContribution) => {
      if (memberCount > 1 && (familyContribution || 0) > 0) {
        return familyContribution;
      }
      if ((indvContribution || 0) > 0) {
        return indvContribution;
      }

      return null;
    },
  );

const makeGetMonthlyHRAEmployerContribution = () =>
  createSelector(
    makeGetYearlyHRAEmployerContribution(),
    (yearlyContribution) => (yearlyContribution || 0) / 12,
  );

const makeGetHealthPlanMemberIds = () =>
  /* Used to get selected member ids */
  createSelector(
    [
      makeSelectValidPolicyholderOrUndefined(),
      makeSelectValidSpouseOrUndefined(),
      makeSelectValidDependents(),
      makeGetHouseholdId(),
      makeSelectJvpField('userId'),
      makeSelectGlobalField('user'),
    ],
    (policyholder, spouse, dependents, householdId, userId?, user?): string[] => {
      const coveredMembers: string[] = [];
      if (policyholder) {
        // todo this should be able to be simplified.
        // todo userId is what we want.
        //  I'd want to update policyholder.external_id to be correct but I
        //  can't update that state.
        if (user?.alex_id_uuid && householdId) {
          coveredMembers.push(householdId);
        } else {
          coveredMembers.push(userId || policyholder.external_id);
        }
      }
      if (spouse && spouse.isCovered) {
        coveredMembers.push(spouse.external_id);
      }
      dependents.forEach((m: Member) => {
        if (m.isCovered) {
          coveredMembers.push(m.external_id);
        }
      });

      return coveredMembers;
    },
  );

const makeSelectIsUsingBuilderConfig = () =>
  createSelector(
    makeGetSelectedEnrollmentEventId(),
    (enrollmentEventId: string | undefined) => !!enrollmentEventId,
  );

const makeSelectShouldShowTobaccoQuestion = (memberType: 'policyholder' | 'spouse') =>
  createSelector(
    [
      makeSelectConfigField('collect_tobacco_usage'),
      makeSelectIsUsingBuilderConfig(),
      makeGetSurveyQuestions(),
    ],
    (collect_tobacco_usage, isUsingBuilderConfig, surveyQuestions): boolean => {
      if (!isUsingBuilderConfig) {
        return collect_tobacco_usage;
      }
      if (memberType === 'policyholder' && 'tobacco' in surveyQuestions) {
        return true;
      }
      if (memberType === 'spouse' && 'spouse_tobacco' in surveyQuestions) {
        return true;
      }

      return false;
    },
  );

const makeGetSelectedProduct = () =>
  createSelector(
    makeSelectConfigField('has_product_bc'),
    makeSelectConfigField('has_product_go'),
    makeSelectGlobalField('selectedProduct'),
    (hasBc, hasGo, selectedProduct) => {
      if (selectedProduct) {
        return selectedProduct;
      }
      if (hasBc && !hasGo) {
        return 'bc';
      }
      if (hasGo && !hasBc) {
        return 'go';
      }
      return '';
    },
  );

/**
 * Checks both zipcode and client survey responses in redux to determine if the profile is empty or not
 */
const makeGetProfileIsEmpty = () =>
  createSelector(
    [makeSelectProfileField('zipcode'), makeSelectClientSurveyResponses(), makeGetSelectedPublicationKey()],
    (zipcode, clientSurveyResponses, publicationKey) => {
      let clientSurveyHasResponses = false;
      // Check if client survey is loaded with questions
      const clientSurveyMayHaveResponses = clientSurveyResponses && clientSurveyResponses[publicationKey];
      // If so, we need to see if any of those questions have responses set
      if (clientSurveyMayHaveResponses) {
        clientSurveyHasResponses = Object.values(clientSurveyResponses[publicationKey]).some(
          (value) => !!value,
        );
      }
      return !zipcode && !clientSurveyHasResponses;
    },
  );

const makeGetTierCodeData = () =>
  createSelector([makeSelectValidSpouseOrUndefined(), makeSelectDependents()], (spouse, dependents) => {
    const validDependents = dependents.filter((dependent) => dependent.isValid && dependent.isCovered);

    let defaultKey = 'individual';
    if (spouse && spouse?.isCovered) {
      defaultKey += '_spouse';
    }
    if (validDependents.length > 0) {
      if (validDependents.length === 1) {
        defaultKey += `_child`;
      }
      if (validDependents.length > 1) {
        defaultKey += `_children_2`;
      }
    }

    const tierCodeMap = {
      // medical_cover_self
      individual: {
        code: '10',
        description: "You'll cover yourself.",
      },
      // medical_cover_self_spouse
      individual_spouse: {
        code: '20',
        description: "You'll cover yourself and your spouse.",
      },
      // medical_cover_self_child
      individual_child: {
        code: '30',
        description: "You'll cover yourself and your child.",
      },
      // medical_cover_self_children
      individual_children_2: {
        code: '40',
        description: `You'll cover yourself and ${validDependents.length} children.`,
      },
      // medical_cover_self_spouse_child
      individual_spouse_child: {
        code: '60',
        description: "You'll cover yourself, your spouse, and your child.",
      },
      // medical_cover_self_spouse_children
      individual_spouse_children_2: {
        code: '70',
        description: `You'll cover yourself, your spouse, and ${validDependents.length} children.`,
      },
      // Currently Go only tracks domestic partner vs spouse if selected product is BC
      // medical_cover_default: '',
      // medical_cover_self_partner: "You'll cover yourself and your partner.",
      // medical_cover_self_partner_child:
      //   "You'll cover yourself, your partner and your child.",
      // medical_cover_self_partner_children:
      //   "You'll cover yourself, your partner, and {{{g.User.Medical.coverKidsNum}}} children.",
    };

    const tierCodeDescription = tierCodeMap[defaultKey].description;
    const tierCode = tierCodeMap[defaultKey].code;

    return [tierCode, tierCodeDescription];
  });

export {
  selectProfileDomain,
  makeSelectProfileField,
  makeSelectIsHouseholdLoaded,
  makeGetHouseholdId,
  makeGetPayCycle,
  makeGetBuilderGoSettings,
  makeGetBuilderGoSetting,
  // Eligibility section
  makeSelectClientSurvey,
  makeGetSurveyQuestions,
  makeSelectClientSurveyResponses,
  makeSelectClientSurveyIsLoaded,
  makeSelectShowClientSurvey,
  // Location section
  makeSelectCountyResults,
  makeGetStateCodeOptions,
  // Member section
  makeSelectValidPolicyholderOrUndefined,
  makeSelectPolicyholder,
  makeSelectValidSpouseOrUndefined,
  makeSelectSpouse,
  makeSelectValidDependents,
  makeSelectDependents,
  makeSelectDependentsCount,
  makeGetCoveredHouseholdMemberCount,
  makeGetCanBearChild,
  makeGetHouseholdMembers,
  makeGetHousehold,
  makeGetCoverageTier,
  makeGetIsFamily,
  makeGetHasSpouse,
  makeGetTierCodeData,
  // Income section
  makeGetHouseholdIncome,
  // Prescription section
  makeSelectDrugResults,
  makeSelectRxIsLoading,
  makeSelectPrescriptions,
  makeSelectRxIsSkipped,
  makeGetHouseholdPrescriptions,
  // Capacity, Risk sections
  makeSelectSurvey,
  // Misc
  makeGetYearlyHRAEmployerContribution,
  makeGetMonthlyHRAEmployerContribution,
  makeGetHealthPlanMemberIds,
  makeSelectIsUsingBuilderConfig,
  makeGetSelectedProduct,
  makeGetProfileIsEmpty,
  // JVP
  makeGetSelectedPublicationKey,
  makeGetSelectedPublicationInfo,
  makeGetSelectedPublicationPlanYear,
  makeGetSelectedPublicationSegmentSlug,
  makeGetSelectedEnrollmentEventId,
  makeGetSelectedEnrollmentEvent,
  makeGetIsDuringOE,
  makeGetIntegratedUserPublicationKey,
  // Special EQs
  makeSelectShouldShowTobaccoQuestion,
  makeGetActiveHousholdMembers,
  makePreviousSelectedPlan,
};
