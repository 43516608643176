import { Slider, SliderTrack, SliderThumb, SliderFilledTrack, SliderMark } from '@chakra-ui/react';
import { debounce } from 'lodash';
import React, { useCallback } from 'react';

import { Paragraph } from 'DesignLibrary/atoms';
import { COLORS, SHADOW } from 'DesignLibrary/vars';
import { toDollars } from 'Utils/helpers';
import { sendInsightsEvent } from 'Utils/insights';

import { Container } from './styled';

interface HsaSliderProps {
  contribution: number;
  userContributionLimit: number;
  sliderType: string;
  setContribution: (contribution: number) => void;
  setLockedContribution: (contribution: number) => void;
}

export const HsaSlider = ({
  contribution,
  userContributionLimit,
  sliderType,
  setContribution,
  setLockedContribution,
}: HsaSliderProps) => {
  function trackSlider(cont: number) {
    sendInsightsEvent(null, 'slide_hsa_contribution', {
      type: sliderType,
      cont,
    });
  }

  const handleLockedContributionChange = useCallback(
    debounce((cont: number) => {
      // setLockedContribution updates redux which triggers the saga that makes the forcasting calls
      setLockedContribution(cont);
      trackSlider(cont);
    }, 1000),
    [],
  );

  const handleSliderChange = (v: number) => {
    handleLockedContributionChange(v);
    setContribution(v);
  };

  const getContributionSliderMarkText = () => {
    if (contribution === 0) {
      return `Min: $0`;
    }
    if (contribution === userContributionLimit) {
      return `Max: $${userContributionLimit}`;
    }
    return toDollars(contribution);
  };

  return (
    <Container>
      <Paragraph size="small" color="--text-gray" className="start-number">
        $0
      </Paragraph>

      <Slider
        aria-label="hsa yearly contribution"
        className="slider"
        value={contribution}
        onChange={(v) => handleSliderChange(v)}
        min={0}
        max={userContributionLimit}
        step={5}
      >
        <SliderTrack bg={COLORS.gray['200']}>
          <SliderFilledTrack bg={COLORS.blue['400']} />
        </SliderTrack>
        <SliderMark className="slider-mark" value={contribution}>
          <Paragraph size="mini" color="--primary-white" weight="bold">
            {getContributionSliderMarkText()}
          </Paragraph>
        </SliderMark>
        <SliderThumb className="slider-thumb" boxSize="32px" sx={{ boxShadow: SHADOW.large }} />
      </Slider>

      <Paragraph size="small" color="--text-gray" className="end-number">
        {toDollars(userContributionLimit)}
      </Paragraph>
    </Container>
  );
};
