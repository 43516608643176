import { AspectRatio, useDisclosure } from '@chakra-ui/react';
import { push } from 'connected-react-router';
import { isEmpty } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import { GlobalReducerState } from 'app/reducers';
import {
  makeSelectCommercialField,
  makeSelectConfigField,
  makeSelectJvpField,
} from 'Containers/App/selectors';
import { fetchShowVideoStatus } from 'Containers/App/utils';
import {
  getSupplementalPlansForViewBenefitPage,
  removeSelectedSupplementalPlan,
  selectSupplementalPlan,
} from 'Containers/OverviewPage/actions';
import { ALL_SELECTABLE_PLAN_TYPES } from 'Containers/OverviewPage/constants';
import {
  getRiskAversion,
  makeGetHospitalPlan,
  makeGetIsHealthPlanSelected,
  makeGetSelectedHealthDeductible,
  makeGetSupplementalPlanReasonsWhy,
  makeSelectOverviewField,
} from 'Containers/OverviewPage/selectors';
import {
  makeGetBuilderGoSettings,
  makeGetHouseholdMembers,
  makeGetPayCycle,
  makeGetSelectedPublicationPlanYear,
  makeSelectDependentsCount,
  makeSelectSurvey,
} from 'Containers/ProfilePage/selectors';
import { RiskAversion, Survey } from 'Containers/ProfilePage/types';
import { memberIdsListToMemberIdsKey } from 'Containers/ViewBenefitPage/utils';
import { ContentfulFbsBenefit } from 'ContentfulDefaults/types/benefits';
import { useFeatureFlagContext } from 'Contexts/featureFlagContext';
import { useTextContext } from 'Contexts/textContext';
import { Button } from 'DesignLibrary/atoms/Button';
import { PageLayout } from 'DesignLibrary/atoms/PageLayout';
import { H3 } from 'DesignLibrary/atoms/typography';
import { SupplementalIcon } from 'Shared/SupplementalIcon';
import Text from 'Shared/Text';
import Video from 'Shared/Video';
import {
  DefaultCoveredMembers,
  HouseholdMember,
  ReasonsWhy,
  SelectedSupplementalPlans,
  SupplementalPlan,
  SupplementalPlansCache,
  SupplementalPlansFullHousehold,
} from 'Types/entities';
import { BuilderGoSettings } from 'Utils/apiTypes';
import injectSaga from 'Utils/injectSaga';
import { OVERVIEW_PATH } from 'Utils/urls';

import BenefitDetails from './BenefitDetails';
import { BenefitPlans } from './BenefitPlans';
import { CONTENT_MAP } from './constants';
import ReasonsWhyList from './ReasonsWhyList';
import saga from './saga';
import { BenefitTitle, Container, TopBar } from './styled';
import { BuilderBenefit, InsuranceType } from './types';

export interface ViewBenefitPageProps {
  client: string;
  employerName: string;
  showFbsCardDetails: boolean;
  match: {
    params: {
      insuranceType: string;
    };
  };
  defaultCoveredMembers: DefaultCoveredMembers;
  reasonsWhy: ReasonsWhy;
  selectedHealthDeductible: number;
  dependentsCount: number;
  surveyAnswers: Survey;
  isHealthPlanSelected: boolean;
  selectedHealthPlan: GlobalReducerState['commercialApp']['selectedHealthPlan'];
  planYear: number;
  supplementalPlansFullHousehold: SupplementalPlansFullHousehold;
  fbsBenefitsCache: SupplementalPlansCache;
  selectedSupplementalPlans: SelectedSupplementalPlans;
  riskAversion: RiskAversion;
  builderCustomerKey: string | null;
  fetchSupplementalPlans: (memberIds: string[]) => void;
  chooseSupplementalPlan: (
    insuranceType: InsuranceType,
    plan: SupplementalPlan,
    coveredMembers: DefaultCoveredMembers,
  ) => void;
  removeSupplementalPlan: () => void;
  goToSupplemental: () => void;
  selectedHospitalIndemnityPlan?: SupplementalPlan | null;
  builderGoSettings: BuilderGoSettings | null;
  householdMembers: HouseholdMember[];
}

export const ViewBenefitPage: React.FC<ViewBenefitPageProps> = ({
  client,
  employerName,
  showFbsCardDetails,
  match,
  defaultCoveredMembers,
  reasonsWhy,
  selectedHealthDeductible,
  dependentsCount,
  surveyAnswers,
  isHealthPlanSelected,
  selectedHealthPlan,
  planYear,
  supplementalPlansFullHousehold,
  fbsBenefitsCache,
  selectedSupplementalPlans,
  riskAversion,
  builderCustomerKey,
  fetchSupplementalPlans,
  chooseSupplementalPlan,
  removeSupplementalPlan,
  goToSupplemental,
  selectedHospitalIndemnityPlan,
  builderGoSettings,
  householdMembers,
}) => {
  const insuranceType = match.params.insuranceType as InsuranceType;

  const { retrieveContentfulData } = useTextContext();
  const { is_educational_videos_enabled } = useFeatureFlagContext();

  const { isOpen: benefitsOpen, onOpen: benefitsOnOpen, onClose: benefitsOnClose } = useDisclosure();

  // get educational benefits from contentful, get specific benefit/index to render
  const educationalBenefits = retrieveContentfulData<ContentfulFbsBenefit[]>(
    'benefits_section.educational_benefits',
    [],
  );

  const builderBenefitsArray = retrieveContentfulData<BuilderBenefit[]>(
    'benefits_section_additional.supplemental_plans',
    [],
  );

  const builderBenefits: Record<string, BuilderBenefit> = builderBenefitsArray.reduce(
    (acc, i, idx) => ({
      [i.plan_id]: {
        ...i,
        path: `benefits_section_additional.supplemental_plans[${idx}]`,
      },
      ...acc,
    }),
    {},
  );

  const educationalBenefitIndex = educationalBenefits.findIndex(
    (benefit) => benefit.plan_type === insuranceType,
  );
  const educationalBenefitDetails =
    educationalBenefitIndex >= 0 ? educationalBenefits[educationalBenefitIndex] : null;

  const [coveredMembers, setCoveredMembers] = useState(defaultCoveredMembers);
  const [transitionIndicator, setTransitionIndicator] = useState(false);
  const prevPlans = useRef<SupplementalPlan[] | null>([]);

  useEffect(() => {
    if (!educationalBenefitDetails) {
      fetchSupplementalPlans(getCoveredMemberIds());
    }
  }, []);

  // When user toggles a member select:
  // - If we have the current combination of coveredMembers in cache, set that in state
  // - Else, reach out to API to getSupplementalPlans (updates cache in redux)
  useEffect(() => {
    if (!educationalBenefitDetails) {
      fetchSupplementalPlans(getCoveredMemberIds());
    }
  }, [coveredMembers]);

  // keep previous plans in ref to display while isLoading
  useEffect(() => {
    if (getPlans()) {
      prevPlans.current = getPlans();
    } else {
      setTransitionIndicator(true);
    }

    const transitionTimeout = setTimeout(() => {
      setTransitionIndicator(false);
    }, 2000);

    return () => {
      clearTimeout(transitionTimeout);
    };
  }, [fbsBenefitsCache]);

  // returns array of member ids
  const getCoveredMemberIds = () => {
    if (coveredMembers[insuranceType]) {
      return Object.values(coveredMembers[insuranceType])
        .filter((member) => member.covered)
        .map((member) => member.memberId);
    }
    return [];
  };

  function getPlans() {
    if (educationalBenefitDetails) {
      return null;
    }
    const cacheKey = memberIdsListToMemberIdsKey(getCoveredMemberIds());
    if (fbsBenefitsCache[cacheKey]) {
      if (fbsBenefitsCache[cacheKey][insuranceType]) {
        return fbsBenefitsCache[cacheKey][insuranceType]?.plans || null;
      }
    }
    return null;
  }

  const toggleMemberSelect = (id) => {
    const coveredMembersByInsuranceType = coveredMembers[insuranceType];
    setCoveredMembers({
      ...coveredMembers,
      [insuranceType]: {
        ...coveredMembersByInsuranceType,
        [id]: {
          ...coveredMembersByInsuranceType[id],
          covered: !coveredMembersByInsuranceType[id].covered,
        },
      },
    });
  };

  // AUTO-ENROLLED PLANS
  const plans = getPlans();
  let autoEnrollCount = 0;
  let isAutoEnrollAvailable = false;

  if (plans) {
    autoEnrollCount = plans && plans.filter((plan) => !!plan.auto_enrolled === true).length;
    isAutoEnrollAvailable = autoEnrollCount > 0;
  }

  // IS SELECTED HEALTH PLAN HSA ELIGIBLE?
  const healthHsaEligible = isHealthPlanSelected && selectedHealthPlan.plan.hsa_eligible;

  // Determine if fields exist in Contentful. If not, use hard-coded values in content map
  const content = CONTENT_MAP;
  const benefitTitle = retrieveContentfulData<string>(`${content[insuranceType].field}.card_title`);
  const altBenefitTitle = retrieveContentfulData<string>(`${content[insuranceType].field}.alternate_title`);
  const benefitVideo = retrieveContentfulData<string>(`${content[insuranceType].field}.video_link`);
  const altBenefitVideo = retrieveContentfulData<string>(
    `${content[insuranceType].field}.alternate_video_link`,
  );

  // Determine if Limited Purpose FSA and show alternate description if so
  let revisedTitle = benefitTitle;
  let revisedVideo = benefitVideo;

  if (healthHsaEligible && ['fsa', 'fsa_medical'].includes(insuranceType)) {
    revisedTitle = altBenefitTitle;
    revisedVideo = altBenefitVideo;
  }

  if (educationalBenefitDetails) {
    revisedTitle = `benefits_section.educational_benefits[${educationalBenefitIndex}].card_title`;
  }

  const isInsuranceBenefit = ALL_SELECTABLE_PLAN_TYPES.includes(insuranceType);

  const renderTitle = () => {
    if (isInsuranceBenefit) {
      return <Text field="benefits_section.benefit_page_heading" vars={{ x: revisedTitle }} />;
    }

    return revisedTitle;
  };

  // RENDER BENEFITS WHY
  const renderReasonsWhy = () =>
    !isEmpty(reasonsWhy[insuranceType]) && (
      <ReasonsWhyList
        insuranceType={insuranceType}
        reasonsWhy={reasonsWhy[insuranceType]!}
        healthDeductible={selectedHealthDeductible}
        childrenCount={dependentsCount}
        capacityToPay={surveyAnswers.capacity_to_pay}
        riskAversion={riskAversion}
      />
    );

  // RENDER BENEFIT PLANS
  const renderBenefitPlans = () => (
    <BenefitPlans
      client={client}
      employerName={employerName}
      plans={getPlans()}
      builderBenefits={builderBenefits}
      insuranceType={insuranceType}
      selectedPlan={selectedSupplementalPlans[insuranceType] as SupplementalPlan}
      planYear={planYear}
      coveredMembers={coveredMembers}
      choosePlan={(plan) => chooseSupplementalPlan(insuranceType, plan, coveredMembers)}
      removePlan={removeSupplementalPlan}
      toggleMemberSelect={toggleMemberSelect}
      isLoading={transitionIndicator}
      showFbsCardDetails={showFbsCardDetails}
      supplementalPlansFullHousehold={supplementalPlansFullHousehold}
      autoEnrollCount={autoEnrollCount}
      isAutoEnrollAvailable={isAutoEnrollAvailable}
      builderCustomerKey={builderCustomerKey}
      selectedHospitalIndemnityPlan={selectedHospitalIndemnityPlan}
      householdMembers={householdMembers}
    />
  );

  const shouldShowEducationalVideo =
    !is_educational_videos_enabled ||
    (builderGoSettings && !!builderGoSettings[fetchShowVideoStatus(insuranceType)]);

  return (
    <>
      <PageLayout size="small">
        <TopBar>
          <Button iconLeft="ArrowLeft" buttonType="standalone" onClick={goToSupplemental}>
            <Text field="benefits_section.button_text.backToBenefits" />
          </Button>
        </TopBar>
      </PageLayout>
      <PageLayout size="small" bg="x" id="plan-detail-container">
        <Container bg="">
          <BenefitTitle>
            <div className="title">
              <SupplementalIcon type={insuranceType} size="large" />
              <H3 as="h1" data-testid="benefit-page-heading">
                {renderTitle()}
              </H3>
            </div>
            <div className="details">
              {renderReasonsWhy()}
              {shouldShowEducationalVideo && revisedVideo && (
                <div className="benefit-video">
                  <AspectRatio ratio={16 / 9} maxW="600px" className="video-container">
                    <Video
                      title={`${benefitTitle} video`}
                      url={revisedVideo as string}
                      width="100%"
                      height="100%"
                    />
                  </AspectRatio>
                </div>
              )}

              <BenefitDetails
                employerName={employerName}
                insuranceType={insuranceType}
                healthHsaEligible={healthHsaEligible}
                builderBenefits={builderBenefits}
                isOpen={benefitsOpen}
                onClose={benefitsOnClose}
                reasonsWhy={reasonsWhy}
                selectedHealthDeductible={selectedHealthDeductible}
                dependentsCount={dependentsCount}
                surveyAnswers={surveyAnswers}
                riskAversion={riskAversion}
                handleOpenBenefit={() => null}
                builderCustomerKey={builderCustomerKey}
                builderGoSettings={builderGoSettings}
              />

              <Button
                data-testid={`learn-${insuranceType}-btn`}
                className="open-benefit-btn"
                onClick={benefitsOnOpen}
                stretch
                iconRight="QuestionOutline"
              >
                <Text field="benefits_section.button_text.learnMore" />
              </Button>
            </div>
          </BenefitTitle>

          {renderBenefitPlans()}

          <div className="bottom-button">
            <Button iconLeft="ArrowLeft" buttonType="standalone" onClick={goToSupplemental}>
              <Text field="benefits_section.button_text.backToBenefits" />
            </Button>
          </div>
        </Container>
      </PageLayout>
    </>
  );
};

const mapStateToProps = () =>
  createStructuredSelector<GlobalReducerState, unknown>({
    client: makeSelectConfigField('client'),
    employerName: makeSelectJvpField('employerName'),
    showFbsCardDetails: makeSelectConfigField('show_detail_fbs_cards'),
    supplementalPlansFullHousehold: makeSelectOverviewField('supplemental_plans_full_household'),
    fbsBenefitsCache: makeSelectOverviewField('supplemental_plans_household_subsets_cache'),
    selectedHealthDeductible: makeGetSelectedHealthDeductible(),
    selectedSupplementalPlans: makeSelectOverviewField('selected_plans'),
    defaultCoveredMembers: makeSelectOverviewField('default_covered_members'),
    reasonsWhy: makeGetSupplementalPlanReasonsWhy(),
    dependentsCount: makeSelectDependentsCount(),
    surveyAnswers: makeSelectSurvey(),
    payCycle: makeGetPayCycle(),
    isHealthPlanSelected: makeGetIsHealthPlanSelected(),
    selectedHealthPlan: makeSelectCommercialField('selectedHealthPlan'),
    planYear: makeGetSelectedPublicationPlanYear(),
    riskAversion: getRiskAversion(),
    builderCustomerKey: makeSelectConfigField('builder_customer_key'),
    selectedHospitalIndemnityPlan: makeGetHospitalPlan(),
    builderGoSettings: makeGetBuilderGoSettings(),
    householdMembers: makeGetHouseholdMembers(),
  });

export function mapDispatchToProps(dispatch) {
  return {
    fetchSupplementalPlans: (memberIds) => dispatch(getSupplementalPlansForViewBenefitPage(memberIds)),
    chooseSupplementalPlan: (insuranceType, plan, coveredMembers) =>
      dispatch(selectSupplementalPlan(insuranceType, plan, coveredMembers)),
    removeSupplementalPlan: (insuranceType) => dispatch(removeSelectedSupplementalPlan(insuranceType)),
    goToSupplemental: () => dispatch(push(OVERVIEW_PATH)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSaga({
  key: 'viewBenefitPage',
  saga,
});

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default compose(withSaga, withConnect)(ViewBenefitPage);
