import styled, { css } from 'styled-components';

import { ParagraphMiniStyle, TooltipUnderlineStyle } from 'DesignLibrary/atoms';

export const Container = styled.section`
  z-index: 0;

  & > * + * {
    margin-top: var(--sizing-md);
  }
`;

export const InfoContainer = styled.div`
  border: var(--border-thick);
  border-radius: var(--border-radius-xs);

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--sizing-sm);
  }

  & > div + div {
    border-top: 1px solid var(--border-gray);
  }

  .flex {
    display: flex;
    align-items: center;
    gap: var(--sizing-xs);
  }
`;

export const LegendContainer = styled.div`
  display: flex;
  gap: var(--sizing-xs) var(--sizing-sm);
  flex-wrap: wrap;
  margin-top: var(--sizing-sm);
`;

interface LegendItemProps {
  legendType: 'line' | 'dash' | 'block';
  color: string;
}

export const LegendItem = styled.div<LegendItemProps>`
  ${ParagraphMiniStyle('--text-gray')}
  ${TooltipUnderlineStyle}
  display: flex;
  align-items: center;
  gap: var(--sizing-xs);

  &:before {
    content: '';
    display: block;
    width: var(--sizing-sm);

    ${({ legendType, color }) => {
      switch (legendType) {
        case 'line':
          return css`
            height: 1px;
            border-top: 3px solid var(${color});
          `;

        case 'dash':
          return css`
            height: 1px;
            border-top: 3px dashed var(${color});
          `;

        case 'block':
          return css`
            height: var(--sizing-sm);
            background: var(${color});
            border-radius: 4px;
          `;

        default:
          return null;
      }
    }}
  }
`;
