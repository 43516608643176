import {
  DirectlyUpdateableJVPUserAttributes,
  EnrollmentEventType,
  GetConfigResponse,
  JVPEnrollmentEvent,
  JVPUserWithDependents,
  PreviousSelectedPlan,
} from 'Utils/apiTypes';

import {
  CHANGE_STEP,
  CHOOSE_PRODUCT,
  GET_AAPI_USER_FAILURE,
  GET_AAPI_USER_REQUEST,
  GET_AAPI_USER_SUCCESS,
  GET_JVP_BOOTSTRAP,
  GET_JVP_BOOTSTRAP_FAILURE,
  GET_JVP_BOOTSTRAP_SUCCESS,
  GET_JVP_USER_FAILURE,
  GET_JVP_USER_SUCCESS,
  HANDLE_PROFILE_COMPLETE,
  HANDLE_UNLOCK_MAIN_NAV,
  LOAD_CONFIG,
  LOAD_CONFIG_FAILURE,
  LOAD_CONFIG_SUCCESS,
  PULSE_JVP_SESSION,
  SET_ALEX_ID_UUID,
  SET_BC_URL_HASH,
  SET_DEMO_MODE,
  SET_ENROLLMENT_EVENT_PROFILE_COMPLETE,
  SET_ENROLLMENT_EVENT_PROFILE_COMPLETE_FAILURE,
  SET_ENROLLMENT_EVENT_PROFILE_COMPLETE_SUCCESS,
  SET_ENROLLMENT_EVENT_TYPE,
  SET_ENROLLMENT_EVENT_TYPE_FAILURE,
  SET_ENROLLMENT_EVENT_TYPE_SUCCESS,
  SET_JVP_SESSION_ID,
  SET_PAGE_VIEWED,
  SET_UNVALIDATED_SEGMENT_SLUG,
  SET_VALIDATED_SEGMENT_SLUG,
  UPDATE_PREVIOUS_SELECTED_PLAN,
  UPDATE_USER_UI_SETTINGS,
} from './constants';
import { AlexIdUser, AlexProducts, GetJVPBootstrapSuccessAction } from './types';

export const setValidatedSegmentSlug = (slug) => ({
  type: SET_VALIDATED_SEGMENT_SLUG,
  validatedSegmentSlug: slug,
});

export function changeStep(value: string) {
  return {
    type: CHANGE_STEP,
    value,
  };
}

export function loadConfig() {
  return { type: LOAD_CONFIG };
}

export function chooseProduct(value: AlexProducts) {
  return {
    type: CHOOSE_PRODUCT,
    value,
  };
}

export function loadConfigSuccess(response: GetConfigResponse) {
  return {
    type: LOAD_CONFIG_SUCCESS,
    response,
  };
}

export function loadConfigFailure(error: Error) {
  return {
    type: LOAD_CONFIG_FAILURE,
    error,
  };
}

export function handleUnlockMainNav(path: string) {
  return {
    type: HANDLE_UNLOCK_MAIN_NAV,
    path,
  };
}

export function setPageViewed(path: string, viewed: boolean) {
  return {
    type: SET_PAGE_VIEWED,
    path,
    viewed,
  };
}

export function getJVPBootstrap(userId: string, employerSlug: string) {
  return {
    type: GET_JVP_BOOTSTRAP,
    userId,
    employerSlug,
  };
}

export function setEnrollmentEventProfileComplete(
  employeeId: string,
  publicationId: string,
  eventType: EnrollmentEventType,
  profileComplete: boolean,
) {
  return {
    type: SET_ENROLLMENT_EVENT_PROFILE_COMPLETE,
    employeeId,
    publicationId,
    eventType: eventType || 'none',
    profileComplete,
  };
}
export function setEnrollmentEventType(
  eventType: EnrollmentEventType | null,
  publicationId?: string,
  employeeId?: string,
) {
  return {
    type: SET_ENROLLMENT_EVENT_TYPE,
    eventType,
    publicationId,
    employeeId,
  };
}

export function getJVPBootstrapSuccess({
  user,
  employerId,
  employerName,
  employeeId,
  active,
  upcoming,
  validatedSegmentSlug,
}: GetJVPBootstrapSuccessAction): GetJVPBootstrapSuccessAction & { type: typeof GET_JVP_BOOTSTRAP_SUCCESS } {
  return {
    type: GET_JVP_BOOTSTRAP_SUCCESS,
    user,
    employerId,
    employerName,
    employeeId,
    active,
    upcoming,
    validatedSegmentSlug,
  };
}

export function getJVPBootstrapFailure(error: Error) {
  return {
    type: GET_JVP_BOOTSTRAP_FAILURE,
    error,
  };
}

export function getJVPUserSuccess(jvpUser: JVPUserWithDependents) {
  return {
    type: GET_JVP_USER_SUCCESS,
    jvpUser,
  };
}

export function getJVPUserFailure(error: Error) {
  return {
    type: GET_JVP_USER_FAILURE,
    error,
  };
}

export function setJVPSessionId(sessionId: string) {
  return {
    type: SET_JVP_SESSION_ID,
    sessionId,
  };
}

export function pulseJVPSession(sessionId: string) {
  return {
    type: PULSE_JVP_SESSION,
    sessionId,
  };
}
export function getAAPIUser(sessionId: string) {
  return {
    type: GET_AAPI_USER_REQUEST,
    sessionId,
  };
}

export function getAAPIUserSuccess(user: AlexIdUser) {
  return {
    type: GET_AAPI_USER_SUCCESS,
    user,
  };
}

export function getAAPIUserFailure(error: Error) {
  return {
    type: GET_AAPI_USER_FAILURE,
    error,
  };
}

export function setDemoMode(isDemoMode: boolean) {
  return {
    type: SET_DEMO_MODE,
    isDemoMode,
  };
}

export function setUnvalidatedSegmentSlug(unvalidatedSegmentSlug: string) {
  return {
    type: SET_UNVALIDATED_SEGMENT_SLUG,
    unvalidatedSegmentSlug,
  };
}

export function setBCUrlHash(hash: string) {
  return {
    type: SET_BC_URL_HASH,
    hash,
  };
}

export function setEnrollmentEventProfileCompleteSuccess(
  enrollmentEvent: JVPEnrollmentEvent,
  previousSelectedPlan: PreviousSelectedPlan[],
) {
  return {
    type: SET_ENROLLMENT_EVENT_PROFILE_COMPLETE_SUCCESS,
    profileComplete: enrollmentEvent.profile_complete,
    previousSelectedPlan,
  };
}

export function setEnrollmentEventProfileCompleteFailure(error: Error) {
  return {
    type: SET_ENROLLMENT_EVENT_PROFILE_COMPLETE_FAILURE,
    error,
  };
}

export function setEnrollmentEventTypeSuccess(enrollmentEvent: JVPEnrollmentEvent) {
  return {
    type: SET_ENROLLMENT_EVENT_TYPE_SUCCESS,
    eventType: enrollmentEvent.event_type,
  };
}

export function setEnrollmentEventTypeFailure(error: Error) {
  return {
    type: SET_ENROLLMENT_EVENT_TYPE_FAILURE,
    error,
  };
}

export function setAlexIdUuid(alex_id_uuid: string) {
  return {
    type: SET_ALEX_ID_UUID,
    alex_id_uuid,
  };
}

export function handleProfileComplete() {
  return {
    type: HANDLE_PROFILE_COMPLETE,
  };
}

export function updatePreviousSelectedPlan(previousSelectedPlan: PreviousSelectedPlan[] | []) {
  return {
    type: UPDATE_PREVIOUS_SELECTED_PLAN,
    previousSelectedPlan,
  };
}

export function updateJVPUserUiSettings(uiSettings: Partial<DirectlyUpdateableJVPUserAttributes>) {
  return {
    type: UPDATE_USER_UI_SETTINGS,
    uiSettings,
  };
}
