import React from 'react';

import { Icon, IconTypes, Paragraph } from 'DesignLibrary/atoms';

import { BulletPointContainer } from './styled';

export interface BulletPointProps {
  icon: IconTypes;
  text: string;
  id: string;
}

export const BulletPoint = ({ icon, text, id }: BulletPointProps) => (
  <BulletPointContainer>
    <Icon size="large" type={icon} color="--primary-blue" />
    <Paragraph id={id} color="--text-black" size="small">
      {text}
    </Paragraph>
  </BulletPointContainer>
);
