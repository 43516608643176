import styled from 'styled-components';

import { media } from 'DesignLibrary/global';

export const BenefitsHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .deductible {
    display: none;
    margin: 0 0 -1rem 0;
    max-width: 6.5rem;

    .deductible-row {
      display: flex;
      flex-direction: row;
      align-items: center;

      span {
        margin-right: var(--sizing-xs);
      }
    }

    ${media.TABLET`
      display: flex;
    `}
  }
`;
