import styled from 'styled-components';

import { breakpoint } from 'DesignLibrary/global';
import { cardStyling, selectedBlueStyling } from 'DesignLibrary/utils';

interface HeaderContainerProps {
  isSelected: boolean;
}

export const HeaderContainer = styled.div<HeaderContainerProps>`
  ${cardStyling}
  position: relative;
  margin-bottom: var(--sizing-sm);

  .score-container-attached,
  .badge-container {
    display: none;
  }

  ${breakpoint.DESKTOP} {
    .score-container-attached,
    .badge-container {
      display: block;
      position: absolute;
      top: var(--sizing-md);
      right: 100%;
    }

    .score-container-attached {
      width: var(--sizing-2xl);
      overflow: hidden;
    }

    .badge-container {
      .badge {
        position: relative;
        left: var(--sizing-lg);

        &.lower {
          top: var(--sizing-lg);
        }
      }
    }
  }

  ${({ isSelected }) => isSelected && selectedBlueStyling}
`;

export const Head = styled.header`
  display: flex;
  align-items: flex-start;
  column-gap: 12px;

  .score-container {
    height: var(--sizing-xl);
    width: var(--sizing-xl);
    position: relative;

    > * {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    ${breakpoint.TABLET} {
      height: var(--sizing-2xl);
      width: var(--sizing-2xl);
    }
  }

  .header-content {
    transition: 0.15s transform;
  }

  h1 {
    ${breakpoint.DESKTOP} {
      font-size: 42px;
    }
  }

  ${breakpoint.TABLET} {
    column-gap: var(--sizing-sm);
  }
  ${breakpoint.DESKTOP} {
    .score-container {
      display: none;
    }

    .header-content.scoot {
      transform: translateX(var(--sizing-md));
    }
  }
`;

export const Divider = styled.div`
  width: 1px;
  height: var(--sizing-sm);
  border-right: 1px solid;
  border-color: var(--alex-border-gray) !important;
`;
