import { HSAContributionExplainer } from 'ContentfulDefaults/types/hsa';

export const hsaContributionExplainer: HSAContributionExplainer = {
  contentful_name: '[DEFAULT] HSA Contribution Explainer',
  title: 'YOUR HSA CONTRIBUTION',
  header: {
    nodeType: 'document',
    data: {},
    content: [
      {
        nodeType: 'paragraph',
        data: {},
        content: [
          {
            nodeType: 'text',
            value: 'We suggest that you contribute at least ',
            marks: [],
            data: {},
          },
          {
            nodeType: 'text',
            value: '{x}',
            marks: [
              {
                type: 'code',
              },
              {
                type: 'bold',
              },
            ],
            data: {},
          },
          {
            nodeType: 'text',
            value: '/mo',
            marks: [
              {
                type: 'bold',
              },
            ],
            data: {},
          },
          {
            nodeType: 'text',
            value: ' or ',
            marks: [],
            data: {},
          },
          {
            nodeType: 'text',
            value: '{x}',
            marks: [
              {
                type: 'code',
              },
              {
                type: 'bold',
              },
            ],
            data: {},
          },
          {
            nodeType: 'text',
            value: '/yr',
            marks: [
              {
                type: 'bold',
              },
            ],
            data: {},
          },
          {
            nodeType: 'text',
            value: ' to cover your predicted out-of-pocket costs.',
            marks: [],
            data: {},
          },
        ],
      },
    ],
  },
  employer_contribution: {
    data: {},
    content: [
      {
        data: {},
        content: [
          {
            data: {},
            marks: [],
            value: "Don't forget that your employer will ",
            nodeType: 'text',
          },
          {
            data: {},
            marks: [
              {
                type: 'bold',
              },
            ],
            value: 'contribute ',
            nodeType: 'text',
          },
          {
            data: {},
            marks: [
              {
                type: 'code',
              },
              {
                type: 'bold',
              },
            ],
            value: '{x}',
            nodeType: 'text',
          },
          {
            data: {},
            marks: [],
            value: ' to your HSA!',
            nodeType: 'text',
          },
        ],
        nodeType: 'paragraph',
      },
    ],
    nodeType: 'document',
  },
  employer_match: {
    nodeType: 'document',
    data: {},
    content: [
      {
        nodeType: 'paragraph',
        data: {},
        content: [
          {
            nodeType: 'text',
            value: "Don't forget that your employer will ",
            marks: [],
            data: {},
          },
          {
            nodeType: 'text',
            value: 'match ',
            marks: [
              {
                type: 'bold',
              },
            ],
            data: {},
          },
          {
            nodeType: 'text',
            value: '{x}',
            marks: [
              {
                type: 'code',
              },
              {
                type: 'bold',
              },
            ],
            data: {},
          },
          {
            nodeType: 'text',
            value: '%',
            marks: [
              {
                type: 'bold',
              },
            ],
            data: {},
          },
          {
            nodeType: 'text',
            value: ' of your HSA contributions up to',
            marks: [],
            data: {},
          },
          {
            nodeType: 'text',
            value: ' ',
            marks: [
              {
                type: 'bold',
              },
            ],
            data: {},
          },
          {
            nodeType: 'text',
            value: '{x}',
            marks: [
              {
                type: 'code',
              },
              {
                type: 'bold',
              },
            ],
            data: {},
          },
          {
            nodeType: 'text',
            value: '!',
            marks: [],
            data: {},
          },
        ],
      },
    ],
  },
  retirement_savings_cta: {
    data: {},
    content: [
      {
        data: {},
        content: [
          {
            data: {},
            marks: [],
            value: 'Click the ',
            nodeType: 'text',
          },
          {
            data: {},
            marks: [
              {
                type: 'bold',
              },
            ],
            value: 'Retirement Savings',
            nodeType: 'text',
          },
          {
            data: {},
            marks: [],
            value: ' tab to see how your HSA can be used as a retirement savings vehicle.',
            nodeType: 'text',
          },
        ],
        nodeType: 'paragraph',
      },
    ],
    nodeType: 'document',
  },
  continue: {
    nodeType: 'document',
    data: {},
    content: [
      {
        nodeType: 'paragraph',
        data: {},
        content: [
          {
            nodeType: 'text',
            value: 'Once you are happy with your contribution amount, continue to the next page.',
            marks: [],
            data: {},
          },
        ],
      },
    ],
  },
  header_per_paycheck: {
    nodeType: 'document',
    data: {},
    content: [
      {
        nodeType: 'paragraph',
        data: {},
        content: [
          {
            nodeType: 'text',
            value: 'We suggest that you contribute at least ',
            marks: [],
            data: {},
          },
          {
            nodeType: 'text',
            value: '{x}',
            marks: [
              {
                type: 'code',
              },
              {
                type: 'bold',
              },
            ],
            data: {},
          },
          {
            nodeType: 'text',
            value: ' or ',
            marks: [],
            data: {},
          },
          {
            nodeType: 'text',
            value: '{x}',
            marks: [
              {
                type: 'code',
              },
              {
                type: 'bold',
              },
            ],
            data: {},
          },
          {
            nodeType: 'text',
            value: ' to cover your predicted out-of-pocket costs.',
            marks: [],
            data: {},
          },
        ],
      },
    ],
  },
  slider_cta: {
    data: {},
    content: [
      {
        data: {},
        content: [
          {
            data: {},
            marks: [],
            value:
              'Play around with your contribution amount by dragging the slider below. Contribute more to get the most out of your HSA!',
            nodeType: 'text',
          },
        ],
        nodeType: 'paragraph',
      },
    ],
    nodeType: 'document',
  },
  hsa_administrator: 'Your HSA administrator is {name}. You can reach them by visiting {link}.',
  hsa_administrator_website: 'the HSA administrator website',
  header_per_paycheck_max: {
    nodeType: 'document',
    data: {},
    content: [
      {
        nodeType: 'paragraph',
        data: {},
        content: [
          {
            nodeType: 'text',
            value: 'Contributing the maximum of ',
            marks: [],
            data: {},
          },
          {
            nodeType: 'text',
            value: '{x}',
            marks: [
              {
                type: 'code',
              },
              {
                type: 'bold',
              },
            ],
            data: {},
          },
          {
            nodeType: 'text',
            value: ' or ',
            marks: [],
            data: {},
          },
          {
            nodeType: 'text',
            value: '{x}',
            marks: [
              {
                type: 'code',
              },
              {
                type: 'bold',
              },
            ],
            data: {},
          },
          {
            nodeType: 'text',
            value: ' will help cover your predicted out-of-pocket costs.',
            marks: [],
            data: {},
          },
        ],
      },
    ],
  },
  slider_cta_max: {
    data: {},
    content: [
      {
        data: {},
        content: [
          {
            data: {},
            marks: [],
            value: 'Play around with your contribution amount by dragging the slider below.',
            nodeType: 'text',
          },
        ],
        nodeType: 'paragraph',
      },
    ],
    nodeType: 'document',
  },
};
