import styled from 'styled-components';

export const SplitCostWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SplitCostRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: var(--sizing-xs);

  &:last-child {
    margin-bottom: 0;
  }

  h6 {
    margin-right: var(--sizing-sm);
    padding: 0 var(--sizing-xs);
    border-radius: 4rem;
    background-color: rgba(0, 0, 0, 0.05);
  }
`;
