import styled, { css } from 'styled-components';

import { breakpoint } from 'DesignLibrary/global';

import { PrimaryColors } from '../../vars';

type IconBlockProps = {
  color: PrimaryColors;
  shade: number;
  smallMobile: boolean;
};

export const IconBlockStyle = styled.div<IconBlockProps>`
  height: var(--sizing-xl);
  width: var(--sizing-xl);
  border-radius: var(--border-radius-xs);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  flex-grow: 0;

  ${({ color, shade }) => css`
    background: ${`var(--colors-${color}-${shade})`};
  `}

  ${({ smallMobile }) =>
    smallMobile &&
    css`
      height: 40px;
      width: 40px;

      ${breakpoint.TABLET} {
        height: var(--sizing-xl);
        width: var(--sizing-xl);
      }
    `}

  span {
    display: flex;
  }
`;
