import styled from 'styled-components';

import { ParagraphNormalStyle } from 'DesignLibrary/atoms';

export const SupplementalContent = styled.section`
  overflow: scroll;
  padding: var(--sizing-md);

  article {
    margin-top: var(--sizing-lg);

    & > * + * {
      margin-top: var(--sizing-xl);
    }
  }

  h2 {
    margin-bottom: var(--sizing-sm);
  }

  ul {
    list-style-position: inside;

    & > * + * {
      margin-top: var(--sizing-sm);
    }

    li {
      ${ParagraphNormalStyle()}
      color: var(--text-gray);

      b {
        color: var(--text-black);
      }

      ::marker {
        color: var(--primary-black);
      }
    }

    ul {
      margin-left: var(--sizing-md);
      margin-top: var(--sizing-sm);
    }
  }
`;

export const Header = styled.header`
  h2 {
    margin-bottom: var(--sizing-xs);
  }

  .video {
    overflow: hidden;
    border: 1px solid var(--border-gray);
    border-radius: var(--border-radius-xs);
    margin-top: var(--sizing-md);
  }
`;
