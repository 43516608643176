import { Box } from '@chakra-ui/react';
import React from 'react';

import { TextField } from 'Containers/App/types';
import InfoBar from 'DesignLibrary/molecules/InfoBar';
import Text from 'Shared/Text';

export interface CoverageDisclaimerProps {
  planTypeName: string;
  maxChildAge: string | undefined;
  availableToSpouse: boolean;
  availableToChildren: boolean;
  availableToPartner: boolean;
}

export const CoverageDisclaimer = (props: CoverageDisclaimerProps) => {
  const { planTypeName, maxChildAge, availableToSpouse, availableToChildren, availableToPartner } = props;

  const all = 'benefits_section_additional.coverage_disclaimer_available_to_all';
  const none = 'benefits_section_additional.coverage_disclaimer_available_to_none';
  const spouses = 'benefits_section_additional.coverage_disclaimer_available_to_spouses';
  const children = 'benefits_section_additional.coverage_disclaimer_available_to_children';
  const spousesAndPartners = 'benefits_section_additional.coverage_disclaimer_available_to_partners';
  const spousesAndChildren = 'benefits_section_additional.coverage_disclaimer_spouses_and_children';

  if (availableToChildren && maxChildAge === undefined) {
    return null;
  }

  let result = '';
  if (availableToSpouse && availableToPartner && availableToChildren) {
    result = all;
  } else if (availableToSpouse && availableToPartner && !availableToChildren) {
    result = spousesAndPartners;
  } else if (availableToSpouse && !availableToPartner && availableToChildren) {
    result = spousesAndChildren;
  } else if (availableToSpouse && !availableToPartner && !availableToChildren) {
    result = spouses;
  } else if (!availableToSpouse && availableToPartner && availableToChildren) {
    result = children; // there is no "partners and children"
  } else if (!availableToSpouse && availableToPartner && !availableToChildren) {
    result = none; // there is no "partners only"
  } else if (!availableToSpouse && !availableToPartner && availableToChildren) {
    result = children;
  } else if (!availableToSpouse && !availableToPartner && !availableToChildren) {
    result = none;
  }

  if (result === '') {
    return null;
  }

  return (
    <Box mb={4}>
      <InfoBar
        color="yellow"
        icon="Warning"
        text={<Text field={result as TextField} vars={{ maxAge: maxChildAge, plan: planTypeName }} />}
      />
    </Box>
  );
};
