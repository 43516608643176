import { all, call, ForkEffect, put, select, takeLatest } from 'redux-saga/effects';

import { makeSelectCommercialField, makeSelectConfigField } from 'Containers/App/selectors';
import {
  makeGetSelectedEnrollmentEventId,
  makeGetHousehold,
  makeGetHouseholdId,
  makeSelectSpouse,
} from 'Containers/ProfilePage/selectors';
import { postContributionsFailure, postContributionsSuccess } from 'Containers/TaxSavingsPage/actions';
import { makeSelectTaxSavingsField } from 'Containers/TaxSavingsPage/selectors';
import { Household, Recommendation } from 'Types/entities';
import {
  getHsaForecasts,
  getHsaRecommendations,
  getMarketplaceHsaRecommendations,
  getRetirementForecasts,
  postContributions,
} from 'Utils/api';
import * as API_TYPES from 'Utils/apiTypes';

import {
  forecastHsaFailure,
  forecastHsaSuccess,
  forecastRetirementFailure,
  forecastRetirementSuccess,
  getHsaRecommendationFailure,
  getHsaRecommendationSuccess,
} from './actions';
import {
  GET_HSA_RECOMMENDATION_REQUEST,
  GET_RETIREMENT_FORECASTS,
  LOCK_HSA_CONTRIBUTION,
  SET_HSA_BALANCE_AND_PERSONA,
} from './constants';
import { makeGetForecastPayload, makeGetRetirementForecastPayload } from './selectors';

export function* getXhrHsaRecommendation() {
  // Get selected plan
  const selectedPlan: Recommendation = yield select(makeSelectCommercialField('selectedHealthPlan'));

  try {
    // Get previously stored household id and send it with request if it exists
    const householdId: string = yield select(makeGetHouseholdId());

    const enrollmentEventId: string = yield select(makeGetSelectedEnrollmentEventId());

    const resp: API_TYPES.GetHsaRecommendationResponse = yield call(
      getHsaRecommendations,
      selectedPlan,
      householdId,
      enrollmentEventId,
    );
    yield put(getHsaRecommendationSuccess(resp));
  } catch (err: unknown) {
    yield put(getHsaRecommendationFailure(err as Error));
  }
}

export function* getXhrMarketplaceHsaRecommendation() {
  // Get selected plan
  const selectedPlan: Recommendation = yield select(makeSelectCommercialField('selectedHealthPlan'));

  // Get household
  const household: Household = yield select(makeGetHousehold());
  try {
    const resp: API_TYPES.GetHsaRecommendationResponse = yield call(
      getMarketplaceHsaRecommendations,
      selectedPlan,
      household,
    );
    yield put(getHsaRecommendationSuccess(resp));
  } catch (err: unknown) {
    yield put(getHsaRecommendationFailure(err as Error));
  }
}

/*
      done once on page load when `isHsaForecastEnabled`
      sets `retirementForecastData`, which is the total retirement cost
      makes two requests - one for each of policyholder and spouse,
        and sums their retirement costs
 */
export function* getXhrRetirementCosts() {
  const policyholderPayload = yield select(makeGetRetirementForecastPayload('policyholder'));

  const enrollmentEventId = yield select(makeGetSelectedEnrollmentEventId());

  // Determine if household has spouse
  const spouse = yield select(makeSelectSpouse());
  const hasSpouse = spouse.isValid;

  try {
    let responses: any;
    if (hasSpouse) {
      const spousePayload = yield select(makeGetRetirementForecastPayload('spouse'));
      responses = yield all([
        call(getRetirementForecasts, policyholderPayload, enrollmentEventId),
        call(getRetirementForecasts, spousePayload, enrollmentEventId),
      ]);
    } else {
      responses = yield all([call(getRetirementForecasts, policyholderPayload, enrollmentEventId)]);
    }
    yield put(forecastRetirementSuccess(responses));
  } catch (err: unknown) {
    yield put(forecastRetirementFailure(err as Error));
  }
}

/*
done on page load and slider edit
sets `forecastData`
 */
export function* postXhrHsaForecast() {
  // Get forecast payload
  const payload = yield select(makeGetForecastPayload());

  const enrollmentEventId = yield select(makeGetSelectedEnrollmentEventId());

  try {
    const resp = yield call(getHsaForecasts, payload, enrollmentEventId);
    yield put(forecastHsaSuccess(resp));
  } catch (err: unknown) {
    yield put(forecastHsaFailure(err as Error));
  }
}

export function* postXhrContributions() {
  const enrollmentEventId = yield select(makeGetSelectedEnrollmentEventId());
  const hsaContribution = yield select(makeSelectTaxSavingsField('lockedHsaContribution'));

  const formattedJvpContributions: Record<string, API_TYPES.Contribution[]> = {
    contributions: [],
  };

  if (hsaContribution !== null) {
    formattedJvpContributions?.contributions.push({
      account_type: 'hsa',
      annual_contribution_amount: hsaContribution,
    });
  }

  try {
    const resp: API_TYPES.PostContributionsResponse = yield call(
      postContributions,
      enrollmentEventId,
      formattedJvpContributions,
    );
    // Firing this action doesn't actually do anything; response just contains the payload we sent
    yield put(postContributionsSuccess(resp));
  } catch (err: unknown) {
    yield put(postContributionsFailure(err as Error));
  }
}

export default function* hsaPageSaga() {
  const isMarketplace = yield select(makeSelectConfigField('is_marketplace'));
  const builderCustomerKey = yield select(makeSelectConfigField('builder_customer_key'));
  const sagas: ForkEffect[] = [];

  const recommendationFunc = isMarketplace ? getXhrMarketplaceHsaRecommendation : getXhrHsaRecommendation;
  sagas.push(takeLatest(GET_HSA_RECOMMENDATION_REQUEST, recommendationFunc));
  sagas.push(takeLatest(GET_RETIREMENT_FORECASTS, getXhrRetirementCosts));
  sagas.push(takeLatest(LOCK_HSA_CONTRIBUTION, postXhrHsaForecast));
  sagas.push(takeLatest(SET_HSA_BALANCE_AND_PERSONA, postXhrHsaForecast));

  if (builderCustomerKey) {
    sagas.push(takeLatest(LOCK_HSA_CONTRIBUTION, postXhrContributions));
  }
  yield all(sagas);
}
