import styled from 'styled-components';

import { ButtonStyle } from 'DesignLibrary/atoms/Button/styled';
import { cardStyling, focusUtil } from 'DesignLibrary/utils';

export const FeedbackContainer = styled.div`
  ${cardStyling}
  padding: var(--sizing-md);
  flex-shrink: 0;
`;

export const ScaleFeedback = styled.fieldset`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  flex-direction: row;
  grid-gap: var(--sizing-xs);
  padding: 0;
  margin: 0;
  margin-top: var(--sizing-sm);

  ${focusUtil}

  span {
    display: block;
  }

  button {
    margin: 0;
  }
`;

export const ScaleFaceButton = styled(ButtonStyle)`
  padding: 0;
  font-size: 20px;
  width: 100%;
  justify-content: center;
`;

export const TextAreaFeedback = styled.section`
  & > * + * {
    margin-top: var(--sizing-sm);
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;

    & > * + * {
      margin-top: var(--sizing-sm);
    }
  }

  textarea {
    width: 100%;
    min-height: var(--sizing-3xl);
    padding: 12px;
    border-radius: var(--border-radius-xs);
    border: var(--border);
    box-shadow: var(--input-shadow);
    outline: none;
    font-size: 16px;

    &::placeholder {
      color: var(--text-gray-placeholder);
    }

    ${focusUtil}
  }
`;

export const FinishedFeedback = styled.section``;
