import styled, { css } from 'styled-components';

type LabelInputProps = {
  error: boolean;
};

export const LabelInputStyle = styled.div<LabelInputProps>`
  label {
    margin-top: 0.4rem;
  }

  div {
    margin-top: var(--sizing-xs);
  }

  ${({ error }) =>
    error &&
    css`
      input {
        border: 2px solid var(--primary-red);
      }
    `}
`;
