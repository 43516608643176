import { useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';

type LocationPath = {
  pathname: string;
};
interface ScrollToTopProps {
  children: JSX.Element;
  location: LocationPath;
}

const ScrollToTop = ({ children, location }: ScrollToTopProps) => {
  const prevLocation = useRef<LocationPath>({ pathname: '' });

  useEffect(() => {
    if (prevLocation.current && location.pathname !== prevLocation?.current?.pathname) {
      window.scrollTo(0, 0);
    }
    prevLocation.current = location;
  }, [location]);

  return children;
};

export default withRouter(ScrollToTop);
