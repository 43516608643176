import styled from 'styled-components';

import { media } from 'DesignLibrary/global';
import { cardStyling } from 'DesignLibrary/utils';

export const HsaContainer = styled.section`
  margin-bottom: var(--sizing-3xl);

  > section {
    margin-top: var(--sizing-xl);
  }

  ${media.TABLET`
    > section {
      margin-top: var(--sizing-2xl);
    }
  `}

  .grid {
    ${media.DESKTOP`
      display: grid;
      grid-template-columns: auto minmax(300px, 320px);
      grid-gap: var(--sizing-md);

      & > :first-child {
        order: 2;
      }
    `}
  }
`;

export const NoHsaPlanSelected = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 6rem 1rem auto 1rem;
  min-height: 80vh;

  h4 {
    font-size: 16px;
    font-weight: 400;
  }

  div {
    display: flex;
    flex-direction: column;
    ${media.DESKTOP`flex-direction: row;`}
    justify-content: space-around;

    button {
      margin: 1rem;
    }
  }
`;

export const InteractiveLoading = styled.div`
  ${cardStyling}
  margin-top: var(--sizing-2xl);
  padding: var(--sizing-lg);
  display: flex;
  align-items: center;
  justify-content: center;
`;
