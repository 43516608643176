import styled from 'styled-components';

import { breakpoint } from 'DesignLibrary/global';
import { cardStyling } from 'DesignLibrary/utils';

export const TopContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--sizing-md);

  h6 {
    font-weight: 900;
    text-align: center;
  }

  .callout {
    max-width: 960px;
    font-weight: 300 !important;
    text-align: center;
    margin-bottom: var(--sizing-xs);

    b {
      color: var(--primary-blue);
      font-weight: 900;
    }
  }

  ${breakpoint.TABLET} {
    h6 {
      text-align: left;
    }

    .callout {
      text-align: left;
      margin: 0;
    }
  }
`;

export const ChartContainer = styled.div`
  margin: var(--sizing-xl) 0;

  ${breakpoint.TABLET} {
    margin: var(--sizing-2xl) 0;
  }
`;

export const BottomContent = styled.div``;

export const Bullets = styled.div`
  margin: 0 auto;

  & > * + * {
    margin-top: var(--sizing-md);
  }

  ${breakpoint.TABLET} {
    margin: 0;
  }
`;

export const LoadingState = styled.div`
  ${cardStyling}
  padding: var(--sizing-2xl) 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
