import React, { useState, useEffect, useRef } from 'react';

import { usePremiumContext } from 'Contexts/premiumContext';
import { GraphMode, PAY_PERIOD_VALUES, Recommendation } from 'Types/entities';
import { GetHsaRecommendationResponse } from 'Utils/apiTypes';

import { Switch } from './_shared/Switch';
import { HsaSlider } from './HsaSlider';
import { LongTermDetails } from './LongTermDetails';
import { LongTermForecast } from './LongTermForecast';
import { ShortTermDetails } from './ShortTermDetails';
import { ShortTermForecast } from './ShortTermForecast';
import { Container, GraphContainer } from './styled';
import { Persona } from '../types';

export interface InteractiveProps {
  userContributionLimit: number;
  hsaData: GetHsaRecommendationResponse | Record<string, never>;
  selectedPlan: Recommendation;
  employerHsaContribution: number | null;
  isForecastLoading: boolean;
  isRetirementForecastLoading: boolean;
  retirementCost: number;
  forecastedHsaBalance: number;
  contribution: number;
  currentHsaBalance: number | null;
  persona: Persona;
  isPolicyholderOverRetirementAge: boolean;
  policyholderLifeExpectancy: number;
  setContribution: (contribution: number) => void;
  handleLockedHsaContribution: (contribution: number) => void;
  handleHsaBalanceAndPersona: (balance: number, persona: Persona) => void;
}

export const Interactive = ({
  contribution,
  userContributionLimit,
  hsaData,
  selectedPlan,
  employerHsaContribution,
  retirementCost,
  forecastedHsaBalance,
  isForecastLoading,
  isRetirementForecastLoading,
  currentHsaBalance,
  persona,
  isPolicyholderOverRetirementAge,
  policyholderLifeExpectancy,
  setContribution,
  handleLockedHsaContribution,
  handleHsaBalanceAndPersona,
}: InteractiveProps) => {
  const [graphMode, setGraphMode] = useState<GraphMode>('short');
  const [isEditing, setIsEditing] = useState<boolean>(currentHsaBalance === null);

  const ageInputRef = useRef<HTMLInputElement>(null);
  const editButtonRef = useRef<HTMLButtonElement>(null);

  const { payPeriod } = usePremiumContext();

  useEffect(() => {
    if (isEditing && ageInputRef.current) {
      ageInputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (isEditing && ageInputRef.current) {
      ageInputRef.current.focus();
    } else {
      editButtonRef.current?.focus();
    }
  }, [isEditing]);

  return (
    <Container>
      {/* TODO handle debounce in the saga? */}
      <HsaSlider
        contribution={contribution}
        userContributionLimit={userContributionLimit}
        setContribution={setContribution}
        setLockedContribution={handleLockedHsaContribution}
        sliderType={graphMode === 'short' ? 'short-term' : 'long-term'}
      />
      <GraphContainer>
        <Switch handleButton={(val) => setGraphMode(val)} active={graphMode} />
        {graphMode === 'short' ? (
          <ShortTermForecast
            selectedPlan={selectedPlan}
            employerHsaContribution={employerHsaContribution || 0}
            contribution={contribution}
          />
        ) : (
          <LongTermForecast
            forecastedHsaBalance={forecastedHsaBalance}
            retirementCost={retirementCost}
            isForecastLoading={isForecastLoading}
            isRetirementForecastLoading={isRetirementForecastLoading}
            currentHsaBalance={currentHsaBalance}
            persona={persona}
            isPolicyholderOverRetirementAge={isPolicyholderOverRetirementAge}
            policyholderLifeExpectancy={policyholderLifeExpectancy}
            handleHsaBalanceAndPersona={handleHsaBalanceAndPersona}
            setIsEditing={setIsEditing}
            isEditing={isEditing}
            ref={ageInputRef}
          />
        )}
      </GraphContainer>

      {graphMode === 'short' ? (
        <ShortTermDetails
          hsaData={hsaData}
          contribution={contribution}
          deductionsPerYear={selectedPlan.costs.annual_premium_deductions || PAY_PERIOD_VALUES[payPeriod]}
        />
      ) : (
        <LongTermDetails
          currentHsaBalance={currentHsaBalance}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          persona={persona}
          forecastedHsaBalance={forecastedHsaBalance}
          retirementCost={retirementCost}
          ref={editButtonRef}
        />
      )}
    </Container>
  );
};
