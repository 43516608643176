import React from 'react';

import { ContentfulInfoBars } from 'ContentfulWrappers/ContentfulInfoBars';
import { useTextContext } from 'Contexts/textContext';
import InfoBar from 'DesignLibrary/molecules/InfoBar';
import { invalidPlanMessageType } from 'Types/entities';

export interface InvalidPlanMessageProps {
  invalidPlanMessage: invalidPlanMessageType;
}

const InvalidPlanSelectionMessage = ({ invalidPlanMessage }: InvalidPlanMessageProps) => {
  const { retrieveContentfulData } = useTextContext();

  const invalidatePlanMessage = retrieveContentfulData<string>(
    `health_section.recommendation.invalidate_plan_message.${invalidPlanMessage}`,
  );

  return (
    <ContentfulInfoBars sectionKey="health_section">
      <div key={invalidPlanMessage}>
        <InfoBar
          data-testid="plan-invalidate"
          className="custom-disclaimer"
          color="yellow"
          text={invalidatePlanMessage}
          icon="Warning"
          inline={false}
        />
      </div>
    </ContentfulInfoBars>
  );
};

export default InvalidPlanSelectionMessage;
