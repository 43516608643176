import { Box, Stack } from '@chakra-ui/react';
import React from 'react';

import { CommuterBenefits } from 'Containers/ViewBenefitPage/types';
import { transformBenefitItems } from 'Containers/ViewBenefitPage/utils';
import { useTextContext } from 'Contexts/textContext';
import { Button, Paragraph } from 'DesignLibrary/atoms';
import Text from 'Shared/Text';
import { SupplementalPlan } from 'Types/entities';
import { toDollars } from 'Utils/helpers';

export interface CommuterBenefitDescriptionProps {
  plan: SupplementalPlan;
}

export const CommuterBenefitDescription = (props: CommuterBenefitDescriptionProps) => {
  const { plan } = props;
  const { retrieveContentfulData } = useTextContext();
  const benefitItems = transformBenefitItems(plan) as CommuterBenefits;

  const getFsaOrLocationSection = (): JSX.Element => {
    if (benefitItems.is_fsa) {
      return (
        <Paragraph>
          <Text field="benefits_section_commuter.fsa" />
        </Paragraph>
      );
    }

    // Go to {loc} to access your commuter savings.
    const loc = benefitItems.location_is_website ? (
      <Box display="inline" as="span" sx={{ a: { display: 'inline', fontSize: 'inherit' } }}>
        <Button buttonType="link" url={benefitItems.location_url} onClick={() => null}>
          {benefitItems.location}
        </Button>
      </Box>
    ) : (
      benefitItems.location
    );

    return (
      <Paragraph>
        <Text field="benefits_section_commuter.location" vars={{ loc }} />
      </Paragraph>
    );
  };

  const getParkingOrTransitSection = (): JSX.Element | null => {
    const limit = toDollars(retrieveContentfulData<number>('benefits_section_commuter.contribution_limit'));

    let text: JSX.Element | null = null;
    if (benefitItems.has_parking && benefitItems.has_transit) {
      text = <Text field="benefits_section_commuter.parking_and_transit" vars={{ limit }} />;
    } else if (benefitItems.has_transit) {
      text = <Text field="benefits_section_commuter.transit_only" vars={{ limit }} />;
    } else if (benefitItems.has_parking) {
      text = <Text field="benefits_section_commuter.parking_only" vars={{ limit }} />;
    }
    return text ? <Paragraph>{text}</Paragraph> : null;
  };

  const getMoreInfoSection = () => (
    <Paragraph>
      <Text field="benefits_section_commuter.more_info" />
    </Paragraph>
  );

  return (
    <Stack gap={3}>
      {getFsaOrLocationSection()}
      {getParkingOrTransitSection()}
      {getMoreInfoSection()}
    </Stack>
  );
};
