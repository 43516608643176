import styled from 'styled-components';

export const NavContainer = styled.nav`
  position: relative;
  z-index: 1299;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  height: var(--sizing-2xl);
  width: 100%;
  padding: var(--sizing-sm);
  background: var(--primary-white);
  border-bottom: 2px solid var(--colors-gray-200);

  .left-nav {
    .menu-btn {
      border-radius: 2rem;
    }
  }

  @media print {
    display: none;
  }
`;
