import React, { useState } from 'react';

import { useTextContext } from 'Contexts/textContext';
import { Button, Paragraph } from 'DesignLibrary/atoms/';
import { Drawer } from 'DesignLibrary/atoms/Drawer';
import RichText from 'Shared/RichText';
import Text from 'Shared/Text';

import { DisclaimerContainer } from './styled';

const ClientDisclaimer = () => {
  const [isClientModalOpen, setIsClientModalOpen] = useState(false);
  const { retrieveContentfulData } = useTextContext();

  // Contentful Content
  const showDisclaimer = retrieveContentfulData<boolean>('unified_landing_page.show_custom_disclaimer');
  const disclaimerButtonExists =
    retrieveContentfulData<boolean>('unified_landing_page.custom_landing_disclaimer_button_text') != null;

  return (
    <>
      <Drawer
        isOpen={isClientModalOpen}
        onClose={() => setIsClientModalOpen(false)}
        title={<Text field="unified_landing_page.custom_landing_disclaimer_modal_title" />}
      >
        <RichText field="unified_landing_page.custom_landing_disclaimer_modal" />
      </Drawer>
      {showDisclaimer && (
        <DisclaimerContainer className="disclaimer-content">
          <Paragraph>
            <RichText field="unified_landing_page.custom_landing_disclaimer" />
          </Paragraph>
          {disclaimerButtonExists && (
            <Button className="disclaimer-button" onClick={() => setIsClientModalOpen(true)}>
              <Text field="unified_landing_page.custom_landing_disclaimer_button_text" />
            </Button>
          )}
        </DisclaimerContainer>
      )}
    </>
  );
};

export default ClientDisclaimer;
