import styled, { css } from 'styled-components';

import { PrimaryColors } from '../../vars';

type InfoBarProps = {
  color: PrimaryColors;
  colorText: boolean;
  stretch: boolean;
  showAccent: boolean;
};

type InfoContentProps = {
  size: 'sm' | 'md' | 'lg';
};

export const InfoBarStyle = styled.div<InfoBarProps>`
  display: flex;
  width: ${({ stretch }) => (stretch ? 'auto' : 'fit-content')};
  border-radius: var(--border-radius-xs);
  background: var(--colors-${({ color }) => color}-50);
  border: 2px solid var(--primary-${({ color }) => color});
  overflow: hidden;

  ${({ showAccent }) =>
    !showAccent &&
    css`
      border: none;
    `}

  .paragraph,
  p {
    margin: 0;
    font-size: var(--sizing-sm);
    line-height: var(--sizing-md);

    b {
      color: ${({ colorText }) => (colorText ? 'inherit' : '--text-black')};
    }

    ul {
      padding-top: var(--sizing-xs);
      padding-left: var(--sizing-sm);
    }
  }

  // This rule is for the button which we will always be rendering as an a tag in the InfoBar
  a {
    margin-top: var(--sizing-sm);
    width: fit-content;
  }
`;

export const InfoContent = styled.div<InfoContentProps>`
  ${({ size }) => {
    switch (size) {
      case 'sm':
        return css`
          padding: var(--sizing-xs);
        `;
      case 'md':
        return css`
          padding: var(--sizing-sm);
        `;
      case 'lg':
        return css`
          padding: var(--sizing-md);
        `;
      default:
        return css`
          padding: var(--sizing-sm);
        `;
        break;
    }
  }}

  // padding: var(--sizing-sm);

  .align-icon {
    display: flex;

    svg,
    img,
    .icon {
      margin-right: var(--sizing-sm);
      flex-shrink: 0;
    }
  }
`;
