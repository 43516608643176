import { ContentfulPlanCustomizations } from 'ContentfulDefaults/types/customizations';

export const customizations: {
  plan_customizations: ContentfulPlanCustomizations;
} = {
  plan_customizations: [
    {
      contentful_name: '[plan123] Customization',
      plan_id: 'plan123',
      plan_page_disclaimers: [
        {
          header: 'Warning',
          barColor: 'red',
          description: 'This plan may contain outdated pricing information',
        },
      ],
      result_page_disclaimers: [
        {
          header: 'Warning',
          barColor: 'red',
          description: 'This plan may contain outdated pricing information',
        },
      ],
      custom_plan_notes: [
        'A minimum HSA contribution is required for this plan.',
        'Plan payments must be made quarterly',
      ],
    },
  ],
};
