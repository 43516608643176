import styled from 'styled-components';

import { media } from 'DesignLibrary/global';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: var(--sizing-sm);
  position: relative;

  ${media.TABLET`
    width: 100%;
    left: 0;
  `}
`;
