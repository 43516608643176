import React, { FC } from 'react';

import { InfoBarStyle, InfoContent } from './styled';
import { Button } from '../../atoms';
import { Icon, IconTypes } from '../../atoms/Icon';
import { Paragraph } from '../../atoms/typography';
import { PrimaryColors } from '../../vars';

// There is a chance that HTMLAttributes might not be the right interface
// see: https://github.com/typescript-cheatsheets/react/issues/128
// But it satisfies styled components and seems to define the desired attributes
export interface InfoBarProps extends React.HTMLAttributes<HTMLDivElement> {
  text?: JSX.Element | string;
  header?: string | React.ReactNode;
  stretch?: boolean;
  showAccent?: boolean;
  icon?: IconTypes;
  iconOverride?: JSX.Element;
  buttonText?: string;
  url?: string;
  color?: PrimaryColors;
  colorText?: boolean;
  inline?: boolean;
  size?: 'sm' | 'md' | 'lg';
}

const InfoBar: FC<InfoBarProps> = ({
  header,
  text = 'Fix Me',
  stretch = true,
  showAccent = true,
  icon = null,
  iconOverride,
  buttonText,
  url = '',
  color = 'blue',
  colorText = false,
  inline = true,
  size = 'md',
  children,
  ...rest
}): JSX.Element => (
  <InfoBarStyle color={color} colorText={colorText} stretch={stretch} showAccent={showAccent} {...rest}>
    <InfoContent size={size}>
      <div className="align-icon">
        {!iconOverride && icon && (
          <div data-testid="icon">
            <Icon type={icon} color={`--primary-${color}`} weight="fill" />
          </div>
        )}
        {iconOverride && iconOverride}
        <Paragraph color={colorText ? `--colors-${color}-600` : '--text-black'}>
          {header && <b>{header} &nbsp;</b>}
          {inline ? text : <span style={{ display: 'block' }}>{text}</span>}
        </Paragraph>
      </div>
      {buttonText && (
        <Button size="small" url={url}>
          {buttonText}
        </Button>
      )}
      {children}
    </InfoContent>
  </InfoBarStyle>
);

export default InfoBar;
