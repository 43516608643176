import { ContentfulCallToActionSection } from 'ContentfulDefaults/types/callToAction';

export const callToAction: {
  call_to_action: ContentfulCallToActionSection;
} = {
  call_to_action: {
    contentful_name: 'Call to Action (DEFAULT DO NOT CHANGE)',
    health_default: 'Choose a health plan',
    health_waived: 'Coverage Waived',
    supplemental: 'Supplemental',
    continue_button: {
      profile: 'Health Results',
      hsa: 'Supplemental Plans',
      review: "Let's Enroll",
      results: "Let's Continue",
      overview: "Let's Review",
      viewPlan: 'All Plans',
      viewBenefit: 'All Benefits',
    },
    tooltips: {
      results: 'Go to Health',
      hsa: 'Go to HSA',
      overview: 'View Plans',
      continueButtonDisabled: 'Choose a Health Plan',
      profile: {
        eligibility: 'Eligibility',
        enrollment: 'Enrollment',
        location: 'Residence',
        member: 'Household',
        pregnancy: 'Pregnancy',
        prescription: 'Prescriptions',
        utilization: 'Utilization',
        income: 'Income',
        riskAssessment: 'Risk Aversion',
        capacityToPay: 'Affordability',
        incentives: 'Incentives',
      },
    },
    cart_header: 'Chosen Plans',
    cart_text: 'Here’s what you’ve chosen so far.  Click on a plan to go back to the plan’s details.',
    cart_close_button: 'Close',
    accessibility: {
      continue_button: 'Navigation',
    },
  },
};
