import React from 'react';

import { RiskAversion, CAPACITY_TO_PAY, CapacityToPayAnswer } from 'Containers/ProfilePage/types';
import { useTextContext } from 'Contexts/textContext';
import InfoBar from 'DesignLibrary/molecules/InfoBar';
import Text from 'Shared/Text';
import { REASONS_WHY } from 'Types/entities';
import { toDollars } from 'Utils/helpers';

import { CONTENT_MAP } from '../constants';
import { InsuranceType } from '../types';

export const CAPACITY_TO_PAY_MAP = {
  [CAPACITY_TO_PAY.CAP_LT_500]: <Text field="profile_section.cost_thresholds.lt_500" />,
  [CAPACITY_TO_PAY.CAP_500_1000]: <Text field="profile_section.cost_thresholds.500_1000" />,
  [CAPACITY_TO_PAY.CAP_1000_2000]: <Text field="profile_section.cost_thresholds.1000_2000" />,
  [CAPACITY_TO_PAY.CAP_2000_3000]: <Text field="profile_section.cost_thresholds.2000_3000" />,
  [CAPACITY_TO_PAY.CAP_3000_4000]: <Text field="profile_section.cost_thresholds.3000_4000" />,
  [CAPACITY_TO_PAY.CAP_4000_6000]: <Text field="profile_section.cost_thresholds.4000_6000" />,
  [CAPACITY_TO_PAY.CAP_GT_6000]: <Text field="profile_section.cost_thresholds.gt_6000" />,
};

export const getReasonsWhyText = (
  childrenCount: number,
  capacityToPay: CapacityToPayAnswer,
  healthDeductible: number,
  riskAversion: RiskAversion,
) => {
  const prefix = 'benefits_section.reasons_why';

  return {
    [REASONS_WHY.HAS_DENTAL_CLEANINGS]: <Text field={`${prefix}.dentalCleanings`} />,
    [REASONS_WHY.HAS_KIDS_DENTAL]:
      childrenCount === 1 ? (
        <Text field={`${prefix}.childDental`} />
      ) : (
        <Text field={`${prefix}.childrenDental`} vars={{ x: childrenCount }} />
      ),
    [REASONS_WHY.HAS_EYE_EXAMS]: <Text field={`${prefix}.eyeExams`} />,
    [REASONS_WHY.HAS_EYE_GLASSES]: <Text field={`${prefix}.eyeGlasses`} />,
    [REASONS_WHY.HAS_PHYSICAL_ACTIVITIES]:
      childrenCount === 1 ? (
        <Text field={`${prefix}.childActivity`} />
      ) : (
        <Text field={`${prefix}.childrenActivity`} vars={{ x: childrenCount }} />
      ),
    [REASONS_WHY.HAS_HIGH_DEDUCTIBLE_LOW_CAPACITY]: (
      <Text
        field={`${prefix}.highDeductLowCapacity`}
        vars={{
          healthDeductible: toDollars(healthDeductible),
          capacityToPay: CAPACITY_TO_PAY_MAP[capacityToPay],
        }}
      />
    ),
    [REASONS_WHY.HAS_MODERATE_HIGH_RISK_AVERSION]: (
      <Text field={`${prefix}.moderateRisk`} vars={{ x: riskAversion }} />
    ),
    [REASONS_WHY.HAS_HIGH_RISK_AVERSION]: (
      <Text field={`${prefix}.moderateRisk`} vars={{ x: riskAversion }} />
    ),
    [REASONS_WHY.IS_OLDEST_AGE_40_TO_55]: <Text field={`${prefix}.age40to50`} />,
    [REASONS_WHY.IS_OLDEST_AGE_OVER_55]: <Text field={`${prefix}.ageOver50`} />,
    [REASONS_WHY.HAS_PLANNED_PREGNANCY]: <Text field={`${prefix}.pregnancy`} />,
    [REASONS_WHY.HAS_HOSPITAL_ADMISSION]: <Text field={`${prefix}.hospitalAdmission`} />,
    [REASONS_WHY.LIFE_DEPENDENT]: <Text field={`${prefix}.lifeDependent`} />,
    [REASONS_WHY.LONG_TERM_DISABILITY]: <Text field={`${prefix}.longTermDisability`} />,
    [REASONS_WHY.TOBACCO]: <Text field={`${prefix}.tobacco`} />,
    [REASONS_WHY.PRESCRIPTIONS]: <Text field={`${prefix}.prescriptions`} />,
    [REASONS_WHY.PARENTAL_LEAVE]: <Text field={`${prefix}.parentalLeave`} />,
    [REASONS_WHY.EXTRA_PROTECTION]: (
      <Text field={`${prefix}.extraProtection`} vars={{ x: CAPACITY_TO_PAY_MAP[capacityToPay] }} />
    ),
    [REASONS_WHY.CHILDCARE]: <Text field={`${prefix}.childcare`} />,
    [REASONS_WHY.PLANNED_PROCEDURE_INPATIENT]: <Text field={`${prefix}.plannedProcedureInpatient`} />,
  };
};

export interface ReasonsWhyListProps {
  insuranceType: InsuranceType;
  reasonsWhy: REASONS_WHY[];
  childrenCount: number;
  healthDeductible: number;
  capacityToPay: CapacityToPayAnswer;
  riskAversion: RiskAversion;
}

const ReasonsWhyList = ({
  insuranceType,
  reasonsWhy,
  childrenCount,
  healthDeductible,
  capacityToPay,
  riskAversion,
}: ReasonsWhyListProps) => {
  const { retrieveContentfulData } = useTextContext();

  const reasonWhyText = getReasonsWhyText(childrenCount, capacityToPay, healthDeductible, riskAversion);

  return (
    <InfoBar
      inline={false}
      showAccent={false}
      color="blue"
      header={
        <Text
          field="benefits_section.reasons_why_heading.title"
          vars={{ x: retrieveContentfulData<string>(`${CONTENT_MAP[insuranceType].field}.card_title`) }}
        />
      }
      text={
        <ul>
          {reasonsWhy.map((reason) => (
            <li key={reason}>{reasonWhyText[reason]}</li>
          ))}
        </ul>
      }
    />
  );
};

export default ReasonsWhyList;
