import React, { useEffect } from 'react';

import { RiskCard } from 'ContentfulDefaults/types/profile';
import { useTextContext } from 'Contexts/textContext';
import { Paragraph } from 'DesignLibrary/atoms/typography';

import { AnswerToggle } from './AnswerToggle';
import Question from '../_shared/Question';
import { QuestionWrapper } from '../_shared/Question/styled';
import { AudioConfigTooltipContentfulPaths } from '../ProfileAudio';
import { RiskAssessmentAnswer, Survey } from '../types';

export interface RiskAssessmentSectionProps {
  survey: Survey;
  isDirty: boolean;
  isProfileAudioEnabled: boolean;
  handleChangeRiskQuestion1: (answer: RiskAssessmentAnswer) => void;
  handleChangeRiskQuestion2: (answer: RiskAssessmentAnswer) => void;
  handleSectionChange: (isValid: boolean, isComplete: boolean) => void;
  setHiddenAlert: (alert: string) => void;
}

const RiskAssessmentSection = ({
  survey,
  isDirty,
  isProfileAudioEnabled,
  handleChangeRiskQuestion1,
  handleChangeRiskQuestion2,
  handleSectionChange,
  setHiddenAlert,
}: RiskAssessmentSectionProps) => {
  const { retrieveContentfulData } = useTextContext();
  const profileCard = retrieveContentfulData<RiskCard>('profile_section.risk');
  const { risk_question_1, risk_question_2 } = survey;

  useEffect(() => {
    const alertText = retrieveContentfulData<string>('profile_section.accessibility.unlocked_section', '');
    setHiddenAlert(alertText.replace(/{x}/, profileCard.name));
  }, []);

  useEffect(() => {
    handleSectionChange(validateForm(), getIsFormComplete());
  }, [risk_question_1, risk_question_2]);

  function validateForm() {
    return risk_question_1 !== '' && risk_question_2 !== '';
  }

  const getIsFormComplete = () => {
    if (risk_question_1 === 'plan_a') {
      return true;
    }
    if (risk_question_2 !== '') {
      return true;
    }
    return false;
  };

  const question1_label_id = 'risk_question_1';
  const question2_label_id = 'risk_question_2';

  return (
    <div id="riskAssessment-section" data-testid="risk-section">
      <Question
        error={!validateForm() && isDirty}
        isIncomplete={!getIsFormComplete()}
        name="risk"
        audioTooltipsEnabled={isProfileAudioEnabled}
        audioTooltipContentfulPath={AudioConfigTooltipContentfulPaths.RiskSectionAudioTooltip}
      >
        <QuestionWrapper>
          <Paragraph id={question1_label_id}>{profileCard.header_question_a}</Paragraph>
          <AnswerToggle
            labelId={question1_label_id}
            groupName="risk_question_1"
            className="pingdom-risk-question-1"
            selectedValue={risk_question_1}
            handleSelectA={() => {
              handleChangeRiskQuestion1('plan_a');
              handleChangeRiskQuestion2('plan_a');
            }}
            handleSelectB={() => {
              handleChangeRiskQuestion1('plan_b');
              handleChangeRiskQuestion2('');
            }}
          />
        </QuestionWrapper>
        {risk_question_1 === 'plan_b' ? (
          <QuestionWrapper>
            <Paragraph id={question2_label_id}>{profileCard.header_question_b}</Paragraph>
            <AnswerToggle
              labelId={question2_label_id}
              groupName="risk_question_2"
              className="pingdom-risk-question-2"
              selectedValue={risk_question_2}
              handleSelectA={() => {
                handleChangeRiskQuestion2('plan_a');
              }}
              handleSelectB={() => {
                handleChangeRiskQuestion2('plan_b');
              }}
            />
          </QuestionWrapper>
        ) : null}
      </Question>
    </div>
  );
};

export default RiskAssessmentSection;
