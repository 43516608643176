import React, { ChangeEvent, forwardRef } from 'react';

import { TextInputStyle, TextContainer } from './styled';
import { Icon, IconTypes } from '../../Icon';

export interface TextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  inputType?: 'normal' | 'icon' | 'currency' | 'percent' | 'integer';
  inputMode?: 'text' | 'numeric';
  stretch?: boolean;
  value?: string;
  placeholder?: string;
  iconType?: IconTypes;
  disabled?: boolean;
  error?: boolean;
  testId?: string;
  labelId?: string;
  handleChange: (e: ChangeEvent<HTMLInputElement>, value?: number | string | null) => void;
}

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      inputType = 'normal',
      inputMode = 'text',
      stretch = false,
      value = '',
      placeholder = 'Type here',
      iconType = 'Search',
      disabled = false,
      error = false,
      testId = 'textInput',
      labelId,
      handleChange,
      ...rest
    },
    ref,
  ) => {
    const hasDecimalPoint = (value: string) => value && (value.match(/\./g) || []).length > 0;

    const handleChangePercent = (e: ChangeEvent<HTMLInputElement>, val: string) => {
      const regex = /^(?:\d{0,2}(?:\.\d{0,2})?|100(?:\.0?0)?)$/g;
      // We want to prevent commas so we need to check e.target.value because we already stripped comma separators out of val
      const isValid = e.target.value.match(regex);
      if (isValid) {
        const parsedVal = val === '' ? null : parseFloat(val.replace(/,/g, ''));
        handleChange(e, parsedVal);
      }
    };

    const handleChangeCurrency = (e: ChangeEvent<HTMLInputElement>, val: string) => {
      const regex = /^\d*(?:\.\d{0,2})?$/g;
      // We stripped comma separators so we check val
      const isValid = val.match(regex);

      if (isValid) {
        const idx = val.indexOf('.');
        if (hasDecimalPoint(val) && idx > 3) {
          // If decimal with 4 or more characters formatDecimal
          const formatted = formatDecimal(val, idx, false);
          handleChange(e, formatted);
        } else {
          const parsedVal = val === '' ? null : val;
          handleChange(e, parsedVal);
        }
      }
    };

    const handleChangeInteger = (e: ChangeEvent<HTMLInputElement>, val: string) => {
      const regex = /^[\d]*$/g;
      // We want to prevent commas so we need to check e.target.value because we already stripped comma separators out of val
      const isValid = e.target.value.match(regex);
      if (isValid) {
        const parsedVal = val === '' ? null : val;
        handleChange(e, parsedVal);
      }
    };

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
      const val = e.target.value.replace(/,/g, ''); // remove comma separator
      switch (inputType) {
        case 'percent': {
          handleChangePercent(e, val);
          break;
        }
        case 'currency': {
          handleChangeCurrency(e, val);
          break;
        }
        case 'integer': {
          handleChangeInteger(e, val);
          break;
        }
        default: {
          const stringVal = e.target.value;
          const parsedVal = stringVal === '' ? null : stringVal;
          handleChange(e, parsedVal);
        }
      }
    };

    // formatDecimal is used to format decimals that are being sent out through handleChange to Redux
    // and to format inbound decimals coming from the value Prop so it needs to be able to support
    // formatting decimals with and without comma separation
    const formatDecimal = (val, idx, isCommaSeparated) => {
      const decimal = val.slice(idx);
      const rounded = decimal.length > 3 ? decimal.slice(0, -1) : decimal;
      const number = val.slice(0, idx);
      if (isCommaSeparated) {
        return `${parseFloat(number).toLocaleString()}${rounded}`;
      }
      return `${number}${rounded}`;
    };

    // Format value prop for display in TextInput
    const getParsedValue = () => {
      if (inputType === 'currency') {
        if (hasDecimalPoint(value)) {
          // Handle decimals
          const idx = value.indexOf('.'); // get index of decimal
          return formatDecimal(value, idx, true);
        }
        if (value) {
          // Handle currency inputs that are not ''
          return parseFloat(value).toLocaleString();
        }
      }
      return value;
    };

    return (
      <TextContainer data-testid="textInputWrapper" inputType={inputType} stretch={stretch}>
        <TextInputStyle
          type="text"
          inputMode={['currency', 'percent'].includes(inputType) ? 'numeric' : inputMode}
          aria-labelledby={labelId}
          data-testid={testId}
          value={getParsedValue()}
          inputType={inputType}
          placeholder={placeholder}
          disabled={disabled}
          error={!disabled && error}
          onChange={onChange}
          ref={ref}
          {...rest}
        />
        {inputType === 'icon' && (
          <span className="icon" aria-hidden>
            <Icon type={iconType} color={disabled ? '--text-gray-light' : '--text-black'} />
          </span>
        )}
        {inputType === 'currency' && (
          <span className="currency" aria-hidden>
            $
          </span>
        )}
        {inputType === 'percent' && (
          <span className="percent" aria-hidden>
            %
          </span>
        )}
      </TextContainer>
    );
  },
);

// Makes eslint stop yelling; something to do with forwardRef and possibly default exports
TextInput.displayName = 'TextInput';
export default TextInput;
