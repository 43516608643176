import { AspectRatio } from '@chakra-ui/react';
import React from 'react';

import { TextField } from 'Containers/App/types';
import { ContentfulFbsBenefit, ContentfulFbsBenefitNote } from 'ContentfulDefaults/types/benefits';
import { useTextContext } from 'Contexts/textContext';
import { Button, H4, Paragraph } from 'DesignLibrary/atoms';
import { Drawer } from 'DesignLibrary/atoms/Drawer';
import RichText from 'Shared/RichText';
import Text from 'Shared/Text';
import Video from 'Shared/Video';

import { DetailsContainer } from '../BenefitDetails/styled';

export interface EducationalBenefitDetailsProps {
  benefitDetails: ContentfulFbsBenefit;
  benefitIndex: number;
  isOpen: boolean;
  onClose: () => void;
}

// This component is only used for Educational Benefits that have no "plans"
// that only render contentful text
export const EducationalBenefitDetails = ({
  benefitDetails,
  benefitIndex,
  isOpen,
  onClose,
}: EducationalBenefitDetailsProps) => {
  const { retrieveContentfulData } = useTextContext();

  const benefitTitle = benefitDetails.card_title;
  const benefitVideo = benefitDetails.video_link;
  const benefitPdfLink = benefitDetails.pdf_link;
  const benefitNotes =
    retrieveContentfulData<ContentfulFbsBenefitNote>(
      `benefits_section.educational_benefits[${benefitIndex}].notes`,
    ) || null;

  return (
    <Drawer title={benefitTitle} isOpen={isOpen} onClose={onClose}>
      <DetailsContainer>
        {benefitVideo && (
          <AspectRatio ratio={16 / 9} maxW="600px" className="video-container">
            <Video title={`${benefitTitle} video`} url={benefitVideo} width="100%" height="100%" />
          </AspectRatio>
        )}
        <Paragraph>
          <RichText
            noWrapper={false}
            field={`benefits_section.educational_benefits[${benefitIndex}].modal_description` as TextField}
          />
        </Paragraph>
        {benefitNotes && (
          <div className="notes">
            <H4>
              <Text
                field={`benefits_section.educational_benefits[${benefitIndex}].notes.title` as TextField}
              />
            </H4>
            <Paragraph>
              <Text
                field={`benefits_section.educational_benefits[${benefitIndex}].notes.note` as TextField}
              />
            </Paragraph>
          </div>
        )}
        {benefitPdfLink && (
          <Button
            // sends insight to track plan was viewed, is this necessary here?
            // onClick={handleTrackPdfView}
            url={benefitPdfLink}
          >
            <Text field="benefits_section.button_text.learnMorePdf" />
          </Button>
        )}
      </DetailsContainer>
    </Drawer>
  );
};
