export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';

export const PRIVACY_POLICY_URL = 'https://www.jellyvision.com/privacy/';
export const YOUR_PRIVACY_CHOICES_URL = 'https://www.jellyvision.com/privacy-choices';

export enum PREMIUM_PERIOD {
  // Coincidentally, these values are the values that the App API expects
  WEEKLY = 'Weekly',
  BI_WEEKLY = 'Bi-weekly',
  SEMI_MONTHLY = 'Semi-monthly',
  MONTHLY = 'Monthly',
  ANNUALLY = 'Annually',
}

export enum DEPLOY_ENVIRONMENTS {
  LOCALHOST = 'localhost',
  REVIEW = 'review',
  STAGING = 'staging',
  UAT = 'uat',
  PROD = 'prod',
}

// Array of errors we do not want sentry to send us alerts for.
// The values themselves are partial string matches for the exception listed
// last in the breadcrumbs.
// Also allows regex for exact matches.
export const SENTRY_IGNORE = [
  'getBoundingClientRect',
  'Non-Error promise rejection captured with value: Object Not Found Matching Id',
  'Request aborted',
  'No valid configurations found',
  'Failed to fetch',
  'Load failed',
];
