import styled from 'styled-components';

export const HowContainer = styled.div`
  ul,
  ol {
    padding: 0;
    text-indent: -20px; /* key property */
    margin-left: 20px; /* key property */
  }
`;
