import styled from 'styled-components';

export const InputContainer = styled.div`
  border: var(--border-thick);
  border-radius: var(--border-radius-xs);

  > .input {
    border-top: var(--border);
    padding: var(--sizing-sm);

    &.flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: var(--sizing-md);
    }

    .cont-input {
      max-width: 160px;

      input {
        width: 100% !important;
      }
    }
  }
`;

export const Title = styled.div`
  display: flex;
  justify-content: center;
  padding: var(--sizing-xs) 0;
  border-bottom: var(--border);

  h3 {
    font-weight: 900;
    color: var(--primary-blue);
  }
`;
