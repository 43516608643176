// Configure Google Analytics and Insights
import * as Insights from '@picwell/insights-js';

export function configureAnalytics() {
  const isTestMode = process.env.NODE_ENV !== 'production';

  if (!isTestMode) {
    Insights.configure({
      url: window._env_.INSIGHTS_URL,
      apiKey: window._env_.INSIGHTS_API_KEY,
    });
  }
}
