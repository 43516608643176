import styled from 'styled-components';

import { media } from 'DesignLibrary/global';

export const MemberSelectionWrapper = styled.div`
  padding: var(--sizing-md);
  border-top: 1px solid var(--border-gray);

  ${media.TABLET`
    border: none;
  `}

  .title {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .household-members {
    margin-top: var(--sizing-md);

    & > * + * {
      margin-top: var(--sizing-sm);
    }
  }

  .submit {
    margin-top: var(--sizing-md);
  }

  input[type='checkbox']:disabled + .pseudo-checkbox {
    opacity: 1;
    cursor: not-allowed;
  }
`;
