import React from 'react';

import { TextField } from 'Containers/App/types';
import { FormattedTooltipProps, Icon, Paragraph } from 'DesignLibrary/atoms';
import { AccountIcons } from 'DesignLibrary/types';
import RichText from 'Shared/RichText';
import Text from 'Shared/Text';

import { Wrapper } from './styled';

interface AccountInfobarProps {
  type: AccountIcons;
  field: TextField;
  vars?: (string | number)[];
  tooltips?: FormattedTooltipProps[];
  textOverride?: TextField | null;
}

export const AccountInfobar = ({ type, field, vars, tooltips, textOverride }: AccountInfobarProps) => {
  let color;
  if (type === 'hsa') color = 'purple';
  if (type === 'hra') color = 'blue';
  if (type === 'fsa') color = 'green';

  return (
    <Wrapper color={color}>
      <Icon type="Coins" weight="duotone" color="--primary-blue" />
      <Paragraph size="small" color="--text-black">
        <b>
          <Text field={`${field}.header` as TextField} /> &nbsp;
        </b>
        {!textOverride ? (
          <RichText field={`${field}.text` as TextField} vars={vars} toolTips={tooltips} />
        ) : (
          <Text field={textOverride} />
        )}
      </Paragraph>
    </Wrapper>
  );
};
