import { Grid, Stack } from '@chakra-ui/react';
import React from 'react';

import { ContentfulTableColumn } from 'Containers/ViewBenefitPage/types';
import { H5, Paragraph } from 'DesignLibrary/atoms';
import { useViewport } from 'DesignLibrary/context';
import RichText from 'Shared/RichText';

import { StackContainer, StackSection } from './styled';
// import { useTextContext } from 'Contexts/textContext';

export interface PlanDetailsTableProps {
  columns: ContentfulTableColumn[];
  header: string | undefined;
  subheader: string | undefined;
}

const columnTitle = (title: string) => (
  <div className="title">
    <Paragraph lineHeight={1.75} size="small" weight="bold" color="--text-black">
      {title}
    </Paragraph>
  </div>
);

const renderColumns = (columns: ContentfulTableColumn[], showHeading = true) => {
  if (!columns) {
    return null;
  }

  return columns.map((col, index) => (
    <StackSection key={index}>
      {showHeading && columnTitle(col.heading)}
      <Stack className="body" gap={0}>
        {col.richText && <RichText fieldContent={col.richText} field={undefined} />}
        {col.listItems?.length && (
          <ul>{col.listItems?.map((level, index) => <li key={index}>{level}</li>)}</ul>
        )}
        {col.note && (
          <Paragraph lineHeight={1.75} size="small" className="plan-description" text-align="left">
            {col.note}
          </Paragraph>
        )}
      </Stack>
    </StackSection>
  ));
};

const PlanDetailsTable = ({ columns, header, subheader }: PlanDetailsTableProps) => {
  const numColumns = columns.length;
  const { device } = useViewport();

  return (
    <Stack gap={4}>
      <Stack>
        <H5>{header}</H5>
        <Paragraph size="small">{subheader}</Paragraph>
      </Stack>
      <StackContainer>
        {device === 'mobile' ? (
          renderColumns(columns)
        ) : (
          <Stack gap={0}>
            <Grid templateColumns={['1', `repeat(${numColumns}, 1fr)`]}>
              {columns.map((col, index) => (
                <StackSection key={index}>{columnTitle(col.heading)}</StackSection>
              ))}
            </Grid>

            <Grid marginTop="0 !important" templateColumns={['1', `repeat(${numColumns}, 1fr)`]}>
              {renderColumns(columns, false)}
            </Grid>
          </Stack>
        )}
      </StackContainer>
    </Stack>
  );
};

export default PlanDetailsTable;
