import styled, { css } from 'styled-components';

import { ParagraphSmallStyle } from 'DesignLibrary/atoms';
import { breakpoint } from 'DesignLibrary/global';

export const ChartContainer = styled.div`
  position: relative;
`;

export const ChartWrapper = styled.div`
  overflow: hidden;
  border: var(--border-thick);
  border-radius: var(--border-radius-xs) var(--border-radius-xs) 0 0;
  background: var(--background-gray-light);
  position: relative;
  width: 100%;
  min-width: 200px;
  height: 320px;
  background-image: linear-gradient(
    0deg,
    var(--colors-opaque-5) 1%,
    var(--colors-opaque-5) 5%,
    rgba(0, 0, 0, 0) 5%,
    rgba(0, 0, 0, 0) 100%
  );
  background-size: 20px 20px;

  ${breakpoint.TABLET} {
    height: 400px;
    border-radius: var(--border-radius-xs);
  }
`;

interface PercentProps {
  percent: number;
}

export const ContributionContainer = styled.div<PercentProps>`
  position: absolute;
  z-index: 99;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: var(--sizing-3xl);
  height: ${({ percent }) => `${percent}%`};
  border-radius: var(--border-radius-xs) var(--border-radius-xs) 0 0;

  ${breakpoint.TABLET} {
    width: 160px;
  }
`;

const BarStyle = css`
  ${ParagraphSmallStyle('--primary-white', 600)}
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface ContributionProps extends PercentProps {
  small: boolean;
  curved: boolean;
}

export const YourContribution = styled.div.attrs<{ percent: number }>(({ percent }) => ({
  style: { height: `${percent}%` },
}))<ContributionProps>`
  ${BarStyle}
  background: var(--colors-blue-400);

  ${({ small }) =>
    small &&
    css`
      span {
        position: relative;
        top: -8px;
        display: block;
        background: var(--text-black);
        border-radius: 4px;
        padding: 4px;
      }
    `}

  ${({ curved }) =>
    curved &&
    css`
      border-radius: var(--border-radius-xs) var(--border-radius-xs) 0 0;
    `}
`;

export const EmployerContribution = styled.div.attrs<{ percent: number }>(({ percent }) => ({
  style: { height: `${percent}%` },
}))<ContributionProps>`
  ${BarStyle}
  background: var(--colors-blue-800);

  ${({ small }) =>
    small &&
    css`
      span {
        position: relative;
        top: -8px;
        display: block;
        background: var(--text-black);
        border-radius: 4px;
        padding: 4px;
      }
    `}

  ${({ curved }) =>
    curved &&
    css`
      border-radius: var(--border-radius-xs) var(--border-radius-xs) 0 0;
    `}
`;

export const OopLine = styled.div.attrs<{ percent: number }>(({ percent }) => ({
  style: { bottom: `${percent}%` },
}))<PercentProps>`
  position: absolute;
  z-index: 1;
  left: 0;
  width: 100%;
  height: 3px;
  // CSS Workaround to create custom spaced dashed line
  background-image: linear-gradient(to right, black 50%, rgba(255, 255, 255, 0) 0%);
  background-position: bottom;
  background-size: 12px 3px;
  background-repeat: repeat-x;
`;

export const ChartTitle = styled.div`
  ${ParagraphSmallStyle('--text-gray')}
  background: var(--background-gray);
  padding: var(--sizing-xs);
  line-height: 1;
  border-radius: 0 0 var(--border-radius-xs) var(--border-radius-xs);
  border: var(--border-thick);
  border-top: 0;
  text-align: right;

  ${breakpoint.TABLET} {
    position: absolute;
    z-index: 100;
    top: 0;
    right: 0;
    display: inline-block;
    border-bottom-right-radius: 0;
    border-top-right-radius: var(--border-radius-xs);
    border: var(--border-thick);
    padding: var(--sizing-2xs) var(--sizing-xs);
  }

  b {
    color: var(--text-black);
    font-weight: 700 !important;
  }
`;

export const YAxis = styled.div<PercentProps>`
  position: absolute;
  left: var(--sizing-xs);
  bottom: var(--sizing-xs);
  height: ${({ percent }) => `${percent}%`};
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
`;
