import styled from 'styled-components';

export const RankingsContainer = styled.div`
  padding-bottom: var(--sizing-md);

  .paragraph,
  p {
    b {
      color: var(--text-black) !important;
    }
  }
`;
