import styled from 'styled-components';

import { FadeInUpKeyframes } from 'DesignLibrary/keyframes';

export const ConstructionWrapper = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  background: var(--primary-white);
  z-index: 999999;

  img {
    height: 10.5rem;
    opacity: 0;
    animation: ${FadeInUpKeyframes} 1s 0.25s forwards;
  }

  .text {
    margin-top: var(--sizing-xl);

    > * {
      text-align: center;
      margin: 0;
    }
  }
`;
