import { Stack } from '@chakra-ui/react';
import React from 'react';

import { H4, Paragraph } from 'DesignLibrary/atoms';
import RichText from 'Shared/RichText';
import Text from 'Shared/Text';

import { RankingsContainer } from './styled';
import SectionWrapper from '../_shared/SectionWrapper';

const Rankings = () => (
  <SectionWrapper number={3}>
    <RankingsContainer>
      <Stack>
        <H4 as="h2">
          <Text field="people_like_you.rankings_title" />
        </H4>
        <Paragraph lineHeight={1.75}>
          <RichText field="people_like_you.rankings_text" />
        </Paragraph>
      </Stack>
    </RankingsContainer>
  </SectionWrapper>
);

export default Rankings;
