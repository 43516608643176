import { Stack } from '@chakra-ui/react';
import React, { FC, useEffect, useState } from 'react';

import { TextField } from 'Containers/App/types';
import {
  Member,
  MEMBER_TYPE,
  MemberSectionConfigFields,
  RxSearchResults,
  UpdateMemberFields,
} from 'Containers/ProfilePage/types';
import { ContentfulTextNoteType } from 'ContentfulDefaults/types/_shared';
import { MemberCard } from 'ContentfulDefaults/types/profile';
import { ContentfulTextNote } from 'ContentfulWrappers/ContentfulTextNote';
import { useProfileContext } from 'Contexts/profileContext';
import { useTextContext } from 'Contexts/textContext';
import { Icon, IconTypes } from 'DesignLibrary/atoms';
import { Paragraph } from 'DesignLibrary/atoms/typography';
import InfoBar from 'DesignLibrary/molecules/InfoBar';
import RichText from 'Shared/RichText';
import Text from 'Shared/Text';

import { AddMemberButtons } from './AddMemberButtons';
import Disclaimer from './Disclaimer';
import { HouseholdMemberList } from './HouseholdMemberList';
import { CoverageMessage } from './styled';
import Question from '../_shared/Question';
import { AudioConfigTooltipContentfulPaths } from '../ProfileAudio';

export interface MemberSectionProps {
  policyholder: Member;
  spouse: Member;
  dependents: Array<Member>;
  memberConfig: MemberSectionConfigFields;
  drugResults: RxSearchResults;
  isRxLoading: boolean;
  isRxSkipped: boolean;
  isDirty: boolean;
  planYear?: number;
  isProfileAudioEnabled: boolean;
  isIncentiveLoading: boolean;
  handleEditPolicyholder: (update: UpdateMemberFields) => void;
  handleEditSpouse: (update: UpdateMemberFields) => void;
  handleEditDependent: (memberId: string, update: UpdateMemberFields) => void;
  handleDeleteSpouse: () => void;
  handleDeleteDependent: (memberId: string) => void;
  handleSectionChange: (isValid: boolean, isComplete: boolean) => void;
  setHiddenAlert: (alert: string) => void;
  queryDrugs: (value: string) => void;
}

export const MemberSection: FC<MemberSectionProps> = ({
  policyholder,
  spouse,
  dependents,
  memberConfig,
  isDirty,
  drugResults,
  isRxLoading,
  isRxSkipped,
  planYear,
  handleEditPolicyholder,
  handleEditSpouse,
  handleEditDependent,
  handleDeleteSpouse,
  handleDeleteDependent,
  handleSectionChange,
  setHiddenAlert,
  queryDrugs,
  isProfileAudioEnabled,
  isIncentiveLoading,
}): JSX.Element => {
  const defaultIsOpen = {
    policyholder: !policyholder.pristine,
    spouse: !spouse.pristine,
    dependents: [...dependents.filter((i) => !i.pristine).map((i) => i.external_id)],
  };

  const [isOpen, setIsOpen] = useState(defaultIsOpen);
  // This currently exists to handle a specific age-validation related behavior (that's it).
  // Allows child householdMember components to display red error state for the member card.
  const [isError, setIsError] = useState(false);

  const { retrieveContentfulData } = useTextContext();
  const { getSectionFromMap } = useProfileContext();

  const profileCard = retrieveContentfulData<MemberCard>('profile_section.member');
  const householdTextNotes = profileCard.text_notes;

  useEffect(() => {
    const alertText = retrieveContentfulData<string>('profile_section.accessibility.unlocked_section', '');
    setHiddenAlert(alertText.replace(/{x}/, profileCard.name));
  }, []);

  // only called once now
  const handleOpen = (relationship, memberId) => {
    if (relationship === 'policyholder') {
      setIsOpen({
        ...isOpen,
        policyholder: true,
      });
    } else if (relationship === 'spouse') {
      setIsOpen({
        ...isOpen,
        spouse: true,
      });
    } else if (relationship === 'dependent') {
      setIsOpen({
        ...isOpen,
        dependents: [...isOpen.dependents, memberId],
      });
      // calling handle edit here will add an empty dependent to redux (to render the additional add dependent button)
      handleEditDependent(memberId, {});
    }
  };

  const handleRemoveMember = (relationship, memberId) => {
    if (relationship === 'spouse') {
      setIsOpen({
        ...isOpen,
        spouse: false,
      });
      handleDeleteSpouse();
      setHiddenAlert(profileCard.accessibility.remove_spouse_alert);
    } else if (relationship === 'dependent') {
      setIsOpen({
        ...isOpen,
        dependents: [...isOpen.dependents.filter((i) => i !== memberId)],
      });
      setHiddenAlert(profileCard.accessibility.remove_dependent_alert);
      // prevent inadvertently removing add dependent button or rendering multiple add dependent buttons
      const closedDependents = dependents.filter((i) => !isOpen.dependents.includes(i.external_id));
      if (closedDependents.length > 0) {
        handleDeleteDependent(memberId);
      }
    }
  };

  const validateForm = () => {
    const isPolicyholderValid = policyholder.isValid;

    const isSpouseValid = (spouse.isComplete && spouse.isValid) || !isOpen.spouse;

    const invalidDependents = dependents
      .map((i) => {
        if (!isOpen.dependents.includes(i.external_id)) {
          return null;
        }
        if (i.isComplete && i.isValid) {
          return null;
        }
        return i;
      })
      .filter((i) => i);

    const isDependentsValid = invalidDependents.length < 1;
    return isPolicyholderValid && isSpouseValid && isDependentsValid;
  };

  const getIsFormComplete = () => {
    const isPolicyholderComplete = policyholder.isComplete;
    const isSpouseComplete = (isOpen.spouse && spouse.isComplete) || !isOpen.spouse;
    const incompleteDependents = dependents
      .map((i) => {
        if (isOpen.dependents.includes(i.external_id) && !i.isComplete) {
          return i;
        }
        return null;
      })
      .filter((i) => i);
    const isDependentsComplete = incompleteDependents.length < 1;

    return isPolicyholderComplete && isSpouseComplete && isDependentsComplete;
  };

  useEffect(() => {
    handleSectionChange(validateForm(), getIsFormComplete());
  }, [JSON.stringify({ policyholder, spouse, dependents })]);

  const getCoverageMessage = () => {
    const validDependents = dependents.filter((i) => i.isValid);
    const invalidDependents = dependents.filter((i) => !i.isValid && i.isComplete);
    const incompleteDependents = dependents.filter(
      (i) => isOpen.dependents.includes(i.external_id) && !i.isComplete,
    );

    let coverageStatus;
    let coverageMessage;
    let coverageMember;

    // COMPLETE STATUS
    // --------------------------
    if (policyholder.isValid) {
      // only policyholder
      coverageStatus = 'complete';
      coverageMessage = 'myself';
      if (spouse.isValid) {
        // policyholder and spouse
        coverageMessage = 'meAndSpouseDomesticPartner';
        if (spouse.member_type === MEMBER_TYPE.SPOUSE) {
          coverageMessage = 'meAndSpouse';
        }
        if (spouse.member_type === MEMBER_TYPE.DOMESTIC_PARTNER) {
          coverageMessage = 'meAndDomesticPartner';
        }
        if (validDependents.length > 0) {
          // policyholder, spouse, dependents
          coverageMessage = 'meAndOthers';
          coverageMember = validDependents.length + 1;
        }
        // only policyholder and dependents
      } else if (validDependents.length > 0) {
        // policyholder and one dependent
        coverageMessage = 'meAndChild';
        coverageMember = validDependents.length;

        // policyholder and multiple dependents
        if (validDependents.length > 1) {
          coverageMessage = 'meAndOthers';
          coverageMember = validDependents.length;
        }
      }
    }

    // INCOMPLETE STATUS
    // --------------------------
    if (isOpen.policyholder && !policyholder.isComplete) {
      coverageStatus = 'warning';
      coverageMessage = 'incomplete';
      coverageMember = 'policyholder';
    }
    if (isOpen.spouse && !spouse.isComplete) {
      coverageStatus = 'warning';
      coverageMessage = 'incomplete';
      coverageMember = 'spouse';
    }
    if (incompleteDependents.length > 0) {
      coverageStatus = 'warning';
      coverageMessage = 'incomplete';
      coverageMember = 'dependent';
    }

    if (isError) {
      coverageStatus = 'error';
      coverageMessage = 'error';
    }
    // ERROR STATUS
    // --------------------------
    if (policyholder.isComplete && !policyholder.isValid) {
      coverageStatus = 'error';
      coverageMessage = 'error';
      coverageMember = 'policyholder';
    }
    if (spouse.isComplete && !spouse.isValid) {
      coverageStatus = 'error';
      coverageMessage = 'error';
      coverageMember = 'spouse';
    }
    if (invalidDependents.length > 0) {
      coverageStatus = 'error';
      coverageMessage = 'error';
      coverageMember = 'dependent';
    }

    return {
      status: coverageStatus,
      message: coverageMessage,
      member: coverageMember,
    };
  };

  const renderCoverageText = () => {
    const { status, message, member } = getCoverageMessage();

    let icon: IconTypes = 'CheckCircle';
    if (status === 'warning') icon = 'Warning';
    if (status === 'error') icon = 'XCircle';

    let color = '--primary-green';
    if (status === 'warning') color = '--primary-yellow';
    if (status === 'error') color = '--primary-red';

    return (
      status && (
        <CoverageMessage>
          <Icon type={icon} color={color} />
          <Paragraph size="small">
            <Text
              field={`profile_section.member.coverage_text.${message}` as TextField}
              vars={member && { x: member }}
            />
          </Paragraph>
        </CoverageMessage>
      )
    );
  };
  const isIncomplete = !getSectionFromMap('member')?.isComplete;

  const renderHouseholdTextNotes = () => {
    const matchingTextNotes: ContentfulTextNoteType[] = (householdTextNotes || []).filter(
      (n) => (n.plan_year || '').toString() === planYear?.toString(),
    );
    if (matchingTextNotes?.length) {
      return <ContentfulTextNote note={matchingTextNotes[0]} />;
    }

    // Fallback to legacy single Text Note
    const legacyTextNote = profileCard.text_note;
    if (legacyTextNote && legacyTextNote.short_text) {
      return <ContentfulTextNote note={legacyTextNote} />;
    }

    return null;
  };

  return (
    <div id="member-section" data-testid="member-section">
      <Question
        error={!validateForm() && isDirty}
        isIncomplete={isIncomplete}
        name="member"
        audioTooltipsEnabled={isProfileAudioEnabled}
        audioTooltipContentfulPath={AudioConfigTooltipContentfulPaths.HouseholdSectionAudioTooltip}
      >
        <Stack>
          <InfoBar text={<RichText field="profile_section.member.add_member_disclaimer" />} />
          <Disclaimer />
          {renderHouseholdTextNotes()}
        </Stack>
        <HouseholdMemberList
          policyholder={policyholder}
          spouse={spouse}
          dependents={dependents}
          memberConfig={memberConfig}
          isOpen={isOpen}
          drugResults={drugResults}
          isRxLoading={isRxLoading}
          isRxSkipped={isRxSkipped}
          isProfileAudioEnabled={isProfileAudioEnabled}
          handleEditPolicyholder={handleEditPolicyholder}
          handleEditSpouse={handleEditSpouse}
          handleEditDependent={handleEditDependent}
          handleRemoveMember={handleRemoveMember}
          queryDrugs={queryDrugs}
          setError={(val) => setIsError(val)}
        />

        <AddMemberButtons
          policyholder={policyholder}
          spouse={spouse}
          dependents={dependents}
          isOpen={isOpen}
          memberConfig={memberConfig}
          handleOpen={handleOpen}
          isIncentiveLoading={isIncentiveLoading}
        />

        {renderCoverageText()}
      </Question>
    </div>
  );
};

export default MemberSection;
