import { Box } from '@chakra-ui/react';
import React, { FC, useState } from 'react';

import ToggleList, { ToggleListOption } from 'DesignLibrary/atoms/ToggleList';
import { useViewport } from 'DesignLibrary/context';
import Text from 'Shared/Text';

export interface RangeToggleProps {
  label: string;
  groupName: string;
  startValue: string;
  rangeOptions: ToggleListOption[];
  handleChange: (value: number) => void;
}

export const RangeToggle: FC<RangeToggleProps> = ({
  label,
  groupName,
  startValue,
  rangeOptions,
  handleChange,
}): JSX.Element => {
  const [value, setValue] = useState<string>(startValue);

  const { device } = useViewport();

  return (
    <Box w={['100%', null, 360]} flexShrink={0}>
      <ToggleList
        options={rangeOptions}
        groupName={groupName}
        label={label}
        stretch={device !== 'mobile'}
        grid={device === 'mobile'}
        gridMobile={device === 'mobile'}
        layout="row"
        sizing="small"
        selected={value}
        handleChange={(e: React.ChangeEvent<HTMLInputElement>, newValue: string): void => {
          handleChange(parseInt(newValue, 10));
          setValue(newValue);
        }}
      />
    </Box>
  );
};

// Option[] values used by the utilization form
export const PcpVisitValues: ToggleListOption[] = [
  {
    option: <Text field="profile_section.member.pcp_visit_labels.zero_visits_label" />,
    value: '0',
  },
  {
    option: <Text field="profile_section.member.pcp_visit_labels.one_visit_label" />,
    value: '1',
  },
  {
    option: <Text field="profile_section.member.pcp_visit_labels.two_to_four_visits_label" />,
    value: '3',
  },
  {
    option: <Text field="profile_section.member.pcp_visit_labels.five_or_more_visits_label" />,
    value: '5',
  },
];

export const SpecVisitValues: ToggleListOption[] = [
  {
    option: <Text field="profile_section.member.specialist_visit_labels.zero_visits_label" />,
    value: '0',
  },
  {
    option: <Text field="profile_section.member.specialist_visit_labels.one_to_six_visits_label" />,
    value: '3',
  },
  {
    option: <Text field="profile_section.member.specialist_visit_labels.seven_to_twelve_visits_label" />,
    value: '9',
  },
  {
    option: <Text field="profile_section.member.specialist_visit_labels.thirteen_or_more_visits_label" />,
    value: '13',
  },
];

export const InpatientDayValues: ToggleListOption[] = [
  {
    option: <Text field="profile_section.member.inpatient_labels.zero_days_label" />,
    value: '0',
  },
  {
    option: <Text field="profile_section.member.inpatient_labels.one_to_two_days_label" />,
    value: '1',
  },
  {
    option: <Text field="profile_section.member.inpatient_labels.three_or_more_days_label" />,
    value: '3',
  },
];
