import { InsuranceType } from 'Containers/ViewBenefitPage/types';
import {
  DefaultCoveredMembers,
  HouseholdMember,
  SelectedSupplementalPlans,
  SupplementalPlan,
  SupplementalPlansFullHousehold,
} from 'Types/entities';
import { GetSupplementalPlansResponse, UpdateHouseholdResponse } from 'Utils/apiTypes';

import {
  SELECT_PLAN,
  REMOVE_PLAN,
  GET_SUPPLEMENTAL_PLANS_REQUEST,
  GET_SUPPLEMENTAL_PLANS_FOR_VIEW_BENEFIT_REQUEST,
  GET_SUPPLEMENTAL_PLANS_SUCCESS,
  GET_SUPPLEMENTAL_PLANS_FAILURE,
  GET_SUPPLEMENTAL_PLANS_SUCCESS_SET_CACHE,
  SET_DEFAULT_COVERED_MEMBERS,
  SET_DEFAULT_SELECTED_PLANS,
  TRACK_SUPP_ENROLLMENT_SUCCESS,
  TRACK_SUPP_ENROLLMENT_FAILURE,
  RESET_AUTO_ENROLLED,
  CLEAR_SUPPLEMENTAL_PLANS_CACHE,
} from './constants';

export function selectSupplementalPlan(
  insuranceType: InsuranceType,
  plan: SupplementalPlan,
  coveredMembers: DefaultCoveredMembers,
) {
  return {
    type: SELECT_PLAN,
    insuranceType,
    plan,
    coveredMembers,
  };
}

export function removeSelectedSupplementalPlan(insuranceType: InsuranceType) {
  return {
    type: REMOVE_PLAN,
    insuranceType,
  };
}

export function setDefaultCoveredMembers(
  members: HouseholdMember[],
  supplementalPlans: SupplementalPlansFullHousehold,
  client: string,
) {
  return {
    type: SET_DEFAULT_COVERED_MEMBERS,
    members,
    supplementalPlans,
    client,
  };
}

export function setDefaultSelectedPlans(
  supplementalPlans: SupplementalPlansFullHousehold | [],
  selectedPlans: SelectedSupplementalPlans | Record<string, never>,
) {
  return {
    type: SET_DEFAULT_SELECTED_PLANS,
    supplementalPlans,
    selectedPlans,
  };
}

export function clearSupplementalPlansCache() {
  return {
    type: CLEAR_SUPPLEMENTAL_PLANS_CACHE,
  };
}

export function getSupplementalPlans() {
  return {
    type: GET_SUPPLEMENTAL_PLANS_REQUEST,
  };
}

export function getSupplementalPlansForViewBenefitPage(memberIds: string[]) {
  return {
    type: GET_SUPPLEMENTAL_PLANS_FOR_VIEW_BENEFIT_REQUEST,
    memberIds,
  };
}

export function getSupplementalPlansSuccess(response: GetSupplementalPlansResponse) {
  return {
    type: GET_SUPPLEMENTAL_PLANS_SUCCESS,
    response,
  };
}

export function getSupplementalPlansFailure(error: Error) {
  return {
    type: GET_SUPPLEMENTAL_PLANS_FAILURE,
    error,
  };
}

export function getSupplementalPlansSuccessSetCache(
  response: GetSupplementalPlansResponse,
  coveredMemberIds: string[],
) {
  return {
    type: GET_SUPPLEMENTAL_PLANS_SUCCESS_SET_CACHE,
    response,
    coveredMemberIds,
  };
}

export function trackSuppEnrollmentSuccess(response: UpdateHouseholdResponse) {
  return {
    type: TRACK_SUPP_ENROLLMENT_SUCCESS,
    response,
  };
}

export function trackSuppEnrollmentFailure(error: Error) {
  return {
    type: TRACK_SUPP_ENROLLMENT_FAILURE,
    error,
  };
}

export function resetAutoEnrolled(
  insuranceType: InsuranceType,
  supplementalPlans: SupplementalPlansFullHousehold,
) {
  return {
    type: RESET_AUTO_ENROLLED,
    insuranceType,
    supplementalPlans,
  };
}
