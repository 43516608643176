import { Stack } from '@chakra-ui/react';
import React from 'react';

import { DCFSABenefits } from 'Containers/ViewBenefitPage/types';
import { transformBenefitItems } from 'Containers/ViewBenefitPage/utils';
import { useTextContext } from 'Contexts/textContext';
import { Paragraph } from 'DesignLibrary/atoms';
import Text from 'Shared/Text';
import { SupplementalPlan } from 'Types/entities';
import { formatDate, toDollars } from 'Utils/helpers';

export interface DCFSADescriptionProps {
  plan: SupplementalPlan;
}

export const DCFSADescription = (props: DCFSADescriptionProps) => {
  const { plan } = props;
  const { locale, retrieveContentfulData } = useTextContext();

  const benefitItems = transformBenefitItems(plan) as DCFSABenefits;

  const getLimitSection = (): JSX.Element | null => {
    const fedLimit = toDollars(retrieveContentfulData<number>('benefits_section_fsa.federal_limit'));

    let text: JSX.Element | null = null;
    if (benefitItems.uses_federal_limit) {
      text = <Text field="benefits_section_fsa.federal_limit_description" vars={{ fedLimit }} />;
    } else if (benefitItems.max_contribution) {
      text = (
        <Text
          field="benefits_section_fsa.custom_limit_description"
          vars={{ limit: toDollars(benefitItems.max_contribution) }}
        />
      );
    }
    return text ? <Paragraph>{text}</Paragraph> : null;
  };

  const getMinimumSection = (): JSX.Element | null => {
    if (benefitItems.has_min && benefitItems.min_contribution) {
      return (
        <Paragraph>
          <Text
            field="benefits_section_fsa.minimum_contribution"
            vars={{ min: toDollars(benefitItems.min_contribution) }}
          />
        </Paragraph>
      );
    }
    return null;
  };
  const getIncurSubmitSection = () => (
    <Paragraph>
      <Text
        field="benefits_section_fsa.incur_submit_info"
        vars={{
          incurDate: formatDate(benefitItems.incur_date, locale),
          submitDate: formatDate(benefitItems.submit_date, locale),
        }}
      />
    </Paragraph>
  );

  return (
    <Stack gap={3}>
      {getLimitSection()}
      {getMinimumSection()}
      {getIncurSubmitSection()}
    </Stack>
  );
};
