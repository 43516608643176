import { fromJS } from 'immutable';
import { isEmpty } from 'lodash';
import { createSelector } from 'reselect';

import { GlobalReducerState } from 'app/reducers';
import {
  COUPLE_TIER,
  FAMILY_TIER,
  INDIVIDUAL_DEPENDANT_TIER,
  INDIVIDUAL_TIER,
  LOW_POP_ZIP3,
} from 'Containers/App/constants';
import { makeSelectCommercialField } from 'Containers/App/selectors';
import { makeGetRecommendedHsaContribution } from 'Containers/CommercialRoutes/selectors';
import { formatMembersForForecastApi } from 'Containers/HsaPage/helpers';
import {
  makeGetCoverageTier,
  makeGetHouseholdMembers,
  makeSelectPolicyholder,
  makeSelectProfileField,
} from 'Containers/ProfilePage/selectors';
import { HouseholdMember, Recommendation } from 'Types/entities';

import { INVESTMENT_RETURN_PERCENT, RETIREMENT_AGE } from './constants';
import { mapCommApiTierToForecastingTier, formatEmployerContribution, formatPlanParameters } from './helpers';
import { TaxSavingsPageReducerState, TaxSavingsPageState } from './types';

const selectTaxSavingsDomain = (state: GlobalReducerState): TaxSavingsPageState =>
  fromJS(state).get('taxSavingsPage');

const selectTaxSavingsDomainAsJS = (state: GlobalReducerState): TaxSavingsPageReducerState =>
  state.taxSavingsPage;

const makeSelectTaxSavingsField = <K extends keyof TaxSavingsPageReducerState>(field: K) =>
  createSelector(
    selectTaxSavingsDomainAsJS,
    (substate: TaxSavingsPageReducerState): TaxSavingsPageReducerState[K] => substate[field],
  );

const makeSelectHsaData = () =>
  createSelector(selectTaxSavingsDomain, (substate) => substate.get('hsaData').toJS());

const makeGetForecastPayload = () =>
  createSelector(
    [
      makeGetHouseholdMembers(),
      makeSelectCommercialField('selectedHealthPlan'),
      makeSelectProfileField('zipcode'),
      makeSelectTaxSavingsField('currentHsaBalance'),
      makeSelectTaxSavingsField('persona'),
      makeSelectTaxSavingsField('retirementAge'),
      makeSelectTaxSavingsField('lockedHsaContribution'),
      makeGetRecommendedHsaContribution(),
      // this coverage tier needs to be constructed from the same members as
      // the selected health plan is recommended for
      makeGetCoverageTier(),
    ],
    (
      members,
      selectedPlan,
      zipcode,
      currentHsaBalance,
      persona,
      retirementAge,
      employeeHsaContribution,
      recommendedContribution,
      coverageTier,
    ) => {
      const zip3 = LOW_POP_ZIP3.includes(zipcode.substring(0, 3)) ? '000' : zipcode.substring(0, 3);
      const contribution = employeeHsaContribution || recommendedContribution;

      const currentYear = new Date().getFullYear();

      const policyholder = members.find((member) => member.policyholder) as HouseholdMember;
      const policyholderRetireStartYear = Math.max(
        retirementAge - policyholder.age + currentYear,
        currentYear,
      );

      return {
        household: {
          members: formatMembersForForecastApi(members),
          zip_code_3: zip3,
        },
        hsas: [
          {
            current_balance: currentHsaBalance || 0,
            employee_contribution: contribution,
            employer_contribution: formatEmployerContribution(selectedPlan as Recommendation),
            investment_return_percent: INVESTMENT_RETURN_PERCENT,
            minimum_desired_balance: persona === 'super_saver' ? 'inf' : 0,
          },
        ],
        plans: [
          {
            external_id: selectedPlan.plan.external_id,
            is_hsa_eligible: selectedPlan.plan.hsa_eligible,
            plan_parameters: {
              [mapCommApiTierToForecastingTier(coverageTier)]: formatPlanParameters(
                selectedPlan as Recommendation,
                coverageTier,
              ),
            },
            premiums: {
              [mapCommApiTierToForecastingTier(INDIVIDUAL_TIER)]: selectedPlan.costs.effective_premium,
              [mapCommApiTierToForecastingTier(COUPLE_TIER)]: selectedPlan.costs.effective_premium,
              [mapCommApiTierToForecastingTier(INDIVIDUAL_DEPENDANT_TIER)]:
                selectedPlan.costs.effective_premium,
              [mapCommApiTierToForecastingTier(FAMILY_TIER)]: selectedPlan.costs.effective_premium,
            },
          },
        ],
        scenario: {
          end_year: policyholderRetireStartYear,
          start_year: currentYear,
          starting_plans: {
            [`${selectedPlan.plan.external_id}`]: members.map((member) => member.external_id),
          },
        },
      };
    },
  );

const makeGetFsaTaxSavingsPayload = () =>
  createSelector(
    [makeSelectCommercialField('selectedHealthPlan'), makeSelectTaxSavingsField('lockedFsaContribution')],
    (selectedPlan, fsaContribution) => ({
      plans: [
        {
          external_id: selectedPlan.plan.external_id,
          fsa_contributions: {
            monthly_employee_contribution: (fsaContribution || 0) / 12,
            monthly_employer_contribution: 0,
          },
        },
      ],
    }),
  );

const makeGetAvgRetirementCost = () =>
  createSelector([makeSelectTaxSavingsField('retirementForecastData')], (data) => {
    if (data.length > 0) {
      const yearlyForecasts = data.map((resp) => resp.yearly_forecasts).flat();

      const avgRetirementCost = yearlyForecasts.reduce(
        (total, current) => current.total_cost.mean + total,
        0,
      );

      return avgRetirementCost;
    }

    return 0;
  });

const makeGetAvgForecastedHsaBalance = () =>
  createSelector(
    [makeSelectPolicyholder(), makeSelectTaxSavingsField('forecastData')],
    (policyholder, data) => {
      if (!isEmpty(data)) {
        const currentYear = new Date().getFullYear();
        const yearlyForecasts = data.yearly_forecasts;

        const retireStartYear = Math.max(
          RETIREMENT_AGE - parseInt(policyholder.age, 10) + currentYear,
          currentYear,
        );

        // Find forecast at retirement
        const forecastAtRetirement = yearlyForecasts.find((forecast) => forecast.year === retireStartYear);

        if (forecastAtRetirement) return forecastAtRetirement.hsa.balance.mean;
      }

      return 0;
    },
  );

export {
  selectTaxSavingsDomain,
  makeSelectTaxSavingsField,
  makeSelectHsaData,
  makeGetForecastPayload,
  makeGetAvgRetirementCost,
  makeGetAvgForecastedHsaBalance,
  makeGetFsaTaxSavingsPayload,
};
