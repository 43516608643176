import _ from 'lodash';
import React, { FC } from 'react';

import { TextField } from 'Containers/App/types';
import { Tooltip, TooltipContentProps } from 'DesignLibrary/atoms/Tooltip';
import { H6 } from 'DesignLibrary/atoms/typography';

export interface TooltipCapsProps {
  tooltip: TooltipContentProps;
  children: string | Text | React.ReactNode;
  keyHint: string;
  color?: string;
  audioTooltipsEnabled?: boolean;
  audioTooltipContentfulPath?: TextField;

  // All other props
  [x: string]: any;
}

export const TooltipCaps: FC<TooltipCapsProps> = ({
  color = '--text-gray-light',
  children,
  tooltip,
  keyHint,
  ...rest
}): JSX.Element => {
  // force to an appropriate format.
  const properId = `tooltip-${_.kebabCase(keyHint)}`;

  return (
    <Tooltip id={properId} data-testid={properId} label={tooltip}>
      <H6 color={color} underline {...rest}>
        {children}
      </H6>
    </Tooltip>
  );
};
