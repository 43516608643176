import { ContentfulTopLevelNavigationSection } from 'ContentfulDefaults/types/navigation';

export const navigation: {
  top_level_navigation: ContentfulTopLevelNavigationSection;
} = {
  top_level_navigation: {
    contentful_name: 'Top Level Navigation (DEFAULT DO NOT CHANGE)',
    desktop_pages: {
      hsa: 'HSA',
      taxSavings: 'Tax Savings',
      health: 'Health',
      review: 'Review',
      profile: 'Profile',
      eligibility: 'Eligibility',
      supplemental: 'Supplemental',
    },
    mobile_pages: {
      hsa: 'HSA',
      risk: 'Risk',
      review: 'Review',
      results: 'Your Results',
      planning: 'Family Planning',
      'you&family': 'You & Family',
      eligibility: 'Eligibility',
      houseIncome: 'Household Income',
      medications: 'Medications',
      utilization: 'Utilization',
      supplemental: 'Supplemental',
      capacityToPay: 'Capacity to Pay',
    },
    menu_icon: 'https://picwellapppublicdata.s3.amazonaws.com/contentful/navigationbar/menu-icon.svg',
    close_button: 'https://picwellapppublicdata.s3.amazonaws.com/contentful/navigationbar/close-btn.svg',
    security_header: {
      heading: 'Your security is our priority.',
      lockIcon: 'https://picwellapppublicdata.s3.amazonaws.com/contentful/navigationbar/security-lock.svg',
      badgeIcon: 'https://picwellapppublicdata.s3.amazonaws.com/contentful/navigationbar/security.svg',
    },
    security_text: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Picwell works hard to ensure the security of your data. We protect the\nprivacy of your information and will never share your data with any\nthird party without your permission. For more information, please\nreview our ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [{ type: 'bold' }],
              value: 'Privacy Policy',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value: '.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    premium_select: {
      weekly: 'Weekly (/wk)',
      monthly: 'Monthly (/mo)',
      biWeekly: 'Bi-Weekly (/bwk)',
      semiMonthly: 'Semi-monthly (/sm)',
    },
    premium_select_with_abbreviation: {
      weekly: {
        display: 'Weekly',
        abbreviation: '(/wk)',
      },
      monthly: {
        display: 'Monthly',
        abbreviation: '(/mo)',
      },
      biWeekly: {
        display: 'Bi-Weekly',
        abbreviation: '(/bwk)',
      },
      semiMonthly: {
        display: 'Semi-monthly',
        abbreviation: '(/sm)',
      },
    },
    pay_period_options: {
      weekly: {
        display: 'Weekly',
        abbreviation: '(/wk)',
      },
      monthly: {
        display: 'Monthly',
        abbreviation: '(/mo)',
      },
      bi_weekly: {
        display: 'Bi-Weekly',
        abbreviation: '(/bwk)',
      },
      semi_monthly: {
        display: 'Semi-monthly',
        abbreviation: '(/sm)',
      },
    },
    mobile_nav_instructions:
      'Navigate by clicking on any of the unlocked items. Update your profile at any time to update your health and supplemental results.',
    mobile_nav_close_btn: 'Close',
    pay_period_label: 'How often do you get paid?',
    language_toggle_label: 'Choose your preferred language',
    alex_id_login_btn: 'Log In',
    alex_id_signup_btn: 'Sign Up',
    alex_id_logout_btn: 'Log Out',
    alex_id_account_btn: 'Account',
    alex_id_reset_password_btn: 'Reset Password',
    alex_id_update_btn: 'Update my account',
    alex_id_delete_btn: 'Delete my account',
    alex_id_google: 'Signed in with Google',
    alex_id_help: 'In order to delete or change your account, email:',
    alex_id_reset_password_text: 'We just sent you an emaiil to reset your password.',
    alex_id_reset_password_error: 'Sorry there was an error. Please try again later.',
  },
};
