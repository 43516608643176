import { createGlobalStyle, css, ThemedCssFunction } from 'styled-components';

import { COLORS, SPACING, BORDER_RADIUS, FONT, BREAKPOINTS, SHADOW } from 'DesignLibrary/vars';

export interface MediaTypes {
  WIDEDESKTOP: number;
  DESKTOP: number;
  TABLET: number;
  MOBILE: number;
}

// Iterate through the sizes and create a media template
export const media = (Object.keys(BREAKPOINTS) as (keyof typeof BREAKPOINTS)[]).reduce(
  (acc, label) => {
    acc[label] = (first: any, ...interpolations: any[]) => css`
      @media (min-width: ${BREAKPOINTS[label]}px) {
        ${css(first, ...interpolations)}
      }
    `;
    return acc;
  },
  {} as { [key in keyof typeof BREAKPOINTS]: ThemedCssFunction<any> },
);

const customMediaQuery = (minWidth: number) => `@media (min-width: ${minWidth}px)`;

export const breakpoint = {
  WIDEDESKTOP: customMediaQuery(BREAKPOINTS.WIDEDESKTOP),
  DESKTOP: customMediaQuery(BREAKPOINTS.DESKTOP),
  TABLET: customMediaQuery(BREAKPOINTS.TABLET),
  MOBILE: customMediaQuery(BREAKPOINTS.MOBILE),
};

export const DesignSystemVariables = createGlobalStyle`
  html {

    /* COLORS */
    ${Object.entries(COLORS).map(([color, nested]) =>
      Object.entries(nested).map(([number, hex]) => `--colors-${color}-${number}: ${hex};`),
    )}

    --text-black: var(--colors-alex-ink);
    --text-gray: var(--colors-gray-600);
    --text-gray-light: var(--colors-gray-500);
    --text-gray-placeholder: var(--colors-gray-400);

    --primary-green: var(--colors-green-400);
    --primary-purple: var(--colors-purple-400);
    --primary-blue: var(--colors-blue-400);
    --primary-yellow: var(--colors-yellow-400);
    --primary-red: var(--colors-red-400);
    --primary-white: var(--colors-gray-white);
    --primary-black: var(--colors-alex-ink);

    --background-white: var(--colors-gray-white);
    --background-gray-light: var(--colors-gray-50);
    --background-gray: var(--colors-gray-100);
    --border-gray: var(--colors-gray-200);
    --alex-border-gray: var(--colors-gray-300);
    --input-gray: var(--colors-gray-300);

    --button-background-normal: var(--colors-opaque-5);
    --button-background-hover: var(--colors-opaque-10);

    /* SIZING or SPACING */
    --sizing-2xs: ${SPACING['2xs']}; /* 4px */
    --sizing-xs: ${SPACING.xs}; /* 8px */
    --sizing-sm: ${SPACING.sm}; /* 16px */
    --sizing-md: ${SPACING.md}; /* 24px */
    --sizing-lg: ${SPACING.lg}; /* 32px */
    --sizing-xl: ${SPACING.xl}; /* 48px */
    --sizing-xxl: ${SPACING.xxl}; /* 64px */
    --sizing-xxxl: ${SPACING.xxxl}; /* 128px */
    --sizing-xxxxl: ${SPACING.xxxxl}; /* 256px */

    --sizing-2xl: ${SPACING.xxl}; /* 64px */
    --sizing-3xl: ${SPACING.xxxl}; /* 128px */
    --sizing-4xl: ${SPACING.xxxxl}; /* 256px */

    /* BORDER_RADIUS */
    --border-radius-xs: ${BORDER_RADIUS.xs}; /* 8px */
    --border-radius-sm: ${BORDER_RADIUS.sm}; /* 16px */
    --border-radius-lg: ${BORDER_RADIUS.lg}; /* 24px */

    /* FONTS */
    --font-header: ${FONT.header};
    --font-primary: ${FONT.primary};

    /* BORDERS */
    --border: 1px solid var(--border-gray);
    --border-thick: 2px solid var(--border-gray);
    --input-border: 2px solid var(--input-gray);
    --test: 1px solid red;

    /* DROPSHADOW */
    --shadow-card: ${SHADOW.card};
    --shadow-badge: ${SHADOW.badge};
    --shadow-large: ${SHADOW.large};
    --input-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1) inset;
    --shadow-selected-green: ${SHADOW.selectedGreen};
    --shadow-selected-blue: ${SHADOW.selectedBlue};
    --shadow-selected-purple: ${SHADOW.selectedPurple};
    --shadow-selected-red: ${SHADOW.selectedRed};
  }

  html,
  body {
    font-size: 16px;
    font-family: ${FONT.primary} !important;
    color: var(--text-black);
    text-rendering: optimizeLegibility;
    background: var(--background-gray);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    background: var(--background-gray) !important;
  }

  html:not(.cypress-tests) {
    scroll-behavior: smooth;
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
  }

  input,
  select,
  textarea,
  button {
    font-family: inherit;
    font-size: inherit;
    cursor: pointer;

    &:disabled {
      cursor: default;
    }
  }

  ul {
    margin: 0;
  }

  svg {
    display: inline-block;
  }

  .paragraph,
  .numbers,
  p {
    margin: 0;
  }

  fieldset {
    border: 0;
  }

  .profile-audio {
    margin: 0 auto;
  }

  .profile-audio video {
    margin-top: -0.5rem;
  }

  @supports (-moz-appearance:none) {
    .profile-audio video::cue {
      color: white;
      outline: gray;
      outline-style: solid;
      outline-width: 1rem;
      white-space: nowrap;
      background-color: grey;
    }

    .profile-audio video {
      margin-top: -1.5rem;
    }
  }

`;
