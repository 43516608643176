import React from 'react';

import { useFeatureFlagContext } from 'Contexts/featureFlagContext';
import { usePremiumContext } from 'Contexts/premiumContext';
import { useTextContext } from 'Contexts/textContext';
import { NumberWithPayPeriod, Paragraph, Tooltip } from 'DesignLibrary/atoms';
import { PicwellScore } from 'DesignLibrary/atoms/PicwellScore';
import { PremiumPerDeduction } from 'DesignLibrary/atoms/PremiumPerDeduction';
import Plan from 'Models/plan';
import { PlanFit } from 'Shared/PlanFit';
import RichText from 'Shared/RichText';
import { SupplementalIcon } from 'Shared/SupplementalIcon';
import Text from 'Shared/Text';
import { createHraTooltip, createHsaTooltip, createSihraTooltip } from 'Utils/createTooltips';

import { EligibilitySticker, EmployerContribution } from './styled';
import { CompareTableRow } from '../_shared/CompareTableRow';

export interface MainDetailsProps {
  plans: Plan[];
  hsaContributionLimit: number;
  maximumAvailableAnnualSihraContribution: number;
  killMedRec: boolean;
  isImprovedRecExplanationEnabled: boolean;
}

export const MainDetails = ({
  plans,
  hsaContributionLimit,
  maximumAvailableAnnualSihraContribution,
  killMedRec,
  isImprovedRecExplanationEnabled,
}: MainDetailsProps) => {
  const { retrieveContentfulData } = useTextContext();
  const { payPeriodText } = usePremiumContext();

  const firstPlan = plans[0];
  const secondPlan = plans[1];
  const thirdPlan = plans[2] || null;

  const score = retrieveContentfulData<string>(
    'health_section.compare_plan_text.compareModal.terms.score.title',
  );
  const accountEligibility = retrieveContentfulData<string>('tool_tips.account_eligibility.title');
  const estimatedYearlyTitle = retrieveContentfulData<string>(
    'health_section.compare_plan_text.compareModal.terms.estYearlyTotal.title',
  );
  const premiumTitle = <Text field="health_section.compare_plan_text.compareModal.terms.premium.title" />;
  const oopTitle = retrieveContentfulData<string>(
    'health_section.compare_plan_text.compareModal.terms.oopEstimate.title',
  );

  // Line Item Title Tooltips
  const scoreTooltip = retrieveContentfulData<string>(
    'health_section.compare_plan_text.compareModal.terms.score.term',
  );
  const accountEligibilityTooltip = retrieveContentfulData<string>('tool_tips.account_eligibility.text');

  const estimatedYearlyTooltip = (
    <>
      <Text field="tool_tips.estimated_yearly_total.text.p1" vars={{ x: payPeriodText }} />
      <Text field="tool_tips.estimated_yearly_total.text.p2" />
    </>
  );

  const premiumTooltip = <Text field="tool_tips.premium.text" vars={{ x: payPeriodText }} />;

  const oopTooltip = <Text field="tool_tips.out_of_pocket_estimate.text" />;

  // ACCOUNT ELIGIBILITY TAGS
  const renderHsaTag = (plan: Plan) => {
    if (plan.hsaEligible) {
      const employerHsaContribution = plan.calcEmployerHsaContribution(hsaContributionLimit) || 0;

      return (
        <>
          <EligibilitySticker className="hsa-eligible-sticker">
            <SupplementalIcon type="hsa" size="small" />
            <Paragraph size="small" weight="bold" color="--text-black">
              <Text field="health_section.eligibility_text.hsa.tag" />
            </Paragraph>
          </EligibilitySticker>
          {employerHsaContribution > 0 && (
            <EmployerContribution>
              <Paragraph data-testid="employer-hsa-contribution-text" color="--text-black" size="mini">
                <RichText
                  toolTips={[
                    createHsaTooltip(
                      plan.isEmployerMatching,
                      plan.calcEmployerHsaContribution(hsaContributionLimit),
                      plan.employerHsaContributionLimit,
                      plan.employerHsaPercentageMatch,
                    ),
                  ]}
                  field="health_section.hsa_hra_employer_contribution"
                />
              </Paragraph>
            </EmployerContribution>
          )}
        </>
      );
    }
    return null;
  };

  const renderHraTag = (plan: Plan) => {
    if (plan.hraEligible) {
      const employerHraContribution = plan.employerHraContribution || 0;
      return (
        <>
          <EligibilitySticker className="hsa-eligible-sticker">
            <SupplementalIcon type="hra" size="small" />
            <Paragraph size="small" weight="bold" color="--text-black">
              <Text field="health_section.eligibility_text.hra.tag" />
            </Paragraph>
          </EligibilitySticker>
          {employerHraContribution > 0 && (
            <EmployerContribution>
              <Paragraph
                color="--text-black"
                size="mini"
                data-testid="employer-hra-contribution-text"
                className="employer-hra-contribution"
              >
                <RichText
                  toolTips={[createHraTooltip(plan.employerHraContribution)]}
                  field="health_section.hsa_hra_employer_contribution"
                />
              </Paragraph>
            </EmployerContribution>
          )}
        </>
      );
    }
    return null;
  };

  const renderSihraTag = (plan: Plan) => {
    const employerSihraContribution = plan.annualSihraDollarsUsed || 0;
    if (employerSihraContribution) {
      return (
        <>
          <EligibilitySticker className="hsa-eligible-sticker">
            <SupplementalIcon type="hra" size="small" />
            <Paragraph size="small" weight="bold" color="--text-black">
              <Text field="health_section.eligibility_text.sihra.tag" />
            </Paragraph>
          </EligibilitySticker>
          <EmployerContribution>
            <Paragraph color="--text-black" size="mini" className="employer-sihra-contribution">
              <RichText
                toolTips={[createSihraTooltip(maximumAvailableAnnualSihraContribution)]}
                field="health_section.hsa_hra_employer_contribution"
              />
            </Paragraph>
          </EmployerContribution>
        </>
      );
    }
    return null;
  };

  const renderEligibilityStatus = (plan: Plan) => {
    const render = renderHsaTag(plan) || renderHraTag(plan) || renderSihraTag(plan);

    if (!render) {
      return null;
    }

    return (
      <>
        {renderHsaTag(plan)}
        {renderHraTag(plan)}
        {renderSihraTag(plan)}
      </>
    );
  };

  const renderPicwellScore = (plan: Plan) => {
    if (isImprovedRecExplanationEnabled) {
      return <PlanFit variant="tag" score={plan.picwellScore} />;
    }

    return <PicwellScore size="small" tier={plan.tier} score={plan.picwellScore} />;
  };

  // Remove when retiring feature flag APP-1028-oop-estimate-uses-comm-api
  const { is_real_cost_before_tax_benefit_enabled } = useFeatureFlagContext();

  const renderYearlyExpenseTotal = (plan: Plan) => {
    const estimatedYearlyTotal = is_real_cost_before_tax_benefit_enabled
      ? plan.realCostBeforeTaxBenefit
      : plan.calcEstimatedYearlyTotal(hsaContributionLimit);

    return (
      <NumberWithPayPeriod
        keyHint={`plan-${plan.planId}-yearly-total-value`}
        payPeriodType="yearly"
        value={estimatedYearlyTotal}
      />
    );
  };

  const renderPremium = (plan: Plan) => (
    <PremiumPerDeduction
      annualPremium={plan.annualPremium}
      deductions={plan.annualPremiumDeductions}
      currency
      precision={2}
      keyHint={plan.planId}
    />
  );

  const renderOopEstimate = (plan: Plan) => {
    const calculatedOutOfPocket = plan.calcOutOfPocket(hsaContributionLimit);

    const oopMax = plan.inNetworkOopMax || (plan.inNetworkMedOopMax || 0) + (plan.inNetworkDrugOopMax || 0);

    const isEstimateGreaterThanMax = calculatedOutOfPocket > oopMax;

    return (
      <>
        <NumberWithPayPeriod
          value={calculatedOutOfPocket}
          payPeriodType="yearly"
          keyHint={`plan-${plan.planId}-oop-est-value`}
        />
        {isEstimateGreaterThanMax && (
          <Tooltip
            label={{
              title: <Text field="tool_tips.yearly_estimate_greater_than_out_of_pocket_limit.title" />,
              text: <Text field="tool_tips.yearly_estimate_greater_than_out_of_pocket_limit.text" />,
            }}
          />
        )}
      </>
    );
  };

  return (
    <>
      {/* PLAN SCORE */}
      {!killMedRec && (
        <CompareTableRow
          rowHeader={score}
          rowHeaderTooltip={{ text: scoreTooltip }}
          columnOne={renderPicwellScore(firstPlan)}
          columnTwo={renderPicwellScore(secondPlan)}
          columnThree={thirdPlan && renderPicwellScore(thirdPlan)}
          keyHint="plan-score"
        />
      )}

      {/* ACCOUNT ELIGIBILITY */}
      <CompareTableRow
        rowHeader={accountEligibility}
        rowHeaderTooltip={{ text: accountEligibilityTooltip }}
        columnOne={renderEligibilityStatus(firstPlan)}
        columnTwo={renderEligibilityStatus(secondPlan)}
        columnThree={thirdPlan && renderEligibilityStatus(thirdPlan)}
        size="medium"
        keyHint="account-eligibility"
      />

      {/* YEARLY EXPENSE TOTAL */}
      <CompareTableRow
        rowHeader={estimatedYearlyTitle}
        rowHeaderTooltip={{ text: estimatedYearlyTooltip }}
        columnOne={renderYearlyExpenseTotal(firstPlan)}
        columnTwo={renderYearlyExpenseTotal(secondPlan)}
        columnThree={thirdPlan && renderYearlyExpenseTotal(thirdPlan)}
        keyHint="yearly-expense-total"
      />

      {/* PREMIUM */}
      <CompareTableRow
        rowHeader={premiumTitle}
        rowHeaderTooltip={{ text: premiumTooltip }}
        columnOne={renderPremium(firstPlan)}
        columnTwo={renderPremium(secondPlan)}
        columnThree={thirdPlan && renderPremium(thirdPlan)}
        keyHint="premium"
      />

      {/* OOP ESTIMATE */}
      <CompareTableRow
        rowHeader={oopTitle}
        rowHeaderTooltip={{ text: oopTooltip }}
        columnOne={renderOopEstimate(firstPlan)}
        columnTwo={renderOopEstimate(secondPlan)}
        columnThree={thirdPlan && renderOopEstimate(thirdPlan)}
        keyHint="oop-estimate"
      />
    </>
  );
};
