import React, { FC } from 'react';
import CountUp from 'react-countup';

import { useTextContext } from 'Contexts/textContext';
import { Tooltip } from 'DesignLibrary/atoms/Tooltip';
import { Tier } from 'Types/entities';

import { ScoreContainer } from './styled';
import { Icon } from '../Icon';

export interface PicwellScoreProps {
  score?: number;
  size?: 'small' | 'medium' | 'large';
  tier?: Tier;
  animate?: boolean;
  showTooltip?: boolean;
  attached?: boolean;
  waived?: boolean;
  dataTestId?: string;
}

export const PicwellScore: FC<PicwellScoreProps> = ({
  score = 0,
  size = 'large',
  tier = 'red',
  animate = true,
  showTooltip = true,
  attached = false,
  waived = false,
  dataTestId = undefined,
}) => {
  const { retrieveContentfulData } = useTextContext();

  // Animation Controls
  const delay = animate ? 1 : 0;
  const duration = animate ? 1.5 : 0;
  const start = animate ? 0 : score;

  // Background Color
  let bgColor = tier as 'red' | 'yellow' | 'green' | 'purple';
  if (waived) bgColor = 'red';

  // Show Tooltip Logic
  const tooltipVisible = !waived && size !== 'small' && showTooltip;

  // Create Tooltip
  const tooltipTitle = retrieveContentfulData<string>(`health_section.plan_tips.colorTiers.${tier}Title`);
  const tooltipText = retrieveContentfulData<string>(`health_section.plan_tips.colorTiers.${tier}Text`);

  const tooltip = {
    title: tooltipTitle,
    text: tooltipText,
  };

  // Count Render
  const renderCount = () => (
    <CountUp delay={delay} duration={duration} start={start} useEasing end={score} className="count-number" />
  );

  const renderContent = () => {
    if (waived) {
      return <Icon type="X" size={size !== 'small' ? 'large' : 'normal'} color="--primary-white" />;
    }
    return renderCount();
  };

  return (
    <ScoreContainer
      data-testid={dataTestId}
      showTooltip={tooltipVisible}
      color={bgColor}
      size={size}
      attached={attached}
    >
      {tooltipVisible ? (
        <Tooltip id={`${score}-score-tooltip`} label={tooltip}>
          {renderCount()}
        </Tooltip>
      ) : (
        renderContent()
      )}
    </ScoreContainer>
  );
};
