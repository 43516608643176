import _ from 'lodash';
import React from 'react';

import { SERVICE_TYPES, DRUG_TYPES } from 'Containers/ResultPage/constants';
import { useTextContext } from 'Contexts/textContext';
import Plan from 'Models/plan';

import CopayInfo from './CopayInfo';
import { CompareTable } from '../_shared/CompareTable';
import { CompareTableRow } from '../_shared/CompareTableRow';

export interface CopaysProps {
  plans: Plan[];
}

export const Copays = ({ plans }: CopaysProps) => {
  const { retrieveContentfulData } = useTextContext();

  const firstPlan = plans[0];
  const secondPlan = plans[1];
  const thirdPlan = plans[2] || null;

  const header = retrieveContentfulData<string>(
    'health_section.compare_plan_text.compareModal.headers.copays',
  );

  const shouldRenderRow = (isDrug: boolean, plans: Plan[], copayType: DRUG_TYPES | SERVICE_TYPES) => {
    if (isDrug) {
      const somePlanIsImputed = plans.some((plan) => plan[copayType as DRUG_TYPES].imputed);

      // avoid "null% coinsurance"
      const allPlansNoCopayAndNoCoinsurance = plans.every(
        (plan) => plan[copayType].copay === null && plan[copayType].coinsurance === null,
      );

      // do NOT render row if any imputed plans or they all have no copay/coinsurance
      if (somePlanIsImputed || allPlansNoCopayAndNoCoinsurance) {
        return false;
      }
    }

    return true;
  };

  const renderCopayFields = (isDrug: boolean) => {
    const copayTypes = isDrug ? DRUG_TYPES : SERVICE_TYPES;

    return Object.values(copayTypes).map((copayType: DRUG_TYPES | SERVICE_TYPES, index) => {
      const copayTitle = retrieveContentfulData<string>(
        `health_section.compare_plan_text.compareModal.terms.${copayType}.title`,
      );
      const copayTooltip = retrieveContentfulData<string>(`tool_tips.${_.snakeCase(copayType)}_copay.text`);

      const renderFirstPlanInfo = () => (
        <CopayInfo
          plan={firstPlan}
          copayType={copayType}
          perVisit={!isDrug}
          key={`copay-first-plan-${index}`}
        />
      );
      const renderSecondPlanInfo = () => (
        <CopayInfo
          plan={secondPlan}
          copayType={copayType}
          perVisit={!isDrug}
          key={`copay-second-plan-${index}`}
        />
      );
      const renderThirdPlanInfo = () =>
        thirdPlan ? (
          <CopayInfo
            plan={thirdPlan}
            copayType={copayType}
            perVisit={!isDrug}
            key={`copay-third-plan-${index}`}
          />
        ) : null;

      if (shouldRenderRow(isDrug, plans, copayType)) {
        return (
          <CompareTableRow
            rowHeader={copayTitle}
            rowHeaderTooltip={copayTooltip}
            columnOne={renderFirstPlanInfo()}
            columnTwo={renderSecondPlanInfo()}
            columnThree={renderThirdPlanInfo()}
            key={`compare-table-${index}`}
            keyHint={`compare-table-${index}`}
          />
        );
      }

      return null;
    });
  };

  return (
    <CompareTable tableHeader={header}>
      {renderCopayFields(false)}
      {renderCopayFields(true)}
    </CompareTable>
  );
};
