import React from 'react';

import { useTextContext } from 'Contexts/textContext';
import Text from 'Shared/Text';

export const getIncomeString = (household) => `$${Number(household.income).toLocaleString()}`;

export const getSpouseString = (household) => {
  const hasSpouse = household.members.find(
    (member) => member.policyholder === false && member.dependant === false,
  );

  return hasSpouse ? (
    <Text field="people_like_you.profile_answers.spouse" />
  ) : (
    <Text field="people_like_you.profile_answers.noSpouse" />
  );
};

export const getDependantString = (household) => {
  const dependentCount = household.members.filter((member) => member.dependant === true).length;

  return dependentCount === 1 ? (
    <Text field="people_like_you.profile_answers.dependent.one" />
  ) : (
    <Text field="people_like_you.profile_answers.dependent.many" vars={{ x: dependentCount }} />
  );
};

export const surveyAnswerById = (survey, questionId) => {
  const question = survey.find((q) => q.question_id === questionId);
  return question ? question.answer : null;
};

export const getPrescriptionsString = (household) => {
  const prescriptions = household.members[0].prescriptions ? household.members[0].prescriptions.length : 0;
  return prescriptions === 1 ? (
    <Text field="people_like_you.profile_answers.prescriptions.one" />
  ) : (
    <Text field="people_like_you.profile_answers.prescriptions.many" vars={{ x: prescriptions }} />
  );
};

// Returns sum of Household PCP Visits
export const getPcpVisitsFromHousehold = (household) =>
  household.members.reduce((visits, member) => visits + member.utilization.pcp_visits, 0);

// Returns sum of Household Specialist Visits
export const getSpecialistVisitsFromHousehold = (household) =>
  household.members.reduce((visits, member) => visits + member.utilization.specialist_visits, 0);

// Returns sum of Household Inpatient Days
export const getInpatientDaysFromHousehold = (household) =>
  household.members.reduce((visits, member) => visits + member.utilization.inpatient_days, 0);

// Returns sum of Household Mental Health Provider Visits
export const getMentalHealthVisitsFromHousehold = (household) =>
  household.members.reduce((visits, member) => visits + member.utilization.mental_health_visits, 0);

export const getPCPVisitsString = (household) => {
  const pcpVisists = getPcpVisitsFromHousehold(household);
  return pcpVisists === 1 ? (
    <Text field="people_like_you.profile_answers.pcpVisits.one" />
  ) : (
    <Text field="people_like_you.profile_answers.pcpVisits.many" vars={{ x: pcpVisists }} />
  );
};

export const getSpecialistVisitString = (household) => {
  const specialistVisits = getSpecialistVisitsFromHousehold(household);

  return specialistVisits === 1 ? (
    <Text field="people_like_you.profile_answers.specialistVisits.one" />
  ) : (
    <Text field="people_like_you.profile_answers.specialistVisits.many" vars={{ x: specialistVisits }} />
  );
};

export const getInpatientDaysString = (household) => {
  const inpatientDays = getInpatientDaysFromHousehold(household);

  return inpatientDays === 1 ? (
    <Text field="people_like_you.profile_answers.inpatientDays.one" />
  ) : (
    <Text field="people_like_you.profile_answers.inpatientDays.many" vars={{ x: inpatientDays }} />
  );
};

export const getMentalHealthVisitsString = (household) => {
  const mentalHealthVisits = getMentalHealthVisitsFromHousehold(household);
  return mentalHealthVisits === 1 ? (
    <Text field="people_like_you.profile_answers.mentalHealthVisits.one" />
  ) : (
    <Text field="people_like_you.profile_answers.mentalHealthVisits.many" vars={{ x: mentalHealthVisits }} />
  );
};

export const getCapacityToPayString = (household) => {
  const capacityToPay = surveyAnswerById(household.survey, '9');
  const { retrieveContentfulData } = useTextContext();
  const capacityToPayText = retrieveContentfulData<string>('people_like_you.profile_answers.capacityToPay');

  return capacityToPayText?.[capacityToPay];
};
