import React, { useState } from 'react';

import BcLogo from 'Assets/landing-page/BC.svg';
import GoLogo from 'Assets/landing-page/GO.svg';
import { AlexProducts } from 'Containers/App/types';
import { useTextContext } from 'Contexts/textContext';
import { Button, Icon, IconTypes, Paragraph } from 'DesignLibrary/atoms';
import Text from 'Shared/Text';
import { INTRO_PATH, PROFILE_PATH } from 'Utils/urls';

import { BulletPoint } from './BulletPoint';
import { ContinueContainer, ProductSelectContainer, SpanishDisclaimer, ToggleContainer } from './styled';
import { ToggleItem } from './ToggleItem';

export interface ProductSelectToggleProps {
  handleNext: (path: string) => void;
  chooseProduct: (product: AlexProducts) => void;
}

export const ProductSelectToggle = ({ handleNext, chooseProduct }: ProductSelectToggleProps) => {
  const [selectedProduct, setSelectedProduct] = useState<AlexProducts | null>(null);
  const { locale, retrieveContentfulData, setLocale } = useTextContext();

  const spanish = locale === 'es-US';

  const handleContinue = () => {
    chooseProduct(selectedProduct as AlexProducts);
    if (selectedProduct === 'go') {
      handleNext(PROFILE_PATH);
    }
    if (selectedProduct === 'bc') {
      if (spanish) {
        setLocale('en-US');
      }
      handleNext(INTRO_PATH);
    }
  };

  const renderBullets = (product: 'bc' | 'go') => {
    let bullets: string[];
    let icons: IconTypes[];

    if (product === 'bc') {
      bullets = retrieveContentfulData<string[]>(
        'unified_landing_page.choose_experience_modal.option1_bullets',
        [],
      );
      icons = ['SpeakerHigh', 'ListChecks', 'ClosedCaptioning'];
    } else {
      bullets = retrieveContentfulData<string[]>(
        'unified_landing_page.choose_experience_modal.option2_bullets',
        [],
      );
      icons = ['FileText', 'Lightning', 'Scales'];
    }

    return bullets.map((i: string, idx: number) => (
      <BulletPoint id={`bullets-${product}-${idx}`} icon={icons[idx]} text={i} key={idx} />
    ));
  };

  const getAriaLabelledByFromBullets = (bullets) => (bullets.length > 0 ? bullets[0].props.id : '');
  const getAriaDescribedByFromBullets = (bullets) =>
    bullets.length > 0
      ? bullets
          .slice(1)
          .map((e) => e.props.id)
          .join(' ')
      : '';

  const bc_bullets = renderBullets('bc');
  const bc_bullets_labelledBy = getAriaLabelledByFromBullets(bc_bullets);
  const bc_bullets_describedBy = getAriaDescribedByFromBullets(bc_bullets);

  const go_bullets = renderBullets('go');
  const go_bullets_labelledBy = getAriaLabelledByFromBullets(go_bullets);
  const go_bullets_describedBy = getAriaDescribedByFromBullets(go_bullets);

  return (
    <ProductSelectContainer>
      <ToggleContainer>
        <ToggleItem
          product="bc"
          selectedProduct={selectedProduct}
          setSelectedProduct={setSelectedProduct}
          image={BcLogo}
          ariaLabelledBy={bc_bullets_labelledBy}
          ariaDescribedBy={bc_bullets_describedBy}
        >
          {bc_bullets}
          {spanish && (
            <SpanishDisclaimer>
              <Icon type="Warning" color="--primary-red" />
              <Paragraph size="mini" weight="bold" color="--primary-red">
                <Text field="unified_landing_page.choose_experience_modal.spanish_support_disclaimer" />
              </Paragraph>
            </SpanishDisclaimer>
          )}
        </ToggleItem>

        <ToggleItem
          product="go"
          selectedProduct={selectedProduct}
          setSelectedProduct={setSelectedProduct}
          image={GoLogo}
          ariaLabelledBy={go_bullets_labelledBy}
          ariaDescribedBy={go_bullets_describedBy}
        >
          {go_bullets}
        </ToggleItem>
      </ToggleContainer>

      <ContinueContainer>
        <div className="product-select-continue-button">
          <Button buttonType="primary" isDisabled={!selectedProduct} onClick={handleContinue} stretch>
            <Text field="unified_landing_page.choose_experience_modal.button" />
          </Button>
        </div>
      </ContinueContainer>
    </ProductSelectContainer>
  );
};
