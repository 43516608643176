import styled from 'styled-components';

import { media } from 'DesignLibrary/global';

export const ProfileContainer = styled.div`
  display: flex;
  gap: var(--sizing-2xs);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  background: var(--primary-white);
  padding-left: var(--sizing-xs);

  ${media.TABLET`
    position: static;
    top: auto;
    left: auto;
    height: auto;
  `}
`;
