import { fromJS } from 'immutable';

import { GET_RECOMMENDATIONS_SUCCESS, SELECT_HEALTH_PLAN } from 'Containers/CommercialRoutes/constants';
import { GetForecastsResponse, GetHsaRecommendationResponse } from 'Utils/apiTypes';
import { wrapImmutableReducerInPojoTranslation } from 'Utils/reducers';

import {
  FORECAST_HSA_FAILURE,
  FORECAST_HSA_SUCCESS,
  GET_HSA_RECOMMENDATION_FAILURE,
  GET_HSA_RECOMMENDATION_REQUEST,
  GET_HSA_RECOMMENDATION_SUCCESS,
  GET_RETIREMENT_FORECASTS,
  GET_RETIREMENT_FORECASTS_FAILURE,
  GET_RETIREMENT_FORECASTS_SUCCESS,
  LOCK_HSA_CONTRIBUTION,
  SET_HSA_BALANCE_AND_PERSONA,
} from './constants';
import { HsaPageState, Persona } from './types';

const initialState: HsaPageState = fromJS({
  hsaData: {},
  forecastData: {},
  retirementForecastData: [],
  isHsaRecommendationLoading: true, // TODO: we can set this to false when a loading state is created for HSA page
  isForecastLoading: false,
  isRetirementForecastLoading: false,
  error: null,
  forecastError: null,
  retirementError: null,
  // insights and the review pag event are falling back to the recommended
  // contribution if null to render the "final" contribution,
  lockedHsaContribution: null,
  longtermHsaContribution: 0,
  currentHsaBalance: null,
  persona: 'spender',
});

function hsaPageReducer(state = initialState, action) {
  switch (action.type) {
    case GET_HSA_RECOMMENDATION_REQUEST:
      return getHsaData(state);
    case GET_HSA_RECOMMENDATION_SUCCESS:
      return getHsaDataSuccess(state, action);
    case GET_HSA_RECOMMENDATION_FAILURE:
      return getHsaDataFailure(state, action);
    case FORECAST_HSA_SUCCESS:
      return getForecastSuccess(state, action);
    case FORECAST_HSA_FAILURE:
      return getForecastFailure(state, action);
    case GET_RETIREMENT_FORECASTS:
      return getRetirementForecast(state);
    case GET_RETIREMENT_FORECASTS_SUCCESS:
      return getRetirementForecastSuccess(state, action);
    case GET_RETIREMENT_FORECASTS_FAILURE:
      return getRetirementForecastFailure(state, action);
    case LOCK_HSA_CONTRIBUTION:
      return setSavedHsaContribution(state, action);
    case SET_HSA_BALANCE_AND_PERSONA: {
      return setHsaBalanceAndPersona(state, action);
    }
    case GET_RECOMMENDATIONS_SUCCESS:
      return resetHsaData(state);
    case SELECT_HEALTH_PLAN:
      return resetContributionAmounts(state);
    default:
      return state;
  }
}

function resetHsaData(state: HsaPageState) {
  return state
    .set('isHsaRecommendationLoading', true)
    .set('lockedHsaContribution', null)
    .set('hsaData', fromJS({}))
    .set('isForecastLoading', false)
    .set('isRetirementForecastLoading', false)
    .set('forecastData', fromJS({}))
    .set('retirementForecastData', fromJS([]));
}

function getHsaData(state: HsaPageState) {
  return state.set('isHsaRecommendationLoading', true);
}

function getHsaDataSuccess(
  state: HsaPageState,
  action: {
    response: GetHsaRecommendationResponse;
  },
) {
  return state.set('isHsaRecommendationLoading', false).set('hsaData', fromJS(action.response));
}

function getHsaDataFailure(
  state: HsaPageState,
  action: {
    error: Error;
  },
) {
  return state.set('isHsaRecommendationLoading', false).set('error', action.error);
}

function getForecastSuccess(
  state: HsaPageState,
  action: {
    response: GetForecastsResponse;
  },
) {
  return state.set('isForecastLoading', false).set('forecastData', fromJS(action.response));
}

function getForecastFailure(
  state: HsaPageState,
  action: {
    error: Error;
  },
) {
  return state.set('isForecastLoading', false).set('forecastError', action.error);
}

function getRetirementForecast(state: HsaPageState) {
  return state.set('retirementForecastData', fromJS([])).set('isRetirementForecastLoading', true);
}

function getRetirementForecastSuccess(
  state: HsaPageState,
  action: {
    responses: GetForecastsResponse;
  },
) {
  return state
    .set('isRetirementForecastLoading', false)
    .set('retirementForecastData', fromJS(action.responses));
}

function getRetirementForecastFailure(
  state: HsaPageState,
  action: {
    error: Error;
  },
) {
  return state.set('isRetirementForecastLoading', false).set('retirementError', action.error);
}

function setSavedHsaContribution(
  state: HsaPageState,
  action: {
    contribution: number;
  },
) {
  return state.set('lockedHsaContribution', action.contribution).set('isForecastLoading', true);
}

function setHsaBalanceAndPersona(
  state: HsaPageState,
  action: {
    balance: number;
    persona: Persona;
  },
) {
  return state
    .set('currentHsaBalance', action.balance)
    .set('persona', action.persona)
    .set('isForecastLoading', true);
}

function resetContributionAmounts(state: HsaPageState) {
  return state.set('lockedHsaContribution', null);
}

const pojoHsaPageReducer = wrapImmutableReducerInPojoTranslation(hsaPageReducer);

export default pojoHsaPageReducer;
