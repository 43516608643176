import { Flex, ModalHeader } from '@chakra-ui/react';
import React from 'react';

import { Button, H5 } from 'DesignLibrary/atoms';
import Text from 'Shared/Text';

const ProviderHeader = ({ setOptions, setSearchText, onClose, sendInsightsEvent }) => {
  return (
    <ModalHeader as={Flex} align="center" justify="space-between" px={[4, 6]}>
      <H5 className="title" weight="bold" data-testid="provider-selection-title">
        <Text field="health_section.provider_selection_modal.title" />
      </H5>
      <Button
        size="small"
        iconLeft="X"
        buttonType="transparent"
        iconProps={{ color: '--text-gray' }}
        onClick={() => {
          // TODO: Turn this into a function that holds this behavior
          setOptions([]);
          setSearchText('');
          onClose();
          sendInsightsEvent(null, 'close_provider_search_modal');
        }}
        data-testid="provider-selection-close-modal-button"
      />
    </ModalHeader>
  );
};

export default ProviderHeader;
