import React, { useState, useEffect, useRef } from 'react';

import { TextField } from 'Containers/App/types';
import { RatingData } from 'ContentfulDefaults/types/review';
import { useTextContext } from 'Contexts/textContext';
import { Button, Paragraph, Tooltip } from 'DesignLibrary/atoms';
import Text from 'Shared/Text';
import { AriaHiddenAlert } from 'Utils/accessibility';
import { sendInsightsEvent } from 'Utils/insights';

import {
  FeedbackContainer,
  TextAreaFeedback,
  FinishedFeedback,
  ScaleFaceButton,
  ScaleFeedback,
} from './styled';

export interface FeedbackProps {
  feedbackSubmitted: boolean;
  handleFeedbackSubmit: () => void;
}

const Feedback = ({ feedbackSubmitted, handleFeedbackSubmit }: FeedbackProps) => {
  const [scaleAnswered, setScaleAnswered] = useState(false);
  const [answer, setAnswer] = useState('');
  const [hiddenAlert, setHiddenAlert] = useState<string | null>(null);
  const [activeIndex, setActiveIndex] = useState(-1);

  const faceIconRefs = useRef<HTMLButtonElement[]>([]);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const { retrieveContentfulData } = useTextContext();

  useEffect(() => {
    if (scaleAnswered) {
      containerRef.current?.focus();
    }
  }, [scaleAnswered, feedbackSubmitted]);

  function handleFeedback(e, rating) {
    sendInsightsEvent(e, 'feedback_rating', { rating });

    setScaleAnswered(true);
    setHiddenAlert(retrieveContentfulData<string>('review_section.accessibility.rating_submitted_alert'));
  }

  function handleAnswerInput(e) {
    setAnswer(e.target.value);
  }

  function handleSubmit(e) {
    e.preventDefault();

    sendInsightsEvent(e, 'feedback_text', { answer });

    handleFeedbackSubmit();
    setHiddenAlert(retrieveContentfulData<string>('review_section.accessibility.comments_submitted_alert'));
  }

  const renderFeedbackOptions = () => {
    const handleOnKeyDown = (e) => {
      if (e.key === 'Enter' || e.key === ' ') {
        handleFeedback(e, 5 - activeIndex);
      }
    };

    const handleOnClick = (e, val) => {
      if (e.type === 'click' && e.clientX !== 0 && e.clientY !== 0) {
        handleFeedback(e, val);
      }
    };

    const faceButtonValues = ['great', 'good', 'ok', 'bad', 'terrible'];

    return (
      <ScaleFeedback
        id="face-button-list"
        className="options"
        role="radiogroup"
        tabIndex={0}
        aria-activedescendant={`face-button-${activeIndex}`}
        onKeyDown={(e) => {
          if (e.key === 'ArrowLeft') {
            if (activeIndex > 0) {
              faceIconRefs.current[activeIndex - 1].focus();
              setActiveIndex(activeIndex - 1);
            }
          } else if (e.key === 'ArrowRight') {
            if (activeIndex < 4) {
              faceIconRefs.current[activeIndex + 1].focus();
              setActiveIndex(activeIndex + 1);
            }
          }
        }}
      >
        <legend aria-labelledby="feedback-question-labels" />

        {faceButtonValues.map((i, idx) => {
          // the value that will be sent on submission (5 = great, 4 = good, ...)
          const value = 5 - idx;

          const ratings = retrieveContentfulData<Record<number, RatingData>>(`review_section.ratings`);

          return (
            <Tooltip id={`${i}-rating-tooltip`} isInteractive label={ratings?.[value].text} key={idx}>
              <ScaleFaceButton
                buttonType="secondary"
                role="button"
                onClick={(e) => handleOnClick(e, value)}
                onKeyDown={(e) => handleOnKeyDown(e)}
                onFocus={() => {
                  if (activeIndex < 0) {
                    setActiveIndex(idx);
                  }
                }}
                aria-labelledby={`${i}-rating`}
                id={`face-button-${value}`}
                aria-current={activeIndex === idx}
                ref={(el: HTMLButtonElement) => {
                  faceIconRefs.current[idx] = el;
                }}
                tabIndex={activeIndex < 0 || activeIndex === idx ? 0 : -1}
              >
                <div id={`${i}-rating`} className="pseudo-answer" aria-hidden>
                  <span role="img" aria-label={ratings?.[value].alt}>
                    <Text field={`review_section.ratings[${value}].icon` as TextField} />
                  </span>
                </div>
              </ScaleFaceButton>
            </Tooltip>
          );
        })}
      </ScaleFeedback>
    );
  };

  return (
    <FeedbackContainer ref={containerRef}>
      <AriaHiddenAlert>{hiddenAlert}</AriaHiddenAlert>
      {!scaleAnswered && !feedbackSubmitted ? (
        <>
          <Paragraph size="small">
            <Text field="review_section.experience_question" />
          </Paragraph>
          {renderFeedbackOptions()}
        </>
      ) : !feedbackSubmitted ? (
        <TextAreaFeedback id="feedback" role="region" aria-live="assertive">
          <Paragraph size="small">
            <Text field="review_section.comment_instructions" />
          </Paragraph>
          <form id="feedback-form" onSubmit={handleSubmit}>
            <textarea
              aria-labelledby="comment-heading-instructions"
              onChange={handleAnswerInput}
              placeholder={retrieveContentfulData<string>('review_section.comment_placeholder_text')}
            />
            <Button buttonType="primary" color="black" size="small" stretch onClick={() => null}>
              <Text field="review_section.button_text.comments" />
            </Button>
          </form>
        </TextAreaFeedback>
      ) : (
        <FinishedFeedback id="finished" role="region" aria-live="polite">
          <Paragraph size="small" weight="bold" color="--text-black">
            <Text field="review_section.response_heading" />
          </Paragraph>
          <Paragraph size="small">
            <Text field="review_section.response_body" />
          </Paragraph>
        </FinishedFeedback>
      )}
    </FeedbackContainer>
  );
};

export default Feedback;
