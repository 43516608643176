import React, { useEffect } from 'react';

import { CAPACITY_TO_PAY, Survey } from 'Containers/ProfilePage/types';
import { CapacityToPayCard } from 'ContentfulDefaults/types/profile';
import { useProfileContext } from 'Contexts/profileContext';
import { useTextContext } from 'Contexts/textContext';
import ToggleList from 'DesignLibrary/atoms/ToggleList';
import { Paragraph } from 'DesignLibrary/atoms/typography';
import { useViewport } from 'DesignLibrary/context';

import Question from '../_shared/Question';
import { QuestionWrapper } from '../_shared/Question/styled';
import { AudioConfigTooltipContentfulPaths } from '../ProfileAudio';

export interface CapacityToPaySectionProps {
  survey: Survey;
  isDirty: boolean;
  isProfileAudioEnabled: boolean;
  handleChangeCapacityToPay: (answer: string) => void;
  handleSectionChange: (isValid: boolean, isComplete: boolean) => void;
  setHiddenAlert: (alert: string) => void;
}

const CapacityToPaySection = ({
  survey,
  isDirty,
  isProfileAudioEnabled,
  handleChangeCapacityToPay,
  handleSectionChange,
  setHiddenAlert,
}: CapacityToPaySectionProps) => {
  const { retrieveContentfulData } = useTextContext();
  const { getSectionFromMap } = useProfileContext();
  const { device } = useViewport();

  const profileCard = retrieveContentfulData<CapacityToPayCard>('profile_section.capacity_to_pay');

  useEffect(() => {
    const alertText = retrieveContentfulData<string>('profile_section.accessibility.unlocked_section', '');
    setHiddenAlert(alertText.replace(/{x}/, profileCard.name));
  }, []);

  useEffect(() => {
    const section = getSectionFromMap('capacityToPay');
    const isComplete = section?.isComplete || validateForm();
    handleSectionChange(validateForm(), isComplete);
  }, [survey.capacity_to_pay]);

  function validateForm() {
    return survey.capacity_to_pay !== '';
  }

  const handleChange = (value) => {
    handleChangeCapacityToPay(value);
  };

  const renderOptions = () => {
    const capacityOptions = [
      {
        option: <span className="pingdom-capacity-to-pay">{profileCard.button_options.lt_500}</span>,
        value: CAPACITY_TO_PAY.CAP_LT_500,
      },
      {
        option: profileCard.button_options['500_1000'],
        value: CAPACITY_TO_PAY.CAP_500_1000,
      },
      {
        option: profileCard.button_options['1000_2000'],
        value: CAPACITY_TO_PAY.CAP_1000_2000,
      },
      {
        option: profileCard.button_options['2000_3000'],
        value: CAPACITY_TO_PAY.CAP_2000_3000,
      },
      {
        option: profileCard.button_options['3000_4000'],
        value: CAPACITY_TO_PAY.CAP_3000_4000,
      },
      {
        option: profileCard.button_options['4000_6000'],
        value: CAPACITY_TO_PAY.CAP_4000_6000,
      },
      {
        option: profileCard.button_options.gt_6000,
        value: CAPACITY_TO_PAY.CAP_GT_6000,
      },
    ];

    return (
      <ToggleList
        label={profileCard.header}
        groupName="capacityToPaySection"
        className="pingdom-capacity-to-pay-section"
        grid
        layout={device === 'mobile' ? 'column' : 'row'}
        options={capacityOptions}
        handleChange={(e) => handleChange(e.target.value)}
        selected={survey.capacity_to_pay}
      />
    );
  };

  const isIncomplete = !getSectionFromMap('capacityToPay')?.isComplete;

  return (
    <div id="capacityToPay-section" data-testid="capacity-section">
      <Question
        error={!validateForm() && isDirty}
        name="capacity_to_pay"
        isIncomplete={isIncomplete}
        audioTooltipsEnabled={isProfileAudioEnabled}
        audioTooltipContentfulPath={AudioConfigTooltipContentfulPaths.AffordabilitySectionAudioTooltip}
      >
        <QuestionWrapper className="question-wrapper">
          <Paragraph>{profileCard.header}</Paragraph>
          {renderOptions()}
        </QuestionWrapper>
      </Question>
    </div>
  );
};

export default CapacityToPaySection;
