import styled from 'styled-components';

import { cardStyling } from 'DesignLibrary/utils';

export const Container = styled.div`
  ${cardStyling}
  display: flex;
  align-items: center;
  padding: 20px var(--sizing-md);
  margin-bottom: var(--sizing-sm);

  .start-number,
  .end-number {
    user-select: none;
    pointer-events: none;
    flex-shrink: 0;
  }

  .slider {
    margin: 0 var(--sizing-sm);
  }

  .slider-thumb {
    box-shadow: var(--shadow-large);
    background: var(--primary-white);
    outline: 4px solid rgba(0, 0, 0, 0);
    transition: 0.15s outline-color;

    &:hover,
    &:focus {
      outline-color: var(--colors-opaque-30);
      outline-offset: 0;
    }
  }

  .slider-mark {
    background: var(--text-black);
    border-radius: 4px;
    padding: 2px var(--sizing-xs);
    position: relative;
    margin-top: -56px;
    transform: translateX(-50%);

    &:after {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      background: var(--text-black);
      position: absolute;
      bottom: -5px;
      left: calc(50% - 5px);
      transform: rotate(45deg);
      border-radius: 0 0 2px 0;
    }
  }
`;
