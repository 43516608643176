import { AspectRatio } from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import React from 'react';

import { TextField } from 'Containers/App/types';
import { fetchShowVideoStatus } from 'Containers/App/utils';
import { RiskAversion, Survey } from 'Containers/ProfilePage/types';
import { CommuterBenefitDescription } from 'Containers/ViewBenefitPage/BenefitDetails/commuterContent';
import { ContentfulCustomMessage } from 'ContentfulDefaults/types/_shared';
import { ContentfulSupplementalPlan } from 'ContentfulDefaults/types/benefitsAdditional';
import { useFeatureFlagContext } from 'Contexts/featureFlagContext';
import { useTextContext } from 'Contexts/textContext';
import { Button, H4, H6, Paragraph } from 'DesignLibrary/atoms';
import { Drawer } from 'DesignLibrary/atoms/Drawer';
import InfoBar from 'DesignLibrary/molecules/InfoBar';
import RichText from 'Shared/RichText';
import { SupplementalIcon } from 'Shared/SupplementalIcon';
import Text from 'Shared/Text';
import Video from 'Shared/Video';
import { ReasonsWhy, SupplementalPlan } from 'Types/entities';
import { BuilderGoSettings } from 'Utils/apiTypes';

import { DCFSADescription } from './dcfsaContent';
import { MedicalFSABenefitDescription } from './medicalFSAContent';
import { ChoosePlanBtnWrapper, DetailsContainer, DetailsPlan } from './styled';
import { CONTENT_MAP } from '../constants';
import FbsDetail from '../ExpandablePlanCard/FbsDetail';
import PlanButtons from '../ExpandablePlanCard/PlanButtons';
import { ButtonContainer } from '../ExpandablePlanCard/styled';
import ReasonsWhyList from '../ReasonsWhyList';
import { BuilderBenefit, InsuranceType } from '../types';

export interface BenefitDetailsProps {
  employerName: string;
  insuranceType: InsuranceType;
  healthHsaEligible: boolean;
  isOpen: boolean;
  nonSelectablePlans?: SupplementalPlan[];
  builderBenefits: Record<string, BuilderBenefit> | Record<string, never>;
  reasonsWhy: ReasonsWhy;
  selectedHealthDeductible: number;
  dependentsCount: number;
  surveyAnswers: Survey;
  riskAversion: RiskAversion;
  planCount?: number;
  builderCustomerKey: string | null;
  builderGoSettings: BuilderGoSettings | null;
  handleOpenBenefit: (insuranceType: string) => void;
  onClose: () => void;
}

const BenefitDetails = ({
  employerName,
  insuranceType,
  healthHsaEligible,
  isOpen,
  nonSelectablePlans = [],
  builderBenefits,
  reasonsWhy,
  selectedHealthDeductible,
  dependentsCount,
  surveyAnswers,
  riskAversion,
  planCount,
  builderCustomerKey,
  onClose,
  handleOpenBenefit,
  builderGoSettings,
}: BenefitDetailsProps) => {
  const content = CONTENT_MAP;
  const { retrieveContentfulData } = useTextContext();
  const { is_educational_videos_enabled } = useFeatureFlagContext();

  // Determine if fields exist in Contentful. If not, use hard-coded values in content map
  const benefitTitle = retrieveContentfulData<string>(
    `${content[insuranceType].field}.card_title`,
    insuranceType,
  );
  const altBenefitTitle = retrieveContentfulData<string>(`${content[insuranceType].field}.alternate_title`);
  const benefitVideo = retrieveContentfulData<string>(`${content[insuranceType].field}.video_link`);
  const altBenefitVideo = retrieveContentfulData<string>(
    `${content[insuranceType].field}.alternate_video_link`,
  );
  const benefitNotes = retrieveContentfulData<Record<string, string>>(
    `${content[insuranceType].field}.notes`,
  );

  // Determine if Limited Purpose FSA and show alternate description if so
  const description = content[insuranceType].description;
  const altDescription = content[insuranceType].alternate_description as JSX.Element;
  let revisedTitle = benefitTitle;
  let revisedDescription = description;
  let revisedVideo = benefitVideo;

  const isFsaOrFsaMedical = healthHsaEligible && ['fsa', 'fsa_medical'].includes(insuranceType);

  if (isFsaOrFsaMedical) {
    revisedTitle = altBenefitTitle as string;
    revisedDescription = altDescription;
    revisedVideo = altBenefitVideo;
  }

  const renderReasonsWhy = () =>
    !isEmpty(reasonsWhy[insuranceType]) && (
      <ReasonsWhyList
        insuranceType={insuranceType}
        reasonsWhy={reasonsWhy[insuranceType]!}
        healthDeductible={selectedHealthDeductible}
        childrenCount={dependentsCount}
        capacityToPay={surveyAnswers.capacity_to_pay}
        riskAversion={riskAversion}
      />
    );

  const renderViewPlansButton = () => {
    if (planCount) {
      return (
        <ChoosePlanBtnWrapper>
          <Button
            buttonType="primary"
            iconRight="ArrowRight"
            data-testid={`choose-${insuranceType}-btn`}
            className={`open-benefit-btn ${`pingdom-choose-${insuranceType}`}`}
            onClick={() => handleOpenBenefit(insuranceType)}
            stretch
          >
            <Text field="benefits_section.button_text.viewPlans" />
          </Button>
        </ChoosePlanBtnWrapper>
      );
    }
    return null;
  };

  const renderCustomMessage = (plan: SupplementalPlan) => {
    const contentfulSupplementalPlans = retrieveContentfulData<ContentfulSupplementalPlan[]>(
      'benefits_section_additional.supplemental_plans',
      [],
    );

    const customMessageIdx = contentfulSupplementalPlans.findIndex((i) => i.plan_id === plan.external_id);

    if (customMessageIdx >= 0) {
      const customMessage = contentfulSupplementalPlans[customMessageIdx]
        .custom_message as ContentfulCustomMessage;

      if (!customMessage) {
        return null;
      }
      return (
        <InfoBar
          className={`${plan.external_id}-customMessage`}
          text={
            <RichText
              field={
                `benefits_section_additional.supplemental_plans[${customMessageIdx}].custom_message.text` as TextField
              }
            />
          }
          color={customMessage?.color}
          header={customMessage?.header}
          stretch
          icon={customMessage?.icon}
          buttonText={customMessage?.url_button_text}
          url={customMessage?.url}
        />
      );
    }
    return null;
  };

  const renderDescription = (plan: SupplementalPlan) => {
    if (isEmpty(plan.benefits.description) && !builderBenefits?.[plan.external_id]?.path) {
      return null;
    }
    if (!builderCustomerKey && plan?.benefits?.description) {
      return (
        <Paragraph lineHeight={1.75} size="small" className="plan-description">
          <FbsDetail type={plan.benefits.description.type} data={plan.benefits.description.data} />
        </Paragraph>
      );
    }

    // TODO: Use this variable somewhere
    // eslint-disable-next-line no-constant-condition
    if (false) {
      return employerName;
    }
    if (builderBenefits[plan.external_id]?.description) {
      return (
        <Paragraph lineHeight={1.75} size="small" className="plan-description">
          <RichText
            noWrapper={false}
            field={`${builderBenefits[plan.external_id].path}.description` as TextField}
          />
        </Paragraph>
      );
    }
    let desc: JSX.Element | null | string = null;
    if (plan.plan_type === 'commuter') {
      desc = <CommuterBenefitDescription plan={plan} />;
    } else if (plan.plan_type === 'fsa' || plan.plan_type === 'fsa_medical') {
      desc = <MedicalFSABenefitDescription plan={plan} isLimitedPurpose={isFsaOrFsaMedical} />;
    } else if (plan.plan_type === 'fsa_dependent') {
      desc = <DCFSADescription plan={plan} />;
    } else if (builderBenefits?.[plan.external_id]?.path) {
      desc = (
        <Paragraph lineHeight={1.75} size="small" className="plan-description">
          <RichText
            noWrapper={false}
            field={`${builderBenefits[plan.external_id].path}.description` as TextField}
          />
        </Paragraph>
      );
    } else if (plan?.benefits?.description) {
      desc = (
        <Paragraph lineHeight={1.75} size="small" className="plan-description">
          <FbsDetail type={plan.benefits.description.type} data={plan.benefits.description.data} />
        </Paragraph>
      );
    }

    return desc;
  };
  const shouldShowEducationalVideo =
    !is_educational_videos_enabled ||
    (builderGoSettings && !!builderGoSettings[fetchShowVideoStatus(insuranceType)]);

  return (
    <Drawer
      title={revisedTitle as string}
      titleIcon={<SupplementalIcon type={insuranceType} size="small" />}
      isOpen={isOpen}
      onClose={onClose}
    >
      <DetailsContainer data-testid={`${insuranceType}-drawer`}>
        <div className="reasons-why">{renderReasonsWhy()}</div>

        {nonSelectablePlans.length > 0 && (
          <InfoBar text={<RichText field="benefits_section_additional.no_plans_info_bar.text" />} />
        )}

        {shouldShowEducationalVideo && revisedVideo && (
          <div className="video-container">
            <AspectRatio ratio={16 / 9} data-testid={`${insuranceType}-video`}>
              <Video
                title={`${benefitTitle} video`}
                url={revisedVideo as string}
                width="100%"
                height="100%"
              />
            </AspectRatio>
          </div>
        )}

        {nonSelectablePlans.length > 0 && (
          <>
            <Paragraph>
              <Text field="benefits_section_additional.non_selectable_plans_label" />
            </Paragraph>
            <section className="detail-plans">
              {nonSelectablePlans.map((plan, idx) => (
                <DetailsPlan key={`${plan.external_id}-details`}>
                  <div className="header-text">
                    <Paragraph size="small" className="carrier-name">
                      {plan.carrier_name}
                    </Paragraph>
                    <H4 as="h3" className="plan-name">
                      {revisedTitle}
                    </H4>
                  </div>

                  {renderCustomMessage(plan)}
                  {renderDescription(plan)}

                  {builderBenefits?.[plan.external_id]?.benefit_items && (
                    <div className="benefit-items">
                      {builderBenefits[plan.external_id].benefit_items.map((item, idx) => {
                        const [name, details = ''] = item.split(':');
                        return (
                          <React.Fragment key={idx}>
                            <h4>{name}</h4>
                            <Paragraph lineHeight={1.75} size="small">
                              {details}
                            </Paragraph>
                          </React.Fragment>
                        );
                      })}
                    </div>
                  )}

                  {plan.pdf_url && (
                    <ButtonContainer className="button-container">
                      <PlanButtons
                        plan={plan}
                        idx={idx}
                        insuranceType={insuranceType}
                        isSelected={false}
                        isAutoEnrolled={false}
                        isEligible={false}
                        hasNoSelection
                        // hasNoSelection -> don't need functions to handle selection
                        handleSelect={() => null}
                        handleRemovePlan={() => null}
                        handleTrackPdfView={() => null}
                      />
                    </ButtonContainer>
                  )}
                </DetailsPlan>
              ))}
            </section>
          </>
        )}

        <div className="main-description">
          <Paragraph lineHeight={1.75}>{revisedDescription}</Paragraph>
          <Paragraph lineHeight={1.75}>{content[insuranceType].info}</Paragraph>

          {benefitNotes && (
            <div className="notes">
              <H6>{benefitNotes.title}</H6>
              <Paragraph lineHeight={1.75}>{benefitNotes.note}</Paragraph>
            </div>
          )}
        </div>

        {renderViewPlansButton()}
      </DetailsContainer>
    </Drawer>
  );
};

export default BenefitDetails;
