export function calculateHsaRecommendation(
  oopEstimate: number,
  hsaPercentageMatch: number | null,
  employerHsaContributionLimit: number | null,
  hsaContributionLimit: number,
  employerHsaContribution: number | null,
) {
  if (hsaPercentageMatch && hsaPercentageMatch > 0) {
    // Calculate the recommended contribution after employer match
    const percentageMatch = hsaPercentageMatch / 100;
    const employerLimit =
      employerHsaContributionLimit && employerHsaContributionLimit > 0
        ? Math.min(employerHsaContributionLimit, hsaContributionLimit)
        : hsaContributionLimit;
    const totalToContribute = Math.min(oopEstimate, hsaContributionLimit);
    const employerMatch = Math.min(
      (totalToContribute * percentageMatch) / (1 + percentageMatch),
      employerLimit,
    );
    const recommendedContribution = totalToContribute - employerMatch;
    return recommendedContribution;
  }

  // Else return recommended employee contribution for fixed employer contributions
  const hsaOopEstimate = Math.max(oopEstimate - (employerHsaContribution || 0), 0);
  const maxEmployeeContribution = hsaContributionLimit
    ? Math.max(hsaContributionLimit - (employerHsaContribution || 0), 0)
    : null;
  return maxEmployeeContribution ? Math.min(maxEmployeeContribution, hsaOopEstimate) : hsaOopEstimate;
}
