import { Stack } from '@chakra-ui/react';
import React from 'react';

import { MedicalFSABenefits } from 'Containers/ViewBenefitPage/types';
import { transformBenefitItems } from 'Containers/ViewBenefitPage/utils';
import { useTextContext } from 'Contexts/textContext';
import { Paragraph } from 'DesignLibrary/atoms';
import Text from 'Shared/Text';
import { SupplementalPlan } from 'Types/entities';
import { toDollars } from 'Utils/helpers';

export interface MedicalFSABenefitDescriptionProps {
  plan: SupplementalPlan;
  isLimitedPurpose: boolean;
}

export const MedicalFSABenefitDescription = (props: MedicalFSABenefitDescriptionProps) => {
  const { plan, isLimitedPurpose } = props;
  const { retrieveContentfulData } = useTextContext();
  const benefitItems = transformBenefitItems(plan) as MedicalFSABenefits;
  const getLimitSection = (): JSX.Element | null => {
    const limit = toDollars(
      retrieveContentfulData<number>('benefits_section_medical_fsa.federal_contribution_limit'),
    );
    if (!isLimitedPurpose) {
      if (benefitItems.uses_federal_limit) {
        return (
          <Paragraph>
            <Text
              field="benefits_section_medical_fsa.uses_federal_max_body"
              vars={{ limit: toDollars(limit) }}
            />
          </Paragraph>
        );
      }
      return (
        <Paragraph>
          <Text
            field="benefits_section_medical_fsa.not_federal_max_body"
            vars={{ limit: toDollars(benefitItems.maximum_contribution), fedLimit: limit }}
          />
        </Paragraph>
      );
    }
    if (isLimitedPurpose) {
      if (benefitItems.uses_federal_limit) {
        return (
          <Paragraph>
            <Text
              field="benefits_section_medical_fsa.limited_purpose_uses_federal_max_body"
              vars={{ limit: toDollars(limit) }}
            />
          </Paragraph>
        );
      }
      return (
        <Paragraph>
          <Text
            field="benefits_section_medical_fsa.limited_purpose_not_federal_max_body"
            vars={{ limit: toDollars(benefitItems.maximum_contribution), fedLimit: limit }}
          />
        </Paragraph>
      );
    }
    return null;
  };

  const getMinimumContributionSection = (): JSX.Element | null =>
    benefitItems.minimum_contribution !== '0' ? (
      <Paragraph>
        <Text
          field="benefits_section_medical_fsa.minimum_contribution_info"
          vars={{ min: toDollars(benefitItems.minimum_contribution) }}
        />
      </Paragraph>
    ) : null;

  const getRolloverInfo = (): JSX.Element =>
    plan.fsa_specific?.rollover_amount_limit?.data ? (
      <Paragraph>
        <Text
          field="benefits_section_medical_fsa.has_rollover_body"
          vars={{ amount: toDollars(plan.fsa_specific?.rollover_amount_limit.data) }}
        />
      </Paragraph>
    ) : (
      <Paragraph>
        <Text field="benefits_section_medical_fsa.no_rollover_body" />
      </Paragraph>
    );

  return (
    <Stack gap={3}>
      {getLimitSection()}
      {getMinimumContributionSection()}
      {getRolloverInfo()}
    </Stack>
  );
};
