import React, { FC } from 'react';

import { useTextContext } from 'Contexts/textContext';
import { FormattedTooltipProps, Icon } from 'DesignLibrary/atoms';
import { TooltipPlanDetails } from 'DesignLibrary/molecules/TooltipPlanDetails';
import Text from 'Shared/Text';

import { Container, Status } from './styled';

export type Risk = 'risky' | 'moderate' | 'safe';

export interface RatingProps {
  risk: Risk;
  type: 'short' | 'long';
}

export const Rating: FC<RatingProps> = ({ risk, type, children }) => {
  const { retrieveContentfulData } = useTextContext();
  const shortTermTitle = (
    <TooltipPlanDetails
      tooltip={retrieveContentfulData<FormattedTooltipProps>(
        'hsa.interactive_section.tooltips.yearly_will_this_cover_question_short_term',
      )}
      keyHint="will-this-cover-question-short-term"
    >
      <Text field="hsa.interactive_section.yearly_will_this_cover_question" />
    </TooltipPlanDetails>
  );

  const longTermTitle = (
    <TooltipPlanDetails
      tooltip={retrieveContentfulData<FormattedTooltipProps>(
        'hsa.interactive_section.tooltips.yearly_will_this_cover_question_long_term',
      )}
      keyHint="will-this-cover-question-long-term"
    >
      <Text field="hsa.interactive_section.yearly_will_this_cover_question" />
    </TooltipPlanDetails>
  );

  return (
    <Container>
      <div className="title">{type === 'short' ? shortTermTitle : longTermTitle}</div>
      <div className="status-container" aria-hidden>
        <Status state="risky" active={risk === 'risky'}>
          <Icon type="Warning" color={risk === 'risky' ? '--primary-white' : '--colors-gray-400'} />
        </Status>
        <Status state="moderate" active={risk === 'moderate'}>
          <Icon type="WarningCircle" color={risk === 'moderate' ? '--primary-white' : '--colors-gray-400'} />
        </Status>
        <Status state="safe" active={risk === 'safe'}>
          <Icon type="CheckCircle" color={risk === 'safe' ? '--primary-white' : '--colors-gray-400'} />
        </Status>
      </div>
      {children}
    </Container>
  );
};
