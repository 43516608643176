import { COLORS } from 'DesignLibrary/vars';

const groupValues = ['low', 'moderate', 'high'];

export type Group = (typeof groupValues)[number];

export const tierColor = (group: Group): string | undefined => {
  switch (group) {
    case 'low':
      return COLORS.green[400];
    case 'moderate':
      return COLORS.yellow[300];
    case 'high':
      return COLORS.red[400];
    default:
      return undefined;
  }
};
