import React from 'react';

import { Group } from 'Containers/ResultPage/ExplainerModal/_shared/styled';

import { PersonContainer, PersonIcon } from './styled';

interface PLYVisualizationProps {
  percent: number;
  group: Group;
}

const getPerson = (group: Group, key: string) => (
  <PersonIcon key={`personIcon-${key}`} group={group} aria-hidden>
    <svg viewBox="0 0 22 26">
      <g>
        <path d="M10.7 12.2C7.5 12.2 4.9 9.6 4.9 6.3 4.9 3.1 7.5 0.5 10.7 0.5 13.9 0.5 16.6 3.1 16.6 6.3 16.6 9.6 13.9 12.2 10.7 12.2ZM10.7 14.9C16.6 14.9 21.3 19.7 21.3 25.5L0.2 25.5C0.2 19.7 4.9 14.9 10.7 14.9Z" />
      </g>
    </svg>
  </PersonIcon>
);

export const PLYVisualization = ({ percent, group }: PLYVisualizationProps) => {
  const icons: JSX.Element[] = [];
  for (let i = 0; i < percent; i += 1) {
    icons.push(getPerson(group, `${i}`));
  }
  return <PersonContainer>{icons}</PersonContainer>;
};
