import React, { FC } from 'react';

import { usePremiumContext } from 'Contexts/premiumContext';
import { useTextContext } from 'Contexts/textContext';
import { FormattedTooltipProps, Numbers, Paragraph, Tooltip } from 'DesignLibrary/atoms';
import { PAY_PERIOD_VALUES } from 'Types/entities';
import { toDollars } from 'Utils/helpers';

import { PremiumPerDeductionContainer } from './styled';

export interface PremiumPerDeductionProps {
  annualPremium: number;
  deductions: number;
  currency: boolean;
  precision?: 0 | 2;
  keyHint?: string;
  prefix?: string;
  size?: 'small' | 'medium' | 'large';
  hideTooltip?: boolean;
  fontWeight?: 'normal' | 'bold';
}

export const PremiumPerDeduction: FC<PremiumPerDeductionProps> = ({
  keyHint,
  annualPremium,
  deductions,
  currency,
  precision = 0,
  prefix = '',
  size = 'medium',
  hideTooltip = false,
  fontWeight = 'normal',
}): JSX.Element => {
  const { retrieveContentfulData } = useTextContext();
  const { payPeriod } = usePremiumContext();

  const tooltipTitle = retrieveContentfulData<string>('tool_tips.pay_period.title');
  const deductionTextKeys = {
    12: 'tool_tips.pay_period.monthly',
    24: 'tool_tips.pay_period.semiMonthly',
    26: 'tool_tips.pay_period.biWeekly',
    1: 'tool_tips.pay_period.yearly',
    52: 'tool_tips.pay_period.weekly',
  };

  const nonNullDeductions = deductions || PAY_PERIOD_VALUES[payPeriod];

  const key: string =
    nonNullDeductions in deductionTextKeys
      ? deductionTextKeys[nonNullDeductions]
      : 'tool_tips.pay_period.perPaycheck';

  const tooltip = retrieveContentfulData<FormattedTooltipProps>(key);

  // Replace template var with number of deductions
  let tooltipText = tooltip.text;
  if (typeof tooltip.text === 'string') {
    tooltipText = tooltip.text ? tooltip.text.replace('{x}', `${nonNullDeductions}`) : '';
  }

  const formatValue = () => {
    const valuePerDeduction = annualPremium / nonNullDeductions;
    if (currency) {
      return toDollars(valuePerDeduction, precision);
    }
    return valuePerDeduction;
  };

  return (
    <PremiumPerDeductionContainer
      key={`number-per-deduction-${keyHint}`}
      data-testid={`number-per-deduction-${keyHint}`}
      size={size}
    >
      <div className="numbers">
        <Numbers data-testid={`number-${keyHint}`} size={size} weight={fontWeight}>
          {prefix}
          {formatValue()}
          {hideTooltip && tooltip.underline}
        </Numbers>
        {!hideTooltip && (
          <Tooltip
            data-testid={`pay-period-${keyHint}`}
            id={`pay-period-${keyHint}`}
            label={{ title: tooltipTitle, text: tooltipText }}
          >
            <Paragraph className="pay-period" underline size="small" lineHeight={1} color="--text-gray-light">
              {tooltip.underline}
            </Paragraph>
          </Tooltip>
        )}
      </div>
    </PremiumPerDeductionContainer>
  );
};
