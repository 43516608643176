import { ContentfulPlySection } from 'ContentfulDefaults/types/ply';

export const ply: { people_like_you: ContentfulPlySection } = {
  people_like_you: {
    contentful_name: 'People Like You (DEFAULT DO NOT CHANGE)',
    button_text: {
      results: 'Mostrar mis resultados',
      backToResults: 'Volver a resultados',
    },
    plan_ranking: {
      texts: [
        'Análisis de todos los planes elegibles',
        'Comparación de planes para personas como tú',
        'Finalizando la clasificación del plan',
      ],
      title: 'Clasifica tus planes',
    },
    people_like_you_title: 'Encontrar personas como tú',
    office_visits: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'tuvo ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'bold',
                },
              ],
              value: 'una o más visitas al consultorio',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value: ' durante el año del plan.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    emergency_care: {
      nodeType: 'document',
      data: {},
      content: [
        {
          nodeType: 'paragraph',
          content: [
            {
              nodeType: 'text',
              value: 'que recibieron ',
              marks: [],
              data: {},
            },
            {
              nodeType: 'text',
              value: 'atención urgente o de emergencia',
              marks: [
                {
                  type: 'bold',
                },
              ],
              data: {},
            },
            {
              nodeType: 'text',
              value: ' durante el año del plan.',
              marks: [],
              data: {},
            },
          ],
          data: {},
        },
      ],
    },
    claim_rate: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'fueron ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'bold',
                },
              ],
              value: 'ingresados en un hospital',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value: ' durante el año del plan.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    profile_summary_title: 'Compilando sus respuestas de perfil',
    profile_answers: {
      spouse: 'You and Spouse/Partner',
      noSpouse: 'Covering You',
      dependent: {
        one: '1 dependiente',
        many: '{x} Dependientes',
      },
      pcpVisits: {
        one: '1 visita de PCP',
        many: '{x} visitas al PCP',
      },
      pregnancy: 'Esperando embarazo',
      riskAversion: {
        low: 'Baja aversión al riesgo',
        high: 'Aversión al alto riesgo',
        moderate: 'moderada aversión al riesgo',
      },
      capacityToPay: {
        lt_500: 'Podría permitirse menos de $ 500',
        gt_6000: 'Podría permitirse más de $ 6,000',
        '500_1000': 'Podría permitirse $ 500 a $ 1,000',
        '1000_2000': 'Podría permitirse $ 1,000 a $ 2,000',
        '2000_3000': 'Podría permitirse $ 2,000 a $ 3,000',
        '3000_4000': 'Podría permitirse $ 3,000 a $ 4,000',
        '4000_6000': 'Podría permitirse $ 4,000 a $ 6,000',
      },
      inpatientDays: {
        one: '1 día de internación',
        many: '{x} días de internación',
      },
      prescriptions: {
        one: '1 receta',
        many: '{x} Recetas',
      },
      specialistVisits: {
        one: '1 visita de especialista',
        many: '{x} Visitas especializadas',
      },
      mentalHealthVisits: {
        one: '',
        many: '',
      },
    },
    modal_title: 'Explore nuestras predicciones',
    spending_icons: [
      {
        plyKey: 'office_visits',
        iconText: 'Visitas a la oficina',
        inlineText: 'al menos una consulta con un médico de cuidados primarios o un especialista',
      },
      {
        plyKey: 'labs_diagnostics',
        iconText: 'Laboratorios',
        inlineText:
          'pagaron por servicios de laboratorio (por ejemplo, análisis de sangre u otras pruebas de diagnóstico)',
      },
      {
        plyKey: 'emergency_urgent_care',
        iconText: 'Emergencias',
        inlineText:
          'al menos una consulta en una clínica de cuidados de urgencia o en una sala de emergencias (Tenga en cuenta que las consultas de cuidados de urgencia son más comunes y menos costosas que las consultas en la sala de emergencias.)',
      },
      {
        plyKey: 'inpatient',
        iconText: 'Hospital',
        inlineText: 'se les admitió en un hospital u otro centro para pacientes hospitalizados',
      },
    ],
    spending_title: 'Tu gasto potencial',
    spending_explanatory_text: '',
    spending_section_introduction:
      '¿Tiene curiosidad sobre qué tipo de servicios de cuidados médicos usa la gente como usted? Esta es la frecuencia con la que las personas como usted usan diferentes tipos de cuidados médicos y cuánto gastan.',
    service_utilization_text: 'Porcentaje de personas como usted que tuvieron {x}:',
    cost_distribution_bar_text: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Para el ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'code',
                },
              ],
              value: '{x}',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value: '% que lo hizo, el total de facturas médicas por estos servicios varió\nde ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'bold',
                },
                {
                  type: 'underline',
                },
                {
                  type: 'code',
                },
              ],
              value: '{x}',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value: ' a ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'bold',
                },
                {
                  type: 'underline',
                },
                {
                  type: 'code',
                },
              ],
              value: '{x}',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value: '. El costo medio fue ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'bold',
                },
                {
                  type: 'underline',
                },
                {
                  type: 'code',
                },
              ],
              value: '{x}',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value: '.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    healthcare_usage_title: 'Su uso de atención médica',
    healthcare_usage_description: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Para ayudarle a comprender su riesgo, aquí le mostramos cuántas personas como usted terminan con niveles bajos, moderados o altos de gastos médicos cada año.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    healthcare_usage_distribution_text: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'La mayoría de las personas como usted terminan con un nivel ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'bold',
                },
                {
                  type: 'code',
                },
              ],
              value: '{x}',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value: ' de gastos médicos.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    healthcare_usage_spending_groups: {
      low: '',
      high: '',
      moderate: '',
    },
    modal_overview_text: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    healthcare_usage_range_descriptions: {
      low: {
        title: 'Bajo',
        tooltip: 'Menos de $ 1,000 en facturas médicas totales.',
        description:
          'Esto significa que es probable que sus facturas médicas totales para el año sean inferiores a $ 1,000. Cuando usted tiene más probabilidad de tener un nivel bajo de gastos médicos, los planes con primas más bajas tendrán un mayor valor.',
      },
      high: {
        title: 'Alto',
        tooltip: 'Más de $ 10,000 en facturas médicas totales.',
        description:
          'Esto significa que es probable que sus facturas médicas totales para el año sean de $10,000 o más.  Cuando usted tiene más probabilidad de tener un nivel alto de gastos médicos, los planes que limitan sus costos de bolsillo serán una mejor combinación para usted, pero el precio aún así tiene que ser adecuado para que le de un buen valor.',
      },
      moderate: {
        title: 'Moderado',
        tooltip: '$ 1,000 - $ 10,000 en facturas médicas totales.',
        description:
          'Esto significa que es probable que sus facturas médicas totales para el año estén entre $ 1,000 y $ 10,000.  Cuando usted tiene más probabilidad de tener un nivel moderado de gastos médicos, el mejor plan para usted logrará un equilibrio entre los ahorros de primas y la cobertura.',
      },
    },
    modal_button_primer_text:
      'Anticipar las necesidades de atención médica puede ser difícil. Picwell predice una variedad de posibles resultados para encontrar los mejores planes para usted.',
    overview_title: 'Cómo hacemos nuestras predicciones',
    user_icon_text:
      'Primero, identificamos a personas como usted que tienen un uso similar de la atención médica.',
    costs_icon_text: 'A continuación, comprendemos sus gastos en diferentes servicios de salud.',
    ranks_icon_text:
      'Por último, los resultados se clasifican en función de sus preferencias proporcionadas.',
    overview_explanatory_text: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Picwell lo ayuda a encontrar el mejor plan mediante el análisis de datos de Personas como usted para predecir sus costos médicos y riesgos.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Así es como procesamos la información que proporcionó en la sección de perfil.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    overview_learn_more: 'Para obtener más información sobre cómo funciona esto, sigue leyendo.',
    rankings_title: 'Tus resultados',
    rankings_text: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Usamos los patrones de atención médica de personas como usted para estimar\n   sus ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'bold',
                },
              ],
              value: 'costos de OOP en un año típico',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value: ' como\n   así como su ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'bold',
                },
              ],
              value: 'riesgo de un alto gasto OOP bajo',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value: ' sus planes disponibles.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Finalmente, combinamos esta información con las ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'bold',
                },
              ],
              value: 'primas del plan',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value: ' y sus ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'bold',
                },
              ],
              value: 'preferencias financieras ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value:
                'para calificar y clasificar las opciones del plan que se encuentran en su página de resultados.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    modal_button_text: 'Explore nuestras predicciones',
    tooltips: {
      p25: '25 porciento: El cuarto menos costoso gasta menos que esto.',
      p50: '50 porciento: La mitad gasta menos de esta cantidad y la otra mitad gasta más.',
      p75: '75 porciento: El cuarto más caro gasta más que esto.',
      medicalSpending:
        'El precio total de los cuidados médicos, incluyendo lo que usted paga y lo que cubre su seguro.',
    },
    service_utilization_caveat_text:
      'Tenga en cuenta que esto refleja el costo del servicio antes de la cobertura del seguro. Consulte los detalles del plan para comprender cómo un plan en particular cubrirá los costos de este servicio.',
  },
};

export default ply;
