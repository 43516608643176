/* eslint-disable no-console */
const logger = {
  log: (...msg) => console.log(msg),
  debug: (...msg) => console.debug(msg),
  info: (...msg) => console.info(msg),
  warn: (...msg) => console.warn(msg),
  error: (...msg) => console.error(msg),
};

export default logger;
