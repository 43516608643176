import React from 'react';

import { TextField } from 'Containers/App/types';
import { AddBenefits } from 'Containers/ViewBenefitPage/types';
import { transformBenefitItems } from 'Containers/ViewBenefitPage/utils';
import { Paragraph } from 'DesignLibrary/atoms';
import Text from 'Shared/Text';
import { SupplementalPlan } from 'Types/entities';
import { toDollars } from 'Utils/helpers';

import { HiddenItem } from './PlanContent/styled';

const suppFieldName = (base_field: string, benefits: AddBenefits): string => {
  switch (benefits.supplemental_term) {
    case 'Voluntary':
      return `benefits_section_add.voluntary_${base_field}`;
    case 'Optional':
      return `benefits_section_add.optional_${base_field}`;
    case 'Supp':
    default:
      return `benefits_section_add.supplemental_${base_field}`;
  }
};

export const addDetails = (addPlan: SupplementalPlan) => {
  const benefits = transformBenefitItems(addPlan) as AddBenefits;

  const benefitValue = (calc_method, amount, factor, how_determined, max_benefit, bundled) => {
    if (calc_method === 'Flat') {
      return toDollars(amount);
    }
    if (factor) {
      if (max_benefit) {
        return (
          <Text
            field={
              how_determined === 'EligiblePay'
                ? 'benefits_section_add.multiple_of_eligible_pay_capped'
                : 'benefits_section_add.multiple_of_salary_capped'
            }
            vars={{ factor, max: toDollars(max_benefit) }}
          />
        );
      }

      return (
        <Text
          field={
            how_determined === 'EligiblePay'
              ? 'benefits_section_add.multiple_of_eligible_pay'
              : 'benefits_section_add.multiple_of_salary'
          }
          vars={{ factor }}
        />
      );
    }
    if (bundled) {
      return <Text field={suppFieldName('bundled_amount', benefits) as TextField} />;
    }

    return null;
  };

  const basicValue = benefitValue(
    benefits.basic_calc_method,
    benefits.flat_amount,
    benefits.salary_multiple,
    benefits.how_determined,
    benefits.max_benefit,
    false,
  );

  const supplementalValue = benefitValue(
    benefits.supplemental_calc_method,
    benefits.supplemental_limit,
    benefits.supplemental_multiple,
    benefits.how_determined,
    benefits.supplemental_limit,
    benefits.supplemental_coverage_bundled,
  );

  return (
    <section className="benefits hidden-benefits add-plan">
      {basicValue && (
        <HiddenItem className="add-basic">
          <Paragraph size="mini" className="key">
            <Text field="benefits_section_add.basic_benefit_amount" />
          </Paragraph>

          <Paragraph size="mini" className="value">
            {basicValue}
          </Paragraph>
        </HiddenItem>
      )}

      {supplementalValue && (
        <HiddenItem className="add-supplemental">
          <Paragraph size="mini" className="key">
            <Text field={suppFieldName('benefit_amount', benefits) as TextField} />
          </Paragraph>

          <Paragraph size="mini" className="value">
            {supplementalValue}
          </Paragraph>
        </HiddenItem>
      )}
    </section>
  );
};

export const getAddTagList = (addBenefits: AddBenefits): JSX.Element[] => {
  const tags: JSX.Element[] = [];

  if (addBenefits.benefits === 'Basic' || addBenefits.benefits === 'Both') {
    tags.push(<Text field="benefits_section_add.basic_benefit_amount" />);
  }

  if (addBenefits.benefits === 'Supp' || addBenefits.benefits === 'Both') {
    tags.push(<Text field={suppFieldName('benefit_amount', addBenefits) as TextField} />);
  }

  return tags;
};

export const addNotes = (addPlan: SupplementalPlan, hasDependentCoverage: boolean) => {
  const benefits = transformBenefitItems(addPlan) as AddBenefits;

  return (
    <section className="benefits plan-notes add-plan">
      {benefits.supplemental_coverage_bundled && (
        <>
          <h4 className="bundled-insurance">
            <Text field="benefits_section_add.bundled_insurance_header" />
          </h4>
          <Paragraph size="mini" className="bundled-insurance">
            <Text field={suppFieldName('bundled_insurance_body', benefits) as TextField} />
          </Paragraph>
        </>
      )}

      {hasDependentCoverage && (
        <>
          <h4 className="dependent-coverage">
            <Text field="benefits_section_add.dependent_coverage_header" />
          </h4>
          <Paragraph size="mini" className="dependent-coverage">
            <Text field="benefits_section_add.dependent_coverage_body" />
          </Paragraph>
        </>
      )}

      <h4 className="evidence-of-insurability">
        <Text field="benefits_section_add.eoi_header" />
      </h4>
      <Paragraph size="mini" className="evidence-of-insurability">
        <Text field="benefits_section_add.eoi_body" />
      </Paragraph>
    </section>
  );
};
