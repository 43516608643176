import { get } from 'lodash';
import React from 'react';

import { ContentfulTextNote } from 'ContentfulWrappers/ContentfulTextNote';
import { useTextContext } from 'Contexts/textContext';
import InfoBar, { InfoBarProps } from 'DesignLibrary/molecules/InfoBar';

export interface DisclaimersProps {
  client: string;
  planId: string;
  hasSpouse: boolean;
}

const Disclaimers = ({ client, planId, hasSpouse }: DisclaimersProps) => {
  const { retrieveContentfulData } = useTextContext();

  // Get disclaimers from Contentful
  const customizations = retrieveContentfulData<Record<string, string>[]>('plan_customizations', []);

  const planCustomizationsIndex = customizations.findIndex((item) => item.plan_id === planId);
  const planCustomizations = customizations[planCustomizationsIndex];

  const planDisclaimers = planCustomizations?.plan_page_disclaimers as unknown as Record<string, string>[];

  const clientDisclaimer = retrieveContentfulData<Record<string, string>>(
    'health_section.client_disclaimers.viewPlanPageSpousal',
  );

  const renderTextNote = () => {
    if (planCustomizations) {
      return <ContentfulTextNote sectionKey="plan_customizations" sectionIndex={planCustomizationsIndex} />;
    }
    return null;
  };

  return (
    <>
      {clientDisclaimer && clientDisclaimer.title && hasSpouse && (
        <InfoBar
          id="premium-disclaimer"
          header={get(clientDisclaimer, 'title')}
          text={clientDisclaimer.text}
          color="blue"
        />
      )}
      {planDisclaimers?.map((disclaimer, disclaimerIdx) => (
        <React.Fragment key={`${client}-${planId}-disclaimer-${disclaimerIdx}`}>
          <InfoBar
            id={`${client}-${planId}-disclaimer-${disclaimerIdx}`}
            header={disclaimer.header}
            text={disclaimer.description}
            color={(disclaimer.barColor as InfoBarProps['color']) || 'yellow'}
          />
        </React.Fragment>
      ))}
      {renderTextNote()}
    </>
  );
};

export default Disclaimers;
