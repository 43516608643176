import { uniqueId } from 'lodash';
import React, { useState, useEffect } from 'react';

import { Paragraph } from 'DesignLibrary/atoms';
import { PageLayout } from 'DesignLibrary/atoms/PageLayout';
import Text from 'Shared/Text';
import { Household } from 'Types/entities';

import {
  getCapacityToPayString,
  getDependantString,
  getIncomeString,
  getInpatientDaysString,
  getPCPVisitsString,
  getPrescriptionsString,
  getSpecialistVisitString,
  getSpouseString,
} from './helpers';
import { Fill, ProgressBarContainer, Bar, Bubble } from './styled';

function getProgressText(percent: number) {
  const textField =
    percent <= 60 ? 'health_section.loading_statuses.50%' : 'health_section.loading_statuses.100%';

  return <Text field={textField} />;
}

interface LoadingScreenProps {
  household: Household;
  handleOnLoadingComplete: () => void;
  isLoading: boolean;
}

export const LoadingScreen = ({ household, handleOnLoadingComplete, isLoading }: LoadingScreenProps) => {
  const [progress, setProgress] = useState(0);

  // How often we should increment progress to match up LOADING_TIMER
  const LOADING_TIMER = 4000;
  const INTERVAL_STEPS = 10;
  const INTERVAL_TIME = LOADING_TIMER / INTERVAL_STEPS;

  // Bubble animation timing to match with the LOADING_TIMER
  const BUBBLE_DELAY = 1200;
  const BUBBLE_ANIMATION = LOADING_TIMER - 700 - BUBBLE_DELAY;

  useEffect(() => {
    if (progress >= 120 && !isLoading) {
      handleOnLoadingComplete();
    }
  }, [progress]);

  useEffect(() => {
    const progressTimer = setInterval(() => {
      setProgress((prev) => prev + INTERVAL_STEPS);
    }, INTERVAL_TIME);

    return () => {
      clearInterval(progressTimer);
    };
  }, []);

  const filler = <Fill data-testid="progress-bar" percent={progress} />;

  const bubbleValues = [
    getSpouseString(household),
    getDependantString(household),
    getPrescriptionsString(household),
    getIncomeString(household),

    getPCPVisitsString(household),
    getSpecialistVisitString(household),
    getInpatientDaysString(household),

    // Uncomment when comm api is updated
    // getMentalHealthVisitsString(household),

    getCapacityToPayString(household),
  ];

  return (
    <PageLayout>
      <ProgressBarContainer bubbleDuration={BUBBLE_ANIMATION} bubbleDelay={BUBBLE_DELAY}>
        <Paragraph size="small">{getProgressText(progress)}</Paragraph>
        <Bar className="bar">{filler}</Bar>
        <div className="bubbles">
          {bubbleValues.map((bubble) => (
            <Bubble key={`bubble-${uniqueId()}}`}>
              <Paragraph size="mini" color="--primary-blue" weight="bold">
                {bubble}
              </Paragraph>
            </Bubble>
          ))}
        </div>
      </ProgressBarContainer>
    </PageLayout>
  );
};
