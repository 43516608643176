import styled, { css } from 'styled-components';

import { hideAccessibleCheckmark, focusSingle } from 'DesignLibrary/utils';

type CheckboxLabelProps = {
  interactive: boolean;
  color: string;
};

type CheckboxProps = {
  sizing: 'small' | 'normal';
};

export const CheckboxLabel = styled.label<CheckboxLabelProps>`
  ${({ interactive }) =>
    !interactive &&
    css`
      pointer-events: none;
    `}
  position: relative;

  input[type='checkbox'] {
    ${hideAccessibleCheckmark}

    &:checked + .pseudo-checkbox {
      .checkbox-button {
        background: var(${({ color }) => color});
        border-color: var(${({ color }) => color});
      }
    }

    &:enabled:not(:checked) + .pseudo-checkbox {
      &:hover {
        .checkbox-button {
          border-color: var(--text-black);
        }
      }
    }

    &:disabled + .pseudo-checkbox {
      cursor: default;
      opacity: 0.5;
    }

    &:focus + .pseudo-checkbox .checkbox-button {
      ${focusSingle}
    }
  }
`;

export const PseudoCheckbox = styled.section<CheckboxProps>`
  cursor: pointer;
  display: flex;
  align-items: center;

  .checkbox-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: calc(var(--border-radius-xs) * 0.5);
    background: var(--background-white);
    border: var(--input-border);
    width: var(--sizing-lg);
    height: var(--sizing-lg);

    ${({ sizing }) =>
      sizing === 'small' &&
      css`
        width: var(--sizing-md);
        height: var(--sizing-md);
      `}

    span {
      display: inherit;
      align-items: inherit;
      justify-content: inherit;
    }
  }

  .paragraph {
    margin-left: calc(var(--sizing-xs) * 1.5);
  }
`;
