import { fromJS } from 'immutable';
import { isEmpty } from 'lodash';

import { Recommendation } from 'Types/entities';
import * as API_TYPES from 'Utils/apiTypes';
import { GetRecommendationsResponse, PeopleLikeYou } from 'Utils/apiTypes';
import { TypedMap } from 'Utils/immutable-types';
import { wrapImmutableReducerInPojoTranslation } from 'Utils/reducers';

import {
  CHANGE_FORM_VALUE,
  GET_PEOPLE_LIKE_YOU_FAILURE,
  GET_PEOPLE_LIKE_YOU_SUCCESS,
  GET_RESULTS_PAGE_DATA,
  GET_RECOMMENDATIONS_EPHEMERAL_REQUEST,
  GET_RECOMMENDATIONS_FAILURE,
  GET_RECOMMENDATIONS_SUCCESS,
  SELECT_HEALTH_PLAN,
  WAIVE_HEALTH_COVERAGE,
  SET_HOUSEHOLD_EXTERNAL_ID,
  SELECT_HEALTH_PLAN_SUCCESS,
  SELECT_HEALTH_PLAN_FAILURE,
  SELECT_HEALTH_PLAN_STORE_ONLY,
} from './constants';
import { EnteredPlan } from './types';
import { PREMIUM_PERIOD } from '../../constants';

const defaultPlanEntryData: EnteredPlan = {
  isHsaEligible: false,
  employerHsaContribution: 0,
  isHraEligible: false,
  employerHraContribution: 0,
  premiumAmount: '',
  premiumPeriod: PREMIUM_PERIOD.MONTHLY,
  isDeductibleComprehensive: true,
  isOopMaxComprehensive: true,
  deductibleAmount: '',
  drugDeductibleAmount: '',
  healthDeductibleAmount: '',
  oopMaxAmount: '',
  drugOopMaxAmount: '',
  healthOopMaxAmount: '',
  isDirty: false,
  benefitGroups: {
    office: {
      title: "If you visit a healthcare provider's office for",
      benefits: {
        primaryCare: {
          costSharingType: 'copay',
          costSharingAmount: null,
          isSubjectToDeductible: true,
          displayText: 'A primary care visit',
          serffCode: '1',
        },
        specialist: {
          costSharingType: 'copay',
          costSharingAmount: null,
          isSubjectToDeductible: true,
          displayText: 'A specialist visit',
          serffCode: '2',
        },
        preventiveCare: {
          costSharingType: 'coinsurance',
          costSharingAmount: 0,
          isSubjectToDeductible: false,
          displayText: 'Preventive care',
          serffCode: '38',
        },
      },
    },
    emergency: {
      title: 'If you need immediate medical attention',
      benefits: {
        urgentCare: {
          costSharingType: 'copay',
          costSharingAmount: null,
          isSubjectToDeductible: true,
          displayText: 'Urgent care',
          serffCode: '13',
        },
        emergencyCare: {
          costSharingType: 'copay',
          costSharingAmount: null,
          isSubjectToDeductible: true,
          displayText: 'Emergency care',
          serffCode: '15',
        },
      },
    },
    testing: {
      title: 'If you have a test',
      benefits: {
        imaging: {
          costSharingType: 'copay',
          costSharingAmount: null,
          isSubjectToDeductible: true,
          displayText: 'Imaging',
          serffCode: '37',
        },
        laboratory: {
          costSharingType: 'copay',
          costSharingAmount: null,
          isSubjectToDeductible: true,
          displayText: 'Laboratory',
          serffCode: '48',
        },
      },
    },
    outpatientSurgery: {
      title: 'If you have outpatient surgery',
      benefits: {
        services: {
          costSharingType: 'copay',
          costSharingAmount: null,
          isSubjectToDeductible: true,
          displayText: 'Physician and surgical services',
          serffCode: '4',
        },
        outpatientFacilityFee: {
          costSharingType: 'copay',
          costSharingAmount: null,
          isSubjectToDeductible: true,
          displayText: 'Facility fee',
          serffCode: '5',
        },
      },
    },
    hospital: {
      title: 'If you have a hospital stay',
      benefits: {
        hospitalFacilityFee: {
          costSharingType: 'copay',
          costSharingAmount: null,
          isSubjectToDeductible: true,
          displayText: 'Facility fee',
          serffCode: '17',
        },
      },
    },
    drug: {
      title: 'If you need drugs to heal your illness or condition...',
      benefits: {},
    },
  },
};

export interface CommercialRoutesReducerState {
  state_code: string;
  external_id: string;
  isLoading: boolean;
  isDirty: boolean;
  error: Error | null;
  recommendations: Recommendation[];
  annual_sihra_contribution: number;
  selectedHealthPlan: Recommendation | Record<string, never>;
  isCoverageWaived: boolean;
  isPLYLoading: boolean;
  people_like_you: PeopleLikeYou | Record<string, never>;
  entered_plan: EnteredPlan;
  enrollment:
    | {
        plan_id: string;
        network_id: string;
      }
    | Record<string, never>;
  isEnrollmentLoading: boolean;
  enrollmentError: Error | null;
}

export type CommercialRoutesState = TypedMap<CommercialRoutesReducerState>;

export const initialState: CommercialRoutesState = fromJS({
  state_code: '',
  external_id: '',
  isLoading: false,
  isLoadingIncentives: false,
  isDirty: false,
  error: null,
  recommendations: [],
  annual_sihra_contribution: 0,
  selectedHealthPlan: {},
  isCoverageWaived: false,
  isPLYLoading: false,
  people_like_you: {},
  entered_plan: defaultPlanEntryData,
  enrollment: {},
  isEnrollmentLoading: false,
  enrollmentError: null,
});

function commercialAppReducer(state = initialState, action): typeof initialState {
  switch (action.type) {
    case CHANGE_FORM_VALUE:
      return changeFormValue(state, action);
    case GET_PEOPLE_LIKE_YOU_SUCCESS:
      return getPeopleLikeYouSuccess(state, action);
    case GET_PEOPLE_LIKE_YOU_FAILURE:
      return getPeopleLikeYouFailure(state, action);
    case GET_RESULTS_PAGE_DATA:
      return getRecommendations(state);
    case GET_RECOMMENDATIONS_SUCCESS:
      return getRecommendationsSuccess(state, action);
    case GET_RECOMMENDATIONS_FAILURE:
      return getRecommendationsFailure(state, action);
    case SELECT_HEALTH_PLAN:
      return selectHealthPlan(state, action);
    case WAIVE_HEALTH_COVERAGE:
      return waiveHealthCoverage(state);
    case SET_HOUSEHOLD_EXTERNAL_ID:
      return setHouseholdExternalId(state, action);
    case GET_RECOMMENDATIONS_EPHEMERAL_REQUEST:
      return getRecommendationsWithEphemeralPlan(state);
    case SELECT_HEALTH_PLAN_SUCCESS:
      return selectHealthPlanSuccess(state, action);
    case SELECT_HEALTH_PLAN_FAILURE:
      return selectHealthPlanFailure(state, action);
    case SELECT_HEALTH_PLAN_STORE_ONLY:
      return selectHealthPlanStoreOnly(state, action);

    default:
      return state;
  }
}

function changeFormValue(
  state: CommercialRoutesState,
  action: {
    name: keyof CommercialRoutesReducerState;
    value: string | EnteredPlan;
  },
) {
  return state.set(action.name, fromJS(action.value));
}

function getRecommendations(state: CommercialRoutesState) {
  return state.set('isLoading', true);
}

function getRecommendationsWithEphemeralPlan(state: CommercialRoutesState) {
  const enteredPlan = state.get('entered_plan').toJS();
  enteredPlan.isDirty = true;

  return state
    .set('selectedHealthPlan', fromJS({})) // reset selected plan
    .set('isLoading', true)
    .set('isDirty', true)
    .set('isCoverageWaived', false)
    .set('error', null)
    .set('entered_plan', fromJS(enteredPlan));
}

export function getRecommendationsSuccess(
  state: CommercialRoutesState,
  action: {
    response: GetRecommendationsResponse;
    storeHousehold: boolean;
  },
) {
  if (action.storeHousehold) localStorage.setItem('household_id', action.response.household_id);

  const enteredPlan = state.get('entered_plan').toJS();
  enteredPlan.isDirty = false;

  const selectedPlan = state.get('selectedHealthPlan').toJS();

  const newState = state
    .set('isLoading', false)
    .set('recommendations', fromJS(action.response.recommendations))
    .set('annual_sihra_contribution', fromJS(action.response.annual_sihra_contribution))
    .set('entered_plan', fromJS(enteredPlan));

  if (isEmpty(selectedPlan)) {
    return newState.set('selectedHealthPlan', fromJS({})); // reset selected plan
  }

  return newState;
}

function getRecommendationsFailure(
  state: CommercialRoutesState,
  action: {
    error: Error | null;
  },
) {
  return state
    .set('selectedHealthPlan', fromJS({})) // reset selected plan
    .set('isLoading', false)
    .set('error', action.error)
    .set('recommendations', []);
}

function selectHealthPlan(
  state: CommercialRoutesState,
  action: {
    plan: Recommendation | Record<string, never>;
  },
) {
  return state
    .set('selectedHealthPlan', fromJS(action.plan))
    .set('isCoverageWaived', false)
    .set('isEnrollmentLoading', true);
}

function selectHealthPlanStoreOnly(
  state: CommercialRoutesState,
  action: {
    plan: Recommendation | Record<string, never>;
  },
) {
  return state.set('selectedHealthPlan', action.plan);
}

function waiveHealthCoverage(state: CommercialRoutesState) {
  return state.set('isCoverageWaived', true);
}

function setHouseholdExternalId(
  state: CommercialRoutesState,
  action: {
    id: string;
  },
) {
  return state.set('external_id', action.id);
}

function getPeopleLikeYouSuccess(
  state: CommercialRoutesState,
  action: {
    response: PeopleLikeYou;
  },
) {
  return state.set('isPLYLoading', false).set('people_like_you', fromJS(action.response));
}

function getPeopleLikeYouFailure(
  state: CommercialRoutesState,
  action: {
    error: Error | null;
  },
) {
  return state.set('isPLYLoading', false).set('error', action.error);
}

function selectHealthPlanSuccess(
  state: CommercialRoutesState,
  action: {
    response: API_TYPES.UpdateHouseholdResponse;
  },
) {
  const selectedPlan = state.get('selectedHealthPlan').toJS();
  // If we have a selecedHealthPlan stored set that plan in enrollment, else reset enrollment
  if (!isEmpty(selectedPlan)) {
    return state.set('enrollment', fromJS(action.response)).set('isEnrollmentLoading', false);
  }
  return state.set('enrollment', fromJS({})).set('isEnrollmentLoading', false);
}

function selectHealthPlanFailure(
  state: CommercialRoutesState,
  action: {
    error: Error | null;
  },
) {
  return state.set('enrollmentError', fromJS(action.error)).set('isEnrollmentLoading', false);
}

const pojoCommercialAppReducer = wrapImmutableReducerInPojoTranslation(commercialAppReducer);

export default pojoCommercialAppReducer;
