import React from 'react';

import { TextField } from 'Containers/App/types';
import { ShortTermDisabilityBenefits } from 'Containers/ViewBenefitPage/types';
import { transformBenefitItems } from 'Containers/ViewBenefitPage/utils';
import { useTextContext } from 'Contexts/textContext';
import { Paragraph } from 'DesignLibrary/atoms';
import RichText from 'Shared/RichText';
import Text from 'Shared/Text';
import { SupplementalPlan } from 'Types/entities';
import { toDollars } from 'Utils/helpers';

export const shortTermDisabilityDescription = (plan: SupplementalPlan, employerName: string) => {
  const { retrieveContentfulData } = useTextContext();

  const benefits = transformBenefitItems(plan) as ShortTermDisabilityBenefits;

  const hasSplitBenefits = benefits.basic_divided;
  const hasWeeklyMax = benefits.has_weekly_max;

  const salaryOrEP =
    benefits.how_determined === 'EligiblePay'
      ? retrieveContentfulData<string>('benefits_section_disability.std_eligible_pay')
      : retrieveContentfulData<string>('benefits_section_disability.std_salary');

  let descriptionLabelKey = 'benefits_section_disability.std_employer_paid_label';
  let descriptionKey = 'benefits_section_disability.std_employer_paid_description';

  if (benefits.benefits === 'Voluntary') {
    descriptionLabelKey = 'benefits_section_disability.std_voluntary_label';
    descriptionKey = 'benefits_section_disability.std_voluntary_description';
  }

  let coverageKey = '';
  if (hasSplitBenefits) {
    coverageKey = hasWeeklyMax
      ? 'benefits_section_disability.std_coverage_split_with_weekly_max'
      : 'benefits_section_disability.std_coverage_split';
  } else {
    coverageKey = hasWeeklyMax
      ? 'benefits_section_disability.std_coverage_whole_with_weekly_max'
      : 'benefits_section_disability.std_coverage_whole';
  }

  const coverageText = (
    <Text
      field={coverageKey as TextField}
      vars={{
        salaryOrEP,
        percent: benefits.basic_percent_whole,
        weeks: benefits.basic_weeks_whole,
        percentFirst: benefits.basic_percent_first,
        weeksFirst: benefits.basic_weeks_first,
        percentSecond: benefits.basic_percent_second,
        weeksSecond: benefits.basic_weeks_second,
        weeklyMax: toDollars(benefits.weekly_max),
      }}
    />
  );

  const descriptionLabel = <RichText field={descriptionLabelKey as TextField} />;
  const description = (
    <Text field={descriptionKey as TextField} vars={{ coverage: coverageText, employerName }} />
  );

  let buyUpContent: JSX.Element | null = null;
  if (benefits.benefits === 'CoreBuyUp') {
    const additionalCoverageHeader = (
      <RichText field="benefits_section_disability.std_additional_coverage_header" />
    );

    let buyUpKey = '';
    if (hasWeeklyMax) {
      buyUpKey = 'benefits_section_disability.std_buy_up_with_weekly_max';
    } else {
      buyUpKey = 'benefits_section_disability.std_buy_up';
    }
    const buyUp = (
      <Text
        field={buyUpKey as TextField}
        vars={{
          maxPercent: benefits.supplemental_salary_max_percent,
          weeklyMax: toDollars(benefits.supplemental_weekly_max),
          salaryOrEP,
        }}
      />
    );

    buyUpContent = (
      <>
        <br />
        {additionalCoverageHeader}
        {buyUp}
      </>
    );
  }

  let coverageBeginsOnKey = '';
  if (benefits.illness_wait === benefits.injury_wait) {
    coverageBeginsOnKey = 'benefits_section_disability.std_coverage_begins_on';
  } else {
    coverageBeginsOnKey = 'benefits_section_disability.std_coverage_begins_on_split';
  }

  const coverageBeginsOn = (
    <Text
      field={coverageBeginsOnKey as TextField}
      vars={{ dayInjury: benefits.injury_wait, dayIllness: benefits.illness_wait }}
    />
  );

  return (
    <Paragraph as="div" lineHeight={1.75} size="small" className="plan-description">
      {descriptionLabel}
      {description}
      {buyUpContent}
      <br />
      {coverageBeginsOn}
    </Paragraph>
  );
};
