import React from 'react';

import PrivacyOptions from 'Assets/privacy-options.svg';
import { useFeatureFlagContext } from 'Contexts/featureFlagContext';
import { Paragraph } from 'DesignLibrary/atoms';
import RichText from 'Shared/RichText';
import Text from 'Shared/Text';

import { FooterContainer } from './styled';
import { YOUR_PRIVACY_CHOICES_URL } from '../../../constants';

export interface FooterProps {
  isNextClicked: boolean;
}

export const Footer = ({ isNextClicked }: FooterProps) => {
  const { is_footer_test_enabled } = useFeatureFlagContext();
  const currentYearString = `${new Date().getFullYear()}${is_footer_test_enabled ? ' | ' : ''}`;

  return (
    <FooterContainer isNextClicked={isNextClicked}>
      <Paragraph size="mini">
        <RichText field="unified_landing_page.footer.disclaimer" />
        <a href={YOUR_PRIVACY_CHOICES_URL} target="_blank" rel="noreferrer">
          <img src={PrivacyOptions} alt="Your Privacy Options" width={30} height={20} />
          <Text field="unified_landing_page.footer.do_not_sell_text" />
        </a>
      </Paragraph>
      <Paragraph size="mini">
        <Text field="unified_landing_page.footer.copyright" vars={{ x: currentYearString }} />
      </Paragraph>
    </FooterContainer>
  );
};
