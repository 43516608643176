import React from 'react';

import { TextField } from 'Containers/App/types';
import RichText from 'Shared/RichText';
import Text from 'Shared/Text';
import { FieldDataTypes } from 'Types/entities';
import { toDollars } from 'Utils/helpers';

interface FbsDetailProps {
  type?: FieldDataTypes;
  data?: string | number | React.ReactNode;
}

const FbsDetail = ({ type, data }: FbsDetailProps) => {
  switch (type) {
    case FieldDataTypes.STRING:
    case FieldDataTypes.TEXTFIELD:
      return data;
    case FieldDataTypes.RICHTEXT:
      return <RichText noWrapper={false} field={data as TextField} />;
    case FieldDataTypes.REFERENCE:
      return <Text field={`fbs_tokens.${data}` as TextField} />;
    case FieldDataTypes.USD:
      return toDollars(data?.toString());
    default:
      return data || ' ';
  }
};

export default FbsDetail;
