import { Flex } from '@chakra-ui/react';
import React, { useState } from 'react';

import { Button, FadeUp, H6, Icon, Paragraph } from 'DesignLibrary/atoms';
import RichText from 'Shared/RichText';
import { formatDate } from 'Utils/helpers';

import { Content, EnrollBar, Bar, Dates, OpenEnrollmentBarProps } from './styled';

export const ONE_DAY_IN_MS = 24 * 60 * 60 * 1000;

export const OpenEnrollmentBar = ({
  isNextClicked,
  openEnrollmentStartDate,
  openEnrollmentEndDate,
}: OpenEnrollmentBarProps) => {
  const [isOpen, setIsOpen] = useState(true);

  if (!openEnrollmentStartDate || !openEnrollmentEndDate) {
    return null;
  }

  // Dates
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const start = new Date(`${openEnrollmentStartDate.substring(0, 10)}T00:00:00.000`);
  const end = new Date(`${openEnrollmentEndDate.substring(0, 10)}T00:00:00.000`);

  // Date Math
  const totalDaysInOE = Math.ceil((end.getTime() - start.getTime()) / ONE_DAY_IN_MS);
  const daysUntilOEStart = Math.ceil((start.getTime() - today.getTime()) / ONE_DAY_IN_MS);

  // on last day, daysUntilOEEnd === 1
  const daysUntilOEEnd = Math.ceil((end.getTime() - today.getTime()) / ONE_DAY_IN_MS);

  const isBeforeOE = daysUntilOEStart > 0;
  const isAfterOE = daysUntilOEEnd <= 0;

  if (isAfterOE) {
    return null;
  }

  const percentComplete = Math.max(((totalDaysInOE - daysUntilOEEnd) / totalDaysInOE) * 100, 0);

  const dataTestId = isBeforeOE ? 'before-oe-content' : 'during-oe-content';

  // Formatted Display Dates
  const startFormatted = formatDate(start, 'en-US', {
    month: 'short',
    day: 'numeric',
  });
  const endFormatted = formatDate(end, 'en-US', {
    month: 'short',
    day: 'numeric',
  });

  // Bar Color
  let barColor: 'green' | 'yellow' | 'red' = 'green';
  if (percentComplete >= 65) barColor = 'yellow';
  if (percentComplete >= 90) barColor = 'red';

  // Icon Type
  let iconType: 'Flag' | 'Clock' | 'Warning' = 'Clock';
  if (isBeforeOE) iconType = 'Flag';
  if (percentComplete >= 75) iconType = 'Warning';

  const getDaysLeftText = () => {
    if (isBeforeOE) {
      return (
        <Paragraph size="small" className="days-left" data-testid="before-oe-text">
          <RichText
            vars={[daysUntilOEStart]}
            field="unified_landing_page.open_enrollment_bar_before_oe_text"
          />
        </Paragraph>
      );
    }

    if (percentComplete >= 75) {
      return (
        <Paragraph size="small" className="days-left" data-testid="ending-soon-text">
          <RichText
            vars={[daysUntilOEEnd]}
            field="unified_landing_page.open_enrollment_bar_days_left_ending_soon_text"
          />
        </Paragraph>
      );
    }

    return (
      <Paragraph size="small" className="days-left" data-testid="days-left-text">
        <RichText vars={[daysUntilOEEnd]} field="unified_landing_page.open_enrollment_bar_days_left_text" />
      </Paragraph>
    );
  };

  return (
    <EnrollBar>
      <FadeUp isOpen={isOpen} delay={2000} timeout={1000}>
        <Content
          isNextClicked={isNextClicked}
          openEnrollmentStartDate={openEnrollmentStartDate}
          openEnrollmentEndDate={openEnrollmentEndDate}
          data-testid={dataTestId}
        >
          <Flex className="title" align="center" justify="space-between">
            <Flex align="center" gap="2">
              <Icon type={iconType} weight="fill" color="--text-black" />
              {getDaysLeftText()}
            </Flex>

            <Button
              onClick={() => setIsOpen(false)}
              size="xsmall"
              buttonType="standalone"
              iconLeft="X"
              iconProps={{
                color: '--text-gray-placeholder',
              }}
            />
          </Flex>
          <div className="bar-wrapper" aria-hidden="true">
            <Bar color={barColor} isBeforeOE={isBeforeOE} percent={percentComplete}>
              <div className="active-bar" />
            </Bar>
            <div className="dates">
              <Dates color={barColor}>
                <H6 as="span" weight="bold" color={isBeforeOE ? '--text-gray' : '--primary-white'}>
                  {startFormatted}
                </H6>
              </Dates>
              <Dates>
                <H6 as="span" weight="bold">
                  {endFormatted}
                </H6>
              </Dates>
            </div>
          </div>
        </Content>
      </FadeUp>
    </EnrollBar>
  );
};
