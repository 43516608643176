import { Stack } from '@chakra-ui/react';
import React from 'react';

import { RangeDescription } from 'ContentfulDefaults/types/ply';
import { useTextContext } from 'Contexts/textContext';
import { H4, Paragraph } from 'DesignLibrary/atoms';
import RichText from 'Shared/RichText';
import Text from 'Shared/Text';
import { PeopleLikeYou } from 'Utils/apiTypes';

import { LowMedHighDist } from './LowMedHighDistribution';
import { DollarIcon, UsageContainer } from './styled';
import SectionWrapper from '../_shared/SectionWrapper';

const renderMedicalCostsTip = () => ({
  text: (
    <p>
      {' '}
      <Text field="people_like_you.tooltips.medicalSpending" />{' '}
    </p>
  ),
});

const getMaxKey = (obj) => Object.keys(obj).reduce((a, b) => (obj[a] > obj[b] ? a : b));
// const getMostCommonGroup = (utilizationLevelDist) => getMaxKey(utilizationLevelDist);

interface HealthcareUsageProps {
  plyData: PeopleLikeYou | Record<string, never>;
}

const HealthcareUsage = ({ plyData }: HealthcareUsageProps) => {
  const { retrieveContentfulData } = useTextContext();

  const utilizationLevelDist = plyData.utilization_level_dist;
  const modalGroup = getMaxKey(utilizationLevelDist);
  const groupDesc = retrieveContentfulData<RangeDescription>(
    'people_like_you.healthcare_usage_range_descriptions',
  )[modalGroup].description;
  const groupTitle = retrieveContentfulData<RangeDescription>(
    'people_like_you.healthcare_usage_range_descriptions',
  )[modalGroup].title;

  return (
    <SectionWrapper number={1}>
      <UsageContainer>
        <Stack gap={[4, 6]}>
          <Stack>
            <H4 as="h2">
              <Text field="people_like_you.healthcare_usage_title" />
            </H4>
            <Paragraph lineHeight={1.75}>
              <RichText
                field="people_like_you.healthcare_usage_description"
                toolTips={[renderMedicalCostsTip()]}
              />
            </Paragraph>
          </Stack>
          <LowMedHighDist
            low={utilizationLevelDist.low}
            medium={utilizationLevelDist.moderate}
            high={utilizationLevelDist.high}
          />
          <Paragraph lineHeight={1.75}>
            <RichText
              field="people_like_you.healthcare_usage_distribution_text"
              vars={[
                <>
                  &nbsp;
                  <DollarIcon group={groupTitle.toLowerCase()}>{modalGroup}</DollarIcon>
                  &nbsp;
                </>,
                groupDesc,
              ]}
            />
          </Paragraph>
        </Stack>
      </UsageContainer>
    </SectionWrapper>
  );
};

export default HealthcareUsage;
