import styled from 'styled-components';

import { media } from 'DesignLibrary/global';

export const ContentfulContainer = styled.div`
  padding-top: var(--sizing-sm);

  ${media.TABLET`
    padding-top: var(--sizing-md);
  `}

  & > * + * {
    margin-top: var(--sizing-xs);
  }
`;
