import styled, { css } from 'styled-components';

import { ParagraphMiniStyle, ParagraphSmallStyle } from 'DesignLibrary/atoms';
import { breakpoint } from 'DesignLibrary/global';

export const ChartContainer = styled.div`
  min-width: 0;
  position: relative;
`;

export const LoadingWrapper = styled.div`
  border: var(--border);
  border-radius: var(--border-radius-xs);
  position: relative;
  background: var(--background-gray-light);
  padding-bottom: var(--sizing-sm);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SpinnerContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--colors-opaque-20);
`;

export const ChartWrapper = styled.div`
  border: var(--border-thick);
  border-radius: var(--border-radius-xs) var(--border-radius-xs) 0 0;
  position: relative;
  background: var(--background-gray-light);
  padding-bottom: var(--sizing-sm);
  overflow: hidden;

  ${breakpoint.TABLET} {
    border-radius: var(--border-radius-xs);
  }
`;

export const ChartTitle = styled.div`
  ${ParagraphSmallStyle('--text-gray')}
  background: var(--background-gray);
  padding: var(--sizing-xs);
  line-height: 1;
  border-radius: 0 0 var(--border-radius-xs) var(--border-radius-xs);
  border: var(--border-thick);
  border-top: 0;
  text-align: right;

  ${breakpoint.TABLET} {
    position: absolute;
    z-index: 100;
    top: 0;
    right: 0;
    display: inline-block;
    border-bottom-right-radius: 0;
    border-top-right-radius: var(--border-radius-xs);
    border: var(--border-thick);
    padding: var(--sizing-2xs) var(--sizing-xs);
  }

  b {
    color: var(--text-black);
    font-weight: 700;
  }
`;

export const LegendContainer = styled.div`
  display: flex;
  gap: var(--sizing-xs) var(--sizing-sm);
  flex-wrap: wrap;
  margin-top: var(--sizing-sm);
`;

interface LegendItemProps {
  legendType: 'line' | 'dash' | 'block';
  color: string;
}

export const LegendItem = styled.div<LegendItemProps>`
  ${ParagraphMiniStyle()}
  display: flex;
  align-items: center;
  gap: var(--sizing-xs);

  &:before {
    content: '';
    display: block;
    width: var(--sizing-sm);

    ${({ legendType, color }) => {
      switch (legendType) {
        case 'line':
          return css`
            height: 1px;
            border-top: 3px solid var(${color});
          `;

        case 'dash':
          return css`
            height: 1px;
            border-top: 3px dashed var(${color});
          `;

        case 'block':
          return css`
            height: var(--sizing-sm);
            background: var(${color});
            border-radius: 4px;
          `;

        default:
          return null;
      }
    }}
  }
`;

export const Disclaimer = styled.div`
  margin-top: var(--sizing-sm);
`;
