import styled from 'styled-components';

export const TableContainer = styled.div``;

export const TableHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: var(--sizing-xs);
  background: var(--background-gray);
  border-bottom: var(--border);
`;
