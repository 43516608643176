import styled from 'styled-components';

import { breakpoint } from 'DesignLibrary/global';

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--sizing-sm);
  margin-bottom: var(--sizing-sm);
  position: relative;
  z-index: 999;

  ${breakpoint.TABLET} {
    flex-direction: row;
    justify-content: space-between;
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    gap: var(--sizing-sm);

    ${breakpoint.TABLET} {
      order: 2;
    }
  }

  .title {
    text-align: center;

    ${breakpoint.TABLET} {
      text-align: left;
      order: 1;
    }

    img {
      vertical-align: baseline;
      margin-left: var(--sizing-2xs);
      height: 20px;

      ${breakpoint.DESKTOP} {
        height: var(--sizing-md);
      }
    }
  }
`;

export const Card = styled.div`
  padding: var(--sizing-sm);

  ${breakpoint.TABLET} {
    padding: var(--sizing-lg);
  }
`;
