import styled, { css } from 'styled-components';

import { focusUtil } from 'DesignLibrary/utils';

interface DropdownContainerProps {
  horizontalPosition: 'left' | 'right';
}

interface DropdownListItemProps {
  selected: boolean;
}

export const DropdownContainer = styled.div<DropdownContainerProps>`
  position: absolute;
  z-index: 9999;
  top: calc(100% + var(--sizing-xs));
  left: ${({ horizontalPosition }) => (horizontalPosition === 'left' ? 0 : 'auto')};
  right: ${({ horizontalPosition }) => (horizontalPosition === 'right' ? 0 : 'auto')};
  min-width: var(--sizing-4xl);
  border-radius: var(--border-radius-xs);
  overflow: hidden;
  box-shadow: var(--shadow-large);
  background: var(--primary-white);

  .label {
    padding: var(--sizing-xs) var(--sizing-sm);
    background: var(--primary-white);
    border-radius: 0;
  }

  ul {
    margin: 0;
    padding: 0;

    li {
      list-style: none;
      margin: 0;
      height: auto;
    }
  }
`;

export const DropdownListItem = styled.button<DropdownListItemProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: var(--sizing-sm) var(--sizing-sm);
  width: 100%;
  border: none;
  background: var(--background-white);
  ${focusUtil}

  .values {
    margin-right: var(--sizing-sm);
    display: flex;
    align-items: center;
    /* Prevent event bubbling from the wrong event target */
    pointer-events: none;

    .description {
      margin-left: var(--sizing-xs);
      margin-top: 0;
    }
  }

  .pseudo-radio {
    pointer-events: none;
  }

  &:hover,
  &:focus-visible {
    background: var(--background-gray-light);
  }

  ${({ selected }) =>
    selected &&
    css`
      background: var(--background-gray);
      color: var(--text-black);

      &:hover {
        background: var(--background-gray);
        cursor: default;
      }
    `}
`;
