import styled from 'styled-components';

export const FAQContainer = styled.div`
  h3 {
    margin-bottom: var(--sizing-sm);
  }
`;

export const FAQLinkWrapper = styled.div`
  margin-top: var(--sizing-sm);
`;

export const TableWrapper = styled.div`
  border: 1px solid var(--border-gray);
  border-radius: var(--border-radius-xs);
  overflow: hidden;

  table {
    /* table-layout: fixed; */

    * {
      word-wrap: break-word;
      white-space: normal;
    }

    .x {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 4px;

      .paragraph {
        text-align: center;
      }
    }
  }

  th {
    border-right: 1px solid var(--border-gray);
    border-bottom: none;
    text-transform: inherit;
  }

  thead {
    background: var(--background-gray-light);
    border-bottom: 1px solid var(--border-gray);

    th {
      padding: var(--sizing-xs) 0;
      text-align: center;
      font-weight: 600;
      font-size: 13px;
      letter-spacing: 0.5px;

      &:last-child {
        border-right: none;
      }
    }
  }

  tr {
    &:nth-child(2n + 1) {
      background: var(--background-gray-light);
    }
  }

  td {
    border: 0;
    border-right: 1px solid var(--border-gray);
    max-width: var(--sizing-4xl);
    padding: var(--sizing-sm);

    &:last-child {
      border: none;
    }
  }
`;
