import React, { FC } from 'react';

import { EnteredPlan } from 'Containers/CommercialRoutes/types';
import { useTextContext } from 'Contexts/textContext';
import { Button } from 'DesignLibrary/atoms';
import { Drawer } from 'DesignLibrary/atoms/Drawer';
import { Paragraph, H4 } from 'DesignLibrary/atoms/typography';
import InfoBar from 'DesignLibrary/molecules/InfoBar';
import RichText from 'Shared/RichText';
import Text from 'Shared/Text';
import { toDollars } from 'Utils/helpers';

import PlanBenefits from './PlanBenefits';
import PlanEntryDetails from './PlanEntryDetails';
import { FormWrapper, Introduction, Necessities } from './styled';

interface PlanEntryModalProps {
  isOpen: boolean;
  individualHsaContributionLimit: number;
  familyHsaContributionLimit: number;
  plan: EnteredPlan;
  isFamily: boolean;
  maximumAvailableAnnualSihraContribution: number;
  handleClose: () => void;
  getRecommendations: (plan: EnteredPlan) => void;
  savePlan: (EnteredPlan) => void;
}

interface NecessaryInformationItems {
  link: string;
  name: string;
  type: string;
}

const PlanEntryModal: FC<PlanEntryModalProps> = ({
  isOpen,
  plan,
  individualHsaContributionLimit,
  familyHsaContributionLimit,
  isFamily,
  maximumAvailableAnnualSihraContribution,
  handleClose,
  getRecommendations,
  savePlan,
}): JSX.Element => {
  const { retrieveContentfulData } = useTextContext();

  const onSubmit = (e) => {
    e.preventDefault();
    getRecommendations(plan);
    handleClose();
  };

  const necessities = retrieveContentfulData<NecessaryInformationItems[]>(
    'spousal_plan_comparison.necessary_information.items',
  );

  const title = retrieveContentfulData<string>('spousal_plan_comparison.page_title');

  const formattedContribution = toDollars(maximumAvailableAnnualSihraContribution);

  const buildAPlanModalSihraText = (
    <>
      <Paragraph>
        <Text
          field="health_section.client_disclaimers.buildAPlanModalSihra.text"
          vars={{ x: formattedContribution }}
        />
      </Paragraph>
      <br />
      <Paragraph>
        <Text
          field="health_section.client_disclaimers.buildAPlanModalSihra.note"
          vars={{ x: formattedContribution }}
        />
      </Paragraph>
    </>
  );

  return (
    <Drawer isOpen={isOpen} onClose={handleClose} title={title}>
      <Introduction>
        <Paragraph>
          <RichText field="spousal_plan_comparison.heading" />
        </Paragraph>

        <H4>
          <Text field="spousal_plan_comparison.necessary_information.question" />
        </H4>
        <Necessities>
          {necessities &&
            necessities.map((necessity) => (
              <Button
                buttonType="secondary"
                key={necessity.name}
                url={necessity.link}
                size="small"
                iconRight="Question"
              >
                {necessity.name}
              </Button>
            ))}
        </Necessities>
        {maximumAvailableAnnualSihraContribution > 0 && (
          <InfoBar
            data-testid="planEntryModalSihraDisclaimer"
            color="blue"
            header={<Text field="health_section.client_disclaimers.buildAPlanModalSihra.title" />}
            text={buildAPlanModalSihraText}
          />
        )}
      </Introduction>
      <FormWrapper>
        <form data-testid="plan-entry-modal-form" onSubmit={onSubmit}>
          <PlanEntryDetails
            plan={plan}
            savePlan={savePlan}
            individualHsaContributionLimit={individualHsaContributionLimit}
            familyHsaContributionLimit={familyHsaContributionLimit}
            isFamily={isFamily}
          />
          <PlanBenefits plan={plan} savePlan={savePlan} />
          <Button buttonType="primary" stretch onClick={() => {}} id="continue-btn">
            <Text field="spousal_plan_comparison.button_text.complete" />
          </Button>
        </form>
      </FormWrapper>
    </Drawer>
  );
};

export default PlanEntryModal;
