import styled from 'styled-components';

interface AppWrapperProps {
  isDevMode?: boolean;
}

export const AppWrapper = styled.div<AppWrapperProps>`
  ${({ isDevMode }) =>
    isDevMode &&
    `
      &:after {
        width: 100%;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 99999999999;
        opacity: 0.5;
        font-weight: bold;
        font-size: 14px;
        pointer-events: none;
        color: #fff;
      }

      * {
        animation-duration: 0s !important;
        animation-delay: 0s !important;
      }

      &:after {
        content: 'DEV MODE (turn off with ?dev=false)';
        background: red;
      }
    `};

  .login-banner {
    color: white;
    position: relative;
    top: 0px;

    button {
      display: inline-block;
      color: white;
      font-weight: 900;
      padding-left: 10px;
      border: 1px (--primary-white);
    }
  }
`;
