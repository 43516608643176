import styled from 'styled-components';

import { breakpoint } from 'DesignLibrary/global';
import { cardStyling } from 'DesignLibrary/utils';

export const Container = styled.div`
  ${cardStyling}
  display: flex;
  align-items: center;
  gap: var(--sizing-sm);
  padding: 12px;
  margin-bottom: var(--sizing-sm);
  position: sticky;
  top: -4px;
  z-index: 9999;

  .input-container {
    width: 96px;
    flex-shrink: 0;

    > div {
      height: 40px;
    }

    input {
      width: 100%;
      padding-left: var(--sizing-lg);
    }
  }

  ${breakpoint.TABLET} {
    padding: var(--sizing-sm);

    .input-container {
      width: var(--sizing-3xl);
      flex-shrink: 0;

      > div {
        height: 40px;
      }

      input {
        padding-left: 40px;
      }
    }
  }
`;

export const SliderContainer = styled.div`
  flex-basis: 100%;
  position: relative;

  .numbers {
    position: absolute;
    bottom: -4px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    user-select: none;
    pointer-events: none;
    flex-shrink: 0;
  }

  .slider-thumb {
    box-shadow: var(--shadow-large);
    background: var(--primary-white);
    outline: 4px solid rgba(0, 0, 0, 0);
    transition: 0.15s outline-color;

    &:hover,
    &:focus {
      outline-color: var(--colors-opaque-30);
      outline-offset: 0;
    }
  }

  .rec-circle {
    display: block;
    height: 12px;
    width: 12px;
    border-radius: 4rem;
    transform: translate(-50%, -50%);
    pointer-events: auto !important;
  }

  .slider-mark,
  .slider-mark-rec {
    background: var(--text-black);
    border-radius: 4px;
    padding: 2px var(--sizing-xs);
    position: relative;
    margin-top: 32px;
    transform: translateX(-50%);
    white-space: nowrap;

    &:after {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      background: inherit;
      position: absolute;
      top: -5px;
      left: calc(50% - 5px);
      transform: rotate(45deg);
      border-radius: 2px 0 0 0;
    }
  }

  .slider-mark-rec {
    display: flex;
    align-items: center;
    gap: var(--sizing-2xs);
    margin-top: -56px;
    background: var(--primary-blue);
    pointer-events: auto !important;

    &:after {
      top: auto;
      bottom: -5px;
      border-radius: 0 0 2px 0;
    }
  }
`;
