import styled from 'styled-components';

import { breakpoint } from 'DesignLibrary/global';

export const StackContainer = styled.div`
  border: var(--border-thick);
  border-radius: var(--border-radius-xs);
`;

export const StackSection = styled.div`
  border-top: var(--border-thick);

  &:first-child {
    border: none;
  }

  ${breakpoint.TABLET} {
    border-left: var(--border-thick);
    border-top: none;
  }

  .title {
    border-bottom: var(--border-thick);
    padding: var(--sizing-sm);
    height: 100%;
  }

  .body {
    padding: var(--sizing-sm);
  }

  ul {
    padding: 0;
    padding-left: var(--sizing-xs);
  }

  li {
    list-style-position: inside;
  }
`;
