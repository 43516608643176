import styled from 'styled-components';

import { media } from 'DesignLibrary/global';
import { FadeInRightKeyframes } from 'DesignLibrary/keyframes';
import { cardStyling } from 'DesignLibrary/utils';

interface ContainerProps {
  bg: string;
}

export const TopBar = styled.div`
  padding: var(--sizing-md) 0;
`;

export const Container = styled.section<ContainerProps>`
  z-index: 0;
  margin-bottom: var(--sizing-3xl);

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: var(--sizing-xl);
    background: ${({ bg }) => bg && `var(${bg})`};
  }

  .bottom-button {
    padding-top: var(--sizing-xl);
  }
`;

export const BenefitTitle = styled.div`
  ${cardStyling}
  position: relative;
  z-index: 2;
  padding: var(--sizing-sm);

  ${media.TABLET`
    padding: var(--sizing-md);
  `}

  .title {
    display: flex;
    gap: var(--sizing-sm);
    align-items: center;
    margin-left: var(--sizing-xs);
    margin-bottom: var(--sizing-md);
    margin-top: var(--sizing-xs);

    ${media.TABLET`
      margin-top: 0;
    `}
  }

  .details {
    & > * + * {
      margin-top: var(--sizing-sm);
    }
  }

  .benefit-video {
    border: 1px solid var(--border-gray);
    border-radius: var(--border-radius-xs);
    overflow: hidden;

    ${media.TABLET`
      display: none;
    `}

    ${media.WIDEDESKTOP`
      display: block;
      position: fixed;
      z-index: 999;
      bottom: 80px;
      right: var(--sizing-sm);
      width: var(--sizing-4xl);
      border: 0;
      box-shadow: var(--shadow-large);
      opacity: 0;
      animation: ${FadeInRightKeyframes} 1s 2s forwards;
    `}
  }
`;
