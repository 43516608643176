export const CHANGE_FORM_VALUE = 'app/CommercialV2/CommercialV2/CHANGE_FORM_VALUE';

export const SET_SELECTED_MEMBER_IDS = 'app/CommercialV2/CommercialV2/SET_SELECTED_MEMBER_IDS';

export const GET_RECOMMENDATIONS_SUCCESS = 'app/CommercialV2/CommercialV2/GET_RECOMMENDATIONS_SUCCESS';
export const GET_RECOMMENDATIONS_FAILURE = 'app/CommercialV2/CommercialV2/GET_RECOMMENDATIONS_FAILURE';

export const GET_RESULTS_PAGE_DATA = 'app/CommercialV2/CommercialV2/GET_RESULTS_PAGE_DATA';

export const GET_RECOMMENDATIONS_EPHEMERAL_REQUEST =
  'app/CommercialV2/CommercialV2/GET_RECOMMENDATIONS_EPHEMERAL_REQUEST';

export const GET_PEOPLE_LIKE_YOU_SUCCESS = 'app/CommercialV2/CommercialV2/GET_PEOPLE_LIKE_YOU_SUCCESS';
export const GET_PEOPLE_LIKE_YOU_FAILURE = 'app/CommercialV2/CommercialV2/GET_PEOPLE_LIKE_YOU_FAILURE';

export const SELECT_HEALTH_PLAN = 'app/CommercialV2/CommercialV2/SELECT_HEALTH_PLAN';
export const SELECT_HEALTH_PLAN_STORE_ONLY = 'app/CommercialV2/CommercialV2/SELECT_HEALTH_PLAN_STORE_ONLY';

export const WAIVE_HEALTH_COVERAGE = 'app/CommercialV2/CommercialV2/WAIVE_HEALTH_COVERAGE';

export const SET_HOUSEHOLD_EXTERNAL_ID = 'app/CommercialV2/CommercialV2/SET_HOUSEHOLD_EXTERNAL_ID';

export const SELECT_HEALTH_PLAN_SUCCESS = 'app/CommercialV2/CommercialV2/SELECT_HEALTH_PLAN_SUCCESS';
export const SELECT_HEALTH_PLAN_FAILURE = 'app/CommercialV2/CommercialV2/SELECT_HEALTH_PLAN_FAILURE';

export const GET_HOUSEHOLD_ID_BY_EXTERNAL_ID_SUCCESS =
  'app/CommercialV2/CommercialV2/GET_HOUSEHOLD_ID_BY_EXTERNAL_ID_SUCCESS';
export const GET_HOUSEHOLD_ID_BY_EXTERNAL_ID_FAILURE =
  'app/CommercialV2/CommercialV2/GET_HOUSEHOLD_ID_BY_EXTERNAL_ID_FAILURE';

export const POST_INCENTIVE_SURVEY_SUCCESS = 'app/CommercialV2/CommercialV2/POST_INCENTIVE_SURVEY_SUCCESS';
export const POST_INCENTIVE_SURVEY_FAILURE = 'app/CommercialV2/CommercialV2/POST_INCENTIVE_SURVEY_FAILURE';
