import styled from 'styled-components';

import { ParagraphSmallStyle } from 'DesignLibrary/atoms';

export const ServiceContainer = styled.section`
  width: 100%;

  > section {
    margin-top: 1.5rem;

    .paragraph {
      margin: 0;
      margin-bottom: 0.5rem;
    }
  }
`;

export const PercentContainer = styled.section`
  max-width: 800px;
  display: block;
  border-radius: var(--border-radius-xs);
  background-color: var(--background-gray);
  overflow: hidden;
  height: 1.75rem;
`;

interface PercentBarProps {
  percent: number;
}

export const PercentBar = styled.section<PercentBarProps>`
  position: relative;
  width: ${({ percent }) => percent}%;
  min-width: 2rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;
  background: var(--primary-blue);
  color: var(--primary-white);
  font-weight: 600;
  transition: 0.5s width;
  will-change: width;

  span {
    position: relative;
    z-index: 1;
  }
`;

interface DistBarProps {
  percent: number;
}

export const DistBar = styled.section<DistBarProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.325rem;
  background: linear-gradient(
    270deg,
    rgba(230, 79, 79, 0.5) 0%,
    rgba(250, 207, 37, 0.5) ${({ percent }) => 100 - percent}%,
    rgba(59, 205, 148, 0.5) 100%
  );
  border-radius: var(--border-radius-xs);
  max-width: 800px;
  margin: 0.5rem 0;
  transition: 0.5s background-color;
  will-change: background-color;

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: ${({ percent }) => percent}%;
    top: 0;
    width: 2px;
    height: 100%;
    background: var(--primary-black);
    opacity: 0.25;
    transition: 0.5s left;
    will-change: left;
  }
`;

interface NumberProps {
  percent?: number;
}

export const Number = styled.div<NumberProps>`
  ${ParagraphSmallStyle()}
  font-weight: 600 !important;
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
  border-radius: 1rem;
  z-index: 1;
  color: var(--primary-white);

  &:nth-child(1) {
    background: var(--primary-green);
  }
  &:nth-child(3) {
    background: var(--primary-red);
  }

  &:nth-child(2) {
    color: var(--text-black);
    background: var(--colors-yellow-300);
    position: absolute;
    left: ${({ percent }) => percent}%;
    top: 0.325rem;
    transform: translateX(-50%);
    transition: 0.5s left;
    will-change: left;
  }
`;

interface CostContainerProps {
  visible: boolean;
}

export const CostContainer = styled.section<CostContainerProps>`
  opacity: ${({ visible }) => (visible ? '1' : '0')};
`;
