import { isEmpty } from 'lodash';
import { createSelector } from 'reselect';

import { GlobalReducerState } from 'app/reducers';
import { CommercialRoutesReducerState } from 'Containers/CommercialRoutes/reducer';
import { Recommendation as RecommendationType } from 'Types/entities';
import { ClientConfig } from 'Utils/apiTypes';

import { GlobalAppReducerState } from './reducer';
import { EnabledProducts, JVPState } from './types';

const selectAppDomainAsJS = (state: GlobalReducerState): GlobalAppReducerState => state.globalApp;

const selectIsFirstPageView = (path: string) =>
  createSelector(makeSelectGlobalField('pageProps'), (substate) => {
    const pageProps = substate;
    if (pageProps[path] && pageProps[path].viewed) {
      return false;
    }
    return true;
  });

const makeSelectGlobalField = <K extends keyof GlobalAppReducerState>(field: K) =>
  createSelector(
    selectAppDomainAsJS,
    (substate: GlobalAppReducerState): GlobalAppReducerState[K] => substate[field],
  );

const selectCommercialDomainAsJS = (state: GlobalReducerState): CommercialRoutesReducerState =>
  state.commercialApp;

const makeSelectCommercialField = <K extends keyof CommercialRoutesReducerState>(field: K) =>
  createSelector(
    selectCommercialDomainAsJS,
    (substate: CommercialRoutesReducerState): CommercialRoutesReducerState[K] => substate[field],
  );

const makePreviousSelectedPlan = <K extends keyof GlobalAppReducerState>(field: K) =>
  createSelector(selectAppDomainAsJS, (substate) => substate[field]);

const makeIsPrevPlanSelected = () =>
  createSelector(
    [makeSelectCommercialField('selectedHealthPlan')],
    (selectedPlan: RecommendationType | Record<string, never>) => {
      if (!isEmpty(selectedPlan)) {
        return true;
      }
      return false;
    },
  );

const makeSelectConfigField = <K extends keyof ClientConfig>(flag: K) =>
  createSelector(makeSelectGlobalField('config'), (cfg) => cfg[flag]);

const makeSelectJvpField = <K extends keyof JVPState>(flag: K) =>
  createSelector(makeSelectGlobalField('jvp'), (cfg) => cfg[flag]);

const makeGetJvpHasPublicationWithFlag = (flag: string) =>
  createSelector(
    [makeSelectJvpField('active'), makeSelectJvpField('upcoming')],
    (active, upcoming) => active?.publication[flag] || upcoming?.publication[flag] || false,
  );

const makeGetSSOConnectionName = () =>
  createSelector(
    [makeSelectJvpField('active'), makeSelectJvpField('upcoming')],
    (active, upcoming): string => {
      const activePub = active?.publication;
      const upcomingPub = upcoming?.publication;

      if (activePub?.sso_enabled) {
        return activePub?.sso_connection_name || '';
      }

      if (upcomingPub?.sso_enabled) {
        return upcomingPub?.sso_connection_name || '';
      }

      return '';
    },
  );

const makeGetEnabledProducts = () =>
  createSelector(
    [
      makeSelectConfigField('has_product_bc'),
      makeSelectConfigField('has_product_go'),
      makeSelectConfigField('has_product_medicare'),
    ],
    (hasProductBc, hasProductGo, hasProductMedicare): EnabledProducts => ({
      bc: hasProductBc,
      go: hasProductGo,
      medicare: hasProductMedicare,
    }),
  );

const makeGetCanChangePayPeriod = () =>
  createSelector(
    [makeSelectConfigField('can_change_pay_period'), makeSelectConfigField('builder_customer_key')],
    (canChangePayPeriod: boolean, builderCustomerKey: string | null): boolean => {
      return canChangePayPeriod && !builderCustomerKey;
    },
  );

export {
  selectAppDomainAsJS,
  selectCommercialDomainAsJS,
  selectIsFirstPageView,
  makeSelectGlobalField,
  makeSelectCommercialField,
  makeSelectConfigField,
  makeGetEnabledProducts,
  makeSelectJvpField,
  makeGetJvpHasPublicationWithFlag,
  makeGetSSOConnectionName,
  makePreviousSelectedPlan,
  makeGetCanChangePayPeriod,
  makeIsPrevPlanSelected,
};
