import React from 'react';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';

export interface FadeInProps {
  isOpen: boolean;
  children;
  enableTransitionInJest?: boolean;
}

const CSSTransitionStyled = styled.div`
  &.fadeIn-enter,
  &.fadeIn-appear {
    opacity: 0;
  }
  &.fadeIn-enter-active,
  &.fadeIn-appear-active {
    opacity: 1;
    transition: opacity 200ms;
  }
  &.fadeIn-exit {
    opacity: 1;
  }
  &.fadeIn-exit-active {
    opacity: 0;
    transition: opacity 200ms;
  }
`;

export const FadeIn = ({ isOpen, children, enableTransitionInJest = false }: FadeInProps): JSX.Element => {
  const isTestEnv = process.env.NODE_ENV === 'test';

  if (isTestEnv && !enableTransitionInJest) {
    return children;
  }

  return (
    <CSSTransition in={isOpen} timeout={200} classNames="fadeIn" appear unmountOnExit>
      <CSSTransitionStyled>{children}</CSSTransitionStyled>
    </CSSTransition>
  );
};
