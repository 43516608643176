import styled from 'styled-components';

import { TooltipUnderlineStyle } from '../typography';

interface ScoreContainerProps {
  size: 'small' | 'medium' | 'large';
  color: 'red' | 'yellow' | 'green' | 'purple';
  showTooltip: boolean;
  attached: boolean;
}

export const ScoreContainer = styled.div<ScoreContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  background: ${({ color }) => color && `var(--primary-${color})`};
  width: ${({ size }) => {
    switch (size) {
      case 'small':
        return 'var(--sizing-lg)';
      case 'medium':
        return 'var(--sizing-xl)';
      case 'large':
        return 'var(--sizing-xxl)';
      default:
        return 'var(--sizing-xxl)';
    }
  }};
  height: ${({ size }) => {
    switch (size) {
      case 'small':
        return 'var(--sizing-lg)';
      case 'medium':
        return 'var(--sizing-xl)';
      case 'large':
        return 'var(--sizing-xxl)';
      default:
        return 'var(--sizing-xxl)';
    }
  }};
  border-radius: ${({ attached }) =>
    !attached ? 'var(--border-radius-xs)' : 'var(--border-radius-xs) 0 0 var(--border-radius-xs)'};

  .count-number {
    display: block;
    cursor: ${({ showTooltip }) => (showTooltip ? 'help' : 'default')};
    ${({ showTooltip }) => showTooltip && TooltipUnderlineStyle}
    text-underline-offset: 4px;
    color: var(--primary-white);
    font-family: var(--font-primary) !important;
    font-weight: 600;
    font-size: ${({ size }) => {
      switch (size) {
        case 'small':
          return '18px';
        case 'medium':
          return '24px';
        case 'large':
          return '28px';
        default:
          return '24px';
      }
    }};
  }
`;
