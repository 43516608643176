import styled from 'styled-components';

import { media } from 'DesignLibrary/global';
import { cardStyling } from 'DesignLibrary/utils';

export const DetailsContainer = styled.article`
  padding: var(--sizing-md);
  padding-bottom: var(--sizing-xl);

  & > * + * {
    margin-top: var(--sizing-md);
  }

  .main-description {
    .paragraph {
      max-width: 768px;
      padding-bottom: 1em;
    }

    p,
    .paragraph {
      margin-bottom: var(--sizing-xs);

      &:last-child {
        margin-bottom: 0;
      }
    }

    b {
      color: var(--text-black);
    }

    a {
      color: var(--text-black);
    }

    ol,
    ul {
      margin-bottom: var(--sizing-sm);

      li {
        margin-top: var(--sizing-xs);
      }
    }
  }

  .video-container {
    border: 1px solid var(--border-gray);
    border-radius: var(--border-radius-sm);
    overflow: hidden;
    max-width: 600px;
  }

  .notes {
    h6 {
      margin: var(--sizing-sm) 0;
    }
  }

  .detail-plans {
    background: var(--background-gray);
    padding: var(--sizing-md);
    margin: 0 -24px;
    margin-top: var(--sizing-md);

    & > * + * {
      margin-top: var(--sizing-sm);
    }
  }
`;

export const DetailsPlan = styled.div`
  ${cardStyling}
  padding: var(--sizing-sm);

  ${media.TABLET`
    padding: var(--sizing-md);
  `}

  .header-text {
    margin-bottom: var(--sizing-md);
  }

  .button-container {
    margin-top: var(--sizing-sm);
  }
`;

export const ChoosePlanBtnWrapper = styled.div`
  margin-top: var(--sizing-lg);
`;
