import styled from 'styled-components';

import { media } from 'DesignLibrary/global';
import { cardStyling } from 'DesignLibrary/utils';

export const Container = styled.div`
  ${cardStyling}
  padding: var(--sizing-sm);

  ${media.TABLET`
    padding: var(--sizing-sm) var(--sizing-md);
  `}

  .status-container {
    display: flex;
    gap: 0 4px;
    margin: var(--sizing-sm) 0;
  }

  .paragraph {
    margin: 0;
  }
`;

interface StatusProps {
  state: 'risky' | 'moderate' | 'safe';
  active: boolean;
}

export const Status = styled.div<StatusProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 2;
  height: var(--sizing-lg);
  background: ${({ state, active }) => {
    if (!active) {
      return 'var(--background-gray)';
    }

    switch (state) {
      case 'risky':
        return 'var(--primary-red)';
      case 'moderate':
        return 'var(--primary-yellow)';
      case 'safe':
        return 'var(--primary-green)';
      default:
        return 'var(--background-gray)';
    }
  }};

  &:first-child {
    border-radius: var(--border-radius-xs) 0 0 var(--border-radius-xs);
  }

  &:last-child {
    border-radius: 0 var(--border-radius-xs) var(--border-radius-xs) 0;
  }

  span {
    display: flex;
  }
`;
