import styled from 'styled-components';

interface SplitWrapperProps {
  direction: 'left' | 'right';
}

export const SplitWrapper = styled.div<SplitWrapperProps>`
  display: inline-grid;
  grid-template-columns: auto auto;
  gap: var(--sizing-xs);

  > div {
    display: flex;
  }

  h6 {
    display: inline-block;
    padding: 0 var(--sizing-xs);
    border-radius: 4rem;
    background-color: rgba(0, 0, 0, 0.05);
  }
`;
