import styled, { css } from 'styled-components';

import { H6Style } from 'DesignLibrary/atoms';
import { media } from 'DesignLibrary/global';

interface ValuesContainerProps {
  isProviderSearchEnabled: boolean;
}

export const ValuesContainer = styled.div<ValuesContainerProps>`
  border: var(--border-thick);
  border-radius: var(--border-radius-xs);

  ${media.TABLET`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: 1fr 1fr;
    justify-items: stretch;
    width: 100%;
  `}

  ${({ isProviderSearchEnabled }) =>
    isProviderSearchEnabled &&
    css`
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    `}
`;

export const Value = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--sizing-sm);
  border-bottom: var(--border-thick);

  &:nth-child(2n) {
    background: var(--background-gray-light);
  }

  &:last-child {
    border-bottom: 0;
  }

  > span {
    display: flex;
  }

  ${media.TABLET`
    border: 0;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 2px;

    &:nth-child(2n) {
      background: unset;
    }

    > :first-child {
      order: 2;
    }

    &:nth-child(1) {
      grid-column: 1 / 2;
      grid-row: 1 / span 2;
      border-right: var(--border-thick);
      align-items: center;
      gap: var(--sizing-sm);
    }

    &:nth-child(2) {
      grid-column: 2;
      grid-row: 1;
      border-right: var(--border-thick);
      border-bottom: var(--border-thick);
    }

    &:nth-child(3) {
      grid-column: 2;
      grid-row: 2;
      border-right: var(--border-thick);
    }

    &:nth-child(4) {
      grid-column: 3;
      grid-row: 1;
      border-bottom: var(--border-thick);
    }

    &:nth-child(5) {
      grid-column: 3;
      grid-row: 2;
    }
  `}
`;

export const SplitThreshold = styled.div`
  gap: var(--sizing-sm);

  > div {
    display: flex;
    align-items: center;
    gap: var(--sizing-xs);
  }
`;

export const BreakdownTag = styled.div`
  ${H6Style()}
  padding: 0 var(--sizing-xs);
  border-radius: 4rem;
  background-color: var(--colors-opaque-5);
  text-align: center;
`;
