import styled from 'styled-components';

export const Item = styled.div`
  border-top: 1px solid var(--border-gray);
  padding: var(--sizing-sm) 0;

  & > * + * {
    margin-top: var(--sizing-xs);
  }

  button {
    margin-top: var(--sizing-sm);
  }
`;

interface BulletProps {
  color: string;
}

export const Bullet = styled.div<BulletProps>`
  * {
    display: inline-block;
  }
  &::before {
    /* content: '>'; */
    /* margin-right: var(--sizing-sm);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: var(--sizing-md);
    height: var(--sizing-md);
    border-radius: 4px;
    background: ${({ color }) => `var(--colors-${color}-50)`};
    color: ${({ color }) => `var(--colors-${color}-400)`}; */
  }
`;

export const BulletIcon = styled.div<BulletProps>`
  content: '>';
  align-items: center;
  justify-content: center;
  margin-right: var(--sizing-sm);
  display: inline-flex;
  width: var(--sizing-md);
  height: var(--sizing-md);
  border-radius: 4px;
  background: ${({ color }) => `var(--colors-${color}-50)`};
  color: ${({ color }) => `var(--colors-${color}-400)`};
`;
