import React from 'react';
import styled from 'styled-components';

import { COVERAGE_TIERS } from 'Containers/App/constants';
import { PlanCardRow } from 'Containers/ViewPlanPage/_shared/styled';
import { SplitCostRow, SplitCostWrapper } from 'Containers/ViewPlanPage/PlanEssentialsCard/styled';
import { BreakdownTag } from 'Containers/ViewPlanPage/ViewPlanPageCard/BreakdownTag';
import { useTextContext } from 'Contexts/textContext';
import { FormattedTooltipProps, Numbers } from 'DesignLibrary/atoms';
import { TooltipCaps, TooltipPlanDetails } from 'DesignLibrary/molecules';
import { SplitNumbers } from 'Shared/SplitNumbers';
import Text from 'Shared/Text';

export const adoaPlan = 'PPO2020';

// ADOA PPO2020 PLAN HACKS:
export const showAdoaHack = (client, planId) => client === 'adoa' && planId === adoaPlan;

// Compare Modal:  Deductible Limits
export const renderAdoaInNetworkDeductible = (coverageTier: string): JSX.Element => {
  const tier1Deductible = coverageTier === COVERAGE_TIERS.INDIVIDUAL ? '$200' : '$400';
  const tier2Deductible = coverageTier === COVERAGE_TIERS.INDIVIDUAL ? '$1,000' : '$2,000';

  return (
    <SplitNumbers
      titles={[
        <Text
          key="breakdown_tag_tier_1_deductible"
          field="health_details_section.plan_details.breakdown_tag_tier1_deductible"
        />,
        <Text
          key="breakdown_tag_tier_2_deductible"
          field="health_details_section.plan_details.breakdown_tag_tier2_deductible"
        />,
      ]}
      values={[tier1Deductible, tier2Deductible]}
      identifiers={['adoa-ppo2020-ded-t1', 'adoa-ppo2020-ded-t2']}
    />
  );
};

// View Plan Page: PlanCosts
const AdoaDeductiblesWrapper = styled.div`
  & h6 {
    max-height: 20px;
  }
`;

export const renderAdoaDeductiblesPlanCosts = (coverageTier: string): JSX.Element => {
  const { retrieveContentfulData } = useTextContext();

  const tier1Deductible = coverageTier === COVERAGE_TIERS.INDIVIDUAL ? '$200' : '$400';
  const tier2Deductible = coverageTier === COVERAGE_TIERS.INDIVIDUAL ? '$1,000' : '$2,000';

  const tier1DeductibleTooltip: FormattedTooltipProps = {
    ...retrieveContentfulData<FormattedTooltipProps>('tool_tips.tier1_deductible'),
  };

  const tier2DeductibleTooltip: FormattedTooltipProps = {
    ...retrieveContentfulData<FormattedTooltipProps>('tool_tips.tier2_deductible'),
  };

  const inNetworkDeductibleTooltip: FormattedTooltipProps = {
    ...retrieveContentfulData<FormattedTooltipProps>('tool_tips.in_network_deductible'),
  };

  return (
    <AdoaDeductiblesWrapper>
      <PlanCardRow>
        <TooltipPlanDetails tooltip={inNetworkDeductibleTooltip} keyHint="adoa-in-network-deductible">
          {String(inNetworkDeductibleTooltip.underline)}
        </TooltipPlanDetails>

        <SplitCostWrapper>
          <SplitCostRow>
            {/* Tier 1 Deductible */}
            <BreakdownTag>
              <Text field="health_details_section.plan_details.breakdown_tag_tier1_deductible" />
              {/* TODO: TICKETED Leaving this tooltip in for JakeBlank to deal with */}
              {/* <Tooltip label={tier1DeductibleTooltip} id="" /> */}
            </BreakdownTag>
            <TooltipPlanDetails
              tooltip={tier1DeductibleTooltip}
              keyHint={`adao-plan-${adoaPlan}-tier1-deductible`}
            >
              <Numbers className="tier-1-comprehensive-ded">{tier1Deductible}</Numbers>
            </TooltipPlanDetails>
          </SplitCostRow>
          <SplitCostRow>
            {/* Tier 2 Deductible */}
            <BreakdownTag>
              <Text field="health_details_section.plan_details.breakdown_tag_tier2_deductible" />
            </BreakdownTag>
            <TooltipPlanDetails
              tooltip={tier2DeductibleTooltip}
              keyHint={`adao-plan-${adoaPlan}-tier2-deductible`}
            >
              <Numbers className="adoa-ppo2020-ded">{tier2Deductible}</Numbers>
            </TooltipPlanDetails>
          </SplitCostRow>
        </SplitCostWrapper>
      </PlanCardRow>
    </AdoaDeductiblesWrapper>
  );
};

// Recommendation
export const renderAdoaDeductiblesRecommendation = (isFamily) => {
  const { retrieveContentfulData } = useTextContext();

  const coverageString = isFamily ? 'family' : 'indv';
  const tier1Deductible = isFamily ? '$400' : '$200';
  const tier2Deductible = isFamily ? '$2,000' : '$1,000';

  return (
    <span data-testid="adoa-deductibles">
      <TooltipCaps
        tooltip={retrieveContentfulData<FormattedTooltipProps>('tool_tips.tier1_deductible')}
        keyHint="adoa_tier1_deductible"
      >
        <Numbers data-testid={`adoa-ppo2020-${coverageString}-tier1-ded`}>{tier1Deductible}</Numbers>
      </TooltipCaps>
      &nbsp;/&nbsp;
      <TooltipCaps
        tooltip={retrieveContentfulData<FormattedTooltipProps>('tool_tips.tier2_deductible')}
        keyHint="adoa_tier2_deductible"
      >
        <Numbers data-testid={`adoa-ppo2020-${coverageString}-tier2-ded`}>{tier2Deductible}</Numbers>
      </TooltipCaps>
    </span>
  );
};

// Recommendation > Deductibles
// Display tooltip with underline text of "In-Network Deductible(s)" for ADOA plan with multiple deductibles
export const renderAdoaDeductibleTooltip = () => {
  const { retrieveContentfulData } = useTextContext();

  const adoaDeductibleTooltip = {
    title: retrieveContentfulData<string>('tool_tips.deductible.title'),
    text: retrieveContentfulData<string>('tool_tips.deductible.text'),
  };

  return (
    <TooltipCaps tooltip={adoaDeductibleTooltip} keyHint="adoa_deductible">
      <Text field="tool_tips.deductible.underline" />
    </TooltipCaps>
  );
};
