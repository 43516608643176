import React, { FC } from 'react';

import { useConfigContext } from 'Contexts/configContext';
import { usePremiumContext } from 'Contexts/premiumContext';
import { Tooltip } from 'DesignLibrary/atoms/Tooltip';
import { Numbers, Paragraph } from 'DesignLibrary/atoms/typography';
import Text from 'Shared/Text';
import { DollarField, PAY_PERIOD_KEYS } from 'Types/entities';
import { toDollars } from 'Utils/helpers';

import { PremiumContainer } from './styled';

export interface NumberWithPayPeriodProps {
  value: NumberWithPayPeriodValue;
  keyHint?: string;
  precision?: 0 | 2;
  payPeriodType?: 'yearly' | 'pay_period';
  prefix?: string;
  size?: 'small' | 'medium' | 'large';
  hideTooltip?: boolean;
  fontWeight?: 'normal' | 'bold';
}

type NumberWithPayPeriodValue = DollarField | RangeValue | number | string;

type RangeValue = {
  type: 'range';
  data: {
    lower: number;
    upper: number;
  };
};

export const NumberWithPayPeriod: FC<NumberWithPayPeriodProps> = ({
  value,
  keyHint,
  precision = 0,
  payPeriodType = 'pay_period',
  prefix = '',
  size = 'medium',
  hideTooltip = false,
  fontWeight = 'normal',
}): JSX.Element => {
  const { payPeriod, payCycle } = usePremiumContext();
  const { config } = useConfigContext();
  const { client } = config;

  // Weekly: Every week on a specific day of the week (52 payrolls per year). Example: every Friday.
  // Bi-Weekly: Every two weeks on a specific day of the week (26 payrolls per year). Example: every other Friday.
  // Semi-Monthly: Twice per month on two specific dates of the month (24 payrolls per year). Example: the 15th and the last day of the month.
  // Monthly: Every month on a specific date of the month (12 payrolls per year). Example: on the 26th.
  function calculatePremiumFromMonthlyAmount(monthlyAmount) {
    // price is almost always the monthly premium except in the case where a client doesn't pay the full 12 months
    // i.e. a school that pays 9 mo out of the year (except summer break); we need to adjust for those edge cases
    const monthlyValue = payCycle === 12 ? monthlyAmount : (monthlyAmount * 12) / payCycle;
    const yearlyValue = parseFloat((monthlyValue * payCycle).toFixed(2));

    switch (payPeriod) {
      case PAY_PERIOD_KEYS.WEEKLY:
        return yearlyValue / 52;
      case PAY_PERIOD_KEYS.BI_WEEKLY:
        if (client === 'fcps') {
          return yearlyValue / 20;
        }
        return yearlyValue / 26;
      case PAY_PERIOD_KEYS.SEMI_MONTHLY:
        return yearlyValue / 24;
      default:
        if (client === 'fcps') {
          return yearlyValue / 10;
        }
        return monthlyValue;
    }
  }

  // -----------------------
  // Contentful Values
  // -----------------------
  // All tooltips of type pay_period have the same title
  // We have a separate yearly type for annual Numbers that aren't tied to the pay period
  const tooltipTitle =
    payPeriodType === 'pay_period' ? (
      <Text field="tool_tips.number_with_pay_period.pay_period.title" />
    ) : (
      <Text field="tool_tips.number_with_pay_period.yearly.title" />
    );

  // Renders underlined text that is hovered on to display the tooltip
  const getTooltipUnderlineText = () => {
    switch (payPeriod) {
      case PAY_PERIOD_KEYS.WEEKLY:
        return <Text field="tool_tips.number_with_pay_period.pay_period.weekly.underline" />;
      case PAY_PERIOD_KEYS.BI_WEEKLY:
        return <Text field="tool_tips.number_with_pay_period.pay_period.biWeekly.underline" />;
      case PAY_PERIOD_KEYS.SEMI_MONTHLY:
        return <Text field="tool_tips.number_with_pay_period.pay_period.semiMonthly.underline" />;
      default:
        return <Text field="tool_tips.number_with_pay_period.pay_period.monthly.underline" />;
    }
  };

  // If payPeriodType is pay_period we want to use premiumContext to display values according to the selected pay period
  // Else we want to display a yearly value that isn't tied to selected pay period
  // (We may support an annual pay period that is different from the yearly values that are always displayed as yearly)
  const tooltipUnderlineText =
    payPeriodType === 'pay_period' ? (
      getTooltipUnderlineText()
    ) : (
      <Text field="tool_tips.number_with_pay_period.yearly.underline" />
    );

  // Renders tooltip text content
  const getTooltipContents = () => {
    switch (payPeriod) {
      case PAY_PERIOD_KEYS.WEEKLY:
        return <Text field="tool_tips.number_with_pay_period.pay_period.weekly.text" />;
      case PAY_PERIOD_KEYS.BI_WEEKLY:
        return <Text field="tool_tips.number_with_pay_period.pay_period.biWeekly.text" />;
      case PAY_PERIOD_KEYS.SEMI_MONTHLY:
        return <Text field="tool_tips.number_with_pay_period.pay_period.semiMonthly.text" />;
      default:
        return (
          <Text field="tool_tips.number_with_pay_period.pay_period.monthly.text" vars={{ x: payCycle }} />
        );
    }
  };

  // If payPeriodType is pay_period we want to use premiumContext to display values according to the selected pay period
  // Else we want to display a yearly value that isn't tied to selected pay period
  // (We may support an annual pay period that is different from the yearly values that are always displayed as yearly)
  const tooltipContents =
    payPeriodType === 'pay_period' ? (
      getTooltipContents()
    ) : (
      <Text field="tool_tips.number_with_pay_period.yearly.text" />
    );

  // -----------------------
  // Format Price Value
  // -----------------------
  const formatPrice = () => {
    let premiumValue;
    if (typeof value === 'object') {
      if (value == null) {
        return 'N/A';
      }

      if (value.type === 'USD') {
        premiumValue = value.data;
      } else if (value.type === 'range') {
        premiumValue = value.data.lower;
      }
    } else {
      premiumValue = value;
    }

    if (payPeriodType === 'pay_period') {
      return toDollars(calculatePremiumFromMonthlyAmount(premiumValue), precision);
    }

    return toDollars(premiumValue, precision);
  };

  return (
    <PremiumContainer
      key={`number-with-pay-period-${keyHint}`}
      data-testid={`number-with-pay-period-${keyHint}`}
      size={size}
    >
      {value && typeof value === 'object' && value.type === 'range' ? (
        <Paragraph className="starting-at" size="small">
          <Text field="benefits_section_additional.number_with_pay_period_range_label" />
        </Paragraph>
      ) : null}
      <div className="numbers">
        <Numbers size={size} weight={fontWeight}>
          {prefix}
          {formatPrice()}
          {hideTooltip && tooltipUnderlineText}
        </Numbers>
        {!hideTooltip && (
          <Tooltip
            data-testid={`pay-period-${keyHint}`}
            id={`pay-period-${keyHint}`}
            label={{ title: tooltipTitle, text: tooltipContents }}
          >
            <Paragraph className="pay-period" underline size="small" lineHeight={1} color="--text-gray-light">
              {tooltipUnderlineText}
            </Paragraph>
          </Tooltip>
        )}
      </div>
    </PremiumContainer>
  );
};
