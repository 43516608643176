import { GetForecastsResponse, GetHsaRecommendationResponse } from 'Utils/apiTypes';

import {
  FORECAST_HSA_FAILURE,
  FORECAST_HSA_SUCCESS,
  GET_HSA_RECOMMENDATION_FAILURE,
  GET_HSA_RECOMMENDATION_REQUEST,
  GET_HSA_RECOMMENDATION_SUCCESS,
  GET_RETIREMENT_FORECASTS,
  GET_RETIREMENT_FORECASTS_FAILURE,
  GET_RETIREMENT_FORECASTS_SUCCESS,
  LOCK_HSA_CONTRIBUTION,
  SET_HSA_BALANCE_AND_PERSONA,
} from './constants';
import { Persona } from './types';

export function setLockedHsaContribution(contribution: number) {
  return {
    type: LOCK_HSA_CONTRIBUTION,
    contribution,
  };
}

export function getHsaRecommendation() {
  return { type: GET_HSA_RECOMMENDATION_REQUEST };
}

export function getHsaRecommendationSuccess(response: GetHsaRecommendationResponse) {
  return {
    type: GET_HSA_RECOMMENDATION_SUCCESS,
    response,
  };
}

export function getHsaRecommendationFailure(error: Error) {
  return {
    type: GET_HSA_RECOMMENDATION_FAILURE,
    error,
  };
}

export function forecastHsaSuccess(response: GetForecastsResponse) {
  return {
    type: FORECAST_HSA_SUCCESS,
    response,
  };
}

export function forecastHsaFailure(error: Error) {
  return {
    type: FORECAST_HSA_FAILURE,
    error,
  };
}

export function forecastRetirement() {
  return { type: GET_RETIREMENT_FORECASTS };
}

export function forecastRetirementSuccess(responses: GetForecastsResponse[]) {
  return {
    type: GET_RETIREMENT_FORECASTS_SUCCESS,
    responses,
  };
}

export function forecastRetirementFailure(error: Error) {
  return {
    type: GET_RETIREMENT_FORECASTS_FAILURE,
    error,
  };
}

export function setHsaBalanceAndPersona(balance: number, persona: Persona) {
  return {
    type: SET_HSA_BALANCE_AND_PERSONA,
    balance,
    persona,
  };
}
