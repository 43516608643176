/* eslint-disable no-underscore-dangle */
import { COVERAGE_TIERS } from 'Containers/App/constants';
import { DrugCoverage, HealthCoverage, Recommendation, Thresholds, Tier } from 'Types/entities';
import { calculateHsaRecommendation } from 'Utils/hsa';

class Plan {
  _recommendation: Recommendation;

  // useLegacyCostCalc is true when is_real_cost_before_tax_benefit_enabled is false. Remove arg when retiring feature flag APP-1028-oop-estimate-uses-comm-api
  _useLegacyCostCalc: boolean | undefined;

  _planId: string;

  _planType: string;

  _picwellScore: number;

  _tier: Tier;

  _carrierName: string;

  _planMarketingName: string;

  _hsaEligible: boolean;

  _hraEligible: boolean;

  _premium: number;

  _annualPremium: number;

  _annualPremiumDeductions: number;

  _spendingAccountBenefit: number;

  _medicalCost: number;

  _drugCost: number;

  _employerHraContribution: number | null;

  _employerHsaFixedContribution: number | null;

  _employerHsaPercentageMatch: number | null;

  _employerHsaContributionLimit: number | null;

  _annualSihraDollarsUsed?: number;

  _individualThresholds: Thresholds;

  _individualChildThresholds: Thresholds;

  _individualChildrenThresholds: Thresholds;

  _individualSpouseThresholds: Thresholds;

  _familyThresholds: Thresholds;

  _primaryCare: HealthCoverage;

  _specialistCare: HealthCoverage;

  _genericDrug: DrugCoverage;

  _brandDrug: DrugCoverage;

  _realCostBeforeTaxBenefit: number;

  _benefitsSummaryUrl: string | null;

  _nonFormularyDrug: DrugCoverage;

  _specialtyDrug: DrugCoverage;

  _inNetworkDeductible: number | null;

  _inNetworkMedDeductible: number | null;

  _inNetworkDrugDeductible: number | null;

  _oonDeductible: number | null;

  _oonMedDeductible: number | null;

  _oonDrugDeductible: number | null;

  _inNetworkOopMax: number | null;

  _inNetworkMedOopMax: number | null;

  _inNetworkDrugOopMax: number | null;

  _oonOopMax: number | null;

  _oonMedOopMax: number | null;

  _oonDrugOopMax: number | null;

  _canReimburseCopay: boolean | null;

  _docfinderNetworkUuids: string[] | null;

  constructor(
    recommendation: Recommendation,
    useLegacyCostCalc: boolean = true, // useLegacyCostCalc is true when is_real_cost_before_tax_benefit_enabled is false. Remove arg when retiring feature flag APP-1028-oop-estimate-uses-comm-api
  ) {
    this._recommendation = recommendation;
    this._useLegacyCostCalc = useLegacyCostCalc; // useLegacyCostCalc is true when is_real_cost_before_tax_benefit_enabled is false. Remove arg when retiring feature flag APP-1028-oop-estimate-uses-comm-api
    this._planId = recommendation.plan.external_id || recommendation.plan.external_plan_id;
    this._planType = recommendation.plan.plan_type;
    this._picwellScore = recommendation.score;
    this._tier = recommendation.tier;
    this._carrierName = recommendation.plan.carrier_name;
    this._planMarketingName = recommendation.plan.plan_marketing_name;
    this._hsaEligible = recommendation.plan.hsa_eligible;
    this._hraEligible = recommendation.plan.hra_eligible;
    this._premium = recommendation.costs.effective_premium;
    this._annualPremium = recommendation.costs.annual_premium;
    this._annualPremiumDeductions = recommendation.costs.annual_premium_deductions;
    this._spendingAccountBenefit = recommendation.costs.spending_account_benefit;
    this._medicalCost = recommendation.costs.services.in_network;
    this._drugCost = recommendation.costs.drugs;
    this._employerHraContribution =
      recommendation.plan.spending_account_contributions.hra_annual?.employer_contribution_amount ?? 0;
    this._employerHsaFixedContribution =
      recommendation.plan.spending_account_contributions.hsa_annual.employer_contribution_amount;
    this._employerHsaPercentageMatch =
      recommendation.plan.spending_account_contributions.hsa_annual.employer_contribution_percent;
    this._employerHsaContributionLimit =
      recommendation.plan.spending_account_contributions.hsa_annual.employer_contribution_limit;
    this._annualSihraDollarsUsed = recommendation.costs.annual_sihra_dollars_used;
    this._realCostBeforeTaxBenefit = recommendation.costs.real_cost_before_tax_benefit;
    this._inNetworkDeductible =
      recommendation.plan_details.deductible.comprehensive?.in_network?.total || null;
    this._inNetworkMedDeductible =
      recommendation.plan_details.deductible.health_and_drug?.health?.in_network?.total || null;
    this._inNetworkDrugDeductible =
      recommendation.plan_details.deductible.health_and_drug?.drug?.in_network?.total || null;

    this._oonDeductible = recommendation.plan_details.deductible.comprehensive?.out_of_network?.total || null;
    this._oonMedDeductible =
      recommendation.plan_details.deductible.health_and_drug?.health?.out_of_network?.total || null;
    this._oonDrugDeductible =
      recommendation.plan_details.deductible.health_and_drug?.drug?.out_of_network?.total || null;

    this._inNetworkOopMax = recommendation.plan_details.max_oop.comprehensive?.in_network?.total || null;
    this._inNetworkMedOopMax =
      recommendation.plan_details.max_oop.health_and_drug?.health?.in_network?.total || null;
    this._inNetworkDrugOopMax =
      recommendation.plan_details.max_oop.health_and_drug?.drug?.in_network?.total || null;

    this._oonOopMax = recommendation.plan_details.max_oop.comprehensive?.out_of_network?.total || null;
    this._oonMedOopMax =
      recommendation.plan_details.max_oop.health_and_drug?.health?.out_of_network?.total || null;
    this._oonDrugOopMax =
      recommendation.plan_details.max_oop.health_and_drug?.drug?.out_of_network?.total || null;
    this._individualThresholds = recommendation.plan.individual_thresholds;
    this._individualChildThresholds = recommendation.plan.individual_child_thresholds;
    this._individualChildrenThresholds = recommendation.plan.individual_children_thresholds;
    this._individualSpouseThresholds = recommendation.plan.individual_spouse_thresholds;
    this._familyThresholds = recommendation.plan.family_thresholds;
    this._primaryCare = recommendation.plan.primary_care;
    this._specialistCare = recommendation.plan.specialist_care;
    this._genericDrug = recommendation.plan.generic_drug;
    this._brandDrug = recommendation.plan.brand_drug;
    this._benefitsSummaryUrl = recommendation.plan.benefits_summary_url;
    this._nonFormularyDrug = recommendation.plan.non_formulary_drug;
    this._specialtyDrug = recommendation.plan.specialty_drug;
    this._canReimburseCopay =
      recommendation.plan_details.health_reimbursement_arrangement?.can_reimburse_copay || null;
    this._docfinderNetworkUuids = recommendation.plan.docfinder_network_uuids;
  }

  /* Getters & Setters */

  get recommendation() {
    return this._recommendation;
  }

  // useLegacyCostCalc is true when is_real_cost_before_tax_benefit_enabled is false. Remove arg when retiring feature flag APP-1028-oop-estimate-uses-comm-api
  get useLegacyCostCalc() {
    return this._useLegacyCostCalc;
  }

  get planId() {
    return this._planId;
  }

  get picwellScore() {
    return this._picwellScore;
  }

  get planType() {
    return this._planType;
  }

  set planType(newPlanType) {
    this._planType = newPlanType;
  }

  get tier() {
    return this._tier;
  }

  get carrierName() {
    return this._carrierName;
  }

  set carrierName(newCarrierName) {
    this._carrierName = newCarrierName;
  }

  get planMarketingName() {
    return this._planMarketingName;
  }

  set planMarketingName(newPlanMarketingName) {
    this._planMarketingName = newPlanMarketingName;
  }

  get hsaEligible() {
    return this._hsaEligible;
  }

  get hraEligible() {
    return this._hraEligible;
  }

  get premium() {
    return this._premium;
  }

  set premium(newPremium) {
    this._premium = newPremium;
  }

  get annualPremium() {
    return this._annualPremium;
  }

  set annualPremium(annualPremium: number) {
    this._annualPremium = annualPremium;
  }

  get annualPremiumDeductions() {
    return this._annualPremiumDeductions;
  }

  set annualPremiumDeductions(annualPremiumDeductions: number) {
    this._annualPremiumDeductions = annualPremiumDeductions;
  }

  get premiumPerDeduction() {
    return this._annualPremiumDeductions === 0 ? 0 : this._annualPremium / this._annualPremiumDeductions;
  }

  // useLegacyCostCalc is true when is_real_cost_before_tax_benefit_enabled is false. Remove when retiring feature flag APP-1028-oop-estimate-uses-comm-api
  // With the new cost calc value from Comm API,
  // want to show the user unrounded numbers
  // so the math makes a bit more sense
  get spendingAccountBenefit() {
    return this.useLegacyCostCalc ? Math.floor(this._spendingAccountBenefit) : this._spendingAccountBenefit;
  }

  get medicalCost() {
    return this.useLegacyCostCalc ? Math.floor(this._medicalCost) : this._medicalCost;
  }

  get drugCost() {
    return this._useLegacyCostCalc ? Math.floor(this._drugCost) : this._drugCost;
  }

  get employerHraContribution() {
    return this._employerHraContribution === null
      ? null
      : this._employerHraContribution + this._getHRAIncentiveTotal();
  }

  get employerHsaFixedContribution() {
    return this._employerHsaFixedContribution === null
      ? null
      : this._employerHsaFixedContribution + this._getHSAIncentiveTotal();
  }

  get employerHsaPercentageMatch() {
    return this._employerHsaPercentageMatch;
  }

  get employerHsaContributionLimit() {
    return this._employerHsaContributionLimit;
  }

  get annualSihraDollarsUsed() {
    return this._annualSihraDollarsUsed;
  }

  get inNetworkDeductible() {
    return this._inNetworkDeductible;
  }

  get inNetworkMedDeductible() {
    return this._inNetworkMedDeductible;
  }

  get inNetworkDrugDeductible() {
    return this._inNetworkDrugDeductible;
  }

  get oonDeductible() {
    return this._oonDeductible;
  }

  get oonMedDeductible() {
    return this._oonMedDeductible;
  }

  get oonDrugDeductible() {
    return this._oonDrugDeductible;
  }

  get inNetworkOopMax() {
    return this._inNetworkOopMax;
  }

  get inNetworkMedOopMax() {
    return this._inNetworkMedOopMax;
  }

  get inNetworkDrugOopMax() {
    return this._inNetworkDrugOopMax;
  }

  get oonOopMax() {
    return this._oonOopMax;
  }

  get oonMedOopMax() {
    return this._oonMedOopMax;
  }

  get oonDrugOopMax() {
    return this._oonDrugOopMax;
  }

  get individualThresholds() {
    return this._individualThresholds;
  }

  set individualThresholds(newIndividualThresholds) {
    this._individualThresholds = newIndividualThresholds;
  }

  get individualChildThresholds() {
    return this._individualChildThresholds;
  }

  get individualChildrenThresholds() {
    return this._individualChildrenThresholds;
  }

  get individualSpouseThresholds() {
    return this._individualSpouseThresholds;
  }

  get familyThresholds() {
    return this._familyThresholds;
  }

  set familyThresholds(newFamilyThresholds) {
    this._familyThresholds = newFamilyThresholds;
  }

  get primaryCare() {
    return this._primaryCare;
  }

  set primaryCare(newPrimaryCare) {
    this._primaryCare = newPrimaryCare;
  }

  get specialistCare() {
    return this._specialistCare;
  }

  set specialistCare(newSpecialistCare) {
    this._specialistCare = newSpecialistCare;
  }

  get genericDrug() {
    return this._genericDrug;
  }

  set genericDrug(newGenericDrug) {
    this._genericDrug = newGenericDrug;
  }

  get brandDrug() {
    return this._brandDrug;
  }

  set brandDrug(newBrandDrug) {
    this._brandDrug = newBrandDrug;
  }

  get benefitsSummaryUrl() {
    return this._benefitsSummaryUrl;
  }

  set benefitsSummaryUrl(newBenefitsSummaryUrl) {
    this._benefitsSummaryUrl = newBenefitsSummaryUrl;
  }

  get nonFormularyDrug() {
    return this._nonFormularyDrug;
  }

  set nonFormularyDrug(newNonFormularyDrug) {
    this._nonFormularyDrug = newNonFormularyDrug;
  }

  get specialtyDrug() {
    return this._specialtyDrug;
  }

  set specialtyDrug(newSpecialtyDrug) {
    this._specialtyDrug = newSpecialtyDrug;
  }

  get HRAReimbursesCopay() {
    return this._canReimburseCopay;
  }

  get docfinderNetworkUuids() {
    return this._docfinderNetworkUuids;
  }

  /* Properties */

  get oopEstimate() {
    return this.medicalCost + this.drugCost;
  }

  get isEmployerMatching() {
    if (this.employerHsaPercentageMatch !== null) {
      return true;
    }

    return false;
  }

  get realCostBeforeTaxBenefit() {
    return this._realCostBeforeTaxBenefit;
  }

  /* Methods */

  calcEmployerHsaContribution(hsaContributionLimit: number | null) {
    if (hsaContributionLimit && this.isEmployerMatching) {
      return this.calcEmployerHsaMatch(hsaContributionLimit);
    }
    return this.employerHsaFixedContribution;
  }

  calcEmployerHsaMatch(hsaContributionLimit: number | null) {
    /* Calculates employer HSA match based on user's recommended contribution amount */
    if (this.isEmployerMatching) {
      return (
        Math.min(this.oopEstimate, hsaContributionLimit || 0) -
        this.calcRecommendedHsaContribution(hsaContributionLimit)
      );
    }

    return 0;
  }

  // employerHsaPercentageMatch: Can be null
  // employerHsaContributionLimit: Can be null
  calcSlidingEmployerHsaMatch(userContribution) {
    /* Calculates the maximum amount that an employer will match based on user's contribution input */
    if (this.isEmployerMatching && this.employerHsaPercentageMatch && this.employerHsaContributionLimit) {
      return Math.min(
        (this.employerHsaPercentageMatch * userContribution) / 100,
        this.employerHsaContributionLimit,
      );
    }

    return 0;
  }

  calcRecommendedHsaContribution(hsaContributionLimit) {
    return calculateHsaRecommendation(
      this.oopEstimate,
      this.employerHsaPercentageMatch,
      this.employerHsaContributionLimit,
      hsaContributionLimit,
      this.employerHsaFixedContribution,
    );
  }

  calcHsaTaxBenefit(hsaContributionLimit) {
    if (this.isEmployerMatching) {
      return Math.max(this.spendingAccountBenefit - this.calcEmployerHsaMatch(hsaContributionLimit), 0);
    }

    // this.employerHsaFixedContribution can be null
    const emplHsaFixedCont = this.employerHsaFixedContribution ?? 0;
    return Math.max(this.spendingAccountBenefit - emplHsaFixedCont, 0);
  }

  calcOutOfPocket(hsaContributionLimit) {
    // useLegacyCostCalc is true when is_real_cost_before_tax_benefit_enabled is false. Remove when retiring feature flag APP-1028-oop-estimate-uses-comm-api
    if (this.useLegacyCostCalc) {
      if (this.hsaEligible) {
        if (this.isEmployerMatching) {
          return Math.max(this.oopEstimate - this.calcEmployerHsaMatch(hsaContributionLimit), 0);
        }
        // this.employerHsaFixedContribution can be null
        const emplHsaFixedCont = this.employerHsaFixedContribution ?? 0;
        return Math.max(this.oopEstimate - emplHsaFixedCont, 0);
      }
      if (!!this.employerHraContribution || !!this.annualSihraDollarsUsed) {
        return Math.max(
          this.oopEstimate - ((this.employerHraContribution || 0) + (this.annualSihraDollarsUsed || 0)),
          0,
        );
      }
      return this.oopEstimate;
    }

    return Math.floor((this.realCostBeforeTaxBenefit - this.annualPremium) * 100) / 100;
  }

  // Remove method when retiring APP-1028-oop-estimate-uses-comm-api
  calcEstimatedYearlyTotal(hsaContributionLimit) {
    return this.calcOutOfPocket(hsaContributionLimit) + this.annualPremium;
  }

  getInNetworkThreshold(coverageTier) {
    switch (coverageTier) {
      case COVERAGE_TIERS.INDIVIDUAL: {
        return this.individualThresholds.in_network;
      }
      case COVERAGE_TIERS.INDIVIDUAL_CHILD: {
        return this.individualChildThresholds.in_network;
      }
      case COVERAGE_TIERS.INDIVIDUAL_CHILDREN: {
        return this.individualChildrenThresholds.in_network;
      }
      case COVERAGE_TIERS.INDIVIDUAL_SPOUSE: {
        return this.individualSpouseThresholds.in_network;
      }
      default: {
        return this.familyThresholds.in_network;
      }
    }
  }

  getInNetworkDeductible(coverageTier) {
    const thresholds = this.getInNetworkThreshold(coverageTier);
    return thresholds.medical_and_drug_deductible || thresholds.medical_deductible || 0;
  }

  getOutOfNetworkThreshold(coverageTier) {
    switch (coverageTier) {
      case COVERAGE_TIERS.INDIVIDUAL: {
        return this.individualThresholds.out_of_network;
      }
      case COVERAGE_TIERS.INDIVIDUAL_CHILD: {
        return this.individualChildThresholds.out_of_network;
      }
      case COVERAGE_TIERS.INDIVIDUAL_CHILDREN: {
        return this.individualChildrenThresholds.out_of_network;
      }
      case COVERAGE_TIERS.INDIVIDUAL_SPOUSE: {
        return this.individualSpouseThresholds.out_of_network;
      }
      default: {
        return this.familyThresholds.out_of_network;
      }
    }
  }

  // TODO - Remove when /forecasts API supports new coverage tiers
  getIsInNetworkOopLimitComprehensiveLegacy(coverageTier) {
    const threshold = this.getInNetworkThreshold(coverageTier);

    return (
      threshold.medical_oop_max == null &&
      threshold.drug_oop_max == null &&
      threshold.medical_and_drug_oop_max !== null
    );
  }

  getIsInNetworkOopLimitComprehensive() {
    return this.inNetworkDrugOopMax == null && this.inNetworkMedOopMax == null;
  }

  // TODO - Remove when /forecasts API supports new coverage tiers
  getIsInNetworkDeductibleComprehensiveLegacy(coverageTier) {
    const threshold = this.getInNetworkThreshold(coverageTier);

    return (
      threshold.medical_deductible == null &&
      threshold.drug_deductible == null &&
      threshold.medical_and_drug_deductible !== null
    );
  }

  getIsInNetworkDeductibleComprehensive() {
    return this.inNetworkDrugDeductible == null && this.inNetworkMedDeductible == null;
  }

  getIsOonOopLimitComprehensive() {
    return this.oonDrugOopMax == null && this.oonMedOopMax == null;
  }

  getIsOonDeductibleComprehensive() {
    return this.oonDrugDeductible == null && this.oonMedDeductible == null;
  }

  getIsNoInNetworkDeductible() {
    return (
      this.inNetworkDrugDeductible == null &&
      this.inNetworkMedDeductible == null &&
      this.inNetworkDeductible == null
    );
  }

  _getHSAIncentiveTotal() {
    const incentives = this._recommendation.plan_details.health_savings_account?.annual_incentives;
    return incentives?.reduce((acc, { amount }) => acc + amount, 0) || 0;
  }

  _getHRAIncentiveTotal() {
    const incentives = this._recommendation.plan_details.health_reimbursement_arrangement?.annual_incentives;
    return incentives?.reduce((acc, { amount }) => acc + amount, 0) || 0;
  }
}

export default Plan;
