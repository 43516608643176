import { Stack, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import React from 'react';

import { TextField } from 'Containers/App/types';
import { ContentfulCustomContentBlock } from 'ContentfulDefaults/types/_shared';
import { useTextContext } from 'Contexts/textContext';
import { Button, H3, Icon, Paragraph } from 'DesignLibrary/atoms';
import { Accordion } from 'DesignLibrary/atoms/Accordion';
import RichText from 'Shared/RichText';
import Text from 'Shared/Text';

import { FAQContainer, FAQLinkWrapper, TableWrapper } from './styled';

interface FAQProps {
  infoType: 'hsa' | 'hra' | 'fsa';
}

// Jake's Comments:
// Look into Chakra for their Table components
// Should make styling the tables much easier

// Accordion component can get updated to match the design

// Fix ES Disable line at top of file

export const FAQ = ({ infoType }: FAQProps) => {
  const { retrieveContentfulData } = useTextContext();

  const faqContentItemsPath = `health_section.secondary_content.${infoType}_modal.faq_content`;

  const faqContentItems = retrieveContentfulData<ContentfulCustomContentBlock[]>(faqContentItemsPath);

  const faqItems = faqContentItems.map((faqContentItem, idx) => ({
    title: faqContentItem.title,
    content: faqContentItem?.cells?.length ? (
      <FAQTable cells={faqContentItem.cells} />
    ) : (
      <Stack gap={3}>
        <RichText noWrapper={false} field={`${faqContentItemsPath}[${idx}].content` as TextField} />
        {faqContentItem.button_text && faqContentItem.button_link && (
          <FAQLinkWrapper>
            <Button onClick={() => window.open(faqContentItem.button_link)} iconRight="ArrowUpRight">
              {faqContentItem.button_text}
            </Button>
          </FAQLinkWrapper>
        )}
      </Stack>
    ),
  }));

  return (
    faqItems && (
      <FAQContainer>
        <H3 as="h2">
          <Text field={`health_section.secondary_content.${infoType}_modal.faq_header_text`} />
        </H3>
        <Accordion items={faqItems} chakraAccordionProps={{ allowToggle: true }} />
      </FAQContainer>
    )
  );
};

const FAQTable = ({ cells }) => {
  const headerRow = cells[0];
  const body = cells.slice(1);
  return (
    <TableWrapper>
      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              {headerRow.map((col: string, index) => (
                <Th key={`header:${index}`} role="columnheader">
                  <span>{col}</span>
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {body.map((row, index) => (
              <Tr key={index}>
                {row.map((col, i) => {
                  // Row header
                  if (i === 0) {
                    return (
                      <Th scope="row" key={`${index}:${i}`}>
                        <Paragraph size="small">{col}</Paragraph>
                      </Th>
                    );
                  }
                  // Empty Row
                  if (col === '') {
                    return <Td key={`${index}:${i}`}> </Td>;
                  }
                  // Checkmark w/o text
                  if (col === 'X') {
                    return (
                      <Td key={`${index}:${i}`}>
                        <span className="x" aria-label="checkmark">
                          <span aria-hidden>
                            <Icon color="--primary-green" type="CheckCircle" />
                          </span>
                        </span>
                      </Td>
                    );
                  }

                  // Text w/o checkmark for "Tax deferred" column
                  // Needs to be updated if index changes
                  if (i === 2 && index === 1 && row[i]) {
                    return (
                      <Td key={`${index}:${i}`}>
                        <span className="x">
                          <Paragraph size="mini">{col}</Paragraph>
                        </span>
                      </Td>
                    );
                  }

                  // Default: checkmark with text unless equal to tax deferred
                  return (
                    <Td key={`${index}:${i}`}>
                      <span className="x" aria-label="checkmark">
                        <span aria-hidden>
                          <Icon color="--primary-green" type="CheckCircle" />
                        </span>
                        <Paragraph size="mini">{col}</Paragraph>
                      </span>
                    </Td>
                  );
                })}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </TableWrapper>
  );
};
