import {
  Drawer as ChakraDrawer,
  DrawerProps as ChakraDrawerProps,
  DrawerOverlay,
  DrawerContent,
} from '@chakra-ui/react';
import React from 'react';

import { COLORS } from 'DesignLibrary/vars';

import { DrawerTitleContainer } from './styled';
import { Button } from '../Button';
import { IconTypes } from '../Icon';
import { H5 } from '../typography';

export interface DrawerProps extends ChakraDrawerProps {
  title: string | JSX.Element;
  titleIcon?: IconTypes | JSX.Element;
}

export const Drawer = ({
  id,
  isOpen,
  title,
  titleIcon,
  size = 'lg',
  finalFocusRef,
  children,
  onClose,
}: DrawerProps): JSX.Element => (
  <ChakraDrawer
    id={id}
    isOpen={isOpen}
    placement="right"
    onClose={onClose}
    size={size}
    finalFocusRef={finalFocusRef}
  >
    <DrawerOverlay />
    <DrawerContent sx={{ background: COLORS.gray.white, overflow: 'scroll' }}>
      <DrawerTitleContainer>
        <div className="title">
          {/* ADD SUPPLEMENTAL ICONS COMPONENT HERE WHEN CREATED */}
          {/* <SupplementalIcon type={titleIcon} /> */}
          {titleIcon}
          <H5 as="p">{title}</H5>
        </div>
        <Button
          // TODO: Contentful
          aria-label={`Close ${title}`}
          buttonType="standalone"
          iconLeft="X"
          iconProps={{ color: '--text-gray-light' }}
          onClick={onClose}
        />
      </DrawerTitleContainer>
      {children}
    </DrawerContent>
  </ChakraDrawer>
);
