import styled, { css } from 'styled-components';

import { hideAccessibleCheckmark, focusSingle } from 'DesignLibrary/utils';

type AnswerPseudoProps = {
  selected: boolean;
};

export const AnswerContainer = styled.fieldset`
  border: 0;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0;
  gap: var(--sizing-xs);

  input[type='radio'] {
    ${hideAccessibleCheckmark}

    &:focus + .pseudo-answer {
      ${focusSingle}
    }
  }
`;

export const AnswerLabel = styled.label`
  cursor: pointer;
  width: 100%;
`;

export const AnswerPseudo = styled.div<AnswerPseudoProps>`
  background: var(--colors-gray-white);
  border: 2px solid var(--colors-gray-300);
  border-radius: var(--border-radius-xs);
  padding: var(--sizing-sm) var(--sizing-md);
  user-select: none;

  .paragraph,
  p {
    margin: 0;

    & + .paragraph,
    & + p {
      margin-top: var(--sizing-xs);
    }
  }

  ${({ selected }) =>
    selected &&
    css`
      background: var(--primary-blue);
      border-color: var(--primary-blue);
      color: var(--primary-white);
      cursor: default;

      .paragraph,
      p {
        color: var(--primary-white);
      }

      &:hover:not([disabled]) {
        background: var(--primary-blue);
      }
    `}
`;
