import { Flex } from '@chakra-ui/react';
import React from 'react';

import { useFeatureFlagContext } from 'Contexts/featureFlagContext';
import { usePremiumContext } from 'Contexts/premiumContext';
import { useTextContext } from 'Contexts/textContext';
import { FormattedTooltipProps, Icon, Numbers, NumberWithPayPeriod, Paragraph } from 'DesignLibrary/atoms';
import { PremiumPerDeduction } from 'DesignLibrary/atoms/PremiumPerDeduction';
import { TooltipCaps } from 'DesignLibrary/molecules';
import { IconBlockProps } from 'DesignLibrary/molecules/IconBlock';
import Plan from 'Models/plan';
import Text from 'Shared/Text';
import { PAY_PERIOD_VALUES, Recommendation } from 'Types/entities';
import { AnnualPremiumTooltipWithText, YearlyTotalTooltipWithText } from 'Utils/createTooltips';
import { toDollars } from 'Utils/helpers';

import { ContentContainer, CostBreakdownColumn, CostBreakdownRow, CostBreakdownWrapper } from './styled';
import { ViewPlanPageCard } from '../ViewPlanPageCard';

export interface CostBreakdownCardProps {
  recommendation: Recommendation;
  hsaContributionLimit: number | null;
}

export const CostBreakdownCard: React.FC<CostBreakdownCardProps> = ({
  recommendation,
  hsaContributionLimit,
}) => {
  const { payPeriod } = usePremiumContext();
  const { retrieveContentfulData } = useTextContext();

  const title = retrieveContentfulData<string>('health_section.breakdown_heading');
  const icon: IconBlockProps = {
    icon: 'ChartPie',
    color: 'blue',
  };

  const {
    // Remove when retiring feature flag APP-1028-oop-estimate-uses-comm-api
    is_real_cost_before_tax_benefit_enabled,
  } = useFeatureFlagContext();

  // useLegacyCostCalc is true when is_real_cost_before_tax_benefit_enabled is false.
  // Remove arg when retiring feature flag APP-1028-oop-estimate-uses-comm-api
  const plan = new Plan(
    recommendation,
    !is_real_cost_before_tax_benefit_enabled, // Remove arg when retiring feature flag APP-1028-oop-estimate-uses-comm-api
  );

  // Plan properties
  const {
    drugCost,
    medicalCost,
    isEmployerMatching,
    oopEstimate,
    annualPremium,
    hsaEligible,
    hraEligible,
    employerHraContribution,
  } = plan;

  // Plan calculations
  const employerHsaContribution = plan.calcEmployerHsaContribution(hsaContributionLimit);

  const outOfPocket = plan.calcOutOfPocket(hsaContributionLimit);

  const hasEmployerHsaContribution = hsaEligible && !!employerHsaContribution && employerHsaContribution > 0;

  const hasEmployerHraContribution = hraEligible && !!employerHraContribution && employerHraContribution > 0;

  const estimatedYearlyTotal = is_real_cost_before_tax_benefit_enabled
    ? plan.realCostBeforeTaxBenefit
    : plan.calcEstimatedYearlyTotal(
        hsaContributionLimit,
      ); /* Remove when retiring feature flag APP-1028-oop-estimate-uses-comm-api */

  const outOfPocketTooltip = retrieveContentfulData<FormattedTooltipProps>(
    'tool_tips.remaining_oop',
    {} as FormattedTooltipProps,
  );

  const planReimburseCopay = plan.HRAReimbursesCopay;

  const premiumComponent = (
    <PremiumPerDeduction
      annualPremium={plan.annualPremium}
      deductions={plan.annualPremiumDeductions}
      currency
      prefix="+"
      size="small"
      hideTooltip
      precision={2}
      keyHint={`plan-${plan.planId}-premium-per-deduction`}
    />
  );

  return (
    <ViewPlanPageCard title={title} icon={icon} border={false}>
      <ContentContainer>
        <CostBreakdownWrapper>
          <CostBreakdownColumn>
            <div className="details">
              {/* Premium Costs */}
              <CostBreakdownRow>
                <Paragraph size="small">
                  <Text field="health_section.premium_cost_receipt.premiumCosts" />
                </Paragraph>
                {premiumComponent}
              </CostBreakdownRow>

              {/* Yearly Multiplier */}
              <CostBreakdownRow>
                <Paragraph size="small">
                  <Text field="health_section.premium_cost_receipt.yearlyMultiplier" />
                </Paragraph>
                <Numbers size="small">{` x ${plan.annualPremiumDeductions || PAY_PERIOD_VALUES[payPeriod]}`}</Numbers>
              </CostBreakdownRow>
            </div>

            <div>
              {/* Yearly Premiums */}
              <CostBreakdownRow className="subtotal">
                <AnnualPremiumTooltipWithText
                  color="--text-black"
                  keyHint={`plan-${plan.planId}-yearly-premium`}
                />
                <Numbers size="small" weight="bold" color="--text-black">
                  {toDollars(annualPremium, Number.isInteger(annualPremium) ? 0 : 2)}
                </Numbers>
              </CostBreakdownRow>
            </div>
          </CostBreakdownColumn>

          {/* Plus Symbol */}
          <div className="plus-wrapper">
            <span className="plus">
              <Icon type="Plus" size="small" color="--text-gray" weight="bold" />
            </span>
          </div>

          {/* Out-Of-Pocket Estimate */}
          <CostBreakdownColumn>
            <div className="details">
              <section className="sub-details">
                <CostBreakdownRow>
                  <Paragraph size="small">
                    <Text field="health_section.oop_cost_receipt.oopEstimate" />
                  </Paragraph>

                  <NumberWithPayPeriod
                    prefix="+"
                    value={oopEstimate}
                    payPeriodType="yearly"
                    size="small"
                    hideTooltip
                    keyHint={`plan-${plan.planId}-oop-estimate`}
                  />
                </CostBreakdownRow>

                {/* Prescription Drugs */}
                <CostBreakdownRow>
                  <Flex align="center" gap={2}>
                    <Icon type="ArrowElbowDownRight" color="--text-gray-light" size="small" />
                    <Paragraph size="small">
                      <Text field="health_section.oop_cost_receipt.drugs" />
                    </Paragraph>
                  </Flex>
                  <Paragraph size="small">{toDollars(drugCost)}</Paragraph>
                </CostBreakdownRow>

                {/* Medical Costs */}
                <CostBreakdownRow>
                  <Flex align="center" gap={2}>
                    <Icon type="ArrowElbowDownRight" color="--text-gray-light" size="small" />
                    <Paragraph size="small">
                      <Text field="health_section.oop_cost_receipt.medicalCare" />
                    </Paragraph>
                  </Flex>
                  <Paragraph size="small">{toDollars(medicalCost)}</Paragraph>
                </CostBreakdownRow>
              </section>

              {/* HSA Contribution (if applicable) */}
              {hasEmployerHsaContribution &&
                (isEmployerMatching ? (
                  <CostBreakdownRow type="hsa">
                    <Paragraph size="small">
                      <Text field="health_section.oop_cost_receipt.hsaMatch" />
                    </Paragraph>
                    <div className="highlight">
                      <Numbers
                        size="small"
                        weight="bold"
                        color="--text-black"
                      >{`-${toDollars(employerHsaContribution)}`}</Numbers>
                    </div>
                  </CostBreakdownRow>
                ) : (
                  <CostBreakdownRow type="hsa">
                    <Paragraph size="small">
                      <Text field="health_section.oop_cost_receipt.hsaContribution" />
                    </Paragraph>
                    <div className="highlight">
                      <Flex gap={2}>
                        <Icon type="Coins" weight="duotone" color="--primary-blue" />
                        <Numbers size="small" weight="bold" color="--primary-blue">
                          {`-${toDollars(employerHsaContribution)}`}
                        </Numbers>
                      </Flex>
                    </div>
                  </CostBreakdownRow>
                ))}

              {planReimburseCopay === false && (
                <CostBreakdownRow type="hra">
                  <Paragraph size="small">
                    <Text field="health_section.oop_cost_receipt.noReimburseCopay" />
                  </Paragraph>
                </CostBreakdownRow>
              )}

              {/* HRA Contribution (if applicable) */}
              {hasEmployerHraContribution && (
                <CostBreakdownRow type="hra">
                  <Paragraph size="small">
                    <Text field="health_section.oop_cost_receipt.hraContribution" />
                  </Paragraph>
                  <div className="highlight">
                    <Flex gap={2}>
                      <Icon type="Coins" weight="duotone" color="--primary-blue" />
                      <Numbers size="small" weight="bold" color="--primary-blue">
                        {`-${toDollars(employerHraContribution)}`}
                      </Numbers>
                    </Flex>
                  </div>
                </CostBreakdownRow>
              )}
            </div>

            {/* Remaining Out-Of-Pocket */}
            <CostBreakdownRow className="subtotal">
              <TooltipCaps
                tooltip={outOfPocketTooltip}
                color="--text-black"
                keyHint={`plan-${plan.planId}-cost-breakdown-oop`}
              >
                {outOfPocketTooltip.title}
              </TooltipCaps>
              {(hsaEligible || hraEligible) && (
                <Numbers size="small" weight="bold" color="--text-black">
                  {toDollars(outOfPocket)}
                </Numbers>
              )}
              {!hsaEligible && !hraEligible && (
                <Numbers size="small" weight="bold" color="--text-black">
                  {toDollars(oopEstimate)}
                </Numbers>
              )}
            </CostBreakdownRow>
          </CostBreakdownColumn>
        </CostBreakdownWrapper>

        {/* Yearly Expense Total */}
        <CostBreakdownRow className="grand-total">
          <YearlyTotalTooltipWithText payPeriod={payPeriod} color="--text-black" />
          <NumberWithPayPeriod
            payPeriodType="yearly"
            value={estimatedYearlyTotal}
            size="medium"
            fontWeight="bold"
            keyHint={`plan-${plan.planId}-estimated-yearly-total`}
          />
        </CostBreakdownRow>
      </ContentContainer>
    </ViewPlanPageCard>
  );
};
