import styled from 'styled-components';

import { breakpoint } from 'DesignLibrary/global';

export const ContentContainer = styled.div``;

export const CostBreakdownWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--sizing-xs);

  ${breakpoint.TABLET} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: var(--sizing-sm);
  }

  .plus-wrapper {
    height: 1px;
    width: 100%;
    z-index: 2;
    position: relative;

    ${breakpoint.TABLET} {
      height: 1px;
      width: 1px;
      position: absolute;
      left: 50%;
      bottom: var(--sizing-lg);
      transform: translateX(-50%);
    }

    .plus {
      height: var(--sizing-lg);
      width: var(--sizing-lg);
      background: var(--primary-white);
      border: var(--border-thick);
      border-radius: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%) translateY(50%);
    }
  }
`;

export const CostBreakdownColumn = styled.div`
  border: 2px solid var(--border-gray);
  border-radius: var(--border-radius-xs);
  overflow: hidden;

  ${breakpoint.TABLET} {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .details {
    padding: var(--sizing-sm);

    ${breakpoint.TABLET} {
      padding: var(--sizing-md);
    }

    & > * + * {
      margin-top: var(--sizing-sm);

      ${breakpoint.TABLET} {
        margin-top: var(--sizing-md);
      }
    }
  }

  .sub-details {
    & > * + * {
      margin-top: var(--sizing-xs);
    }
  }
`;

interface CostBreakdownRowProps {
  type?: 'hsa' | 'hra';
}

export const CostBreakdownRow = styled.div<CostBreakdownRowProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.subtotal {
    background-color: var(--background-gray-light);
    padding: var(--sizing-sm);

    ${breakpoint.TABLET} {
      padding: var(--sizing-sm) var(--sizing-md);
    }
  }

  &.grand-total {
    padding: var(--sizing-sm) var(--sizing-sm) var(--sizing-xs);

    ${breakpoint.TABLET} {
      padding: var(--sizing-lg) var(--sizing-md) var(--sizing-sm);
    }
  }

  .highlight {
    position: relative;
    z-index: 0;

    &:after {
      content: '';
      display: block;
      width: calc(100% + var(--sizing-sm));
      height: calc(100% + var(--sizing-sm));
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
      border-radius: var(--border-radius-xs);
      background: var(--colors-blue-50);
    }
  }
`;
