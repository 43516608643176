import { debounce } from 'lodash';
import React, { useState, forwardRef, useEffect, useCallback, Ref } from 'react';

import { Persona } from 'Containers/TaxSavingsPage/types';
import { useTextContext } from 'Contexts/textContext';
import { FormattedTooltipProps, H6 } from 'DesignLibrary/atoms';
import Counter from 'DesignLibrary/atoms/inputs/Counter';
import TextInput from 'DesignLibrary/atoms/inputs/TextInput';
import { TooltipCaps } from 'DesignLibrary/molecules';

import { PersonaToggle } from './PersonaToggle';
import { InputContainer, Title } from './styled';

export interface LongTermDetailsProps {
  currentHsaBalance: number | null;
  persona: Persona;
  retirementAge: number;
  handleForecastInputs: (balance: number, persona: Persona, retirementAge: number) => void;
}

export const LongTermDetails = forwardRef(
  (
    { currentHsaBalance, persona, retirementAge, handleForecastInputs }: LongTermDetailsProps,
    ref: Ref<HTMLInputElement>,
  ) => {
    const { retrieveContentfulData } = useTextContext();

    const [currentValue, setCurrentValue] = useState<number>(currentHsaBalance || 0);
    const [currentRetireAge, setCurrentRetireAge] = useState<number>(retirementAge);
    const [currentPersona, setCurrentPersona] = useState<Persona>(persona);

    useEffect(() => {
      handleForecastInputs(currentValue, currentPersona, currentRetireAge);
    }, []);

    const debouncedUpdate = useCallback(
      debounce((props = {}) => {
        // handleForecastInputs updates redux which triggers the saga that makes the forecasting calls
        handleForecastInputs(props.balance, props.persona, props.retirementAge);
      }, 1000),
      [],
    );

    const handleChangeHsaBalance = (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue: string = e.target.value.replace(/,/g, ''); // Remove commas from value
      const parsed: number | '' = newValue === '' ? '' : parseInt(newValue, 10);
      if (Number.isInteger(parsed) || newValue === '') {
        const maxAllowed = parseInt(e.target.max, 10) || Infinity;
        if (parsed === '' || (parsed <= maxAllowed && parsed >= 0)) {
          setCurrentValue(parsed as number);
          debouncedUpdate({
            balance: parsed,
            persona: currentPersona,
            retirementAge: currentRetireAge,
          });
        }
      }
    };

    const handleChangeRetirementAge = (age: number) => {
      setCurrentRetireAge(age);
      debouncedUpdate({
        balance: currentValue,
        persona: currentPersona,
        retirementAge: age,
      });
    };

    const handleChangePersona = (persona: Persona) => {
      // No debounce onchange persona
      setCurrentPersona(persona);
      handleForecastInputs(currentValue, persona, currentRetireAge);
    };

    return (
      <InputContainer>
        <Title>
          {/* TODO: CONTENTFUL Add Field */}
          <H6 as="h3">Investing Playground</H6>
        </Title>
        <div className="input flex">
          <TooltipCaps
            // TODO: CONTENTFUL Update field
            tooltip={retrieveContentfulData<FormattedTooltipProps>(
              'hsa.interactive_section.tooltips.current_hsa_balance',
            )}
            keyHint="current-hsa-balance"
          >
            {/* TODO: CONTENTFUL Add Field */}
            Current HSA Balance
          </TooltipCaps>
          <div className="cont-input">
            <TextInput
              inputType="currency"
              inputMode="numeric"
              placeholder="$10,000"
              handleChange={handleChangeHsaBalance}
              value={currentValue ? currentValue.toString() : ''}
              name="hsaBalanceInput"
              max={999999}
              stretch
              ref={ref}
            />
          </div>
        </div>
        <div className="input flex">
          <H6 color="--text-gray-light">
            {/* TODO: CONTENTFUL Add Field */}
            Retirement Age
          </H6>
          <Counter
            label="Retirement Age"
            id="retirement-age-counter"
            minValue={30}
            maxValue={80}
            startValue={currentRetireAge}
            handleChange={handleChangeRetirementAge}
          />
        </div>
        <div className="input">
          <H6 color="--text-gray-light">
            {/* TODO: CONTENTFUL Add Field */}
            How do you use your HSA?
          </H6>
          <div>
            <PersonaToggle
              labelId="persona"
              selectedValue={currentPersona}
              handleSelect={handleChangePersona}
            />
          </div>
        </div>
      </InputContainer>
    );
  },
);

LongTermDetails.displayName = 'LongTermDetails';
