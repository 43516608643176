import styled from 'styled-components';

import { media } from 'DesignLibrary/global';

interface MemberEditProps {
  color: string;
}

export const MemberEdit = styled.div<MemberEditProps>`
  background: var(--primary-white);
  border-radius: var(--border-radius-xs);
  border: 2px solid transparent;
  border-color: ${({ color }) => `var(--primary-${color})`};

  .member-header {
    height: var(--sizing-2xl);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 var(--sizing-sm);
    border-radius: 6px 6px 0 0;
    background-color: ${({ color }) => `var(--colors-${color}-50)`};
    color: ${({ color }) => `var(--colors-${color}-600)`};

    ${media.TABLET`
      padding: 0 var(--sizing-md);
    `}

    .title {
      display: flex;
      align-items: center;
      column-gap: var(--sizing-sm);
    }
  }
`;

export const MemberForms = styled.div`
  padding: var(--sizing-sm);
  display: flex;
  flex-direction: column;
  row-gap: var(--sizing-sm);

  ${media.TABLET`
    padding: var(--sizing-md);
    row-gap: var(--sizing-md);
  `}

  > section {
    &.sex-and-age {
      overflow: visible;
      padding-bottom: var(--sizing-sm);
      display: flex;
      /* flex-direction: column; */
      gap: var(--sizing-sm);
      border-bottom: 1px solid var(--border-gray);
      align-items: center;

      &.flex-column {
        flex-direction: column;
      }

      ${media.TABLET`
        overflow: initial;
        flex-direction: row !important;
        gap: var(--sizing-md);
        padding: 0;
        border: 0;
      `}
    }
  }

  .question {
    & > * + * {
      margin-top: var(--sizing-xs);
    }

    ${media.TABLET`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--sizing-sm);

      & > * {
        margin: 0;
      }
    `}

    &.sex-question {
      flex-grow: 2;

      ${media.TABLET`
        border-right: 1px solid var(--border-gray);
        padding-right: var(--sizing-md);

        .sex-toggle {
          width: var(--sizing-4xl);
          width: 100%;
          flex-wrap: wrap !important;
        }
      `}
    }

    &.age-question {
      flex-shrink: 0;

      input {
        max-width: 96px;
        min-width: 24px;
      }
    }
  }
`;

export const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--sizing-xs);

  ${media.TABLET`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: var(--sizing-md);
  `}

  .question-container {
    .paragraph > span {
      display: inline-flex;
      position: relative;
      top: 2px;
      left: 4px;
    }
  }

  .toggle-container {
    flex-shrink: 0;
    width: 100%;

    ${media.TABLET`
      width: calc(var(--sizing-md) * 10);
    `}
  }
`;

export const QuestionHorizontalLine = styled.div`
  height: 1px;
  width: 100%;
  border-top: 1px solid var(--border-gray);
`;

export const PrescriptionContainer = styled.section`
  > button {
    text-transform: none;
  }
`;

// Going to borrow this for Planned Procedures as well
export const UtilContainer = styled.section`
  > button {
    margin-top: var(--sizing-sm);
    text-transform: none;
  }
`;
