import { Box, Stack } from '@chakra-ui/react';
import { get } from 'lodash';
import React from 'react';

import {
  CardContainer,
  CompareCheck,
  MainContentContainer,
} from 'Containers/ResultPage/Recommendation/styled';
import { useCompareContext } from 'Contexts/compareContext';
import { useProfileContext } from 'Contexts/profileContext';
import { useTextContext } from 'Contexts/textContext';
import Checkbox from 'DesignLibrary/atoms/inputs/Checkbox';
import Plan from 'Models/plan';
import { PlanFit } from 'Shared/PlanFit';
import Text from 'Shared/Text';
import { Recommendation as RecommendationEntity } from 'Types/entities';
import { PeopleLikeYou } from 'Utils/apiTypes';

import { Buttons } from './Buttons';
import { CardHeader } from './CardHeader';
import { Disclaimers } from './Disclaimers';
import { InNetworkProviders } from './InNetworkProviders';
import { Values } from './Values';

export interface RecommendationProps {
  dataTestId: string;
  client: string;
  plan: Plan;
  isFamily: boolean;
  hsaContributionLimit: number;
  isSelected: boolean;
  isEntered: boolean;
  killMedRec: boolean;
  isImprovedRecExplanationEnabled: boolean;
  idx: number;
  isPlanComparisonEnabled: boolean;
  maximumAvailableAnnualSihraContribution: number;
  score?: number;
  searchProviderLinks: {
    [key: string]: string;
  } | null;
  plyData: PeopleLikeYou | Record<string, never>;
  viewPlan: (planId: string) => void;
  choosePlan: (recommendation: RecommendationEntity) => void;
  deselectPlan: () => void;
  handleOpenPlanEntry: () => void;
}

export const Recommendation = ({
  dataTestId,
  client,
  plan,
  isFamily,
  isImprovedRecExplanationEnabled,
  hsaContributionLimit,
  isEntered,
  isSelected,
  killMedRec,
  idx,
  isPlanComparisonEnabled,
  maximumAvailableAnnualSihraContribution,
  score = 0,
  searchProviderLinks,
  plyData,
  viewPlan,
  choosePlan,
  deselectPlan,
  handleOpenPlanEntry,
}: RecommendationProps) => {
  const { comparablePlans, canAdd, togglePlan } = useCompareContext();

  const { retrieveContentfulData } = useTextContext();

  const { selectedPublicationInfo } = useProfileContext();

  const employerHsaContribution = plan?.calcEmployerHsaContribution(hsaContributionLimit);

  const checkCompared = comparablePlans.some((e) => e.planId === plan?.planId);

  const handleCompare = () => {
    togglePlan(plan);
  };

  const displaySearchProviderButton = !isEntered && searchProviderLinks !== null;

  const searchProviderButtonLink = get(searchProviderLinks, plan.planId, '');

  const compareAriaLabel = `${retrieveContentfulData<string>(
    'health_section.button_text.compare',
  )} ${plan.planMarketingName}`;
  const docFinderEnabled = selectedPublicationInfo?.publication?.docfinder_enabled || false;

  return (
    <div>
      <CardContainer
        data-testid={dataTestId}
        idx={idx}
        isSelected={isSelected}
        className="recommendation-card"
      >
        {!killMedRec && isImprovedRecExplanationEnabled && <PlanFit score={score} plyData={plyData} />}

        <Stack p={[4, 6]} gap={3} pos="relative">
          <CardHeader
            plan={plan}
            killMedRec={killMedRec}
            isImprovedRecExplanationEnabled={isImprovedRecExplanationEnabled}
            isSelected={isSelected}
            searchProviderLink={displaySearchProviderButton ? searchProviderButtonLink : ''}
          />

          {isPlanComparisonEnabled && (
            <CompareCheck>
              <Checkbox
                aria-label={compareAriaLabel}
                sizing="small"
                disabled={!canAdd && !checkCompared}
                checked={checkCompared}
                handleChange={handleCompare}
                testId="compare-plan-checkbox"
              >
                <Text field="health_section.button_text.compare" />
              </Checkbox>
            </CompareCheck>
          )}

          <MainContentContainer>
            {/* // TODO: Give these more descriptive names */}
            <Disclaimers
              plan={plan}
              employerHsaContribution={employerHsaContribution}
              maximumAvailableAnnualSihraContribution={maximumAvailableAnnualSihraContribution}
              isEntered={isEntered}
              hsaContributionLimit={hsaContributionLimit}
            />

            <Box>
              <Values
                client={client}
                plan={plan}
                isFamily={isFamily}
                hsaContributionLimit={hsaContributionLimit}
                isProviderSearchEnabled={docFinderEnabled}
              />
              {docFinderEnabled && <InNetworkProviders plan={plan} />}
            </Box>
          </MainContentContainer>
          <Buttons
            plan={plan}
            isSelected={isSelected}
            isEntered={isEntered}
            viewPlan={viewPlan}
            choosePlan={choosePlan}
            deselectPlan={deselectPlan}
            handleOpenPlanEntry={handleOpenPlanEntry}
          />
        </Stack>
      </CardContainer>
    </div>
  );
};

export default Recommendation;
