import React from 'react';

import { RangeDescription } from 'ContentfulDefaults/types/ply';
import { useTextContext } from 'Contexts/textContext';
import { Paragraph, Tooltip } from 'DesignLibrary/atoms';

import { PLYVisualization } from './PLYVisualization';
import { DistContainer, LowMedHighContainer } from './styled';
import { Group } from '../../_shared/styled';
import { DollarIcon } from '../styled';

interface DistWrapperProps {
  percent: number;
  group: Group;
}

interface LowMedHighDistProps {
  low: number;
  medium: number;
  high: number;
}

const DistWrapper = ({ percent, group }: DistWrapperProps) => {
  const { retrieveContentfulData } = useTextContext();

  const symbol = retrieveContentfulData<RangeDescription>(
    'people_like_you.healthcare_usage_range_descriptions',
  )[group].symbol;
  const tooltipText = retrieveContentfulData<RangeDescription>(
    'people_like_you.healthcare_usage_range_descriptions',
  )[group].tooltip;
  const title = retrieveContentfulData<RangeDescription>(
    'people_like_you.healthcare_usage_range_descriptions',
  )[group].title;

  return (
    <DistContainer group={group}>
      <div className="header">
        <div className="title">
          <DollarIcon group={group}>{symbol}</DollarIcon>
          <Tooltip label={tooltipText}>
            <Paragraph size="small" weight="bold" color="--text-black" underline>
              {title}
            </Paragraph>
          </Tooltip>
        </div>
        <p className="percent">{percent}%</p>
      </div>
      <PLYVisualization percent={percent} group={group} />
    </DistContainer>
  );
};

export const LowMedHighDist = ({ low, medium, high }: LowMedHighDistProps) => {
  const standardizePercent = (percent) => Math.floor(percent * 100);

  const distValues = [
    {
      group: 'low',
      percent: low,
    },
    {
      group: 'moderate',
      percent: medium,
    },
    {
      group: 'high',
      percent: high,
    },
  ];
  distValues.sort((a, b) => b.percent - a.percent);

  const roundedTotal = distValues.map((dist) => standardizePercent(dist.percent)).reduce((a, b) => a + b);
  const modalSupplement = 100 - roundedTotal;

  return (
    <LowMedHighContainer>
      <DistWrapper
        percent={standardizePercent(distValues[0].percent) + modalSupplement}
        group={distValues[0].group}
      />
      <section className="lower">
        {distValues.slice(1).map((distValue) => (
          <DistWrapper
            key={`distValue-${distValue.group}`}
            percent={standardizePercent(distValue.percent)}
            group={distValue.group}
          />
        ))}
      </section>
    </LowMedHighContainer>
  );
};
