import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import { ConnectedRouter, push } from 'connected-react-router';
import React from 'react';
import { Provider } from 'react-redux';

import Error500 from 'Shared/Error500';
import { ERROR_PATH } from 'Utils/urls';

const chakraThemeOverrides = extendTheme({
  styles: {
    global: {
      body: {
        fontFamily: null,
        lineHeight: null,
        backgroundColor: null,
      },
    },
  },
});

export const withResources = (children, store, history) => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Sentry.ErrorBoundary
        fallback={() => {
          store.dispatch(push(ERROR_PATH));
          return <Error500 />;
        }}
        beforeCapture={(scope) => {
          scope.setTag('triggeredFallbackUi', history.location.pathname);
        }}
      >
        <ChakraProvider resetCSS={false} theme={chakraThemeOverrides}>
          {children}
        </ChakraProvider>
      </Sentry.ErrorBoundary>
    </ConnectedRouter>
  </Provider>
);
