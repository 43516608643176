import { Box, Stack } from '@chakra-ui/react';
import { debounce } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';

import { DoctorApi, DoctorType, ProviderLocationProps } from 'Types/entities';
import { getDoctor } from 'Utils/docfinder';
import { createProviderData, isValidZipCode } from 'Utils/helpers';
import { sendInsightsEvent } from 'Utils/insights';

import ProviderSelect from '../ProviderSelect';
import ZipDistanceSection from '../ZipDistanceSection';

interface ProviderSearchProps {
  searchText: string;
  setSearchText: (prev: string) => void;
  zipcodeLocal: string;
  setZipcodeLocal: (prev: string) => void;
  setDoctorList: (prev: DoctorType[]) => void;
  options: ProviderLocationProps[] | [];
  setOptions: (options: ProviderLocationProps[]) => void;
  setShowSearchPage: (value: boolean) => void;
  setSelectedProvider: (value: ProviderLocationProps) => void;
  distance: string;
  setDistance: (prev: string) => void;
}

const ProviderSearch = ({
  searchText,
  setSearchText, // Function to set searched text
  zipcodeLocal,
  setZipcodeLocal,
  setDoctorList,
  options,
  setOptions,
  setShowSearchPage, // Function to check add button is clicked and set show search page
  setSelectedProvider, // Function to set selected provider
  distance,
  setDistance,
}: ProviderSearchProps) => {
  // Handler for the input change

  const [showLoading, setShowLoading] = useState<boolean>(true);
  const [fetched, setFetched] = useState<boolean>(false); // Function to hide and show options message

  const distanceRef = useRef<HTMLDivElement>(null);

  // Get doctors (20 records at a time)
  useEffect(() => {
    const fetchOptions = async (inputValue: string) => {
      const docParams = {
        name: inputValue,
        address: zipcodeLocal,
        distance,
        page: 0,
      };
      try {
        const searchResults = (await getDoctor(docParams)) as DoctorApi;

        setDoctorList(searchResults?.data);
        const optionsList = searchResults?.data?.map((result: DoctorType) => {
          return createProviderData(result);
        });

        if (optionsList) {
          setOptions(optionsList);
        }
        const totalCount = searchResults && searchResults.parameters.total_count;
        const event =
          totalCount && totalCount > 0
            ? 'number_of_results_search_provider'
            : 'zero_results_in_search_provider';
        sendInsightsEvent(null, 'searched_for_a_provider', docParams);
        sendInsightsEvent(null, event, {
          ...docParams,
          total_count: totalCount,
        });
        setFetched(true);
      } catch (error) {
        throw new Error(`Doc finder error: ${error}`);
      }
    };

    // trigger search after 300ms
    const debouncedFetchOptions = debounce(fetchOptions, 300);

    if (searchText.length > 2 && isValidZipCode(zipcodeLocal)) {
      debouncedFetchOptions(searchText);
    }
    return () => {
      debouncedFetchOptions.cancel();
    };
  }, [searchText, distance, zipcodeLocal]);

  return (
    <Box>
      <Stack
        bg="var(--background-gray)"
        borderRadius="var(--border-radius-xs)"
        p={[4, 6]}
        pt={[4, 4]}
        gap={4}
      >
        {/* Component for Zip-code and Distance */}
        <ZipDistanceSection
          zipcodeLocal={zipcodeLocal}
          setZipcodeLocal={setZipcodeLocal}
          distance={distance}
          distanceRef={distanceRef}
          setOptions={setOptions}
          setShowLoading={setShowLoading}
          setFetched={setFetched}
          setDistance={setDistance}
        />
        {/* Component for Search Field */}
        <div data-testid="select-table">
          <ProviderSelect
            setSearchText={setSearchText}
            searchText={searchText}
            setShowSearchPage={setShowSearchPage}
            setOptions={setOptions}
            setSelectedProvider={setSelectedProvider}
            options={options}
            zipcode={zipcodeLocal}
            setFetched={setFetched}
            fetched={fetched}
            showLoading={showLoading}
            setShowLoading={setShowLoading}
            distanceRef={distanceRef}
          />
        </div>
      </Stack>
    </Box>
  );
};

export default ProviderSearch;
