import styled, { css } from 'styled-components';

import { media } from 'DesignLibrary/global';
import { hideAccessibleCheckmark, focusSingle } from 'DesignLibrary/utils';

type PersonaPseudoProps = {
  selected: boolean;
};

export const PersonaContainer = styled.fieldset`
  border: 0;
  position: relative;
  padding: 0;

  ${media.TABLET`
    flex-direction: row;
  `}

  input[type='radio'] {
    ${hideAccessibleCheckmark}

    &:focus + .pseudo-answer {
      ${focusSingle}
      box-shadow: inset 0 0 0 2px var(--primary-black);
      border: none;
    }
    &:focus:not(:focus-visible) + .pseudo-answer {
      outline: 0;
      box-shadow: inset 0 0 0 2px var(--primary-black);
    }
    &:focus-visible + .pseudo-answer {
      ${focusSingle}
      box-shadow: inset 0 0 0 2px var(--primary-black);
      border: none;
    }
  }
`;

export const PersonaLabel = styled.label`
  cursor: pointer;

  & > * + * {
    margin-top: var(--sizing-xs);
  }
`;

export const PersonaPseudo = styled.div<PersonaPseudoProps>`
  background: var(--background-gray);
  border-radius: var(--border-radius-xs);
  padding: var(--sizing-sm);
  display: flex;
  align-items: center;
  gap: var(--sizing-sm);

  ${({ selected }) =>
    selected &&
    css`
      box-shadow: inset 0 0 0 2px var(--primary-black);
      cursor: default;
    `}
`;
