import styled from 'styled-components';

import { breakpoint } from 'DesignLibrary/global';

export const TopBar = styled.div`
  padding: var(--sizing-md) 0;
`;

export const Container = styled.section`
  ${breakpoint.DESKTOP} {
    display: grid;
    grid-template-columns: minmax(240px, 320px) auto;
    column-gap: var(--sizing-md);
    align-items: flex-start;
  }

  .header-card {
    ${breakpoint.DESKTOP} {
      position: sticky;
      top: var(--sizing-md);
    }
  }
`;

export const Footnote = styled.div`
  text-align: right;
`;

export const CtaCardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--sizing-sm);

  ${breakpoint.TABLET} {
    flex-direction: row;
  }
`;
