import React, { FC } from 'react';

import { BadgeContainer } from './styled';
import { useViewport } from '../../context/viewportContext';
import { Icon } from '../Icon';

type SizeProps = 'small' | 'normal' | 'large';

export interface SelectedBadgeProps {
  type?: 'selected' | 'auto_enrolled';
  sizeOverride?: SizeProps | null;
}

export const SelectedBadge: FC<SelectedBadgeProps> = ({ type = 'selected', sizeOverride }) => {
  const { device } = useViewport();

  let size: SizeProps = 'small';
  if (device === 'tablet') size = 'normal';
  if (device === 'desktop') size = 'large';
  if (sizeOverride) size = sizeOverride;

  return (
    <BadgeContainer type={type} size={size} data-testid={type}>
      <div className="check">
        <Icon
          type="Check"
          weight="bold"
          color="--primary-white"
          size={size === 'large' ? 'large' : 'normal'}
        />
      </div>

      <div className="background">
        <svg width="64" height="64" viewBox="0 0 70 70">
          <path d="M27.3504 4.33377C31.3073 -0.494396 38.6926 -0.494395 42.6495 4.33377C44.7463 6.89224 47.9834 8.23307 51.2751 7.90662C57.4872 7.29057 62.7093 12.5127 62.0933 18.7248C61.7668 22.0165 63.1076 25.2536 65.6661 27.3504C70.4943 31.3073 70.4943 38.6926 65.6661 42.6495C63.1076 44.7463 61.7668 47.9834 62.0933 51.2751C62.7093 57.4872 57.4872 62.7093 51.2751 62.0933C47.9834 61.7668 44.7463 63.1076 42.6495 65.6661C38.6926 70.4943 31.3073 70.4943 27.3504 65.6661C25.2536 63.1076 22.0165 61.7668 18.7248 62.0933C12.5127 62.7093 7.29057 57.4872 7.90662 51.2751C8.23307 47.9834 6.89224 44.7463 4.33377 42.6495C-0.494396 38.6926 -0.494395 31.3073 4.33377 27.3504C6.89224 25.2536 8.23307 22.0165 7.90662 18.7248C7.29057 12.5127 12.5127 7.29057 18.7248 7.90662C22.0165 8.23307 25.2536 6.89224 27.3504 4.33377Z" />
        </svg>
      </div>
    </BadgeContainer>
  );
};
