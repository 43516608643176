import { useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';

export const Portal = ({ children }) => {
  const portalRoot = document.getElementById('portal-root');
  const el = useMemo(() => document.createElement('div'), []);
  useEffect(() => {
    portalRoot?.appendChild(el);
    return () => {
      portalRoot?.removeChild(el);
    };
  }, []);
  return ReactDOM.createPortal(children, el);
};
