import React from 'react';

import { useTextContext } from 'Contexts/textContext';
import { Button, H3, Paragraph } from 'DesignLibrary/atoms';
import { PageLayout } from 'DesignLibrary/atoms/PageLayout';
import Text from 'Shared/Text';

import { EligNone } from '../styled';

interface NoEligiblePlansProps {
  goToClientSurvey: () => void;
  goToSupplemental: () => void;
}

const NoEligiblePlans = ({ goToClientSurvey, goToSupplemental }: NoEligiblePlansProps) => {
  const { retrieveContentfulData } = useTextContext();

  // client has external enrollment option
  const externalPlanLink = retrieveContentfulData<string>('health_section.no_plans_modal.externalPlanLink');

  return (
    <PageLayout>
      <EligNone>
        <H3>
          <Text field="health_section.no_plans_modal.title" />
        </H3>
        <Paragraph>
          <Text field="health_section.no_plans_modal.description" />
        </Paragraph>
        {externalPlanLink ? (
          <>
            <Button iconRight="ArrowUpRight" url={externalPlanLink}>
              <Text field="health_section.no_plans_modal.externalButtonText" />
            </Button>
            <Button buttonType="primary" onClick={goToSupplemental}>
              <Text field="health_section.button_text.noPlans" />
            </Button>
          </>
        ) : (
          <Button id="modify-employee-status-btn" buttonType="primary" onClick={goToClientSurvey}>
            <Text field="health_section.button_text.noPlans" />
          </Button>
        )}
      </EligNone>
    </PageLayout>
  );
};

export default NoEligiblePlans;
