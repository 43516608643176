import { Text } from '@chakra-ui/react';
import { Warning } from '@phosphor-icons/react';
import React, { useEffect } from 'react';

import { GlobalReducerState } from 'app/reducers';
import { Tooltip } from 'DesignLibrary/atoms';
import { PageLayout } from 'DesignLibrary/atoms/PageLayout';
import { useViewport } from 'DesignLibrary/context/viewportContext';
import { COLORS } from 'DesignLibrary/vars';
import { getEnvironmentFromUrl, WELCOME_PATH } from 'Utils/urls';

import Desktop from './Desktop';
import Mobile from './Mobile';
import { getPages } from './pages';
import { DEPLOY_ENVIRONMENTS } from '../../../constants';

export const determineRoutes = (
  isMobile: boolean,
  isFullBenefitEnabled: boolean,
  showHsaNav: boolean,
  showTaxSavings: boolean,
  showResultPage: boolean,
  hasUnsavedProfileChanges: boolean,
) => {
  // Need to fix this behavior @dan_warrick
  // Causes nav bar to break
  if (hasUnsavedProfileChanges) {
    //   return [];
  }
  if (isMobile) {
    return getPages(isFullBenefitEnabled, showHsaNav, showTaxSavings, showResultPage);
  }
  return getPages(isFullBenefitEnabled, showHsaNav, showTaxSavings, showResultPage);
};

export interface NavigationProps {
  path: string;
  isFullBenefitEnabled: boolean;
  showLanguageToggle: boolean;
  showHsaNav: boolean;
  showTaxSavings: boolean;
  showResultPage: boolean;
  canChangePayPeriod: boolean;
  unlockedPath: string;
  isAlexIdEnabled: boolean;
  hasUnsavedProfileChanges: boolean;
  hideNavItems: boolean;
  handleUnlockPath: (path: string) => void;
  getStore: () => GlobalReducerState;
  routerPush: () => void;
}

export const Navigation = ({
  path,
  isFullBenefitEnabled,
  showLanguageToggle,
  showHsaNav,
  showTaxSavings,
  showResultPage,
  canChangePayPeriod,
  unlockedPath,
  isAlexIdEnabled,
  hasUnsavedProfileChanges,
  hideNavItems,
  getStore,
  handleUnlockPath,
  routerPush,
}: NavigationProps) => {
  const { device } = useViewport();
  const pages = determineRoutes(
    device !== 'desktop',
    isFullBenefitEnabled,
    showHsaNav,
    showTaxSavings,
    showResultPage,
    hasUnsavedProfileChanges,
  );
  const currentPage = pages.find((p) => p.paths.includes(path));
  const unlockedPage = pages.find((p) => p.paths.includes(unlockedPath));

  useEffect(() => {
    if (path !== '/' && currentPage) {
      if (unlockedPage && currentPage.idx > unlockedPage.idx) {
        handleUnlockPath(path);
      }
    }
  }, [path]);

  const loginWarning = isAlexIdEnabled &&
    getEnvironmentFromUrl() === DEPLOY_ENVIRONMENTS.UAT &&
    path !== WELCOME_PATH && (
      <div className="login-banner">
        <PageLayout bg="--primary-blue">
          <Text align="right">
            <Warning size={16} weight="fill" /> For the best review experience, do not sign in.{' '}
            <Tooltip
              label="ALEX will save your answers across sessions. Testing multiple
              scenarios while signed in may result in conflicting information.
              If you log in and something looks amiss, log out and try again."
              desktopOverrides={{
                bg: COLORS.blue[500],
                color: '#000',
                placement: 'bottom-end',
                size: 'large',
              }}
            >
              <strong>Why?</strong>
            </Tooltip>
          </Text>
        </PageLayout>
      </div>
    );

  return device === 'desktop' || path === WELCOME_PATH ? (
    <>
      <Desktop
        pages={pages}
        getStore={getStore}
        unlockedPage={unlockedPage}
        path={path}
        showLanguageToggle={showLanguageToggle}
        canChangePayPeriod={canChangePayPeriod}
        routerPush={routerPush}
        isAlexIdEnabled={isAlexIdEnabled}
        hideNavItems={hideNavItems}
      />
      {loginWarning}
    </>
  ) : (
    <>
      <Mobile
        getStore={getStore}
        pages={pages}
        unlockedPage={unlockedPage}
        path={path}
        canChangePayPeriod={canChangePayPeriod}
        currentPage={currentPage}
        routerPush={routerPush}
        isAlexIdEnabled={isAlexIdEnabled}
        hideNavItems={hideNavItems}
      />
      {loginWarning}
    </>
  );
};
