import { Box, Flex } from '@chakra-ui/react';
import React, { FC } from 'react';

import { TextField } from 'Containers/App/types';
import { AudioConfigTooltipContentfulPaths } from 'Containers/ProfilePage/ProfileAudio';
import { Member, MemberSectionConfigFields, UpdateMemberFields } from 'Containers/ProfilePage/types';
import { ContentfulTooltip, MemberCard, ProfileAudio } from 'ContentfulDefaults/types/profile';
import { useFeatureFlagContext } from 'Contexts/featureFlagContext';
import { useTextContext } from 'Contexts/textContext';
import { Paragraph } from 'DesignLibrary/atoms';
import { AudioTooltip } from 'DesignLibrary/atoms/AudioTooltip';
import Counter from 'DesignLibrary/atoms/inputs/Counter';
import { ToggleListOption } from 'DesignLibrary/atoms/ToggleList';
import { TooltipCaps } from 'DesignLibrary/molecules';
import Text from 'Shared/Text';

import { InpatientDayValues, PcpVisitValues, RangeToggle, SpecVisitValues } from './RangeToggle';
import { InputSection, UtilContainer } from './styled';

export interface UtilizationSectionProps {
  member: Member;
  memberConfig: MemberSectionConfigFields;
  isProfileAudioEnabled: boolean;
  isPlannedProceduresEnabled: boolean;
  handleEdit: (fields: UpdateMemberFields, memberConfig: MemberSectionConfigFields) => void;
}

interface UtilInputSectionProps {
  label: string;
  visitTypeTooltip: ContentfulTooltip;
  keyHint: string;
  handleChange: (value: number) => void;
  // Below added as a prop due to scoping issues
  renderUtilSectionInfo: (
    label: string,
    visitTypeTooltip: ContentfulTooltip,
    keyHint: string,
    audioConfig?: ProfileAudio,
  ) => JSX.Element;
  startValue: number;
  counterTestId: string;
  rangeOptions: ToggleListOption[];
  useRanges: boolean;
  audioConfig?: ProfileAudio;
  exclusionsLabelId?: string;
  exclusionsTextFieldKey?: TextField;
}

/**
 * Utilization Input Section
 * pulled out for easy conditionalization
 * @param UtilInputSectionProps
 * @returns
 */
const UtilInputSection = ({
  audioConfig,
  label,
  visitTypeTooltip,
  keyHint,
  handleChange,
  renderUtilSectionInfo,
  startValue,
  counterTestId,
  rangeOptions,
  useRanges,
  exclusionsLabelId,
  exclusionsTextFieldKey,
}: UtilInputSectionProps) => {
  if (useRanges && rangeOptions.length > 0) {
    return (
      <Flex
        flexDir={['column', null, 'row']}
        justify="space-between"
        align={['flex-start', null, 'center']}
        mt={6}
        gap={[2, 4]}
      >
        <div className="util-question">
          {renderUtilSectionInfo(label, visitTypeTooltip, keyHint, audioConfig)}
          {exclusionsTextFieldKey && (
            <Paragraph id={exclusionsLabelId} size="mini">
              <Text field={exclusionsTextFieldKey} />
            </Paragraph>
          )}
        </div>
        {/* Do we want something diff for groupName? */}
        <RangeToggle
          label={label}
          startValue={startValue > 0 ? startValue.toString() : ''}
          groupName={counterTestId}
          rangeOptions={rangeOptions}
          handleChange={handleChange}
        />
      </Flex>
    );
  }
  return (
    <InputSection>
      <div className="util-question">
        {renderUtilSectionInfo(label, visitTypeTooltip, keyHint, audioConfig)}
        {exclusionsTextFieldKey && (
          <Paragraph id={exclusionsLabelId} size="mini">
            <Text field={exclusionsTextFieldKey} />
          </Paragraph>
        )}
      </div>
      <div data-testid={counterTestId}>
        <Counter
          id={counterTestId}
          label={label}
          describedBy={exclusionsLabelId}
          startValue={startValue || 0}
          handleChange={handleChange}
          minValue={0}
          maxValue={99}
        />
      </div>
    </InputSection>
  );
};

const UtilizationForm: FC<UtilizationSectionProps> = ({
  member,
  memberConfig,
  isProfileAudioEnabled,
  isPlannedProceduresEnabled,
  handleEdit,
}) => {
  const { retrieveContentfulData } = useTextContext();

  const { is_improved_recommendation_explanation_enabled } = useFeatureFlagContext();

  const handleUtilizationChange = (value, name) => {
    handleEdit(
      {
        utilization: {
          ...member.utilization,
          [name]: value !== '' ? parseInt(value, 10) : '',
        },
      },
      memberConfig,
    );
  };

  const { member_type } = member;

  const pcpVisits = member.utilization.pcp_visits;
  const specialistVisits = member.utilization.specialist_visits;
  const mentalHealthVisits = member.utilization.mental_health_visits;
  const inpatientDays = member.utilization.inpatient_days;

  const profileCard = retrieveContentfulData<MemberCard>('profile_section.member');

  const pcpExclusionsLabel = `${member.external_id}-pcp-exclusions`;
  const hospitalExclusionsLabel = `${member.external_id}-hospital-exclusions`;

  const youOrTheyReplacement = member_type === 'policyholder' ? 'you' : 'they';

  const yourOrTheirReplacement = member_type === 'policyholder' ? 'your' : 'their';

  const {
    tooltips: { pcp_visits, mental_health_visits, specialist_visits, hospital_visits },
  } = profileCard;

  const getTooltipText = (visitTypeTooltip) => {
    if (visitTypeTooltip === pcp_visits) {
      return visitTypeTooltip.text
        .replace('{x}', youOrTheyReplacement)
        .replace(/{y}/g, yourOrTheirReplacement);
    }
    return visitTypeTooltip.text.replace('{x}', youOrTheyReplacement);
  };

  const getProfileAudioConfig = (path: AudioConfigTooltipContentfulPaths): ProfileAudio =>
    retrieveContentfulData<ProfileAudio>(path);

  const pcpAudioConfig: ProfileAudio = getProfileAudioConfig(
    AudioConfigTooltipContentfulPaths.PcpAudioTooltip,
  );
  const specialistAudioConfig: ProfileAudio = getProfileAudioConfig(
    AudioConfigTooltipContentfulPaths.SpecialistAudioTooltip,
  );
  const hospitalAudioConfig: ProfileAudio = getProfileAudioConfig(
    AudioConfigTooltipContentfulPaths.HospitalStaysAudioTooltip,
  );

  const pcpLabel = profileCard.tooltips.pcp_visits.title;
  const specialistLabel = profileCard.tooltips.specialist_visits.title;
  const hospitalLabel = profileCard.tooltips.hospital_visits.title;
  const mentalHealthLabel = profileCard.tooltips.mental_health_visits.title;

  const renderUtilSectionInfo = (
    label: string,
    visitTypeTooltip: ContentfulTooltip,
    keyHint: string,
    audioConfig?: ProfileAudio,
  ) => {
    if (isProfileAudioEnabled && audioConfig) {
      return (
        <Box pos="relative">
          <Box pos="absolute" top={-2} left={-12}>
            <AudioTooltip
              data-testid={`audio-tooltip-${keyHint}`}
              urlToAudio={audioConfig.audio_file_url}
              urlToClosedCaptionVtt={audioConfig.vtt_transcript_file_url}
            />
          </Box>
          <Paragraph size="small">{label}</Paragraph>
        </Box>
      );
    }
    return (
      <TooltipCaps color="--text-black" tooltip={getTooltipText(visitTypeTooltip)} keyHint={keyHint}>
        {label}
      </TooltipCaps>
    );
  };

  return (
    <UtilContainer>
      <Paragraph size="normal">{profileCard.utilization_instructions}</Paragraph>
      <UtilInputSection
        audioConfig={pcpAudioConfig}
        label={pcpLabel}
        visitTypeTooltip={pcp_visits}
        keyHint={`member-${member.external_id}-pcp-visits`}
        handleChange={(value) => handleUtilizationChange(value, 'pcp_visits')}
        startValue={pcpVisits || 0}
        rangeOptions={PcpVisitValues}
        useRanges={is_improved_recommendation_explanation_enabled}
        counterTestId="pcp-counter"
        exclusionsLabelId={pcpExclusionsLabel}
        exclusionsTextFieldKey="profile_section.member.pcp_exclusions"
        renderUtilSectionInfo={renderUtilSectionInfo}
      />

      <UtilInputSection
        audioConfig={specialistAudioConfig}
        label={specialistLabel}
        visitTypeTooltip={specialist_visits}
        keyHint={`member-${member.external_id}-specialist-visits`}
        handleChange={(value) => handleUtilizationChange(value, 'specialist_visits')}
        startValue={specialistVisits || 0}
        rangeOptions={SpecVisitValues}
        useRanges={is_improved_recommendation_explanation_enabled}
        counterTestId="specialist-counter"
        renderUtilSectionInfo={renderUtilSectionInfo}
      />

      <UtilInputSection
        audioConfig={hospitalAudioConfig}
        label={hospitalLabel}
        visitTypeTooltip={hospital_visits}
        keyHint={`member-${member.external_id}-hospital-visits`}
        handleChange={(value) => handleUtilizationChange(value, 'inpatient_days')}
        startValue={inpatientDays || 0}
        counterTestId="inpatient-counter"
        rangeOptions={InpatientDayValues}
        useRanges={is_improved_recommendation_explanation_enabled}
        exclusionsLabelId={hospitalExclusionsLabel}
        exclusionsTextFieldKey="profile_section.member.hospital_exclusions"
        renderUtilSectionInfo={renderUtilSectionInfo}
      />

      {!isPlannedProceduresEnabled && (
        // Part of the rollout strategy for Planned Procedures is to hide the mental health visits counter for the Utilization section so that we only ask once.
        <UtilInputSection
          label={mentalHealthLabel}
          visitTypeTooltip={mental_health_visits}
          keyHint={`member-${member.external_id}-mental-health-visits`}
          handleChange={(value) => handleUtilizationChange(value, 'mental_health_visits')}
          startValue={mentalHealthVisits || 0}
          counterTestId="mental-health-counter"
          rangeOptions={[]}
          useRanges={false}
          renderUtilSectionInfo={renderUtilSectionInfo}
        />
      )}
    </UtilContainer>
  );
};

export default UtilizationForm;
