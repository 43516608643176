import styled, { css } from 'styled-components';

import { ButtonStyle, ButtonStyleProps } from 'DesignLibrary/atoms/Button/styled';

interface AudioButtonProps extends ButtonStyleProps {
  isPlaying: boolean;
}

export const AudioButton = styled(ButtonStyle)<AudioButtonProps>`
  border-radius: 2rem;
  background: var(--primary-white);
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition:
    0.2s box-shadow,
    0.2s transform;

  &:hover {
    background: var(--primary-white);
    box-shadow: var(--shadow-large);
    transform: rotate(-10deg) scale(1.1);
  }

  &:active {
    transform: rotate(0deg) scale(1);
  }

  ${({ isPlaying }) =>
    isPlaying &&
    css`
      background: var(--primary-blue);
      border-color: var(--primary-blue);
      box-shadow: var(--shadow-selected-blue);
      transform: rotate(-10deg) scale(1.2);

      &:hover {
        background: var(--primary-blue);
      }
    `}
`;
