import React, { FC } from 'react';

import { InsuranceType } from 'Containers/ViewBenefitPage/types';
import { useFeatureFlagContext } from 'Contexts/featureFlagContext';
import { Paragraph } from 'DesignLibrary/atoms';
import { PicwellScore, PicwellScoreProps } from 'DesignLibrary/atoms/PicwellScore';
import { Tooltip, TooltipProps } from 'DesignLibrary/atoms/Tooltip';
import { PlanFit } from 'Shared/PlanFit';
import { SupplementalIcon } from 'Shared/SupplementalIcon';

import { CtaItemStyle } from './styled';

export interface ItemProps {
  title: string;
  tooltip?: TooltipProps;
  supplementalPlanType?: InsuranceType;
  planScoreProps?: PicwellScoreProps;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  isDisabled?: boolean;
}

export const CtaItem: FC<ItemProps> = ({
  title,
  tooltip,
  supplementalPlanType,
  planScoreProps,
  onClick,
  isDisabled,
}) => {
  const { is_improved_recommendation_explanation_enabled } = useFeatureFlagContext();
  const nonInteractiveTitles = ['HRA', 'FSA', 'SIHRA'];

  const Item = (
    <CtaItemStyle onClick={onClick} isDisabled={isDisabled}>
      {planScoreProps &&
        (is_improved_recommendation_explanation_enabled && !planScoreProps.waived ? (
          <PlanFit variant="icon" score={planScoreProps.score as number} />
        ) : (
          <PicwellScore {...planScoreProps} animate={false} size="small" />
        ))}
      {supplementalPlanType && (
        <div className="supplemental-icon">
          <SupplementalIcon type={supplementalPlanType} size="mini" />
        </div>
      )}
      <Paragraph size="small" weight="bold" color="--text-black">
        {title}
      </Paragraph>
    </CtaItemStyle>
  );

  if (tooltip) {
    return (
      <Tooltip {...tooltip} isInteractive={!nonInteractiveTitles.includes(title)}>
        {Item}
      </Tooltip>
    );
  }

  return Item;
};
