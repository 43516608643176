import { Slider, SliderTrack, SliderThumb, SliderFilledTrack, SliderMark } from '@chakra-ui/react';
import { debounce } from 'lodash';
import React, { useCallback, useEffect } from 'react';

import { Paragraph } from 'DesignLibrary/atoms';
import TextInput from 'DesignLibrary/atoms/inputs/TextInput';
import { toDollars } from 'Utils/helpers';
import { sendInsightsEvent } from 'Utils/insights';

import { Container, SliderContainer } from './styled';

export interface FsaSliderProps {
  contribution: number;
  userContributionLimit: number;
  lockedFsaContribution: number | null;
  setContribution: (contribution: number) => void;
  setLockedContribution: (contribution: number) => void;
}

export const FsaSlider = ({
  contribution,
  userContributionLimit,
  lockedFsaContribution,
  setContribution,
  setLockedContribution,
}: FsaSliderProps) => {
  function trackSlider(cont: number) {
    sendInsightsEvent(null, 'slide_fsa_contribution', {
      cont,
    });
  }

  function trackInput(cont: number) {
    sendInsightsEvent(null, 'input_fsa_contribution', {
      cont,
    });
  }

  useEffect(() => {
    if (lockedFsaContribution === null) {
      setLockedContribution(contribution);
    }
  }, []);

  const handleLockedContributionChange = useCallback(
    debounce((cont: number, type: 'slider' | 'input') => {
      // setLockedContribution updates redux which triggers the saga that makes the forcasting calls
      setLockedContribution(cont);

      if (type === 'slider') {
        trackSlider(cont);
      } else if (type === 'input') {
        trackInput(cont);
      }
    }, 1000),
    [],
  );

  const handleSliderChange = (cont: number) => {
    handleLockedContributionChange(cont, 'slider');
    setContribution(cont);
  };

  const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    const cont = parseInt(e.currentTarget.value.replace(',', ''), 10) || 0;

    if (cont <= userContributionLimit) {
      handleLockedContributionChange(cont, 'input');
      setContribution(cont);
    }
  };

  const getContributionSliderMarkText = () => {
    if (contribution === 0) {
      return `Min: $0`;
    }
    if (contribution === userContributionLimit) {
      return `Max: ${toDollars(userContributionLimit)}`;
    }
    return toDollars(contribution);
  };

  return (
    <Container>
      <div className="input-container">
        <TextInput
          handleChange={() => null}
          inputType="currency"
          inputMode="numeric"
          value={contribution.toString()}
          onChange={(e) => handleInputChange(e)}
          max={userContributionLimit}
          stretch
        />
      </div>
      <SliderContainer>
        <Slider
          aria-label="hsa yearly contribution"
          className="slider"
          value={contribution}
          onChange={(v) => handleSliderChange(v)}
          min={0}
          max={userContributionLimit}
          step={5}
          focusThumbOnChange={false}
        >
          <SliderTrack bg="var(--colors-gray-200)">
            <SliderFilledTrack bg="var(--primary-blue)" />
          </SliderTrack>
          <SliderMark className="slider-mark" value={contribution}>
            <Paragraph size="mini" color="--primary-white" weight="bold">
              {getContributionSliderMarkText()}
            </Paragraph>
          </SliderMark>
          <SliderThumb className="slider-thumb" boxSize="32px" sx={{ boxShadow: 'var(--shadow-large)' }} />
        </Slider>
        <div className="numbers">
          <Paragraph size="mini" weight="bold" color="--text-gray" className="start-number">
            $0
          </Paragraph>
          <Paragraph size="mini" weight="bold" color="--text-gray" className="end-number">
            {toDollars(userContributionLimit)}
          </Paragraph>
        </div>
      </SliderContainer>
    </Container>
  );
};
