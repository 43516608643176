import React, { FC } from 'react';

import { PageLayoutContainer } from './styled';

export interface PageLayoutProps {
  size?: 'normal' | 'small';
  bg?: string;
  page?: string;
  id?: string;
}

export const PageLayout: FC<PageLayoutProps> = ({ size = 'normal', bg = '', page, id, children }) => (
  <PageLayoutContainer id={id} size={size} bg={bg} page={page}>
    <section className="inner-wrapper">{children}</section>
  </PageLayoutContainer>
);
