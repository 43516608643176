import { debounce } from 'lodash';
import React, { useCallback, useState } from 'react';

import { TextField } from 'Containers/App/types';
import { ClientSurveyQuestion } from 'Containers/ProfilePage/types';
import TextInput from 'DesignLibrary/atoms/inputs/TextInput';
import Text from 'Shared/Text';

export interface ValidatedTextInputProps {
  questionId: string;
  question: ClientSurveyQuestion;
  response: string;
  labelId: string;
  errorMessageField: TextField;
  handleAnswerChange: (value: string, property: string, isValid: boolean) => void;
}

export const RegexOrHideChoicesValidatedTextInput = ({
  questionId,
  question,
  response,
  errorMessageField,
  labelId,
  handleAnswerChange,
}: ValidatedTextInputProps) => {
  const [value, setValue] = useState(response);
  const [validationHasOccurred, setValidationHasOccurred] = useState(false);

  const getInputIsValid = (val: string) => {
    if (question.hideChoices) {
      // case insensitive handling
      const validValues = question?.enum?.map((i) => i.toLowerCase());
      const toCheck = val.toLocaleLowerCase().trim();

      return validValues?.includes(toCheck) || false;
    }
    return new RegExp(question.pattern as string).test(val);
  };

  const validate = (val: string) => {
    const isValid = getInputIsValid(val);
    if (isValid && question.hideChoices) {
      // case insensitive handling
      const sanitized = question?.enum?.find(
        (i) => i.toLocaleLowerCase() === val.toLocaleLowerCase().trim(),
      ) as string;
      handleAnswerChange(sanitized, questionId, true);
    } else {
      handleAnswerChange(val, questionId, isValid);
    }
    setValidationHasOccurred(true);
  };

  const debouncedValidate = useCallback(
    debounce((val) => validate(val), 1000),
    [],
  );

  const handleChange = (val: string) => {
    setValue(val);
    if (validationHasOccurred) debouncedValidate(val);
  };

  const shouldShowErrorMessage = validationHasOccurred && !getInputIsValid(value);

  const errorMessage = shouldShowErrorMessage ? (
    <p
      className="error-text"
      data-testid={
        question.hideChoices ? 'hide-choices-validated-text-input-error' : 'regex-validated-text-input-error'
      }
    >
      <Text field={errorMessageField} vars={{ x: value }} />
    </p>
  ) : null;

  return (
    <>
      <TextInput
        labelId={labelId}
        className={`input-${questionId}`}
        data-testid={
          question.hideChoices ? 'hide-choices-validated-text-input' : 'regex-validated-text-input'
        }
        name={questionId}
        inputType="normal"
        inputMode="text"
        stretch={false}
        iconType="Search"
        value={value}
        handleChange={(e) => handleChange(e.target.value)}
        onBlur={(e) => validate(e.target.value)}
        error={shouldShowErrorMessage}
        pattern={question.pattern}
      />
      {errorMessage}
    </>
  );
};
