import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  BoxProps,
  Box,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';

import { useTextContext } from 'Contexts/textContext';
import { Button, Paragraph } from 'DesignLibrary/atoms';
import { TextNoteWrapper } from 'DesignLibrary/molecules/TextNote/styled';
import { PrimaryColors } from 'DesignLibrary/vars';

export interface TextNoteProps extends BoxProps {
  shortDisplayText: string | JSX.Element;
  hasExpandedText?: boolean;
  expandedText?: string | JSX.Element;
  buttonText?: string;
  color?: PrimaryColors;
  className?: string;
}

export const TextNote = ({
  shortDisplayText,
  hasExpandedText = false,
  expandedText,
  buttonText,
  color = 'yellow',
  className = '',
  ...props
}: TextNoteProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { retrieveContentfulData } = useTextContext();

  const closeButtonText = retrieveContentfulData<string>(`profile_section.button_text.close_button`);

  return (
    <Box
      p={4}
      border="2px solid"
      borderColor={`var(--primary-${color})`}
      bg={`var(--colors-${color}-50)`}
      borderRadius="6px"
      className={className}
      {...props}
    >
      <TextNoteWrapper>
        <Paragraph size="small">{shortDisplayText}</Paragraph>
      </TextNoteWrapper>
      {hasExpandedText ? (
        <Box
          mt={3}
          sx={{
            button: {
              bg: 'blackAlpha.100',
              _hover: {
                bg: 'blackAlpha.200',
              },
              _active: {
                bg: 'blackAlpha.100',
              },
            },
          }}
        >
          <Button buttonType="transparent" size="xsmall" onClick={onOpen}>
            {buttonText}
          </Button>
        </Box>
      ) : null}

      <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent maxH="480px">
          <ModalBody p={[4, 6]}>
            <Paragraph>{expandedText}</Paragraph>
          </ModalBody>
          <ModalFooter>
            <Box maxW={48} w="100%">
              <Button stretch onClick={onClose}>
                {closeButtonText}
              </Button>
            </Box>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};
