export const COLORS = {
  green: {
    50: 'var(--colors-alex-green10)',
    100: '#E2F2DF',
    200: 'var(--colors-alex-green50)',
    300: '#5FA081',
    400: 'var(--colors-alex-green)',
    500: '#16583C',
    600: '#0E3927',
    800: '#0A1F17',
  },
  purple: {
    50: '#F7F2FA',
    100: '#F0E5F6',
    200: '#C8ADD5',
    300: '#A076B6',
    400: '#794096',
    500: '#613378',
    600: '#482659',
    800: '#1C1221',
  },
  blue: {
    50: 'var(--colors-alex-blue10)',
    100: '#E0F1FB',
    200: '#A0C9E1',
    300: '#61A3C7',
    400: 'var(--colors-alex-blue)',
    500: '#1C658E',
    600: '#164F6F',
    800: '#012E40',
  },
  yellow: {
    50: '#FFFBEA',
    100: 'var(--colors-alex-gold80)',
    200: 'var(--colors-alex-gold90)',
    300: 'var(--colors-alex-gold)',
    400: 'var(--colors-alex-gold)',
    500: '#A9792E',
    600: '#6B3F13',
    800: '#211C12',
  },
  red: {
    50: 'var(--colors-alex-red10)',
    100: '#FCCECE',
    200: '#F39A9A',
    300: '#EB6868',
    400: 'var(--colors-alex-red)',
    500: '#B42424',
    600: '#861313',
    800: '#211212',
  },
  gray: {
    white: '#FFFFFF',
    0: '#FFFFFF',
    50: '#F9F9F9',
    100: 'var(--colors-alex-offwhite)',
    200: 'var(--colors-alex-gray)',
    300: 'var(--colors-alex-ink10)',
    400: '#AAAAAA',
    500: 'var(--colors-alex-charcoal)',
    600: 'var(--colors-alex-ink80)',
    700: '#161616',
    black: '#2f2f52',
  },
  opaque: {
    0: 'rgba(0,0,0,0)',
    5: 'rgba(0,0,0,0.05)',
    10: 'rgba(0,0,0,0.1)',
    20: 'rgba(0,0,0,0.2)',
    30: 'rgba(0,0,0,0.3)',
    40: 'rgba(0,0,0,0.4)',
    50: 'rgba(0,0,0,0.5)',
    60: 'rgba(0,0,0,0.6)',
    70: 'rgba(0,0,0,0.7)',
    80: 'rgba(0,0,0,0.8)',
    90: 'rgba(0,0,0,0.9)',
    100: 'rgba(0,0,0,1)',
  },
  // reskin palette
  alex: {
    ink10: '#D5D5DC',
    ink80: '#6F6F85',
    ink: '#2F2F52',
    gold50: '#FCF3B0',
    gold80: '#FFE767',
    gold90: '#FFD831',
    gold: '#E0B90E',
    blue10: '#E4F6FC',
    blue: '#036991',
    green10: '#C7F3E6',
    green50: '#72E0C0',
    green: '#048468',
    red10: '#FFEEF4',
    red: '#DB064F',
    charcoal: '#707070',
    gray: '#EBEBEB',
    offwhite: '#F7F7F7',
    orange: '#FAE9D6',
  },
};

// This color array is used to generate a type union and to populate the color dropdown in InfoBar.stories.tsx
export const primaryColorsArray = ['green', 'yellow', 'red', 'purple', 'blue', 'black'] as const;

export const primaryColorsVars = primaryColorsArray.map((color) => `--primary-${color}`);

const colorVars = Object.entries(COLORS)
  .map(([color, nested]) => Object.entries(nested).map(([number]) => `--colors-${color}-${number}`))
  .flat();

// Array to populate color select dropdown in stories files
export const colorPaletteArray = [...primaryColorsVars, '-----------------------', ...colorVars];

export type PrimaryColors = (typeof primaryColorsArray)[number];

export const SPACING = {
  '2xs': '4px',
  xs: '8px',
  sm: '16px',
  md: '24px',
  lg: '32px',
  xl: '48px',
  xxl: '64px',
  xxxl: '128px',
  xxxxl: '256px',
  '2xl': '64px',
  '3xl': '128px',
  '4xl': '256px',
};

export const BORDER_RADIUS = {
  xs: '6px',
  sm: '16px',
  lg: '24px',
};

export const BREAKPOINTS = {
  WIDEDESKTOP: 1300,
  DESKTOP: 1024,
  TABLET: 768,
  MOBILE: 0,
};

export const FONT = {
  header: '"Museo Sans", -apple-system, Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif',
  primary:
    '"Museo Sans", -apple-system, Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif',
};

export const SHADOW = {
  card: '0px 4px 8px 0px rgba(0, 0, 0, 0.05), 0px 1px 2px 0px rgba(0, 0, 0, 0.05)',
  badge:
    '0px 4px 8px 0px rgba(0, 0, 0, 0.05), 0px 1px 2px 0px rgba(0, 0, 0, 0.05), 0px 8px 20px 0px rgba(36, 36, 36, 0.16)',
  large: '0px 6px 24px 0px rgba(0, 0, 0, 0.16), 0px 2px 4px 0px rgba(0, 0, 0, 0.05)',
  selectedGreen: '0px 4px 12px rgba(29, 115, 79, 0.24), 0px 2px 8px rgba(29, 115, 79, 0.2)',
  selectedBlue: '0px 4px 12px rgba(35, 124, 174, 0.24), 0px 2px 8px rgba(35, 124, 174, 0.2)',
  selectedPurple: '0px 4px 12px rgba(121, 64, 150, 0.24), 0px 2px 8px rgba(121, 64, 150, 0.2)',
  selectedRed: '0px 4px 12px rgba(220, 52, 52, 0.24), 0px 2px 8px rgba(220, 52, 52, 0.2)',
};

export const rgba = (hexColor: string, opacity = 1) => {
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hexColor)) {
    c = hexColor.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = `0x${c.join('')}`;
    // eslint-disable-next-line no-bitwise
    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')},${opacity})`;
  }
  throw new Error('Bad Hex');
};

export function Gradient(colors: string[], dir: string) {
  const gradient = colors.join();
  const direction = dir || 'to right';
  return `linear-gradient(${direction}, ${gradient});`;
}
