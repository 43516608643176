import { ContentfulEligibilitySection } from 'ContentfulDefaults/types/eligibility';

export const eligibility: {
  eligibility_section: ContentfulEligibilitySection;
} = {
  eligibility_section: {
    contentful_title: 'Eligibility Section (DEFAULT DO NOT CHANGE)',
    name: 'Elegibilidad',
    button_text: {
      employment: 'Selecciona una respuesta',
      survey_page: 'Seguir',
      landing_page: 'Empecemos',
    },
    mobile_nav_instruction:
      'Navegue hacia adelante y hacia atrás haciendo clic en cualquiera de estos elementos que están desbloqueados. Si lo desea, puede cambiar sus respuestas en cualquier momento.',
    service_options: {
      benefits: 'beneficios',
      insurance: 'seguro de salud',
    },
    landing_headings: {
      mobile: '¡Elija su {x}, finalmente sin esfuerzo!',
      desktop: '¿Quién sabía que elegir tu {x} podría ser así de simple?',
    },
    landing_description:
      '¡Picwell está aquí para ayudarlo a determinar qué plan es el más adecuado para usted y su familia! Esto, sin embargo, no es una plataforma de inscripción; lo guiaremos a su sitio de inscripción al final de nuestra experiencia. Debe revisar completamente todos sus documentos de beneficios antes de inscribirse. Sus respuestas a estas preguntas son anónimas.',
    landing_arrow: 'https://picwellapppublicdata.s3.amazonaws.com/contentful/profileimages/cartoon-arrow.svg',
    terms_and_policy_disclaimer: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Al hacer clic en "Comencemos", usted reconoce que comprende esto y acepta los ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'bold',
                },
              ],
              value: 'Términos de servicio',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value: ' y la ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'bold',
                },
              ],
              value: 'Política de privacidad',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value: '.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    terms_title: 'Términos y Condiciones',
    terms_and_conditions: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Estos términos de uso (los "Términos de uso") son un acuerdo vinculante entre usted y Picwell, Inc., una corporación de Delaware ("Picwell", "nosotros", "nuestro" o "nosotros"), que rige su acceso a y el uso del servicio de selección de planes y soporte de decisiones de Picwell (los "Servicios"). Reconocemos y usted reconoce que estos Términos de uso son entre Picwell y usted, y no con ningún otro tercero, incluido cualquier proveedor de plataforma móvil.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'El servicio de selección de decisiones y soporte de decisiones de Picwell utiliza algoritmos diseñados para crear suposiciones financieras y de salud para futuras necesidades de salud y atención médica. Los resultados de los Servicios se basan en estimaciones, supuestos y proyecciones, que incluyen, entre otros, los costos futuros anuales estimados, las necesidades de atención médica y los supuestos de cobertura. Debido a las incertidumbres necesariamente inherentes a depender de tales estimaciones, suposiciones y proyecciones, sus costos médicos y cobertura reales pueden variar, quizás materialmente, de las estimaciones proporcionadas por los Servicios. Los resultados generados por los Servicios son predicciones solo con fines informativos, no están garantizados y no se debe confiar en ellos para tomar decisiones actuales o futuras.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'LEA DETENIDAMENTE LOS TÉRMINOS DE USO ANTES DE ACCEDER O INICIAR EL USO DE LOS SERVICIOS. AL ACEPTAR ESTOS TÉRMINOS DE USO, AL HACER CLIC PARA ACEPTAR O ACEPTAR O AL DESCARGAR, INSTALAR O USAR Y / O CONTINUAR PARA USAR LOS SERVICIOS, RECONOCE Y REPRESENTA QUE (A) HA LEÍDO Y ENTENDIDO ESTOS TÉRMINOS DE USO, ( B) TIENE 18 AÑOS DE EDAD O MÁS, Y (C) ACEPTA Y ACEPTA LOS TÉRMINOS DE ESTOS TÉRMINOS DE USO SIN LIMITACIÓN O CALIFICACIÓN.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '1. Acceso y licencia',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-3',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                '1.1 Picwell le otorga un derecho mundial no exclusivo, intransferible y revocable para usar los Servicios, sujeto a estos Términos de uso (la "Licencia"). Usted le otorga a Picwell el derecho no exclusivo y mundial de usar, copiar, almacenar, transmitir y mostrar sus Datos únicamente en la medida necesaria para proporcionar los Servicios. Picwell y sus licenciantes se reservan todos los derechos que no se le otorgan expresamente. A los fines del presente documento, "Sus datos" se refiere a cualquier dato o información de cualquier tipo proporcionada o presentada por usted a Picwell o ingresada electrónicamente durante el uso de los Servicios.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                '1.2 Usted acepta que no intentará o intentará: (i) duplicar, copiar, vender, revender, explotar comercialmente para cualquier propósito, interrumpir o interferir con cualquier parte, uso o acceso a los Servicios; (ii) usar los Servicios para cualquier propósito que no sea su educación personal; (iii) proporcionar acceso directo o indirecto al Servicio a cualquier otra persona; o (iv) violar cualquier ley o regulación aplicable.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                '1.3 Usted es responsable de su acceso y uso de los Servicios. Asegurará que su acceso y uso de los Servicios y Sus Datos cumplirán en todo momento con todas las leyes aplicables.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                '2. Derechos de propiedad intelectual; Tu información; Proceso de notificación de infracción de derechos de autor',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-3',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                '2.1 Los Servicios están protegidos por derechos de propiedad intelectual (los "Derechos de propiedad intelectual"), y cualquier uso no autorizado de los Servicios puede violar dichos Derechos de propiedad intelectual, las leyes aplicables y estos Términos de uso. Picwell y sus otorgantes de licencias poseen todos los derechos, títulos e intereses sobre los Derechos de Propiedad Intelectual. Estos Términos de uso no le transmiten ni le transfieren ningún derecho de propiedad. Si nos hace una sugerencia para cualquier tipo de adición u otro cambio a los Servicios (los "Comentarios"), acepta que Picwell será el propietario de los Comentarios y que no tiene derecho a ningún dinero, crédito u otra compensación. Las marcas comerciales, logotipos y marcas de servicio que se muestran en los Servicios, incluidos, entre otros, "Picwell", son propiedad de Picwell u otros terceros. No está permitido usar estas marcas sin el consentimiento previo por escrito de Picwell o de un tercero que pueda ser el propietario de las marcas. No puede eliminar ni ocultar ningún aviso de copyright u otros avisos de propiedad contenidos en los Servicios.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                '2.2 Usted, no Picwell, será el único responsable de todos sus datos, y Picwell no será responsable de la eliminación, corrección, destrucción, daño, pérdida o falta de almacenamiento de ninguno de sus datos. Al utilizar los Servicios, usted acepta que no cargará, publicará, enviará por correo electrónico, transmitirá ni pondrá a disposición ninguno de Sus Datos que: (i) sea ilegal u objetable; (ii) crea un riesgo de daño, pérdida o daño a cualquier persona o propiedad; (iii) busca dañar o explotar a menores de cualquier manera; (iv) viole o aliente cualquier conducta que viole la ley aplicable; o (v) infringe los derechos de terceros. Tras la rescisión por incumplimiento, su derecho de acceso o uso de sus datos cesa inmediatamente, y Picwell no tendrá la obligación de mantener o reenviar ninguno de sus datos.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                '2.3 Usted comprende que el procesamiento técnico y la transmisión de Sus Datos (incluida la posible transmisión de Sus Datos fuera de su país de origen) puede ser necesario para su uso de los Servicios y el contenido ofrecido en oa través de los Servicios, y acepta nuestra intercepción y almacenamiento de sus datos. Usted comprende que usted o Picwell pueden estar transmitiendo sus datos a través de Internet y a través de varias redes, de las cuales Picwell solo puede ser propietario y operar parte de ellas. Usted acepta que Picwell no es responsable de ninguna porción de sus datos que se pierda, altere, intercepte o almacene sin autorización durante la transmisión de sus datos a través de redes que no son propiedad ni están operadas por Picwell.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                '2.4 Picwell cumple con la Ley Federal de Derechos de Autor del Milenio Digital (la "DMCA"). Si cree que cualquier contenido incluido en los Servicios es su trabajo de propiedad exclusiva y se ha copiado de una manera que constituye una infracción de sus derechos de autor en ese trabajo, notifique inmediatamente a Picwell de dicha infracción. Tenga en cuenta también que, según la DMCA, cualquier persona que, a sabiendas, tergiversa materialmente que el material o la actividad está infringiendo o fue eliminada o deshabilitada por error o identificación errónea puede estar sujeta a responsabilidad. Su aviso por escrito debe enviarse a nuestro agente designado de la siguiente manera:',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Quejas de DMCA:',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Picwell, Inc.\nA la atención de: Departamento Jurídico\n1900 Market Street, Suite 120, Filadelfia, PA 19103\nCorreo electrónico: legal@picwell.com',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Todos los informes y consultas se mantendrán confidenciales, excepto en la medida necesaria para investigar cualquier presunta violación y hacer cumplir los términos y condiciones de estos Términos de uso. Antes de enviar una notificación de infracción de derechos de autor o una contranotificación, puede comunicarse con un abogado para comprender mejor sus derechos y obligaciones bajo la DMCA y otras leyes aplicables. Los siguientes requisitos de notificación están destinados a cumplir con nuestros derechos y obligaciones bajo la DMCA y no constituyen asesoramiento legal. Picwell eliminará el contenido infractor, sujeto a los procedimientos descritos en la DMCA.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Para presentar una notificación de infracción de derechos de autor con nosotros, deberá enviar una comunicación por escrito que incluya sustancialmente lo siguiente (consulte a su asesor legal o consulte 17 USC Sección 512 (c) (3) para confirmar estos requisitos): (1) a firma física o electrónica de una persona autorizada para actuar en nombre del propietario de un derecho exclusivo presuntamente infringido; (2) identificación del trabajo protegido por derechos de autor que supuestamente ha sido infringido, o, si múltiples trabajos protegidos por derechos de autor en un solo sitio en línea están cubiertos por una sola notificación, una lista representativa de dichos trabajos en ese sitio; (3) identificación del material que se alega que está infringiendo o que es objeto de actividad infractora e información razonablemente suficiente para permitir que el proveedor del servicio localice el material; (4) información razonablemente suficiente para permitir que el proveedor de servicios se comunique con la parte reclamante, como una dirección, número de teléfono y, si está disponible, una dirección de correo electrónico; (5) una declaración de que la parte reclamante cree de buena fe que el uso del material en la forma reclamada no está autorizado por el propietario de los derechos de autor, su agente o la ley; y (6) una declaración de que la información en la notificación es precisa y, bajo pena de perjurio, que la parte reclamante está autorizada a actuar en nombre del propietario de un derecho exclusivo que supuestamente se ha infringido.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Si sus datos han sido eliminados, puede optar por enviarnos una contranotificación. Para que sea efectiva, su contranotificación debe ser una comunicación escrita proporcionada a nuestro agente designado que incluya sustancialmente lo siguiente (consulte a su asesor legal o consulte 17 USC Sección 512 (g) (3) para confirmar estos requisitos): (1) a firma física o electrónica del suscriptor; (2) identificación del material que se ha eliminado o cuyo acceso se ha desactivado, y la ubicación en la que apareció el material antes de que se eliminara o se desactivara el acceso al mismo; (3) una declaración bajo pena de perjurio de que el suscriptor cree de buena fe que el material se eliminó o deshabilitó como resultado de un error o una identificación errónea del material que se eliminará o deshabilitará; y (4) el nombre, la dirección y el número de teléfono del suscriptor, y una declaración de que el suscriptor acepta la jurisdicción del Tribunal de Distrito Federal para el distrito judicial en el que se encuentra la dirección, o si la dirección del suscriptor está fuera de los Estados Unidos , para cualquier distrito judicial en el que se pueda encontrar al proveedor de servicios, y que el suscriptor acepte el servicio del proceso de la persona que proporcionó la notificación según el inciso (c) (1) (C) o un agente de dicha persona.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '3. Términos y terminación',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-3',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'The term for your access to and use of the Services shall commence upon the date you accept these Terms of Use and shall continue for the time period as set forth by Picwell (in connection with the applicable underlying subscription of your benefits plan administrator, employer, etc.) (the "Term"). Once terminated, you shall cease any use of the Services. You acknowledge and agree that Picwell will not be liable to you or any third party in connection with any termination of your access to or use of the Services pursuant to the terms hereof. Sections 5 and 6 of these Terms of Use, and the obligations and restrictions placed on you hereunder, shall survive termination.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '4. Renuncia de garantías / Limitación de responsabilidad',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-3',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                '4.1 LOS SERVICIOS PUEDEN ESTAR SUJETOS A LIMITACIONES, RETRASOS Y OTROS PROBLEMAS INHERENTES EN EL USO DE INTERNET Y COMUNICACIONES ELECTRÓNICAS. TAL COMO, LOS SERVICIOS SE PROPORCIONAN "TAL CUAL" Y "SEGÚN DISPONIBILIDAD". EN LA MEDIDA PERMISIBLE POR LA LEY APLICABLE, PICWELL Y SUS SUBSIDIARIAS, SOCIOS, AFILIADOS, OFICIALES, DIRECTORES, ACCIONISTAS, ADMINISTRADORES, MIEMBROS, EMPLEADOS, AGENTES Y LICENCIADORES (COLECTIVAMENTE, LA "PICWELL IMPIEZAS DE IMPIEZA, TODO IMPREIBLE"). , INCLUIDAS, SIN LIMITACIÓN, LAS GARANTÍAS IMPLÍCITAS DE COMERCIABILIDAD, APTITUD PARA UN PROPÓSITO EN PARTICULAR Y NO INFRACCIÓN. LAS PARTES DE PICWELL NO GARANTIZAN QUE LOS SERVICIOS SERÁN ININTERRUMPIDOS O SIN ERRORES, QUE LOS DEFECTOS SERÁN CORREGIDOS, QUE LOS SERVICIOS O SERVIDORES QUE PONEN LOS SERVICIOS DISPONIBLES ESTARÁN LIBRES DE VIRUS U OTROS COMPONENTES DAÑINOS, O QUE LOS SERVICIOS CUMPLIRÁN CON SUS SERVICIOS REQUISITOS APLICABLES',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                '4.2 USTED ACEPTA EXPRESAMENTE QUE EL USO DE LOS SERVICIOS ES BAJO SU PROPIO RIESGO. SERÁ EL ÚNICO RESPONSABLE DE CUALQUIER DAÑO A SU SISTEMA INFORMÁTICO O DISPOSITIVO MÓVIL O PÉRDIDA DE DATOS RESULTANTES DE LA DESCARGA DE CUALQUIER CONTENIDO O DATOS A TRAVÉS DE LOS SERVICIOS. NOS RESERVAMOS EL DERECHO DE RESTRINGIR O TERMINAR SU ACCESO A LOS SERVICIOS O CUALQUIER FUNCIÓN O PARTE DE LOS MISMOS EN CUALQUIER MOMENTO. LAS PARTES DE PICWELL NO ASUMEN NINGUNA RESPONSABILIDAD POR LA ELIMINACIÓN O LA ENTREGA INCORRECTA O LA FALTA DE ALMACENAMIENTO DE NINGÚN CONTENIDO, DATOS O AJUSTES DE PERSONALIZACIÓN.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                '4.3 USTED ENTIENDE Y ACEPTA QUE LAS PARTES DE PICWELL NO SERÁN RESPONSABLES CON USTED POR NINGÚN DAÑO INDIRECTO, INCIDENTAL, ESPECIAL, CONSECUENTE O EJEMPLAR, INCLUYENDO, SIN LIMITACIÓN, DAÑOS POR PÉRDIDA DE BENEFICIOS, BUENA VOLUNTAD, USO, DATOS U OTRA PÉRDIDA INTANGIBLE (INCLUSO SI UNA FIESTA DE PICWELL HA SIDO NOTIFICADA DE LA POSIBILIDAD DE TALES DAÑOS), RESULTANDO DE (i) SU USO O ACCESO O INHABILIDAD PARA USAR O ACCEDER A LOS SERVICIOS; (ii) EL COSTO DE LA ADQUISICIÓN DE BIENES Y SERVICIOS SUSTITUTOS RESULTANTES DE CUALQUIER BIEN, DATOS, INFORMACIÓN O SERVICIOS COMPRADOS O OBTENIDOS O MENSAJES RECIBIDOS O TRANSACCIONES ENTRADAS A TRAVÉS DE LOS SERVICIOS; (iii) ACCESO NO AUTORIZADO O ALTERACIÓN DE SUS TRANSMISIONES O DATOS; O (iv) CUALQUIER OTRO ASUNTO RELACIONADO CON LOS SERVICIOS. EN NINGÚN CASO, LA RESPONSABILIDAD TOTAL TOTAL DE PICWELL EXCEDERÁ LA CANTIDAD PAGADA POR (Y NO REEMBOLSADO) A USTED PARA PICWELL POR EL USO DE LOS SERVICIOS DURANTE EL DOCE (12) PERÍODO MENSUAL QUE PRECEDE INMEDIATAMENTE EL EVENTO QUE OFRECE EL ALCANCE PARA EL RECLAMO.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '5. Indemnización y liberación',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-3',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                '5.1 Usted acepta indemnizar, defender y eximir de responsabilidad a las Partes de Picwell por, desde y contra todas las pérdidas, gastos, daños y costos, incluidos los honorarios razonables de abogados, que surjan o estén relacionados con cualquier violación de estos Términos de uso por usted o cualquier otra acción relacionada con su uso de los Servicios. Sus obligaciones de indemnización incluyen, entre otras, las reclamaciones que surjan de cualquiera de sus Datos, así como cualquier reclamación que surja de actos u omisiones de cualquier otra persona o entidad que obtenga acceso a los Servicios a través de su información de registro, ya sea con su permiso o como resultado de su falta de uso de medidas de seguridad razonables.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                '5.2 Aparte de lo establecido en este documento, Picwell no está directamente involucrado en la transacción real entre usted y su proveedor de beneficios de salud y bienestar (el "Proveedor") que utiliza los Servicios. Como no estamos involucrados en la transacción directa real entre usted y el Proveedor, en caso de que tenga una disputa con el Proveedor, libera a las Partes de Picwell de reclamos, demandas y daños (reales y consecuentes) de todo tipo y naturaleza. , conocidos y desconocidos, sospechosos e insospechados, revelados y no revelados, que surjan o estén relacionados de alguna manera con tales disputas. Si es residente de California, renuncia a todos y cada uno de los derechos y beneficios conferidos por las disposiciones de la Sección 1542 del Código Civil del Estado de California, que establece lo siguiente:',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'UNA LIBERACIÓN GENERAL NO SE EXTIENDE A LAS RECLAMACIONES QUE EL ACREEDOR O LA PARTE DE LIBERACIÓN NO SABE NI SUSPENDE DE EXISTIR EN SU O FAVOR AL MOMENTO DE EJECUTAR LA LIBERACIÓN Y QUE, SI LO SABÍA POR ELLA O HABRÍA TENIDO QUE AFECTAR MATERIALMENTE CON EL DEUDOR O LA FIESTA LIBERADA.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Modificación de los Términos de uso',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-3',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Podemos revisar y actualizar estos Términos de uso de vez en cuando a nuestro exclusivo criterio. Todos los cambios son efectivos inmediatamente cuando se publican, o hasta treinta (30) días después de la publicación, según lo exija la ley, y se aplican a todo acceso y uso de los Servicios a partir de entonces. El uso continuado de los Servicios después de la publicación de cambios a estos Términos de uso significa que usted acepta y acepta dichos cambios. Es su responsabilidad verificar estos Términos de uso periódicamente para ver los cambios, ya que estos cambios son vinculantes para usted.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Medidas cautelares',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-3',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Su incumplimiento de estos Términos de uso puede resultar en un daño inmediato e irreparable para nosotros, para lo cual puede que no exista un remedio legal adecuado y, por lo tanto, usted acepta que tenemos derecho a un alivio equitativo para obligarlo a dejar y desistir de todo lo no autorizado uso, evaluación y divulgación de información obtenida a través de los Servicios, que se suma a cualquier otro recurso disponible por ley o en equidad.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Ley que rige',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-3',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Estos Términos de uso se regirán por las leyes del estado de Delaware y el control de la ley federal de EE. UU. La Ley Uniforme de Transacciones de Información Informática, la Convención de las Naciones Unidas sobre la Venta Internacional de Bienes y las reglas de elección de ley de cualquier jurisdicción no se aplicarán a estos Términos de Uso. Cualquier acción legal o procedimiento relacionado con estos Términos de uso se instituirá en un tribunal estatal o federal en el condado de New Castle, Delaware, y cada parte se somete a la jurisdicción personal exclusiva de dichos tribunales y renuncia a cualquier defensa relacionada con el lugar o foro no conveniencia.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Sin renuncia a la divisibilidad',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-3',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Una renuncia a cualquier incumplimiento de cualquier disposición de estos Términos de uso no es una renuncia a la repetición de dicho incumplimiento y no afectará de ninguna manera a otros términos o condiciones de estos Términos de uso. No renunciamos a ningún derecho por el incumplimiento de cualquier disposición de estos Términos de uso en cada caso en el que pueda aplicarse. En el caso de que cualquier disposición de estos Términos de uso se considere inaplicable, no afectará la validez o aplicabilidad de las disposiciones restantes, y será reemplazada por una disposición ejecutable que sea la más cercana a la intención subyacente a la disposición no ejecutable.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Asignabilidad',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-3',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Podemos asignar nuestros derechos y delegar nuestras obligaciones bajo estos Términos de uso, ya sea en su totalidad o en parte, en cualquier momento sin previo aviso. No puede asignar, sublicenciar ni transferir sus derechos u obligaciones, en su totalidad o en parte, en virtud de estos Términos de uso a ninguna otra persona sin nuestro consentimiento previo por escrito.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Relación',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-3',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Este Acuerdo no establece ninguna relación de asociación, empresa conjunta, empleo, franquicia o agencia entre usted y nosotros.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    privacy_title: 'Política de privacidad',
    privacy_banner_card:
      'Todos los datos proporcionados en ALEX son anónimos. Para más información, por favor consulte nuestra política de privacidad.',
    privacy_policy: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Última modificación: 8 de noviembre de 2019',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Picwell, Inc., una corporación de Delaware ("Picwell", "nosotros", "nuestro" o "nosotros") respeta su privacidad y se compromete a protegerla mediante el cumplimiento de esta Política de privacidad ("Política").\nEsta Política describe los tipos de información que podemos recopilar de usted o que puede proporcionar cuando utiliza el servicio de selección de planes de beneficios de Picwell y el servicio de soporte de decisiones (el "Servicio") y nuestras prácticas para recopilar, usar, mantener, proteger y divulgar que información.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Esta Política se aplica a la información que recopilamos a través del Servicio o por correo electrónico, texto y otros mensajes electrónicos entre usted y el Sitio web. Esta Política no se aplica a la información recopilada por nosotros fuera de línea o por cualquier otro medio, incluso en cualquier otro sitio web operado por Picwell o cualquier tercero, o cualquier tercero, incluso a través de cualquier aplicación o contenido que pueda vincularse o ser accesible desde o a través del servicio.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Lea esta Política detenidamente para comprender nuestras políticas y prácticas con respecto a su información y cómo la tratamos. Si no está de acuerdo con nuestras políticas y prácticas, su elección es no utilizar el Servicio. Al acceder o utilizar el Servicio, usted acepta esta Política. Esta Política puede cambiar ocasionalmente (consulte la Sección 8 a continuación (Cambios en nuestra Política de privacidad)). El uso continuado del Servicio después de que realicemos cambios se considerará como aceptación de dichos cambios, por lo tanto, consulte esta Política periódicamente para obtener actualizaciones.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '1. Tipos de información recopilada',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-3',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Recopilamos varios tipos de información de y sobre los usuarios del Servicio, incluida la información: (i) en conjunto que ha sido desidentificado o anónimo; y (ii) sobre su conexión a Internet, el equipo que utiliza para acceder al Servicio y los detalles de uso. Recopilamos esta información directamente de usted cuando nos la proporciona o automáticamente mientras navega por el Servicio.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'La información que recopilamos sobre oa través del Servicio puede incluir información que usted proporciona al completar formularios en nuestro sitio web, registros y copias de su correspondencia si se comunica con nosotros, detalles de la transacción que realiza a través del Servicio y sus consultas de búsqueda a través del Servicio. La información recopilada automáticamente puede incluir detalles de uso, direcciones de protocolo de Internet (IP) e información recopilada a través de cookies, balizas web y otras tecnologías de seguimiento.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Picwell, en relación con el Servicio, nunca solicitará información sensible o de identificación personal, como un nombre, correo electrónico o dirección postal, número de seguro social, número de licencia de conducir o un número de identificación personal de un banco o tarjeta de crédito.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '2 niños',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-3',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'De conformidad con la Ley de protección de la privacidad en línea para niños de 1998 (COPPA), Picwell está comprometido con la privacidad de todos los niños. Nuestro servicio no está destinado a niños menores de 13 años. Nadie menor de 13 años puede proporcionar información a este Servicio. No recopilamos deliberadamente información personal de niños menores de 13 años. Si usted es menor de 13 años, no use ni brinde información sobre este Servicio. Si descubrimos que hemos recopilado o recibido información personal de un niño menor de 13 años sin verificación del consentimiento de los padres, eliminaremos esa información. Si cree que podríamos tener información de un niño menor de 13 años o sobre él, contáctenos en legal@picwell.com.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '3. Información recopilada automáticamente',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-3',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'A medida que navega e interactúa con el Servicio, podemos utilizar tecnologías de recopilación automática de datos para recopilar cierta información sobre su equipo, acciones de navegación y patrones, incluidos los detalles de sus visitas al Servicio e información sobre su computadora y conexión a Internet. La información que recopilamos automáticamente es solo información estadística y no incluye información personal. Nos ayuda a mejorar el Servicio y a ofrecer un servicio mejor y más personalizado, lo que nos permite: (i) estimar el tamaño de nuestra audiencia y los patrones de uso; (ii) almacenar información sobre sus preferencias; (iii) ayudar a evaluar y mejorar el Servicio; y (iv) promocionar nuestros servicios utilizando los datos resultantes de forma agregada y / o desidentificada o anónima.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Las tecnologías que utilizamos para la recopilación automática de información pueden incluir cookies, balizas web y archivos de registro.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [
                {
                  type: 'italic',
                },
              ],
              value: 'Galletas',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Una cookie es un pequeño archivo colocado en el disco duro de su computadora. Puede negarse a aceptar las cookies del navegador activando la configuración adecuada en su navegador. Sin embargo, si selecciona esta configuración, es posible que no pueda acceder a ciertas partes del Servicio. A menos que haya ajustado la configuración de su navegador para que rechace las cookies, nuestro sistema emitirá cookies cuando dirija su navegador al Servicio.\nEl Servicio utiliza un software de rastreo para monitorear a sus visitantes provisto por Google Analytics que utiliza cookies para rastrear el uso de los visitantes. El software guardará una cookie en el disco duro de su computadora para rastrear y monitorear su participación y uso del sitio web, pero no almacenará, guardará ni recopilará información personal. Puede leer sobre cómo funciona Google Analytics en políticas.google.com.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [
                {
                  type: 'italic',
                },
              ],
              value: 'Balizas web',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Las páginas del Servicio y nuestros correos electrónicos pueden contener pequeños archivos electrónicos conocidos como balizas web (también conocidos como gifs transparentes, etiquetas de píxeles y gifs de un solo píxel) que permiten a Picwell, por ejemplo, contar usuarios que han visitado esas páginas o han abierto un correo electrónico y para otras estadísticas relacionadas del sitio web.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [
                {
                  type: 'italic',
                },
              ],
              value: 'Archivos de registro',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Al igual que muchos sitios, este sitio web utiliza archivos de registro. Estos archivos simplemente registran a los visitantes en el sitio, generalmente un procedimiento estándar para las empresas de alojamiento y una parte de la analítica del servicio de alojamiento. La información dentro de los archivos de registro incluye direcciones IP, tipo de navegador, proveedor de servicios de Internet (ISP), sello de fecha / hora, páginas de referencia / salida, y posiblemente la cantidad de clics. Esta información se utiliza para analizar tendencias, administrar el sitio, rastrear el movimiento del usuario en el sitio y recopilar información demográfica. Las direcciones IP y otra información similar no están vinculadas a ninguna información que sea personalmente identificable.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [
                {
                  type: 'italic',
                },
              ],
              value: 'No rastrear señales',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Las señales de No rastrear (DNT) son señales enviadas a través de un navegador que nos informa que no desea que se rastree. No asignamos ningún significado a las señales de DNT ya que no hay un estándar de cumplimiento consistente de la industria.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '4. Uso y divulgación de información',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-3',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Usamos la información que recopilamos sobre usted o que nos proporciona: (i) para presentarle nuestro Servicio y su contenido; (ii) para proporcionarle la información, productos o servicios que nos solicite; (iii) para cumplir con cualquier otro propósito para el cual lo proporciona; (iv) para cumplir con nuestras obligaciones y hacer cumplir nuestros derechos derivados de cualquier contrato celebrado entre usted y nosotros; (v) de cualquier otra manera que podamos describir cuando proporcione la información; y (vi) para cualquier otro propósito con su consentimiento.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Podemos revelar información agregada sobre nuestros usuarios e información que no identifica a ningún individuo, sin restricción.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '5. Su información personal',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-3',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'No recopilaremos ni conservaremos ninguna información de identificación personal de usted en relación con su uso del Servicio.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '6. Derechos de privacidad de California',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-3',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Según la Sección 1798.83 del Código Civil de California, los residentes de California pueden solicitar cierta información sobre nuestra divulgación de información personal a terceros con fines de marketing directo. Para realizar dicha solicitud, envíenos un correo electrónico a atlegal@picwell.com o escríbanos a: Picwell, Inc., Atención: Departamento legal, 1900 Market Street, Suite 120, Filadelfia, PA 19103.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '7. Seguridad de datos',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-3',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'La seguridad de su información también depende de usted. Cuando le hayamos dado (o donde haya elegido) acceder a ciertas partes del Servicio, usted es responsable de mantener confidencial la información que ingrese.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Lamentablemente, la transmisión de información a través de Internet no es completamente segura. Aunque hacemos nuestro mejor esfuerzo para proteger sus datos (como se define en nuestros Términos de uso), no podemos garantizar la seguridad de sus datos transmitidos al servicio. Cualquier transmisión de sus datos es bajo su propio riesgo. No somos responsables de eludir ninguna configuración de privacidad o medidas de seguridad contenidas a través del Servicio.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '8. Cambios a nuestra política',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-3',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Es nuestra política publicar cualquier cambio que hagamos en nuestra Política en esta página. Si realizamos cambios materiales en la forma en que tratamos sus datos, le notificaremos mediante un aviso a través de la página de inicio del Servicio. La fecha de la última revisión de la Política se encuentra en la parte superior de la página. Usted es responsable de visitar periódicamente el Servicio y esta política de privacidad para verificar cualquier cambio.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '9. Enlaces de terceros',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-3',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'El Servicio puede contener enlaces a aplicaciones o sitios web propiedad de terceros o operados por ellos. No somos responsables de las prácticas de privacidad ni del contenido de estas entidades. Estas entidades de terceros tienen prácticas separadas de privacidad y recopilación de datos y, por lo tanto, no tenemos responsabilidad u obligación relacionada con ellas.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '10. Contáctenos',
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-3',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Si tiene alguna pregunta o comentario sobre esta Política, envíenos un correo electrónico a legal@picwell.com.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    landing_illustrations_links: [
      'https://picwellapppublicdata.s3.amazonaws.com/contentful/profileimages/sketch-landing-new-1.svg',
      'https://picwellapppublicdata.s3.amazonaws.com/contentful/profileimages/sketch-landing-new-3.svg',
      'https://picwellapppublicdata.s3.amazonaws.com/contentful/profileimages/sketch-landing-new-2.svg',
      'https://picwellapppublicdata.s3.amazonaws.com/contentful/profileimages/sketch-landing-new-4.svg',
    ],
    illustration_phrases: [
      'en tu hora de almuerzo.',
      'mientras se mantiene ágil.',
      'durante el corte comercial.',
      'mientras agarras un café con leche.',
    ],
    survey_questions: {
      zip5: {
        key: 'zip5',
        heading: '¿Cuál es su código postal de 5 dígitos?',
        placeholder: 'Código postal',
      },
      fips5: {
        key: 'fips5',
        heading: '',
        options: {
          complete: 'Seleccionar condado',
          incomplete: 'Ingrese el código postal arriba',
        },
      },
      is_california_resident: {
        key: 'is_california_resident',
        heading: 'Are you a resident of the Great State of California?',
        options: {
          Yes: 'Si',
        },
      },
      is_full_time_or_part_time: {
        key: 'is_full_time_or_part_time',
        heading: '¿Es usted a tiempo completo o parcial?',
        options: {
          'Full-Time': 'Tiempo completo',
          'Part-Time': 'Medio tiempo',
        },
      },
    },
    survey_description:
      'Antes de comenzar, responda las siguientes preguntas para que podamos determinar para qué planes es elegible.',
    dropdown_instruction: 'Seleccione una respuesta',
    ie_message:
      'Notamos que está utilizando Internet Explorer. Para proporcionarle la mejor experiencia, le recomendamos usar otro navegador (como Google Chrome, Firefox o Safari). ',
    english_flag: 'https://picwellapppublicdata.s3.amazonaws.com/contentful/navigationbar/usa-flag.svg',
    spanish_flag: 'https://picwellapppublicdata.s3.amazonaws.com/contentful/navigationbar/spain-flag.png',
    tooltips: {
      name: {
        text: 'Utilizamos estas preguntas para determinar qué planes son elegibles para usted',
        title: 'Elegibilidad',
        underline: 'Elegibilidad',
      },
    },
    do_not_sell_my_info_text: 'Sus opciones de privacidad',
  },
};

export default eligibility;
