import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';

export interface CSSTransitionTProps {
  isOpen?: boolean;
  translateY?: string;
  translateX?: string;
  timeout?: number;
  delay?: number;
  unmountOnExit?: boolean;
  children?;
  enableTransitionInJest?: boolean;
}

interface TransitionProps {
  x: string;
  y: string;
  timeout: number;
}

const CSSTransitionStyled = styled.div<TransitionProps>`
  &.fadeUp-enter,
  &.fadeUp-appear {
    opacity: 0;
    transform: translate(${({ x, y }) => `${x}, ${y}`});
  }
  &.fadeUp-enter-active,
  &.fadeUp-appear-active {
    opacity: 1;
    transform: translate(0, 0);
    transition:
      opacity ${({ timeout }) => `${timeout}ms`},
      transform ${({ timeout }) => `${timeout}ms`};
  }
  &.fadeUp-exit,
  &.fadeUp-exit-done {
    opacity: 1;
    transform: translate(0, 0);
  }
  &.fadeUp-exit-active {
    opacity: 0;
    transform: translate(${({ x, y }) => `${x}, ${y}`});
    transition:
      opacity ${({ timeout }) => `${timeout}ms`},
      transform ${({ timeout }) => `${timeout}ms`};
  }
`;

export const FadeUp = ({
  isOpen = false,
  translateX = '0',
  translateY = '1rem',
  timeout = 200,
  delay = 0,
  unmountOnExit = true,
  children,
  enableTransitionInJest = false,
}: CSSTransitionTProps): JSX.Element => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const delayTimer = setTimeout(() => setOpen(true), delay);

    return () => {
      clearTimeout(delayTimer);
    };
  }, [isOpen]);

  const isTestEnv = process.env.NODE_ENV === 'test';
  if (isTestEnv && !enableTransitionInJest) {
    return children;
  }

  return (
    <CSSTransition
      in={isOpen && open}
      timeout={timeout}
      classNames="fadeUp"
      appear
      unmountOnExit={unmountOnExit}
    >
      <CSSTransitionStyled timeout={timeout} x={translateX} y={translateY}>
        {children}
      </CSSTransitionStyled>
    </CSSTransition>
  );
};
