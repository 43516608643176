import React from 'react';

import { NoHsaPlanSelected } from 'Containers/HsaPage/styled';
import { useTextContext } from 'Contexts/textContext';
import { Button } from 'DesignLibrary/atoms';

interface NotEligibleProps {
  handleGoToHealth: () => void;
  handleNext: () => void;
}

export const NotEligible = ({ handleGoToHealth, handleNext }: NotEligibleProps) => {
  const { retrieveContentfulData } = useTextContext();
  const buttonText = retrieveContentfulData<Record<string, string>>('hsa.button_text', {});
  const ineligiblePlanText = retrieveContentfulData<string>('hsa.ineligible_health_plan_text');
  const ineligiblePlanSubtext = retrieveContentfulData<string>('hsa.ineligible_health_plan_subtext');

  return (
    <NoHsaPlanSelected id="noHsaPlan">
      <h1>{ineligiblePlanText}</h1>
      <h4>{ineligiblePlanSubtext}</h4>
      <div>
        <Button id="back-to-profile-btn" onClick={handleGoToHealth}>
          {buttonText.back}
        </Button>
        <Button id="go-to-next-btn" onClick={handleNext}>
          {buttonText.review}
        </Button>
      </div>
    </NoHsaPlanSelected>
  );
};
