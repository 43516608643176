import { Stack } from '@chakra-ui/react';
import React from 'react';

import { TextField } from 'Containers/App/types';
import { BuilderBenefit, DentalBenefits } from 'Containers/ViewBenefitPage/types';
import { transformBenefitItems } from 'Containers/ViewBenefitPage/utils';
import { Button, Paragraph } from 'DesignLibrary/atoms';
import { TooltipPlanDetails } from 'DesignLibrary/molecules';
import RichText from 'Shared/RichText';
import Text from 'Shared/Text';
import { SupplementalPlan } from 'Types/entities';
import { toDollars } from 'Utils/helpers';

import { HiddenItem } from './PlanContent/styled';

const getCoverageType = (
  paymentType?: string,
  coinsurance?: string,
  predeductible?: boolean,
  hasDeductible?: boolean,
) => {
  if (!paymentType) {
    return '';
  }

  const coverageAmount =
    paymentType === 'Copay' ? (
      <Text field="benefits_section_dental.copays_apply" />
    ) : (
      <Text field="benefits_section_dental.coinsurance_level" vars={{ x: coinsurance }} />
    );

  const predeductibleSuffix = predeductible ? (
    <Text field="benefits_section_dental.has_predeductible_label" />
  ) : (
    <Text field="benefits_section_dental.no_predeductible_label" />
  );

  return (
    <>
      {coverageAmount} {hasDeductible && predeductibleSuffix}
    </>
  );
};

const orthoCopays = (adultAmount?: string, kidsAmount?: string) => {
  if (adultAmount) {
    return (
      // $25 copay for children; $35 for adults
      <>
        <Text field="benefits_section_dental.copay_children" vars={{ x: toDollars(kidsAmount) }} />
        {'; '}
        <Text field="benefits_section_dental.copay_adults" vars={{ x: toDollars(adultAmount) }} />
      </>
    );
  }

  return <Text field="benefits_section_dental.copay_children" vars={{ x: toDollars(kidsAmount) }} />;
};

const orthoPaymentSummary = (benefits: DentalBenefits) => {
  switch (benefits.ortho_payment_type) {
    case 'Coinsurance':
      return (
        <Text field="benefits_section_dental.coinsurance_level" vars={{ x: benefits.ortho_coinsurance }} />
      );

    case 'Copay':
      return orthoCopays(benefits.ortho_adults_copay_amount, benefits.ortho_kids_copay_amount);

    default:
      return null;
  }
};

const orthoDeductibleSummary = (benefits: DentalBenefits) => {
  switch (benefits.ortho_deductible_type) {
    case 'Integrated':
      return <Text field="benefits_section_dental.ortho_deductible_integrated" />;

    case 'None':
      return <Text field="benefits_section_dental.ortho_deductible_none" />;

    case 'Independent':
      return toDollars(benefits.ortho_deductible);

    default:
      return null;
  }
};

const orthoHiddenDetails = (benefits: DentalBenefits) => {
  if (!benefits.has_kids_ortho) {
    return (
      <HiddenItem className="orthodontia">
        <Paragraph size="mini">
          <Text field="benefits_section_dental.orthodontia_header" />
        </Paragraph>
        <Paragraph as="span" size="mini">
          <Text field="benefits_section.coverage_options.notCovered" />
        </Paragraph>
      </HiddenItem>
    );
  }

  return (
    <>
      <HiddenItem className="orthodontia">
        <Paragraph size="normal">
          <Text field="benefits_section_dental.orthodontia_header" />
        </Paragraph>
        <Paragraph as="span" size="mini">
          {orthoPaymentSummary(benefits)}
        </Paragraph>
      </HiddenItem>

      <HiddenItem className="orthodontia_covers">
        <Paragraph as="span" size="mini">
          <Text field="benefits_section_dental.orthodontia_covers" />
        </Paragraph>
        <Paragraph as="span" size="mini">
          {benefits.has_adults_ortho ? (
            <Text field="benefits_section_dental.covers_adults_and_children" />
          ) : (
            <Text field="benefits_section_dental.covers_children_only" />
          )}
        </Paragraph>
      </HiddenItem>

      <HiddenItem className="orthodontia_deductible">
        <Paragraph as="span" size="mini">
          <Text field="benefits_section_additional.deductible" />
        </Paragraph>
        <Paragraph as="span" size="mini">
          {orthoDeductibleSummary(benefits)}
        </Paragraph>
      </HiddenItem>

      {parseInt(benefits?.ortho_lifetime_max || '', 10) > 0 && (
        <HiddenItem className="orthodontia_lifetime_maximum">
          <Paragraph as="span" size="mini">
            <Text field="benefits_section_dental.lifetime_maximum" />
          </Paragraph>
          <Paragraph as="span" size="mini">
            <Text
              field="benefits_section_dental.per_person"
              vars={{ x: toDollars(benefits.ortho_lifetime_max) }}
            />
          </Paragraph>
        </HiddenItem>
      )}
    </>
  );
};

const getTooltip = (builderBenefit: BuilderBenefit | undefined, type: string) => {
  const tooltipData = builderBenefit?.[`dental_${type}_examples`];
  const prefix = 'benefits_section_dental';

  if (tooltipData) {
    return (
      <TooltipPlanDetails
        tooltip={
          <Text
            field={`${prefix}.${type}_example_list_preface` as TextField}
            vars={{
              x: <RichText fieldContent={tooltipData} field={undefined} key={`${type}-list`} />,
            }}
          />
        }
        keyHint={`${type}-examples`}
        isRichText
        size="small"
      >
        <Text field={`${prefix}.${type}_care` as TextField} />
      </TooltipPlanDetails>
    );
  }

  return <Text field={`${prefix}.${type}_care` as TextField} />;
};

export const dentalDetails = (plan: SupplementalPlan, builderBenefit: BuilderBenefit | undefined) => {
  const benefits = transformBenefitItems(plan) as DentalBenefits;

  return (
    <section className="benefits hidden-benefits dental-plan">
      <Paragraph as="h6">
        <Text field="benefits_section.plan_card_text.payouts" />
      </Paragraph>

      <HiddenItem>
        <Paragraph size="mini" className="key">
          {getTooltip(builderBenefit, 'preventive')}
        </Paragraph>
        <Paragraph size="mini" className="value">
          {getCoverageType(
            benefits.preventive_payment_type,
            benefits.preventive_coinsurance,
            benefits.preventive_is_predeductible,
            !!plan.deductible,
          )}
        </Paragraph>
      </HiddenItem>

      <HiddenItem>
        <Paragraph size="mini" className="key">
          {getTooltip(builderBenefit, 'basic')}
        </Paragraph>
        <Paragraph size="mini" className="value">
          {getCoverageType(
            benefits.basic_payment_type,
            benefits.basic_coinsurance,
            benefits.basic_is_predeductible,
            !!plan.deductible,
          )}
        </Paragraph>
      </HiddenItem>

      <HiddenItem>
        <Paragraph size="mini" className="key">
          {getTooltip(builderBenefit, 'major')}
        </Paragraph>
        <Paragraph size="mini" className="value" data-testid="dental-major-coverage">
          {benefits.major_payment_type ? (
            getCoverageType(
              benefits.major_payment_type,
              benefits.major_coinsurance,
              benefits.major_is_predeductible,
              !!plan.deductible,
            )
          ) : (
            <Text field="benefits_section.coverage_options.notCovered" />
          )}
        </Paragraph>
      </HiddenItem>

      {orthoHiddenDetails(benefits)}

      {benefits.network_name && (
        <>
          <HiddenItem className="network-info">
            <Paragraph as="span" size="normal">
              <Text field="benefits_section_additional.network_info_header" />
            </Paragraph>
          </HiddenItem>
          <HiddenItem className="network-info">
            <Paragraph as="span" size="mini">
              <Text field="benefits_section_additional.network_name" />
            </Paragraph>
            <Paragraph as="span" size="mini">
              {benefits.network_name}
            </Paragraph>
          </HiddenItem>
        </>
      )}

      {benefits.provider_link && (
        <HiddenItem className="provider-finder">
          <Paragraph as="span" size="mini">
            <Text field="benefits_section_additional.provider_directory" />
          </Paragraph>
          <Stack
            className="value"
            align="flex-end"
            gap={0}
            sx={{
              a: {
                fontSize: '14px !important',
              },
            }}
          >
            <Button url={benefits.provider_link} buttonType="link" size="xsmall">
              <Text field="benefits_section_additional.provider_link" />
            </Button>
            {builderBenefit?.provider_finder_instructions && (
              <Paragraph as="span" size="mini">
                {builderBenefit.provider_finder_instructions}
              </Paragraph>
            )}
          </Stack>
        </HiddenItem>
      )}
    </section>
  );
};

export const getDentalTagList = (dentalBenefits: DentalBenefits): JSX.Element[] => {
  const tags = [
    <Text key="preventive" field="benefits_section_dental.preventive_header" />,
    <Text key="basic" field="benefits_section_dental.basic_header" />,
  ];

  if (dentalBenefits.major_payment_type) {
    tags.push(<Text field="benefits_section_dental.major_header" />);
  }
  if (dentalBenefits.has_kids_ortho) {
    tags.push(<Text field="benefits_section_dental.orthodontia_header" />);
  }

  return tags;
};

// TODO(APP-1446):
//   Implement this code from horus-shared:dental/contentful.ts
//   and remove/suppress duplicative code using `.coverage_disclaimer` for dental. (probably in renderCoverageDisclaimer)
//
// const durationTerm = benefit.settings?.to_or_through_age
//   ? 'until'
//   : 'through';
// if (
//   benefit.settings?.oldest_coverage_age ===
//   benefit.settings?.oldest_college_coverage_age
// ) {
//   return `Generally, kids may be covered ${durationTerm} age ${benefit.settings?.oldest_coverage_age}.`;
// } else {
//   return `Generally, kids may be covered ${durationTerm} age ${benefit.settings?.oldest_coverage_age}, or ${benefit.settings?.oldest_college_coverage_age} if they are in college.`;
