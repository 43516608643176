import { fromJS } from 'immutable';

import { TypedMap } from 'Utils/immutable-types';
import { wrapImmutableReducerInPojoTranslation } from 'Utils/reducers';

import { SUBMIT_FEEDBACK } from './constants';

export interface ReviewPageReducerState {
  feedbackSubmitted: boolean;
}

export type ReviewState = TypedMap<ReviewPageReducerState>;

const initialState: ReviewState = fromJS({
  feedbackSubmitted: false,
});

function reviewPageReducer(state = initialState, action): unknown {
  switch (action.type) {
    case SUBMIT_FEEDBACK:
      return submitFeedback(state);
    default:
      return state;
  }
}

function submitFeedback(state: ReviewState) {
  return state.set('feedbackSubmitted', true);
}

const pojoReviewPageReducer = wrapImmutableReducerInPojoTranslation(reviewPageReducer);

export default pojoReviewPageReducer;
