import React, { FC, useRef } from 'react';

import { Icon } from 'DesignLibrary/atoms';
import { useViewport } from 'DesignLibrary/context';
import Text from 'Shared/Text';
import { GraphMode } from 'Types/entities';

import { Container, SwitchButton } from './styled';

interface SwitchProps {
  active: 'short' | 'long';
  handleButton: (val: GraphMode) => void;
}

export const Switch: FC<SwitchProps> = ({ active, handleButton }) => {
  const { device } = useViewport();

  const shortTabRef = useRef<HTMLButtonElement>(null);

  const longTabRef = useRef<HTMLButtonElement>(null);

  const handleOnKeyDown = (e) => {
    if (active === 'short') {
      if (e.key === 'ArrowRight') {
        handleButton('long');
        if (longTabRef.current) {
          longTabRef.current.focus();
        }
      }
    } else if (e.key === 'ArrowLeft') {
      handleButton('short');
      if (shortTabRef.current) {
        shortTabRef.current.focus();
      }
    }
  };

  return (
    <Container role="tablist" aria-activedescendant={`${active}-tab`}>
      <SwitchButton
        id="short-tab"
        ref={shortTabRef}
        role="tab"
        tabIndex={active === 'short' ? 0 : -1}
        aria-selected={active === 'short'}
        onClick={() => handleButton('short')}
        onKeyDown={handleOnKeyDown}
        isSelected={active === 'short'}
      >
        <Text field="hsa.interactive_section.yearly_contribution_button" />
      </SwitchButton>
      <SwitchButton
        id="long-tab"
        ref={longTabRef}
        role="tab"
        tabIndex={active === 'long' ? 0 : -1}
        aria-selected={active === 'long'}
        onClick={() => handleButton('long')}
        onKeyDown={handleOnKeyDown}
        isSelected={active === 'long'}
      >
        <>
          {device !== 'mobile' && (
            <Icon type="ChartLineUp" color={active === 'long' ? '--text-black' : '--text-gray'} />
          )}
          <Text field="hsa.interactive_section.retirement_savings_button" />
        </>
      </SwitchButton>
    </Container>
  );
};
