import styled, { keyframes } from 'styled-components';

import { media } from 'DesignLibrary/global';
import { cardStyling } from 'DesignLibrary/utils';

const Scroll = keyframes`
  0% {
    transform: translateX(100%);
    opacity: 0;
    height: 0;
    margin-top: var(--sizing-xs);
  }

  10% {
    opacity: 1;
    height: var(--sizing-lg);
    margin-top: var(--sizing-sm);
  }

  90% {
    opacity: 1;
    height: var(--sizing-lg);
    margin-top: var(--sizing-sm);
  }

  100% {
    transform: translateX(-500px);
    opacity: 0;
    height: 0;
    margin-top: var(--sizing-xs);
  }
`;

const Raise = keyframes`
  0% {
    transform: translateY(0%);
  }

  100% {
    transform: translateY(-140%);
  }
`;

interface ProgressBarProps {
  bubbleDuration: number;
  bubbleDelay: number;
}

export const ProgressBarContainer = styled.div<ProgressBarProps>`
  ${cardStyling}
  position: relative;
  overflow: hidden;
  padding: var(--sizing-sm);

  ${media.TABLET`
    padding: var(--sizing-md);
  `}

  .bubbles {
    display: flex;
    column-gap: var(--sizing-xs);
    animation: ${Scroll} ${({ bubbleDuration }) => `${bubbleDuration}ms`}
      ${({ bubbleDelay }) => `${bubbleDelay}ms`} forwards ease-in;
    opacity: 0;
    height: 0;

    ${media.TABLET<ProgressBarProps>`
      position: absolute;
      top: 100%;
      left: calc(50% - 100px);
      flex-direction: column;
      row-gap: var(--sizing-sm);
      width: 200px;
      margin-top: 0;
      animation: ${Raise} ${({ bubbleDuration }) => `${bubbleDuration}ms`} ${({ bubbleDelay }) =>
        `${bubbleDelay}ms`} forwards linear;
      opacity: 1;
      height: auto;
    `}
  }

  .bar {
    margin-top: var(--sizing-sm);
    ${media.TABLET`margin-top: var(--sizing-sm);`}
  }
`;

export const Bar = styled.div`
  position: relative;
  height: 4px;
  width: 100%;
  border-radius: 1rem;
  background-color: var(--colors-gray-100);
  overflow: hidden;
`;

interface FillProps {
  percent: number;
}

export const Fill = styled.div<FillProps>`
  background: var(--primary-blue);
  height: 100%;
  width: ${({ percent }) => `${percent}%`};
  border-radius: inherit;
  transition: width 1s linear;
`;

export const Bubble = styled.div`
  position: relative;
  display: inline-block;
  background: var(--primary-white);
  border: 1px solid var(--border-gray);
  box-shadow: var(--shadow-card);
  border-radius: var(--border-radius-xs);
  padding: 4px 12px;
  align-self: flex-start;
  white-space: nowrap;

  ${media.TABLET`
    &:nth-child(1) {left: 1rem;}
    &:nth-child(2) {left: 6rem;}
    &:nth-child(3) {left: -1rem;}
    &:nth-child(4) {left: 4rem;}
    &:nth-child(5) {left: 1.5rem;}
    &:nth-child(6) {left: 3rem;}
    &:nth-child(7) {left: -2rem;}
    &:nth-child(8) {left: 7rem;}
    &:nth-child(9) {left: 0.5rem;}
  `}

  ${media.DESKTOP`
    &:nth-child(1) {left: 1rem;}
    &:nth-child(2) {left: 7rem;}
    &:nth-child(3) {left: -3.5rem;}
    &:nth-child(4) {left: 3rem;}
    &:nth-child(5) {left: 8rem;}
    &:nth-child(6) {left: 1rem;}
    &:nth-child(7) {left: -4rem;}
    &:nth-child(8) {left: 8rem;}
    &:nth-child(9) {left: 0.5rem;}
  `}
`;
