import React from 'react';

import { renderAdoaDeductiblesPlanCosts, showAdoaHack } from 'ClientCustomizationData/adoa';
import { COVERAGE_TIERS } from 'Containers/App/constants';
import { ViewPlanPageCard } from 'Containers/ViewPlanPage/ViewPlanPageCard';
import { BreakdownTag } from 'Containers/ViewPlanPage/ViewPlanPageCard/BreakdownTag';
import { useTextContext } from 'Contexts/textContext';
import { FormattedTooltipProps, Numbers } from 'DesignLibrary/atoms';
import { TooltipPlanDetails } from 'DesignLibrary/molecules';
import { IconBlockProps } from 'DesignLibrary/molecules/IconBlock';
import Plan from 'Models/plan';
import Text from 'Shared/Text';
import { Recommendation } from 'Types/entities';
import { toDollars } from 'Utils/helpers';

import { SplitCostRow, SplitCostWrapper } from './styled';
import { PlanCardRow } from '../_shared/styled';

interface PlanEssentialsCardProps {
  client: string;
  recommendation: Recommendation;
  coverageTier: COVERAGE_TIERS;
}

export const PlanEssentialsCard: React.FC<PlanEssentialsCardProps> = ({
  client,
  recommendation,
  coverageTier,
}) => {
  const { retrieveContentfulData } = useTextContext();

  const title = retrieveContentfulData<string>(
    'health_details_section.plan_details.plan_essentials_card_title',
  );
  const icon: IconBlockProps = {
    icon: 'Sparkle',
    color: 'blue',
  };

  // const planTypeTooltip: FormattedTooltipProps = {
  //   text: 'TODO FIX ME - text',
  //   title: 'TODO FIX ME - title',
  //   underline: planTypeUnderline,
  // };

  const inNetworkDeductibleTooltip = {
    ...retrieveContentfulData<FormattedTooltipProps>('tool_tips.in_network_deductible'),
  };

  const outOfNetworkDeductibleTooltip = {
    ...retrieveContentfulData<FormattedTooltipProps>('tool_tips.out_of_network_deductible'),
  };

  const inNetworkOopLimitTooltip = {
    ...retrieveContentfulData<FormattedTooltipProps>('tool_tips.in_network_out_of_pocket_limit'),
  };

  const outOfNetworkOopLimitTooltip = {
    ...retrieveContentfulData<FormattedTooltipProps>('tool_tips.out_of_network_out_of_pocket_limit'),
  };

  const plan = new Plan(recommendation);

  const {
    inNetworkDeductible,
    inNetworkDrugDeductible,
    inNetworkMedDeductible,
    oonDeductible,
    oonDrugDeductible,
    oonMedDeductible,
    inNetworkOopMax,
    inNetworkMedOopMax,
    inNetworkDrugOopMax,
    oonOopMax,
    oonDrugOopMax,
    oonMedOopMax,
  } = plan;

  const formatDeductibleOrOop = (value) => {
    if (!value) {
      if (value === 0) {
        return toDollars(String(value));
      }
      return retrieveContentfulData<string>('health_section.null_plan_detail_text.deductibleAndOopMax');
    }
    return toDollars(String(value));
  };

  // Define In/Out Network Deductibles
  const inNetworkCompDed = formatDeductibleOrOop(inNetworkDeductible);
  const inNetworkMedDed = formatDeductibleOrOop(inNetworkMedDeductible);
  const inNetworkDrugDed = formatDeductibleOrOop(inNetworkDrugDeductible);

  const outNetworkCompDed = formatDeductibleOrOop(oonDeductible);
  const outNetworkMedDed = formatDeductibleOrOop(oonMedDeductible);
  const outNetworkDrugDed = formatDeductibleOrOop(oonDrugDeductible);

  // Define In/Out Network OOP Limits
  const inNetworkOopLimitComp = formatDeductibleOrOop(inNetworkOopMax);
  const inNetworkOopLimitMed = formatDeductibleOrOop(inNetworkMedOopMax);
  const inNetworkOopLimitDrug = formatDeductibleOrOop(inNetworkDrugOopMax);

  const outNetworkOopLimitComp = formatDeductibleOrOop(oonOopMax);
  const outNetworkOopLimitMed = formatDeductibleOrOop(oonMedOopMax);
  const outNetworkOopLimitDrug = formatDeductibleOrOop(oonDrugOopMax);

  const showSplitInNetworkMedicalAndDrugDed = !plan.getIsInNetworkDeductibleComprehensive();
  const showSplitOutNetworkMedicalAndDrugDed = !plan.getIsOonDeductibleComprehensive();
  const showSplitInNetworkMedicalAndDrugOopLimit = !plan.getIsInNetworkOopLimitComprehensive();
  const showSplitOutNetworkMedicalAndDrugOopLimit = !plan.getIsOonOopLimitComprehensive();

  // Is the plan HDHP
  const showHdhpNote =
    ['prmifield', 'prmicorp', 'prmiutfield'].includes(client) && ['OAP3', 'OAP4'].includes(plan.planId);

  const renderDeductibles = () => {
    const showAdoa = showAdoaHack(client, plan.planId);
    if (showAdoa) {
      return renderAdoaDeductiblesPlanCosts(coverageTier);
    }
    return (
      <PlanCardRow className="planCardRow">
        <TooltipPlanDetails
          tooltip={inNetworkDeductibleTooltip}
          keyHint={`plan-${plan.planId}-in-network-deductible`}
        >
          {inNetworkDeductibleTooltip.underline as string}
        </TooltipPlanDetails>
        {showSplitInNetworkMedicalAndDrugDed ? (
          <SplitCostWrapper>
            <SplitCostRow>
              <BreakdownTag>
                <Text field="health_section.comprehensive_plan_terms.medical" />
              </BreakdownTag>
              <Numbers className="in-network-med-ded">
                {inNetworkMedDed}
                {showHdhpNote && <>&dagger;</>}
              </Numbers>
            </SplitCostRow>

            <SplitCostRow>
              <BreakdownTag>
                <Text field="health_section.comprehensive_plan_terms.drug" />
              </BreakdownTag>
              <Numbers className="in-network-drug-ded">{inNetworkDrugDed}</Numbers>
            </SplitCostRow>
          </SplitCostWrapper>
        ) : (
          <Numbers className="in-network-comprehensive-ded">
            {inNetworkCompDed}
            {showHdhpNote && <>&dagger;</>}
          </Numbers>
        )}
      </PlanCardRow>
    );
  };

  return (
    <ViewPlanPageCard title={title} icon={icon}>
      {renderDeductibles()}
      {/* <PlanInfo sub={showSplitOutNetworkMedicalAndDrugDed}> */}
      <PlanCardRow className="planCardRow">
        <TooltipPlanDetails
          tooltip={outOfNetworkDeductibleTooltip}
          keyHint={`plan-${plan.planId}-out-of-network-deductible`}
        >
          {outOfNetworkDeductibleTooltip.underline as string}
        </TooltipPlanDetails>
        {showSplitOutNetworkMedicalAndDrugDed ? (
          <SplitCostWrapper>
            <SplitCostRow>
              <BreakdownTag>
                <Text field="health_section.comprehensive_plan_terms.medical" />
              </BreakdownTag>
              <Numbers className="out-of-network-med-ded">{outNetworkMedDed}</Numbers>
            </SplitCostRow>

            <SplitCostRow>
              <BreakdownTag>
                <Text field="health_section.comprehensive_plan_terms.drug" />
              </BreakdownTag>
              <Numbers className="out-of-network-drug-ded">{outNetworkDrugDed}</Numbers>
            </SplitCostRow>
          </SplitCostWrapper>
        ) : (
          <Numbers className="out-of-network-comprehensive-ded">{outNetworkCompDed}</Numbers>
        )}
      </PlanCardRow>

      {/* </PlanInfo> */}
      {/* <PlanInfo sub={showSplitInNetworkMedicalAndDrugOopLimit}> */}
      <PlanCardRow className="planCardRow">
        <TooltipPlanDetails
          tooltip={inNetworkOopLimitTooltip}
          keyHint={`plan-${plan.planId}-in-network-oop-limit`}
        >
          {inNetworkOopLimitTooltip.underline as string}
        </TooltipPlanDetails>
        {showSplitInNetworkMedicalAndDrugOopLimit ? (
          <SplitCostWrapper>
            <SplitCostRow>
              <BreakdownTag>
                <Text field="health_section.comprehensive_plan_terms.medical" />
              </BreakdownTag>
              <Numbers className="in-network-med-oop-max">{inNetworkOopLimitMed}</Numbers>
            </SplitCostRow>

            <SplitCostRow>
              <BreakdownTag>
                <Text field="health_section.comprehensive_plan_terms.drug" />
              </BreakdownTag>
              <Numbers className="in-network-drug-oop-max">{inNetworkOopLimitDrug}</Numbers>
            </SplitCostRow>
          </SplitCostWrapper>
        ) : (
          <Numbers className="in-network-comprehensive-oop-max">{inNetworkOopLimitComp}</Numbers>
        )}
      </PlanCardRow>

      {/* </PlanInfo> */}
      {/* <PlanInfo sub={showSplitOutNetworkMedicalAndDrugOopLimit}> */}
      <PlanCardRow className="planCardRow">
        <TooltipPlanDetails
          tooltip={outOfNetworkOopLimitTooltip}
          keyHint={`plan-${plan.planId}-out-of-network-oop-limit`}
        >
          {outOfNetworkOopLimitTooltip.underline as string}
        </TooltipPlanDetails>
        {showSplitOutNetworkMedicalAndDrugOopLimit ? (
          <SplitCostWrapper>
            <SplitCostRow>
              <BreakdownTag>
                <Text field="health_section.comprehensive_plan_terms.medical" />
              </BreakdownTag>
              <Numbers className="out-of-network-med-oop-max">{outNetworkOopLimitMed}</Numbers>
            </SplitCostRow>

            <SplitCostRow>
              <BreakdownTag>
                <Text field="health_section.comprehensive_plan_terms.drug" />
              </BreakdownTag>
              <Numbers className="out-of-network-drug-oop-max">{outNetworkOopLimitDrug}</Numbers>
            </SplitCostRow>
          </SplitCostWrapper>
        ) : (
          <Numbers className="out-of-network-comprehensive-oop-max">{outNetworkOopLimitComp}</Numbers>
        )}
      </PlanCardRow>

      {/* </PlanInfo> */}
    </ViewPlanPageCard>
  );
};
