import styled from 'styled-components';

import { breakpoint } from 'DesignLibrary/global';
import { cardStyling } from 'DesignLibrary/utils';

export const ChartContainer = styled.div`
  ${cardStyling}
  padding: var(--sizing-sm);
  padding-top: var(--sizing-md);

  ${breakpoint.TABLET} {
    padding: var(--sizing-md);
  }

  .header {
    margin-bottom: var(--sizing-md);

    * {
      text-align: center;
    }

    h4 {
      font-weight: 900 !important;
      margin-bottom: var(--sizing-2xs);
    }

    ${breakpoint.TABLET} {
      * {
        text-align: left;
      }
    }
  }

  .main-content {
    ${breakpoint.TABLET} {
      display: grid;
      grid-template-columns: auto 320px;
      gap: var(--sizing-sm);
    }

    ${breakpoint.DESKTOP} {
      grid-template-columns: auto 360px;
    }
  }

  .chart {
    min-width: 0;
    margin-bottom: var(--sizing-md);

    ${breakpoint.DESKTOP} {
      margin-bottom: 0;
    }
  }
`;
