import React, { ChangeEvent } from 'react';

import { SelectContainer, Select } from './styled';

export interface Option {
  option: string;
  value: string;
}

export interface SelectInputProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  placeholder?: string;
  options: Option[];
  selected?: string;
  labelId?: string;
  handleChange: (e: ChangeEvent<HTMLSelectElement>, val: string) => void;
}

const SelectInput = ({
  placeholder,
  options,
  required,
  selected = '',
  disabled,
  labelId,
  handleChange,
  ...rest
}: SelectInputProps): JSX.Element => (
  <SelectContainer>
    <Select
      aria-labelledby={labelId}
      required={required}
      value={selected}
      disabled={disabled}
      onChange={(e) => handleChange(e, e.target.value)}
      data-testid="select"
      {...rest}
    >
      {placeholder && (
        <option value="" disabled={selected !== ''}>
          {placeholder}
        </option>
      )}
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.option}
        </option>
      ))}
    </Select>
  </SelectContainer>
);

export default SelectInput;
