import styled from 'styled-components';

import { breakpoint } from 'DesignLibrary/global';

export const BulletStyle = styled.div`
  display: flex;
  align-items: flex-start;
  gap: var(--sizing-sm);
  padding: 0 var(--sizing-lg);

  ${breakpoint.TABLET} {
    padding: 0;
    align-items: center;
  }

  .paragraph {
    position: relative;
    top: -4px;
    max-width: 700px;

    ${breakpoint.TABLET} {
      top: 0;
    }
  }

  .include-button {
    button {
      margin-top: var(--sizing-xs);
    }

    ${breakpoint.TABLET} {
      display: flex;
      align-items: center;
      gap: var(--sizing-sm);

      button {
        margin: 0;
      }
    }
  }
`;
