import styled from 'styled-components';

import { breakpoint } from 'DesignLibrary/global';

export const TaxSavingsContainer = styled.section`
  margin-bottom: var(--sizing-2xl);

  ${breakpoint.TABLET} {
    margin-bottom: var(--sizing-3xl);
  }
`;

export const NotEligibleContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: var(--sizing-2xl);
  max-width: 700px;

  .paragraph {
    text-align: center;
  }
`;
