import styled, { css } from 'styled-components';

import { breakpoint } from 'DesignLibrary/global';

export const RowContainer = styled.div`
  display: grid;
  border-bottom: var(--border);
  grid-template-areas: 'title title' 'valueOne valueTwo';

  /* MOBILE CSS GRID LAYOUT */
  > :first-child {
    grid-area: title;
  }

  > :nth-child(2) {
    grid-area: valueOne;
  }

  > :nth-child(3) {
    grid-area: valueTwo;
  }

  ${breakpoint.TABLET} {
    grid-template-areas: unset;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    > * {
      grid-area: initial !important;
    }
  }
`;

interface CellProps {
  gray?: boolean;
  size: 'small' | 'medium' | 'large';
  hideRowHeader?: boolean;
}

export const Cell = styled.div<CellProps>`
  position: relative;
  display: flex;
  align-items: center;
  border-right: var(--border);
  min-width: 0;

  &.row-header {
    border-bottom: var(--border);
    padding: var(--sizing-xs);

    > * {
      text-align: center;
    }

    ${({ hideRowHeader }) =>
      hideRowHeader &&
      css`
        display: none;
      `}

    ${breakpoint.TABLET} {
      border-bottom: 0;
      padding: var(--sizing-sm) var(--sizing-md);

      > * {
        text-align: left;
      }

      ${({ hideRowHeader }) =>
        hideRowHeader &&
        css`
          display: flex;
        `}
    }
  }

  ${({ gray }) =>
    gray &&
    css`
      background: var(--background-gray);
    `}

  ${({ size }) => {
    switch (size) {
      case 'large':
        return css`
          padding: var(--sizing-sm);
        `;
      case 'medium':
        return css`
          padding: var(--sizing-sm);
        `;
      case 'small':
        return css`
          padding: var(--sizing-sm) var(--sizing-xs);
        `;
      default:
        return null;
    }
  }}

  &:nth-child(4) {
    display: none;
  }

  > div {
    width: 100%;
  }

  .asterisk {
    .paragraph {
      text-align: center;
    }
  }

  ${breakpoint.TABLET} {
    min-height: 80px;

    &:nth-child(4) {
      display: flex;
    }

    ${({ size }) => {
      switch (size) {
        case 'large':
          return css`
            padding: var(--sizing-md) var(--sizing-md);
          `;
        case 'medium':
          return css`
            padding: var(--sizing-sm) var(--sizing-md);
          `;
        case 'small':
          return css`
            min-height: 0;
            padding: 12px;
          `;
        default:
          return null;
      }
    }}
  }

  .inline-numbers {
    display: flex;
    align-items: end;
    gap: 4px;
  }
`;

export const Dash = styled.span`
  &:after {
    content: '—';
    display: block;
    font-size: 18px;
    color: var(--input-gray);
  }
`;
