import React from 'react';

import { TextField } from 'Containers/App/types';
import { usePremiumContext } from 'Contexts/premiumContext';
import { useTextContext } from 'Contexts/textContext';
import { applyTextReplacements, PREMIUM_TEXT_REPLACEMENT, TextReplacements } from 'Utils/textReplacement';

export interface TextProps {
  field: TextField;
  vars?: Record<string, unknown>;
  textReplacements?: TextReplacements;
}

const Text = ({ field, vars, textReplacements }: TextProps) => {
  const { retrieveContentfulData, locale } = useTextContext();
  const { useContributionTerm } = usePremiumContext();

  // If the customer uses contribution terms, replace "premium" with "contribution" in selected locale
  let allTextReplacements = {
    ...textReplacements,
  };

  if (useContributionTerm) {
    allTextReplacements = {
      ...allTextReplacements,
      ...PREMIUM_TEXT_REPLACEMENT[locale],
    };
  }

  const withKey = (el) => <React.Fragment key={`${Date.now()}${Math.random()}`}>{el}</React.Fragment>;

  // Swap tokens from the `text` with the corresponding variables in `vars`
  const renderTextVars = (text: string) => {
    if (!vars) {
      return text;
    }

    // First we break the text into an array of parts split around each token
    // Ex. "The minimum is {minimum}... up to a maximum of {maximum}."
    // parts = ['The minimum is ', '{minimum}', '... up to a maximum of ', '{maximum}', '.']
    // This allows us to swap a token with anything, a string, number, or another component
    const parts: (string | unknown)[] = text
      .replaceAll('{', '@@{')
      .replaceAll('}', '}@@')
      .split(/[@]{2}/);

    Object.keys(vars).forEach((key) => {
      parts.forEach((token, idx) => {
        // We look for ALL instances of the token and replace them with the corresponding value
        // This allows for reusing a token multiple times within the `text`
        if (token === `{${key}}`) {
          const value = vars[key];
          parts[idx] = typeof value !== 'object' ? value : withKey(value);
        }
      });
    });

    // If the result after replacement doesn't contain any nested objects/components
    // we join the array back together so that it's all one string.
    // If it does contains objects/components they will be handled by React later and must stay as an array
    const hasObjValues = parts.filter((x) => typeof x === 'object').length;
    return !hasObjValues ? parts.join('') : parts;
  };

  let textField = retrieveContentfulData<string>(field);

  if (textField == null) {
    return <span id={field} className="contentful-error" key="contentful-error" />;
    // TODO: Should we log this error? Is a miss ever acceptable?
  }

  textField = applyTextReplacements(textField, allTextReplacements);

  return withKey(renderTextVars(textField));
};

export default Text;
