/**
 * A map where each key is a 'pattern' (string or regex) to search for,
 * and each value is the corresponding 'replacementText' (string) to replace it with.
 * Regular expressions are supported for pattern matching.
 */
export type TextReplacements = Record<string, string>;

export const applyTextReplacements = (originalText: string, textReplacements: TextReplacements): string => {
  let result = originalText;

  Object.entries(textReplacements).forEach(([pattern, replacement]) => {
    const replaceWhileMatchingCasing = (match: string) => {
      if (match === match.toUpperCase()) {
        return replacement.toUpperCase();
      }
      if (match[0] === match[0].toUpperCase()) {
        return replacement[0].toUpperCase() + replacement.slice(1).toLowerCase();
      }
      return replacement.toLowerCase();
    };

    // Wrap pattern in additional regex to ensure pattern is:
    // - Not within curly braces, e.g. {premium}
    // - Not part of a larger word
    const regexPattern = new RegExp(`(?<!{)(?<!w)${pattern}(?!w)(?!})`, 'gi');
    result = result.replace(regexPattern, replaceWhileMatchingCasing);
  });

  return result;
};

export const PREMIUM_TEXT_REPLACEMENT = {
  'en-US': {
    premiums: 'contributions',
    premium: 'contribution',
  },
  'es-US': {
    primas: 'contribuciones',
    prima: 'contribución',
  },
};
