import styled, { css } from 'styled-components';

import { H6Style } from 'DesignLibrary/atoms';
import { breakpoint } from 'DesignLibrary/global';
import { FadeInUpKeyframes, FadeOutDownKeyframes } from 'DesignLibrary/keyframes';

interface MedicareContainerProps {
  isNextClicked: boolean;
}

export const Wrapper = styled.div`
  padding: 0 var(--sizing-xs);
  ${breakpoint.TABLET} {
    padding: 0;
  }
`;

export const MedicareContainer = styled.div<MedicareContainerProps>`
  display: flex;
  flex-direction: column;
  gap: var(--sizing-sm);
  background: var(--colors-alex-blue10);
  border-radius: var(--border-radius-xs);
  margin-bottom: var(--sizing-lg);
  padding: var(--sizing-md);
  opacity: 0;
  animation: ${FadeInUpKeyframes} 1s 2s forwards;

  ${breakpoint.TABLET} {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: var(--sizing-sm);
  }

  .text {
    display: flex;
    flex-direction: column;
    gap: var(--sizing-xs);
    align-items: center;

    ${breakpoint.TABLET} {
      flex-direction: row;
      gap: var(--sizing-sm);
    }

    .paragraph {
      text-align: center;

      ${breakpoint.TABLET} {
        line-height: 1;
      }
    }

    img {
      display: inline-block;
      height: var(--sizing-sm);
      vertical-align: baseline;
    }
  }

  ${({ isNextClicked }) =>
    isNextClicked &&
    css`
      opacity: 1;
      animation-name: ${FadeOutDownKeyframes};
      animation-duration: 1s;
      animation-delay: 0.25s;
    `}
`;

export const NewTag = styled.div`
  ${H6Style()}
  display: inline-block;
  color: var(--primary-white);
  background: var(--colors-alex-blue);
  border-radius: var(--border-radius-xs);
  padding: 2px var(--sizing-xs);
`;
