import styled from 'styled-components';

import { breakpoint } from 'DesignLibrary/global';
import { cardStyling } from 'DesignLibrary/utils';

export const TopContentContainer = styled.div`
  ${breakpoint.TABLET} {
    display: flex;
    align-items: flex-start;
    gap: var(--sizing-lg);
  }
`;

export const TopContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--sizing-md);

  h6 {
    font-weight: 900;
    text-align: center;
  }

  .callout {
    max-width: 960px;
    font-weight: 300 !important;
    text-align: center;
    margin-bottom: var(--sizing-xs);

    b {
      color: var(--primary-blue);
      font-weight: 900;
    }
  }

  ${breakpoint.TABLET} {
    h6 {
      text-align: left;
    }

    .callout {
      text-align: left;
      margin: 0;
    }
  }
`;

export const FsaNumbers = styled.div`
  ${cardStyling}
  overflow: hidden;
  margin-top: var(--sizing-xl);

  .title {
    padding: var(--sizing-xs);
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--primary-blue);

    h6 {
      font-weight: 900 !important;
    }
  }

  .content {
    .item {
      padding: var(--sizing-sm);
      border-top: var(--border);

      &:first-child {
        border: none;
      }

      > :first-child {
        display: block;
        margin-bottom: var(--sizing-xs);
      }
    }
  }

  ${breakpoint.TABLET} {
    width: var(--sizing-4xl);
    flex-shrink: 0;
  }
`;

export const ChartContainer = styled.div`
  margin: var(--sizing-2xl) 0;
  max-width: 480px;
`;

export const FsaNumbersContainer = styled.div`
  ${cardStyling}
  overflow: hidden;

  .top-numbers {
    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: var(--sizing-sm);

      ${breakpoint.TABLET} {
        padding: var(--sizing-md);
      }

      &:first-child {
        border-bottom: var(--border);
      }
    }

    .blue {
      background: var(--primary-blue);

      .number-color {
        p,
        span {
          color: var(--primary-white);
        }
      }
    }
  }
`;

export const BottomContent = styled.div``;

export const Bullets = styled.div`
  & > * + * {
    margin-top: var(--sizing-md);
  }

  ${breakpoint.TABLET} {
    & > * + * {
      margin-top: var(--sizing-lg);
    }
  }
`;
