import styled, { css } from 'styled-components';

import { focus } from '../../../utils';
import { InputTextStyle } from '../../typography';

type TextContainerProps = {
  inputType: 'normal' | 'icon' | 'currency' | 'percent' | 'integer';
  stretch: boolean;
};

type TextInputProps = {
  inputType: 'normal' | 'icon' | 'currency' | 'percent' | 'integer';
  error: boolean;
};

export const TextContainer = styled.div<TextContainerProps>`
  position: relative;
  display: flex;
  width: var(
    ${({ inputType }) =>
      ['normal', 'icon', 'currency'].includes(inputType) ? '--sizing-4xl' : '--sizing-3xl'}
  );
  height: var(--sizing-xl);
  background: none;
  ${({ stretch }) => stretch && 'width: 100%;'}

  span {
    ${InputTextStyle}
    display: inline-flex;
    align-items: center;
    height: 100%;
    padding: 0 var(--sizing-sm);
    color: var(--text-gray);
    cursor: inherit;
    user-select: none;
    pointer-events: none;
    position: absolute;
    top: 0;
    ${({ inputType }) => (inputType === 'currency' ? 'left: 0;' : 'right: 0;')}
  }
`;

export const TextInputStyle = styled.input<TextInputProps>`
  ${InputTextStyle}
  width: 100%;

  ${({ inputType }) =>
    inputType === 'currency' &&
    css`
      width: var(--sizing-4xl);
    `}

  padding: var(--sizing-sm);
  ${({ inputType }) => ['icon', 'percent'].includes(inputType) && 'padding-right: var(--sizing-xl);'}
  ${({ inputType }) => inputType === 'currency' && 'padding-left: 2.625rem;'}
  outline: none;
  background: var(--background-white);
  border: 2px solid var(--input-gray);
  border-radius: var(--border-radius-xs);

  &:hover {
    border-color: var(--text-black);
  }

  &:focus {
    ${focus}
  }

  ${({ error }) =>
    error &&
    css`
      border-color: transparent;
      box-shadow: 0 0 0 2px var(--primary-red);

      &:hover,
      &:focus {
        border-color: var(--primary-red);
      }
    `}

  &::placeholder {
    color: var(--text-gray-placeholder);
  }

  &:disabled {
    color: var(--text-gray-light);
    pointer-events: none;
    box-shadow: none;

    + span {
      color: var(--text-gray-light);
    }
  }
`;
