import styled, { css } from 'styled-components';

import { FadeInUpKeyframes } from 'DesignLibrary/keyframes';

interface ContainerProps {
  isFirstPageView: boolean;
}

export const Container = styled.div<ContainerProps>`
  max-width: 400px;
  min-width: 240px;
  flex-shrink: 0;
  opacity: 0;
  animation: ${FadeInUpKeyframes} 0.5s 2.8s forwards;

  ${({ isFirstPageView }) =>
    !isFirstPageView &&
    css`
      animation-delay: 1s;
    `}

  .link {
    color: var(--text-gray);
    font-size: 13px;
  }
`;
