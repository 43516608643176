import { keyframes } from 'styled-components';

export const FadeInKeyframes = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

export const FadeInLeftKeyframes = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-1rem);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const FadeInRightKeyframes = keyframes`
  0% {
    opacity: 0;
    transform: translateX(1rem);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const FadeInUpKeyframes = keyframes`
  0% {
    opacity: 0;
    transform: translateY(1.5rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const FadeInDownKeyframes = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-1.5rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const FadeOutKeyframes = keyframes`
  0% {
    opacity: 1;
  }
  
  100% {
    opacity: 0;
  }
`;

export const FadeOutUpKeyframes = keyframes`
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  
  100% {
    opacity: 0;
    transform: translateY(-1.5rem);
  }
`;

export const FadeOutDownKeyframes = keyframes`
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  
  100% {
    opacity: 0;
    transform: translateY(1.5rem);
  }
`;

export const FadeOutRightKeyframes = keyframes`
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  
  100% {
    opacity: 0;
    transform: translateX(1rem);
  }
`;

export const SpinKeyframes = keyframes`
0% {
  transform: rotate(0);
}
100% {
  transform: rotate(360deg);
}
`;
