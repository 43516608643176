export const GET_HSA_RECOMMENDATION_REQUEST = 'app/CommercialV2/HsaPage/GET_HSA_RECOMMENDATION_REQUEST';
export const GET_HSA_RECOMMENDATION_SUCCESS = 'app/CommercialV2/HsaPage/GET_HSA_RECOMMENDATION_SUCCESS';
export const GET_HSA_RECOMMENDATION_FAILURE = 'app/CommercialV2/HsaPage/GET_HSA_RECOMMENDATION_FAILURE';

export const FORECAST_HSA_SUCCESS = 'app/CommercialV2/HsaPage/FORECAST_HSA_SUCCESS';
export const FORECAST_HSA_FAILURE = 'app/CommercialV2/HsaPage/FORECAST_HSA_FAILURE';

export const LOCK_HSA_CONTRIBUTION = 'app/CommercialV2/HsaPage/LOCK_HSA_CONTRIBUTION';
export const LOCK_FSA_CONTRIBUTION = 'app/CommercialV2/TaxSavingsPage/LOCK_FSA_CONTRIBUTION';
export const SET_HSA_BALANCE_AND_PERSONA = 'app/CommercialV2/HsaPage/SET_HSA_BALANCE_AND_PERSONA';

export const GET_FSA_TAX_SAVINGS_REQUEST = 'app/CommercialV2/TaxSavingsPage/GET_FSA_TAX_SAVINGS_REQUEST';
export const GET_FSA_TAX_SAVINGS_SUCCESS = 'app/CommercialV2/TaxSavingsPage/GET_FSA_TAX_SAVINGS_SUCCESS';

export const POST_CONTRIBUTIONS_SUCCESS = 'app/CommercialV2/TaxSavingsPage/POST_CONTRIBUTIONS_SUCCESS';
export const POST_CONTRIBUTIONS_FAILURE = 'app/CommercialV2/TaxSavingsPage/POST_CONTRIBUTIONS_FAILURE';

// Forecasting constants
export const INVESTMENT_RETURN_PERCENT = 6;
export const RETIREMENT_AGE = 65;
export const MAX_FSA_CONTRIBUTION = 3200;
