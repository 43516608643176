import styled from 'styled-components';

import { breakpoint } from 'DesignLibrary/global';
import { hideAccessibleCheckmark, focusSingle } from 'DesignLibrary/utils';

export const ProductSelectContainer = styled.div``;

export const ToggleContainer = styled.fieldset`
  border: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--sizing-xs);
  padding: 0;
  margin: 0;

  ${breakpoint.TABLET} {
    flex-direction: row;
    align-items: stretch;
    gap: var(--sizing-sm);
  }

  input[type='radio'] {
    ${hideAccessibleCheckmark}

    &:focus + .pseudo-answer {
      ${focusSingle}
    }
    &:focus:not(:focus-visible) + .pseudo-answer {
      outline: 0;
      box-shadow: none;
    }
    &:focus-visible + .pseudo-answer {
      ${focusSingle}
    }
  }
`;

export const SpanishDisclaimer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--sizing-xs);
  padding: var(--sizing-xs);
  background: var(--colors-red-50);
  border-radius: var(--border-radius-xs);
`;

export const ContinueContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: var(--sizing-sm);

  .product-select-continue-button {
    min-width: var(--sizing-3xl);
  }

  ${breakpoint.TABLET} {
    margin-top: var(--sizing-md);
  }
`;
