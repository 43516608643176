import styled from 'styled-components';

import { ParagraphMiniStyle } from 'DesignLibrary/atoms';

export const Subtitle = styled.span`
  ${ParagraphMiniStyle('--text-gray')}
  display: flex;
  gap: var(--sizing-sm);
  padding: 12px var(--sizing-sm);
  margin-top: var(--sizing-2xs);
  max-width: 600px;
  background: var(--colors-opaque-5);
  border-radius: var(--border-radius-xs);

  & :first-child {
    position: relative;
    top: 1px;
  }
`;
