import React from 'react';

export const iconSVGs = {
  office_visits: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 50">
      <path d="M20.93 50c4.846 0 8.789-4.04 8.789-8.887v-.148c3.339-.68 5.86-3.64 5.86-7.176 0-4.038-3.287-7.324-7.325-7.324-4.039 0-7.324 3.286-7.324 7.324 0 3.537 2.52 6.495 5.86 7.176v.148c0 3.232-2.629 5.957-5.86 5.957-3.232 0-5.86-2.725-5.86-5.957V26.317c6.574-.74 11.72-6.268 11.72-13.036V4.395A4.4 4.4 0 0022.394 0H18v2.93h4.395c.808 0 1.464.656 1.464 1.465v8.886c0 5.654-4.6 10.254-10.254 10.254-5.653 0-10.253-4.6-10.253-10.254V4.395c0-.809.656-1.465 1.464-1.465h4.395V0H4.816A4.4 4.4 0 00.422 4.395v8.886c0 6.768 5.145 12.296 11.719 13.036v14.796C12.14 45.96 16.083 50 20.93 50zm5.86-17.676h2.929v2.93h-2.93v-2.93z" />
    </svg>
  ),
  labs_diagnostics: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
      <path d="M41.636 29.006v-8.232h.954a1.467 1.467 0 000-2.934H30.547a1.467 1.467 0 000 2.934h1.146v8.232a12.21 12.21 0 00-7.232 11.148c0 3.721 1.667 7.192 4.573 9.523.26.21.584.323.918.323h13.425c.334 0 .658-.114.918-.322a12.153 12.153 0 004.573-9.524c0-4.847-2.866-9.202-7.233-11.148zm-7.998 2.383c.592-.204.988-.76.988-1.386v-9.23h4.076v9.23c0 .625.397 1.182.988 1.386a9.27 9.27 0 015.56 5.27H28.078a9.27 9.27 0 015.56-5.27zm9.203 15.678H30.487a9.227 9.227 0 01-3.075-7.474h18.503a9.228 9.228 0 01-3.075 7.474zM30.875 10.591l.808.808a1.463 1.463 0 002.075 0 1.467 1.467 0 000-2.074L24.861.43a1.467 1.467 0 10-2.074 2.074l.808.808-5.402 5.402L2.968 15.19a3.012 3.012 0 00-1.78 2.201 3.012 3.012 0 00.828 2.707l12.187 12.188a3.01 3.01 0 002.721.825 3.01 3.01 0 002.198-1.803l6.356-15.32 5.397-5.398zm-11.27.71c.172-.073.33-.179.462-.312l5.603-5.602 3.13 3.13-5.172 5.173h-9.64l5.616-2.388zm-3.192 18.885c-.007.016-.017.04-.06.05-.045.008-.064-.012-.076-.024L4.09 18.026c-.011-.011-.031-.031-.023-.074.009-.044.034-.055.05-.061l2.976-1.266H22.04l-5.626 13.562zM37.337 8.157a1.467 1.467 0 100-2.933 1.467 1.467 0 000 2.933zM39.517 14.471a1.467 1.467 0 100-2.933 1.467 1.467 0 000 2.933z" />
      <defs>
        <clipPath id="clip0">
          <path d="M0 0h50v50H0z" />
        </clipPath>
      </defs>
    </svg>
  ),
  emergency_urgent_care: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38 50">
      <path d="M32.281 39.284V25c0-7.323-5.958-13.281-13.281-13.281S5.719 17.677 5.719 25v14.284a4.646 4.646 0 00-3.223 4.417V50h33.008v-6.299a4.647 4.647 0 00-3.223-4.417zM8.648 35.84h20.704v3.222H8.648V35.84zM19 14.648c5.708 0 10.352 4.644 10.352 10.352v7.91H8.648V25c0-5.708 4.644-10.352 10.352-10.352zM32.574 47.07H5.426v-3.369c0-.942.766-1.709 1.709-1.709h23.73c.943 0 1.71.767 1.71 1.71v3.368zM17.535 0h2.93v7.034h-2.93V0zM24.676 7.583l2.617-6.528 2.719 1.09-2.617 6.527-2.72-1.09zM30.819 10.988l4.741-5.199 2.165 1.975-4.741 5.198-2.165-1.974zM7.991 2.127l2.719-1.09 2.616 6.528-2.718 1.09L7.99 2.127zM.287 7.763l2.165-1.975 4.742 5.198-2.166 1.975-4.74-5.198z" />
    </svg>
  ),
  inpatient: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
      <path d="M42.678 7.322A24.837 24.837 0 0025 0C18.322 0 12.044 2.6 7.322 7.322 2.6 12.044 0 18.322 0 25c0 6.678 2.6 12.956 7.322 17.678C12.044 47.4 18.322 50 25 50c6.678 0 12.956-2.6 17.678-7.322A24.837 24.837 0 0050 25c0-6.678-2.6-12.956-7.322-17.678zm-2.072 33.284A21.926 21.926 0 0125 47.07a21.926 21.926 0 01-15.606-6.464A21.926 21.926 0 012.93 25 21.926 21.926 0 019.394 9.394 21.926 21.926 0 0125 2.93a21.926 21.926 0 0115.606 6.464A21.926 21.926 0 0147.07 25a21.926 21.926 0 01-6.464 15.606z" />
      <path d="M31.178 18.822v-8.624H18.822v8.624h-8.624v12.356h8.624v8.624h12.356v-8.624h8.624V18.822h-8.624zm5.695 9.426h-8.625v8.625h-6.496v-8.625h-8.625v-6.496h8.625v-8.625h6.496v8.625h8.625v6.496z" />
    </svg>
  ),
};
