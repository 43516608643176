import { fromJS } from 'immutable';
import { isEmpty } from 'lodash';
import { createSelector } from 'reselect';

import { GlobalReducerState } from 'app/reducers';
import {
  COUPLE_TIER,
  FAMILY_TIER,
  INDIVIDUAL_DEPENDANT_TIER,
  INDIVIDUAL_TIER,
  LOW_POP_ZIP3,
} from 'Containers/App/constants';
import { makeSelectCommercialField } from 'Containers/App/selectors';
import { makeGetRecommendedHsaContribution } from 'Containers/CommercialRoutes/selectors';
import {
  makeGetCoverageTier,
  makeGetHouseholdMembers,
  makeSelectPolicyholder,
  makeSelectProfileField,
} from 'Containers/ProfilePage/selectors';
import { HouseholdMember, Recommendation } from 'Types/entities';

import {
  FEMALE_LIFE_EXPECTANCY,
  INVESTMENT_RETURN_PERCENT,
  MALE_LIFE_EXPECTANCY,
  MIN_DESIRED_BALANCE,
  RETIREMENT_AGE,
} from './constants';
import {
  mapCommApiTierToForecastingTier,
  formatEmployerContribution,
  formatMembersForForecastApi,
  formatPlanParameters,
} from './helpers';
import { HsaPageReducerState, HsaPageState } from './types';

const selectHsaDomain = (state: GlobalReducerState): HsaPageState => fromJS(state).get('hsaPage');

const selectHsaDomainAsJS = (state: GlobalReducerState): HsaPageReducerState => state.hsaPage;

const makeSelectHsaField = <K extends keyof HsaPageReducerState>(field: K) =>
  createSelector(
    selectHsaDomainAsJS,
    (substate: HsaPageReducerState): HsaPageReducerState[K] => substate[field],
  );

const makeSelectHsaData = () => createSelector(selectHsaDomain, (substate) => substate.get('hsaData').toJS());

const makeGetForecastPayload = () =>
  createSelector(
    [
      makeGetHouseholdMembers(),
      makeSelectCommercialField('selectedHealthPlan'),
      makeSelectProfileField('zipcode'),
      makeSelectHsaField('currentHsaBalance'),
      makeSelectHsaField('persona'),
      makeSelectHsaField('lockedHsaContribution'),
      makeGetRecommendedHsaContribution(),
      // this coverage tier needs to be constructed from the same members as
      // the selected health plan is recommended for
      makeGetCoverageTier(),
    ],
    (
      members,
      selectedPlan,
      zipcode,
      currentHsaBalance,
      persona,
      employeeHsaContribution,
      recommendedContribution,
      coverageTier,
    ) => {
      const zip3 = LOW_POP_ZIP3.includes(zipcode.substring(0, 3)) ? '000' : zipcode.substring(0, 3);
      const contribution = employeeHsaContribution || recommendedContribution;

      const currentYear = new Date().getFullYear();

      const policyholder = members.find((member) => member.policyholder) as HouseholdMember;
      const policyholderRetireStartYear = Math.max(
        RETIREMENT_AGE - policyholder.age + currentYear,
        currentYear,
      );

      return {
        household: {
          members: formatMembersForForecastApi(members),
          zip_code_3: zip3,
        },
        hsas: [
          {
            current_balance: currentHsaBalance || 0,
            employee_contribution: contribution,
            employer_contribution: formatEmployerContribution(selectedPlan as Recommendation),
            investment_return_percent: INVESTMENT_RETURN_PERCENT,
            minimum_desired_balance: persona === 'spender' ? 0 : 'inf',
          },
        ],
        plans: [
          {
            external_id: selectedPlan.plan.external_id,
            is_hsa_eligible: selectedPlan.plan.hsa_eligible,
            plan_parameters: {
              [mapCommApiTierToForecastingTier(coverageTier)]: formatPlanParameters(
                selectedPlan as Recommendation,
                coverageTier,
              ),
            },
            premiums: {
              [mapCommApiTierToForecastingTier(INDIVIDUAL_TIER)]: selectedPlan.costs.effective_premium,
              [mapCommApiTierToForecastingTier(COUPLE_TIER)]: selectedPlan.costs.effective_premium,
              [mapCommApiTierToForecastingTier(INDIVIDUAL_DEPENDANT_TIER)]:
                selectedPlan.costs.effective_premium,
              [mapCommApiTierToForecastingTier(FAMILY_TIER)]: selectedPlan.costs.effective_premium,
            },
          },
        ],
        scenario: {
          end_year: policyholderRetireStartYear,
          start_year: currentYear,
          starting_plans: {
            [`${selectedPlan.plan.external_id}`]: members.map((member) => member.external_id),
          },
        },
      };
    },
  );

/*
Makes a forecast for costs beginning in the expected year of retirement
Assumes the user will enroll in a medicare plan at retirement
Jake, as of 8/4/2022 is not sure why this has to be a separate request
from the HSA Forecast request we make on slider change - why can we not get
expected costs in retirement as well as expected HSA balance from one request
to the /forecast endpoint. This might be obvious to anyone who digs into it,
idk, I haven't. It may be a performance optimization
https://picwell.slack.com/archives/C0K5Z38TX/p1659638724467479?thread_ts=1659638483.707979&cid=C0K5Z38TX
*/
const makeGetRetirementForecastPayload = (policyholderOrSpouse = 'policyholder') =>
  createSelector([makeGetHouseholdMembers(), makeSelectProfileField('zipcode')], (members, zipcode) => {
    const zip3 = LOW_POP_ZIP3.includes(zipcode.substring(0, 3)) ? '000' : zipcode.substring(0, 3);
    const currentYear = new Date().getFullYear();

    const user = (
      policyholderOrSpouse === 'policyholder'
        ? members.find((member) => member.policyholder)
        : members.find((member) => !member.policyholder && !member.dependant)
    ) as HouseholdMember;

    const userLifeExpectancy = user.gender === 'male' ? MALE_LIFE_EXPECTANCY : FEMALE_LIFE_EXPECTANCY;

    const userRetireStartYear = Math.max(RETIREMENT_AGE - user.age + currentYear, currentYear);
    const userRetireEndYear = Math.max(userLifeExpectancy - user.age + currentYear, currentYear);

    return {
      household: {
        members: formatMembersForForecastApi([user]),
        zip_code_3: zip3,
      },
      hsas: [
        {
          current_balance: 0,
          employee_contribution: 0,
          employer_contribution: null,
          investment_return_percent: INVESTMENT_RETURN_PERCENT,
          minimum_desired_balance: MIN_DESIRED_BALANCE,
        },
      ],
      plans: [
        { medicare_id: 'traditional+pdp_medium' }, // switch to traditional+pdp_med plan after retirement
      ],
      scenario: {
        end_year: userRetireEndYear,
        start_year: userRetireStartYear,
        starting_plans: { 'traditional+pdp_medium': [user.external_id] },
        life_events: [
          {
            event_type: 'assign_members',
            external_member_ids: [user.external_id],
            external_plan_id: 'traditional+pdp_medium',
            year: userRetireStartYear,
          },
        ],
      },
    };
  });

const makeGetAvgRetirementCost = () =>
  createSelector([makeSelectHsaField('retirementForecastData')], (data) => {
    if (data.length > 0) {
      const yearlyForecasts = data.map((resp) => resp.yearly_forecasts).flat();

      const avgRetirementCost = yearlyForecasts.reduce(
        (total, current) => current.total_cost.mean + total,
        0,
      );

      return avgRetirementCost;
    }

    return 0;
  });

const makeGetAvgForecastedHsaBalance = () =>
  createSelector([makeSelectPolicyholder(), makeSelectHsaField('forecastData')], (policyholder, data) => {
    if (!isEmpty(data)) {
      const currentYear = new Date().getFullYear();
      const yearlyForecasts = data.yearly_forecasts;

      const retireStartYear = Math.max(
        RETIREMENT_AGE - parseInt(policyholder.age, 10) + currentYear,
        currentYear,
      );

      // Find forecast at retirement
      const forecastAtRetirement = yearlyForecasts.find((forecast) => forecast.year === retireStartYear);

      if (forecastAtRetirement) return forecastAtRetirement.hsa.balance.mean;
    }

    return 0;
  });

const makeGetIsPolicyholderOverRetirementAge = () =>
  createSelector([makeGetHouseholdMembers()], (members) => {
    const policyholder = members.find((member) => member.policyholder) as HouseholdMember;
    const currentYear = new Date().getFullYear();

    const policyholderRetireStartYear = Math.max(
      RETIREMENT_AGE - policyholder.age + currentYear,
      currentYear,
    );

    return policyholderRetireStartYear === currentYear;
  });

const makeGetPolicyholderLifeExpectancy = () =>
  createSelector([makeGetHouseholdMembers()], (members) => {
    const policyholder = members.find((member) => member.policyholder) as HouseholdMember;
    const userLifeExpectancy = policyholder.gender === 'male' ? MALE_LIFE_EXPECTANCY : FEMALE_LIFE_EXPECTANCY;
    return userLifeExpectancy;
  });

export {
  selectHsaDomain,
  makeSelectHsaField,
  makeSelectHsaData,
  makeGetForecastPayload,
  makeGetRetirementForecastPayload,
  makeGetAvgRetirementCost,
  makeGetAvgForecastedHsaBalance,
  makeGetIsPolicyholderOverRetirementAge,
  makeGetPolicyholderLifeExpectancy,
};
