import { Stack } from '@chakra-ui/react';
import React from 'react';

import { TextField } from 'Containers/App/types';
import { Button, H4, Icon, IconTypes } from 'DesignLibrary/atoms';
import { useViewport } from 'DesignLibrary/context/viewportContext';
import Text from 'Shared/Text';

import { CtaCard, IconBlock } from './styled';

export interface CtaCardProps {
  isVisible: boolean;
  iconType?: IconTypes;
  headerTextField: TextField;
  buttonTextField: TextField;
  buttonUrl: string;
  type: 'pdf' | 'provider';
}

export interface CtaCardsProps {
  left: CtaCardProps;
  right: CtaCardProps;
}

const SingleCtaCard = ({
  isVisible,
  iconType,
  headerTextField,
  buttonTextField,
  buttonUrl,
  type,
}: CtaCardProps) => {
  const { device } = useViewport();

  return isVisible ? (
    <CtaCard data-testid={`cta-card-${type}`}>
      <Stack p={[4, 6, 8]} gap={[3, 4]} justify="space-between" h="100%">
        <Stack alignItems="center" gap={[2, 3]}>
          <IconBlock aria-hidden>
            <Icon type={iconType} color="--primary-blue" size="large" />
          </IconBlock>
          <H4 as="h3" weight="bold">
            <Text field={headerTextField} />
          </H4>
        </Stack>
        <Button
          buttonType="secondary"
          isDisabled={buttonUrl === ''}
          url={buttonUrl}
          iconRight="ArrowUpRight"
          size={device === 'mobile' ? 'small' : 'normal'}
          stretch
        >
          <Text field={buttonTextField} />
        </Button>
      </Stack>
    </CtaCard>
  ) : null;
};

export const CtaCards = ({ left, right }: CtaCardsProps) => (
  <>
    <SingleCtaCard
      isVisible={left.isVisible}
      iconType="Scroll"
      headerTextField={left.headerTextField}
      buttonTextField={left.buttonTextField}
      buttonUrl={left.buttonUrl}
      type="pdf"
    />
    <SingleCtaCard
      isVisible={right.isVisible}
      iconType="Stethoscope"
      headerTextField={right.headerTextField}
      buttonTextField={right.buttonTextField}
      buttonUrl={right.buttonUrl}
      type="provider"
    />
  </>
);
