import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Stack,
} from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';

import { IncentiveQuestion } from 'Containers/ProfilePage/IncentiveSection/IncentiveQuestion';
import { IncentiveQuestionWrapper } from 'Containers/ProfilePage/IncentiveSection/IncentiveQuestion/styled';
import {
  IncentiveSurveyAnswer,
  IncentiveSurveyQuestion,
  IncentiveSurveyQuestionType,
  IncentiveType,
} from 'Containers/ProfilePage/types';
import { useProfileContext } from 'Contexts/profileContext';
import { useTextContext } from 'Contexts/textContext';
import { Button, H5 } from 'DesignLibrary/atoms';
import ToggleList from 'DesignLibrary/atoms/ToggleList';
import InfoBar from 'DesignLibrary/molecules/InfoBar';
import Text from 'Shared/Text';

export interface MemberSelectionModalProps {
  isOpen: boolean;
  canBearChild: boolean;
  didSpouseChange: boolean;
  incentiveSurvey: IncentiveSurveyQuestion[];
  hasSpouse: boolean;
  isLoading: boolean;
  isIncentiveSurveyLoading: boolean;
  incentiveSurveyAnswers: Record<string, IncentiveSurveyAnswer | null>;
  pregnancyAnswer: string;
  handleModalSubmit: () => void;
  setPregnancyAnswer: (val: string) => void;
  createUpdateHouseholdAndIncentiveSurvey: () => void;
  submitIncentiveSurveyResponses: (
    incentiveSurveyResonses: Record<string, IncentiveSurveyAnswer | null>,
  ) => void;
  closeModal: () => void;
  handleSectionChange: (key: 'incentives' | 'pregnancy', isValid: boolean, isComplete: boolean) => void;
}

export const MemberSelectionModal = ({
  isOpen,
  canBearChild,
  didSpouseChange,
  hasSpouse,
  incentiveSurvey,
  isLoading,
  isIncentiveSurveyLoading,
  incentiveSurveyAnswers,
  pregnancyAnswer,
  handleModalSubmit,
  setPregnancyAnswer,
  createUpdateHouseholdAndIncentiveSurvey,
  submitIncentiveSurveyResponses,
  closeModal,
  handleSectionChange,
}: MemberSelectionModalProps) => {
  const { retrieveContentfulData } = useTextContext();
  const { getSectionFromMap } = useProfileContext();
  const [showIncentivesQuestions, setShowIncentivesQuestions] = useState(false);
  const [showPregnancyQuestion, setShowPregnancyQuestion] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setShowIncentivesQuestions(didSpouseChange);
      setShowPregnancyQuestion(canBearChild);
      if (didSpouseChange) {
        createUpdateHouseholdAndIncentiveSurvey();
      }
    }
  }, [isOpen]);

  function validateForm() {
    const isFormValid = Object.values(incentiveSurveyAnswers).every(
      (i) => i !== null && i.expected_incentive_amount !== '',
    );
    return isFormValid;
  }

  useEffect(() => {
    if (!isEmpty(incentiveSurveyAnswers)) {
      const section = getSectionFromMap('incentives');
      const isComplete = section?.isComplete || validateForm();
      handleSectionChange('incentives', validateForm(), isComplete);
    }
  }, [JSON.stringify(incentiveSurveyAnswers)]);

  const handleChangeIncentive = (
    id: number,
    type: IncentiveSurveyQuestionType,
    incentiveType: IncentiveType,
    val: number | string | null,
  ) => {
    if (type === 'fill_in_the_blank') {
      submitIncentiveSurveyResponses({
        ...incentiveSurveyAnswers,
        [id]: {
          jv_incentive_id: id,
          expected_incentive_amount: val,
          incentive_type: incentiveType,
          choice: null,
        },
      });
    } else {
      submitIncentiveSurveyResponses({
        ...incentiveSurveyAnswers,
        [id]: {
          jv_incentive_id: id,
          choice: val === 'null' ? null : val,
          incentive_type: incentiveType,
          expected_incentive_amount: null,
        },
      });
    }
  };

  const renderPregnancyQuestion = () => {
    if (!showPregnancyQuestion) {
      return null;
    }

    return (
      <IncentiveQuestionWrapper>
        <div className="description">
          {/* TODO: CONTENTFUL Add Field */}
          <H5>Is your covered family planning for a pregnancy in the upcoming year?</H5>
        </div>
        <div className="question">
          {/* TODO: CONTENTFUL Add Field */}
          <ToggleList
            layout="column"
            groupName="pregnancy"
            label="pregnancy"
            options={[
              { option: 'Yes', value: 'yes' },
              { option: 'No', value: 'no' },
            ]}
            required
            color="--colors-gray-black"
            handleChange={(e) => setPregnancyAnswer(e.target.value)}
            selected={pregnancyAnswer}
            stretch
          />
        </div>
      </IncentiveQuestionWrapper>
    );
  };

  const renderIncentiveQuestions = () => {
    if (!showIncentivesQuestions) {
      return null;
    }

    return (
      <>
        {incentiveSurvey.map((i) => (
          <IncentiveQuestion
            key={i.id}
            surveyQuestion={i}
            answer={incentiveSurveyAnswers[i.id]}
            hasSpouse={hasSpouse}
            handleChange={handleChangeIncentive}
          />
        ))}
      </>
    );
  };

  const renderQuestions = () => {
    if (isIncentiveSurveyLoading || isLoading) {
      return (
        <Stack>
          <Skeleton height={16} speed={2} />
          <Skeleton height={16} speed={1.5} />
          <Skeleton height={16} speed={1.75} />
        </Stack>
      );
    }

    const incentivesPrompt = retrieveContentfulData<string>(
      'health_section.secondary_content.member_selection_modal_incentives_prompt',
    );

    return (
      <Stack>
        <InfoBar color="yellow" icon="WarningCircle" text={incentivesPrompt} />
        {renderPregnancyQuestion()}
        {renderIncentiveQuestions()}
      </Stack>
    );
  };

  const updateProfileQuestionsHeader = retrieveContentfulData<string>(
    'health_section.secondary_content.member_selection_modal_header',
  );

  return (
    <Modal isOpen={isOpen} onClose={closeModal} scrollBehavior="inside" size="2xl">
      <ModalOverlay bg="var(--colors-opaque-80)" />
      <ModalContent borderRadius="var(--border-radius-xs)">
        <ModalHeader pt={6}>
          <H5>{updateProfileQuestionsHeader}</H5>
        </ModalHeader>
        <ModalBody>{renderQuestions()}</ModalBody>

        <ModalFooter className="save-btn">
          <Flex justify="flex-end" w="100%">
            <Box maxW={64} w="100%">
              <Button
                data-testid="member-selection-submit-btn"
                buttonType="primary"
                stretch
                size="small"
                isDisabled={!validateForm() || isIncentiveSurveyLoading || isLoading}
                onClick={handleModalSubmit}
              >
                <Text field="health_section.secondary_content.member_selection_pregnancy_save" />
              </Button>
            </Box>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
