import styled from 'styled-components';

import { media } from 'DesignLibrary/global';

export const UtilContainer = styled.section`
  & > * + * {
    margin-top: var(--sizing-sm);
  }
`;

export const InputSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--sizing-xs);

  ${media.TABLET`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  `}
`;
