import { GetForecastsResponse, GetHsaRecommendationResponse } from 'Utils/apiTypes';
import * as API_TYPES from 'Utils/apiTypes';

import {
  FORECAST_HSA_FAILURE,
  FORECAST_HSA_SUCCESS,
  GET_FSA_TAX_SAVINGS_REQUEST,
  GET_FSA_TAX_SAVINGS_SUCCESS,
  GET_HSA_RECOMMENDATION_FAILURE,
  GET_HSA_RECOMMENDATION_REQUEST,
  GET_HSA_RECOMMENDATION_SUCCESS,
  LOCK_FSA_CONTRIBUTION,
  LOCK_HSA_CONTRIBUTION,
  POST_CONTRIBUTIONS_FAILURE,
  POST_CONTRIBUTIONS_SUCCESS,
  SET_HSA_BALANCE_AND_PERSONA,
} from './constants';
import { Persona } from './types';

export function setLockedHsaContribution(contribution: number) {
  return {
    type: LOCK_HSA_CONTRIBUTION,
    contribution,
  };
}

export function setLockedFsaContribution(contribution: number) {
  return {
    type: LOCK_FSA_CONTRIBUTION,
    contribution,
  };
}

export function postContributionsSuccess(response: API_TYPES.PostContributionsResponse) {
  return {
    type: POST_CONTRIBUTIONS_SUCCESS,
    response,
  };
}
export function postContributionsFailure(error: Error) {
  return {
    type: POST_CONTRIBUTIONS_FAILURE,
    error,
  };
}

export function getHsaRecommendation() {
  return { type: GET_HSA_RECOMMENDATION_REQUEST };
}

export function getHsaRecommendationSuccess(response: GetHsaRecommendationResponse) {
  return {
    type: GET_HSA_RECOMMENDATION_SUCCESS,
    response,
  };
}

export function getHsaRecommendationFailure(error: Error) {
  return {
    type: GET_HSA_RECOMMENDATION_FAILURE,
    error,
  };
}

export function forecastHsaSuccess(response: GetForecastsResponse) {
  return {
    type: FORECAST_HSA_SUCCESS,
    response,
  };
}

export function forecastHsaFailure(error: Error) {
  return {
    type: FORECAST_HSA_FAILURE,
    error,
  };
}

export function setHsaForecastInputs(balance: number, persona: Persona, retirementAge: number) {
  return {
    type: SET_HSA_BALANCE_AND_PERSONA,
    balance,
    persona,
    retirementAge,
  };
}

export function getFsaTaxSavings() {
  return { type: GET_FSA_TAX_SAVINGS_REQUEST };
}

export function getFsaTaxSavingsSuccess(response: any) {
  return { type: GET_FSA_TAX_SAVINGS_SUCCESS, response };
}
