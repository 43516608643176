import React, { FC } from 'react';

import { Icon, IconTypes } from 'DesignLibrary/atoms/Icon';
import { PrimaryColors } from 'DesignLibrary/vars';

import { IconBlockStyle } from './styled';

export interface IconBlockProps {
  icon: IconTypes;
  color?: PrimaryColors;
  shade?: number;
  smallMobile?: boolean;
}

export const IconBlock: FC<IconBlockProps> = ({
  icon = 'Search',
  color = 'green',
  shade = 50,
  smallMobile = false,
}): JSX.Element => (
  <IconBlockStyle color={color} shade={shade} aria-hidden smallMobile={smallMobile}>
    <Icon type={icon} color={`--colors-${color}-600`} size="normal" />
  </IconBlockStyle>
);
