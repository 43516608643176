import { Flex, Stack } from '@chakra-ui/react';
import React from 'react';

import { ProviderCard } from 'Containers/ResultPage/ProviderSelectionContent/ProviderCard';
import { ProviderCardContainer } from 'Containers/ResultPage/ProviderSelectionContent/styled';
import { useProviderSelectionContext } from 'Contexts/providerSelectionContext';
import { useTextContext } from 'Contexts/textContext';
import { Button, H5, Tooltip, TooltipContentProps } from 'DesignLibrary/atoms';
import Text from 'Shared/Text';
import { sendInsightsEvent } from 'Utils/insights';

export const ProviderSelectionContent = () => {
  const { retrieveContentfulData } = useTextContext();
  const { providerDetails, onOpenProviderModal } = useProviderSelectionContext();
  const providerHeaderTooltip = retrieveContentfulData<TooltipContentProps>(
    'health_section.secondary_content.tooltips.providerSelectionHeader',
  );

  return (
    <ProviderCardContainer>
      <Stack p={6} gap={4}>
        <Flex align="center" justify="space-between">
          <H5 weight="normal" data-testid="selection-header">
            <Text field="health_section.secondary_content.provider_selection_header" />
          </H5>
          <Tooltip id="member-select-tooltip" label={providerHeaderTooltip} data-testid="selection-tooltip" />
        </Flex>

        <Stack className="cta">
          {providerDetails?.length > 0 ? (
            providerDetails?.map((prov) => (
              <ProviderCard
                key={prov.npi}
                title={[prov.first_name, prov.middle_name, prov.last_name].filter(Boolean).join(' ')}
                subtitle={prov.provider_types?.length > 0 ? prov.provider_types[0] : undefined}
                testId="doctor-name"
              />
            ))
          ) : (
            <ProviderCard isNoProviders />
          )}
        </Stack>

        <div className="submit">
          <Button
            data-testid="provider-selection-button"
            size="small"
            stretch
            onClick={() => {
              onOpenProviderModal();
              sendInsightsEvent(null, 'open_provider_search_modal');
            }}
          >
            <Text
              field={`health_section.secondary_content.${providerDetails?.length > 0 ? 'provider_selection_update_button' : 'provider_selection_add_button'}`}
            />
          </Button>
        </div>
      </Stack>
    </ProviderCardContainer>
  );
};
