import React from 'react';

import { ExplainerContainer } from 'Containers/ProfilePage/EnrollmentSection/styled';
import Text from 'Shared/Text';

import { DEPLOY_ENVIRONMENTS } from '../../../constants';

export interface EnrollmentReasonExplainerProps {
  deployEnvironment: DEPLOY_ENVIRONMENTS;
  hasUpcomingPublication: boolean;
  hasActivePublication: boolean;
  isDuringOE: boolean;
}

export const EnrollmentReasonExplainer = (props: EnrollmentReasonExplainerProps) => {
  const { deployEnvironment, hasUpcomingPublication, hasActivePublication, isDuringOE } = props;

  if (deployEnvironment !== DEPLOY_ENVIRONMENTS.UAT) {
    return null;
  }

  if (hasActivePublication && !hasUpcomingPublication) {
    return (
      <ExplainerContainer data-testid="choose-any-explainer">
        <Text field="profile_section.enrollment.enrollment_reason_explainer_choose_any" />
      </ExplainerContainer>
    );
  }

  const upcomingField = isDuringOE
    ? 'profile_section.enrollment.enrollment_reason_explainer_open_enrollment'
    : 'profile_section.enrollment.enrollment_reason_explainer_upcoming';

  return (
    <ExplainerContainer data-testid="enrollment-reason-explainer">
      <ul>
        {hasUpcomingPublication && (
          <li data-testid="upcoming-explainer">
            <Text field={upcomingField} />
          </li>
        )}
        {hasActivePublication && (
          <li data-testid="active-explainer">
            <Text field="profile_section.enrollment.enrollment_reason_explainer_active" />
          </li>
        )}
      </ul>
    </ExplainerContainer>
  );
};
