import { Modal, ModalContent, ModalOverlay } from '@chakra-ui/react';
import React from 'react';

import AlexLogo from 'Assets/alex-logo.svg';
import LanguageToggle from 'Containers/App/Navigation/LanguageToggle';
import { AlexProducts } from 'Containers/App/types';
import { Button, H4 } from 'DesignLibrary/atoms';
import { useViewport } from 'DesignLibrary/context';
import Text from 'Shared/Text';

import { Card, Header } from './styled';
import { ProductSelectToggle } from '../_shared/ProductSelectToggle';

export interface ProductSelectModalProps {
  isOpen: boolean;
  onClose: () => void;
  handleNext: (path: string) => void;
  chooseProduct: (product: AlexProducts) => void;
  showLanguageToggle: boolean;
  modalTriggerButtonRef: any;
  modalAccessibilityProps: any;
}

export const ProductSelectModal = ({
  isOpen,
  onClose,
  handleNext,
  chooseProduct,
  showLanguageToggle,
  modalTriggerButtonRef = null,
  modalAccessibilityProps = {},
}: ProductSelectModalProps) => {
  const { device } = useViewport();
  return (
    <Modal
      finalFocusRef={modalTriggerButtonRef}
      isOpen={isOpen}
      onClose={onClose}
      isCentered={device !== 'mobile'}
      motionPreset="scale"
      {...modalAccessibilityProps}
    >
      <ModalOverlay />
      <ModalContent mt={4} maxW="calc(100vw - 32px)" w="720px">
        <Card>
          <Header>
            <div className="actions">
              {showLanguageToggle && <LanguageToggle />}
              <Button size="small" iconLeft="X" onClick={onClose} />
            </div>
            <H4 className="title" weight="normal">
              <Text
                field="unified_landing_page.choose_experience_modal.title"
                vars={{
                  x: (
                    <span key="logo">
                      <img src={AlexLogo} alt="Alex" />
                    </span>
                  ),
                }}
              />
            </H4>
          </Header>
          <ProductSelectToggle handleNext={handleNext} chooseProduct={chooseProduct} />
        </Card>
      </ModalContent>
    </Modal>
  );
};
