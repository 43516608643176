import styled, { css } from 'styled-components';

type RadioButtonProps = {
  sizing: 'small' | 'normal';
  isChecked: boolean;
  color: string;
};

export const MockRadio = styled.section<RadioButtonProps>`
  cursor: default;
  display: flex;
  align-items: center;

  .radio-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2rem;
    background: var(--background-white);
    border: var(--input-border);
    width: var(--sizing-lg);
    height: var(--sizing-lg);

    ${({ sizing }) =>
      sizing === 'small' &&
      css`
        width: var(--sizing-md);
        height: var(--sizing-md);
      `}

    span {
      display: inherit;
      align-items: inherit;
      justify-content: inherit;
    }
  }

  ${({ isChecked, color }) =>
    isChecked &&
    css`
      .radio-button {
        background: var(${color || '--primary-green'});
        border-color: var(${color || '--primary-green'});
      }
    `}

  .paragraph {
    margin-left: var(--sizing-sm);
  }
`;
