import styled from 'styled-components';

import { media } from 'DesignLibrary/global';

export const Wrapper = styled.div`
  width: 100%;

  ${media.TABLET`
    width: var(--sizing-4xl);
  `}
`;
