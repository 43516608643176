import styled from 'styled-components';

export const CompareContainer = styled.div`
  position: relative;
  z-index: 1;
`;

interface FloatingHeaderRowProps {
  shadow: boolean;
}

export const FloatingHeaderRow = styled.div<FloatingHeaderRowProps>`
  position: sticky;
  top: 16px;
  background: var(--primary-white);
  z-index: 9999;
  transition: 0.2s box-shadow;

  ${({ shadow }) => shadow && 'box-shadow: var(--shadow-card);'}
`;
