import React from 'react';

import { TextField } from 'Containers/App/types';
import { BenefitDefinition } from 'Containers/CommercialRoutes/types';
import { useTextContext } from 'Contexts/textContext';
import { Paragraph } from 'DesignLibrary/atoms';
import { Button } from 'DesignLibrary/atoms/Button';
import Checkbox from 'DesignLibrary/atoms/inputs/Checkbox';
import TextInput from 'DesignLibrary/atoms/inputs/TextInput';
import ToggleList from 'DesignLibrary/atoms/ToggleList';
import Text from 'Shared/Text';

import { BenefitContainer } from './styled';
import { BenefitGroupIds } from '../../types';

export interface BenefitInputProps {
  benefitGroupId: BenefitGroupIds;
  benefitId: string;
  benefit: BenefitDefinition;
  isRemovable: boolean;
  isHsaEligible: boolean;
  labelId: string;
  handleBenefitChange: (
    benefitGroupId: BenefitGroupIds,
    benefitId: string,
    benefit: BenefitDefinition,
  ) => void;
  deleteBenefit: (benefitGroupId: BenefitGroupIds, benefitId: string) => void;
}

const BenefitInput = ({
  benefitGroupId,
  benefitId,
  benefit,
  isRemovable,
  isHsaEligible,
  labelId,
  handleBenefitChange,
  deleteBenefit,
}: BenefitInputProps) => {
  const { costSharingType, costSharingAmount, isSubjectToDeductible } = benefit;

  const { retrieveContentfulData } = useTextContext();

  const handleChange = (newBenefit) => {
    const updatedBenefit = {
      ...benefit,
      ...newBenefit,
    };
    handleBenefitChange(benefitGroupId, benefitId, updatedBenefit);
  };

  const handleToggle = (type) => {
    // Only call handleChange with new type and reset amount if type will change
    if (costSharingType !== type) {
      handleChange({
        costSharingType: type,
        costSharingAmount: '',
      });
    }
  };

  const handleCostSharingChange = (e) => {
    const { value, max } = e.target;

    // Need to allow '' if user deletes their input
    if (value.match(/^[0-9]{0,3}\.?[0-9]{0,2}?$/) || value === '') {
      const maxAllowed = parseInt(max, 10) || Infinity;
      const parsed = parseFloat(value);
      // To allow input of floats greater than 0 need to allow input of '.' and '.0'
      const isValidPartialInput = value === '' || value === '.' || value === '.0';

      if ((parsed <= maxAllowed && parsed >= 0) || isValidPartialInput) {
        const newBenefit = { costSharingAmount: value };
        if (e.type === 'blur') {
          // If blur we want to call parseFloat to remove trailing decimal points and zeros
          // unless isValidPartialInput where we want to replace with an empty string
          const blurValue = isValidPartialInput ? '' : parseFloat(value).toString();
          newBenefit.costSharingAmount = blurValue;
        }
        handleChange(newBenefit);
      }
    }
  };

  const elementId = `benefit-select-${benefitId}`;

  const removeButton = isRemovable ? (
    <Button
      className="remove-btn"
      data-testid="remove-btn"
      buttonType="link"
      onClick={() => {
        deleteBenefit(benefitGroupId, benefitId);
      }}
    >
      <Text field="spousal_plan_comparison.button_text.remove" />
    </Button>
  ) : (
    ''
  );

  const costSharingTypeOptions = [
    {
      option: <Text field="spousal_plan_comparison.button_text.benefitToggle.copay" />,
      value: 'copay',
    },
    {
      option: <Text field="spousal_plan_comparison.button_text.benefitToggle.coinsurance" />,
      value: 'coinsurance',
    },
  ];

  return (
    <>
      <BenefitContainer id={benefitId} data-testid={`benefit-id-${benefit.serffCode}`}>
        <div className="display-text" id={elementId}>
          <Text
            field={`spousal_plan_comparison.benefit_groups.${benefitGroupId}.types.${benefitId}` as TextField}
          />
        </div>
        <ToggleList
          label={retrieveContentfulData<string>(
            `spousal_plan_comparison.benefit_groups.${benefitGroupId}.types.${benefitId}`,
          )}
          groupName={benefitId}
          selected={costSharingType}
          sizing="small"
          options={costSharingTypeOptions}
          handleChange={(e) => handleToggle(e.target.value)}
        />
        <TextInput
          labelId={`${labelId} ${elementId}`}
          name="benefitCostSharingAmount"
          type="text"
          inputType={costSharingType === 'copay' ? 'currency' : 'percent'}
          value={costSharingAmount ? costSharingAmount.toString() : ''}
          handleChange={handleCostSharingChange}
          required
          max={costSharingType === 'copay' ? 1000 : 100}
        />
        <Checkbox
          testId="is-subject-to-deductible"
          checked={isHsaEligible ? true : isSubjectToDeductible}
          handleChange={() => handleChange({ isSubjectToDeductible: !isSubjectToDeductible })}
          disabled={isHsaEligible}
        >
          <Paragraph className="subject-to-deductible">
            <Text field="spousal_plan_comparison.deductible_benefit.title" />
          </Paragraph>
        </Checkbox>
      </BenefitContainer>
      {removeButton}
    </>
  );
};

export default BenefitInput;
