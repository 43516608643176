import { fromJS, Map, List } from 'immutable';

// I guess this'll fail on tuples?  idk man i can't keep going
type ReturnedType<T> = T extends number
  ? number
  : T extends string
    ? string
    : T extends boolean
      ? boolean
      : T extends undefined
        ? undefined
        : T extends (infer U)[]
          ? List<ReturnedType<U>>
          : TypedMap<T>;

export interface TypedMap<DataType> extends Map<any, any> {
  /*
  Man it's a hack. Ultimately we seem to need to replace our use of immutable.js with something
  that plays more nicely with types.

  There's a lot of functionality not here, and it may fail with certain types, but ultimately the
  goal was just to be able to type the reducer and have those types flow through to the selectors,
  which seems to have worked in a limited setting so far? We'll see if it provides value.

  Inspired by https://blog.mayflower.de/6630-typescript-redux-immutablejs.html, but I couldn't
  get the AllowedValue portion to work, which would constrain the types we passed in.  However,
  I'm not sure need that due to the fact that we're using `fromJS` in `createTypedMap`, which
  operates recursively, rather than plain Immutable.Map, which doesn't.

   */
  toJS(): DataType;
  get<K extends keyof DataType>(key: K, notSetValue?: DataType[K]): ReturnedType<DataType[K]>;
  set<K extends keyof DataType>(key: K, value: DataType[K]): this;
}

export const createTypedMap = <DataType>(data: DataType): TypedMap<DataType> => fromJS(data) as any;
