import React from 'react';

import { H4, Paragraph } from 'DesignLibrary/atoms';

import { ChartContainer } from './styled';

export interface ChartWrapperProps {
  title: string;
  subtitle: string;
  chart: React.ReactNode;
  details: React.ReactNode;
}

export const ChartWrapper = ({ title, subtitle, chart, details }: ChartWrapperProps) => (
  <ChartContainer>
    <div className="header">
      <H4>{title}</H4>
      <Paragraph size="small">{subtitle}</Paragraph>
    </div>
    <div className="main-content">
      <div className="chart">{chart}</div>
      <div className="details">{details}</div>
    </div>
  </ChartContainer>
);
