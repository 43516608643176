import React from 'react';

import { PeopleLikeYou } from 'app/utils/apiTypes';
import { useTextContext } from 'Contexts/textContext';
import { Drawer } from 'DesignLibrary/atoms/Drawer';

import HealthcareCosts from './HealthcareCosts';
import HealthcareUsage from './HealthcareUsage';
import Overview from './Overview';
import Rankings from './Rankings';

interface ExplainerModalProps {
  isOpen: boolean;
  handleClose: () => void;
  plyData: PeopleLikeYou | Record<string, never>;
}

const ExplainerModal = ({ isOpen, handleClose, plyData }: ExplainerModalProps) => {
  const { retrieveContentfulData } = useTextContext();
  const title = retrieveContentfulData<string>('people_like_you.modal_title');

  return (
    <Drawer isOpen={isOpen} onClose={handleClose} title={title}>
      <Overview />
      <HealthcareUsage plyData={plyData} />
      <HealthcareCosts plyData={plyData} />
      <Rankings />
    </Drawer>
  );
};

export default ExplainerModal;
