import styled from 'styled-components';

export const MediaWrapper = styled.div`
  width: 100%;
`;

export const MediaControls = styled.div`
  display: flex;
  flex-direction: row;
  gap: var(--sizing-xs);

  button {
    min-width: var(--sizing-xl);
  }
`;
