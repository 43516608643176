import { ContentfulSpousalPlanComparisonSection } from 'ContentfulDefaults/types/spousal';

export const spousal: {
  spousal_plan_comparison: ContentfulSpousalPlanComparisonSection;
} = {
  spousal_plan_comparison: {
    contentful_name: 'Spousal Plan Comparison (DEFAULT DO NOT CHANGE)',
    button_text: {
      remove: '- Remove benefit',
      openPDF: 'What do I need to compare a plan?',
      complete: 'Compare This Plan',
      drugTier: 'Add a tier',
      editModal: 'Edit your plan',
      openModal: 'Compare a plan',
      benefitToggle: {
        copay: 'Copay',
        coinsurance: 'Coinsurance',
      },
      premiumPeriod: {
        prefix: 'per',
        options: ['Weekly', 'Bi-weekly', 'Semi-monthly', 'Monthly', 'Annually'],
        periodNames: {
          weekly: 'Weekly',
          monthly: 'Monthly',
          annually: 'Annually',
          biweekly: 'Bi-Weekly',
          semimonthly: 'Semi-monthly',
        },
      },
    },
    page_title: 'Compare Spousal Plans',
    heading: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: "Let's Compare a Spouse or Partner Plan",
              nodeType: 'text',
            },
          ],
          nodeType: 'heading-1',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'We know you may have options for health insurance outside of what’s provided by your employer. You can use the following tool to input your partner’s plan information for comparisons with your employer’s options.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    necessary_information: {
      items: [
        {
          link: 'https://picwellapppublicdata.s3.amazonaws.com/Spousal_Plan_Instructions.pdf',
          name: 'Premium',
          type: 'premium',
        },
        {
          link: 'https://picwellapppublicdata.s3.amazonaws.com/Spousal_Plan_Instructions.pdf',
          name: 'Out of Pocket Maximum',
          type: 'oopMax',
        },
        {
          link: 'https://picwellapppublicdata.s3.amazonaws.com/Spousal_Plan_Instructions.pdf',
          name: 'Summary of benefits',
          type: 'benefits',
        },
        {
          link: 'https://picwellapppublicdata.s3.amazonaws.com/Spousal_Plan_Instructions.pdf',
          name: 'Deductible',
          type: 'deductible',
        },
      ],
      question: 'What plan information do I need?',
    },
    details_title: 'Plan Details',
    threshold_warning: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Be sure to enter ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'underline',
                },
              ],
              value: 'family threshold',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value: ' values for the fields below.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    premium_question: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: "What are the plan's ",
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'underline',
                },
              ],
              value: 'premiums',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value: '?',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    deductible_question: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: "What is the plan's annual ",
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'underline',
                },
              ],
              value: 'deductible',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value: '?',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: '',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    deductible_subfields: {
      expand: 'Wait, my plan has separate medical and drug deductibles',
      contract: 'Nevermind, my plan has a joint deductible',
      drugText: 'Drug deductible',
      healthText: 'Health deductible',
    },
    oop_max_question: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: "What is the plan's annual ",
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'underline',
                },
              ],
              value: 'out of pocket maximum',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value: '?',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    oop_max_fields: {
      expand: 'Wait, my plan has separate medical and drug OOP maxes',
      contract: 'Nevermind, my plan has a joint OOP max',
      drugText: 'Drug OOP Max',
      healthText: 'Health OOP Max',
    },
    validation_messages: {
      oopMaxValidationError:
        // eslint-disable-next-line no-template-curly-in-string
        'Out-of-Pocket Maximums for HSA-eligible plans cannot be greater than ${x}',
      benefitCategoryRequired: 'This category must be subject to the deductible in an HSA-eligible plan',
      deductibleExceedsOopMax: 'Deductible cannot be greater than Out-Of-Pocket Maximum',
      splitDeductibleExceedsOopMax:
        'The sum of drug and health deductibles cannot be greater than Out-Of-Pocket Maximum',
      deductibleExceedsSplitOopMax:
        'Deductible cannot be greater than the sum of drug and health Out-Of-Pocket Maximums',
      deductibleValidationError:
        // eslint-disable-next-line no-template-curly-in-string
        'Deductibles for HSA-eligible plans must be at least ${x}',
      splitOopMaxValidationError:
        'HSA-eligible plans do not have separate health and drug Out-Of-Pocket Maximums',
      splitDeductibleValidationError: 'HSA-eligible plans do not have separate health and drug deductibles',
      drugDeductibleExceedsDrugOopMax:
        'The drug deductible cannot be greater than the drug Out-Of-Pocket Maximum.',
      healthDeductibleExceedsHealthOopMax:
        'The health deductible cannot be greater than the health Out-Of-Pocket Maximum.',
    },
    hsa_eligible_question: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'This plan will have ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'underline',
                },
              ],
              value: 'HSA contributions',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value: ' from you or your employer',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    hsa_contribution_question: {
      yearly: 'per year',
      question: "What is the employer's HSA contribution?",
    },
    hra_eligible_question: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Is this plan ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'underline',
                },
              ],
              value: 'HRA eligible',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value: '?',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    hra_contribution_question: {
      yearly: 'per year',
      question: "What is the employer's HRA contribution?",
    },
    benefits_title: 'Plan Benefits',
    deductible_benefit: {
      text: 'Some benefits may be provided before your deductible is reached. For these benefits uncheck the box below.',
      title: 'Subject to Deductible',
    },
    benefit_groups: {
      drug: {
        title:
          'If you need drugs to heal your illness or condition (select ALL drug benefits for your plan)...',
        types: {
          specialty: 'Specialty (Tier 4)',
          generic_tier_1: 'Generic (Tier 1)',
          preferred_tier_2: 'Preferred Brand (Tier 2)',
          non_preferred_tier_3: 'Non-preferred Brand (Tier 3)',
        },
      },
      office: {
        title: "If you visit a health care provider's office for...",
        types: {
          specialist: 'A specialist visit',
          primaryCare: 'A primary care visit',
          preventiveCare: 'Preventive care',
        },
      },
      testing: {
        title: 'If you have a test...',
        types: {
          imaging: 'Imaging',
          laboratory: 'Laboratory',
        },
      },
      hospital: {
        title: 'If you have a hospital stay',
        types: {
          hospitalFacilityFee: 'Facility fee',
        },
      },
      emergency: {
        title: 'If you need immediate medical attention...',
        types: {
          urgentCare: 'Urgent care',
          emergencyCare: 'Emergency care',
        },
      },
      outpatientSurgery: {
        title: 'If you have outpatient surgery',
        types: {
          services: 'Physician and surgical services',
          outpatientFacilityFee: 'Facility fee',
        },
      },
    },
    card: {
      tag: 'Entered Plan',
      icon: 'https://picwellapppublicdata.s3.amazonaws.com/contentful/plancomparison/compare-scale.svg',
      text: "We know you may have other options for health insurance. You can use this tool to enter data and compare another plan, such as a spouse's or partner's plan.",
      title: 'Want to see how other options stack up?',
      editIcon: 'https://picwellapppublicdata.s3.amazonaws.com/contentful/plancomparison/edit.svg',
      editText: 'You can only compare one non-employer plan at a time. Click here to edit your plan.',
      editTitle: 'Edit your entered plan',
    },
    instructions_url: 'https://picwellapppublicdata.s3.amazonaws.com/Spousal_Plan_Instructions.pdf',
  },
};
