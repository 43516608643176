import styled from 'styled-components';

interface PremiumPerDeductionContainerProps {
  size: 'small' | 'medium' | 'large';
}

export const PremiumPerDeductionContainer = styled.div<PremiumPerDeductionContainerProps>`
  position: relative;

  .numbers {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .pay-period {
    padding-left: 4px;
    position: relative;

    ${({ size }) => size === 'large' && 'top: 4px;'}
  }
`;
