import { Box, Flex, Stack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

import { Button, H5, H6, Paragraph } from 'DesignLibrary/atoms';
import Text from 'Shared/Text';
import { LocationProps } from 'Types/entities';
import { sendInsightsEvent } from 'Utils/insights';

import { Location } from '../Location';

export const LocationSelect = ({
  selectedProvider, // Object representing the selected provider
  setShowSearchPage, // Function to set visibility of search page on modal
  setSelectedProvider, // Function to update the selected provider
  setIsAdded, // Function to indicate if a provider has been added
  setIsUpdated, // Function to indicate if a provider has been updated
  setSelectedLocation, // Function to update the selected location
  providerDetails, // Object representing the provider details
  setSearchText,
  isEditLocation, // to check if we are editing a provider
  setIsEditLocation,
}) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const [name] = selectedProvider.label.split(', ');
  // Join provider types into a comma-separated string
  const providerTypes = selectedProvider.providerTypes?.join(', ');

  // Handler for the cancel button click
  const handleOnCancelClick = () => {
    setShowSearchPage(true);
    setSelectedProvider('');
    setIsEditLocation(false);
  };

  // Handler for the add button click
  const handleOnAddClick = () => {
    if (showEditButton) {
      setIsUpdated(true);
      setIsEditLocation(false);
    } else {
      setIsAdded(true);
    }
    setShowSearchPage(true);
    setSearchText('');
    sendInsightsEvent(null, 'provider_added_to_list');
  };

  const defaultSelectedLocationIndex = (): number => {
    if (selectedProvider.locations?.length === 1) {
      setSelectedLocation(selectedProvider.locations[0]);
      return 0;
    }

    // Use reduce to find the selected default index
    const selectedDefaultIndex = providerDetails.reduce((defaultIndex, prov) => {
      if (prov.npi === selectedProvider.value) {
        const selectedIndex = prov.locations.findIndex((loc) => loc.uuid === prov.selected_location.uuid);
        if (selectedIndex !== -1) {
          return selectedIndex; // Return the index if found
        }
      }
      return defaultIndex; // Otherwise, return the current defaultIndex
    }, -1); // Initial value for reduce is -1

    return selectedDefaultIndex;
  };

  useEffect(() => {
    setSelectedIndex(defaultSelectedLocationIndex());
  }, []);

  const showEditButton =
    isEditLocation || providerDetails?.some((provider) => provider.npi === selectedProvider.value);

  return (
    <Stack maxW="480px" w="100%" pb={8} flex="1" overflow="hidden" margin="0 auto">
      <Flex
        flexDir="column"
        overflow="hidden"
        h="100%"
        w="100%"
        border="var(--border-thick)"
        p="4"
        borderRadius="var(--border-radius-xs)"
      >
        <Box mb={6}>
          <Box mb={2} sx={{ h5: { fontWeight: '900 !important' } }}>
            <H5 data-testid="doctor-name">{name}</H5>
          </Box>
          <Paragraph size="small" data-testid="provider-types">
            {providerTypes}
          </Paragraph>
        </Box>

        <Box sx={{ h6: { fontWeight: '900 !important' } }}>
          <H6 weight="bold" data-testid="location-select" color="--primary-blue">
            <Text field="health_section.provider_selection_modal.select_location_label" />
          </H6>
        </Box>

        <Stack role="radiogroup" overflowY="auto" p={1} mt={2} flex="1">
          {selectedProvider.locations?.map((location: LocationProps, index: number) => (
            <Location
              key={location.uuid}
              location={location}
              setSelectedLocation={setSelectedLocation}
              isSelected={selectedIndex === index}
              uniqueId={index}
              setSelectedIndex={setSelectedIndex}
            />
          ))}
        </Stack>
      </Flex>
      <Flex gap={2}>
        <Button onClick={handleOnCancelClick} stretch data-testid="cancel">
          <Text field="health_section.provider_selection_modal.cancel_button_cta" />
        </Button>
        <Button
          buttonType="primary"
          onClick={handleOnAddClick}
          stretch
          isDisabled={selectedIndex === -1}
          data-testid="add"
          iconLeft="PlusCircle"
        >
          <Text
            field={`health_section.provider_selection_modal.${showEditButton ? 'update_button_cta' : 'add_button_cta'}`}
          />
        </Button>
      </Flex>
    </Stack>
  );
};
