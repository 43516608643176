import React, { FC, useEffect, useState, useContext, createContext } from 'react';

import { BREAKPOINTS } from '../vars';

export type DeviceType = 'desktop' | 'tablet' | 'mobile';

export interface ViewportContextProps {
  device: DeviceType;
  width: number;
  height: number;
}

export const ViewportContext = createContext<ViewportContextProps>({ device: 'mobile', width: 0, height: 0 });

export const ViewportProvider: FC = ({ children }) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [device, setDevice] = useState<DeviceType>('mobile');

  const handleViewportDevice = (w) => {
    if (w < BREAKPOINTS.TABLET) {
      setDevice('mobile');
    } else if (w >= BREAKPOINTS.TABLET && w < BREAKPOINTS.DESKTOP) {
      setDevice('tablet');
    } else if (w >= BREAKPOINTS.DESKTOP) {
      setDevice('desktop');
    } else {
      setDevice('desktop');
    }
  };

  const handleWindowResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
    handleViewportDevice(window.innerWidth);
  };

  useEffect(() => {
    handleWindowResize();

    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return <ViewportContext.Provider value={{ device, width, height }}>{children}</ViewportContext.Provider>;
};

export const useViewport = () => {
  const { device, width, height } = useContext(ViewportContext);
  return { device, vw: width, vh: height };
};
