import { css } from 'styled-components';

export const hideAccessibleCheckmark = css`
  // pointer-events: none; //code commented due to test case is failing because click event is not working
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
`;

export const focus = css`
  outline: none;
  border-color: transparent;
  box-shadow: 0 0 0 2px var(--text-black);
`;

export const focusUtil = css`
  &:focus {
    /* Fallback for browsers that don't support :focus-visible */
    outline: 2px solid var(--text-black);
  }
  &:focus:not(:focus-visible) {
    /* Removing the above focus styling */
    outline: 0;
    box-shadow: none;
  }
  &:focus-visible {
    /* add keyboard-user specific focus styling */
    outline: 2px solid var(--text-black);
    outline-offset: 2px;
  }
`;

export const focusSingle = css`
  outline: 2px solid var(--primary-blue);
  outline-offset: 2px;
`;

export const cardStyling = css`
  background: var(--primary-white);
  border-radius: var(--border-radius-xs);
  box-shadow: var(--shadow-card);
`;

export const selectedGreenStyling = css`
  outline: 3px solid var(--colors-green-200);
  box-shadow: var(--shadow-selected-green);
`;

export const selectedBlueStyling = css`
  outline: 3px solid var(--primary-blue);
  box-shadow: var(--shadow-selected-blue);
`;

export const selectedRedStyling = css`
  outline: 3px solid var(--primary-red);
  box-shadow: var(--shadow-selected-red);
`;
