import { ContentfulUnifiedLandingPage } from 'ContentfulDefaults/types/unifiedLanding';

export const unifiedLanding: {
  unified_landing_page: ContentfulUnifiedLandingPage;
} = {
  unified_landing_page: {
    open_enrollment_bar_days_left_text: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [
                {
                  type: 'code',
                },
                {
                  type: 'bold',
                },
              ],
              value: '{x}',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'bold',
                },
              ],
              value: ' days',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value: ' left to enroll!',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    open_enrollment_bar_before_oe_text: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [
                {
                  type: 'code',
                },
                {
                  type: 'bold',
                },
              ],
              value: '{x}',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'bold',
                },
              ],
              value: ' days',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value: ' until Open Enrollment begins!',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    choose_experience_modal: {
      contentful_name: '[default] Unified Landing Choose Experience Modal',
      title: 'Choose your {x} experience',
      option1_bullets: [
        'An audio-led experience',
        'In-depth education on all of your options',
        'Closed captioning available',
      ],
      option2_bullets: [
        'A text-based experience',
        'A brief overview of your benefits',
        'Includes a family plan comparison tool',
      ],
      button: 'Continue',
      spanish_support_disclaimer: 'No disponible en Español',
    },
    hide_open_enrollment_bar: false,
    open_enrollment_bar_start_date: '2023-07-03T00:00:00-04:00',
    open_enrollment_bar_end_date: '2023-07-16T00:00:00-04:00',
    plan_year_start_date: '2024-01-01T00:00:00-04:00',
    plan_year_end_date: '2024-12-31T00:00:00-04:00',
    hero: {
      contentful_name: '[default] Unified Landing Hero',
      title: '{x} can help you pick a medical plan fast.',
      cta_button: "Let's get started.",
      continue_as_guest_button: 'Get started as a guest',
      login_or_signup_button: 'Log in or sign up',
      continue_as_guest_description: 'Without saving your progress.',
      login_signup_description: 'Save progress, get bonus tips, and more!',
      authenticated_header: 'Bienvenido, {x}',
    },
    alert_banner_enabled: false,
    alert_banner_content: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Modal content goes here',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    terms_and_policy_disclaimer: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'By clicking "Help Me Choose", you  agree to the ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [{ type: 'bold' }],
              value: 'Terms of Service',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value: '\u00a0and ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [{ type: 'bold' }],
              value: 'Privacy Policy',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [{ type: 'code' }],
              value: '.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    ie_disclaimer: {
      contentful_name: '[DEFAULT] IE Disclaimer',
      header: "You're using an outdated browser!",
      text: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  "We can't promise the best experience while using the site on your browser. Please use an updated browser like Google Chrome, Firefox, Edge, or Safari.",
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      color: 'red',
      icon: 'X',
    },
    show_custom_disclaimer: 'show custom disclaimer',
    custom_landing_disclaimer_button_text: 'custom disclaimer button text',
    custom_landing_disclaimer_modal_title: '"Notice"',
    custom_landing_disclaimer_modal: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Modal content goes here',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    custom_landing_disclaimer: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Disclaimer text goes here',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    open_enrollment_bar_days_left_ending_soon_text: {
      nodeType: 'document',
      data: {},
      content: [
        {
          nodeType: 'paragraph',
          data: {},
          content: [
            {
              nodeType: 'text',
              value: 'Only ',
              marks: [
                {
                  type: 'bold',
                },
              ],
              data: {},
            },
            {
              nodeType: 'text',
              value: '{x}',
              marks: [
                {
                  type: 'code',
                },
                {
                  type: 'bold',
                },
              ],
              data: {},
            },
            {
              nodeType: 'text',
              value: ' days',
              marks: [
                {
                  type: 'bold',
                },
              ],
              data: {},
            },
            {
              nodeType: 'text',
              value: ' left to enroll!',
              marks: [],
              data: {},
            },
          ],
        },
      ],
    },
    footer: {
      contentful_name: '[default] Unified Landing Footer',
      disclaimer: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'ALEX provides a summary of your benefits and is accurate to the best of our knowledge. This is not an application for enrollment. By clicking "Login or signup" you agree to the ',
                nodeType: 'text',
              },
              {
                data: {
                  uri: 'https://www.jellyvision.com/tos/',
                },
                content: [
                  {
                    data: {},
                    marks: [
                      {
                        type: 'bold',
                      },
                    ],
                    value: 'Terms of Service',
                    nodeType: 'text',
                  },
                ],
                nodeType: 'hyperlink',
              },
              {
                data: {},
                marks: [],
                value: ' and ',
                nodeType: 'text',
              },
              {
                data: {
                  uri: 'https://www.jellyvision.com/privacy/',
                },
                content: [
                  {
                    data: {},
                    marks: [
                      {
                        type: 'bold',
                      },
                    ],
                    value: 'Privacy Policy',
                    nodeType: 'text',
                  },
                ],
                nodeType: 'hyperlink',
              },
              {
                data: {},
                marks: [],
                value: '.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      copyright: '© 2008–{x} The Jellyvision Lab, Inc. All rights reserved.',
      do_not_sell_text: 'No Vendas Mi Informacion',
    },
    contentful_name: '[testdan1] Unified Landing Page',
    medicare_callout: {
      contentful_name: '[default] Unified Landing Medicare Callout',
      tag: 'New',
      text: '{x} can now help you with Medicare!',
      cta_button: 'Learn More About Medicare',
    },
    text_note: {
      short_text: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value: 'text note short text',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      long_text: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value: 'long text text note',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      button_text: 'Leer más',
    },
  },
};

export default unifiedLanding;
