import styled from 'styled-components';

export const EligContainer = styled.div`
  & > * + * {
    margin-top: var(--sizing-xs);
  }
`;

interface EligBarProps {
  color: 'green' | 'yellow' | 'blue' | 'purple';
  isSelected: boolean;
}

export const EligBarStyling = styled.div<EligBarProps>`
  display: flex;
  align-items: flex-start;
  gap: var(--sizing-sm);
  border-radius: var(--border-radius-sm);
  padding: var(--sizing-sm);
  background: ${({ color, isSelected }) => {
    if (isSelected) {
      return `var(--colors-${color}-50)`;
    }

    return 'var(--background-gray)';
  }};

  .paragraph,
  p {
    margin: 0;
  }
`;
