import React, { useEffect, useState } from 'react';

import { getOopChance } from 'Containers/TaxSavingsPage/helpers';
import { useTextContext } from 'Contexts/textContext';
import { NumberWithPayPeriod } from 'DesignLibrary/atoms/NumberWithPayPeriod';
import { TooltipCaps } from 'DesignLibrary/molecules';
import { GetHsaRecommendationResponse } from 'Utils/apiTypes';

import { Rating, Risk } from './Rating';
import { InfoContainer } from '../styled';

export interface ShortTermDetailsProps {
  hsaData: GetHsaRecommendationResponse | Record<string, never>;
  contribution: number;
}

export const ShortTermDetails = ({ hsaData, contribution }: ShortTermDetailsProps) => {
  const [taxBenefit, setTaxBenefit] = useState(0);
  const [oopChance, setOopChance] = useState(0);

  const { retrieveContentfulData } = useTextContext();

  useEffect(() => {
    // Update tax benefit when contribution changes
    setTaxBenefit(parseInt((hsaData.tax_rate * contribution).toString(), 10));

    // Update risk coverage % when contribution changes
    setOopChance(
      parseInt((getOopChance(hsaData.recommended_contribution_deciles, contribution) * 100).toString(), 10),
    );
  }, [contribution]);

  const payPeriod = contribution / 12;

  // Define Risk Level
  let riskLevel: Risk = 'risky';
  if (oopChance > 60) {
    riskLevel = 'moderate';
  }
  if (oopChance > 85) {
    riskLevel = 'safe';
  }

  return (
    <div className="details">
      <InfoContainer>
        <div>
          {/* TODO: CONTENTFUL Update Field Location */}
          <TooltipCaps
            tooltip={retrieveContentfulData<string>('hsa.interactive_section.tooltips.yearly_contribution')}
            keyHint="yearly-contribution"
          >
            {/* <Text field="hsa.interactive_section.yearly_contribution" /> */}
            {/* TODO: CONTENTFUL Update Field Location */}
            Yearly contribution
          </TooltipCaps>
          <NumberWithPayPeriod
            value={contribution}
            payPeriodType="yearly"
            keyHint="yearly-contribution-value"
          />
        </div>
        <div>
          {/* TODO: CONTENTFUL Update Field Location */}
          <TooltipCaps
            tooltip={retrieveContentfulData<string>(
              'hsa.interactive_section.tooltips.per_paycheck_contribution',
            )}
            keyHint="per-paycheck-contribution"
          >
            {/* TODO: CONTENTFUL Update Field Location */}
            {/* <Text field="hsa.interactive_section.per_paycheck_contribution" /> */}
            Per Pay Period
          </TooltipCaps>
          <NumberWithPayPeriod value={payPeriod} keyHint="per-paycheck-contribution-value" />
        </div>
        <div>
          {/* TODO: CONTENTFUL Update Field Location */}
          <TooltipCaps
            tooltip={retrieveContentfulData<string>('hsa.interactive_section.tooltips.yearly_tax_savings')}
            keyHint="yearly-tax-savings"
          >
            {/* TODO: CONTENTFUL Update Field Location */}
            {/* <Text field="hsa.interactive_section.yearly_tax_savings" /> */}
            Yearly Tax Savings
          </TooltipCaps>
          <NumberWithPayPeriod
            value={taxBenefit}
            payPeriodType="yearly"
            prefix="+"
            keyHint="yearly-tax-savings-value"
          />
        </div>
      </InfoContainer>
      <Rating oopChance={oopChance} risk={riskLevel} />
    </div>
  );
};
