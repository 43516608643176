import { Flex } from '@chakra-ui/react';
import React from 'react';

import { ProviderCard } from 'Containers/ResultPage/ProviderSelectionContent/ProviderCard';
import { Button } from 'DesignLibrary/atoms';
import { getProviderAddress } from 'Utils/helpers';

const ProviderList = ({
  location, // Object representing the location
  providerTypes, // Doctor provider types
  label, // Doctor name
  handleProviderDelete, // Handler for deleting provider
  handleProviderEdit, // Handler for editing location of provider
}) => {
  const subtitle = (
    <>
      {providerTypes?.length > 0 ? <>{providerTypes[0]} &#8226;</> : ''}{' '}
      {getProviderAddress(location!.address_details)}
    </>
  );

  return (
    <Flex p={4} justify="space-between" border="var(--border-thick)" borderRadius="var(--border-radius-xs)">
      <ProviderCard title={label} subtitle={subtitle} variant="search" testId="provider-list" />

      <Flex gap={0} flexDir={['column', 'row']}>
        <Button
          onClick={handleProviderEdit}
          buttonType="transparent"
          size="small"
          iconLeft="PencilSimple"
          data-testid="provider-edit-button"
          iconProps={{
            color: '--text-gray',
          }}
        />
        <Button
          onClick={handleProviderDelete}
          buttonType="transparent"
          size="small"
          iconLeft="Trash"
          data-testid="provider-delete-button"
          iconProps={{
            color: '--text-gray',
          }}
        />
      </Flex>
    </Flex>
  );
};

export default ProviderList;
