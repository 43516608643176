import React from 'react';

import AlexLogo from 'Assets/alex-logo.svg';
import { RichTextContent } from 'ContentfulDefaults/types/_shared';
import { ContentfulTextNote } from 'ContentfulWrappers/ContentfulTextNote';
import { useFeatureFlagContext } from 'Contexts/featureFlagContext';
import { useTextContext } from 'Contexts/textContext';
import { H2, H3 } from 'DesignLibrary/atoms';
import { TextNote } from 'DesignLibrary/molecules/TextNote';
import RichText from 'Shared/RichText';
import Text from 'Shared/Text';
import { getEnvironmentFromUrl } from 'Utils/urls';

import { Animation } from './Animation';
import GetStartedButton from './GetStartedButton';
import { HeroContainer, HeroContent, HeroContentWrapper } from './styled';
import { DEPLOY_ENVIRONMENTS } from '../../../constants';

export interface HeroProps {
  isNextClicked: boolean;
  isLoading: boolean;
  isAlexIdEnabled: boolean;
  forceAlexID: boolean;
  userName: string | undefined;
  handleNext: (e: React.MouseEvent<HTMLButtonElement>) => void;
  getStartedButtonRef: any;
  getStartedButtonAccessibilityProps: any;
}

export const Hero = ({
  isNextClicked,
  isLoading,
  isAlexIdEnabled,
  forceAlexID,
  userName,
  handleNext,
  getStartedButtonRef = null,
  getStartedButtonAccessibilityProps = {},
}: HeroProps) => {
  const { is_rue_landing_page_enabled } = useFeatureFlagContext();
  const { retrieveContentfulData } = useTextContext();

  const alertBannerEnabled = retrieveContentfulData<boolean>(
    'unified_landing_page.alert_banner_enabled',
    false,
  );
  const alertBannerContent = retrieveContentfulData<RichTextContent>(
    'unified_landing_page.alert_banner_content',
  );

  return (
    <HeroContainer isNextClicked={isNextClicked}>
      {alertBannerEnabled && alertBannerContent && (
        <TextNote
          className="alert-banner-text-note"
          data-testid="alert-banner-text-note"
          mb={2}
          shortDisplayText={<RichText field={undefined} fieldContent={alertBannerContent} />}
        />
      )}

      <ContentfulTextNote className="hero-text-note" sectionKey="unified_landing_page" />

      <HeroContentWrapper isNextClicked={isNextClicked}>
        <HeroContent isNextClicked={isNextClicked} className="content">
          {is_rue_landing_page_enabled && (
            <H3 data-testid="welcome-header" as="h3" className="mt-3" weight="normal">
              {userName ? (
                <Text
                  field="unified_landing_page.hero.authenticated_header"
                  vars={{
                    x: <span data-testid="username">{userName}</span>,
                  }}
                />
              ) : (
                <span />
              )}
            </H3>
          )}
          <H2 as="h1" weight="normal">
            <Text
              field="unified_landing_page.hero.title"
              vars={{
                x: (
                  <span key="alex-logo">
                    <img src={AlexLogo} alt="Alex" />
                  </span>
                ),
              }}
            />
          </H2>
          {isAlexIdEnabled && getEnvironmentFromUrl() === DEPLOY_ENVIRONMENTS.UAT && (
            <p className="alex-id-warning">
              <h3 className="cta-message">For the best review experience, please do not sign in. </h3>
              Testing multiple scenarios while signed in may result in conflicting information. If you login
              and something is amiss, logout and try again.
            </p>
          )}

          <div className="cta-buttons">
            <GetStartedButton
              getStartedButtonRef={getStartedButtonRef}
              isAlexIdEnabled={isAlexIdEnabled}
              isLoading={isLoading}
              handleNext={handleNext}
              forceAlexID={forceAlexID}
              getStartedButtonAccessibilityProps={getStartedButtonAccessibilityProps}
            />
          </div>
        </HeroContent>
        <div className="animation">
          <Animation />
        </div>
      </HeroContentWrapper>
    </HeroContainer>
  );
};
