import React, { FC, useEffect } from 'react';

import { AudioConfigTooltipContentfulPaths } from 'Containers/ProfilePage/ProfileAudio';
import { PregnancyCard } from 'ContentfulDefaults/types/profile';
import { useProfileContext } from 'Contexts/profileContext';
import { useTextContext } from 'Contexts/textContext';
import ToggleList from 'DesignLibrary/atoms/ToggleList';
import { Paragraph } from 'DesignLibrary/atoms/typography';
import { useViewport } from 'DesignLibrary/context';

import Question from '../_shared/Question';
import { QuestionWrapper } from '../_shared/Question/styled';
import { Survey } from '../types';

export interface PregnancySectionProps {
  survey: Survey;
  isNextDirty: boolean;
  isProfileAudioEnabled: boolean;
  handleSavePregnancyAnswer: (value: boolean | '') => void;
  handleSectionChange: (isValid: boolean, isComplete: boolean) => void;
  setHiddenAlert: (alert: string) => void;
}

const PregnancySection: FC<PregnancySectionProps> = ({
  survey,
  isNextDirty,
  isProfileAudioEnabled,
  handleSavePregnancyAnswer,
  handleSectionChange,
  setHiddenAlert,
}) => {
  const { retrieveContentfulData } = useTextContext();
  const { getSectionFromMap } = useProfileContext();
  const { device } = useViewport();

  const profileCard = retrieveContentfulData<PregnancyCard>('profile_section.pregnancy');
  const optionY = (profileCard?.button_options && profileCard?.button_options.yes) || 'Yes';
  const optionN = (profileCard?.button_options && profileCard?.button_options.no) || 'No';

  useEffect(() => {
    const alertText = retrieveContentfulData<string>('profile_section.accessibility.unlocked_section', '');
    setHiddenAlert(alertText.replace(/{x}/, profileCard.name));
  }, []);

  useEffect(() => {
    const section = getSectionFromMap('pregnancy');
    const isComplete = section?.isComplete || validateForm();
    handleSectionChange(validateForm(), isComplete);
  }, [survey.plan_child_question]);

  const handleChange = (value) => {
    handleSavePregnancyAnswer(value);
  };

  function validateForm() {
    return survey.plan_child_question !== '';
  }

  return (
    <div id="pregnancy-section" data-testid="pregnancy-section">
      <Question
        name="pregnancy"
        error={isNextDirty && survey.plan_child_question === ''}
        isIncomplete={!validateForm()}
        audioTooltipsEnabled={isProfileAudioEnabled}
        audioTooltipContentfulPath={AudioConfigTooltipContentfulPaths.PregnancySectionAudioTooltip}
      >
        <QuestionWrapper className="question-wrapper">
          <Paragraph>{profileCard && profileCard.header}</Paragraph>

          <ToggleList
            label={profileCard.header}
            groupName="pregnancyQuestion"
            options={[
              {
                option: optionY as string,
                value: 'yes',
              },
              {
                option: optionN as string,
                value: 'no',
              },
            ]}
            handleChange={(e) => handleChange(e.target.value)}
            color="--colors-gray-black"
            selected={survey.plan_child_question}
            stretch={device === 'mobile'}
          />
        </QuestionWrapper>
      </Question>
    </div>
  );
};

export default PregnancySection;
