import styled, { keyframes, css } from 'styled-components';

import { FadeOutDownKeyframes } from 'DesignLibrary/keyframes';

interface ColorProps {
  color?: 'green' | 'yellow' | 'red';
}

interface BarProps extends ColorProps {
  isBeforeOE: boolean;
  percent: number;
}

export interface OpenEnrollmentBarProps {
  isNextClicked: boolean;
  openEnrollmentStartDate: string | undefined;
  openEnrollmentEndDate: string | undefined;
}

export const EnrollBar = styled.section`
  position: fixed;
  bottom: var(--sizing-sm);
  right: var(--sizing-sm);
  max-width: calc(100% - 32px);
  width: 420px;
  z-index: var(--chakra-zIndices-banner);
`;

export const Content = styled.div<OpenEnrollmentBarProps>`
  background: var(--primary-white);
  width: 100%;
  border-radius: var(--border-radius-xs);
  box-shadow: var(--shadow-large);
  padding: var(--sizing-sm);

  .paragraph,
  p {
    b {
      color: var(--text-black);
    }
  }

  .title {
    /* border: 1px solid red; */
  }

  .bar-wrapper {
    position: relative;
    margin-top: var(--sizing-sm);
  }

  .dates {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  ${({ isNextClicked }) =>
    isNextClicked &&
    css`
      opacity: 1;
      animation-delay: 0.75s;
      animation-name: ${FadeOutDownKeyframes};
      animation-duration: 1.25s;
    `}
`;

const SlideRight = keyframes`
  0% {
   transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
`;

export const Bar = styled.div<BarProps>`
  position: relative;
  width: 100%;
  height: var(--sizing-md);
  border-radius: var(--border-radius-xs);
  overflow: hidden;
  background: var(--border-gray);
  user-select: none;

  .active-bar {
    position: relative;
    height: 100%;
    width: ${({ isBeforeOE, percent }) => (isBeforeOE ? '0.5%' : `${Math.max(percent, 3)}%`)};
    overflow: hidden;

    transform: translateX(-100%);
    animation: ${SlideRight} ease-out 3s 0s forwards;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      height: 100%;
      width: 100vw;
      background: ${({ color }) => color && `var(--primary-${color})`};
    }
  }
`;

export const Dates = styled.div`
  height: var(--sizing-md);
  padding: 0 var(--sizing-xs);
  display: inline-flex;
  align-items: center;
`;
