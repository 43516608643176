import styled from 'styled-components';

import { media } from 'DesignLibrary/global';

export const LocationContainer = styled.section`
  display: flex;
  flex-direction: column;
  gap: var(--sizing-sm);

  .zipcode-style {
    width: var(--sizing-3xl);
  }

  ${media.TABLET`
    flex-direction: row;

    .state-input {
      margin-top: 0;
    }
  `}
`;
