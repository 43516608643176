import { Stack } from '@chakra-ui/react';
import React, { useState, useRef } from 'react';

import { useTextContext } from 'Contexts/textContext';
import { H4, Paragraph } from 'DesignLibrary/atoms';
import Text from 'Shared/Text';
import { ActiveCostCategory, PeopleLikeYou } from 'Utils/apiTypes';
import { sendInsightsEvent } from 'Utils/insights';

import { CostIcon } from './CostIcon';
import { ServiceUtilization } from './ServiceUtilization';
import { CostContainer, IconTab } from './styled';
import { iconSVGs } from './svg';
import SectionWrapper from '../_shared/SectionWrapper';

interface HealthCareCostsProps {
  plyData: PeopleLikeYou | Record<string, never>;
}

interface SpendingIconText {
  plyKey: string;
  iconText: string;
  inlineText: string;
}

const HealthcareCosts = ({ plyData }: HealthCareCostsProps) => {
  const [activeCostCategory, setActiveCostCategory] = useState<ActiveCostCategory>('office_visits');
  const { retrieveContentfulData } = useTextContext();

  const activeIconRef = useRef<HTMLElement[]>([]);

  const usageRate = parseInt((plyData.claim_rate[activeCostCategory] * 100).toString(), 10);
  const { p25, p50, p75 } = plyData.claim_cost[activeCostCategory];

  const spendingIconTexts = retrieveContentfulData<SpendingIconText[]>('people_like_you.spending_icons');

  const spendingIconData = spendingIconTexts.map((textObj) => ({
    svg: iconSVGs[textObj.plyKey],
    ...textObj,
  }));

  const spendingIcons = spendingIconData.map((data, idx) => {
    const { plyKey, iconText, svg } = data;
    const isActive = activeCostCategory === plyKey;
    return (
      <CostIcon
        id={`${plyKey}-tab`}
        key={plyKey}
        tabIndex={isActive ? 0 : -1}
        role="tab"
        ref={(el: HTMLElement) => {
          activeIconRef.current[idx] = el;
        }}
        aria-controls={`${plyKey}-panel`}
        aria-selected={isActive}
        isActive={isActive}
        displayText={iconText}
        svg={svg}
        onClick={(e) => {
          setActiveCostCategory(plyKey);
          sendInsightsEvent(e, 'explainer_modal_spending_icon', {
            icon: plyKey,
          });
        }}
        onKeyDown={(e) => {
          const activeIndex = spendingIconData.findIndex((i) => i.plyKey === activeCostCategory);
          if (e.key === 'Enter') {
            setActiveCostCategory(plyKey);
            sendInsightsEvent(e, 'explainer_modal_spending_icon', {
              icon: plyKey,
            });
          }
          if (e.key === 'ArrowRight') {
            if (activeIndex + 1 < spendingIconData.length) {
              setActiveCostCategory(spendingIconData[activeIndex + 1].plyKey);
              sendInsightsEvent(e, 'explainer_modal_spending_icon', {
                icon: plyKey,
              });

              activeIconRef.current[activeIndex + 1].focus();
            }
          }
          if (e.key === 'ArrowLeft') {
            if (activeIndex - 1 >= 0) {
              setActiveCostCategory(spendingIconData[activeIndex - 1].plyKey);
              sendInsightsEvent(e, 'explainer_modal_spending_icon', {
                icon: plyKey,
              });

              activeIconRef.current[activeIndex - 1].focus();
            }
          }
        }}
      />
    );
  });

  return (
    <SectionWrapper number={2}>
      <CostContainer>
        <Stack gap={[4, 6]}>
          <Stack>
            <H4 as="h2">
              <Text field="people_like_you.spending_title" />
            </H4>
            <Paragraph lineHeight={1.75}>
              <Text field="people_like_you.spending_section_introduction" />{' '}
            </Paragraph>
          </Stack>
          <Stack>
            <IconTab role="tablist" aria-activedescendant={`${activeCostCategory}-tab`}>
              {spendingIcons}
            </IconTab>

            <ServiceUtilization
              usageRate={usageRate}
              spendingP25={p25}
              spendingP50={p50}
              spendingP75={p75}
              categoryDisplayText={
                spendingIconData.find((data) => data.plyKey === activeCostCategory)?.inlineText || ''
              }
              activeCostCategory={activeCostCategory}
            />
          </Stack>
        </Stack>
      </CostContainer>
    </SectionWrapper>
  );
};

export default HealthcareCosts;
