import { isEmpty } from 'lodash';
import React, { useState } from 'react';

import {
  ALL_NONSELECTABLE_PLAN_TYPES,
  CONTENT_MAP,
  CUSTOM_NAME_PLAN_TYPES,
  NO_SET_PREMIUMS_PLAN_TYPES,
  SET_PREMIUM_PLAN_TYPES,
} from 'Containers/OverviewPage/constants';
import { InsuranceType } from 'Containers/ViewBenefitPage/types';
import { ContentfulType } from 'ContentfulDefaults/ContentfulType';
import { ContentfulSupplementalPlan } from 'ContentfulDefaults/types/benefitsAdditional';
import { usePremiumContext } from 'Contexts/premiumContext';
import { useTextContext } from 'Contexts/textContext';
import { NumberWithPayPeriod } from 'DesignLibrary/atoms';
import { Button } from 'DesignLibrary/atoms/Button';
import { PremiumPerDeduction } from 'DesignLibrary/atoms/PremiumPerDeduction';
import Text from 'Shared/Text';
import {
  Recommendation,
  SelectedSupplementalPlans,
  SupplementalPlan,
  SupplementalPlansFullHousehold,
  USDRangeData,
} from 'Types/entities';

import Card from './Card';
import { ReviewGroup } from './ReviewGroup';
import { Container, ShowMoreButton } from './styled';

export const getTotalPremiums = (healthPremiums, supplementalPremiums) => {
  // Determine whether to show range or single number
  const isPremiumRange = supplementalPremiums.type === 'range';

  if (isPremiumRange) {
    return {
      type: 'range',
      data: {
        lower: healthPremiums + supplementalPremiums.lower,
        upper: healthPremiums + supplementalPremiums.upper,
      },
    };
  }

  // If type is not range, lower and upper are the same
  return healthPremiums + supplementalPremiums.lower;
};

// Using SELECTABLE_PLAN_TYPES constant while removing HSA and HRA
const remove = ['hsa', 'hra'];
const REVIEW_NONSELECTABLE_PLAN_TYPES = ALL_NONSELECTABLE_PLAN_TYPES.filter((item) => !remove.includes(item));

export interface ReviewSectionProps {
  healthPlan: Recommendation | Record<string, never>;
  hsaPlan: SupplementalPlan | null;
  hraPlan: SupplementalPlan | null;
  sihraContribution: number;
  supplementalPlans: SupplementalPlansFullHousehold;
  selectedSupplementalPlans: SelectedSupplementalPlans;
  supplementalPremiums: USDRangeData;
  recommendedHsaContribution: number;
  isFbsEnabled: boolean;
  handleSupplementalChange: (e: React.MouseEvent<HTMLButtonElement>) => void;
  handleHealthChange: (e: React.MouseEvent<HTMLButtonElement>) => void;
  handleHsaChange: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const ReviewSection = ({
  healthPlan,
  hsaPlan,
  hraPlan,
  sihraContribution = 0,
  supplementalPlans,
  selectedSupplementalPlans,
  supplementalPremiums,
  recommendedHsaContribution,
  isFbsEnabled,
  handleSupplementalChange,
  handleHealthChange,
  handleHsaChange,
}: ReviewSectionProps) => {
  const [showEdu, setShowEdu] = useState(false);

  const { retrieveContentfulData } = useTextContext();
  const { useContributionTerm } = usePremiumContext();

  const isHealthPlanSelected = !isEmpty(healthPlan);
  const isHsaEligible = isHealthPlanSelected ? healthPlan.plan.hsa_eligible : false;
  const isHraEligible = isHealthPlanSelected ? healthPlan.plan.hra_eligible : false;

  const planTypes = Object.keys(supplementalPlans);
  const selectedPlanTypes = Object.entries(selectedSupplementalPlans);
  const healthPremiums = isHealthPlanSelected ? healthPlan.costs.effective_premium : 0;
  const monthlyPremiumTotal = getTotalPremiums(healthPremiums, supplementalPremiums);
  const addCarrier = supplementalPlans.life?.plans[0]?.carrier_name || '';

  const getPerDeductionHeader = (annualValue: number, deductions: number) => (
    <PremiumPerDeduction
      annualPremium={annualValue}
      deductions={deductions}
      currency
      precision={annualValue === 0 ? 0 : 2}
      size="small"
      fontWeight="bold"
    />
  );

  const getHeader = (
    value: number,
    hideZeroValue: boolean = false,
    payPeriodType: 'pay_period' | 'yearly' = 'pay_period',
  ): React.ReactNode =>
    value === 0 && hideZeroValue ? null : (
      <div className="value">
        <NumberWithPayPeriod
          value={value}
          size="small"
          fontWeight="bold"
          precision={value === 0 ? 0 : 2}
          payPeriodType={payPeriodType}
        />
      </div>
    );

  // Legacy header
  let premiumHeader = getHeader(monthlyPremiumTotal);
  let hsaHeader = getHeader(recommendedHsaContribution / 12);

  if (healthPlan?.costs?.annual_premium_deductions) {
    // If number of deductions differ, disable the total atop the premiums card
    const deductions = Object.values(selectedSupplementalPlans)
      .filter((p) => p !== null && p !== undefined && p.deductions_per_year > 0)
      .map((p) => p?.deductions_per_year);
    deductions.push(healthPlan.costs.annual_premium_deductions);
    const deductionsSet = [...new Set(deductions)];
    const shouldShowPremiumTotal = deductionsSet.length === 1;
    const annualPremiumTotal = monthlyPremiumTotal * 12;

    premiumHeader = shouldShowPremiumTotal
      ? getPerDeductionHeader(annualPremiumTotal, healthPlan.costs.annual_premium_deductions)
      : null;

    hsaHeader = getPerDeductionHeader(recommendedHsaContribution, healthPlan.costs.annual_premium_deductions);
  }

  const setPremiumPlans = selectedPlanTypes.filter(
    ([planType, benefit]) => benefit && SET_PREMIUM_PLAN_TYPES.includes(planType as InsuranceType),
  ) as [InsuranceType, SupplementalPlan][];

  const noSetPremiumPlans = selectedPlanTypes.filter(
    ([planType, benefit]) => benefit && NO_SET_PREMIUMS_PLAN_TYPES.includes(planType as InsuranceType),
  ) as [InsuranceType, SupplementalPlan][];

  const noPremiumPlans = planTypes
    .filter((planType) => REVIEW_NONSELECTABLE_PLAN_TYPES.includes(planType as InsuranceType))
    .map((planType) => [planType, supplementalPlans[planType]?.plans[0]]) as [
    InsuranceType,
    SupplementalPlan,
  ][];

  const showOtherBenefits = isFbsEnabled && noSetPremiumPlans.length + noPremiumPlans.length > 0;

  const showMoreBenefitsButton = noSetPremiumPlans.length + noPremiumPlans.length > 5;

  const headerTooltips = retrieveContentfulData<ContentfulType['review_section']['card_header_tooltips']>(
    'review_section.card_header_tooltips',
  );

  const contentfulSupplementalPlans = retrieveContentfulData<ContentfulSupplementalPlan[]>(
    'benefits_section_additional.supplemental_plans',
    [],
  );

  const employerPaidPlanIds = contentfulSupplementalPlans
    .map((i) => {
      if (i.is_employer_paid) {
        return i.plan_id;
      }
      return null;
    })
    .filter((i) => i);

  const getIsAutoEnrolled = (planType) => supplementalPlans[planType].plans.some((i) => i.auto_enrolled);

  const handleShowEdu = () => {
    setShowEdu((prev) => !prev);
  };

  const benefitName = (planType, custom_name) =>
    (CUSTOM_NAME_PLAN_TYPES.includes(planType as InsuranceType) && custom_name
      ? custom_name
      : retrieveContentfulData<string>(CONTENT_MAP[planType].title)) || planType;

  const carrierName = (benefit: SupplementalPlan) =>
    benefit.plan_type === 'add' ? addCarrier : benefit.carrier_name;

  const premGroupTitleField = useContributionTerm
    ? 'review_section.benefit_contributions'
    : 'review_section.card_header_tooltips.premiums.title';

  return (
    <Container>
      <ReviewGroup
        title={<Text field={premGroupTitleField} />}
        tooltip={<Text field="review_section.card_header_tooltips.premiums.text" />}
        header={premiumHeader}
      >
        {healthPlan.plan ? (
          <Card
            planType="health"
            carrier={healthPlan.plan.carrier_name}
            healthPlanName={healthPlan.plan.plan_marketing_name}
            premium={healthPlan.costs.effective_premium}
            annualPremium={healthPlan.costs.annual_premium}
            deductionsPerYear={healthPlan.costs.annual_premium_deductions}
            handleChangeButton={handleHealthChange}
          />
        ) : (
          <Card
            planType="waived_coverage"
            carrier="Waived"
            healthPlanName="No Health Plan"
            // TODO: add `waived` to contentful for Spanish translation
            handleChangeButton={handleHealthChange}
          />
        )}
        {setPremiumPlans.length > 0 &&
          setPremiumPlans.map(([planType, benefit]) => (
            <Card
              key={benefit.plan_type}
              planType={planType}
              carrier={benefit.carrier_name}
              healthPlanName={benefitName(planType, benefit.name)}
              premium={benefit.monthly_premium?.data as number}
              annualPremium={(benefit.monthly_premium?.data as number) * 12}
              deductionsPerYear={benefit.deductions_per_year}
              handleChangeButton={handleSupplementalChange}
              isEmployerPaid={employerPaidPlanIds.includes(benefit.external_id)}
            />
          ))}
      </ReviewGroup>

      {isHsaEligible && (
        <ReviewGroup
          title={headerTooltips.hsa_contributions.title}
          tooltip={headerTooltips.hsa_contributions.text}
          header={hsaHeader}
        >
          <Card
            planType="hsa"
            carrier={hsaPlan ? hsaPlan.carrier_name : ''}
            healthPlanName={hsaPlan ? hsaPlan.name : 'HSA'}
            premium={recommendedHsaContribution / 12}
            annualPremium={recommendedHsaContribution}
            deductionsPerYear={healthPlan.costs.annual_premium_deductions}
            handleChangeButton={handleHsaChange}
          />
        </ReviewGroup>
      )}

      {isHraEligible && (
        <ReviewGroup
          title={headerTooltips.hra_contributions.title}
          tooltip={headerTooltips.hra_contributions.text}
          header={getHeader(0, false)}
        >
          <Card
            planType="hra"
            carrier={hraPlan ? hraPlan.carrier_name : ''}
            healthPlanName={hraPlan ? hraPlan.name : 'HRA'}
            // TODO: add `HRA` to contentful for Spanish translation
            premium={0}
            handleChangeButton={handleHsaChange}
          />
        </ReviewGroup>
      )}

      {sihraContribution > 0 && (
        <ReviewGroup
          title={headerTooltips.employer_contributions.title}
          tooltip={headerTooltips.employer_contributions.text}
          header={getHeader(
            // DO WE WANT ONE OR THE OTHER, OR TAKE THE SUM?
            sihraContribution ||
              healthPlan.plan.spending_account_contributions.hra_annual.employer_contribution_amount ||
              0,
            false,
            'yearly',
          )}
        >
          <Card
            planType="hra"
            carrier={hraPlan ? hraPlan.carrier_name : ''}
            healthPlanName={hraPlan ? hraPlan.name : 'HRA'}
            // TODO: add `HRA` to contentful for Spanish translation
            premium={0}
          />
        </ReviewGroup>
      )}

      {showOtherBenefits && (
        <ReviewGroup
          title={headerTooltips.other_benefits.title}
          tooltip={headerTooltips.other_benefits.text}
          header={getHeader(0, true)}
        >
          {noSetPremiumPlans.length > 0 &&
            noSetPremiumPlans.map(([planType, benefit]) => (
              <Card
                key={planType}
                planType={planType}
                carrier={carrierName(benefit)}
                healthPlanName={benefitName(planType, benefit.name)}
                premium={benefit.monthly_premium?.data as number}
                handleChangeButton={handleSupplementalChange}
                autoEnrolled={getIsAutoEnrolled(planType)}
                planCount={supplementalPlans[planType]?.plans.length}
                isEmployerPaid={employerPaidPlanIds.includes(benefit.external_id)}
              />
            ))}
          <div
            className={`${showEdu || !showMoreBenefitsButton ? 'showEdu' : 'hideEdu'} additional-benefits`}
          >
            {noPremiumPlans.length > 0 &&
              noPremiumPlans.map(([planType, benefit]) => {
                let planTitle: JSX.Element | string = benefit.name;

                if (supplementalPlans[benefit.plan_type].plans.length <= 1) {
                  planTitle = benefitName(planType, benefit.name);
                }
                if (
                  // Display plan types rather than plan names for non-selectable benefits if there is more than 1 available plan
                  isHsaEligible &&
                  (benefit.plan_type === 'fsa' || benefit.plan_type === 'fsa_medical')
                ) {
                  // Display alternate title for FSA when health plan is HSA eligible
                  planTitle = <Text field={CONTENT_MAP[benefit.plan_type].titleAlt} />;
                } else {
                  // Display data-driven names when there are several selectable benefits
                  planTitle = <Text field={CONTENT_MAP[benefit.plan_type].title} />;
                }

                return (
                  <Card
                    key={benefit.plan_type}
                    planType={benefit.plan_type as InsuranceType}
                    carrier={benefit.carrier_name}
                    healthPlanName={planTitle}
                    premium={benefit.monthly_premium?.data as number}
                    autoEnrolled={getIsAutoEnrolled(planType)}
                    planCount={supplementalPlans[planType]?.plans.length}
                  />
                );
              })}
          </div>
          {showMoreBenefitsButton && (
            <ShowMoreButton className="no-print">
              <Button
                stretch
                size="small"
                buttonType="transparent"
                onClick={handleShowEdu}
                iconRight={showEdu ? 'CaretUp' : 'CaretDown'}
              >
                {showEdu ? 'Hide' : 'Show All'}
              </Button>
            </ShowMoreButton>
          )}
        </ReviewGroup>
      )}
    </Container>
  );
};

export default ReviewSection;
