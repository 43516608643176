import React from 'react';

import { SERVICE_TYPES } from 'Containers/ResultPage/constants';
import { ViewPlanPageCard } from 'Containers/ViewPlanPage/ViewPlanPageCard';
import { useTextContext } from 'Contexts/textContext';
import { IconBlockProps } from 'DesignLibrary/molecules/IconBlock';
import Plan from 'Models/plan';
import { Recommendation } from 'Types/entities';

import { renderCosts } from '../_shared';

interface DoctorVisitsCardProps {
  client: string;
  recommendation: Recommendation;
}

export const DoctorVisitsCard: React.FC<DoctorVisitsCardProps> = ({ client, recommendation }) => {
  const { retrieveContentfulData } = useTextContext();

  const title = retrieveContentfulData<string>(
    'health_details_section.plan_details.doctor_visits_card_title',
  );
  const icon: IconBlockProps = {
    icon: 'FirstAidKit',
    color: 'blue',
  };

  const plan = new Plan(recommendation);

  return (
    <ViewPlanPageCard title={title} icon={icon}>
      {Object.values(SERVICE_TYPES).map((costType, index) =>
        renderCosts(plan, client, costType, false, index),
      )}
    </ViewPlanPageCard>
  );
};
