import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Tooltip as ChakraTooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { isObject, snakeCase } from 'lodash';
import React, { ReactNode, useRef, useState } from 'react';

import { RichTextContent } from 'ContentfulDefaults/types/_shared';

import { DesktopTooltip, MobileTooltip } from './styled';
import { useViewport } from '../../context';
import { COLORS, SHADOW } from '../../vars';
import { Icon } from '../Icon';
import { Paragraph, TooltipUnderlineWrapper } from '../typography';

export interface TooltipProps {
  id?: string;
  width?: number;
  label: TooltipContentProps;
  children?: ReactNode;
  useUnderline?: boolean;
  isInteractive?: boolean;
  size?: 'normal' | 'small'; // Tooltip icon size
  mobileOverrides?: Record<string, unknown>;
  desktopOverrides?: Record<string, unknown>;
  isRichText?: boolean;
}

export interface FormattedTooltipProps {
  text: string | React.ReactNode;
  title?: string | React.ReactNode;
  underline?: string | React.ReactNode;
  note?: string | React.ReactNode;
}

export type TooltipContentProps = string | FormattedTooltipProps | RichTextContent | React.ReactNode;

export const Tooltip = ({
  id,
  width = 256,
  label = 'Missing tooltip property',
  isInteractive = false,
  useUnderline = false,
  children,
  mobileOverrides,
  desktopOverrides,
  size = 'normal',
  isRichText = false,
  ...rest
}: TooltipProps): JSX.Element => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { device } = useViewport();
  const tooltipRef = useRef<HTMLSpanElement>(null);

  const [isDesktopTooltipOpen, setIsDesktopTooltipOpen] = useState(false);
  // A11y requirement to have key handler
  const handleKeyDown = (e: React.KeyboardEvent<HTMLSpanElement>) => {
    if (!isOpen && e.key === ' ') {
      onOpen();
    }
  };

  // Destructure tooltip object. If tooltip is string, assign 'text' key to hold string
  // Tooltip expects an object with, at minimum, a text entry.  Because of the destructuring, typescript expects title, note, and underline entries in the object

  const { text, title, note, underline } =
    isObject(label) && !isRichText
      ? (label as FormattedTooltipProps)
      : {
          text: label,
          title: null,
          note: null,
          underline: null,
        };

  const outerTextLabel = `${id || snakeCase(text as string)}-outer-tooltip-text`;
  const innerTextLabel = `${id || snakeCase(text as string)}-inner-tooltip-text`;
  const innerTitleLabel = `${id || snakeCase(text as string)}-inner-tooltip-title`;

  // Determine what is displayed in the tooltip
  const renderChildren = () => {
    const child = (
      <span
        onClick={() => {
          setIsDesktopTooltipOpen(true);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            setIsDesktopTooltipOpen(true);
          }
        }}
        onBlur={() => {
          setIsDesktopTooltipOpen(false);
        }}
        aria-labelledby={`${outerTextLabel} ${innerTitleLabel} ${innerTextLabel}`}
      >
        <span id={outerTextLabel} tabIndex={-1}>
          {children || <Icon type="Question" size={size} color="--text-black" />}
        </span>
      </span>
    );

    const rendered = useUnderline ? (
      <TooltipUnderlineWrapper
        onClick={() => {
          setIsDesktopTooltipOpen(true);
        }}
        onBlur={() => {
          setIsDesktopTooltipOpen(false);
        }}
        aria-labelledby={`${outerTextLabel} ${innerTitleLabel} ${innerTextLabel}`}
      >
        {underline}
      </TooltipUnderlineWrapper>
    ) : (
      child
    );
    return rendered;
  };

  const tooltipWidth = isInteractive ? null : { width };

  // RENDERING FLOW
  // 1. Don't render interactive tooltip on Tablet or Mobile device sizes
  if (children && device !== 'desktop' && isInteractive) {
    return children as JSX.Element;
  }

  // 2. Render mobile tooltip view if device is NOT desktop && NOT isInteractive
  if (device !== 'desktop') {
    return (
      <span {...rest}>
        <span
          role="button"
          aria-describedby={id}
          tabIndex={0}
          className="tooltip-icon"
          ref={tooltipRef}
          onClick={onOpen}
          onKeyDown={handleKeyDown}
        >
          {renderChildren()}
        </span>
        <Modal isOpen={isOpen} onClose={onClose} {...mobileOverrides}>
          <ModalOverlay />
          <ModalContent p={0}>
            <MobileTooltip>
              {title && (
                <div className="title">
                  <Paragraph weight="bold">{title}</Paragraph>
                </div>
              )}
              {text && <Paragraph>{text}</Paragraph>}
              {note && <Paragraph>{note}</Paragraph>}
              <div className="close">
                <ModalCloseButton />
              </div>
            </MobileTooltip>
          </ModalContent>
        </Modal>
      </span>
    );
  }

  // 3. Else, return the Chakra tooltip
  return (
    <ChakraTooltip
      {...rest}
      isOpen={isDesktopTooltipOpen || undefined}
      label={
        <DesktopTooltip isInteractive={isInteractive}>
          {title && (
            <Paragraph id={innerTitleLabel} weight="bold" size="small">
              {title}
            </Paragraph>
          )}
          {text && (
            <Paragraph id={innerTextLabel} weight={isInteractive ? 'bold' : 'normal'} size="small">
              {text}
            </Paragraph>
          )}
          {note && <Paragraph size="small">{note}</Paragraph>}
        </DesktopTooltip>
      }
      bg={COLORS.gray.black}
      shouldWrapChildren={!isInteractive}
      p={0}
      {...tooltipWidth}
      {...desktopOverrides}
      sx={{
        borderRadius: '6px',
        boxShadow: SHADOW.card,
        lineHeight: 1.25,
      }}
    >
      {renderChildren()}
    </ChakraTooltip>
  );
};
