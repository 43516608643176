import { DoctorApi } from 'Types/entities';

import { sendInsightsEvent } from './insights';

const COMMON_MEDICAL_PREFIXES = ['Dr.', 'Dr', 'Doctor', 'MD', 'PhD', 'Ph.D.'];

interface DoctorParams {
  name: string;
  address: string;
  distance: string;
  page: number;
}

export const stripCommonPrefixes = (name: string) =>
  name
    .split(' ')
    .filter((word: string) => !COMMON_MEDICAL_PREFIXES.includes(word))
    .join(' ');

export const getDoctor = async (params: DoctorParams): Promise<DoctorApi | null> => {
  const name = stripCommonPrefixes(params.name);
  if (name == null || name === '') {
    return null;
  }

  const response = await fetch(
    `${window._env_.REACT_APP_DOC_FINDER_URL}/doctors?address=${params.address}&name=${name}&distance=${params.distance}&page=${params.page}&page_size=20`,
    {
      method: 'GET',
    },
  );

  if (!response.ok) {
    const message = await response.text();
    sendInsightsEvent(null, 'search_provider_api_failed', { message, status: response.status });
    throw new Error(`Doc finder error: ${message}`);
  }

  return response.json();
};
