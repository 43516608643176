import React, { FC } from 'react';

import { RiskAssessmentAnswer } from 'Containers/ProfilePage/types';
import { Paragraph } from 'DesignLibrary/atoms';
import { HiddenLegend } from 'DesignLibrary/atoms/ToggleList/styled';
import RichText from 'Shared/RichText';
import Text from 'Shared/Text';

import { AnswerContainer, AnswerLabel, AnswerPseudo } from './styled';

const groupNameValues = ['risk_question_1', 'risk_question_2'];
type GroupName = (typeof groupNameValues)[number];

export interface AnswerToggleProps {
  labelId: string;
  selectedValue: RiskAssessmentAnswer;
  groupName: GroupName;
  className?: string;
  handleSelectA: () => void;
  handleSelectB: () => void;
}

export const AnswerToggle: FC<AnswerToggleProps> = ({
  labelId,
  selectedValue,
  groupName,
  className,
  handleSelectA,
  handleSelectB,
}) => {
  const pseudoAnswer_id_option_a = `${groupName}-option-a`;
  const pseudoAnswer_id_option_b = `${groupName}-option-b`;

  return (
    <AnswerContainer role="radiogroup">
      <HiddenLegend aria-describedby={labelId} />
      <AnswerLabel data-testid="pseudo-plan_a">
        <input
          aria-labelledby={pseudoAnswer_id_option_a}
          type="radio"
          data-testid="plan_a"
          className={`${className}-plan_a`}
          checked={selectedValue === 'plan_a'}
          value="plan_a"
          name={groupName}
          onChange={handleSelectA}
        />
        <AnswerPseudo
          id={pseudoAnswer_id_option_a}
          className="pseudo-answer"
          aria-hidden="true"
          selected={selectedValue === 'plan_a'}
        >
          <Paragraph color="--text-black">
            {' '}
            <RichText noWrapper field="profile_section.risk.option_one_header" />
          </Paragraph>
          <Paragraph>
            <Text field="profile_section.risk.option_one_text" />
          </Paragraph>
        </AnswerPseudo>
      </AnswerLabel>
      <AnswerLabel data-testid="pseudo-plan_b">
        <input
          aria-labelledby={pseudoAnswer_id_option_b}
          type="radio"
          data-testid="plan_b"
          className={`${className}-plan_b`}
          checked={selectedValue === 'plan_b'}
          value="plan_b"
          name={groupName}
          onChange={handleSelectB}
        />
        <AnswerPseudo
          id={pseudoAnswer_id_option_b}
          className="pseudo-answer"
          aria-hidden="true"
          selected={selectedValue === 'plan_b'}
        >
          <Paragraph color="--text-black">
            <RichText noWrapper field="profile_section.risk.option_two_header" />
          </Paragraph>
          <Paragraph>
            <Text field="profile_section.risk.option_two_text" />
          </Paragraph>
        </AnswerPseudo>
      </AnswerLabel>
    </AnswerContainer>
  );
};
