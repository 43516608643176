import { ContentfulTopLevelNavigationSection } from 'ContentfulDefaults/types/navigation';

export const navigation: {
  top_level_navigation: ContentfulTopLevelNavigationSection;
} = {
  top_level_navigation: {
    contentful_name: 'Top Level Navigation (DEFAULT DO NOT CHANGE)',
    desktop_pages: {
      hsa: 'HSA',
      taxSavings: 'Tax Savings',
      health: 'Salud',
      review: 'Revisión',
      profile: 'Perfil',
      eligibility: 'Elegibilidad',
      supplemental: 'Suplementario',
    },
    mobile_pages: {
      hsa: 'HSA',
      risk: 'Riesgo',
      review: 'Revisión',
      results: 'Tus resultados',
      planning: 'Planificación familiar',
      'you&family': 'Usted y familia',
      eligibility: 'Elegibilidad',
      houseIncome: 'Ingresos del hogar',
      medications: 'Medicamentos',
      utilization: 'Utilización',
      supplemental: 'Hecho suplementario',
      capacityToPay: 'Capacidad de pago',
    },
    menu_icon: 'https://picwellapppublicdata.s3.amazonaws.com/contentful/navigationbar/menu-icon.svg',
    close_button: 'https://picwellapppublicdata.s3.amazonaws.com/contentful/navigationbar/close-btn.svg',
    security_header: {
      heading: 'Su seguridad es nuestra prioridad.',
      lockIcon: 'https://picwellapppublicdata.s3.amazonaws.com/contentful/navigationbar/security-lock.svg',
      badgeIcon: 'https://picwellapppublicdata.s3.amazonaws.com/contentful/navigationbar/security.svg',
    },
    security_text: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'Picwell trabaja duro para garantizar la seguridad de sus datos. Protegemos el privacidad de su información y nunca compartirá sus datos con ningún\ntercero sin su permiso. Para más información, por favor\nrevise nuestra ',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [
                {
                  type: 'bold',
                },
              ],
              value: 'Política de Privacidad',
              nodeType: 'text',
            },
            {
              data: {},
              marks: [],
              value: '.',
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    },
    premium_select: {
      weekly: 'Semanalmente (/sem)',
      monthly: 'Mensualmente (/mens)',
      biWeekly: 'Bisemanalmente (/bisem)',
      semiMonthly: 'Quincenalmente (/qnal)',
    },
    premium_select_with_abbreviation: {
      weekly: {
        display: 'Semanalmente',
        abbreviation: '(/sem)',
      },
      monthly: {
        display: 'Mensualmente',
        abbreviation: '(/mens)',
      },
      biWeekly: {
        display: 'Bisemanalmente',
        abbreviation: '(/bisem)',
      },
      semiMonthly: {
        display: 'Quincenalmente',
        abbreviation: '(/qnal)',
      },
    },
    pay_period_options: {
      weekly: {
        display: 'Semanalmente',
        abbreviation: '(/sem)',
      },
      monthly: {
        display: 'Mensualmente',
        abbreviation: '(/mens)',
      },
      bi_weekly: {
        display: 'Bisemanalmente',
        abbreviation: '(/bisem)',
      },
      semi_monthly: {
        display: 'Quincenalmente',
        abbreviation: '(/qnal)',
      },
    },
    mobile_nav_instructions:
      'Navigate by clicking on any of the unlocked items. Update your profile at any time to update your health and supplemental results.',
    mobile_nav_close_btn: 'Close',
    pay_period_label: 'How often do you get paid?',
    language_toggle_label: 'Choose your preferred language',
    alex_id_login_btn: 'Iniciar Sesión',
    alex_id_signup_btn: 'Inscribir',
    alex_id_logout_btn: 'Cerrar Sesión',
    alex_id_account_btn: 'Cuenta',
    alex_id_reset_password_btn: 'Reset Password',
    alex_id_update_btn: 'Update my account',
    alex_id_delete_btn: 'Delete my account',
    alex_id_google: 'Signed in with Google',
    alex_id_help: 'In order to delete or change your account, email:',
    alex_id_reset_password_text: 'Acabamos de enviarse un correo electrónico para restablecer su contraseña.',
    alex_id_reset_password_error: 'Hubo un error. Intense de nuevo más tarde.',
  },
};

export default navigation;
