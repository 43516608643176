import React, { FC, ReactElement } from 'react';

import { SubtextType } from 'ContentfulDefaults/types/_shared';
import { useFeatureFlagContext } from 'Contexts/featureFlagContext';
import { useTextContext } from 'Contexts/textContext';
import { PageHeader } from 'DesignLibrary/molecules/PageHeader';
import Text from 'Shared/Text';

export interface ContentfulHeaderWrapperProps {
  sectionKey: 'hsa' | 'benefits_section_additional' | 'review_section';
  animate: boolean;
}

export interface ContentfulHeaderWrapperForProfileProps {
  useIntegratedCustomerSubtext: boolean;
  isReturnUser: boolean;
  animate: boolean;
}

export interface ContentfulHeaderWrapperForHealthSectionProps {
  numAvailablePlans: number;
  hideSubtext: boolean;
  useHeaderAlternateText: boolean;
  isReturnUser: boolean;
  animate: boolean;
  showPreviousSelectedPlanMessage: boolean;
  hidePlanScoringText: boolean;
}

export const ContentfulHeaderWrapper: FC<ContentfulHeaderWrapperProps> = ({ sectionKey, animate }) => {
  const { retrieveContentfulData } = useTextContext();

  const header: string = retrieveContentfulData<string>(`${sectionKey}.page_header.text`);
  const subtext = <Text field={`${sectionKey}.page_header.subtext`} />;

  return <PageHeader header={header} subtext={subtext} animate={animate} />;
};

export const ContentfulHeaderWrapperForProfile: FC<ContentfulHeaderWrapperForProfileProps> = ({
  useIntegratedCustomerSubtext,
  isReturnUser,
  animate,
}) => {
  const { retrieveContentfulData } = useTextContext();
  const { is_rue_landing_page_enabled } = useFeatureFlagContext();

  const headerPath =
    isReturnUser && is_rue_landing_page_enabled
      ? 'profile_section.page_header.text_return_user'
      : 'profile_section.page_header.text';
  const header: string = retrieveContentfulData<string>(headerPath);

  let subtextType: keyof SubtextType = 'subtext';
  if (useIntegratedCustomerSubtext) {
    subtextType = 'subtext_integrated';
  } else if (isReturnUser && is_rue_landing_page_enabled) {
    subtextType = 'subtext_return_user';
  }
  const subtext = <Text field={`profile_section.page_header.${subtextType}`} />;

  return <PageHeader header={header} subtext={subtext} animate={animate} />;
};

export const ContentfulHeaderWrapperForHealthSection: FC<ContentfulHeaderWrapperForHealthSectionProps> = ({
  numAvailablePlans,
  hideSubtext,
  useHeaderAlternateText,
  isReturnUser,
  animate,
  showPreviousSelectedPlanMessage,
  hidePlanScoringText,
}) => {
  const { retrieveContentfulData } = useTextContext();

  const headerPath = useHeaderAlternateText
    ? 'health_section.page_header.text_alternate'
    : 'health_section.page_header.text';
  const header = retrieveContentfulData<string>(headerPath);

  // Count of plans dictates between "default" or "alternate" variants.
  // by default text is pluralized. alternate variants will use singular form.
  // ex. "Here are the N plans for you" vs "There is N plan for you".
  const usePluralText = numAvailablePlans !== 1;

  let subtextType: keyof SubtextType;
  if (showPreviousSelectedPlanMessage) {
    subtextType = usePluralText ? 'previous_selected_plan_text' : 'previous_selected_plan_text_alternate';
  } else if (hidePlanScoringText) {
    subtextType = usePluralText ? 'subtext_hide_plan_scoring' : 'subtext_hide_plan_scoring_alternate';
  } else {
    subtextType = usePluralText ? 'subtext' : 'subtext_alternate';
  }

  const subtext = (
    <Text field={`health_section.page_header.${subtextType}`} vars={{ x: numAvailablePlans }} />
  );

  let welcomeBackText: ReactElement<Text> | string = '';
  if (isReturnUser) {
    welcomeBackText = <Text field="health_section.page_header.text_welcome" />;
  }

  return (
    <PageHeader
      hideSubtext={hideSubtext}
      header={header}
      subtext={subtext}
      animate={animate}
      welcomeBackText={welcomeBackText}
    />
  );
};
