import React, { useState, forwardRef, Ref } from 'react';

import { Persona } from 'Containers/HsaPage/types';
import { ChartLegendTerm } from 'ContentfulDefaults/types/hsa';
import { useTextContext } from 'Contexts/textContext';
import { Button, Paragraph } from 'DesignLibrary/atoms';
import TextInput from 'DesignLibrary/atoms/inputs/TextInput';
import { Spinner } from 'DesignLibrary/atoms/Spinner';
import { TooltipCaps } from 'DesignLibrary/molecules';
import InfoBar from 'DesignLibrary/molecules/InfoBar';
import Text from 'Shared/Text';
import { AriaHidden } from 'Utils/accessibility';
import { toDollars } from 'Utils/helpers';

import { EditableBalance, PersonaToggle, Question, SpinnerContainer, AgeDisclaimer } from './styled';
import { GraphLine, Line, Legend } from '../styled';

export interface LongtermGraphProps {
  retirementCost: number;
  forecastedHsaBalance: number;
  isForecastLoading: boolean;
  isRetirementForecastLoading: boolean;
  currentHsaBalance: number | null;
  persona: Persona;
  isEditing: boolean;
  isPolicyholderOverRetirementAge: boolean;
  policyholderLifeExpectancy: number;
  handleHsaBalanceAndPersona: (balance: number, persona: Persona) => void;
  setIsEditing: (val: boolean) => void;
}

// click LongTermDetails > Edit Button
// focus on LongTermForecast > TextInput

export const LongTermForecast = forwardRef(
  (
    {
      retirementCost,
      forecastedHsaBalance,
      isForecastLoading,
      isRetirementForecastLoading,
      currentHsaBalance,
      persona,
      isEditing,
      isPolicyholderOverRetirementAge,
      policyholderLifeExpectancy,
      handleHsaBalanceAndPersona,
      setIsEditing,
    }: LongtermGraphProps,
    inputRef: Ref<HTMLInputElement>,
  ) => {
    const [currentValue, setCurrentValue] = useState<string>(
      currentHsaBalance ? currentHsaBalance.toString() : '',
    );

    const [currentPersona, setCurrentPersona] = useState<Persona>(persona);

    const { retrieveContentfulData } = useTextContext();

    // Graph Percentages
    const retirementPercentage = Math.min((retirementCost / forecastedHsaBalance) * 100, 100);
    const forecastedPercentage = Math.min((forecastedHsaBalance / retirementCost) * 100, 100);

    // Formatted Values
    const retirementDisplay = toDollars(retirementCost);
    const forecastedDisplay = toDollars(forecastedHsaBalance);

    const handleUpdateHsaBalance = (e) => {
      const value = e.target.value.replace(/,/g, ''); // revert comma separated value

      if (Number.isInteger(parseInt(value, 10)) || value === '') {
        // only change value if value can be parsed as an integer
        setCurrentValue(value);
      }
    };

    const handleSubmit = () => {
      setIsEditing(false);
      handleHsaBalanceAndPersona(currentValue !== '' ? parseInt(currentValue, 10) : 0, currentPersona);
    };

    const renderAgeDisclaimer = () => {
      if (isPolicyholderOverRetirementAge) {
        return (
          <InfoBar
            text={
              <Text
                field="hsa.interactive_section.policyholder_age_disclaimer"
                vars={{ x: policyholderLifeExpectancy }}
              />
            }
          />
        );
      }
      return null;
    };

    const legendTerms = retrieveContentfulData<ChartLegendTerm[]>(
      'hsa.interactive_section.retirement_savings_chart_legend',
      [],
    );

    const legendHiddenValues = [forecastedDisplay, retirementDisplay];

    const legend = legendTerms.map((currElement: ChartLegendTerm, index: number) => (
      <Legend key={currElement.title} type={currElement.type}>
        <TooltipCaps
          tooltip={{
            title: currElement.title,
            text: currElement.text,
          }}
          keyHint={`legend-term-${currElement.title}`}
        >
          <AriaHidden>{legendHiddenValues[index]}</AriaHidden>
          {currElement.underline}
        </TooltipCaps>
      </Legend>
    ));

    let graphAltText = ``;
    legendTerms.forEach((currElement, index) => {
      graphAltText += `${legendHiddenValues[index]} ${currElement.title},`;
    });

    const goalGraphLine = (
      <GraphLine aria-hidden>
        <Line percent={retirementPercentage} type="goal" position="end">
          <span>{retirementDisplay}</span>
        </Line>
      </GraphLine>
    );

    const savingsGraphLine = (
      <GraphLine aria-hidden>
        <Line percent={forecastedPercentage} type="savings" position="end">
          <span>{forecastedDisplay}</span>
        </Line>
      </GraphLine>
    );

    const hsaBalanceQuestionLabel = 'hsa-balance-question-label';

    const editableBalanceAndPersona = (
      <EditableBalance>
        <section className="question-wrapper">
          <Question>
            <div className="title">
              <Paragraph id={hsaBalanceQuestionLabel} weight="bold" color="--text-black">
                <Text field="hsa.interactive_section.hsa_balance_question" />
              </Paragraph>
            </div>
            <div className="input-wrapper">
              <TextInput
                labelId={hsaBalanceQuestionLabel}
                inputType="currency"
                inputMode="numeric"
                stretch
                placeholder="$10,000"
                handleChange={handleUpdateHsaBalance}
                value={currentValue || '0'}
                name="hsaBalanceInput"
                required
                ref={inputRef}
              />
            </div>
            <aside className="note">
              <Paragraph id="hsa-balance-question-approximation" size="mini">
                <Text field="hsa.interactive_section.hsa_balance_question_approximation" />
              </Paragraph>
            </aside>
          </Question>
          <Question className="persona-question">
            <div className="title">
              <Paragraph id="persona-question-label" weight="bold" color="--text-black">
                <Text field="hsa.interactive_section.persona_question" />
              </Paragraph>
            </div>
            <fieldset className="persona-toggle" role="radiogroup">
              <legend aria-labelledby="persona-question-label" />
              <div className="persona-toggle-helper">
                <PersonaToggle>
                  <input
                    aria-labelledby="spender-option-id"
                    type="radio"
                    checked={currentPersona === 'spender'}
                    value="plan_a"
                    name="persona-option-a"
                    onChange={() => setCurrentPersona('spender')}
                  />
                  <div id="spender-option-id" className="pseudo-answer" aria-hidden="true">
                    <Paragraph
                      weight="bold"
                      size="small"
                      color={currentPersona === 'spender' ? '--text-black' : '--text-gray'}
                    >
                      <Text field="hsa.interactive_section.spender_persona" />
                    </Paragraph>
                    <Paragraph>
                      <Text field="hsa.interactive_section.spender_persona_detail" />
                    </Paragraph>
                  </div>
                </PersonaToggle>

                <PersonaToggle>
                  <input
                    aria-labelledby="super-saver-option-id"
                    type="radio"
                    checked={currentPersona === 'super_saver'}
                    value="plan_a"
                    name="persona-option-a"
                    onChange={() => setCurrentPersona('super_saver')}
                  />
                  <div id="super-saver-option-id" className="pseudo-answer" aria-hidden="true">
                    <Paragraph
                      weight="bold"
                      size="small"
                      color={currentPersona === 'super_saver' ? '--text-black' : '--text-gray'}
                    >
                      <Text field="hsa.interactive_section.super_saver_persona" />
                    </Paragraph>
                    <Paragraph>
                      <Text field="hsa.interactive_section.super_saver_persona_detail" />
                    </Paragraph>
                  </div>
                </PersonaToggle>
              </div>
            </fieldset>
          </Question>
        </section>
        <section className="button-wrapper">
          <Button buttonType="primary" color="yellow" onClick={handleSubmit}>
            <Text field="hsa.interactive_section.save_answers" />
          </Button>
        </section>
      </EditableBalance>
    );

    return isEditing ? (
      editableBalanceAndPersona
    ) : (
      <>
        <AgeDisclaimer>{renderAgeDisclaimer()}</AgeDisclaimer>
        <section className="chart-container" role="img" aria-label={graphAltText}>
          {savingsGraphLine}
          {goalGraphLine}
          {(isForecastLoading || isRetirementForecastLoading) && (
            <SpinnerContainer>
              <Spinner color="--colors-gray-200" />
            </SpinnerContainer>
          )}
        </section>
        <aside className="legend">{legend}</aside>
      </>
    );
  },
);

LongTermForecast.displayName = 'LongTermForecast';
