import styled from 'styled-components';

import { InputTextStyle } from '../../typography';

export const SelectContainer = styled.div`
  ${InputTextStyle}
  max-width: 320px;
  width: 100%;
  height: var(--sizing-xl);
  position: relative;
  overflow: hidden;
  background: none;

  &:after {
    content: '';
    display: block;
    position: absolute;
    right: var(--sizing-sm);
    top: 50%;
    transform: translateY(-50%);
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: var(--text-black) transparent transparent transparent;
    pointer-events: none;
  }
`;

export const Select = styled.select`
  -webkit-appearance: none;
  appearance: none;
  outline: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--border-radius-xs);
  padding: 0 var(--sizing-md) 0 var(--sizing-sm);
  border: 2px solid var(--text-black);
  background: var(--background-white);
  line-height: 1.5;
  text-overflow: ellipsis;

  &::-ms-expand {
    display: none;
  }

  &:disabled {
    background: var(--background-gray);
  }

  &:hover {
    border: 2px solid var(--primary-blue);
  }

  &:focus {
    outline: none;
    border-color: transparent;
    box-shadow: 0 0 0 2px var(--primary-blue) inset;
  }
`;
