import React from 'react';

import { Persona } from 'Containers/TaxSavingsPage/types';
import { Paragraph, RadioPseudo } from 'DesignLibrary/atoms';
import { HiddenLegend } from 'DesignLibrary/atoms/ToggleList/styled';

import { PersonaContainer, PersonaLabel, PersonaPseudo } from './styled';

interface PersonaItemProps {
  type: Persona;
  selectedValue: Persona;
  groupName: string;
  children: string;
  handleSelect: (persona: Persona) => void;
}

export interface PersonaToggleProps {
  labelId: string;
  selectedValue: Persona;
  handleSelect: (persona: Persona) => void;
}

const PersonaItem = ({ type, selectedValue, groupName, children, handleSelect }: PersonaItemProps) => (
  <PersonaLabel data-testid="pseudo-spender">
    <input
      aria-labelledby={`${groupName}-${type}`}
      type="radio"
      data-testid="spender"
      className={`${groupName}-${type}`}
      checked={selectedValue === type}
      value={type}
      name={groupName}
      onChange={() => handleSelect(type)}
    />
    <PersonaPseudo
      id={`${groupName}-${type}`}
      className="pseudo-answer"
      aria-hidden="true"
      selected={selectedValue === type}
    >
      <RadioPseudo isChecked={selectedValue === type} sizing="small" color="--text-black" />
      <Paragraph size="small" color="--text-black">
        {children}
      </Paragraph>
    </PersonaPseudo>
  </PersonaLabel>
);

export const PersonaToggle = ({ labelId, selectedValue, handleSelect }: PersonaToggleProps) => {
  const groupName = 'retirement-persona-toggle';

  return (
    <PersonaContainer role="radiogroup">
      <HiddenLegend aria-labelledby={labelId} />
      <PersonaItem
        type="super_saver"
        selectedValue={selectedValue}
        handleSelect={handleSelect}
        groupName={groupName}
      >
        {/* TODO: CONTENTFUL Add Field */}
        Save and invest all of my funds for the future.
      </PersonaItem>
      <PersonaItem
        type="middle"
        selectedValue={selectedValue}
        handleSelect={handleSelect}
        groupName={groupName}
      >
        {/* TODO: CONTENTFUL Add Field */}
        Spend what I need and then save the rest.
      </PersonaItem>
      <PersonaItem
        type="spender"
        selectedValue={selectedValue}
        handleSelect={handleSelect}
        groupName={groupName}
      >
        {/* TODO: CONTENTFUL Add Field */}
        Spend all of my HSA savings on current needs.
      </PersonaItem>
    </PersonaContainer>
  );
};
