import React from 'react';

import { BenefitDefinition, BenefitGroup as BenefitGroupType } from 'Containers/CommercialRoutes/types';
import { useTextContext } from 'Contexts/textContext';

import { BenefitGroupIds } from '../../types';
import BenefitInput from '../BenefitInput';

export interface BenefitGroupProps {
  benefitGroupId: BenefitGroupIds;
  benefitGroup: BenefitGroupType;
  isHsaEligible: boolean;
  handleBenefitChange: (
    benefitGroupId: BenefitGroupIds,
    benefitId: string,
    benefit: BenefitDefinition,
  ) => void;
  deleteBenefit: (benefitGroupId: BenefitGroupIds, benefitId: string) => void;
}

const BenefitGroup = ({
  benefitGroupId,
  benefitGroup,
  isHsaEligible,
  handleBenefitChange,
  deleteBenefit,
}: BenefitGroupProps) => {
  const { retrieveContentfulData } = useTextContext();

  const labelId = `${benefitGroupId}-label`;

  const benefitInputs = Object.keys(benefitGroup.benefits).map((benefitId) => {
    const benefit = benefitGroup.benefits[benefitId];

    // If Benefit Type is 'Preventive Care', don't display it
    if (benefit.serffCode === '38') {
      return null;
    }

    return (
      <BenefitInput
        labelId={labelId}
        key={benefitId}
        benefitGroupId={benefitGroupId}
        benefitId={benefitId}
        benefit={benefit}
        handleBenefitChange={handleBenefitChange}
        deleteBenefit={deleteBenefit}
        isRemovable={benefitGroupId === 'drug'}
        isHsaEligible={isHsaEligible}
      />
    );
  });

  return (
    <>
      <h2 id={labelId}>
        {retrieveContentfulData<string>(`spousal_plan_comparison.benefit_groups.${benefitGroupId}.title`)}
      </h2>
      {benefitInputs}
    </>
  );
};

export default BenefitGroup;
