import React from 'react';

import { ContentfulInfoBars } from 'ContentfulWrappers/ContentfulInfoBars';
import { useTextContext } from 'Contexts/textContext';
import InfoBar from 'DesignLibrary/molecules/InfoBar';
import { PrimaryColors } from 'DesignLibrary/vars';
import Text from 'Shared/Text';
import { toDollars } from 'Utils/helpers';

export interface ClientDisclaimerProps {
  hasSpouse: boolean;
  maximumAvailableAnnualSihraContribution: number;
}

const ClientDisclaimer = ({
  hasSpouse,
  maximumAvailableAnnualSihraContribution: maximumContribution,
}: ClientDisclaimerProps) => {
  const { retrieveContentfulData } = useTextContext();

  const spousalDisclaimer = retrieveContentfulData<Record<string, string>>(
    'health_section.client_disclaimers.resultPageSpousal',
  );

  const sihraDisclaimer = retrieveContentfulData<Record<string, string>>(
    'health_section.client_disclaimers.resultPageSihra',
  );

  const customDisclaimers = retrieveContentfulData<Record<string, string>[]>(
    'health_section.client_disclaimers.resultPageCustom',
    [],
  );

  const showSpousalDisclaimer = spousalDisclaimer?.title && hasSpouse;
  const showSihraDisclaimer = sihraDisclaimer?.title && maximumContribution > 0;
  const showCustomDisclaimers = customDisclaimers && customDisclaimers.length > 0;

  const showDisclaimers = showSpousalDisclaimer || showSihraDisclaimer || showCustomDisclaimers;
  const formattedContribution = toDollars(maximumContribution);

  return (
    <ContentfulInfoBars sectionKey="health_section">
      {showDisclaimers ? (
        <>
          {showSpousalDisclaimer && (
            <div>
              <InfoBar
                data-testid="spousal-disclaimer"
                header={<Text field="health_section.client_disclaimers.resultPageSpousal.title" />}
                color="yellow"
                text={<Text field="health_section.client_disclaimers.resultPageSpousal.text" />}
              />
            </div>
          )}

          {showSihraDisclaimer && (
            <div>
              <InfoBar
                data-testid="sihra-disclaimer"
                color="yellow"
                header={<Text field="health_section.client_disclaimers.resultPageSihra.title" />}
                text={
                  <Text
                    field="health_section.client_disclaimers.resultPageSihra.text"
                    vars={{ x: formattedContribution }}
                  />
                }
              />
            </div>
          )}

          {showCustomDisclaimers &&
            customDisclaimers.map((disclaimer) => (
              <div key={disclaimer.title}>
                <InfoBar
                  data-testid="custom-disclaimer"
                  className="custom-disclaimer"
                  header={disclaimer.title}
                  color={disclaimer.barColor as unknown as PrimaryColors}
                  text={disclaimer.text}
                />
              </div>
            ))}
        </>
      ) : undefined}
    </ContentfulInfoBars>
  );
};

export default ClientDisclaimer;
