import React from 'react';

import { TextField } from 'Containers/App/types';
import { RichTextContent } from 'ContentfulDefaults/types/_shared';
import { ContentfulInfoBar } from 'ContentfulWrappers/ContentfulInfoBar';
import { useTextContext } from 'Contexts/textContext';
import { IconTypes, Paragraph, RadioPseudo } from 'DesignLibrary/atoms';
import { useViewport } from 'DesignLibrary/context';
import RichText from 'Shared/RichText';
import Text from 'Shared/Text';
import { Recommendation } from 'Types/entities';
import { toDollars } from 'Utils/helpers';

import { EligContainer, EligBarStyling } from './styled';

interface EligBarProps {
  isSelected: boolean;
  field: string;
  headerVars?: Record<string, unknown>;
  textVars?: Array<any>;
}

type CustomMessage = {
  color?: 'green' | 'yellow' | 'blue' | 'purple';
  icon?: IconTypes;
  text: RichTextContent;
  header: string;
};

const EligBar = ({ isSelected, field, headerVars = {}, textVars = [] }: EligBarProps) => {
  const { device } = useViewport();
  const { retrieveContentfulData } = useTextContext();

  const customMessage = retrieveContentfulData<CustomMessage>(field);

  const color = customMessage?.color || 'blue';

  return (
    <EligBarStyling color={color} isSelected={isSelected}>
      <RadioPseudo
        sizing={device === 'mobile' ? 'small' : 'normal'}
        color={`--primary-${color}`}
        isChecked={isSelected}
        iconOverride={customMessage?.icon}
      />
      <div>
        <Paragraph weight="bold" color="--text-black">
          <Text field={`${field}.header` as TextField} vars={headerVars} />
        </Paragraph>
        <Paragraph size="small">
          <RichText noWrapper field={`${field}.text` as TextField} vars={textVars} />
        </Paragraph>
      </div>
    </EligBarStyling>
  );
};

interface EligBarsProps {
  infoType: 'hsa' | 'fsa' | 'hra';
  selectedPlan: Recommendation | Record<string, never>;
  isSelectedEligible: boolean;
  employerContribution: number | null;
}

export const EligBars = ({
  infoType,
  selectedPlan,
  isSelectedEligible,
  employerContribution,
}: EligBarsProps) => {
  const prefix = `health_section.secondary_content.${infoType}_modal`;
  const eligiblePlanKey = `${prefix}.eligible_plan_message`;
  const nonEligiblePlanKey = `${prefix}.non_eligible_plan_message`;

  return (
    <EligContainer>
      {infoType !== 'fsa' &&
        (isSelectedEligible ? (
          <EligBar isSelected field={eligiblePlanKey} />
        ) : (
          <EligBar isSelected={false} field={nonEligiblePlanKey} />
        ))}
      {infoType !== 'fsa' &&
        !!employerContribution &&
        (isSelectedEligible ? (
          <EligBar
            isSelected
            field={`${prefix}.employer_contribution_selected_plan_message`}
            headerVars={{ x: toDollars(employerContribution) }}
          />
        ) : (
          <EligBar
            isSelected
            field={`${prefix}.employer_contribution_no_selected_plan_message`}
            headerVars={{ x: toDollars(employerContribution) }}
          />
        ))}

      {infoType === 'fsa' &&
        (selectedPlan?.plan?.hsa_eligible ? (
          <EligBar isSelected field={nonEligiblePlanKey} />
        ) : (
          <EligBar isSelected field={eligiblePlanKey} />
        ))}

      <ContentfulInfoBar field={`${prefix}.custom_alert_message`} />
    </EligContainer>
  );
};
