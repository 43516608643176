import React from 'react';

import { H6 } from 'DesignLibrary/atoms';

import { TableHeader, TableContainer } from './styled';

interface CompareTableProps {
  tableHeader?: string;
  children: React.ReactNode;
}

export const CompareTable = ({ tableHeader, children }: CompareTableProps) => (
  <TableContainer>
    {tableHeader && (
      <TableHeader>
        <H6 as="h3">{tableHeader}</H6>
      </TableHeader>
    )}
    <div role="table" aria-label={tableHeader}>
      {children}
    </div>
  </TableContainer>
);
