import styled from 'styled-components';

import { media } from 'DesignLibrary/global';

export const Container = styled.section`
  max-width: calc(var(--sizing-4xl) * 1.5);
  margin-bottom: var(--sizing-xl);
  ${media.TABLET`  margin-bottom: var(--sizing-2xl);`}

  .banner-content {
    button {
      margin-top: var(--sizing-sm);
    }
  }
`;
