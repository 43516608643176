import styled, { css } from 'styled-components';

import { ButtonSizes, ButtonTypes } from 'DesignLibrary/types';
import { focusUtil } from 'DesignLibrary/utils';
import { PrimaryColors, colorPaletteArray } from 'DesignLibrary/vars';

import { ButtonTextStyle } from '../typography';

export interface ButtonStyleProps {
  buttonType?: ButtonTypes;
  color?: PrimaryColors;
  textColor?: (typeof colorPaletteArray)[number] | '--text-black' | '--primary-white';
  size?: ButtonSizes;
  stretch?: boolean;
  justify?: 'center' | 'space-between' | 'flex-start';
  isDisabled?: boolean;
  isLoading?: boolean;
  as?: 'a' | 'button';
}

export const ButtonStyle = styled.button<ButtonStyleProps>`
  ${ButtonTextStyle}
  display: flex;
  flex-direction: row;
  justify-content: ${({ justify }) => justify};
  align-items: center;
  border-radius: 6px;
  border: 0;
  transition:
    0.1s background-color,
    0.1s border-color;
  color: var(${({ textColor }) => textColor});

  > * {
    line-height: 1;
  }

  /* Focus */
  ${focusUtil}

  /* Stretch Prop */
  ${({ stretch }) =>
    stretch &&
    css`
      width: 100% !important;
    `}

  /* 'as' Anchor Tag Prop */
  ${({ as }) =>
    as === 'a' &&
    css`
      width: fit-content;
      text-decoration: none;
    `}


  /* ------------------------------------ */
  /* ICON
  /* ------------------------------------ */

  &.icon-only {
    padding: 0 var(--sizing-xs);
  }

  &:not(.icon-only) .icon-left {
    margin-right: var(--sizing-xs);
  }

  &:not(.icon-only) .icon-right {
    margin-left: var(--sizing-xs);
  }

  /* ------------------------------------ */
  /* SIZING
  /* ------------------------------------ */

  /* NORMAL */
  height: var(--sizing-xl);
  padding: 0 var(--sizing-sm);

  /* SMALL */
  ${({ size }) =>
    size === 'small' &&
    css`
      height: calc(var(--sizing-sm) * 2.5);
      padding: 0 var(--sizing-sm);
    `}

  /* XSMALL */
  ${({ size }) =>
    size === 'xsmall' &&
    css`
      height: var(--sizing-lg);
      padding: 0 var(--sizing-xs);

      &.icon-only {
        padding: 0 var(--sizing-xs);
      }
    `}



  /* ------------------------------------ */
  /* BUTTON TYPE
  /* ------------------------------------ */

  /* SECONDARY */
  ${({ buttonType }) =>
    buttonType === 'secondary' &&
    css`
      background: var(--primary-white);
      border: 2px solid var(--colors-gray-300);

      &:hover {
        background: var(--colors-opaque-10);
      }

      &:active {
        background: var(--colors-opaque-10);
        border-color: var(--colors-opaque-20);
      }
    `}

  /* PRIMARY */
  ${({ buttonType, color = 'yellow' }) =>
    buttonType === 'primary' &&
    color !== 'black' &&
    color !== 'blue' &&
    css`
      // to do: refactor button text thickness in typography file
      font-weight: 700 !important;
      background: var(--colors-${color}-100);
      border: 2px solid var(--colors-${color}-100);

      &:hover {
        background: var(--colors-${color}-200);
        border-color: var(--colors-${color}-300);
      }

      &:active {
        background: var(--colors-${color}-200);
        border-color: var(--colors-${color}-300);
      }
    `}

    ${({ buttonType, color = 'yellow' }) =>
    buttonType === 'primary' &&
    color === 'blue' &&
    css`
      color: var(--primary-white);
      background: var(--primary-blue);
      border: 2px solid var(--primary-blue);

      &:hover {
        opacity: 0.75;
      }

      &:active {
        opacity: 0.85;
      }
    `}

  ${({ buttonType, color = 'yellow' }) =>
    buttonType === 'primary' &&
    color === 'black' &&
    css`
      background: var(--primary-white);

      &:hover {
        background: var(--primary-white);
      }

      &:active {
        background: var(--colors-gray-100);
      }
    `}

  /* STANDALONE */
  ${({ buttonType, size }) =>
    buttonType === 'standalone' &&
    css`
      height: auto;
      padding: 0.125rem;
      background: none;
      position: relative;
      z-index: 0;

      &:hover,
      &:active {
        background: none;
      }

      /* Pseudo Button Background */
      &:before {
        content: '';
        display: block;
        z-index: -1;
        position: absolute;
        background: none;
        border-radius: var(--border-radius-xs);
        transform: translateY(-50%);
        transition: inherit;

        /* SIZE: NORMAL */
        height: var(--sizing-xl);
        width: calc(100% + 2rem);
        top: 50%;
        left: -1rem;

        /* SMALL */
        ${size === 'small' &&
        css`
          height: calc(var(--sizing-sm) * 2.5);
          width: calc(100% + 1.5rem);
          left: -0.75rem;
        `}

        /* XSMALL */
        ${size === 'xsmall' &&
        css`
          height: var(--sizing-lg);
          width: calc(100% + 1rem);
          left: -0.5rem;
        `}
      }

      &.icon-only {
        padding: 0.125rem;

        &:before {
          width: calc(100% + 1rem);
          left: -0.5rem;
        }
      }

      &:hover:before {
        background: var(--colors-opaque-5);
      }

      &:active:before {
        background: var(--colors-opaque-10);
      }
    `}

  /* LINK */
  ${({ buttonType }) =>
    buttonType === 'link' &&
    css`
      height: auto;
      padding: 0;
      background: none;
      text-decoration: underline;

      &:hover,
      &:active {
        background: none;
        opacity: 0.75;
      }
    `}

   /* TRANSPARENT */
   ${({ buttonType }) =>
    buttonType === 'transparent' &&
    css`
      background: none;
      border: 2px solid transparent;

      &:hover {
        background: var(--button-background-normal);
      }

      &:active {
        background: var(--button-background-hover);
      }
    `}



  /* ------------------------------------ */
  /* DISABLED PROP
  /* ------------------------------------ */

  ${({ isDisabled, buttonType }) =>
    isDisabled &&
    css`
      cursor: not-allowed;
      color: var(--text-black);
      background: var(--button-background-normal);
      border: none;

      > * {
        opacity: 0.5;
      }

      &:hover,
      &:active {
        background: var(--button-background-normal);
        border: none;
      }

      ${(buttonType === 'standalone' || buttonType === 'link' || buttonType === 'transparent') &&
      css`
        background: none;
        border: none;

        &:hover,
        &:active {
          background: none;
          border: none;
        }

        &:hover:before,
        &:active:before {
          background: none;
        }
      `}
    `}

    ${({ isLoading }) =>
    isLoading &&
    css`
      cursor: not-allowed;
      color: var(--text-black);

      > * {
        opacity: 0.5;
      }

      background: none;
      border: none;

      &:hover,
      &:active {
        background: none;
        border: none;
      }

      &:hover:before,
      &:active:before {
        background: none;
      }
    `}
`;
