export function validPolicyholderAge(value: string) {
  if (value === '') return false;

  const v = parseInt(value, 10);
  if (typeof v !== 'number') {
    return false;
  }
  return v >= 0 && v <= 120;
}

export function validSpouseAge(value: string) {
  if (value === '') return false;

  const v = parseInt(value, 10);
  if (typeof v !== 'number') {
    return false;
  }
  return v >= 16 && v <= 120;
}

export function validDependantAge(value: string) {
  if (value === '') return false;

  const v = parseInt(value, 10);
  if (typeof v !== 'number') {
    return false;
  }
  return v >= 0 && v <= 120;
}

export function validateMemberAge(age: string, relationship: string) {
  let validAge = true;

  if (relationship === 'policyholder') {
    validAge = validPolicyholderAge(age);
  }
  if (relationship === 'spouse') {
    validAge = validSpouseAge(age);
  } else if (relationship === 'dependent') {
    validAge = validDependantAge(age);
  }

  return validAge;
}
