import styled from 'styled-components';

import { cardStyling } from 'DesignLibrary/utils';

export const GroupContainer = styled.section`
  ${cardStyling}
  overflow: hidden;

  @media print {
    box-shadow: none;
    border: var(--border);
  }

  .showEdu {
    display: block;
  }

  .hideEdu {
    display: none;
  }

  .additional-benefits {
    @media print {
      display: block !important;
    }
  }
`;

export const GroupHeader = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: var(--border);
  padding: var(--sizing-sm) var(--sizing-md);

  .title {
    display: flex;
    align-items: center;
    gap: var(--sizing-xs);
  }
`;
