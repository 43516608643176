import React from 'react';

import AlexLogo from 'Assets/alex-logo.svg';
import { Button, Paragraph } from 'DesignLibrary/atoms';
import Text from 'Shared/Text';
import { sendInsightsEvent, updateInsightsDefaults } from 'Utils/insights';

import { MedicareContainer, NewTag, Wrapper } from './styled';

export interface MedicareCalloutProps {
  client: string;
  isNextClicked: boolean;
}

export const MedicareCallout = ({ client, isNextClicked }: MedicareCalloutProps) => {
  const handleClick = () => {
    updateInsightsDefaults({ product: 'medicare' }).then(() => {
      sendInsightsEvent(null, 'product_select', {
        product: 'medicare',
      });
    });
  };
  return (
    <Wrapper>
      <MedicareContainer isNextClicked={isNextClicked}>
        <div className="text">
          <NewTag>
            <Text field="unified_landing_page.medicare_callout.tag" />
          </NewTag>
          <Paragraph size="small" color="--text-black">
            <Text
              field="unified_landing_page.medicare_callout.text"
              vars={{
                x: (
                  <span key="logo">
                    <img src={AlexLogo} alt="Alex" />
                  </span>
                ),
              }}
            />
          </Paragraph>
        </div>
        <div className="link">
          {/* TODO: Replace this link */}
          <Button
            stretch
            // TODO: Update URL
            url={`https://medicare.myalex.com/${client}`}
            onClick={handleClick}
            buttonType="standalone"
            iconRight="ArrowUpRight"
          >
            <Text field="unified_landing_page.medicare_callout.cta_button" />
          </Button>
        </div>
      </MedicareContainer>
    </Wrapper>
  );
};
