import styled, { css } from 'styled-components';

import { ParagraphMiniStyle } from 'DesignLibrary/atoms';
import { breakpoint } from 'DesignLibrary/global';
import { cardStyling } from 'DesignLibrary/utils';

interface ContainerProps {
  recommended?: string | null;
  isAutoEnrolled: boolean;
  fbsBestMatch?: boolean;
}

interface RecommendedBadgeProps {
  recommended: string | null;
  fbsBestMatch: boolean;
}

interface SelectedPlanProps {
  interactive: boolean;
  onClick: () => void;
}

export const SecondaryHeader = styled.section`
  display: flex;
  align-items: center;
  gap: var(--sizing-xs);
  margin-bottom: var(--sizing-sm);

  ${breakpoint.TABLET} {
    margin-bottom: var(--sizing-md);
  }
`;

export const GroupSection = styled.section`
  margin-bottom: var(--sizing-xl);

  ${breakpoint.TABLET} {
    margin-bottom: var(--sizing-2xl);
  }
`;

export const FbsGrid = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: var(--sizing-sm);

  ${breakpoint.TABLET} {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  }
`;

export const SpinnerLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: var(--sizing-xl);
`;

export const NoBenefits = styled.div`
  padding-top: var(--sizing-xl);
`;

export const Container = styled.section<ContainerProps>`
  ${cardStyling}
  display: flex;
  flex-direction: column;

  ${({ recommended, isAutoEnrolled }) =>
    recommended &&
    !isAutoEnrolled &&
    css`
      border: 3px solid var(--colors-blue-50);
    `}

  ${({ fbsBestMatch, recommended, isAutoEnrolled }) =>
    fbsBestMatch &&
    recommended === 'best' &&
    !isAutoEnrolled &&
    css`
      border: 3px solid var(--primary-blue);
    `}

  ${({ fbsBestMatch, recommended, isAutoEnrolled }) =>
    !fbsBestMatch &&
    recommended &&
    !isAutoEnrolled &&
    css`
      border: 3px solid var(--primary-blue);
    `}

  .main-content {
    padding: var(--sizing-md);
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    overflow: hidden;
  }

  &:hover {
    z-index: 999;
  }

  .about-container {
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .paragraph,
    p {
      margin-top: var(--sizing-sm);
    }

    .cta {
      margin-top: var(--sizing-sm);
    }
  }
`;

export const Header = styled.section`
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 0;
  flex-shrink: 0;
  justify-content: space-between;
  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  h4 {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: var(--sizing-2xl);
    width: var(--sizing-2xl);
    margin-right: 12px;
  }

  .auto-enrolled {
    ${ParagraphMiniStyle('--colors-blue-600', 600)}
    border-radius: var(--border-radius-xs);
    background: var(--colors-blue-50);
    padding: 4px var(--sizing-xs);
  }
`;

export const RecommendedBadge = styled.div<RecommendedBadgeProps>`
  height: var(--sizing-lg);
  width: 100%;
  background: var(--colors-blue-50);
  border-radius: 3px 3px 0 0;
  cursor: help;
  white-space: nowrap;

  ${({ fbsBestMatch, recommended }) =>
    !fbsBestMatch &&
    recommended &&
    css`
      background: var(--primary-blue);
      box-shadow: 0 0 0 1px var(--primary-blue);
    `}

  ${({ fbsBestMatch, recommended }) =>
    fbsBestMatch &&
    recommended === 'best' &&
    css`
      background: var(--primary-blue);
      box-shadow: 0 0 0 1px var(--primary-blue);
    `}

  span {
    display: block;
    height: 100%;
  }

  [id*='recommended-badge-tooltip'] {
    display: flex;
    align-items: center;
    justify-content: left;
  }

  .text {
    padding-left: var(--sizing-md);
    position: relative;
    left: -1px;
    display: flex;
    gap: var(--sizing-xs);
    align-items: center;
  }
`;

export const TwoButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--sizing-xs);

  ${breakpoint.DESKTOP} {
    flex-direction: row;
  }
`;

export const SelectedPlan = styled.section<SelectedPlanProps>`
  position: relative;
  border: 2px solid var(--colors-gray-300);
  border-radius: var(--border-radius-xs);
  box-shadow: var(--shadow-card);
  margin-top: var(--sizing-sm);
  cursor: pointer;
  z-index: 0;
  transition:
    0.15s box-shadow,
    0.15s border-color,
    0.15s transform;

  &:hover {
    box-shadow: var(--shadow-large);
    border-color: var(--input-gray);
    transform: translateY(-2px);
  }

  .selected-badge {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(40%, -40%);
  }
`;

export const MainContent = styled.div`
  display: flex;
  justify-content: space-between;
  gap: var(--sizing-sm);
  padding: var(--sizing-sm);

  .plan-head {
    min-width: 0;

    > * {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .details {
      display: flex;
      align-items: center;

      span {
        margin-right: var(--sizing-xs);
      }

      .bullet {
        margin: 0 var(--sizing-xs);
      }
    }

    .title {
      margin-top: var(--sizing-xs);
    }
  }

  .price {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex-shrink: 0;
  }

  .auto-enrolled {
    ${ParagraphMiniStyle('--colors-blue-600', 600)}
    border-radius: var(--border-radius-xs);
    background: var(--colors-blue-50);
    padding: 4px var(--sizing-xs);
  }
`;

export const BottomBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${({ color }) => color && `var(--colors-${color}-50)`};
  padding: var(--sizing-xs) var(--sizing-sm);
  border-radius: 0 0 var(--border-radius-xs) var(--border-radius-xs);
`;
