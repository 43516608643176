import styled from 'styled-components';

import { InputTextStyle } from 'DesignLibrary/atoms/typography';
import { focusSingle } from 'DesignLibrary/utils';

export const CounterContainer = styled.div`
  display: inline-flex;
  height: var(--sizing-xl);
  border: 2px solid var(--input-gray);
  border-radius: var(--border-radius-xs);
  background: var(--background-white);
  outline: none;
  box-sizing: content-box;

  .counter {
    ${InputTextStyle}
    text-align:center;
    width: var(--sizing-xxl);
    background: var(--background-white);
    z-index: 1;
    border: none;
    padding: 0;

    &:focus-visible,
    &:focus {
      ${focusSingle}
    }
  }
`;

export const Button = styled.button`
  width: var(--sizing-xl);
  background: var(--colors-gray-100);
  border: none;
  outline: none;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;

  &:hover {
    background: var(--colors-blue-50);
  }

  &:active {
    background: var(--button-background-hover);
  }

  &:disabled {
    background: var(--background-gray);
  }

  &.left {
    border-radius: var(--border-radius-xs) 0 0 var(--border-radius-xs);
  }

  &.right {
    border-radius: 0 var(--border-radius-xs) var(--border-radius-xs) 0;
  }
`;
