import React from 'react';

import ProfileIllustration from 'Assets/banner/privacy-illustration.png';
import PrivacyOptions from 'Assets/privacy-options.svg';
import { Button, Paragraph } from 'DesignLibrary/atoms';
import { BannerCard } from 'DesignLibrary/molecules/BannerCard';
import Text from 'Shared/Text';

import { Container } from './styled';
import { YOUR_PRIVACY_CHOICES_URL, PRIVACY_POLICY_URL } from '../../../constants';

interface PrivacyBannerProps {
  isFirstPageView: boolean;
}

export const PrivacyBanner = ({ isFirstPageView }: PrivacyBannerProps) => {
  const onOpen = () => {
    window.open(PRIVACY_POLICY_URL, '_blank')?.focus();
  };

  return (
    <Container isFirstPageView={isFirstPageView}>
      <BannerCard imgUrl={ProfileIllustration} background="--colors-blue-50">
        <Paragraph size="small">
          <Text field="eligibility_section.privacy_banner_card" />
        </Paragraph>
        <Button stretch size="small" onClick={onOpen}>
          <Text field="eligibility_section.privacy_title" />
        </Button>
        <Button className="link" size="xsmall" buttonType="standalone" url={YOUR_PRIVACY_CHOICES_URL} stretch>
          <>
            <img src={PrivacyOptions} alt="Your Privacy Options" width={30} height={20} />
            <Text field="eligibility_section.do_not_sell_my_info_text" />
          </>
        </Button>
      </BannerCard>
    </Container>
  );
};
