import React from 'react';

import { DRUG_TYPES } from 'Containers/ResultPage/constants';
import { useTextContext } from 'Contexts/textContext';
import { IconBlockProps } from 'DesignLibrary/molecules/IconBlock';
import Plan from 'Models/plan';
import { Recommendation } from 'Types/entities';

import { renderCosts } from '../_shared';
import { ViewPlanPageCard } from '../ViewPlanPageCard';

interface PrescriptionsCardProps {
  client: string;
  recommendation: Recommendation;
}

export const PrescriptionsCard: React.FC<PrescriptionsCardProps> = ({ client, recommendation }) => {
  const { retrieveContentfulData } = useTextContext();

  const title = retrieveContentfulData<string>(
    'health_details_section.plan_details.prescriptions_card_title',
  );
  const icon: IconBlockProps = {
    icon: 'Prescription',
    color: 'blue',
  };

  const plan = new Plan(recommendation);

  return (
    <ViewPlanPageCard title={title} icon={icon}>
      {Object.values(DRUG_TYPES).map((costType, index) => {
        const showDrugType =
          !plan[costType].imputed && (plan[costType].coinsurance !== null || plan[costType].copay !== null);
        if (showDrugType) {
          return renderCosts(plan, client, costType, true, index);
        }
        return null;
      })}
    </ViewPlanPageCard>
  );
};
