import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { URLSearchParams } from 'url';

import { GlobalReducerState } from 'app/reducers';
import { makeSelectGlobalField } from 'Containers/App/selectors';
import { makeGetSelectedPublicationPlanYear } from 'Containers/ProfilePage/selectors';
import { ClientConfig } from 'Utils/apiTypes';
import { FeatureFlagDefaults, FeatureFlags } from 'Utils/featureFlags';

/*
For adding new split-based Feature Flags, see the "Feature Flags" section in the README.md.

## Instructions below are for legacy flags that are part of the ClientConfig record.

### To add a legacy feature flag

- Add the name here, to `featureFlagNames`
- Add the flag to initial redux state in picwell-app-react-v3/app/containers/App/reducer.js
- Add the flag to the `ClientConfig` type
- Add the field, with the _exact_ same name, to the `ClientConfig` object in picwell_app/customers/models.py
- Run `make migrations` to create a migration file to add the file to the DB
- Deploy all of it simultaneously

### To remove a legacy feature flag

1. Remove flag and usages.
  - Remove all code usages of the feature flag
  - Remove the flag from initial redux state
  - Remove the feature flag from here and `ClientConfig`.
    - Do not run `make migrations` yet
  - Deploy this change. This removes our usage of the feature flag from production.
2. Run `make migrations` to remove the flag from the database, and deploy.

*/

export type FeatureFlagContextProps = FeatureFlags | Record<string, never>;

const featureFlagNames: string[] = Object.keys(FeatureFlagDefaults);

export const FeatureFlagContext = React.createContext<FeatureFlagContextProps>(
  featureFlagNames.reduce((acc, flagName) => ({ ...acc, [flagName]: FeatureFlagDefaults[flagName] }), {}),
);

interface FeatureFlagParentProps {
  searchParams: URLSearchParams;
  children?: ReactNode;
}

interface FeatureFlagStoreProps {
  config: ClientConfig;
  planYear: number | undefined;
}

type FeatureFlagProviderProps = FeatureFlagParentProps & FeatureFlagStoreProps;

function getFeatureFlagFromQueryOrConfig(
  params: URLSearchParams,
  config: ClientConfig,
  planYear: string,
  featureFlagName: string,
): boolean {
  // check flag in query string
  const configQueryParamOverride = params.get(featureFlagName);
  if (configQueryParamOverride !== null) {
    return JSON.parse(configQueryParamOverride.toLowerCase());
  }

  // check legacy flags
  if (featureFlagName in config) {
    return !!config[featureFlagName];
  }

  // check split flags
  if (featureFlagName in config.split_feature_flags) {
    const featureFlagValue = config.split_feature_flags?.[featureFlagName];
    // currently planYear is the only exception to boolean ("Yes"/"No") treatments
    if (typeof featureFlagValue === 'string') {
      return planYear === featureFlagValue;
    }
    return !!featureFlagValue;
  }

  // no flag found!
  return false;
}

export const FeatureFlagProvider = ({
  searchParams,
  config,
  planYear,
  children,
}: FeatureFlagProviderProps) => {
  /*
  Save this to state so it never gets overwritten if the URL changes
  */
  const [stateSearchParams, setStateSearchParams] = useState<URLSearchParams>(searchParams);

  useEffect(() => {
    setStateSearchParams(searchParams);
  }, []);

  const featureFlags = featureFlagNames.reduce(
    (acc, featureFlagName) => ({
      ...acc,
      [featureFlagName]: getFeatureFlagFromQueryOrConfig(
        stateSearchParams,
        config,
        planYear?.toString() || '',
        featureFlagName,
      ),
    }),
    {},
  );

  return <FeatureFlagContext.Provider value={featureFlags}>{children}</FeatureFlagContext.Provider>;
};

export const useFeatureFlagContext = () => useContext<FeatureFlagContextProps>(FeatureFlagContext);

const mapStateToProps = createStructuredSelector<GlobalReducerState, FeatureFlagStoreProps>({
  config: makeSelectGlobalField('config'),
  planYear: makeGetSelectedPublicationPlanYear(),
});
const withConnect = connect(mapStateToProps);
export default compose(withConnect)(FeatureFlagProvider);
