import React, { FC } from 'react';
import styled from 'styled-components';

import { SpinKeyframes } from '../../keyframes';

export interface SpinnerProps {
  size?: 'small' | 'normal' | 'large';
  color?: string;
}

const SvgSpinner = styled.svg<SpinnerProps>`
  height: ${({ size }) => {
    switch (size) {
      case 'small':
        return '1rem';
        break;

      case 'large':
        return '2.5rem';
        break;

      default:
        return '1.5rem';
    }
  }};
  width: ${({ size }) => {
    switch (size) {
      case 'small':
        return '1rem';
        break;

      case 'large':
        return '2.5rem';
        break;

      default:
        return '1.5rem';
    }
  }};
  animation: ${SpinKeyframes} 1s 0s linear infinite;
`;

export const Spinner: FC<SpinnerProps> = ({ size = 'normal', color = '--primary-green' }) => {
  let strokeWidth;
  switch (size) {
    case 'small':
      strokeWidth = '2.5';
      break;
    case 'large':
      strokeWidth = '2';
      break;
    default:
      strokeWidth = '2';
  }

  return (
    <SvgSpinner viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" size={size} role="img">
      <g stroke="none" fill="none" fillRule="evenodd" strokeLinecap="round">
        <g
          transform="translate(-1120.000000, -625.000000)"
          stroke={`var(${color})`}
          strokeWidth={strokeWidth}
        >
          <path d="M1151,641 C1151,632.715729 1144.28427,626 1136,626 C1127.71573,626 1121,632.715729 1121,641 C1121,649.284271 1127.71573,656 1136,656" />
        </g>
      </g>
    </SvgSpinner>
  );
};
