// from https://github.com/react-boilerplate/react-boilerplate/blob/master/app/utils/request.js
import 'whatwg-fetch';
import * as Sentry from '@sentry/react';

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
export function parseJSON(response: Response): Promise<any> {
  if (response.status === 204 || response.status === 205) {
    return Promise.resolve(null);
  }
  return response.json();
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
export async function checkStatus(response: Response): Promise<Response> {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  let error: any;
  const url = new URL(response.url);
  try {
    const body = await response.json();
    const bodyErrors = body.errors.length ? body.errors[0].title : '';
    error = new Error(`${response.status} on ${url.pathname}: ${bodyErrors}`);
    error.body = body;
  } catch (bodyReadError) {
    error = new Error(`${response.status} on ${url.pathname}`);
    error.bodyReadError = bodyReadError;
  } finally {
    error.response = response;
    error.status = response.status;
    error.pathname = url.pathname;
    error.hostname = url.hostname;
    Sentry.captureException(error);
  }
  throw error;
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export default function request(url: string, options?: any) {
  return fetch(url, options).then(checkStatus).then(parseJSON);
}
