import { Box, Flex, Stack } from '@chakra-ui/react';
import React from 'react';

import { LocationCard } from 'ContentfulDefaults/types/profile';
import { useTextContext } from 'Contexts/textContext';
import { H6 } from 'DesignLibrary/atoms';
import TextInput from 'DesignLibrary/atoms/inputs/TextInput';
import ToggleList from 'DesignLibrary/atoms/ToggleList';
import { useViewport } from 'DesignLibrary/context';
import LabelInput from 'DesignLibrary/molecules/LabelInput';
import Text from 'Shared/Text';
import { isValidZipCode } from 'Utils/helpers';
import { sendInsightsEvent } from 'Utils/insights';

const ZipDistanceSection = ({
  zipcodeLocal,
  setZipcodeLocal,
  distance,
  distanceRef,
  setOptions,
  setShowLoading,
  setFetched,
  setDistance,
}) => {
  const { device } = useViewport();

  const { retrieveContentfulData } = useTextContext();
  const profileCard = retrieveContentfulData<LocationCard>(`profile_section.location`);
  const valError = retrieveContentfulData<string>(
    `health_section.provider_selection_modal.zipcode_validation_error`,
  );

  const zipcodeLabelId = 'zipcode-input-label';

  // Handler for zipcode change
  const handleZipcodeChange = (value: string) => {
    setOptions([]);
    setZipcodeLocal(value);
    if (isValidZipCode(value)) {
      setShowLoading(true);
      sendInsightsEvent(null, 'zipcode_selected_for_provider_search', { zipcode: value });
    } else {
      setShowLoading(false);
      setFetched(false);
    }
  };

  // Handler for distance change
  const handleDistanceChange = (value: string) => {
    setOptions([]);
    setFetched(false);
    setShowLoading(isValidZipCode(zipcodeLocal));
    setDistance(value);
    sendInsightsEvent(null, 'distance_selected_for_provider_search', { distance: value });
  };

  // Handler for onFocus zip code
  const handleZipCodeFocus = () => {
    setShowLoading(false);
  };

  // Option list to display distance values
  const getOptions = () => {
    const options: { option: string; value: string }[] = [];
    options.push(
      ...[
        {
          option: '5',
          value: '5',
        },
        {
          option: '10',
          value: '10',
        },
        {
          option: '25',
          value: '25',
        },
        {
          option: '50',
          value: '50',
        },
        {
          option: '75',
          value: '75',
        },
        {
          option: '100+',
          value: '100',
        },
      ],
    );

    return options;
  };
  return (
    <Flex flexDir={['column', 'row']} gap={[4, 6]}>
      <Stack>
        <LabelInput
          id={zipcodeLabelId}
          className="zipcode-style"
          error={!isValidZipCode(zipcodeLocal)}
          errorText={valError}
          label=""
        >
          <H6 data-testid="zipcode-title" color="--text-gray" as="label">
            <Box mb={3}>
              <Text field="health_section.provider_selection_modal.zipcode_label" />
            </Box>
          </H6>
          <TextInput
            labelId={zipcodeLabelId}
            className="zipcode-input pingdom-zipcode"
            id="zipcode-input"
            data-testid="zipcode-input"
            inputType="integer"
            inputMode="numeric"
            placeholder={profileCard?.zipcode_placeholder}
            stretch
            iconType="Search"
            error={!isValidZipCode(zipcodeLocal)}
            handleChange={(e) => handleZipcodeChange(e.target.value)}
            maxLength={5}
            value={zipcodeLocal}
            type="tel"
            onFocus={handleZipCodeFocus}
          />
        </LabelInput>
      </Stack>
      <Stack>
        <Box mt={1} mb={[1, 1]}>
          <H6 data-testid="distance-title" color="--text-gray" as="label">
            <Text field="health_section.provider_selection_modal.distance_label" />
          </H6>
        </Box>
        <div ref={distanceRef}>
          <ToggleList
            className="pointer-event-auto"
            label="health_section.provider_selection_modal.distance_label"
            groupName="distance"
            options={getOptions()}
            handleChange={(e) => handleDistanceChange(e.target.value)}
            color="--colors-gray-black"
            selected={distance}
            fit
            {...(device !== 'mobile' ? { layout: 'row' } : { grid: true, gridMobile: true })}
          />
        </div>
      </Stack>
    </Flex>
  );
};

export default ZipDistanceSection;
