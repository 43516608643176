import { Ref } from 'react';
import styled, { css } from 'styled-components';

import { focusUtil } from 'DesignLibrary/utils';

interface ContainerProps {
  ref: Ref<HTMLElement>;
  isActive: boolean;
}

export const Container = styled.section<ContainerProps>`
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;
  gap: var(--sizing-sm);
  cursor: pointer;
  position: relative;
  width: var(--sizing-3xl);
  padding: var(--sizing-sm) var(--sizing-xs);
  border-radius: var(--border-radius-sm);
  background: var(--background-gray);

  ${focusUtil}

  &:hover {
    background: var(--background-gray);
  }

  svg {
    height: 50px;
    fill: var(--text-gray);
  }

  ${({ isActive }) =>
    isActive &&
    css`
      cursor: default;
      background: var(--colors-blue-100);

      &:hover {
        background: var(--colors-blue-100);
      }

      svg {
        fill: var(--colors-blue-400);
      }
    `}
`;
