import React, { useState } from 'react';

import {
  IncentiveSurveyAnswer,
  IncentiveSurveyQuestion,
  Survey,
  ResultPageIncentiveSurveyData,
} from 'Containers/ProfilePage/types';
import { H5, Icon } from 'DesignLibrary/atoms';
import { useViewport } from 'DesignLibrary/context';
import Text from 'Shared/Text';
import { HouseholdMember, ProfileIncentiveQuestionAnswers } from 'Types/entities';

import { MemberWrapper, MobileHeader } from './styled';
import { MemberSelectionContent } from '../MemberSelectionContent';

export interface MemberSelectionCardProps {
  allHouseholdMembers: HouseholdMember[];
  selectedMemberIds: string[];
  hasEnteredCustomPlan: boolean;
  isLoading: boolean;
  survey: Survey;
  isBuilderClient: boolean;
  incentiveSurvey: IncentiveSurveyQuestion[];
  isIncentiveSurveyLoading: boolean;
  incentiveSurveyAnswers: Record<string, IncentiveSurveyAnswer | null>;
  resultPageIncentiveSurvey: ResultPageIncentiveSurveyData;
  createUpdateHouseholdAndIncentiveSurvey: () => void;
  submitIncentiveSurveyResponses: (incentiveSurveyResonses) => void;
  handleSectionChange: (key: 'incentives' | 'pregnancy', isValid: boolean, isComplete: boolean) => void;
  setSelectedMemberIds: (memberIds: string[]) => void;
  lockFuturePages: () => void;
  handleSavePregnancyAnswer: (val: string) => void;
  updateActiveMembers: (members: string[]) => void;
  setProfileIncentiveSurvey: (data: ProfileIncentiveQuestionAnswers) => void;
}

export const MemberSelectionCard = ({
  allHouseholdMembers,
  selectedMemberIds,
  hasEnteredCustomPlan,
  isLoading,
  survey,
  isBuilderClient,
  incentiveSurvey,
  isIncentiveSurveyLoading,
  incentiveSurveyAnswers,
  createUpdateHouseholdAndIncentiveSurvey,
  submitIncentiveSurveyResponses,
  handleSectionChange,
  setSelectedMemberIds,
  lockFuturePages,
  handleSavePregnancyAnswer,
  updateActiveMembers,
  setProfileIncentiveSurvey,
  resultPageIncentiveSurvey,
}: MemberSelectionCardProps) => {
  const { device } = useViewport();
  const [isMobileOpen, setIsMobileOpen] = useState(false);
  const getMobileHeader = () => {
    if (allHouseholdMembers.length === selectedMemberIds.length) {
      return <Text field="health_section.secondary_content.member_selection_mobile_header.everyone" />;
    }
    if (selectedMemberIds.length > 1) {
      return (
        <Text
          field="health_section.secondary_content.member_selection_mobile_header.familyMembers"
          vars={{ x: selectedMemberIds.length }}
        />
      );
    }
    return <Text field="health_section.secondary_content.member_selection_mobile_header.yourself" />;
  };

  const renderMemberSelection = () => (
    <MemberSelectionContent
      allHouseholdMembers={allHouseholdMembers}
      selectedMemberIds={selectedMemberIds}
      hasEnteredCustomPlan={hasEnteredCustomPlan}
      isLoading={isLoading}
      isBuilderClient={isBuilderClient}
      survey={survey}
      incentiveSurvey={incentiveSurvey}
      isIncentiveSurveyLoading={isIncentiveSurveyLoading}
      incentiveSurveyAnswers={incentiveSurveyAnswers}
      createUpdateHouseholdAndIncentiveSurvey={createUpdateHouseholdAndIncentiveSurvey}
      submitIncentiveSurveyResponses={submitIncentiveSurveyResponses}
      handleSectionChange={handleSectionChange}
      setSelectedMemberIds={setSelectedMemberIds}
      lockFuturePages={lockFuturePages}
      handleSavePregnancyAnswer={handleSavePregnancyAnswer}
      updateActiveMembers={updateActiveMembers}
      setProfileIncentiveSurvey={setProfileIncentiveSurvey}
      resultPageIncentiveSurvey={resultPageIncentiveSurvey}
    />
  );

  if (device === 'mobile') {
    return (
      <MemberWrapper>
        <MobileHeader role="button" onClick={() => setIsMobileOpen(!isMobileOpen)}>
          <div className="title">
            <Icon type="Users" />
            <H5 weight="normal">{getMobileHeader()}</H5>
          </div>
          <Icon color="--text-gray-light" type={isMobileOpen ? 'CaretUp' : 'CaretDown'} />
        </MobileHeader>
        {isMobileOpen && renderMemberSelection()}
      </MemberWrapper>
    );
  }
  return <MemberWrapper>{renderMemberSelection()}</MemberWrapper>;
};
