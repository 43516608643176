import { push } from 'connected-react-router';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import { GlobalReducerState } from 'app/reducers';
import {
  makeSelectCommercialField,
  makeSelectConfigField,
  makeSelectJvpField,
  selectIsFirstPageView,
} from 'Containers/App/selectors';
import {
  makeGetBuilderGoSettings,
  makeGetCoveredHouseholdMemberCount,
  makeSelectDependentsCount,
  makeSelectSurvey,
} from 'Containers/ProfilePage/selectors';
import { RiskAversion, Survey } from 'Containers/ProfilePage/types';
import { BuilderBenefit, InsuranceType } from 'Containers/ViewBenefitPage/types';
import { ContentfulFbsBenefit } from 'ContentfulDefaults/types/benefits';
import { ContentfulInfoBar } from 'ContentfulWrappers/ContentfulInfoBar';
import { ContentfulInfoBars } from 'ContentfulWrappers/ContentfulInfoBars';
import { ContentfulHeaderWrapper } from 'ContentfulWrappers/header';
import { useTextContext } from 'Contexts/textContext';
import { FadeUp, H4, Spinner, Tooltip } from 'DesignLibrary/atoms';
import { PageLayout } from 'DesignLibrary/atoms/PageLayout';
import InfoBar from 'DesignLibrary/molecules/InfoBar';
import Text from 'Shared/Text';
import {
  CoveredMembers,
  ReasonsWhy,
  Recommendation,
  SelectedPlansCoveredMembers,
  SelectedSupplementalPlans,
  SupplementalBenefitGroup,
  SupplementalPlan,
  SupplementalPlansFullHousehold,
  SupplementalRecommendations,
} from 'Types/entities';
import { BuilderGoSettings } from 'Utils/apiTypes';
import injectSaga from 'Utils/injectSaga';
import { sendInsightsEvent } from 'Utils/insights';
import { VIEW_BENEFIT_PATH, OVERVIEW_PATH } from 'Utils/urls';

import { getSupplementalPlans } from './actions';
import { getNonSelectableBenefits, getSelectableBenefits } from './constants';
import saga from './saga';
import {
  makeGetIsHealthPlanSelected,
  makeGetSelectedHealthDeductible,
  getRiskAversion,
  makeSelectOverviewField,
  makeGetSupplementalPlanRecommendations,
  makeGetSupplementalPlanReasonsWhy,
} from './selectors';
import { SecondaryHeader, GroupSection, FbsGrid, SpinnerLayout, NoBenefits } from './styled';
import SupplementalCard from './SupplementalCard';

interface OverviewPageStateProps {
  client: string;
  employerName: string;
  fbsBenefitGroups: SupplementalBenefitGroup[];
  selectedSupplementalPlans: SelectedSupplementalPlans;
  supplementalPlans: SupplementalPlansFullHousehold;
  supplementalRecommendations: SupplementalRecommendations;
  reasonsWhy: ReasonsWhy;
  isHealthPlanSelected: boolean;
  selectedHealthPlan: Recommendation | Record<string, never>;
  isLoading: boolean;
  planCoveredMembers: SelectedPlansCoveredMembers;
  isFirstPageView: boolean;
  coveredHouseholdMemberCount: number;
  showEnrolleeSelectDisclaimer: boolean;
  selectedHealthDeductible: number;
  dependentsCount: number;
  surveyAnswers: Survey;
  riskAversion: RiskAversion;
  builderCustomerKey: string | null;
  builderGoSettings: BuilderGoSettings | null;
}

interface OverviewPageDispatchProps {
  viewBenefitDetails: (insuranceType: InsuranceType) => void;
  fetchSupplementalPlans: () => void;
}

export type OverviewPageProps = OverviewPageStateProps & OverviewPageDispatchProps;

export const OverviewPage = ({
  client,
  employerName,
  fbsBenefitGroups,
  selectedSupplementalPlans,
  supplementalPlans,
  supplementalRecommendations,
  reasonsWhy,
  isHealthPlanSelected,
  selectedHealthPlan,
  isLoading,
  planCoveredMembers,
  isFirstPageView,
  coveredHouseholdMemberCount,
  showEnrolleeSelectDisclaimer,
  selectedHealthDeductible,
  dependentsCount,
  surveyAnswers,
  riskAversion,
  builderCustomerKey,
  builderGoSettings,
  viewBenefitDetails,
  fetchSupplementalPlans,
}: OverviewPageProps) => {
  const { retrieveContentfulData } = useTextContext();

  useEffect(() => {
    // Load supplemental plans
    fetchSupplementalPlans();
  }, []);

  const [suppRecsState, setSuppRecsState] = useState<SupplementalRecommendations>();
  useEffect(() => {
    if (!_.isEmpty(supplementalRecommendations) && !suppRecsState) {
      sendInsightsEvent(null, 'fbs_recommendations', {
        fbs_recommendations: supplementalRecommendations,
      });
      setSuppRecsState(supplementalRecommendations);
    }
  }, [supplementalRecommendations]);

  const handleGoToBenefit = (insuranceType) => {
    viewBenefitDetails(insuranceType);
  };

  const educationalBenefits = retrieveContentfulData<ContentfulFbsBenefit[]>(
    'benefits_section.educational_benefits',
    [],
  );

  const builderBenefitsArray = retrieveContentfulData<BuilderBenefit[]>(
    'benefits_section_additional.supplemental_plans',
    [],
  );

  const builderBenefits: Record<string, BuilderBenefit> = builderBenefitsArray.reduce(
    (acc, i, idx) => ({
      [i.plan_id]: {
        ...i,
        path: `benefits_section_additional.supplemental_plans[${idx}]`,
      },
      ...acc,
    }),
    {},
  );

  const getEducationalBenefitTypes = () => educationalBenefits.map((benefit) => benefit.plan_type);

  const healthHsaEligible = isHealthPlanSelected && selectedHealthPlan.plan.hsa_eligible;

  const planCount = Object.entries(supplementalPlans).filter(([key]) =>
    getSelectableBenefits(client).includes(key as InsuranceType),
  ).length;

  const otherCount = Object.entries(supplementalPlans).filter(
    ([key]) =>
      getNonSelectableBenefits(client).includes(key as InsuranceType) && !['hsa', 'hra'].includes(key),
  ).length;

  const selectableBenefits = getSelectableBenefits(client).filter(
    (insuranceType) => insuranceType in supplementalPlans,
  );

  const totalCount = planCount + otherCount;

  // Given a group, return true if it contains at least one eligible benefit
  const hasEligibleBenefitsinGroup = (groupBenefits) => {
    const eligiblePlanTypes = [...getEducationalBenefitTypes(), ...Object.keys(supplementalPlans)];

    // Iterate group benefits, if benefit is included in eligible benefits return true,
    // If benefit is hsa, only return true if selectedHealthPlan is hsa_eligible
    return groupBenefits.plan_types.some(
      ({ plan_type }) => eligiblePlanTypes.includes(plan_type) && (plan_type !== 'hsa' || healthHsaEligible),
    );
  };

  const fbsBestMatch = Object.values(supplementalRecommendations).some((i) => i.label === 'best');

  const renderGroups = () => (
    <>
      {/* TICKETING THIS TO BE CLEANED UP LATER. THESE ITEMS SHOULD BE REMOVED FROM DJANGO clientconfig TABLE */}
      {fbsBenefitGroups?.map((g) => {
        if (hasEligibleBenefitsinGroup(g)) {
          const group = {
            ...g,
            plan_types:
              g.title === 'Tax-Free Accounts'
                ? g.plan_types.filter(
                    (item) =>
                      item.plan_type !== 'hsa' &&
                      item.plan_type !== 'hra' &&
                      // Possible typo 'fsa_medical' - ask @jakeblank what's going on here
                      item.plan_type !== 'fsa-medical',
                  )
                : g.plan_types,
          };
          return (
            <GroupSection key={group.title} data-testid={`group-${group.title}`}>
              <SecondaryHeader className="header">
                <H4 as="h2" weight="normal">
                  {group.title}
                </H4>
                {group.description && <Tooltip id={group.title} label={group.description} />}
              </SecondaryHeader>

              <FbsGrid>
                {group.plan_types
                  .sort((a, b) => a.position - b.position)
                  .map((item) => {
                    const insuranceType = item.plan_type as InsuranceType;

                    if (!Object.keys(supplementalPlans).includes(insuranceType)) {
                      if (!getEducationalBenefitTypes().includes(insuranceType)) return null;
                    }

                    if (
                      getSelectableBenefits(client).includes(insuranceType) &&
                      supplementalPlans[insuranceType]
                    ) {
                      return (
                        <SupplementalCard
                          key={insuranceType}
                          client={client}
                          employerName={employerName}
                          insuranceType={insuranceType}
                          builderBenefits={builderBenefits}
                          supplementalPlans={supplementalPlans[insuranceType]?.plans as SupplementalPlan[]}
                          selectedPlan={selectedSupplementalPlans[insuranceType] as SupplementalPlan}
                          coveredMembers={planCoveredMembers[insuranceType] as CoveredMembers}
                          handleOpenBenefit={handleGoToBenefit}
                          recommendedLabel={supplementalRecommendations[insuranceType]?.label}
                          healthHsaEligible={healthHsaEligible}
                          reasonsWhy={reasonsWhy}
                          selectedHealthDeductible={selectedHealthDeductible}
                          dependentsCount={dependentsCount}
                          surveyAnswers={surveyAnswers}
                          riskAversion={riskAversion}
                          fbsBestMatch={fbsBestMatch}
                          builderCustomerKey={builderCustomerKey}
                          builderGoSettings={builderGoSettings}
                        />
                      );
                    }

                    if (
                      getNonSelectableBenefits(client).includes(insuranceType) &&
                      !getEducationalBenefitTypes().includes(insuranceType)
                    ) {
                      return (
                        <SupplementalCard
                          key={insuranceType}
                          client={client}
                          employerName={employerName}
                          insuranceType={insuranceType}
                          supplementalPlans={supplementalPlans[insuranceType]?.plans as SupplementalPlan[]}
                          builderBenefits={builderBenefits}
                          handleOpenBenefit={handleGoToBenefit}
                          // Plans like EAP can be auto-enrolled
                          // need to pass selectedPlan so this displays on FBS page as auto-enrolled
                          selectedPlan={
                            selectedSupplementalPlans[insuranceType]
                              ? (selectedSupplementalPlans[insuranceType] as SupplementalPlan)
                              : null
                          }
                          healthHsaEligible={healthHsaEligible}
                          recommendedLabel={supplementalRecommendations[insuranceType]?.label}
                          reasonsWhy={reasonsWhy}
                          selectedHealthDeductible={selectedHealthDeductible}
                          dependentsCount={dependentsCount}
                          surveyAnswers={surveyAnswers}
                          riskAversion={riskAversion}
                          fbsBestMatch={fbsBestMatch}
                          builderCustomerKey={builderCustomerKey}
                          builderGoSettings={builderGoSettings}
                        />
                      );
                    }
                    if (getEducationalBenefitTypes().includes(insuranceType)) {
                      return (
                        <SupplementalCard
                          builderBenefits={builderBenefits}
                          selectedPlan={
                            selectedSupplementalPlans[insuranceType]
                              ? (selectedSupplementalPlans[insuranceType] as SupplementalPlan)
                              : null
                          }
                          key={insuranceType}
                          client={client}
                          employerName={employerName}
                          insuranceType={insuranceType}
                          isEducational
                          handleOpenBenefit={handleGoToBenefit}
                          healthHsaEligible={healthHsaEligible}
                          recommendedLabel={supplementalRecommendations[insuranceType]?.label}
                          reasonsWhy={reasonsWhy}
                          selectedHealthDeductible={selectedHealthDeductible}
                          dependentsCount={dependentsCount}
                          surveyAnswers={surveyAnswers}
                          riskAversion={riskAversion}
                          contentfulPlan={
                            educationalBenefits.find(
                              (i) => i.plan_type === insuranceType,
                            ) as ContentfulFbsBenefit
                          }
                          builderCustomerKey={builderCustomerKey}
                          builderGoSettings={builderGoSettings}
                        />
                      );
                    }

                    return null;
                  })}
              </FbsGrid>
            </GroupSection>
          );
        }
        return null;
      })}
    </>
  );

  const renderSupplementalPlans = () =>
    selectableBenefits.map((insuranceType) => (
      <SupplementalCard
        key={insuranceType}
        client={client}
        employerName={employerName}
        insuranceType={insuranceType}
        builderBenefits={builderBenefits}
        supplementalPlans={supplementalPlans[insuranceType]?.plans as SupplementalPlan[]}
        selectedPlan={selectedSupplementalPlans[insuranceType] as SupplementalPlan}
        coveredMembers={planCoveredMembers[insuranceType] as CoveredMembers}
        handleOpenBenefit={handleGoToBenefit}
        recommendedLabel={supplementalRecommendations[insuranceType]?.label}
        healthHsaEligible={healthHsaEligible}
        reasonsWhy={reasonsWhy}
        selectedHealthDeductible={selectedHealthDeductible}
        dependentsCount={dependentsCount}
        surveyAnswers={surveyAnswers}
        riskAversion={riskAversion}
        fbsBestMatch={fbsBestMatch}
        builderCustomerKey={builderCustomerKey}
        builderGoSettings={builderGoSettings}
      />
    ));

  const renderOtherPlans = () =>
    getNonSelectableBenefits(client).map((insuranceType) => {
      // check if insurance type exists in supplemental plan json
      if (insuranceType in supplementalPlans && !getEducationalBenefitTypes().includes(insuranceType)) {
        return (
          <SupplementalCard
            key={insuranceType}
            client={client}
            employerName={employerName}
            insuranceType={insuranceType}
            builderBenefits={builderBenefits}
            supplementalPlans={supplementalPlans[insuranceType]?.plans as SupplementalPlan[]}
            handleOpenBenefit={handleGoToBenefit}
            // Plans like EAP can be auto-enrolled
            // need to pass selectedPlan so this displays on FBS page as auto-enrolled
            selectedPlan={
              selectedSupplementalPlans[insuranceType]
                ? (selectedSupplementalPlans[insuranceType] as SupplementalPlan)
                : null
            }
            recommendedLabel={supplementalRecommendations[insuranceType]?.label}
            healthHsaEligible={healthHsaEligible}
            reasonsWhy={reasonsWhy}
            selectedHealthDeductible={selectedHealthDeductible}
            dependentsCount={dependentsCount}
            surveyAnswers={surveyAnswers}
            riskAversion={riskAversion}
            fbsBestMatch={fbsBestMatch}
            builderCustomerKey={builderCustomerKey}
            builderGoSettings={builderGoSettings}
          />
        );
      }

      return null;
    });

  const renderEducationalPlans = () =>
    educationalBenefits.map((benefit) => {
      const insuranceType = benefit.plan_type as InsuranceType;
      return (
        <SupplementalCard
          key={insuranceType}
          client={client}
          employerName={employerName}
          insuranceType={insuranceType}
          contentfulPlan={benefit}
          handleOpenBenefit={handleGoToBenefit}
          healthHsaEligible={healthHsaEligible}
          recommendedLabel={supplementalRecommendations[insuranceType]?.label}
          reasonsWhy={reasonsWhy}
          selectedHealthDeductible={selectedHealthDeductible}
          dependentsCount={dependentsCount}
          surveyAnswers={surveyAnswers}
          riskAversion={riskAversion}
          builderCustomerKey={builderCustomerKey}
          isEducational
          builderGoSettings={builderGoSettings}
        />
      );
    });

  const renderEnrolleeInfoBar = () => {
    if (showEnrolleeSelectDisclaimer && coveredHouseholdMemberCount > 1 && selectableBenefits.length > 0) {
      return (
        <ContentfulInfoBar
          field="benefits_section_additional.enrollee_select_disclaimer"
          key="benefits_section_additional.enrollee_select_disclaimer"
        />
      );
    }
    return undefined;
  };

  return (
    <>
      <ContentfulHeaderWrapper sectionKey="benefits_section_additional" animate={isFirstPageView} />
      <ContentfulInfoBars sectionKey="benefits_section_additional">
        {renderEnrolleeInfoBar()}
      </ContentfulInfoBars>
      <PageLayout page="overview">
        <FadeUp isOpen={!isLoading}>
          {totalCount > 0 ? (
            fbsBenefitGroups?.length > 0 ? (
              <>{renderGroups()}</>
            ) : (
              <FbsGrid>
                {renderSupplementalPlans()}
                {renderOtherPlans()}
                {renderEducationalPlans()}
              </FbsGrid>
            )
          ) : (
            <NoBenefits>
              <InfoBar icon="WarningCircle" text={<Text field="benefits_section.no_benefits_available" />} />
            </NoBenefits>
          )}
        </FadeUp>
        <FadeUp isOpen={isLoading}>
          <SpinnerLayout>
            <Spinner color="--input-gray" />
          </SpinnerLayout>
        </FadeUp>
      </PageLayout>
    </>
  );
};

const mapStateToProps = createStructuredSelector<GlobalReducerState, OverviewPageStateProps>({
  client: makeSelectConfigField('client'),
  employerName: makeSelectJvpField('employerName'),
  supplementalPlans: makeSelectOverviewField('supplemental_plans_full_household'),
  supplementalRecommendations: makeGetSupplementalPlanRecommendations(),
  planCoveredMembers: makeSelectOverviewField('selected_plans_covered_members'),
  isHealthPlanSelected: makeGetIsHealthPlanSelected(),
  isLoading: makeSelectOverviewField('isLoading'),
  selectedSupplementalPlans: makeSelectOverviewField('selected_plans'),
  selectedHealthPlan: makeSelectCommercialField('selectedHealthPlan'),
  fbsBenefitGroups: makeSelectConfigField('fbs_groups'),
  isFirstPageView: selectIsFirstPageView(OVERVIEW_PATH),
  coveredHouseholdMemberCount: makeGetCoveredHouseholdMemberCount(),
  showEnrolleeSelectDisclaimer: makeSelectConfigField('show_enrollee_select_disclaimer'),
  reasonsWhy: makeGetSupplementalPlanReasonsWhy(),
  selectedHealthDeductible: makeGetSelectedHealthDeductible(),
  dependentsCount: makeSelectDependentsCount(),
  surveyAnswers: makeSelectSurvey(),
  riskAversion: getRiskAversion(),
  builderCustomerKey: makeSelectConfigField('builder_customer_key'),
  builderGoSettings: makeGetBuilderGoSettings(),
});

export function mapDispatchToProps(dispatch): OverviewPageDispatchProps {
  return {
    fetchSupplementalPlans: () => dispatch(getSupplementalPlans()),
    viewBenefitDetails: (insuranceType) => dispatch(push(`${VIEW_BENEFIT_PATH}/${insuranceType}`)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSaga({
  key: 'overviewPage',
  saga,
});

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default compose(withSaga, withConnect)(OverviewPage);
