import { Box } from '@chakra-ui/react';
import React, { useEffect, useRef } from 'react';
import Select, { components, OptionProps } from 'react-select';

import { ProviderCard } from 'Containers/ResultPage/ProviderSelectionContent/ProviderCard';
import { useTextContext } from 'Contexts/textContext';
import { Icon } from 'DesignLibrary/atoms';
import Text from 'Shared/Text';
import { ProviderLocationProps } from 'Types/entities';
import { isValidZipCode } from 'Utils/helpers';

import { CustomWrapper } from '../styled';

interface ProviderSelectProps {
  setSearchText: (prev: string) => void;
  setShowSearchPage: (value: boolean) => void;
  setOptions: (options: []) => void;
  options: ProviderLocationProps[] | [];
  zipcode: string;
  setSelectedProvider: (value: ProviderLocationProps) => void;
  searchText: string;
  fetched: boolean;
  setFetched: (val: boolean) => void;
  showLoading: boolean;
  setShowLoading: (value: boolean) => void;
  distanceRef: React.RefObject<HTMLDivElement>;
}

const customFilter = () => true;

const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <Box pos="absolute" left={4} top={2.5}>
      <Icon type="Search" color="--text-gray" />
    </Box>
  </components.DropdownIndicator>
);

// Custom Option component
const CustomOption = (props: OptionProps<ProviderLocationProps>) => {
  const { data } = props;

  const providerType =
    data.providerTypes && data.providerTypes.length > 0 ? data.providerTypes.join(', ') : data.providerTypes;

  const locations = data.locations.length;

  const subtitle = (
    <>
      {providerType}{' '}
      <Box display="inline-block" fontSize={12}>
        &#8226;
      </Box>{' '}
      {locations}{' '}
      {data.locations.length > 1 ? (
        <Text field="health_section.provider_selection_modal.locations" />
      ) : (
        <Text field="health_section.provider_selection_modal.location" />
      )}
    </>
  );
  return (
    <components.Option {...props}>
      <CustomWrapper>
        <div className="container">
          <ProviderCard
            variant="search"
            title={data.label}
            subtitle={subtitle}
            testId="provider-result-name"
          />
          {/* <img src={Stethoscope} alt="" data-testid="stethoscope-image" /> */}
          {/* <div>{data.label}</div>
          <div data-testid="provider-type" className="provider-type">
            {data.providerTypes && data.providerTypes.length > 0
              ? data.providerTypes.join(', ')
              : data.providerTypes}{' '}
            <img src={Dot} alt="" />
            {data.locations.length}
            {data.locations.length > 1 ? (
              <Text field="health_section.provider_selection_modal.locations" />
            ) : (
              <Text field="health_section.provider_selection_modal.location" />
            )}
          </div> */}
        </div>
      </CustomWrapper>
    </components.Option>
  );
};

const customStyles = {
  control: (provided) => ({
    ...provided,
    border: '2px solid var(--input-gray)',
    borderRadius: '6px',
    display: 'flex',
    alignItems: 'center',
    height: '48px',
  }),
  input: (provided) => ({
    ...provided,
    width: '100%',
    marginLeft: 'var(--sizing-xl)',
    fontSize: '18px',
    whiteSpace: 'nowrap',
  }),
  placeholder: (provided) => ({
    ...provided,
    marginLeft: 'var(--sizing-xl)',
    fontSize: '18px',
    whiteSpace: 'nowrap',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  singleValue: (provided) => ({
    ...provided,
    marginLeft: 'var(--sizing-xl)',
  }),
};

const ProviderSelect = ({
  setSearchText, // Function to set searched text
  setShowSearchPage, // Function to check add button is clicked and set show search page
  searchText,
  setOptions, // Function to set distance options
  options, // Distance options
  zipcode,
  fetched, // Flag to display no option message
  setFetched, // Function is to display no option message
  setSelectedProvider, // Function to set selected provider
  showLoading, // Flag to show loading icon
  setShowLoading, // Function to set loading option
  distanceRef,
}: ProviderSelectProps) => {
  // Handler for the input change
  const handleInputChange = (newValue: string) => {
    setFetched(false);
    setSearchText(newValue);
    setOptions([]);
    setShowLoading(true);
  };
  const { retrieveContentfulData } = useTextContext();
  const searchPlaceholder = retrieveContentfulData<string>(
    `health_section.provider_selection_modal.search_placeholder`,
  ) as unknown as string;
  const searchCharLimitCta = retrieveContentfulData<string>(
    `health_section.provider_selection_modal.search_char_limit_cta`,
  ) as unknown as string;
  const searchNoResultsCta = retrieveContentfulData<string>(
    `health_section.provider_selection_modal.search_no_results_cta`,
  ) as unknown as string;

  // Handler for onChange provider
  const handleOnChange = (inputValue) => {
    setShowLoading(false); // Function to hide loading message
    setSelectedProvider(inputValue); // Function to set current selected provider
    setShowSearchPage(false); // Function to hide doctor list option
  };

  // Handler for menu close
  const handleMenuClose = () => {
    setShowLoading(false);
    setSearchText(searchText);
  };
  const selectRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        selectRef.current &&
        !selectRef.current.contains(event.target) &&
        distanceRef.current &&
        !distanceRef.current.contains(event.target)
      ) {
        setOptions([]);
        setFetched(false);
        setShowLoading(false);
      }
    };
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  const getNoOptionsMessage = ({ inputValue }) =>
    isValidZipCode(zipcode)
      ? inputValue.length < 3
        ? searchCharLimitCta
        : fetched && !options.length
          ? searchNoResultsCta
          : null
      : null;

  return (
    <div ref={selectRef}>
      <Select
        filterOption={customFilter}
        onInputChange={handleInputChange}
        onChange={handleOnChange}
        options={isValidZipCode(zipcode) ? options : []}
        placeholder={searchPlaceholder}
        backspaceRemovesValue
        isSearchable
        components={{ DropdownIndicator, Option: CustomOption }}
        styles={customStyles}
        isDisabled={!isValidZipCode(zipcode)}
        noOptionsMessage={getNoOptionsMessage}
        isLoading={searchText.length > 2 && !fetched && showLoading}
        loadingMessage={() =>
          retrieveContentfulData<string>(
            `health_section.provider_selection_modal.loading`,
          ) as unknown as string
        }
        inputId="name-input"
        data-testid="provider-select"
        onMenuClose={handleMenuClose}
        inputValue={searchText}
        menuIsOpen={searchText.length > 0}
      />
    </div>
  );
};

export default ProviderSelect;
