import styled from 'styled-components';

import { Group, tierColor } from '../_shared/styled';

export const UsageContainer = styled.div`
  .paragraph,
  p {
    b {
      color: var(--text-black) !important;
    }
  }
`;

interface DollarIconProps {
  group: Group;
}

export const DollarIcon = styled.span<DollarIconProps>`
  background: ${({ group }) => tierColor(group)};
  display: inline-block;
  border-radius: var(--border-radius-xs);
  padding: 0 var(--sizing-xs);
  color: ${({ group }) => (group === 'moderate' ? 'var(--text-black)' : 'var(--primary-white)')};
  font-weight: 600;
  text-transform: capitalize;
`;
