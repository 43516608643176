import React, { FC } from 'react';

import { MockRadio } from './styled';
import { Icon, IconTypes } from '../../Icon';
import { Paragraph } from '../../typography';

// STYLLES

export interface RadioPseudoProps {
  sizing?: 'small' | 'normal';
  isChecked: boolean;
  color?: string;
  iconOverride?: IconTypes;
  children?;
  uniqueId?: string;
}

export const RadioPseudo: FC<RadioPseudoProps> = ({
  sizing = 'normal',
  isChecked,
  color = '--primary-green',
  iconOverride = 'Check',
  children,
  uniqueId,
}) => (
  <MockRadio
    className="pseudo-radio"
    data-testid="pseudo-radio"
    sizing={sizing}
    isChecked={isChecked}
    color={color}
  >
    <div id={uniqueId}>
      <div className="radio-button" aria-hidden="true">
        {isChecked && (
          <Icon
            data-testid="check"
            size={sizing}
            color={color === '--primary-yellow' ? '--colors-yellow-600' : '--background-white'}
            type={iconOverride}
            weight="bold"
          />
        )}
      </div>
    </div>
    {children && (
      <Paragraph size="small" weight="bold" color="--text-black">
        {children}
      </Paragraph>
    )}
  </MockRadio>
);
