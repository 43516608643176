import { isEmpty } from 'lodash';
import React from 'react';

import { useTextContext } from 'Contexts/textContext';
import InfoBar from 'DesignLibrary/molecules/InfoBar';

const Disclaimer = () => {
  const { retrieveContentfulData } = useTextContext();
  const domesticPartnerDisclaimer = retrieveContentfulData<Record<string, string>>(
    'profile_section.domestic_partner_disclaimer',
  );

  return !isEmpty(domesticPartnerDisclaimer) ? (
    <InfoBar header={domesticPartnerDisclaimer.title} text={domesticPartnerDisclaimer.text} />
  ) : null;
};

export default Disclaimer;
