import styled, { css, keyframes } from 'styled-components';

import { media } from 'DesignLibrary/global';
import { cardStyling } from 'DesignLibrary/utils';

export const Container = styled.section`
  .details {
    margin-top: var(--sizing-sm);
    display: flex;
    flex-direction: column;
    gap: var(--sizing-sm);

    ${media.TABLET`
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 0 var(--sizing-sm);
      align-items: flex-start;
    `}
  }
`;

export const GraphContainer = styled.div`
  ${cardStyling}

  .chart-container {
    position: relative;
    padding: var(--sizing-sm) var(--sizing-sm) var(--sizing-sm) 0;
    ${media.TABLET`
      padding: var(--sizing-lg) var(--sizing-md) var(--sizing-lg) 0;
    `}
  }

  .legend {
    padding: var(--sizing-sm);
    display: flex;
    gap: 0 var(--sizing-sm);
    flex-wrap: wrap;

    ${media.TABLET`
      padding: var(--sizing-md);
    `}
  }
`;

export const GraphLine = styled.div`
  margin-bottom: 0.5rem;
  width: 100%;
  height: var(--sizing-xl);
  display: flex;
`;

export type LinePosition = 'start' | 'middle' | 'end' | 'whole';

interface LineProps {
  type: string;
  percent: number;
  position: LinePosition;
}

const barberpole = keyframes`
  100% {
    background-position: -1.75rem;
  }
`;

export const Line = styled.div.attrs<{ percent: number }>(({ percent }) => ({
  style: { width: `${percent}%` },
}))<LineProps>`
  background: ${({ type }) => {
    switch (type) {
      case 'out-of-pocket':
      case 'goal':
        return 'var(--colors-opaque-10)';
      case 'employer':
        return 'var(--colors-blue-300)';
      case 'recommended':
      case 'savings':
        return 'var(--primary-blue)';
      case 'remaining':
        return 'var(--colors-yellow-300)';
      default:
        return null;
    }
  }};
  border-radius: ${({ position }) => {
    switch (position) {
      case 'end':
        return '0 var(--border-radius-xs) var(--border-radius-xs) 0';
      case 'start':
      case 'middle':
      default:
        return '0';
    }
  }};
  display: ${({ percent }) => (percent === 0 ? 'none' : 'flex')};
  min-width: 0.25rem;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: 0.2s border-radius;
  will-change: width;

  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    overflow: hidden;
    background-image: repeating-linear-gradient(
      -55deg,
      transparent,
      transparent 1rem,
      rgba(0, 0, 0, 0.025) 1rem,
      rgba(0, 0, 0, 0.025) 2rem
    );
    background-size: 200% 200%;
    animation: ${barberpole} 1s linear infinite;
  }

  span {
    z-index: 1;
    font-size: 18px;
    font-weight: 600;
    color: ${({ type }) => {
      switch (type) {
        case 'out-of-pocket':
        case 'remaining':
          return 'var(--text-black)';
        case 'goal':
          return 'var(--text-black)';
        case 'savings':
          return 'var(--primary-white)';
        default:
          return 'var(--primary-white)';
      }
    }};

    ${({ percent }) =>
      percent &&
      percent <= 14 &&
      css`
        display: block;
        position: absolute;
        top: 12px;
        right: 12px;
        background: var(--text-black);
        padding: 0.125rem 0.25rem;
        border-radius: 4px;
        font-size: 13px;
        color: var(--primary-white);
      `}

    ${({ percent }) =>
      percent &&
      percent <= 10 &&
      css`
        top: 12px;
        left: 12px;
        right: auto;
        white-space: nowrap;
      `}
  }
`;

interface LegendProps {
  type:
    | 'oop'
    | 'projectedHsaBalance'
    | 'employer'
    | 'recommended'
    | 'remaining'
    | 'retirementHealthcareCosts'
    | string;
}

export const Legend = styled.div<LegendProps>`
  position: relative;
  display: inline-flex;
  align-items: center;

  &:before {
    content: '';
    display: inline-block;
    height: 0.65rem;
    width: 0.65rem;
    border-radius: 1rem;
    margin-right: 0.25rem;
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
    background: ${({ type }) => {
      switch (type) {
        case 'projectedHsaBalance':
          return 'var(--primary-blue)';
        case 'employer':
          return 'var(--colors-blue-300)';
        case 'recommended':
          return 'var(--primary-blue)';
        case 'remaining':
          return 'var(--colors-yellow-300)';
        case 'oop':
        case 'retirementHealthcareCosts':
          return 'var(--colors-gray-300)';
        default:
          return 'var(--colors-gray-300)';
      }
    }};
  }
`;

export const InfoContainer = styled.div`
  ${cardStyling}

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--sizing-sm);

    ${media.TABLET`
      padding: var(--sizing-sm) var(--sizing-md);
    `}
  }

  & > div + div {
    border-top: 1px solid var(--border-gray);
  }

  .flex {
    display: flex;
    align-items: center;
    gap: var(--sizing-xs);
  }
`;
