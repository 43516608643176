import { SimpleGrid, Box, Stack, Show } from '@chakra-ui/react';
import React from 'react';

import { ProviderCard } from 'Containers/ResultPage/ProviderSelectionContent/ProviderCard';
import { Paragraph } from 'DesignLibrary/atoms';
import Text from 'Shared/Text';

export const NoProviderSkeleton = () => {
  return (
    <SimpleGrid columns={[1, 2]} gap={10} px={8} py={16} alignItems="center">
      <Show above="sm">
        <Stack gap={2}>
          <ProviderCard isSkeleton />
          <ProviderCard isSkeleton />
        </Stack>
      </Show>
      <Box>
        <Paragraph lineHeight={1.75}>
          <Text field="health_section.provider_selection_modal.create_list_description" />
        </Paragraph>
      </Box>
    </SimpleGrid>
  );
};
