import React from 'react';

import Accident from 'Assets/supplemental-icons/accident.png';
import Add from 'Assets/supplemental-icons/add.png';
import Commuter from 'Assets/supplemental-icons/commuter.png';
import Critical from 'Assets/supplemental-icons/critical-illness.png';
import Dental from 'Assets/supplemental-icons/dental.png';
import Other from 'Assets/supplemental-icons/discount.png';
import EmployeeAssistanceProgram from 'Assets/supplemental-icons/eap.png';
import FinancialWellness from 'Assets/supplemental-icons/financial-wellness.png';
import FsaAdoption from 'Assets/supplemental-icons/fsa-aa.png';
import FsaDependent from 'Assets/supplemental-icons/fsa-dc.png';
import Fsa from 'Assets/supplemental-icons/fsa.png';
import Health from 'Assets/supplemental-icons/health.png';
import Hospital from 'Assets/supplemental-icons/hospital-indemnity.png';
import Hra from 'Assets/supplemental-icons/hra.png';
import Hsa from 'Assets/supplemental-icons/hsa.png';
import IdTheft from 'Assets/supplemental-icons/id-theft.png';
import Legal from 'Assets/supplemental-icons/legal.png';
import Life from 'Assets/supplemental-icons/life.png';
import Ltd from 'Assets/supplemental-icons/ltd.png';
import Medicare from 'Assets/supplemental-icons/medicare.png';
import Pet from 'Assets/supplemental-icons/pet.png';
import Retirement from 'Assets/supplemental-icons/retirement.png';
import Std from 'Assets/supplemental-icons/std.png';
import Teledoc from 'Assets/supplemental-icons/telemed.png';
import Vision from 'Assets/supplemental-icons/vision.png';
import Wellness from 'Assets/supplemental-icons/wellness.png';
import { InsuranceType } from 'Containers/ViewBenefitPage/types';

import { SupplementalIconContainer } from './styled';

export interface SupplementalIconProps {
  type?: InsuranceType | 'health' | 'sihra' | 'other' | string;
  size?: 'large' | 'normal' | 'small' | 'mini';
}

export const SupplementalIcons = {
  health: Health,
  dental: Dental,
  vision: Vision,
  accident: Accident,
  critical: Critical,
  hospital: Hospital,
  pet: Pet,
  life: Life,
  life_add: Life,
  add: Add,
  std: Std,
  ltd: Ltd,
  medicare: Medicare,
  retirement: Retirement,
  '401k': Retirement,
  fsa: Fsa,
  fsa_medical: Fsa,
  fsa_dependent: FsaDependent,
  fsa_adoption: FsaAdoption,
  legal: Legal,
  employee_assistance_program: EmployeeAssistanceProgram,
  wellness: Wellness,
  financial_wellness: FinancialWellness,
  '403b': Retirement,
  commuter: Commuter,
  teledoc: Teledoc,
  id_theft: IdTheft,
  hsa: Hsa,
  hra: Hra,
  sihra: Hra,
  other: Other,
};

export const SupplementalIcon = ({ type = 'other', size = 'normal' }: SupplementalIconProps) => {
  const typeExists = Object.keys(SupplementalIcons).includes(type);
  const renderType = typeExists ? type : 'other';

  return (
    <SupplementalIconContainer size={size}>
      <img src={SupplementalIcons[renderType]} alt={type} />
    </SupplementalIconContainer>
  );
};
