import styled from 'styled-components';

import { media } from 'DesignLibrary/global';
import { cardStyling } from 'DesignLibrary/utils';

export const PricingOverviewWrapper = styled.div`
  ${cardStyling}
  display: flex;
  flex-direction: column;
  padding: var(--sizing-sm);
  gap: var(--sizing-sm);

  ${media.TABLET`
    flex-direction: row;
    padding: var(--sizing-md);
  `}
`;

export const PricingOverviewItem = styled.div`
  border-bottom: 2px solid var(--border-gray);
  padding: var(--sizing-sm) 0;
  display: flex;
  flex-direction: column;

  ${media.TABLET`
    border: 0;
    border-right: 2px solid var(--border-gray);
    padding: 0;
    flex-basis: 100%;
    gap: 2px;
  `}

  > :first-child {
    order: 2;
  }

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
    border: 0;
  }
`;
