import styled from 'styled-components';

import { media } from 'DesignLibrary/global';
import { cardStyling } from 'DesignLibrary/utils';

interface QuestionProps {
  error: boolean;
}

export const QuestionContainer = styled.section<QuestionProps>`
  ${cardStyling}
  position: relative;

  ${media.TABLET`
    display: flex;
    flex-direction: column;
  `}
`;

export const QuestionContent = styled.div`
  flex-grow: 2;
  padding: var(--sizing-md);
  ${media.TABLET`padding: var(--sizing-sm) var(--sizing-lg) var(--sizing-lg);`}

  .client-message {
    margin-bottom: var(--sizing-sm);
  }

  & > * + * {
    margin-top: var(--sizing-lg);
  }
`;

export const QuestionWrapper = styled.div`
  & > * + * {
    margin-top: var(--sizing-sm);
  }

  & > .paragraph {
    margin-bottom: var(--sizing-sm);
  }

  fieldset {
    flex-direction: column;

    ${media.TABLET`
      flex-direction: row;
    `}
  }
`;

interface SideTabProps {
  error: boolean;
  incomplete?: boolean;
}

export const SideTab = styled.div<SideTabProps>`
  /* border: 3px solid; */
  /* border-bottom: 0; */
  /* border-color: ${({ error, incomplete }) =>
    !incomplete ? (error ? 'var(--primary-red)' : 'transparent') : 'var(--colors-green-200)'}; */

  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  height: var(--sizing-xl);
  padding: 0 var(--sizing-md);
  ${media.TABLET`padding: 0 var(--sizing-lg);`}
  flex-shrink: 0;
  border-radius: var(--border-radius-xs) var(--border-radius-xs) 0 0;
  transition:
    background-color 0.5s,
    color 0.5s;

  + div {
    /* border: 3px solid; */
    /* border-radius: 0 0 var(--border-radius-xs) var(--border-radius-xs); */
    /* border-top: 0; */
    /* border-color: ${({ error, incomplete }) =>
      !incomplete ? (error ? 'var(--primary-red)' : 'transparent') : 'var(--colors-green-200)'}; */
  }

  .title h2 {
    color: var(--primary-blue);
    font-weight: 800;
    font-size: 14px;
  }

  .icon {
    display: flex;
    align-items: center;
    opacity: 0.4;

    &:hover {
      opacity: 1;
    }
  }
`;
