import styled, { css } from 'styled-components';

import { breakpoint } from 'DesignLibrary/global';

interface BarPositionProps {
  isOpen: boolean;
}

interface PlaceholderProps {
  empty: boolean;
}

export const BarPosition = styled.div<BarPositionProps>`
  position: fixed;
  bottom: calc(var(--sizing-2xl) + var(--sizing-xs));
  z-index: 1299;
  width: 100%;
  height: 0;
`;

export const BarContainer = styled.div`
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  padding: var(--sizing-xs);
  background-color: var(--primary-white);
  border: 2px solid var(--colors-gray-300);
  box-shadow: var(--shadow-large);
  border-radius: var(--border-radius-xs);

  ${breakpoint.TABLET} {
    flex-direction: row;
    gap: var(--sizing-xs);
    padding-right: 12px;
    width: calc(100% + 32px);
    left: -16px;
  }
`;

export const PlanWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: var(--sizing-xs);
  overflow: hidden;
  width: 100%;

  ${breakpoint.TABLET} {
    width: auto;
  }
`;

const ItemPrimitive = css`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-radius: var(--border-radius-xs);
  border: var(--border-thick);
  height: var(--sizing-xl);
  min-width: 0;
  width: 100%;
  padding: 0 var(--sizing-xs);
  overflow: hidden;

  ${breakpoint.TABLET} {
    width: 240px;
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: var(--sizing-xs);

  ${breakpoint.TABLET} {
    width: var(--sizing-4xl);
    margin-top: 0;
  }
`;

export const Plan = styled.div`
  ${ItemPrimitive}
  transition: 0.1s background-color;
  padding-left: var(--sizing-xs);
  justify-content: space-between;

  > div {
    display: flex;
    align-items: center;
    gap: 12px;
    min-width: 0;
  }

  button {
    flex-shrink: 0;
  }

  .paragraph {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const Placeholder = styled.div<PlaceholderProps>`
  ${ItemPrimitive}
  border: 2px dashed var(--input-gray);
  justify-content: center;
  text-align: center;

  .paragraph {
    line-height: 1 !important;
  }
`;
