import styled from 'styled-components';

import { FadeInLeftKeyframes } from 'DesignLibrary/keyframes';

export const EnrollmentContainer = styled.section`
  max-width: 32rem;

  .pseudo-toggle {
    text-align: left;
    justify-content: flex-start;
    padding-left: var(--sizing-sm);
  }
`;

export const ExplainerContainer = styled.div`
  opacity: 0;
  animation: ${FadeInLeftKeyframes} 1s 1.75s forwards;
  margin-top: 10px;
  width: 100%;
  background: rgba(255, 0, 0, 0.4);
  padding: 20px 20px;
`;
