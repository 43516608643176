import styled from 'styled-components';

export const ErrorContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: var(--sizing-3xl);
  min-height: 80vh;

  & > * + * {
    margin-top: var(--sizing-md);
  }

  .paragraph {
    max-width: 480px;
    text-align: center;
  }
`;
