import { Box, Flex, SimpleGrid, Stack } from '@chakra-ui/react';
import React from 'react';

import { ProviderCard } from 'Containers/ResultPage/ProviderSelectionContent/ProviderCard';
import { Icon, Paragraph } from 'DesignLibrary/atoms';
import Text from 'Shared/Text';
import { ProviderSectionProps } from 'Types/entities';

export const ProviderSection = ({ type, providers }: ProviderSectionProps) => {
  const providersCount = providers!.length;

  let iconColor = '--text-gray';

  if (providersCount > 0) {
    if (type === 'in-network') {
      iconColor = '--primary-green';
    } else {
      iconColor = '--primary-red';
    }
  }

  return (
    <Stack gap={1}>
      <Flex align="center" gap={2}>
        <Icon color={iconColor} type={type === 'in-network' ? 'CheckCircle' : 'XCircle'} />
        <Paragraph
          size="small"
          as="h5"
          weight="bold"
          color={providersCount > 0 ? '--text-black' : '--text-gray'}
          data-testid="network-text"
        >
          {type === 'in-network' ? (
            <Text field="health_section.compare_plan_text.compareModal.inNetwork" />
          ) : (
            <Text field="health_section.compare_plan_text.compareModal.outOfNetwork" />
          )}
        </Paragraph>
      </Flex>
      <SimpleGrid columns={[1, 2]} gap={2}>
        {providersCount > 0 &&
          providers?.map((provider, index) => (
            <ProviderCard
              showIcon={false}
              title={`${provider.first_name} ${provider.middle_name ? `${provider.middle_name} ` : ''}${provider.last_name}`.trim()}
              subtitle={provider.provider_types?.join(', ')}
              key={provider.npi}
              testId={`${type}-view-plan-${index}`}
            />
          ))}

        {providersCount === 0 && (
          <Box p={3} borderRadius="var(--border-radius-xs)" bg="var(--background-gray)">
            <Paragraph size="small" weight="bold" data-testid={`${type}-view-plan-none`}>
              <Text field="health_section.recommendation.in_network_providers_none" />
            </Paragraph>
          </Box>
        )}
      </SimpleGrid>
    </Stack>
  );
};
