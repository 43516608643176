import { Flex, Stack } from '@chakra-ui/react';
import Lottie from 'lottie-react';
import { AnimationSegment } from 'lottie-web';
import React, { useEffect, useRef, useState } from 'react';

import PrivacyOptions from 'Assets/privacy-options.svg';
import { useProfileContext } from 'Contexts/profileContext';
import { Button, Paragraph } from 'DesignLibrary/atoms';
import Text from 'Shared/Text';

import { LottieData } from './lottieData';
import { ProgressGraphicContainer } from './styled';
import { YOUR_PRIVACY_CHOICES_URL } from '../../../constants';

export const ProgressGraphic = () => {
  const lottieRef = useRef<any>(null);
  const { completedSections } = useProfileContext();
  const [animationStarted, setAnimationStarted] = useState(false);

  const defaultLottieOptions = {
    loop: false,
    autoplay: false,
    animationData: LottieData,
    initialSegment: completedSections?.length === 0 ? ([50, 313] as AnimationSegment) : undefined,
    style: {
      width: '100%',
      height: '100%',
      transform: 'scale(1.075)',
    },
  };

  // Play the initial animation segment after 14.4 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      if (lottieRef.current && !animationStarted) {
        lottieRef.current.play();
        setAnimationStarted(true);
      }
    }, 14400); // Change the delay time as needed

    return () => clearTimeout(timer);
  }, []);

  const stepsMapping = {
    enrollment: { startFrame: 312, endFrame: 313, step: 1 },
    eligibility: { startFrame: 312, endFrame: 313, step: 2 },
    location: { startFrame: 312, endFrame: 313, step: 3 },
    member: { startFrame: 313, endFrame: 585, step: 4 },
    pregnancy: { startFrame: 585, endFrame: 585, step: 5 },
    income: { startFrame: 585, endFrame: 585, step: 6 },
    riskAssessment: { startFrame: 585, endFrame: 1050, step: 7 },
    capacityToPay: { startFrame: 1050, endFrame: 1622, step: 8 },
  };

  const numSectionsComplete: number = completedSections?.length || 0;
  const completedStep = completedSections ? completedSections[numSectionsComplete - 1] : '';
  const frameDetails = stepsMapping[completedStep];
  const graphicClassName = `profile-progress-graphic progress-graphic-${frameDetails?.step || 0}`;

  useEffect(() => {
    if (lottieRef?.current && frameDetails) {
      const { startFrame, endFrame } = frameDetails;

      lottieRef.current.playSegments([startFrame, endFrame], true);
    }
  }, [numSectionsComplete]);

  return (
    <Stack pos="sticky" top={16}>
      <ProgressGraphicContainer className={graphicClassName} data-testid="profile-progress-graphic">
        <Lottie lottieRef={lottieRef} {...defaultLottieOptions} />
      </ProgressGraphicContainer>
      <Stack justify="center" textAlign="center" gap={0} py={3} px={4}>
        <Paragraph size="mini">
          {/* TODO: Contentful add field */}
          All data provided within ALEX is anonymous. For more info, see our privacy policy.
        </Paragraph>
        <Button className="link" size="xsmall" buttonType="standalone" url={YOUR_PRIVACY_CHOICES_URL} stretch>
          <Flex gap={2} align="center">
            <img src={PrivacyOptions} alt="Your Privacy Options" width={30} height={20} />
            <Text field="eligibility_section.do_not_sell_my_info_text" />
          </Flex>
        </Button>
      </Stack>
    </Stack>
  );
};
