import styled, { css } from 'styled-components';

import { media } from 'DesignLibrary/global';
import { cardStyling, selectedRedStyling } from 'DesignLibrary/utils';

export const Content = styled.div`
  > * + * {
    margin-top: var(--sizing-sm);
  }
`;

export const ButtonGrid = styled.div`
  display: flex;
  gap: var(--sizing-xs);

  button {
    flex-grow: 2;
  }
`;

interface WaiveContainerProps {
  isCoverageWaived: boolean;
}

export const WaiveContainer = styled.div<WaiveContainerProps>`
  ${cardStyling}
  padding: var(--sizing-sm);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--sizing-sm);

  ${({ isCoverageWaived }) =>
    isCoverageWaived &&
    css`
      ${selectedRedStyling}
    `}

  ${media.TABLET`
    flex-direction: row;
    justify-content: space-between;
    padding: var(--sizing-sm) var(--sizing-md);
  `}
`;

export const WaiveButton = styled.div`
  width: 100%;
  background: var(--colors-red-50);

  ${media.TABLET`
    max-width: var(--sizing-4xl);
  `}
`;
