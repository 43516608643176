import React, { useContext, useEffect, useState } from 'react';

import { sendInsightsEvent } from 'Utils/insights';
import { PROFILE_PATH } from 'Utils/urls';

interface IntroProviderProps {
  handleNext: (path: string) => void;
  children: JSX.Element;
}

export type HasPlayed = boolean;
export type MediaType = 'audio' | 'video';
export type StepState = '' | 'playing' | 'paused';

export interface Step {
  type: MediaType;
  audioHasPlayed: HasPlayed[];
  videoHasPlayed?: HasPlayed;
  delayIn: number;
  delayOut: number;
  nextStep: ALEX_STEPS | null;
  state: StepState;
}

interface IntroState {
  steps: Steps;
  currentStep: ALEX_STEPS;
}

export enum ALEX_STEPS {
  ALEX_VIDEO = 'alexVideo',
  HEAR_ME_AUDIO = 'hearMeAudio',
  SOUND_OFF_AUDIO = 'soundOffAudio',
  ASK_QUESTIONS_VIDEO = 'askQuestionsVideo',
  ASK_QUESTIONS_VIDEO1 = 'askQuestionsVideo1',
  ASK_QUESTIONS_VIDEO2 = 'askQuestionsVideo2',
  ASK_QUESTIONS_VIDEO3 = 'askQuestionsVideo3',
  ASK_QUESTIONS_VIDEO4 = 'askQuestionsVideo4',
  ASK_QUESTIONS_VIDEO5 = 'askQuestionsVideo5',
}

type Steps = { [K in ALEX_STEPS]: Step };

const steps: Steps = {
  alexVideo: {
    state: '',
    audioHasPlayed: [],
    videoHasPlayed: false,
    delayIn: 1000,
    delayOut: 0,
    type: 'video',
    nextStep: ALEX_STEPS.HEAR_ME_AUDIO,
  },
  hearMeAudio: {
    state: '',
    audioHasPlayed: [],
    delayIn: 0,
    delayOut: 0,
    type: 'audio',
    nextStep: null,
  },
  soundOffAudio: {
    state: '',
    audioHasPlayed: [],
    delayIn: 0,
    delayOut: 0,
    type: 'audio',
    nextStep: null,
  },
  askQuestionsVideo: {
    state: '',
    audioHasPlayed: [],
    videoHasPlayed: false,
    delayIn: 0,
    delayOut: 0,
    type: 'video',
    nextStep: null,
  },
  askQuestionsVideo1: {
    state: '',
    audioHasPlayed: [],
    videoHasPlayed: false,
    delayIn: 0,
    delayOut: 0,
    type: 'video',
    nextStep: null,
  },
  askQuestionsVideo2: {
    state: '',
    audioHasPlayed: [],
    videoHasPlayed: false,
    delayIn: 0,
    delayOut: 0,
    type: 'video',
    nextStep: null,
  },
  askQuestionsVideo3: {
    state: '',
    audioHasPlayed: [],
    videoHasPlayed: false,
    delayIn: 0,
    delayOut: 0,
    type: 'video',
    nextStep: null,
  },
  askQuestionsVideo4: {
    state: '',
    audioHasPlayed: [],
    videoHasPlayed: false,
    delayIn: 0,
    delayOut: 0,
    type: 'video',
    nextStep: null,
  },
  askQuestionsVideo5: {
    state: '',
    audioHasPlayed: [],
    videoHasPlayed: false,
    delayIn: 0,
    delayOut: 0,
    type: 'video',
    nextStep: null,
  },
};

// TODO: Remove ALEX_STEPS.ASK_QUESTIONS_VIDEO once ff removed
export const OUTROS = [
  ALEX_STEPS.ASK_QUESTIONS_VIDEO,
  ALEX_STEPS.ASK_QUESTIONS_VIDEO1,
  ALEX_STEPS.ASK_QUESTIONS_VIDEO2,
  ALEX_STEPS.ASK_QUESTIONS_VIDEO3,
  ALEX_STEPS.ASK_QUESTIONS_VIDEO4,
  ALEX_STEPS.ASK_QUESTIONS_VIDEO5,
];

export const defaultIntroState: IntroState = {
  steps,
  currentStep: ALEX_STEPS.ALEX_VIDEO,
};

export interface IntroContextProps {
  introState: IntroState;
  setIntroState: (state: IntroState) => void;
  updateStepState: (key: ALEX_STEPS, state: StepState) => void;
  updateVideoHasPlayed: (key: ALEX_STEPS, state: HasPlayed) => void;
  updateAudioHasPlayed: (key: ALEX_STEPS, state: HasPlayed, index: number) => void;
  updateStep: (key: ALEX_STEPS) => void;
  skipStep: (key: ALEX_STEPS) => void;
}

export const useIntroContext = () => useContext(IntroContext);

export const IntroContext = React.createContext<IntroContextProps>({
  introState: defaultIntroState,
  setIntroState: () => '',
  updateStepState: () => '',
  updateVideoHasPlayed: () => '',
  updateAudioHasPlayed: () => '',
  updateStep: () => '',
  skipStep: () => '',
});

export const IntroProvider = ({ handleNext, children }: IntroProviderProps) => {
  const [introState, setIntroState] = useState<IntroState>(defaultIntroState);

  useEffect(() => {
    const introState = {
      ...defaultIntroState,
    };
    setIntroState(introState);
  }, []);

  // handles auto navigating only when video/audio is complete
  useEffect(() => {
    const current = introState.steps[introState.currentStep];
    const isVideoPlayed =
      current.type === 'video' &&
      current.videoHasPlayed === true &&
      current.audioHasPlayed[current.audioHasPlayed.length - 1] === true;

    if (isVideoPlayed && current.nextStep) {
      setTimeout(() => {
        sendInsightsEvent(null, 'end_scene', {
          scene: introState.currentStep,
        });
        setIntroState((prevState) => ({
          ...prevState,
          currentStep: current.nextStep as ALEX_STEPS,
        }));
      }, current.delayOut);
    }

    if (isVideoPlayed && OUTROS.includes(introState.currentStep)) {
      setTimeout(() => {
        handleNext(PROFILE_PATH);
      }, current.delayOut);
    }
  }, [introState]);

  const updateStepState = (key: ALEX_STEPS, state: StepState) => {
    setIntroState((prevState) => ({
      ...prevState,
      steps: {
        ...prevState.steps,
        [key]: {
          ...prevState.steps[key],
          state,
        },
      },
    }));
  };

  const updateVideoHasPlayed = (key: ALEX_STEPS, progress: HasPlayed) => {
    setIntroState((prevState) => ({
      ...prevState,
      steps: {
        ...prevState.steps,
        [key]: {
          ...prevState.steps[key],
          videoHasPlayed: progress,
        },
      },
    }));
  };

  const updateAudioHasPlayed = (key: ALEX_STEPS, progress: HasPlayed, index: number) => {
    const newAudioState = introState.steps[key].audioHasPlayed;
    newAudioState[index] = progress;

    setIntroState((prevState) => ({
      ...prevState,
      steps: {
        ...prevState.steps,
        [key]: {
          ...prevState.steps[key],
          audioHasPlayed: newAudioState,
        },
      },
    }));
  };

  const updateStep = (key: ALEX_STEPS, overrides: Partial<IntroState> = {}) => {
    const current = introState.steps[introState.currentStep];
    setTimeout(() => {
      sendInsightsEvent(null, 'end_scene', {
        scene: introState.currentStep,
      });
      setIntroState((prevState) => ({
        ...prevState,
        ...overrides,
        currentStep: key,
      }));
    }, current.delayOut);
  };

  const skipStep = (key: ALEX_STEPS) => {
    const newAudioState = introState.steps[key].audioHasPlayed.map(() => true);
    setIntroState((prevState) => ({
      ...prevState,
      steps: {
        ...prevState.steps,
        [key]: {
          ...prevState.steps[key],
          state: 'paused',
          videoHasPlayed: true,
          audioHasPlayed: newAudioState,
        },
      },
    }));
  };

  return (
    <IntroContext.Provider
      value={{
        introState,
        setIntroState,
        updateStepState,
        updateVideoHasPlayed,
        updateAudioHasPlayed,
        updateStep,
        skipStep,
      }}
    >
      {children}
    </IntroContext.Provider>
  );
};
