import styled from 'styled-components';

export const ResultsContainer = styled.div`
  flex-shrink: 0;
  display: flex;
  gap: var(--sizing-xs);
`;

export const SupplementalPopupContainer = styled.div`
  .supplemental-popup {
    border-radius: var(--border-radius-xs);
    box-shadow: var(--shadow-large);
    padding: var(--sizing-sm);
    width: 480px;

    .supplemental-items {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: var(--sizing-xs);
      margin-top: var(--sizing-sm);
    }
  }
`;

interface MobileCartButtonProps {
  isDisabled: boolean;
}

export const MobileCartButton = styled.div<MobileCartButtonProps>`
  position: relative;

  .cart-count {
    position: absolute;
    top: -6px;
    right: -6px;
    height: var(--sizing-md);
    width: var(--sizing-md);
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    border-radius: 1rem;
    background: ${({ isDisabled }) => {
      if (isDisabled) {
        return 'var(--colors-gray-300)';
      }

      return 'var(--primary-blue)';
    }};
    color: var(--primary-white);
    font-size: 14px;
    font-weight: 600;
    user-select: none;
  }
`;

export const MobileCartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  .top-content {
    padding: var(--sizing-md);
    flex-grow: 2;
    overflow-y: scroll;

    .cart-items {
      display: flex;
      flex-direction: column;
      row-gap: var(--sizing-xs);
      margin-top: var(--sizing-md);
    }
  }

  .close-button {
    height: var(--sizing-2xl);
    padding: 0 var(--sizing-xs);
    border-top: 1px solid var(--border-gray);
    flex-shrink: 0;
    display: flex;
    align-items: center;
  }
`;
