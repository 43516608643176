import styled from 'styled-components';

export const CopayTag = styled.div`
  display: inline-flex;
  padding: 0 var(--sizing-xs);
  border-radius: 2rem;
  background: var(--background-gray);
  position: relative;
  left: -8px;
  margin-top: 4px;

  span {
    display: block;
  }
`;
