import { SimpleGrid, Stack } from '@chakra-ui/react';
import React from 'react';

import { TextField } from 'Containers/App/types';
import { H4, Paragraph } from 'DesignLibrary/atoms';
import RichText from 'Shared/RichText';
import Text from 'Shared/Text';

import { NumberBlock } from '../_shared/SectionWrapper';

interface OverviewCardProps {
  number: number;
  textField: TextField;
}

const OverviewCard = ({ number, textField }: OverviewCardProps) => (
  <Stack px={4} pb={4} border="var(--border-thick)" borderRadius="var(--border-radius-xs)" gap={2}>
    <NumberBlock number={number} />
    <Paragraph weight="bold" size="small" color="--text-black">
      <Text field={textField} />
    </Paragraph>
  </Stack>
);

const Overview = () => (
  <Stack p={[4, 8]} gap={4}>
    <Stack>
      <H4 as="h1">
        <Text field="people_like_you.overview_title" />
      </H4>

      <Paragraph lineHeight={1.75}>
        <RichText field="people_like_you.overview_explanatory_text" />
      </Paragraph>
    </Stack>

    <SimpleGrid columns={[1, 3]} gap={4} my={8}>
      <OverviewCard number={1} textField="people_like_you.user_icon_text" />
      <OverviewCard number={2} textField="people_like_you.costs_icon_text" />
      <OverviewCard number={3} textField="people_like_you.ranks_icon_text" />
    </SimpleGrid>

    <Paragraph>
      <Text field="people_like_you.overview_learn_more" />
    </Paragraph>
  </Stack>
);

export default Overview;
