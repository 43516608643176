import {
  CapacityToPayAnswer,
  ClientSurveyResponsesByYear,
  IncentiveSurveyAnswer,
  MemberSectionConfigFields,
  RiskAssessmentAnswer,
  Survey,
  UpdateMemberFields,
} from 'Containers/ProfilePage/types';
import { Household } from 'Types/entities';
import {
  ClientSurveySchema,
  GetBatchDrugDetailsResponse,
  GetDrugsResponse,
  GetLocationsResponse,
  IncentiveSurveyResponse,
  ItemReference,
  JVPEligibilityQuestions,
  JVPUserWithDependents,
  PreviousSelectedPlan,
} from 'Utils/apiTypes';

import {
  CHANGE_FORM_VALUE,
  CHANGE_SURVEY_VALUE,
  CHANGE_SURVEY_VALUE_FROM_RESULT_PAGE,
  CREATE_OR_UPDATE_HOUSEHOLD_FAILURE,
  CREATE_OR_UPDATE_HOUSEHOLD_REQUEST,
  CREATE_OR_UPDATE_HOUSEHOLD_SUCCESS,
  EDIT_DEPENDENT,
  EDIT_POLICYHOLDER,
  EDIT_SPOUSE,
  GET_CLIENT_SURVEY_FAILURE,
  GET_CLIENT_SURVEY_REQUEST,
  GET_CLIENT_SURVEY_SUCCESS,
  GET_HOUSEHOLD_FAILURE,
  GET_HOUSEHOLD_REQUEST,
  GET_HOUSEHOLD_SUCCESS,
  GET_INCENTIVE_SURVEY_FAILURE,
  GET_INCENTIVE_SURVEY_SUCCESS,
  GET_JVP_CLIENT_SURVEY_FAILURE,
  GET_JVP_CLIENT_SURVEY_REQUEST,
  GET_JVP_CLIENT_SURVEY_SUCCESS,
  GET_JVP_ELIGIBILITY_QUESTIONS_FAILURE,
  GET_JVP_ELIGIBILITY_QUESTIONS_SUCCESS,
  PREFILL_USER_PROFILE_FAILURE,
  PREFILL_USER_PROFILE_REQUEST,
  PREFILL_USER_PROFILE_SUCCESS,
  PUT_HOUSEHOLD_MEMBER_CONFIG,
  REMOVE_DEPENDENT,
  REMOVE_SPOUSE,
  RESET_COUNTY_RESULTS,
  RESET_FORM_DIRTY_STATE,
  SANITIZE_PROFILE_INPUTS,
  SEARCH_DRUGS_FAILURE,
  SEARCH_DRUGS_REQUEST,
  SEARCH_DRUGS_SUCCESS,
  SEARCH_LOCATIONS_FAILURE,
  SEARCH_LOCATIONS_REQUEST,
  SEARCH_LOCATIONS_SUCCESS,
  SET_ACTIVE_HOUSEHOLE_MEMBERS,
  SET_INCENTIVE_SURVEY,
  SET_RESULT_PAGE_INCENTIVE_SURVEY,
  SUBMIT_CLIENT_SURVEY,
  SUBMIT_INCENTIVE_SURVEY,
  SUBMIT_RESULT_INCENTIVE_SURVEY,
  UPDATE_HOUSEHOLD_FROM_RESULT_PAGE,
} from './constants';
import { IProfilePageReducerState } from './reducer';

export function getHousehold(memberConfig: MemberSectionConfigFields) {
  return {
    type: GET_HOUSEHOLD_REQUEST,
    memberConfig,
  };
}

export function getHouseholdSuccess(response: Household, memberConfig: MemberSectionConfigFields) {
  return {
    type: GET_HOUSEHOLD_SUCCESS,
    response,
    memberConfig,
  };
}

export function getHouseholdError(error: unknown) {
  return {
    type: GET_HOUSEHOLD_FAILURE,
    error,
  };
}

export function createOrUpdateHousehold() {
  return {
    type: CREATE_OR_UPDATE_HOUSEHOLD_REQUEST,
  };
}

export function setActiveHouseholdMembers(members: string[]) {
  return {
    type: SET_ACTIVE_HOUSEHOLE_MEMBERS,
    members,
  };
}

export function setIncentiveSurvey(data) {
  return {
    type: SET_INCENTIVE_SURVEY,
    data,
  };
}

export function setResultPageIncentiveSurvey(response: IncentiveSurveyResponse) {
  return {
    type: SET_RESULT_PAGE_INCENTIVE_SURVEY,
    response,
  };
}

export function createOrUpdateHouseholdSuccess(response: ItemReference, builderCustomerKey?: string) {
  return {
    type: CREATE_OR_UPDATE_HOUSEHOLD_SUCCESS,
    response,
    builderCustomerKey,
  };
}

/*
`error` here is type Error, but TS won't let you type things you catch
*/
export function createOrUpdateHouseholdFailure(error: Error) {
  return {
    type: CREATE_OR_UPDATE_HOUSEHOLD_FAILURE,
    error,
  };
}

export function prefillUserProfile(memberConfig: MemberSectionConfigFields) {
  return {
    type: PREFILL_USER_PROFILE_REQUEST,
    memberConfig,
  };
}

export function getAlexIdUserProfileSuccess(
  household: Household,
  publicationKey: 'active' | 'upcoming',
  validClientSurveyQuestionIds: string[],
  jvpUser: JVPUserWithDependents,
  drugDetails: GetBatchDrugDetailsResponse | null,
  memberConfig: MemberSectionConfigFields,
  previousPlan: PreviousSelectedPlan[],
  isReturnUser: boolean | null,
  jvpEligibilityQuestions: JVPEligibilityQuestions,
) {
  return {
    type: PREFILL_USER_PROFILE_SUCCESS,
    household,
    publicationKey,
    validClientSurveyQuestionIds,
    jvpUser,
    drugDetails,
    memberConfig,
    previousPlan,
    isReturnUser,
    jvpEligibilityQuestions,
  };
}

export function getAlexIdUserProfileFailure(error: unknown) {
  return {
    type: PREFILL_USER_PROFILE_FAILURE,
    error,
  };
}

/* Eligibility section actions */

export function getClientSurvey() {
  return { type: GET_CLIENT_SURVEY_REQUEST };
}

export function getClientSurveySuccess(response: Record<string, ClientSurveySchema>) {
  return {
    type: GET_CLIENT_SURVEY_SUCCESS,
    response,
  };
}

export function getClientSurveyFailure(error: unknown) {
  return {
    type: GET_CLIENT_SURVEY_FAILURE,
    error,
  };
}

export function getJvpClientSurvey() {
  return { type: GET_JVP_CLIENT_SURVEY_REQUEST };
}

export function getJvpClientSurveySuccess(
  response: Record<'active' | 'upcoming', ClientSurveySchema | null>,
) {
  return {
    type: GET_JVP_CLIENT_SURVEY_SUCCESS,
    response,
  };
}

export function getJvpClientSurveyFailure(error: unknown) {
  return {
    type: GET_JVP_CLIENT_SURVEY_FAILURE,
    error,
  };
}

export function submitClientSurvey(responses: ClientSurveyResponsesByYear) {
  return {
    type: SUBMIT_CLIENT_SURVEY,
    responses,
  };
}

export function submitIncentiveSurvey(responses: Record<string, IncentiveSurveyAnswer | null>) {
  return {
    type: SUBMIT_INCENTIVE_SURVEY,
    responses,
  };
}

export function submitResultIncentiveSurvey(responses: Record<string, IncentiveSurveyAnswer | null>) {
  return {
    type: SUBMIT_RESULT_INCENTIVE_SURVEY,
    responses,
  };
}

/* Location section actions */

export function searchLocations(zipcode: string) {
  return {
    type: SEARCH_LOCATIONS_REQUEST,
    zipcode,
  };
}

export function searchLocationsSuccess(response: GetLocationsResponse) {
  return {
    type: SEARCH_LOCATIONS_SUCCESS,
    response,
  };
}

export function searchLocationsFailure(error: unknown) {
  return {
    type: SEARCH_LOCATIONS_FAILURE,
    error,
  };
}

export function resetCountyResults() {
  return { type: RESET_COUNTY_RESULTS };
}

/* Member section actions */

export function editPolicyholder(fields: UpdateMemberFields, memberConfig: MemberSectionConfigFields) {
  return {
    type: EDIT_POLICYHOLDER,
    fields,
    memberConfig,
  };
}

export function editSpouse(fields: UpdateMemberFields, memberConfig: MemberSectionConfigFields) {
  return {
    type: EDIT_SPOUSE,
    fields,
    memberConfig,
  };
}

export function resetFormDirtyState() {
  return { type: RESET_FORM_DIRTY_STATE };
}

export function editDependent(
  memberId: string,
  fields: UpdateMemberFields,
  memberConfig: MemberSectionConfigFields,
) {
  return {
    type: EDIT_DEPENDENT,
    memberId,
    fields,
    memberConfig,
  };
}

export function removeSpouse() {
  return { type: REMOVE_SPOUSE };
}

export function removeDependent(memberId: string) {
  return {
    type: REMOVE_DEPENDENT,
    memberId,
  };
}

export function changeFormValue(value: string, name: keyof IProfilePageReducerState) {
  return {
    type: CHANGE_FORM_VALUE,
    value,
    name,
  };
}

export function changeSurveyValue(
  value: RiskAssessmentAnswer | CapacityToPayAnswer | 'yes' | 'no' | '',
  name: keyof Survey,
) {
  return {
    type: CHANGE_SURVEY_VALUE,
    value,
    name,
  };
}

export function changeSurveyValueFromResultPage(value: string, name: keyof Survey) {
  return {
    type: CHANGE_SURVEY_VALUE_FROM_RESULT_PAGE,
    value,
    name,
  };
}

/* Prescription section actions */

export function searchDrugs(query: string) {
  return {
    type: SEARCH_DRUGS_REQUEST,
    query,
  };
}

export function searchDrugsSuccess(response: GetDrugsResponse) {
  return {
    type: SEARCH_DRUGS_SUCCESS,
    response,
  };
}

export function searchDrugsFailure(error: unknown) {
  return {
    type: SEARCH_DRUGS_FAILURE,
    error,
  };
}

export function sanitizeProfileInputs() {
  return {
    type: SANITIZE_PROFILE_INPUTS,
  };
}

export function getIncentiveSurveySuccess(response: IncentiveSurveyResponse) {
  return {
    type: GET_INCENTIVE_SURVEY_SUCCESS,
    response,
  };
}

export function getIncentiveSurveyFailure(error: unknown) {
  return {
    type: GET_INCENTIVE_SURVEY_FAILURE,
    error,
  };
}

export function updateHouseholdFromResultPage() {
  return {
    type: UPDATE_HOUSEHOLD_FROM_RESULT_PAGE,
  };
}

export function putHouseholdMemberConfig() {
  return {
    type: PUT_HOUSEHOLD_MEMBER_CONFIG,
  };
}

export function getJvpEligibilityQuestionsSuccess(response: JVPEligibilityQuestions) {
  return {
    type: GET_JVP_ELIGIBILITY_QUESTIONS_SUCCESS,
    response,
  };
}

export function getJvpEligibilityQuestionsFailure(error: unknown) {
  return {
    type: GET_JVP_ELIGIBILITY_QUESTIONS_FAILURE,
    error,
  };
}
