import React from 'react';

import { useTextContext } from 'Contexts/textContext';
import { H6, Numbers } from 'DesignLibrary/atoms';
import { toDollars } from 'Utils/helpers';

import { SplitWrapper } from './styled';

export interface SplitNumbersProps {
  titles?: (string | JSX.Element)[] | null;
  values: (number | string | JSX.Element)[];
  identifiers?: string[] | null;
  direction?: 'left' | 'right';
}

export const SplitNumbers = ({ titles, values, identifiers, direction = 'left' }: SplitNumbersProps) => {
  const { retrieveContentfulData } = useTextContext();

  const medTitle = retrieveContentfulData<string>('health_section.comprehensive_plan_terms.medical');
  const drugTitle = retrieveContentfulData<string>('health_section.comprehensive_plan_terms.drug');

  const defaultTitles = [medTitle, drugTitle];
  const renderTitles = titles || defaultTitles;

  return (
    <SplitWrapper direction={direction}>
      {renderTitles.map((item, index) => (
        <React.Fragment key={index}>
          <div>
            <H6>{item}</H6>
          </div>
          <Numbers className={identifiers ? identifiers[index] || '' : ''}>
            {toDollars(values[index].toString())}
          </Numbers>
        </React.Fragment>
      ))}
    </SplitWrapper>
  );
};
