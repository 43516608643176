import styled from 'styled-components';

import { ButtonStyle } from 'DesignLibrary/atoms/Button/styled';
import { media } from 'DesignLibrary/global';

interface AddMemberButtonProps {
  isDisabled?: boolean;
}

export const AddMemberContainer = styled.div`
  & > * + * {
    margin-top: var(--sizing-sm);
  }

  > div {
    & > * + * {
      margin-top: var(--sizing-sm);
    }

    ${media.TABLET`
      display: flex;
      gap: var(--sizing-sm);

      & > * {
        margin: 0;
      }
    `}
  }
`;

export const AddMemberButton = styled(ButtonStyle)<AddMemberButtonProps>`
  width: 100%;
  height: var(--sizing-2xl);
  padding: 0 var(--sizing-md);
  border: 2px dashed var(--colors-gray-300);
  border-radius: var(--border-radius-xs);
  justify-content: center;

  &:hover:enabled {
    border-color: var(--primary-black);
    background: var(--colors-blue-50);
  }

  &:disabled {
    pointer-events: none;
    background: var(--colors-gray-50);
    color: var(--text-gray-light);
  }
`;
