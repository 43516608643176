import styled from 'styled-components';

import { media } from 'DesignLibrary/global';

export const Wrapper = styled.div`
  border-radius: var(--border-radius-xs);
  background: var(--colors-blue-50);
  display: inline-block;
  gap: 12px;
  padding: var(--sizing-sm);
  width: 100%;

  span[role='img'] {
    margin-right: var(--sizing-sm);
    display: inline-block !important;
    height: 16px;
    width: 16px;
  }

  .paragraph,
  p {
    display: inline;
    b {
      color: inherit;
      font-weight: 900;
    }

    .tooltip-icon {
      color: inherit !important;
    }

    span > * {
      color: inherit;
    }
  }

  ${media.TABLET`
    display: flex;
    align-items: center;
    padding: 0 var(--sizing-sm);
    height: 40px;

    span[role='img'] {
      display: flex;
      margin: 0;
      height: 24px;
      width: 24px;
    }

    .paragraph,
    p {
      display: block;
    }
  `}
`;
