import styled, { css } from 'styled-components';

import { media } from 'DesignLibrary/global';

import { hideAccessibleCheckmark, focusSingle } from '../../utils';
import { ButtonTextStyle } from '../typography';

type ToggleProps = {
  layout: 'row' | 'column';
  grid: boolean;
  gridMobile: boolean;
  gridRowCount?: number;
};

type ToggleLabelProps = {
  sizing: 'normal' | 'small';
  stretch: boolean;
  fit: boolean;
};

type TogglePseudoProps = {
  sizing: 'normal' | 'small';
  color: string;
  disabled: boolean;
  selected: boolean;
  fit: boolean;
  styleClass?: string;
};

export const ToggleListContainer = styled.fieldset<ToggleProps>`
  border: 0;
  padding: 0;
  position: relative;
  display: flex;
  flex-direction: ${({ layout }) => layout} !important;
  gap: var(--sizing-xs);

  input[type='radio'] {
    ${hideAccessibleCheckmark}

    &:focus + .pseudo-toggle {
      ${focusSingle};
    }
  }

  ${({ grid, gridMobile, gridRowCount: gridRows }) =>
    grid &&
    css`
      flex-direction: column;
      grid-auto-flow: column;
      grid-column-gap: var(--sizing-xs);
      grid-template-columns: 1fr 1fr;
      grid-template-rows: repeat(${gridRows}, 1fr);

      ${gridMobile &&
      css`
        display: grid;
      `}

      ${media.TABLET`
      display: grid;
      `}
    `}
`;

export const HiddenLegend = styled.legend`
  display: block;
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
`;

export const ToggleLabel = styled.label<ToggleLabelProps>`
  cursor: pointer;
  height: ${({ sizing }) => (sizing === 'normal' ? 'var(--sizing-xl)' : '48px')};
  width: ${({ fit, sizing, stretch }) => {
    if (fit) return undefined;
    if (stretch) return '100%';
    if (sizing === 'normal') return 'var(--sizing-4xl)';
    return 'auto';
  }};
  flex-grow: ${({ fit }) => {
    if (fit) return '1';
    return undefined;
  }};
`;

export const TogglePseudo = styled.div<TogglePseudoProps>`
  ${ButtonTextStyle}
  height: 100%;
  box-sizing: border-box;
  min-height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--colors-gray-white);
  border: 2px solid var(--colors-gray-300);
  border-radius: var(--border-radius-xs);
  text-align: center;
  padding: 0
    ${({ sizing, fit }) =>
      sizing === 'normal' ? (fit ? 'var(--sizing-md)' : 'var(--sizing-lg)') : 'var(--sizing-sm)'};

  &:hover:not([disabled]) {
    background: var(--colors-blue-50);
    border-color: var(--text-black);
  }

  ${({ styleClass }) =>
    styleClass &&
    styleClass === 'open-enrollment' &&
    css`
      background-color: #e9f9f5;
      border-width: 4px;
    `}

  ${({ selected }) =>
    selected &&
    css`
      background: var(--primary-blue);
      border-color: var(--primary-blue);
      color: var(--primary-white);
      cursor: default;

      &:hover:not([disabled]) {
        background: var(--primary-blue);
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
      opacity: 0.5;
    `}
`;
