import { get } from 'lodash';
import React, { FC, useEffect } from 'react';

import { LocationCard } from 'ContentfulDefaults/types/profile';
import { useFeatureFlagContext } from 'Contexts/featureFlagContext';
import { useProfileContext } from 'Contexts/profileContext';
import { useTextContext } from 'Contexts/textContext';
import { Icon } from 'DesignLibrary/atoms';
import SelectInput, { Option } from 'DesignLibrary/atoms/inputs/SelectInput';
import TextInput from 'DesignLibrary/atoms/inputs/TextInput';
import { Paragraph } from 'DesignLibrary/atoms/typography';
import LabelInput from 'DesignLibrary/molecules/LabelInput';
import { isValidZipCode } from 'Utils/helpers';

import { STATES } from './constants';
import { LocationContainer } from './styled';
import Question from '../_shared/Question';
import { QuestionWrapper } from '../_shared/Question/styled';
import { Subtitle } from '../EligibilitySection/SurveyQuestion/styled';
import { AudioConfigTooltipContentfulPaths } from '../ProfileAudio';
import { CountyResult, ClientSurveyQuestion, ClientSurveyResponsesByYear } from '../types';

export interface LocationSectionProps {
  zipcode: string;
  stateCode?: string;
  stateCodeOptions: string[];
  countyResults: CountyResult[];
  surveyQuestions: Record<string, ClientSurveyQuestion>;
  surveyResponses: ClientSurveyResponsesByYear;
  collectTaxInputs: boolean;
  isDirty: boolean;
  hiddenQuestionIds: string[];
  selectedPublicationKey: 'active' | 'upcoming';
  isProfileAudioEnabled: boolean;
  searchCounties: (query: string) => void;
  resetCounties: () => void;
  handleZipChange: (zipcode: string) => void;
  handleStateChange: (stateCode: string) => void;
  submitClientSurveyResponses: (survey: ClientSurveyResponsesByYear) => void;
  handleSectionChange: (isValid: boolean, isComplete: boolean) => void;
  setHiddenAlert: (alert: string) => void;
}

const LocationSection: FC<LocationSectionProps> = ({
  zipcode,
  stateCode,
  stateCodeOptions,
  countyResults,
  surveyQuestions,
  surveyResponses,
  collectTaxInputs,
  isDirty,
  hiddenQuestionIds,
  selectedPublicationKey,
  searchCounties,
  resetCounties,
  handleZipChange,
  handleStateChange,
  submitClientSurveyResponses,
  handleSectionChange,
  setHiddenAlert,
  isProfileAudioEnabled,
}) => {
  const { retrieveContentfulData } = useTextContext();
  const { getSectionFromMap } = useProfileContext();
  const { is_hide_eligibility_questions_enabled } = useFeatureFlagContext();

  const profileCard = retrieveContentfulData<LocationCard>(`profile_section.location`);

  useEffect(() => {
    const alertText = retrieveContentfulData<string>('profile_section.accessibility.unlocked_section', '');
    setHiddenAlert(alertText.replace(/{x}/, profileCard.name));
  }, []);

  useEffect(() => {
    const section = getSectionFromMap('location');
    const isComplete = section?.isComplete || validateForm();
    handleSectionChange(validateForm(), isComplete);
  }, [zipcode, stateCode]);

  const handleZipcodeChange = (value: string) => {
    if (get(surveyQuestions, 'zip5') || collectTaxInputs) {
      if (isValidZipCode(value)) {
        searchCounties(value);
      } else {
        // reset county results if zip is invalid
        resetCounties();
      }
    }

    // Set zip5 on household
    handleZipChange(value);

    // Set zip5 on client survey response
    if (get(surveyQuestions, 'zip5')) {
      submitClientSurveyResponses({
        ...surveyResponses,
        [selectedPublicationKey]: {
          ...surveyResponses[selectedPublicationKey],
          zip5: value,
        },
      } as ClientSurveyResponsesByYear);
    }
  };

  const handleCountySelect = (e) => {
    const value = e.target.value as string;

    submitClientSurveyResponses({
      ...surveyResponses,
      [selectedPublicationKey]: {
        ...surveyResponses[selectedPublicationKey],
        fips5: value,
      },
    } as ClientSurveyResponsesByYear);
  };

  const getStateOptions = () => {
    const codes = stateCodeOptions || [];
    return STATES.map((i) => {
      if (codes.includes(i.abbreviation)) {
        return {
          option: i.name,
          value: i.abbreviation,
        };
      }
      return null;
    }).filter((i) => i) as Option[];
  };

  function validateForm() {
    let isFormValid = false;

    isFormValid = isValidZipCode(zipcode);

    if (isFormValid && 'zip5' in surveyQuestions) {
      isFormValid = isValidZipCode(surveyResponses[selectedPublicationKey]?.zip5);
    }

    if (isFormValid && 'fips5' in surveyQuestions) {
      isFormValid = surveyResponses[selectedPublicationKey]?.fips5 !== '';
    }

    if (isFormValid && collectTaxInputs) {
      isFormValid = stateCode !== '';
    }

    return isFormValid;
  }

  const isIncomplete = !getSectionFromMap('location')?.isComplete;

  const zipcodeLabelId = 'zipcode-input-label';
  const stateLabelId = 'state-input-label';
  const selectFipsLabelId = 'select-fips5';

  const zipcodeClarification = retrieveContentfulData<string>(
    `eligibility_section.survey_questions.zip.clarification`,
  );

  const invalidZipcode = !isValidZipCode(zipcode) && isDirty;

  const showZip = !hiddenQuestionIds.includes('zip5') || !is_hide_eligibility_questions_enabled;
  const showFips =
    get(surveyQuestions[selectedPublicationKey], 'fips5') &&
    (!hiddenQuestionIds.includes('fips5') || !is_hide_eligibility_questions_enabled);

  const showState =
    collectTaxInputs && (!hiddenQuestionIds.includes('state') || !is_hide_eligibility_questions_enabled);

  if (!showZip && !showFips && !showState) {
    return null;
  }

  return (
    <div id="location-section" data-testid="location-section">
      <Question
        error={!validateForm() && isDirty}
        name="location"
        isIncomplete={isIncomplete}
        audioTooltipsEnabled={isProfileAudioEnabled}
        audioTooltipContentfulPath={AudioConfigTooltipContentfulPaths.ResidenceSectionAudioTooltip}
      >
        <QuestionWrapper>
          <Paragraph>{profileCard?.header}</Paragraph>
          {zipcodeClarification && (
            <Subtitle>
              <Icon type="Info" weight="fill" size="small" color="--text-gray" />
              {zipcodeClarification}
            </Subtitle>
          )}
          <LocationContainer>
            <LabelInput
              id={zipcodeLabelId}
              label={profileCard?.zipcode_label}
              className="zipcode-style"
              error={!validateForm() && isDirty}
            >
              <TextInput
                labelId={zipcodeLabelId}
                className="zipcode-input pingdom-zipcode"
                id="zipcode-input"
                data-testid="zipcode-input"
                inputType="integer"
                inputMode="numeric"
                stretch
                placeholder={profileCard?.zipcode_placeholder}
                iconType="Search"
                error={invalidZipcode}
                aria-invalid={invalidZipcode}
                handleChange={(e) => handleZipcodeChange(e.target.value)}
                maxLength={5}
                value={zipcode}
                type="tel"
              />
            </LabelInput>

            {showFips && (
              <div>
                <LabelInput
                  id={selectFipsLabelId}
                  label=""
                  className="zipcode-style"
                  error={!validateForm() && isDirty}
                >
                  <SelectInput
                    className="select-fips5"
                    data-testid="select-fips5"
                    placeholder={
                      surveyResponses?.fips5?.toString().length === 5
                        ? retrieveContentfulData<string>(
                            'eligibility_section.survey_questions.fips5.options.complete',
                          )
                        : retrieveContentfulData<string>(
                            'eligibility_section.survey_questions.fips5.options.incomplete',
                          )
                    }
                    options={countyResults.map((county) => ({
                      option: `${county.county_name}, ${county.state_name}`,
                      value: county.county_fips,
                    }))}
                    required
                    selected={surveyResponses[selectedPublicationKey]?.fips5 as string}
                    handleChange={handleCountySelect}
                    aria-labelledby={selectFipsLabelId}
                  />
                </LabelInput>
              </div>
            )}
            {showState && (
              <div className="state-input">
                <LabelInput id={stateLabelId} label={profileCard?.state_label}>
                  <SelectInput
                    name="state"
                    className="select-state"
                    data-testid="select-state"
                    required
                    placeholder={profileCard?.state_placeholder || ''}
                    options={getStateOptions()}
                    handleChange={(e) => handleStateChange(e.target.value)}
                    selected={stateCode}
                    labelId={stateLabelId}
                  />
                </LabelInput>
              </div>
            )}
          </LocationContainer>
        </QuestionWrapper>
      </Question>
    </div>
  );
};

export default LocationSection;
