import styled from 'styled-components';

import { hideAccessibleCheckmark, focusSingle } from 'DesignLibrary/utils';

type RadioLabel = {
  interactive: boolean;
  color: string;
};

export const RadioButtonLabel = styled.label<RadioLabel>`
  ${({ interactive }) => !interactive && 'pointer-events: none;'}
  position: relative;

  input[type='radio'] {
    ${hideAccessibleCheckmark}

    &:enabled:not(:checked) + .pseudo-radio {
      &:hover {
        cursor: pointer;

        .radio-button {
          border-color: var(--text-black);
        }
      }
    }

    &:focus-visible + .pseudo-radio .radio-button {
      ${focusSingle}
    }

    &:disabled + .pseudo-radio {
      opacity: 0.5;
    }
  }
`;
