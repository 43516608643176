import { Box } from '@chakra-ui/react';
import React, { FC } from 'react';

import { AudioConfigTooltipContentfulPaths } from 'Containers/ProfilePage/ProfileAudio';
import { ContentfulTooltip, PlannedProceduresSection, ProfileAudio } from 'ContentfulDefaults/types/profile';
import { useTextContext } from 'Contexts/textContext';
import { Paragraph } from 'DesignLibrary/atoms';
import { AudioTooltip } from 'DesignLibrary/atoms/AudioTooltip';
import Counter from 'DesignLibrary/atoms/inputs/Counter';
import { TooltipCaps } from 'DesignLibrary/molecules';
import Text from 'Shared/Text';
import { Relationship } from 'Types/entities';

import { InputSection, PlannedProceduresContainer } from './styled';
import { Member, MemberSectionConfigFields, UpdateMemberFields } from '../../../types';

export interface PlannedProceduresFormProps {
  member: Member;
  memberConfig: MemberSectionConfigFields;
  relationship: Relationship;
  isProfileAudioEnabled: boolean;
  handleEdit: (fields: UpdateMemberFields, memberConfig: MemberSectionConfigFields) => void;
}

export const PlannedProceduresForm: FC<PlannedProceduresFormProps> = ({
  member,
  memberConfig,
  relationship,
  isProfileAudioEnabled,
  handleEdit,
}) => {
  const { retrieveContentfulData } = useTextContext();

  const handlePlannedProceduresChange = (value, name) => {
    handleEdit(
      {
        planned_procedures: {
          ...member.planned_procedures,
          [name]: value !== '' ? parseInt(value, 10) : '',
        },
      },
      memberConfig,
    );
  };

  const instructions =
    relationship === 'policyholder' ? (
      <Text field="profile_section.member.planned_procedures.instructions_policyholder" />
    ) : (
      <Text field="profile_section.member.planned_procedures.instructions_household_member" />
    );

  const plannedProceduresSection = retrieveContentfulData<PlannedProceduresSection>(
    'profile_section.member.planned_procedures',
  );

  const inpatient_procedures_tooltip = plannedProceduresSection.tooltips.inpatient_procedures;

  const outpatient_procedures_tooltip = plannedProceduresSection.tooltips.outpatient_procedures;

  const routine_therapy_visits_tooltip =
    relationship === 'policyholder'
      ? plannedProceduresSection.tooltips.routine_therapy_visits_policyholder
      : plannedProceduresSection.tooltips.routine_therapy_visits_household_member;

  const mental_health_visits_tooltip =
    relationship === 'policyholder'
      ? plannedProceduresSection.tooltips.mental_health_visits_policyholder
      : plannedProceduresSection.tooltips.mental_health_visits_household_member;

  const getProfileAudioConfig = (path: AudioConfigTooltipContentfulPaths): ProfileAudio =>
    retrieveContentfulData<ProfileAudio>(path);

  const planned_procedures_question_audio: ProfileAudio = getProfileAudioConfig(
    AudioConfigTooltipContentfulPaths.PlannedProceduresQuestionAudio,
  );

  const inpatient_procedures_audio: ProfileAudio = getProfileAudioConfig(
    AudioConfigTooltipContentfulPaths.PlannedProceduresInpatientAudioTooltip,
  );

  const outpatient_audio: ProfileAudio = getProfileAudioConfig(
    AudioConfigTooltipContentfulPaths.PlannedProceduresOutpatientAudioTooltip,
  );

  const routine_therapy_audio: ProfileAudio = getProfileAudioConfig(
    AudioConfigTooltipContentfulPaths.PlannedProceduresRoutineTherapyAudioTooltip,
  );

  const mental_health_audio: ProfileAudio = getProfileAudioConfig(
    AudioConfigTooltipContentfulPaths.PlannedProceduresMentalHealthAudioTooltip,
  );

  const renderQuestion = () => {
    if (isProfileAudioEnabled && planned_procedures_question_audio) {
      return (
        <Box pos="relative">
          <Box pos="absolute" top={-2} left={-12}>
            <AudioTooltip
              data-testid="audio-tooltip-planned-procedure-inpatient"
              urlToAudio={planned_procedures_question_audio.audio_file_url}
              urlToClosedCaptionVtt={planned_procedures_question_audio.vtt_transcript_file_url}
            />
          </Box>
          <Paragraph>{instructions}</Paragraph>
        </Box>
      );
    }

    return <Paragraph>{instructions}</Paragraph>;
  };

  const renderTooltip = (
    contentful_tooltip: ContentfulTooltip,
    audio_tooltip: ProfileAudio,
    keyHint: string,
  ) => {
    if (isProfileAudioEnabled && audio_tooltip) {
      return (
        <div className="planned-procedures-question">
          <Box pos="relative">
            <Box pos="absolute" top={-2} left={-12}>
              <AudioTooltip
                data-testid={`audio-tooltip-planned-procedure-${keyHint}`}
                urlToAudio={audio_tooltip.audio_file_url}
                urlToClosedCaptionVtt={audio_tooltip.vtt_transcript_file_url}
              />
            </Box>
            <Paragraph size="normal">{contentful_tooltip.title}</Paragraph>
          </Box>
        </div>
      );
    }

    return (
      <div className="planned-procedures-question">
        <TooltipCaps
          color="--text-black"
          tooltip={contentful_tooltip.text}
          keyHint={`member-${member.external_id}-${keyHint}`}
        >
          {contentful_tooltip.title}
        </TooltipCaps>
      </div>
    );
  };

  return (
    <PlannedProceduresContainer>
      {renderQuestion()}

      {/* Inpatient Procedures */}
      <InputSection>
        {renderTooltip(inpatient_procedures_tooltip, inpatient_procedures_audio, 'inpatient-procedures')}

        <div data-testid="inpatient-procedures-counter">
          <Counter
            label={inpatient_procedures_tooltip.title}
            id="inpatient-procedures-counter"
            startValue={member.planned_procedures.inpatient_procedures || 0}
            handleChange={(value) => handlePlannedProceduresChange(value, 'inpatient_procedures')}
            minValue={0}
            maxValue={99}
          />
        </div>
      </InputSection>

      {/* Outpatient Procedures */}
      <InputSection>
        {renderTooltip(outpatient_procedures_tooltip, outpatient_audio, 'outpatient-procedures')}

        <div data-testid="outpatient-procedures-counter">
          <Counter
            label={outpatient_procedures_tooltip.title}
            id="outpatient-procedures-counter"
            startValue={member.planned_procedures.outpatient_procedures || 0}
            handleChange={(value) => handlePlannedProceduresChange(value, 'outpatient_procedures')}
            minValue={0}
            maxValue={99}
          />
        </div>
      </InputSection>

      {/* Routine Therapy Visits */}
      <InputSection>
        {renderTooltip(routine_therapy_visits_tooltip, routine_therapy_audio, 'routine-therapy-visits')}

        <div data-testid="routine-therapy-visits-counter">
          <Counter
            label={routine_therapy_visits_tooltip.title}
            id="routine-therapy-visits-counter"
            startValue={member.planned_procedures.routine_therapy_visits || 0}
            handleChange={(value) => handlePlannedProceduresChange(value, 'routine_therapy_visits')}
            minValue={0}
            maxValue={99}
          />
        </div>
      </InputSection>

      {/* Mental Health Visits */}
      <InputSection>
        {renderTooltip(mental_health_visits_tooltip, mental_health_audio, 'mental-health-visits')}

        <div data-testid="mental-health-visits-counter">
          <Counter
            label={mental_health_visits_tooltip.title}
            id="mental-health-visits-counter"
            startValue={member.planned_procedures.mental_health_visits || 0}
            handleChange={(value) => handlePlannedProceduresChange(value, 'mental_health_visits')}
            minValue={0}
            maxValue={99}
          />
        </div>
      </InputSection>
    </PlannedProceduresContainer>
  );
};

export default PlannedProceduresForm;
