import { ContentfulType } from 'ContentfulDefaults/ContentfulType';

import { alexIntro } from './alexIntro';
import { benefits } from './benefits';
import { benefitsAdditional } from './benefits_additional';
import { callToAction } from './callToAction';
import { customizations } from './customizations';
import { displayName } from './displayName';
import { eligibility } from './eligibility';
import { fbs } from './fbsTokens';
import { health } from './health';
import { healthDetails } from './healthDetails';
import { hsa } from './hsa';
import { logo } from './logo';
import { navigation } from './navigation';
import { ply } from './ply';
import { profile } from './profile';
import { review } from './review';
import { spousal } from './spousal';
import { toolTips } from './toolTips';
import { unifiedLanding } from './unifiedLanding';

export const getEnglishDefaults = (field: keyof ContentfulType) => {
  switch (field) {
    case 'alex_intro_page':
      return alexIntro;
    case 'benefits_section':
      return benefits;
    case 'benefits_section_additional':
      return benefitsAdditional;
    case 'call_to_action':
      return callToAction;
    case 'display_name':
      return displayName;
    case 'eligibility_section':
      return eligibility;
    case 'fbs_tokens':
      return fbs;
    case 'health_section':
      return health;
    case 'health_details_section':
      return healthDetails;
    case 'hsa':
      return hsa;
    case 'logo':
      return logo;
    case 'people_like_you':
      return ply;
    case 'plan_customizations':
      return customizations;
    case 'profile_section':
      return profile;
    case 'review_section':
      return review;
    case 'spousal_plan_comparison':
      return spousal;
    case 'tool_tips':
      return toolTips;
    case 'top_level_navigation':
      return navigation;
    case 'unified_landing_page':
      return unifiedLanding;
    default:
      return {};
  }
};
