import { STANDARD_EQ_TO_QUESTION_CODE } from 'Containers/ProfilePage/constants';
import { ClientSurveyResponses } from 'Containers/ProfilePage/types';
import { JVPEligibilityQuestion } from 'Utils/apiTypes';

export const determineHiddenEqs = (
  clientSurvey: ClientSurveyResponses | null,
  integratedEqs: JVPEligibilityQuestion[],
): string[] => {
  // Hide EQs if they have a Question Code and if we have an answer for them
  const hiddenEligibilityQuestions: string[] = [];
  Object.entries(clientSurvey || {}).forEach(([questionId, answer]) => {
    const questionCodeOrId =
      questionId in STANDARD_EQ_TO_QUESTION_CODE ? STANDARD_EQ_TO_QUESTION_CODE[questionId] : questionId;

    if (
      answer !== null &&
      (integratedEqs.find((q) => q.jv_eligibility_question_id === questionCodeOrId) ||
        integratedEqs.find((q) => q.question_code === questionCodeOrId))
    ) {
      hiddenEligibilityQuestions.push(questionId);
    }
  });

  return hiddenEligibilityQuestions;
};
