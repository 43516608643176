import { camelCase } from 'lodash';
import React from 'react';

import { DRUG_TYPES, SERVICE_TYPES } from 'Containers/ResultPage/constants';
import { H6, Numbers, Paragraph } from 'DesignLibrary/atoms';
import Plan from 'Models/plan';
import Text from 'Shared/Text';
import { toDollars } from 'Utils/helpers';

import { CopayTag } from '../styled';

export interface CopayInfoProps {
  plan: Plan;
  copayType: DRUG_TYPES | SERVICE_TYPES;
  perVisit?: boolean;
  /*
  tier is an optional argument that only gets passed when client is Vidant Health.
  because each copayType has an array of objects (multiple tiers) when the client is Vidant Health
  we are passing the specific tier that is getting rendered
  */
  tier?: {
    underline: string;
    copay: number;
    coinsurance: number;
    titleRef: string;
    textRef: string;
    copayText?: string;
    coinsuranceText?: string;
  };
}

const CopayInfo = ({ plan, copayType, perVisit = false, tier }: CopayInfoProps) => {
  const current = plan[copayType];
  const isCopay = current.copay != null;
  const isCovered = current.covered;
  const deductibleApplies = current.deductible_applies;

  if (!isCovered) {
    return (
      <Paragraph className="not-covered">
        <Text field="health_section.compare_plan_text.compareModal.notCovered" />
      </Paragraph>
    );
  }

  return (
    <div
      aria-labelledby={
        deductibleApplies ? `${copayType}${plan.planId}${camelCase(tier?.underline)}CopayCoinsuranceCell` : ''
      }
      aria-describedby={deductibleApplies ? 'afterDeductibleDisclaimer' : ''}
    >
      <div
        aria-hidden={!!deductibleApplies}
        className={isCopay ? 'copay' : 'coinsurance'}
        id={`${copayType}${plan.planId}${camelCase(tier?.underline)}CopayCoinsuranceCell`}
      >
        <div className="inline-numbers">
          <Numbers size="medium">
            {isCopay
              ? toDollars(tier ? tier.copay : current.copay)
              : `${tier ? tier.coinsurance : current.coinsurance}%`}
          </Numbers>
          {plan[copayType].max_cost_sharing && (
            <Paragraph size="small">
              <Text
                vars={{
                  x: toDollars(plan[copayType].max_cost_sharing),
                }}
                field="health_section.min_max_cost_sharing.maxCostSharing"
              />
            </Paragraph>
          )}
          {isCopay && perVisit && (
            <Paragraph size="small" color="--text-gray-light">
              <Text field="health_section.compare_plan_text.compareModal.perVisit" />
            </Paragraph>
          )}
          {deductibleApplies && '*'}
        </div>
        <CopayTag>
          <H6 as="span">
            {isCopay ? (
              <Text field="health_section.compare_plan_text.compareModal.copay" />
            ) : (
              <Text field="health_section.compare_plan_text.compareModal.coinsurance" />
            )}
          </H6>
        </CopayTag>
      </div>
    </div>
  );
};

export default CopayInfo;
