import styled from 'styled-components';

import { cardStyling } from 'DesignLibrary/utils';

export const ProgressGraphicContainer = styled.div`
  ${cardStyling}
  height: 420px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
`;
