import styled, { css } from 'styled-components';

import { media } from 'DesignLibrary/global';
import { FadeInUpKeyframes } from 'DesignLibrary/keyframes';
import { cardStyling } from 'DesignLibrary/utils';

interface ProfileContainerProps {
  isFirstPageView: boolean;
}
interface RightColumnContainerProps {
  isFirstPageView: boolean;
}

export const ProfileContainer = styled.div<ProfileContainerProps>`
  display: flex;
  flex-direction: column;
  row-gap: var(--sizing-sm);
  ${media.TABLET`row-gap: var(--sizing-md);`}
  opacity: 0;
  animation: ${FadeInUpKeyframes} 0.5s 3s forwards;

  ${({ isFirstPageView }) =>
    !isFirstPageView &&
    css`
      animation-delay: 1s;
    `}
`;

// Container to give the Profile Audio component the correct subtitle positioning
export const ProfileAudioContainer = styled.div`
  display: block;
  position: fixed;
  left: 0;
  bottom: var(--sizing-2xl); /* bottom at height of CtaBar */
  width: 100%;
  pointer-events: none; /* react-player & subs should not prevent clicks */
`;
// Container to make the Audio component size correctly and fit nicely within page margins/padding
export const ProfileAudioSubsContainer = styled.div`
  padding-left: var(--sizing-sm);
  padding-right: var(--sizing-sm);
  margin: 0 auto;
`;

export const RightColumnContainer = styled.div<RightColumnContainerProps>`
  flex-shrink: 0;
  opacity: 0;
  height: 100%;
  animation: ${FadeInUpKeyframes} 0.5s 2.8s forwards;

  ${({ isFirstPageView }) =>
    !isFirstPageView &&
    css`
      animation-delay: 1s;
    `}

  .link {
    color: var(--text-gray);
    font-size: 13px;
  }
`;

export const LoadingCard = styled.div`
  ${cardStyling}
`;
