import React from 'react';

import { EnteredPlan, BenefitDefinition } from 'Containers/CommercialRoutes/types';
import { useTextContext } from 'Contexts/textContext';
import { H1, Icon } from 'DesignLibrary/atoms';
import SelectInput, { Option } from 'DesignLibrary/atoms/inputs/SelectInput';
import { Tooltip } from 'DesignLibrary/atoms/Tooltip';
import Text from 'Shared/Text';

import BenefitGroup from './BenefitGroup';
import { BenefitsHeader } from './styled';
import { DrugTiers } from '../types';

const SerffCodes = {
  generic_tier_1: '28',
  preferred_tier_2: '29',
  non_preferred_tier_3: '30',
  specialty: '31',
};

interface PlanBenefitsProps {
  plan: EnteredPlan;
  savePlan: (plan: EnteredPlan) => void;
}

const PlanBenefits = ({ savePlan, plan }: PlanBenefitsProps) => {
  const { retrieveContentfulData } = useTextContext();
  const { benefitGroups, isHsaEligible } = plan;

  const drugTiers: DrugTiers = [
    {
      id: 'generic_tier_1',
      text: 'Generic (Tier 1)',
    },
    {
      id: 'preferred_tier_2',
      text: 'Preferred Brand (Tier 2)',
    },
    {
      id: 'non_preferred_tier_3',
      text: 'Non-preferred Brand (Tier 3)',
    },
    {
      id: 'specialty',
      text: 'Specialty (Tier 4)',
    },
  ];

  const handleBenefitChange = (benefitGroupId: string, benefitId: string, benefit: BenefitDefinition) => {
    const oldBenefit = plan.benefitGroups[benefitGroupId].benefits[benefitId];
    const newBenefit = {
      ...oldBenefit,
      ...benefit,
    };
    const updatedPlan = {
      ...plan,
      benefitGroups: {
        ...plan.benefitGroups,
        [benefitGroupId]: {
          benefits: {
            ...plan.benefitGroups[benefitGroupId].benefits,
            [benefitId]: newBenefit,
          },
        },
      },
    } as EnteredPlan;

    savePlan(updatedPlan);
  };

  const deleteBenefit = (benefitGroupId: string, benefitId: string) => {
    const updatedPlan = { ...plan };

    delete updatedPlan.benefitGroups[benefitGroupId].benefits[benefitId];
    savePlan(updatedPlan);
  };

  const addDrugTier = (e) => {
    const drugTierId = e.target.value;
    const index = e.target.options.selectedIndex;
    if (drugTierId !== 'none') {
      const benefit = {
        displayText: e.target.options[index].innerText,
        costSharingType: 'copay',
        costSharingAmount: 0,
        isSubjectToDeductible: true,
        serffCode: SerffCodes[drugTierId],
      } as BenefitDefinition;

      handleBenefitChange('drug', e.target.value, benefit);
    }
  };

  const drugTierOptions = drugTiers.reduce((accumulator: Option[], drugTier) => {
    const { id } = drugTier;
    if (!benefitGroups?.drug?.benefits[id]) {
      return [
        ...accumulator,
        {
          option: retrieveContentfulData<string>(`spousal_plan_comparison.benefit_groups.drug.types.${id}`),
          value: id,
        },
      ];
    }
    return accumulator;
  }, []);

  const benefitGroupIds = Object.keys(benefitGroups) as Array<keyof EnteredPlan['benefitGroups']>;
  const drugTierSelectPlaceholder = retrieveContentfulData<string>(
    'spousal_plan_comparison.button_text.drugTier',
  );

  return (
    <>
      <BenefitsHeader>
        <H1>
          <Text field="spousal_plan_comparison.benefits_title" />
        </H1>
        <div className="deductible">
          <Tooltip
            id="deductible-tooltip"
            label={{
              title: <Text field="spousal_plan_comparison.deductible_benefit.title" />,
              text: <Text field="spousal_plan_comparison.deductible_benefit.text" />,
            }}
          >
            <div className="deductible-row">
              <Icon type="Question" />
              <Text field="spousal_plan_comparison.deductible_benefit.title" />
            </div>
          </Tooltip>
        </div>
      </BenefitsHeader>

      {benefitGroupIds.map((benefitGroupId) => {
        const benefitGroup = benefitGroups[benefitGroupId];
        return (
          <BenefitGroup
            key={`benefitGroup-${benefitGroupId}`}
            benefitGroupId={benefitGroupId}
            benefitGroup={benefitGroup}
            handleBenefitChange={handleBenefitChange}
            deleteBenefit={deleteBenefit}
            isHsaEligible={isHsaEligible}
          />
        );
      })}

      <SelectInput
        name="drug-tier"
        id="add-drug-tier"
        handleChange={addDrugTier}
        placeholder={drugTierSelectPlaceholder}
        aria-label={drugTierSelectPlaceholder}
        options={drugTierOptions}
      />
    </>
  );
};

export default PlanBenefits;
