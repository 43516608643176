import styled from 'styled-components';

export const CostContainer = styled.div`
  .paragraph,
  p {
    b {
      color: var(--text-black) !important;
    }
  }
`;

export const IconTab = styled.section`
  display: inline-flex;
  gap: var(--sizing-sm);
  width: 100%;
  overflow-x: auto;
  padding: var(--sizing-xs) 0;
`;
