import img_0 from 'Assets/lottie/img_0.png';
import img_1 from 'Assets/lottie/img_1.png';
import img_10 from 'Assets/lottie/img_10.png';
import img_11 from 'Assets/lottie/img_11.png';
import img_12 from 'Assets/lottie/img_12.png';
import img_13 from 'Assets/lottie/img_13.png';
import img_14 from 'Assets/lottie/img_14.png';
import img_15 from 'Assets/lottie/img_15.png';
import img_16 from 'Assets/lottie/img_16.png';
import img_17 from 'Assets/lottie/img_17.png';
import img_18 from 'Assets/lottie/img_18.png';
import img_19 from 'Assets/lottie/img_19.png';
import img_2 from 'Assets/lottie/img_2.png';
import img_20 from 'Assets/lottie/img_20.png';
import img_3 from 'Assets/lottie/img_3.png';
import img_4 from 'Assets/lottie/img_4.png';
import img_5 from 'Assets/lottie/img_5.png';
import img_6 from 'Assets/lottie/img_6.png';
import img_7 from 'Assets/lottie/img_7.png';
import img_8 from 'Assets/lottie/img_8.png';
import img_9 from 'Assets/lottie/img_9.png';

export const animationData = {
  v: '5.7.4',
  fr: 24,
  ip: 0,
  op: 345,
  w: 976,
  h: 760,
  nm: 'Pre-comp 1',
  ddd: 0,
  assets: [
    {
      id: 'image_0',
      w: 80,
      h: 80,
      u: '',
      p: img_0,
      e: 0,
    },
    {
      id: 'image_1',
      w: 157,
      h: 291,
      u: '',
      p: img_1,
      e: 0,
    },
    {
      id: 'image_2',
      w: 223,
      h: 260,
      u: '',
      p: img_2,
      e: 0,
    },
    {
      id: 'image_3',
      w: 109,
      h: 359,
      u: '',
      p: img_3,
      e: 0,
    },
    {
      id: 'image_4',
      w: 177,
      h: 353,
      u: '',
      p: img_4,
      e: 0,
    },
    {
      id: 'image_5',
      w: 189,
      h: 240,
      u: '',
      p: img_5,
      e: 0,
    },
    {
      id: 'image_6',
      w: 109,
      h: 360,
      u: '',
      p: img_6,
      e: 0,
    },
    {
      id: 'image_7',
      w: 141,
      h: 263,
      u: '',
      p: img_7,
      e: 0,
    },
    {
      id: 'image_8',
      w: 200,
      h: 209,
      u: '',
      p: img_8,
      e: 0,
    },
    {
      id: 'image_9',
      w: 112,
      h: 329,
      u: '',
      p: img_9,
      e: 0,
    },
    {
      id: 'image_10',
      w: 168,
      h: 226,
      u: '',
      p: img_10,
      e: 0,
    },
    {
      id: 'image_11',
      w: 111,
      h: 385,
      u: '',
      p: img_11,
      e: 0,
    },
    {
      id: 'image_12',
      w: 223,
      h: 260,
      u: '',
      p: img_12,
      e: 0,
    },
    {
      id: 'image_13',
      w: 72,
      h: 72,
      u: '',
      p: img_13,
      e: 0,
    },
    {
      id: 'image_14',
      w: 151,
      h: 151,
      u: '',
      p: img_14,
      e: 0,
    },
    {
      id: 'image_15',
      w: 21,
      h: 21,
      u: '',
      p: img_15,
      e: 0,
    },
    {
      id: 'image_16',
      w: 20,
      h: 20,
      u: '',
      p: img_16,
      e: 0,
    },
    {
      id: 'image_17',
      w: 449,
      h: 450,
      u: '',
      p: img_17,
      e: 0,
    },
    {
      id: 'image_18',
      w: 453,
      h: 453,
      u: '',
      p: img_18,
      e: 0,
    },
    {
      id: 'image_19',
      w: 513,
      h: 513,
      u: '',
      p: img_19,
      e: 0,
    },
    {
      id: 'image_20',
      w: 566,
      h: 566,
      u: '',
      p: img_20,
      e: 0,
    },
    {
      id: 'comp_0',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 2,
          nm: 'Asset 37.png',
          cl: 'png',
          refId: 'image_0',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 393.6,
                  s: [0],
                },
                { t: 396, s: [40] },
              ],
              ix: 10,
            },
            p: { a: 0, k: [195, 665, 0], ix: 2, l: 2 },
            a: { a: 0, k: [40, 40, 0], ix: 1, l: 2 },
            s: { a: 0, k: [80, 80, 100], ix: 6, l: 2 },
          },
          ao: 0,
          ip: 393.6,
          op: 396.8,
          st: 223.2,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 2,
          nm: 'Asset 37.png',
          cl: 'png',
          refId: 'image_0',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 392.8,
                  s: [0],
                },
                { t: 395.2, s: [40] },
              ],
              ix: 10,
            },
            p: { a: 0, k: [566, 665, 0], ix: 2, l: 2 },
            a: { a: 0, k: [40, 40, 0], ix: 1, l: 2 },
            s: { a: 0, k: [80, 80, 100], ix: 6, l: 2 },
          },
          ao: 0,
          ip: 392.8,
          op: 395.2,
          st: 222.4,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 2,
          nm: 'Asset 37.png',
          cl: 'png',
          refId: 'image_0',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 393.6,
                  s: [0],
                },
                { t: 396, s: [40] },
              ],
              ix: 10,
            },
            p: { a: 0, k: [742, 665, 0], ix: 2, l: 2 },
            a: { a: 0, k: [40, 40, 0], ix: 1, l: 2 },
            s: { a: 0, k: [80, 80, 100], ix: 6, l: 2 },
          },
          ao: 0,
          ip: 393.6,
          op: 396.8,
          st: 264,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 2,
          nm: 'Asset 37.png',
          cl: 'png',
          refId: 'image_0',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 392.8,
                  s: [0],
                },
                { t: 395.2, s: [40] },
              ],
              ix: 10,
            },
            p: { a: 0, k: [380.111, 665, 0], ix: 2, l: 2 },
            a: { a: 0, k: [40, 40, 0], ix: 1, l: 2 },
            s: { a: 0, k: [80, 80, 100], ix: 6, l: 2 },
          },
          ao: 0,
          ip: 392.8,
          op: 395.2,
          st: 347.2,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 2,
          nm: 'Asset 37.png',
          cl: 'png',
          refId: 'image_0',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 392.8,
                  s: [0],
                },
                { t: 395.2, s: [40] },
              ],
              ix: 10,
            },
            p: { a: 0, k: [383.333, 399, 0], ix: 2, l: 2 },
            a: { a: 0, k: [40, 40, 0], ix: 1, l: 2 },
            s: { a: 0, k: [80, 80, 100], ix: 6, l: 2 },
          },
          ao: 0,
          ip: 392.8,
          op: 395.2,
          st: 179.2,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 6,
          ty: 2,
          nm: 'Asset 37.png',
          cl: 'png',
          refId: 'image_0',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 392.8,
                  s: [0],
                },
                { t: 395.2, s: [40] },
              ],
              ix: 10,
            },
            p: { a: 0, k: [562.722, 399, 0], ix: 2, l: 2 },
            a: { a: 0, k: [40, 40, 0], ix: 1, l: 2 },
            s: { a: 0, k: [80, 80, 100], ix: 6, l: 2 },
          },
          ao: 0,
          ip: 392.8,
          op: 395.2,
          st: 305.6,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 7,
          ty: 2,
          nm: 'Asset 37.png',
          cl: 'png',
          refId: 'image_0',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 393.6,
                  s: [0],
                },
                { t: 396, s: [40] },
              ],
              ix: 10,
            },
            p: { a: 0, k: [196.111, 399, 0], ix: 2, l: 2 },
            a: { a: 0, k: [40, 40, 0], ix: 1, l: 2 },
            s: { a: 0, k: [80, 80, 100], ix: 6, l: 2 },
          },
          ao: 0,
          ip: 393.6,
          op: 396,
          st: 264,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 8,
          ty: 2,
          nm: 'Asset 37.png',
          cl: 'png',
          refId: 'image_0',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 393.6,
                  s: [0],
                },
                { t: 396, s: [40] },
              ],
              ix: 10,
            },
            p: { a: 0, k: [742, 399, 0], ix: 2, l: 2 },
            a: { a: 0, k: [40, 40, 0], ix: 1, l: 2 },
            s: { a: 0, k: [80, 80, 100], ix: 6, l: 2 },
          },
          ao: 0,
          ip: 393.6,
          op: 396,
          st: 96,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 9,
          ty: 2,
          nm: 'Asset 37.png',
          cl: 'png',
          refId: 'image_0',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 392.8,
                  s: [0],
                },
                { t: 395.2, s: [40] },
              ],
              ix: 10,
            },
            p: { a: 0, k: [562.722, 144, 0], ix: 2, l: 2 },
            a: { a: 0, k: [40, 40, 0], ix: 1, l: 2 },
            s: { a: 0, k: [80, 80, 100], ix: 6, l: 2 },
          },
          ao: 0,
          ip: 392.8,
          op: 395.2,
          st: 305.6,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 10,
          ty: 2,
          nm: 'Asset 37.png',
          cl: 'png',
          refId: 'image_0',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 392.8,
                  s: [0],
                },
                { t: 395.2, s: [40] },
              ],
              ix: 10,
            },
            p: { a: 0, k: [380.111, 144, 0], ix: 2, l: 2 },
            a: { a: 0, k: [40, 40, 0], ix: 1, l: 2 },
            s: { a: 0, k: [80, 80, 100], ix: 6, l: 2 },
          },
          ao: 0,
          ip: 392.8,
          op: 395.2,
          st: 220.8,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 11,
          ty: 2,
          nm: 'Asset 37.png',
          cl: 'png',
          refId: 'image_0',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 393.6,
                  s: [0],
                },
                { t: 396, s: [40] },
              ],
              ix: 10,
            },
            p: { a: 0, k: [742, 144, 0], ix: 2, l: 2 },
            a: { a: 0, k: [40, 40, 0], ix: 1, l: 2 },
            s: { a: 0, k: [80, 80, 100], ix: 6, l: 2 },
          },
          ao: 0,
          ip: 393.6,
          op: 396.8,
          st: 137.6,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 12,
          ty: 2,
          nm: 'Asset 37.png',
          cl: 'png',
          refId: 'image_0',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 393.6,
                  s: [0],
                },
                { t: 396, s: [40] },
              ],
              ix: 10,
            },
            p: { a: 0, k: [193.333, 144, 0], ix: 2, l: 2 },
            a: { a: 0, k: [40, 40, 0], ix: 1, l: 2 },
            s: { a: 0, k: [80, 80, 100], ix: 6, l: 2 },
          },
          ao: 0,
          ip: 393.6,
          op: 396.8,
          st: 135.2,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 13,
          ty: 2,
          nm: 'Asset 14.png',
          cl: 'png',
          refId: 'image_1',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [752, 665.86, 0], ix: 2, l: 2 },
            a: { a: 0, k: [78.5, 145.5, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 396.8,
                  s: [38, 38, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 399.2,
                  s: [59, 59, 100],
                },
                { t: 400.8, s: [56, 56, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          ip: 396.8,
          op: 489.6,
          st: 397.2,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 14,
          ty: 2,
          nm: 'Asset 13.png',
          cl: 'png',
          refId: 'image_2',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [562.722, 674.54, 0], ix: 2, l: 2 },
            a: { a: 0, k: [111.5, 130, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 395.2,
                  s: [38, 38, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 397.6,
                  s: [59, 59, 100],
                },
                { t: 399.2, s: [56, 56, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          ip: 395.2,
          op: 487.2,
          st: 395.9,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 15,
          ty: 2,
          nm: 'Asset 12.png',
          cl: 'png',
          refId: 'image_3',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [380.111, 646.82, 0], ix: 2, l: 2 },
            a: { a: 0, k: [54.5, 179.5, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 395.2,
                  s: [38, 38, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 397.6,
                  s: [59, 59, 100],
                },
                { t: 399.2, s: [56, 56, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          ip: 395.2,
          op: 488,
          st: 397,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 16,
          ty: 2,
          nm: 'Asset 11.png',
          cl: 'png',
          refId: 'image_4',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [196.333, 648.5, 0], ix: 2, l: 2 },
            a: { a: 0, k: [88.5, 176.5, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 396.8,
                  s: [38, 38, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 399.2,
                  s: [59, 59, 100],
                },
                { t: 400.8, s: [56, 56, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          ip: 396.8,
          op: 488.8,
          st: 398.1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 17,
          ty: 2,
          nm: 'Asset 10.png',
          cl: 'png',
          refId: 'image_5',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [752, 405, 0], ix: 2, l: 2 },
            a: { a: 0, k: [94.5, 120, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 396,
                  s: [38, 38, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 398.4,
                  s: [59, 59, 100],
                },
                { t: 400, s: [56, 56, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          ip: 396,
          op: 488.8,
          st: 396.7,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 18,
          ty: 2,
          nm: 'Asset 9.png',
          cl: 'png',
          refId: 'image_6',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [562.722, 371.4, 0], ix: 2, l: 2 },
            a: { a: 0, k: [54.5, 180, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 395.2,
                  s: [38, 38, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 397.6,
                  s: [59, 59, 100],
                },
                { t: 399.2, s: [56, 56, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          ip: 395.2,
          op: 488,
          st: 396,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 19,
          ty: 2,
          nm: 'Asset 8.png',
          cl: 'png',
          refId: 'image_7',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [380.111, 398.56, 0], ix: 2, l: 2 },
            a: { a: 0, k: [70.5, 131.5, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 395.2,
                  s: [38, 38, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 397.6,
                  s: [59, 59, 100],
                },
                { t: 399.2, s: [56, 56, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          ip: 395.2,
          op: 488,
          st: 398.5,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 20,
          ty: 2,
          nm: 'Asset 7.png',
          cl: 'png',
          refId: 'image_8',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [196.333, 413.68, 0], ix: 2, l: 2 },
            a: { a: 0, k: [100, 104.5, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 396,
                  s: [38, 38, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 398.4,
                  s: [59, 59, 100],
                },
                { t: 400, s: [56, 56, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          ip: 396,
          op: 488.8,
          st: 398.2,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 21,
          ty: 2,
          nm: 'Asset 6.png',
          cl: 'png',
          refId: 'image_9',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [752, 125, 0], ix: 2, l: 2 },
            a: { a: 0, k: [56, 164.5, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 396.8,
                  s: [38, 38, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 399.2,
                  s: [59, 59, 100],
                },
                { t: 400.8, s: [56, 56, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          ip: 396.8,
          op: 489.6,
          st: 398.5,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 22,
          ty: 2,
          nm: 'Asset 5.png',
          cl: 'png',
          refId: 'image_10',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [562.722, 153.84, 0], ix: 2, l: 2 },
            a: { a: 0, k: [84, 113, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 395.2,
                  s: [38, 38, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 397.6,
                  s: [59, 59, 100],
                },
                { t: 399.2, s: [56, 56, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          ip: 395.2,
          op: 488,
          st: 396.6,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 23,
          ty: 2,
          nm: 'Asset 4.png',
          cl: 'png',
          refId: 'image_11',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [380.111, 109.32, 0], ix: 2, l: 2 },
            a: { a: 0, k: [55.5, 192.5, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 395.2,
                  s: [38, 38, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 397.6,
                  s: [59, 59, 100],
                },
                { t: 399.2, s: [56, 56, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          ip: 395.2,
          op: 488,
          st: 396.3,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 24,
          ty: 2,
          nm: 'Asset 3.png',
          cl: 'png',
          refId: 'image_12',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [196.333, 144.32, 0], ix: 2, l: 2 },
            a: { a: 0, k: [111.5, 130, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 396.8,
                  s: [38, 38, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 399.2,
                  s: [59, 59, 100],
                },
                { t: 400.8, s: [56, 56, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          ip: 396.8,
          op: 489.6,
          st: 397.6,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_1',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 2,
          nm: 'Asset 37.png',
          cl: 'png',
          refId: 'image_0',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 256.4,
                  s: [0],
                },
                { t: 261.2, s: [40] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 186.8,
                  s: [196.333, 144.32, 0],
                  to: [30.167, 0, 0],
                  ti: [-30.167, 0, 0],
                },
                { t: 192.8, s: [377.333, 144.32, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [40, 40, 0], ix: 1, l: 2 },
            s: { a: 0, k: [80, 80, 100], ix: 6, l: 2 },
          },
          ao: 0,
          ip: 256,
          op: 261.2,
          st: 0.8,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 2,
          nm: 'Asset 3.png',
          cl: 'png',
          refId: 'image_12',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 185.6,
                  s: [196.333, 144.32, 0],
                  to: [30.167, 0, 0],
                  ti: [-30.167, 0, 0],
                },
                { t: 189.6, s: [377.333, 144.32, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [111.5, 130, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 252.8,
                  s: [56, 56, 100],
                },
                { t: 256, s: [38, 38, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          ip: 0,
          op: 256,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_2',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 2,
          nm: 'Asset 37.png',
          cl: 'png',
          refId: 'image_0',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 174,
                  s: [0],
                },
                { t: 178.8, s: [40] },
              ],
              ix: 10,
            },
            p: { a: 0, k: [380.111, 109.32, 0], ix: 2, l: 2 },
            a: { a: 0, k: [40, 40, 0], ix: 1, l: 2 },
            s: { a: 0, k: [80, 80, 100], ix: 6, l: 2 },
          },
          ao: 0,
          ip: 174,
          op: 178.8,
          st: 2.4,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 2,
          nm: 'Asset 4.png',
          cl: 'png',
          refId: 'image_11',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [380.111, 109.32, 0], ix: 2, l: 2 },
            a: { a: 0, k: [55.5, 192.5, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 170.4,
                  s: [56, 56, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 173.6,
                  s: [38, 38, 100],
                },
                { t: 248.8, s: [56, 56, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          ip: 0,
          op: 174,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_3',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 2,
          nm: 'Asset 37.png',
          cl: 'png',
          refId: 'image_0',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 90,
                  s: [0],
                },
                { t: 94.8, s: [40] },
              ],
              ix: 10,
            },
            p: { a: 0, k: [562.722, 153.84, 0], ix: 2, l: 2 },
            a: { a: 0, k: [40, 40, 0], ix: 1, l: 2 },
            s: { a: 0, k: [80, 80, 100], ix: 6, l: 2 },
          },
          ao: 0,
          ip: 90,
          op: 94.8,
          st: 2.4,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 2,
          nm: 'Asset 5.png',
          cl: 'png',
          refId: 'image_10',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [562.722, 153.84, 0], ix: 2, l: 2 },
            a: { a: 0, k: [84, 113, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 86.4,
                  s: [56, 56, 100],
                },
                { t: 89.6, s: [38, 38, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          ip: 0,
          op: 90,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_4',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 2,
          nm: 'Asset 37.png',
          cl: 'png',
          refId: 'image_0',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 300,
                  s: [0],
                },
                { t: 304.8, s: [40] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 104.4,
                  s: [752, 125, 0],
                  to: [-31.833, 0, 0],
                  ti: [31.833, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 110.4,
                  s: [561, 125, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 272.4,
                  s: [561, 125, 0],
                  to: [0, 43, 0],
                  ti: [0, -43, 0],
                },
                { t: 278.4, s: [561, 383, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [40, 40, 0], ix: 1, l: 2 },
            s: { a: 0, k: [80, 80, 100], ix: 6, l: 2 },
          },
          ao: 0,
          ip: 300,
          op: 304.8,
          st: 2.4,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 2,
          nm: 'Asset 6.png',
          cl: 'png',
          refId: 'image_9',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 103.2,
                  s: [752, 125, 0],
                  to: [-31.833, 0, 0],
                  ti: [31.833, 0, 0],
                },
                {
                  i: { x: 0.667, y: 0.667 },
                  o: { x: 0.333, y: 0.333 },
                  t: 107.2,
                  s: [561, 125, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 269.6,
                  s: [561, 125, 0],
                  to: [0, 43, 0],
                  ti: [0, -43, 0],
                },
                { t: 273.6, s: [561, 383, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [56, 164.5, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 296.8,
                  s: [56, 56, 100],
                },
                { t: 300, s: [38, 38, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          ip: 0,
          op: 300,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_5',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 2,
          nm: 'Asset 30.png',
          cl: 'png',
          refId: 'image_13',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 397.2,
                  s: [0],
                },
                { t: 400.8, s: [40] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 230.4,
                  s: [196.333, 413.68, 0],
                  to: [30.667, 0, 0],
                  ti: [-30.667, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 236.4,
                  s: [380.333, 413.68, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 314.4,
                  s: [380.333, 413.68, 0],
                  to: [17.944, 0, 0],
                  ti: [-17.944, 0, 0],
                },
                { t: 320.4, s: [488, 413.68, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [36, 36, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 331.2,
                  s: [56, 56, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 332.4,
                  s: [94, 94, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 397.2,
                  s: [82, 82, 100],
                },
                { t: 400.8, s: [60, 60, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          ip: 397.6,
          op: 400.8,
          st: 2.4,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 2,
          nm: 'pop',
          refId: 'image_14',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 397.2,
                  s: [0],
                },
                { t: 400.8, s: [40] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 230.4,
                  s: [196.333, 413.68, 0],
                  to: [30.667, 0, 0],
                  ti: [-30.667, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 236.4,
                  s: [380.333, 413.68, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 314.4,
                  s: [380.333, 413.68, 0],
                  to: [17.944, 0, 0],
                  ti: [-17.944, 0, 0],
                },
                { t: 320.4, s: [488, 413.68, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [75.5, 75.5, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 331.2,
                  s: [56, 56, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 332.4,
                  s: [94, 94, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 397.2,
                  s: [82, 82, 100],
                },
                { t: 400.8, s: [60, 60, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          ip: 397.6,
          op: 400.8,
          st: 2.4,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 2,
          nm: 'Asset 7.png',
          cl: 'png',
          refId: 'image_8',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 228,
                  s: [196.333, 413.68, 0],
                  to: [30.667, 0, 0],
                  ti: [-30.667, 0, 0],
                },
                {
                  i: { x: 0.667, y: 0.667 },
                  o: { x: 0.333, y: 0.333 },
                  t: 232,
                  s: [380.333, 413.68, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 312,
                  s: [380.333, 413.68, 0],
                  to: [17.944, 0, 0],
                  ti: [-17.944, 0, 0],
                },
                { t: 316, s: [488, 413.68, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [100, 104.5, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 324,
                  s: [56, 56, 100],
                },
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 328,
                  s: [41, 41, 100],
                },
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 332.8,
                  s: [41, 41, 100],
                },
                {
                  i: { x: [0.662, 0.662, 0.662], y: [1, 1, 1] },
                  o: { x: [0.329, 0.329, 0.329], y: [0, 0, 0] },
                  t: 335.2,
                  s: [105, 105, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
                  o: { x: [0.342, 0.342, 0.342], y: [0, 0, 0] },
                  t: 336.8,
                  s: [94, 94, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 391.2,
                  s: [94, 94, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 395.2,
                  s: [127, 127, 100],
                },
                { t: 396.8, s: [43, 43, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          ip: 0,
          op: 397.2,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_6',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 2,
          nm: 'Asset 37.png',
          cl: 'png',
          refId: 'image_0',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 132,
                  s: [0],
                },
                { t: 136.8, s: [40] },
              ],
              ix: 10,
            },
            p: { a: 0, k: [380.111, 398.56, 0], ix: 2, l: 2 },
            a: { a: 0, k: [40, 40, 0], ix: 1, l: 2 },
            s: { a: 0, k: [80, 80, 100], ix: 6, l: 2 },
          },
          ao: 0,
          ip: 132,
          op: 136.8,
          st: 2.4,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 2,
          nm: 'Asset 8.png',
          cl: 'png',
          refId: 'image_7',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [380.111, 398.56, 0], ix: 2, l: 2 },
            a: { a: 0, k: [70.5, 131.5, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 128.8,
                  s: [56, 56, 100],
                },
                { t: 132, s: [38, 38, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          ip: 0,
          op: 132,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_7',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 2,
          nm: 'Asset 37.png',
          cl: 'png',
          refId: 'image_0',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 90,
                  s: [0],
                },
                { t: 94.8, s: [40] },
              ],
              ix: 10,
            },
            p: { a: 0, k: [562.722, 371.4, 0], ix: 2, l: 2 },
            a: { a: 0, k: [40, 40, 0], ix: 1, l: 2 },
            s: { a: 0, k: [80, 80, 100], ix: 6, l: 2 },
          },
          ao: 0,
          ip: 90,
          op: 94.8,
          st: 2.4,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 2,
          nm: 'Asset 9.png',
          cl: 'png',
          refId: 'image_6',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [562.722, 371.4, 0], ix: 2, l: 2 },
            a: { a: 0, k: [54.5, 180, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 86.4,
                  s: [56, 56, 100],
                },
                { t: 89.6, s: [38, 38, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          ip: 0,
          op: 90,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_8',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 2,
          nm: 'Asset 37.png',
          cl: 'png',
          refId: 'image_0',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 258,
                  s: [0],
                },
                { t: 262.8, s: [40] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 104.4,
                  s: [752, 405, 0],
                  to: [-31.833, 0, 0],
                  ti: [31.833, 0, 0],
                },
                { t: 110.4, s: [561, 405, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [40, 40, 0], ix: 1, l: 2 },
            s: { a: 0, k: [80, 80, 100], ix: 6, l: 2 },
          },
          ao: 0,
          ip: 258,
          op: 262.8,
          st: 2.4,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 2,
          nm: 'Asset 10.png',
          cl: 'png',
          refId: 'image_5',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 101.6,
                  s: [752, 405, 0],
                  to: [-31.833, 0, 0],
                  ti: [31.833, 0, 0],
                },
                { t: 105.6, s: [561, 405, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [94.5, 120, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 254.4,
                  s: [56, 56, 100],
                },
                { t: 257.6, s: [38, 38, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          ip: 0,
          op: 258,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_9',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 2,
          nm: 'Asset 37.png',
          cl: 'png',
          refId: 'image_0',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 216,
                  s: [0],
                },
                { t: 220.8, s: [40] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 62.4,
                  s: [196.333, 648.5, 0],
                  to: [31.167, 0, 0],
                  ti: [-31.167, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 68.4,
                  s: [383.333, 648.5, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 146.4,
                  s: [383.333, 648.5, 0],
                  to: [0, -45.667, 0],
                  ti: [0, 45.667, 0],
                },
                { t: 152.4, s: [383.333, 374.5, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [40, 40, 0], ix: 1, l: 2 },
            s: { a: 0, k: [80, 80, 100], ix: 6, l: 2 },
          },
          ao: 0,
          ip: 216,
          op: 220.8,
          st: 2.4,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 2,
          nm: 'Asset 11.png',
          cl: 'png',
          refId: 'image_4',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 60,
                  s: [196.333, 648.5, 0],
                  to: [31.167, 0, 0],
                  ti: [-31.167, 0, 0],
                },
                {
                  i: { x: 0.667, y: 0.667 },
                  o: { x: 0.333, y: 0.333 },
                  t: 64,
                  s: [383.333, 648.5, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 143.2,
                  s: [383.333, 648.5, 0],
                  to: [0, -45.667, 0],
                  ti: [0, 45.667, 0],
                },
                { t: 147.2, s: [383.333, 374.5, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [88.5, 176.5, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 212.8,
                  s: [56, 56, 100],
                },
                { t: 216, s: [38, 38, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          ip: 0,
          op: 216,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_10',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 2,
          nm: 'Asset 37.png',
          cl: 'png',
          refId: 'image_0',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 48,
                  s: [0],
                },
                { t: 52.8, s: [40] },
              ],
              ix: 10,
            },
            p: { a: 0, k: [380.111, 646.82, 0], ix: 2, l: 2 },
            a: { a: 0, k: [40, 40, 0], ix: 1, l: 2 },
            s: { a: 0, k: [80, 80, 100], ix: 6, l: 2 },
          },
          ao: 0,
          ip: 48,
          op: 52.8,
          st: 2.4,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 2,
          nm: 'Asset 12.png',
          cl: 'png',
          refId: 'image_3',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [380.111, 646.82, 0], ix: 2, l: 2 },
            a: { a: 0, k: [54.5, 179.5, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 44.8,
                  s: [56, 56, 100],
                },
                { t: 48, s: [38, 38, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          ip: 0,
          op: 48,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_11',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 2,
          nm: 'Asset 37.png',
          cl: 'png',
          refId: 'image_0',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 132,
                  s: [0],
                },
                { t: 136.8, s: [40] },
              ],
              ix: 10,
            },
            p: { a: 0, k: [562.722, 674.54, 0], ix: 2, l: 2 },
            a: { a: 0, k: [40, 40, 0], ix: 1, l: 2 },
            s: { a: 0, k: [80, 80, 100], ix: 6, l: 2 },
          },
          ao: 0,
          ip: 132,
          op: 136.8,
          st: 2.4,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 2,
          nm: 'Asset 13.png',
          cl: 'png',
          refId: 'image_2',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [562.722, 674.54, 0], ix: 2, l: 2 },
            a: { a: 0, k: [111.5, 130, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 128.8,
                  s: [56, 56, 100],
                },
                { t: 132, s: [38, 38, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          ip: 0,
          op: 132,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_12',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 2,
          nm: 'Asset 37.png',
          cl: 'png',
          refId: 'image_0',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 174,
                  s: [0],
                },
                { t: 178.8, s: [40] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 146.4,
                  s: [752, 665.86, 0],
                  to: [-31, 0, 0],
                  ti: [31, 0, 0],
                },
                { t: 152.4, s: [566, 665.86, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [40, 40, 0], ix: 1, l: 2 },
            s: { a: 0, k: [80, 80, 100], ix: 6, l: 2 },
          },
          ao: 0,
          ip: 174,
          op: 178.8,
          st: 2.4,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 2,
          nm: 'Asset 14.png',
          cl: 'png',
          refId: 'image_1',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 144,
                  s: [752, 665.86, 0],
                  to: [-31, 0, 0],
                  ti: [31, 0, 0],
                },
                { t: 148, s: [566, 665.86, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [78.5, 145.5, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 170.4,
                  s: [56, 56, 100],
                },
                { t: 173.6, s: [38, 38, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          ip: 0,
          op: 174,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_13',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 2,
          nm: 'Asset 38.png',
          cl: 'png',
          refId: 'image_17',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [222.5, 223, 0], ix: 2, l: 2 },
            a: { a: 0, k: [224.5, 225, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          ip: 0,
          op: 291.2,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_14',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 2,
          nm: 'Asset 42.png',
          cl: 'png',
          refId: 'image_18',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [222.5, 223, 0], ix: 2, l: 2 },
            a: { a: 0, k: [226.5, 226.5, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          ip: 0,
          op: 291.2,
          st: 0,
          bm: 0,
        },
      ],
    },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 0,
      nm: 'Refresh',
      refId: 'comp_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: -82.4,
      op: 349.6,
      st: -82.4,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 0,
      nm: '11',
      refId: 'comp_1',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 193.6,
      op: 327.2,
      st: -104.8,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 0,
      nm: '11',
      refId: 'comp_1',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 167.2,
      op: 193.6,
      st: -89.2,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 0,
      nm: '11',
      refId: 'comp_1',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 138.4,
      op: 167.2,
      st: -76,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 0,
      nm: '11',
      refId: 'comp_1',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 111.2,
      op: 138.4,
      st: -61.2,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 0,
      nm: '11',
      refId: 'comp_1',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 82.4,
      op: 111.2,
      st: -48,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 7,
      ty: 0,
      nm: '11',
      refId: 'comp_1',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 54,
      op: 82.4,
      st: -34.8,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 8,
      ty: 0,
      nm: '11',
      refId: 'comp_1',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 0,
      op: 54,
      st: -24,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 9,
      ty: 0,
      nm: '12',
      refId: 'comp_2',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [486.75, 379.5, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 193.6,
      op: 327.2,
      st: -104.8,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 10,
      ty: 0,
      nm: '12',
      refId: 'comp_2',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 167.2,
      op: 193.6,
      st: -89.2,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 11,
      ty: 0,
      nm: '12',
      refId: 'comp_2',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 138.4,
      op: 167.2,
      st: -76,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 12,
      ty: 0,
      nm: '12',
      refId: 'comp_2',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 111.2,
      op: 138.4,
      st: -61.2,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 13,
      ty: 0,
      nm: '12',
      refId: 'comp_2',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 82.4,
      op: 111.2,
      st: -48,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 14,
      ty: 0,
      nm: '12',
      refId: 'comp_2',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 54,
      op: 82.4,
      st: -34.8,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 15,
      ty: 0,
      nm: '12',
      refId: 'comp_2',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 0,
      op: 54,
      st: -24,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 16,
      ty: 0,
      nm: '13',
      refId: 'comp_3',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 193.6,
      op: 327.2,
      st: -104.8,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 17,
      ty: 0,
      nm: '13',
      refId: 'comp_3',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 167.2,
      op: 193.6,
      st: -89.2,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 18,
      ty: 0,
      nm: '13',
      refId: 'comp_3',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 138.4,
      op: 167.2,
      st: -76,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 19,
      ty: 0,
      nm: '13',
      refId: 'comp_3',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 111.2,
      op: 138.4,
      st: -61.2,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 20,
      ty: 0,
      nm: '13',
      refId: 'comp_3',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 82.4,
      op: 111.2,
      st: -43.2,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 21,
      ty: 0,
      nm: '13',
      refId: 'comp_3',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 54,
      op: 82.4,
      st: -34.8,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 22,
      ty: 0,
      nm: '13',
      refId: 'comp_3',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 0,
      op: 54,
      st: -24,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 23,
      ty: 0,
      nm: '14',
      refId: 'comp_4',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 193.6,
      op: 327.2,
      st: -104.8,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 24,
      ty: 0,
      nm: '14',
      refId: 'comp_4',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 167.2,
      op: 193.6,
      st: -89.2,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 25,
      ty: 0,
      nm: '14',
      refId: 'comp_4',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 138.4,
      op: 167.2,
      st: -76,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 26,
      ty: 0,
      nm: '14',
      refId: 'comp_4',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 111.2,
      op: 138.4,
      st: -61.2,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 27,
      ty: 0,
      nm: '14',
      refId: 'comp_4',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 82.4,
      op: 111.2,
      st: -48,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 28,
      ty: 0,
      nm: '14',
      refId: 'comp_4',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 54,
      op: 82.4,
      st: -34.8,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 29,
      ty: 0,
      nm: '14',
      refId: 'comp_4',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 0,
      op: 54,
      st: -24,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 30,
      ty: 0,
      nm: '21',
      refId: 'comp_5',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 193.6,
      op: 327.2,
      st: -104.8,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 31,
      ty: 0,
      nm: '21',
      refId: 'comp_5',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 167.2,
      op: 193.6,
      st: -89.2,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 32,
      ty: 0,
      nm: '21',
      refId: 'comp_5',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 138.4,
      op: 167.2,
      st: -76,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 33,
      ty: 0,
      nm: '21',
      refId: 'comp_5',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 111.2,
      op: 138.4,
      st: -61.2,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 34,
      ty: 0,
      nm: '21',
      refId: 'comp_5',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 82.4,
      op: 111.2,
      st: -48,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 35,
      ty: 0,
      nm: '21',
      refId: 'comp_5',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 54,
      op: 82.4,
      st: -34.8,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 36,
      ty: 0,
      nm: '21',
      refId: 'comp_5',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 0,
      op: 54,
      st: -24,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 37,
      ty: 0,
      nm: '22',
      refId: 'comp_6',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 193.6,
      op: 327.2,
      st: -104.8,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 38,
      ty: 0,
      nm: '22',
      refId: 'comp_6',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 167.2,
      op: 193.6,
      st: -89.2,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 39,
      ty: 0,
      nm: '22',
      refId: 'comp_6',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 138.4,
      op: 167.2,
      st: -76,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 40,
      ty: 0,
      nm: '22',
      refId: 'comp_6',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 111.2,
      op: 138.4,
      st: -61.2,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 41,
      ty: 0,
      nm: '22',
      refId: 'comp_6',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 82.4,
      op: 111.2,
      st: -48,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 42,
      ty: 0,
      nm: '22',
      refId: 'comp_6',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 54,
      op: 82.4,
      st: -34.8,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 43,
      ty: 0,
      nm: '22',
      refId: 'comp_6',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 0,
      op: 54,
      st: -24,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 44,
      ty: 0,
      nm: '23',
      refId: 'comp_7',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 193.6,
      op: 327.2,
      st: -104.8,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 45,
      ty: 0,
      nm: '23',
      refId: 'comp_7',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 167.2,
      op: 193.6,
      st: -89.2,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 46,
      ty: 0,
      nm: '23',
      refId: 'comp_7',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 138.4,
      op: 167.2,
      st: -76,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 47,
      ty: 0,
      nm: '23',
      refId: 'comp_7',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 111.2,
      op: 138.4,
      st: -61.2,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 48,
      ty: 0,
      nm: '23',
      refId: 'comp_7',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 82.4,
      op: 111.2,
      st: -48,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 49,
      ty: 0,
      nm: '23',
      refId: 'comp_7',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 54,
      op: 82.4,
      st: -34.8,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 50,
      ty: 0,
      nm: '23',
      refId: 'comp_7',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 0,
      op: 54,
      st: -24,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 51,
      ty: 0,
      nm: '24',
      refId: 'comp_8',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 193.6,
      op: 327.2,
      st: -104.8,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 52,
      ty: 0,
      nm: '24',
      refId: 'comp_8',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 167.2,
      op: 193.6,
      st: -91.6,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 53,
      ty: 0,
      nm: '24',
      refId: 'comp_8',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 138.4,
      op: 167.2,
      st: -76,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 54,
      ty: 0,
      nm: '24',
      refId: 'comp_8',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 111.2,
      op: 138.4,
      st: -61.2,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 55,
      ty: 0,
      nm: '24',
      refId: 'comp_8',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 82.4,
      op: 111.2,
      st: -48,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 56,
      ty: 0,
      nm: '24',
      refId: 'comp_8',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 54,
      op: 82.4,
      st: -34.8,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 57,
      ty: 0,
      nm: '24',
      refId: 'comp_8',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 0,
      op: 54,
      st: -24,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 58,
      ty: 0,
      nm: '31',
      refId: 'comp_9',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 193.6,
      op: 327.2,
      st: -104.8,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 59,
      ty: 0,
      nm: '31',
      refId: 'comp_9',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 167.2,
      op: 193.6,
      st: -89.2,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 60,
      ty: 0,
      nm: '31',
      refId: 'comp_9',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 138.4,
      op: 167.2,
      st: -76,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 61,
      ty: 0,
      nm: '31',
      refId: 'comp_9',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 111.2,
      op: 138.4,
      st: -61.2,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 62,
      ty: 0,
      nm: '31',
      refId: 'comp_9',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 82.4,
      op: 111.2,
      st: -48,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 63,
      ty: 0,
      nm: '31',
      refId: 'comp_9',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 54,
      op: 82.4,
      st: -34.8,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 64,
      ty: 0,
      nm: '31',
      refId: 'comp_9',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 0,
      op: 54,
      st: -24,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 65,
      ty: 0,
      nm: '32',
      refId: 'comp_10',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 193.6,
      op: 327.2,
      st: -104.8,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 66,
      ty: 0,
      nm: '32',
      refId: 'comp_10',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 167.2,
      op: 193.6,
      st: -89.2,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 67,
      ty: 0,
      nm: '32',
      refId: 'comp_10',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 138.4,
      op: 167.2,
      st: -76,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 68,
      ty: 0,
      nm: '32',
      refId: 'comp_10',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 111.2,
      op: 138.4,
      st: -61.2,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 69,
      ty: 0,
      nm: '32',
      refId: 'comp_10',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 82.4,
      op: 111.2,
      st: -48,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 70,
      ty: 0,
      nm: '32',
      refId: 'comp_10',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 54,
      op: 82.4,
      st: -34.8,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 71,
      ty: 0,
      nm: '32',
      refId: 'comp_10',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 0,
      op: 54,
      st: -24,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 72,
      ty: 0,
      nm: '33',
      refId: 'comp_11',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 193.6,
      op: 327.2,
      st: -104.8,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 73,
      ty: 0,
      nm: '33',
      refId: 'comp_11',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 167.2,
      op: 193.6,
      st: -89.2,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 74,
      ty: 0,
      nm: '33',
      refId: 'comp_11',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 138.4,
      op: 167.2,
      st: -76,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 75,
      ty: 0,
      nm: '33',
      refId: 'comp_11',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 111.2,
      op: 138.4,
      st: -61.2,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 76,
      ty: 0,
      nm: '33',
      refId: 'comp_11',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 82.4,
      op: 111.2,
      st: -48,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 77,
      ty: 0,
      nm: '33',
      refId: 'comp_11',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 54,
      op: 82.4,
      st: -34.8,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 78,
      ty: 0,
      nm: '33',
      refId: 'comp_11',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 0,
      op: 54,
      st: -24,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 79,
      ty: 0,
      nm: '34',
      refId: 'comp_12',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 193.6,
      op: 327.2,
      st: -104.8,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 80,
      ty: 0,
      nm: '34',
      refId: 'comp_12',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 167.2,
      op: 193.6,
      st: -89.2,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 81,
      ty: 0,
      nm: '34',
      refId: 'comp_12',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 138.4,
      op: 167.2,
      st: -76,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 82,
      ty: 0,
      nm: '34',
      refId: 'comp_12',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 111.2,
      op: 138.4,
      st: -61.2,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 83,
      ty: 0,
      nm: '34',
      refId: 'comp_12',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 82.4,
      op: 111.2,
      st: -48,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 84,
      ty: 0,
      nm: '34',
      refId: 'comp_12',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 54,
      op: 82.4,
      st: -34.8,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 85,
      ty: 0,
      nm: '34',
      refId: 'comp_12',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [488, 380, 0], ix: 2, l: 2 },
        a: { a: 0, k: [488, 375, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 976,
      h: 750,
      ip: 0,
      op: 54,
      st: -24,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 86,
      ty: 2,
      nm: 'Asset 40.png',
      cl: 'png',
      refId: 'image_15',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: -44, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 302.4,
              s: [460, 405.75, 0],
              to: [-44.333, -41.5, 0],
              ti: [44.333, 41.5, 0],
            },
            { t: 310.400390625, s: [194, 156.75, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [10.5, 10.5, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 302.4,
              s: [10, 10, 100],
            },
            { t: 308.7998046875, s: [79.045, 79.045, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      ip: 302.4,
      op: 311.2,
      st: 288,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 87,
      ty: 2,
      nm: 'Asset 40.png',
      cl: 'png',
      refId: 'image_15',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: -90, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 302.4,
              s: [455.25, 421.75, 0],
              to: [-43.542, -0.292, 0],
              ti: [43.542, 0.292, 0],
            },
            { t: 310.400390625, s: [194, 420, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [10.5, 10.5, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 302.4,
              s: [10.045, 10.045, 100],
            },
            { t: 308.7998046875, s: [96.045, 96.045, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      ip: 302.4,
      op: 311.2,
      st: 288,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 88,
      ty: 2,
      nm: 'Asset 40.png',
      cl: 'png',
      refId: 'image_15',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 90, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 302.4,
              s: [520.25, 418.5, 0],
              to: [38.479, 0.25, 0],
              ti: [-38.479, -0.25, 0],
            },
            { t: 310.400390625, s: [751.125, 420, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [10.5, 10.5, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 302.4,
              s: [10.045, 10.045, 100],
            },
            { t: 308.7998046875, s: [96.045, 96.045, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      ip: 302.4,
      op: 311.2,
      st: 288,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 89,
      ty: 2,
      nm: 'Asset 40.png',
      cl: 'png',
      refId: 'image_15',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 214, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 302.4,
              s: [489, 449, 0],
              to: [-17.979, 31.625, 0],
              ti: [17.979, -31.625, 0],
            },
            { t: 310.400390625, s: [381.125, 638.75, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [10.5, 10.5, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 302.4,
              s: [10.045, 10.045, 100],
            },
            { t: 308.7998046875, s: [96.045, 96.045, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      ip: 302.4,
      op: 310.4,
      st: 288,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 90,
      ty: 2,
      nm: 'Asset 40.png',
      cl: 'png',
      refId: 'image_15',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 19, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 302.4,
              s: [486.75, 388.25, 0],
              to: [12.771, -38.583, 0],
              ti: [-12.771, 38.583, 0],
            },
            { t: 310.400390625, s: [563.375, 156.75, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [10.5, 10.5, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 302.4,
              s: [10.045, 10.045, 100],
            },
            { t: 308.7998046875, s: [96.045, 96.045, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      ip: 302.4,
      op: 310.4,
      st: 288,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 91,
      ty: 2,
      nm: 'Asset 41.png',
      cl: 'png',
      refId: 'image_16',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: -24, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 302.4,
              s: [470.125, 392.5, 0],
              to: [-14.833, -39.292, 0],
              ti: [14.833, 39.292, 0],
            },
            { t: 310.400390625, s: [381.125, 156.75, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [10, 10, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 302.4,
              s: [10, 10, 100],
            },
            { t: 308.7998046875, s: [96, 96, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      ip: 302.4,
      op: 310.4,
      st: 288,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 92,
      ty: 2,
      nm: 'Asset 41.png',
      cl: 'png',
      refId: 'image_16',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: -124.5, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 302.4,
              s: [473.875, 448.25, 0],
              to: [-46.646, 31.75, 0],
              ti: [46.646, -31.75, 0],
            },
            { t: 310.400390625, s: [194, 638.75, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [10, 10, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 302.4,
              s: [10, 10, 100],
            },
            { t: 308.7998046875, s: [96, 96, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      ip: 302.4,
      op: 311.2,
      st: 288,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 93,
      ty: 2,
      nm: 'Asset 41.png',
      cl: 'png',
      refId: 'image_16',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: -90, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 302.4,
              s: [460.625, 422.1, 0],
              to: [-13.25, -0.35, 0],
              ti: [13.25, 0.35, 0],
            },
            { t: 310.400390625, s: [381.125, 420, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [10, 10, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 302.4,
              s: [10, 10, 100],
            },
            { t: 308.7998046875, s: [96, 96, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      ip: 302.4,
      op: 310.4,
      st: 288,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 94,
      ty: 2,
      nm: 'Asset 41.png',
      cl: 'png',
      refId: 'image_16',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 131.5, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 302.4,
              s: [516.125, 433.5, 0],
              to: [39.167, 34.208, 0],
              ti: [-39.167, -34.208, 0],
            },
            { t: 310.400390625, s: [751.125, 638.75, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [10, 10, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 302.4,
              s: [10, 10, 100],
            },
            { t: 308.7998046875, s: [96, 96, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      ip: 302.4,
      op: 311.2,
      st: 288,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 95,
      ty: 2,
      nm: 'Asset 41.png',
      cl: 'png',
      refId: 'image_16',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 160, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 302.4,
              s: [505.375, 445.75, 0],
              to: [9.667, 32.167, 0],
              ti: [-9.667, -32.167, 0],
            },
            { t: 310.400390625, s: [563.375, 638.75, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [10, 10, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 302.4,
              s: [10, 10, 100],
            },
            { t: 308.7998046875, s: [96, 96, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      ip: 302.4,
      op: 310.4,
      st: 288,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 96,
      ty: 2,
      nm: 'Asset 41.png',
      cl: 'png',
      refId: 'image_16',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 44.5, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 302.4,
              s: [501.125, 392.75, 0],
              to: [41.667, -39.333, 0],
              ti: [-41.667, 39.333, 0],
            },
            { t: 310.400390625, s: [751.125, 156.75, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [10, 10, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 302.4,
              s: [10, 10, 100],
            },
            { t: 308.7998046875, s: [96, 96, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      ip: 302.4,
      op: 311.2,
      st: 288,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 97,
      ty: 2,
      nm: 'Asset 41.png',
      cl: 'png',
      refId: 'image_16',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 90, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 302.4,
              s: [514.125, 418.1, 0],
              to: [8.208, 0.317, 0],
              ti: [-8.208, -0.317, 0],
            },
            { t: 310.400390625, s: [563.375, 420, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [10, 10, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 302.4,
              s: [10, 10, 100],
            },
            { t: 308.7998046875, s: [96, 96, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      ip: 302.4,
      op: 310.4,
      st: 288,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 98,
      ty: 0,
      nm: 'Asset 38',
      refId: 'comp_13',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 228.8,
              s: [28],
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 298.4,
              s: [143],
            },
            { t: 300.7998046875, s: [352] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [488, 418, 0], ix: 2, l: 2 },
        a: { a: 0, k: [222.5, 223, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 228,
              s: [14, 14, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 233.6,
              s: [63, 63, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 292,
              s: [63, 63, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 296,
              s: [83, 83, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 298.4,
              s: [83, 83, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 301.6,
              s: [14, 14, 100],
            },
            { t: 308, s: [31, 31, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      w: 445,
      h: 446,
      ip: 228.8,
      op: 301.6,
      st: 24,
      bm: 1,
    },
    {
      ddd: 0,
      ind: 99,
      ty: 0,
      nm: 'Asset 41',
      refId: 'comp_14',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 228.8,
              s: [-35],
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 298.4,
              s: [79],
            },
            { t: 300.7998046875, s: [352] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [488, 418, 0], ix: 2, l: 2 },
        a: { a: 0, k: [222.5, 223, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 228.8,
              s: [14, 14, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 234.4,
              s: [130, 130, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 292,
              s: [130, 130, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 296,
              s: [148, 148, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 298.4,
              s: [148, 148, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 301.6,
              s: [14, 14, 100],
            },
            { t: 308, s: [31, 31, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      w: 445,
      h: 446,
      ip: 228.8,
      op: 301.6,
      st: 24,
      bm: 1,
    },
    {
      ddd: 0,
      ind: 100,
      ty: 2,
      nm: 'Asset 33.png',
      cl: 'png',
      refId: 'image_19',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 228.8,
              s: [0],
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 298.4,
              s: [261],
            },
            { t: 300.7998046875, s: [383] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [488, 418, 0], ix: 2, l: 2 },
        a: { a: 0, k: [256.5, 256.5, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 228.8,
              s: [14, 14, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 232.8,
              s: [92, 92, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 292,
              s: [92, 92, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 296,
              s: [112.222, 112.222, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 298.4,
              s: [112.2, 112.2, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 301.6,
              s: [14, 14, 100],
            },
            { t: 308, s: [31, 31, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      ip: 228.8,
      op: 301.6,
      st: 24,
      bm: 1,
    },
    {
      ddd: 0,
      ind: 101,
      ty: 2,
      nm: 'Asset 32.png',
      cl: 'png',
      refId: 'image_20',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 228.8,
              s: [0],
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 298.4,
              s: [-174],
            },
            { t: 300.7998046875, s: [-240] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [488, 418, 0], ix: 2, l: 2 },
        a: { a: 0, k: [283, 283, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 228.8,
              s: [14, 14, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 232.8,
              s: [87, 87, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 291.2,
              s: [87, 87, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 296,
              s: [112.222, 112.222, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 298.4,
              s: [112.2, 112.2, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 301.6,
              s: [14, 14, 100],
            },
            { t: 308, s: [31, 31, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      ip: 228.8,
      op: 302.4,
      st: 24,
      bm: 0,
    },
  ],
  markers: [],
};
