import React from 'react';

import { ContentfulCustomMessage, SectionsWithCustomMessages } from 'ContentfulDefaults/types/_shared';
import { useTextContext } from 'Contexts/textContext';
import { PageLayout } from 'DesignLibrary/atoms/PageLayout';

import { ContentfulContainer } from './styled';
import { ContentfulInfoBar } from '../ContentfulInfoBar';

interface ContentfulCustomMessagesProps {
  sectionKey: SectionsWithCustomMessages;
  children?: JSX.Element;
}

export const ContentfulInfoBars = ({ sectionKey, children }: ContentfulCustomMessagesProps) => {
  const { retrieveContentfulData } = useTextContext();
  // Show warning message for Internet Explorer 6-11 users
  // Typescript doesn't like dot notation, eslint does
  // eslint-disable-next-line dot-notation
  const isIE = /* @cc_on!@ */ false || !!document['documentMode'];

  let ieInfoBar;
  const ieDisclaimerKey = 'unified_landing_page.ie_disclaimer';
  const ieMessage = retrieveContentfulData<string>(ieDisclaimerKey);
  if (isIE && ieMessage) {
    ieInfoBar = <ContentfulInfoBar field={ieDisclaimerKey} key={ieDisclaimerKey} />;
  }

  let infoBars;
  const customMessageKey = `${sectionKey}.custom_messages`;
  const customMessages = retrieveContentfulData<ContentfulCustomMessage[]>(`${sectionKey}.custom_messages`);

  if (customMessages) {
    infoBars = customMessages.map((msg: ContentfulCustomMessage, index: number) => {
      const field = `${customMessageKey}[${index}]`;
      return <ContentfulInfoBar field={field} key={field} />;
    });
  }

  if (ieInfoBar || infoBars || children) {
    // if (ieInfoBar || infoBars) {
    return (
      <PageLayout>
        <ContentfulContainer>
          {ieInfoBar}
          {infoBars}
          {children}
        </ContentfulContainer>
      </PageLayout>
    );
  }
  return null;
};
