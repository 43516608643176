import React, { useEffect, useRef, useState } from 'react';

import { AudioState, useAudioContext } from 'Contexts/audioContext';
import { Icon } from 'DesignLibrary/atoms';
import { AudioButton } from 'DesignLibrary/atoms/AudioTooltip/styled';

export interface AudioTooltipProps {
  urlToAudio: string;
  urlToClosedCaptionVtt: string;
  handleClick?: () => void;
}

export const AudioTooltip = ({
  urlToAudio,
  urlToClosedCaptionVtt,
  handleClick,
  ...rest
}: AudioTooltipProps): JSX.Element => {
  const { audioState, setAudioState } = useAudioContext();
  const audioButtonRef = useRef<HTMLButtonElement>(null);
  const [isCurrentlyPlaying, setIsCurrentlyPlaying] = useState(false);

  useEffect(() => {
    setIsCurrentlyPlaying(audioButtonRef.current === audioState.currentRef);
  }, [audioButtonRef.current, audioState.currentRef]);

  const actualOnClick =
    handleClick ||
    (() => {
      let newState: AudioState;

      if (isCurrentlyPlaying) {
        // todo consolidate this behavior with onEnded.
        newState = {
          ...audioState,
          currentRef: undefined,
          isPlaying: false,
          urlToClosedCaptionVtt: '',
          urlToAudio: '',
        };
      } else {
        newState = {
          ...audioState,
          currentRef: audioButtonRef.current as HTMLButtonElement,
          isPlaying: true,
          urlToAudio,
          urlToClosedCaptionVtt,
        };
      }

      setAudioState(newState);
    });

  return (
    <div {...rest}>
      <AudioButton
        onClick={actualOnClick}
        ref={audioButtonRef}
        buttonType="secondary"
        size="small"
        isPlaying={isCurrentlyPlaying}
      >
        <Icon
          type="SpeakerHigh"
          color={isCurrentlyPlaying ? '--primary-white' : '--primary-blue'}
          size="normal"
        />
      </AudioButton>
    </div>
  );
};
