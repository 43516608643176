import React from 'react';

import { Button, H3, Paragraph } from 'DesignLibrary/atoms';

import { ErrorContainer } from './styled';

export const BrowserNavError = ({ errorRoute }) => (
  <>
    <H3>Not So Fast!</H3>
    <Paragraph>
      You made changes to your profile. You need to return to the profile page and submit those changes to
      proceed.
    </Paragraph>
    <Button className="return-to-profile" onClick={errorRoute} buttonType="primary">
      Back to profile
    </Button>
  </>
);

const LocalStorageError = ({ errorRoute }) => (
  <>
    <H3>Cookies Disabled</H3>
    <Paragraph>Please enable browser cookies to continue with your experience!</Paragraph>
    <Button className="return-to-profile" onClick={errorRoute} buttonType="primary" color="black">
      Return Home
    </Button>
  </>
);

export const DefaultError = ({ errorRoute }) => (
  <>
    <H3>Oops...</H3>
    <Paragraph>It looks like we have encountered an error. Please click below and try again.</Paragraph>
    <Button buttonType="primary" color="black" onClick={errorRoute}>
      Return Home
    </Button>
  </>
);

const Error500 = () => {
  const isLocalStorageDisabled = () => {
    const test = 'test';
    try {
      localStorage.setItem(test, test);
      localStorage.removeItem(test);
      return false;
    } catch (e) {
      return true;
    }
  };

  return (
    <ErrorContainer>
      {(() => {
        switch (true) {
          case isLocalStorageDisabled():
            return <LocalStorageError errorRoute={() => window.location.reload()} />;
          default:
            return <DefaultError errorRoute={() => window.location.reload()} />;
        }
      })()}
    </ErrorContainer>
  );
};

export default Error500;
