import styled from 'styled-components';

import { media } from 'DesignLibrary/global';
import { cardStyling } from 'DesignLibrary/utils';

export const PlansContainer = styled.div`
  margin-top: var(--sizing-sm);

  .plan-section {
    margin-top: var(--sizing-xl);
  }

  .plan-list {
    & > * + * {
      margin-top: var(--sizing-md);
    }
  }
`;

export const Bullet = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: var(--sizing-sm);
  margin-bottom: var(--sizing-md);

  ${media.TABLET`
    flex-direction: row;
  `}

  .paragraph {
    text-align: center;
  }
`;

export const LoadingCard = styled.div`
  ${cardStyling}
  padding: var(--sizing-2xl) 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
