import { ContentfulType } from 'ContentfulDefaults/ContentfulType';

import { benefits } from './benefits';
import { eligibility } from './eligibility';
import { health } from './health';
import { healthDetails } from './healthDetails';
import { hsa } from './hsa';
import { navigation } from './navigation';
import { ply } from './ply';
import { profile } from './profile';
import { review } from './review';
import { spousal } from './spousal';
import { toolTips } from './toolTips';
import { unifiedLanding } from './unifiedLanding';

export function getSpanishDefaults(field: keyof ContentfulType) {
  switch (field) {
    case 'benefits_section':
      return benefits;
    case 'eligibility_section':
      return eligibility;
    case 'health_section':
      return health;
    case 'health_details_section':
      return healthDetails;
    case 'hsa':
      return hsa;
    case 'people_like_you':
      return ply;
    case 'profile_section':
      return profile;
    case 'review_section':
      return review;
    case 'spousal_plan_comparison':
      return spousal;
    case 'tool_tips':
      return toolTips;
    case 'top_level_navigation':
      return navigation;
    case 'unified_landing_page':
      return unifiedLanding;
    default:
      return {};
  }
}
